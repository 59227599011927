import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import { useState } from "react";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { calculateTotalWithReduce, formatIndianCurrency } from "../../utils/utils";
const ReportAccountBalanceTable = ({ departureReport, fullData, getSingleBookingDataHandler }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",

    //   { title: "Booking ID" },d
    //   { title: "Guest Name" },d
    //   { title: "Arrival Date" },d
    //   { title: "Departure Date" },d
    //   { title: "Source" },
    //   { title: "Grand Total" },
    //   { title: "Amount Paid" },
    //   { title: "Balance" },
    //   { title: "Status" },
  };
  const extractedData = departureReport?.map((item) => ({
    unique_booking_id: item?.unique_booking_id,
    guest_name: item?.guest[0]?.guest_name,
    check_in: item?.check_in,
    check_out: item?.check_out,
    booking_source: item?.booking_source?.replace(/_/g, " "),
    grand_total: item?.grand_total,
    paid_amount: item?.paid_amount,
    balance_amount: item?.balance_amount,
    status: item?.status?.replace(/_/g, " "),
  }));

  const [informationPopup, setInformationPopup] = useState(false);

  const names = [
    {
      unique_booking_id: "Booking ID",
      guest_name: "Guest Name",
      check_in: "Arrival Date",
      check_out: "Departure Date",
      booking_source: "Booking Source",
      grand_total: "Grand Total",
      paid_amount: "Paid Amount",
      grand_total: "Balance",
      status: "Status",
    },
  ];
  const rowMarkup = departureReport?.map((order) => (
    <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
      <CustomReportTableCell> <div
            className="pointer"
            style={{
              color: "rgba(57, 104, 237, 1)",
            }}
            onClick={() => getSingleBookingDataHandler(order.unique_booking_id)}
          >
            #{order.unique_booking_id || ""}
          </div></CustomReportTableCell>
      <CustomReportTableCell>
        {order?.guest[0]?.guest_name}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {" "}
        <TextDateFormatter as={"span"} showYear={true} date={order.check_in} />
      </CustomReportTableCell>
      <CustomReportTableCell>
        <TextDateFormatter as={"span"} showYear={true} date={order.check_out} />
      </CustomReportTableCell>
      <CustomReportTableCell>
        <div style={{ textTransform: "capitalize" }}>
          {order &&
            order.booking_source &&
            order?.booking_source.replace(/_/g, " ")}
        </div>
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order?.items?.map((item, index) => (
          <span key={index}>
            {item.room_type_name}
            {index < order.items.length - 1 && ", "}
          </span>
        ))}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order?.items?.map((item, index) => (
          <span key={index}>
            {item.no_nights}
            {index < order.items.length - 1 && ", "}
          </span>
        ))}
      </CustomReportTableCell>
      <CustomReportTableCell>{order?.guest?.length}</CustomReportTableCell>

      <CustomReportTableCell>{formatIndianCurrency(order?.paid_amount)}</CustomReportTableCell>
      <CustomReportTableCell>{formatIndianCurrency(order.balance_amount)}</CustomReportTableCell>
      <CustomReportTableCell>{formatIndianCurrency(order?.grand_total)}</CustomReportTableCell>
      <CustomReportTableCell>
        <StatusUpdate status={order.status} /> {}
      </CustomReportTableCell>
    </CustomReportTableRow>
  ));

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell colSpan={4}>{"Totals"}</CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell> 
   <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>

   <CustomReportTableCell>

      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "paid_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "balance_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "grand_total")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>

    </CustomReportTableRow>
  );

  return (
    <>
      <div
        id="receipt"
        className="room-types-full-container reports-new-background"
      >
        <div className="room-types-full-container-div">
          <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
            <div className="room-type-heading">Account Balance Report</div>
            <div
              className="hoverCursor"
              onClick={() => setInformationPopup(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#3968ED"
              >
                <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
              </svg>
            </div>
          </div>
          {departureReport?.length > 0 && (
            <div className="flex flex_gap_15" id="doNotPrint">
              <DownloadPdfTables
                tableData={extractedData}
                tableNames={names}
                downloadFileName="AccountBalanceReport"
              />

              {/* <ButtonToDownloadTablePDF  downloadFileName="AccountBalanceReport" itemToDownloadID={"receipt"} ignoreFromPrint={"doNotPrint"}/> */}
              <DownloadCSVButton
                downloadFileName="AccountBalanceReport"
                itemToDownloadID={"receipt"}
              />
            </div>
          )}
        </div>

        {departureReport && departureReport.length > 0 ? (
          <>
            {/* <LegacyCard>
              <IndexTable
                resourceName={resourceName}
                itemCount={departureReport.length}
                selectable={false}
                headings={[
                  { title: "Booking ID" },
                  { title: "Guest Name" },
                  { title: "Arrival Date" },
                  { title: "Departure Date" },
                  { title: "Source" },
                  { title: "Grand Total" },
                  { title: "Amount Paid" },
                  { title: "Balance" },
                  { title: "Status" },
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </LegacyCard> */}

            <CustomReportTable
              headings={[
                "Booking ID",
                "Guest Name",
                "Check In Date",
                "Check Out Date",
                "Booking Source",
                "Room Type",
                "No Of Nights",
                "No Of Guests",
                "Paid Amount",
                "Balance",
                "Grand Total",
                "Status",
              ]}
              // commonHeadings={[
              //   "",
              //   "",
              //   "",
              //   "",
              //   "Quantity",
              //   "Quantity",
              //   "Quantity",
              //   "Total Purchase Amount  (₹)",
              //   "Total Purchase Amount  (₹)",
              //   "Total Purchase Amount  (₹)",
              //   "Average Purchase Cost per unit (₹)",
              //   "Average Purchase Cost per unit (₹)",
              //   "Average Purchase Cost per unit (₹)",
              // ]}
              headerStyles={{
                backgroundColor: "#dddddd",
                fontSize: "12px",
                textAlign: "left",
              }}
              tableStyles={{ textAlign: "left" }}
            >
              {rowMarkup}
              {calculateTotals}
            </CustomReportTable>
          </>
        ) : (
          <NoDataFound />
        )}

        {informationPopup && (
          <InformationCustomPopup
            setInformationPopup={setInformationPopup}
            title="Information of Account Balance Report"
          >
            <div className="mb-20">
              <AccordionComponent
                questions={[
                  {
                    question: "What is the Account Balance Report?",
                    answer: (
                      <p>
                        The <b>Account Balance Report</b> provides a detailed overview of guest bookings and their financial status, covering key details such as booking ID, guest name, stay dates, booking source, room type, total nights, guest count, grand total, paid amount, balance due, and booking status. This report focuses on ensuring that all financial aspects of guest reservations are accurately tracked and updated.
                      </p>
                    ),
                  },
                  {
                    question: "Why is the Arrival Report Important?",
                    answer: (
                      <div>
                        <p>
                        The Account Balance Report is crucial for several reasons:

                        </p>

                        <ul>
                          <li>
                            <b>Payment Collection: </b>Ensures no guest checks out with an outstanding balance, reducing the risk of unpaid dues after departure.

                          </li>
                          <li>
                            <b>Reservation Adjustments: </b>Ensures no guest checks in without payment or a valid form of payment on file, improving financial security.
                          </li>
                          <li>
                            <b>Operational Efficiency: </b>Helps hotel staff stay on top of guest payments and balances, reducing potential payment disputes or delays.
                          </li>
                          <li>
                            <b>Revenue Assurance: </b>Assists in tracking all financial aspects of bookings, ensuring timely payment collection and minimizing revenue leakage.
                          </li>
                        </ul>
                        <p>This report is a valuable tool for maintaining financial control, ensuring accurate billing, and promoting smoother guest check-ins and check-outs.
                        </p>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </InformationCustomPopup>
        )}
      </div>
    </>
  );
};
export default ReportAccountBalanceTable;
