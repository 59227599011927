import React from "react";
import Folio from "../Components/Folio/Folio";
import FolioSummeryBill from "../Components/Folio/FolioSummeryBill";

const FolioBillPage = () => {
    return (
        <>
            {/* <div className="main_container"> */}
            <div style={{ margin: "0", padding: '70px 0' }}>
                <FolioSummeryBill />

            </div>
            {/* </div> */}
        </>
    );
};

export default FolioBillPage;
