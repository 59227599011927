import styles from "./Tags.module.scss";

export const StatusTag = ({
    status,
    title
}) => {
    return (
        <div className={`${styles.status_tag} ${styles[status]}`}>
            {title}
        </div>
    );
};

export const PrimaryTag = ({
    size,
    title,
    tickIcon,
    deleteIcon,
    onDelete
}) => {
    return (
        <div className={`${styles.primary_tag} ${styles[size]}`}>
            {tickIcon}
            {title}
            {deleteIcon &&
                <button
                    onClick={onDelete}
                    className="flex align_items_center border_none bg_transparent pointer">
                    {deleteIcon}
                </button>
            }
        </div>
    );
};

export const SecondaryTag = ({
    size,
    title,
    tickIcon,
    deleteIcon,
    onDelete
}) => {
    return (
        <div className={`${styles.secondary_tag} ${styles[size]}`}>
            {tickIcon}
            {title}
            {deleteIcon &&
                <button
                    onClick={onDelete}
                    className="flex align_items_center border_none bg_transparent pointer">
                    {deleteIcon}
                </button>
            }
        </div>
    );
};