import React from "react";
import Sidebar from "../Components/Sidebar";
import Competitors from "../Components/Competitors";


const CompetitorsPage = () => {
  return (
    <>
      <div className="main_container">
        <Competitors />
      </div>
    </>
  );
};

export default CompetitorsPage;
