import React, { useState } from 'react';
import styles from "./Curtain.module.scss";


export const Curtain = ({
    centerHeading,
    rightHeading,
    customWidth,
    children,
    handleClose
}) => {
   
    return (
        <div>
            <div style={{ width: customWidth }} className={styles.mainWrapper}>

                {/* Slider Heading */}
                <div className={`${styles.sliderTitle}`}>
                    <span onClick={handleClose} style={{ cursor: "pointer" }}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_963_18)">
                                <path d="M16.4818 4.6967L15.3033 3.51819L10 8.82149L4.6967 3.51819L3.51819 4.6967L8.82149 10L3.51819 15.3033L4.6967 16.4818L10 11.1785L15.3033 16.4818L16.4818 15.3033L11.1785 10L16.4818 4.6967Z" fill="#858585" />
                            </g>
                            <defs>
                                <clipPath id="clip0_963_18">
                                    <rect width="20" height="20" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                    <h4>{centerHeading} Heading 1</h4>
                    <h3>{rightHeading} Heading 2</h3>
                </div>

                {/* Slider Content */}
                <div className={styles.sliderContent}>
                    {children}
                </div>

                {/* Slider Button */}
                {/* {showDefaultBtn && (
                    <div className={styles.sliderBtn}>
                        <PrimaryButton title={"Confirm"} />
                        <GhostButton title={"Cancel"} />
                    </div>
                )} */}

            </div>
        </div>
    )
}

// export default Curtain

export const CurtainTable = ({
    centerHeading,
    rightHeading,
    customWidth,
    children,
    handleClose
}) => {
   
    return (
        <div>
            <div style={{ width: customWidth  }} className={`${styles.mainWrapper} ${styles.mainWrapperTable}`}>

                {/* Slider Content */}
                <div className={`${styles.sliderContent} ${styles.sliderTableContent}`}>
                    {children}
                </div>

            </div>
        </div>
    )
}
