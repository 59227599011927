import { Button } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import VerticalInputFieldCustom from "../VerticalInputFieldCustom";
import VerticalTextAreaCustom from "../VerticalTextAreaCustom";
import { BgOutlineBlackButton } from "../../UI/Buttons";
import { showErrorToast } from "../../../assets/toastUtils";
import VerticalInputSelectCustomCopy from "../VerticalInputSelectCustomCopy";

const DeletePopupWithReasonBooking = ({
  title,
  onDeleteWithReason,
  wordLimit,
  deleteWithReasonPopup,
  setDeleteWithReasonPopup,
  deleteFunction,
  id,
  btnText,
  cancelText,
}) => {
  const [reason, setReason] = useState({
    reason: "",
    duplicateBookingId: "",
    originalBookingId: "",
    otherReason: "",
  });
  const [originalBookingId, setOriginalBookingId] = useState("");
  const [duplicateBookingId, setDuplicateBookingId] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  // const handleRemove = () => {

  // if (reasonLength > 0) {
  //   setButtonDisabled(false);
  // } else {
  //   setButtonDisabled(true);
  // }

  // deleteFunction()
  // };

  useEffect(() => {
    const reasonLength = reason.reason.trim().length;
    if (reasonLength > 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [reason]);

  const handleChange = (e) => {
    // const inputValue = e.target.value;

    // if (inputValue.length > wordLimit) {
    //   showErrorToast("You have exceeded the word limit");
    // } else {
    //   setReason(inputValue.slice(0, wordLimit));
    // }
    setReason({
      ...reason,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    onDeleteWithReason(reason, id);
  }, [reason, id]);

  const [deleteSelectReason, setDeleteReason] = useState("");

  const deleteReason = [
    {
      label: "Select Option",
      value: "",
    },
    {
      label: "Duplicate Booking",
      value: "duplicate_booking",
    },
    {
      label: "Test Booking",
      value: "test_booking",
    },
    {
      label: "Other",
      value: "other",
    },
  ];


  return (
    <div className="bg-backdrop">
      <section
        className="room-type-model-container"
        style={{ padding: "20px" }}
      >
        <h4 style={{ color: "#E03838", marginBottom: "8px" }}>Are You Sure?</h4>
        <p className="deleteContainer" style={{ marginBottom: "8px" }}>
          {cancelText ? (
            cancelText
          ) : (
            <div> You Want to Cancel This {title}?</div>
          )}
        </p>
        <div style={{ width: "100%" }}>
          <div>
            <VerticalInputSelectCustomCopy
              titleName={"Select Reason"}
              name={"reason"}
              options={deleteReason}
              onChange={handleChange}
              value={reason.reason}
              style={{ width: "100%", height: "30px", minHeight: "30px" }}
            />
          </div>

          {reason.reason === "other" && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <div className="reason-input-button">Enter Reason</div>
                <div className="reason-input-button">
                  {reason?.otherReason?.trim()?.length}/{wordLimit}
                </div>
              </div>
              <VerticalTextAreaCustom
                name={"otherReason"}
                className="textfield"
                focused={false}
                type="text"
                value={reason.otherReason}
                autoComplete="off"
                onChange={handleChange}
                cols={9}
              />
            </>
          )}
          {reason.reason === "duplicate_booking" && (
            <>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <div className="reason-input-button">
                  Enter Duplicate Booking ID{" "}
                </div>
              </div>
              <VerticalInputFieldCustom
                name={"duplicateBookingId"}
                className="textfield"
                focused={false}
                type="number"
                value={reason.duplicateBookingId}
                autoComplete="off"
                onChange={handleChange}
                cols={9}
              /> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <div className="reason-input-button">
                  Enter Original Booking ID{" "}
                </div>
              </div>
              <VerticalInputFieldCustom
                name={"originalBookingId"}
                className="textfield"
                focused={false}
                type="number"
                value={reason.originalBookingId}
                autoComplete="off"
                onChange={handleChange}
              />
            </>
          )}
        </div>

        <div
          style={{
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <BgOutlineBlackButton onClick={() => setDeleteWithReasonPopup(false)}>
            Discard
          </BgOutlineBlackButton>
          <Button
            disabled={buttonDisabled}
            destructive
            onClick={() =>
              deleteFunction(reason, id, duplicateBookingId, originalBookingId)
            }
          >
            {btnText ? btnText : "Yes, Cancel"}
          </Button>
        </div>
      </section>
    </div>
  );
};

export default DeletePopupWithReasonBooking;
