// React Imports
import * as React from "react";

// Polaris Imports
import { BarChart } from "@shopify/polaris-viz";
import { Card } from "@shopify/polaris";
import "@shopify/polaris-viz/build/esm/styles.css";
import { Page } from "@shopify/polaris";

const BarCharts = ({ loader, data, leftTitle }) => {
  const data1 = [
    {
      name: "Values in Thousand",
      color: "#7C9CF3",
      barMargin: "None",
      data: [
        { value: 2000, key: "Sep" },
        { value: 200, key: "Jan" },
        { value: 2000, key: "Sep" },
        { value: 2000, key: "Sep" },
      ],
    },
  ];

  const [renderedChartData, setRenderedChartData] = React.useState(data);

  return (
    <div style={{ position: "relative" }}>
      <Card>
        <div title="Reservation Insights">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "40px",
            }}
          >
            <div className="report-chart-heading">{leftTitle}</div>
            {/* <div>
              Total Reservation:
              <span className="report-chart-heading">7,600</span>
            </div> */}
          </div>

          <BarChart
            theme="Light"
            showLegend={true}
            isAnimated={true}
            data={renderedChartData}
            // state={false}
          />
        </div>
      </Card>
    </div>
  );
};

export default BarCharts;
