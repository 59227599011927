import {
  TextField,
  IndexTable,
  LegacyCard,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Button,
  Icon,
  Select,
  Page,
} from "@shopify/polaris";

import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  SettleBillButton,
  WithoutBgButtonBlue,
} from "./UI/Buttons";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import Loader from "./UI/Loader/Loader";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import NoDataFound from "./CustomComponents/NoDataFound";
import { banquet_inner_lists, pms_inner_lists } from "../assets/constant";
import SidebarInnerLists from "./CustomComponents/SidebarInnerLists";
import StatusUpdate from "./CustomComponents/StatusUpdate";
import AddCategoryItem from "./UI/AddCategoryItem";
import EventCategories from "./UI/EventCategories";
import CustomPagination from "./CustomComponents/CustomPagination/CustomPagination";
import { getDecryptedData } from "../utils/encryptStorage";

const Events = () => {
  const currentDate = new Date().toISOString().split("T")[0];

  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddStockModelMenuActive, setShowAddStockModelMenuActive] =
    useState(false);

  const [showAddItemModelMenuActive, setShowAddItemModelMenuActive] =
    useState(false);

  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);
  const [showAddCategoryModelMenuActive, setShowAddCategoryModelMenuActive] =
    useState(false);

  const [showCheckOutSettlementDetails, setShowCheckOutSettlementDetails] =
    useState(false);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [itemStrings, setItemStrings] = useState([
    "Past 7 Days",
    "Past 30 Days",
    "Past 90 Days",
    "Select Date Range",
  ]);

  const paymentModeOptions = [
    { label: "Select Option" },
    { label: "Cash", value: "cash" },
    { label: "Bank Payment", value: "bank_payment" },
    { label: "UPI", value: "upi" },
    { label: "Credit Card", value: "credit_card" },
    { label: "Debit Card", value: "debit_card" },
    { label: "Other", value: "other" },
  ];

  const deleteView = (index) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    setSelected(0);
  };

  const duplicateView = async (name) => {
    setItemStrings([...itemStrings, name]);
    setSelected(itemStrings.length);
    await sleep(1);
    return true;
  };

  const tabs = itemStrings.map((item, index) => ({
    content: item,
    index,
    onAction: () => {},
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions:
      index === 0
        ? []
        : [
            {
              type: "rename",
              onAction: () => {},
              onPrimaryAction: async (value) => {
                const newItemsStrings = tabs.map((item, idx) => {
                  if (idx === index) {
                    return value;
                  }
                  return item.content;
                });
                await sleep(1);
                setItemStrings(newItemsStrings);
                return true;
              },
            },
            {
              type: "duplicate",
              onPrimaryAction: async (value) => {
                await sleep(1);
                duplicateView(value);
                return true;
              },
            },
            {
              type: "edit",
            },
            {
              type: "delete",
              onPrimaryAction: async () => {
                await sleep(1);
                deleteView(index);
                return true;
              },
            },
          ],
  }));
  const [selected, setSelected] = useState(0);
  const onCreateNewView = async (value) => {
    await sleep(500);
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    return true;
  };
  const sortOptions = [
    { label: "Order", value: "order asc", directionLabel: "Ascending" },
    { label: "Order", value: "order desc", directionLabel: "Descending" },
    { label: "Customer", value: "customer asc", directionLabel: "A-Z" },
    { label: "Customer", value: "customer desc", directionLabel: "Z-A" },
    { label: "Date", value: "date asc", directionLabel: "A-Z" },
    { label: "Date", value: "date desc", directionLabel: "Z-A" },
    { label: "Total", value: "total asc", directionLabel: "Ascending" },
    { label: "Total", value: "total desc", directionLabel: "Descending" },
  ];

  const [sortSelected, setSortSelected] = useState(["order asc"]);
  const { mode, setMode } = useSetIndexFiltersMode();
  const onHandleCancel = () => {};

  const onHandleSave = async () => {
    await sleep(1);
    return true;
  };

  const primaryAction =
    selected === 0
      ? {
          type: "save-as",
          onAction: onCreateNewView,
          disabled: false,
          loading: false,
        }
      : {
          type: "save",
          onAction: onHandleSave,
          disabled: false,
          loading: false,
        };
  const [accountStatus, setAccountStatus] = useState(undefined);
  const [moneySpent, setMoneySpent] = useState(undefined);
  const [taggedWith, setTaggedWith] = useState("");
  const [queryValue, setQueryValue] = useState("");

  const handleAccountStatusChange = useCallback(
    (value) => setAccountStatus(value),
    []
  );
  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );
  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleAccountStatusRemove = useCallback(
    () => setAccountStatus(undefined),
    []
  );
  const handleMoneySpentRemove = useCallback(
    () => setMoneySpent(undefined),
    []
  );
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAccountStatusRemove();
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [
    handleAccountStatusRemove,
    handleMoneySpentRemove,
    handleQueryValueRemove,
    handleTaggedWithRemove,
  ]);

  const navigate = useNavigate();
  const userToken = getDecryptedData('encryptToken');

  const property_id = useSelector((state) => state.property.value);

  const [loader, setLoader] = useState(true);

  // settlementData
  const [settlementAddData, setSettlementAddData] = useState({
    settlement_name: "",
    payment_mode: "",
    notes: "",
    date: "",
    amount: "",
    property_id: property_id?.id,
    other_payment_mode: "",
  });

  const filters = [
    {
      key: "accountStatus",
      label: "Account status",
      filter: (
        <ChoiceList
          title="Account status"
          titleHidden
          choices={[
            { label: "Enabled", value: "enabled" },
            { label: "Not invited", value: "not invited" },
            { label: "Invited", value: "invited" },
            { label: "Declined", value: "declined" },
          ]}
          selected={accountStatus || []}
          onChange={handleAccountStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "taggedWith",
      label: "Tagged with",
      filter: (
        <TextField
          label="Tagged with"
          value={taggedWith}
          onChange={handleTaggedWithChange}
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: true,
    },
    {
      key: "moneySpent",
      label: "Money spent",
      filter: (
        <RangeSlider
          label="Money spent is between"
          labelHidden
          value={moneySpent || [0, 500]}
          prefix="$"
          output
          min={0}
          max={2000}
          step={1}
          onChange={handleMoneySpentChange}
        />
      ),
    },
  ];

  const appliedFilters = [];
  if (accountStatus && !isEmpty(accountStatus)) {
    const key = "accountStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, accountStatus),
      onRemove: handleAccountStatusRemove,
    });
  }
  if (moneySpent) {
    const key = "moneySpent";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, moneySpent),
      onRemove: handleMoneySpentRemove,
    });
  }
  if (!isEmpty(taggedWith)) {
    const key = "taggedWith";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }
  const active = (
    <div
      style={{
        color: "#15AA12",
        padding: "6px",
        backgroundColor: "#DFF8DF",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        fontFamily: "Inter",
        fontSize: "12px",
      }}
    >
      Active
    </div>
  );

  const expired = (
    <div
      style={{
        color: "#888",
        padding: "6px",
        backgroundColor: "#EEE",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        fontFamily: "Inter",
        fontSize: "12px",
      }}
    >
      Expired
    </div>
  );

  const scheduled = (
    <div
      style={{
        color: "#FFC107",
        padding: "6px",
        backgroundColor: "#FFF5DC",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        fontFamily: "Inter",
        fontSize: "12px",
      }}
    >
      Scheduled
    </div>
  );

  const order = [
    {
      id: "1",
      event_name: "Ritu weds Sahil’s Marriage",
      event_id: "640247",
      event_date: "04 Jul to 11 Jul, 23",
      event_type: "Wedding",
      event_pax: "200 to 250",
      status: "completed",
    },
    {
      id: "2",
      event_name: "Birthday Party",
      event_id: "640247",
      event_date: "04 Jul to 11 Jul, 23",
      event_type: "Corporate Meeting",
      event_pax: "20 to 30",
      status: "pending",
    },
  ];

  const [orders, setOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  // Pagignation
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(orders.length / itemsPerPage);
    setCurrentPage(newPage > totalPages ? totalPages : newPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentOrders = orders.slice(startIndex, endIndex);

  const [kotId, setKotId] = useState("");

  const [billBalance, setBillBalance] = useState("");

  useEffect(() => {
    setKotId(kotId);
  }, [kotId]);

  useEffect(() => {
    setBillBalance(billBalance);
  }, [billBalance]);

  const handleSettleBillButton = async (id, balance_amount, grand_total) => {
    setKotId(id);
    setBillBalance(balance_amount);
    setShowCheckOutSettlementDetails(!showCheckOutSettlementDetails);
  };

  const fetchInfo = async (propertyId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        // /api/v1/expenses/1
        `${process.env.REACT_APP_BASE_URL}/api/v1/event/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data.event);
      setAllOrders(data.event);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };
  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
      fetchEventCategories(property_id?.id);
    }
  }, [property_id]);

  // setTimeout(() => {
  //   setLoader(false);
  // }, 1000);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(currentOrders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      // getOneData(selectedResources[0]);
      // setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(true);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const addModalButtonClickHandler = () => {
    setShowAddStockModelMenuActive(!showAddStockModelMenuActive);
  };

  const editModalButtonClickHandler = () => {
    setShowEditModelMenuActive(!showEditModelMenuActive);
  };

  const removeAddModalContainer = () => {
    setShowAddStockModelMenuActive(!showAddStockModelMenuActive);
  };

  // UseState for forms
  const [addItemData, setAddItemData] = useState({
    event_name: "",
    event_date: "",
    event_type: "",
    event_pax: "",
    status: "",
    category_id: "",
    client_name: "",
    client_phone: "",
    date: "",
    slot: "",
  });

  const setAddItemDataHandleChange = (event) => {
    setAddItemData({
      ...addItemData,
      [event.target.name]: event.target.value,
    });
  };

  const setAddSettlementDataHandleChange = (event) => {
    setSettlementAddData({
      ...settlementAddData,
      [event.target.name]: event.target.value,
    });
  };

  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/edit/event/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        event_name: data.event.event_name,
        event_date: data.event.event_date,
        event_type: data.event.event_type,
        event_pax: data.event.event_pax,
        status: data.event.status,
        category_id: data.event.category_id,
      };

      setAddItemData(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGenerateBillButton = async (id, event) => {
    event.stopPropagation();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/event/generate/bill/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        // clearSelection();
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const rowMarkup = currentOrders?.map(
    ({
      id,
      event_name,
      event_id,
      status,
      index,
      client_name,
      category,
      no_of_pax,
      balance_amount,
      paid_amount,
      billGenerated,
    }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          <CustomCssComponentTableText
            capitalize
            innerText={event_name}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={client_name}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            capitalize
            innerText={event_id}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={no_of_pax}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={(paid_amount && "₹ " + paid_amount) || 0}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={balance_amount}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <StatusUpdate status={status}></StatusUpdate>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {balance_amount < 1 ? (
            <>
              {!billGenerated ? (
                <BgOutlineThemeButton
                  onClick={(e) => handleGenerateBillButton(id, e)}
                >
                  Generate Bill
                </BgOutlineThemeButton>
              ) : (
                <Link
                  to={`/event/bill/${id}`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  target="_blank"
                >
                  <BgOutlineThemeButton children={"Event Bill"} />
                </Link>
              )}
            </>
          ) : (
            <SettleBillButton
              type={"button"}
              // onClick={(e) => {
              //   setShowCheckOutSettlementDetails(true);
              //   e.stopPropagation();
              // }}
              onClick={() => handleSettleBillButton(id, balance_amount)}
              children={"Settle Bill"}
            />
          )}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Link
            to={`/event/voucher/${id}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
            target="_blank"
          >
            <BgOutlineThemeButton children={"Preview Voucher"} />
          </Link>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const removeItemConfirm = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  // Event Type Options
  const eventStatusOptions = [
    { label: "Select Option", value: "Select Option" },
    { label: "Confirmed", value: "confirmed" },
    { label: "Pending", value: "pending" },
    { label: "Upcoming", value: "upcoming" },
  ];

  const [planTypeSelected, setPlanTypeSelected] = useState("EP");
  const planTypeHandleSelectChange = useCallback(
    (value) => setPlanTypeSelected(value),
    []
  );

  // Plan Name Options
  const planNameOptions = [
    { label: "Room Only", value: "newestUpdate" },
    { label: "Room + Complimentary Breakfast", value: "oldestUpdate" },
    {
      label: "Room with Complimentary Breakfast and dinner",
      value: "mostSpent",
    },
    { label: "Breakfast + Lunch + Dinner", value: "mostOrders" },
  ];

  const [planNameSelected, setNameTypeSelected] = useState("EP");

  const planNameHandleSelectChange = useCallback(
    (value) => setNameTypeSelected(value),
    []
  );

  // Plan Name Options
  const planStatusOptions = [
    { label: "Pending", value: "pending" },
    {
      label: "Confirmed",
      value: "confirmed",
    },
  ];

  const [planStatusSelected, setPlanStatusSelected] = useState("Booked");
  const planStatusHandleSelectChange = useCallback(
    (value) => setPlanStatusSelected(value),
    []
  );

  // Plan Type Options
  const roomTypeOptions = [
    { label: "Deluxe", value: "Deluxe" },
    { label: "Super Deluxe", value: "Super Deluxe" },
    { label: "Cottage", value: "Cottage" },
    { label: "Luxury", value: "Luxury" },
    { label: "Executive", value: "Executive" },
    { label: "Family", value: "Family" },
  ];

  const [roomTypeSelected, setRoomTypeSelected] = useState("EP");
  const roomTypeHandleSelectChange = useCallback(
    (value) => setRoomTypeSelected(value),
    []
  );

  const [asignHotelChecked, setAsignHotelChecked] = useState(false);

  const asignHotelCheckboxHandleChange = useCallback(
    (newChecked) => setAsignHotelChecked(newChecked),
    []
  );

  const [purchaseHeadingActiveIndex, setPurchaseHeadingActiveIndex] =
    useState(null);
  const [purchaseHeadingActive, setPurchaseHeadingActive] = useState("");

  const setPurchaseHeading = (idx) => {
    setPurchaseHeadingActiveIndex(idx);
    if (purchaseHeadingActive === idx) {
      setPurchaseHeadingActive("active");
    } else {
      setPurchaseHeadingActive("");
    }
  };

  const handleSubmitSettlement = (event) => {
    event.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/event/store/settlements/${kotId}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...settlementAddData,
          property_id: property_id?.id,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setShowCheckOutSettlementDetails(false);
          fetchInfo(property_id?.id);
          setSettlementAddData({
            settlement_name: "",
            payment_mode: "",
            notes: "",
            date: "",
            amount: "",
            property_id: property_id?.id,
          });
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const updateFunction = (id, event) => {
    event.preventDefault();

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/event/status/update/${id}/${property_id?.id}?status=${addItemData.status}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          fetchInfo(property_id?.id);
          setShowEditModelMenuActive(false);
          clearSelection();
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const fetchChannelInfo = async (channelId, propertyId, index) => {
    setUniqueChannelId(channelId);
  };

  const [uniqueChannelId, setUniqueChannelId] = useState("all");

  useEffect(() => {
    setUniqueChannelId(uniqueChannelId);
    if (uniqueChannelId === "all") {
      setOrders(allOrders);
    } else {
      const copyOrders = [...orders];
      const filteredOrders = allOrders.filter(
        (order) => order.category_id === uniqueChannelId
      );

      // Set the filtered array into the orders state
      setOrders(filteredOrders);
    }
  }, [uniqueChannelId]);

  const handleSubmitAddExpenseHandler = (event) => {
    event.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/create/event/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...addItemData }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setShowAddStockModelMenuActive(false);
          fetchInfo(property_id?.id);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    clearSelection();
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/event/delete/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          clearSelection();
          fetchInfo(property_id?.id);
          clearSelection();
          setShowLowerMenuMultipleSelect(false);
          setShowModelMenuActive(false);
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  // const channelsArray = [
  //   {
  //     id: 1,
  //     channel_name: "All",
  //   },
  //   {
  //     id: 2,
  //     channel_name: "Wedding",
  //   },
  //   {
  //     id: 3,
  //     channel_name: "Corporate Meeting",
  //   },
  //   {
  //     id: 4,
  //     channel_name: "Party",
  //   },
  // ];

  const [eventCategories, setEventCategories] = useState([]);

  const fetchEventCategories = async (id) => {
    try {
      // setLoader(true);
      const response = await fetch(
        // `${process.env.REACT_APP_BASE_URL}/api/v1/event/update/${id}/${property_id?.id}`
        `${process.env.REACT_APP_BASE_URL}/api/v1/event/category/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setEventCategories(data.categories);
      // setLoader(false);
    } catch (error) {
      // setLoader(false);
      console.error(error);
    }
  };

  // const eventCategoriesOptions = eventCategories.map((category) => {
  //   return { label: category.category_name, value: category.id };
  // });

  const channelsArray = [
    { channel_name: "All", id: "all" },
    ...eventCategories.map((category) => {
      return { channel_name: category.category_name, id: category.id };
    }),
  ];

  const eventCategoriesOptions = [
    { label: "Select Option" },
    ...eventCategories.map((category) => {
      return { label: category.category_name, value: category.id };
    }),
  ];

  return (
    <>
      <SidebarInnerLists innerLists={banquet_inner_lists} />

      <>
        <div className="room-types-full-container">
          <div className="room-types-full-container-div">
            <div className="room-type-heading">Events</div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <div style={{ textDecoration: "none" }}>
                <BgOutlineThemeButton
                  onClick={() => {
                    setShowAddCategoryModelMenuActive(true);
                    clearSelection();
                    setAddItemData({});
                  }}
                  children={"Categories"}
                />
              </div>

              <div style={{ textDecoration: "none" }}>
                <BgThemeButton
                  onClick={
                    () => navigate("/events/add")
                    // setShowAddStockModelMenuActive(true);
                    // clearSelection();
                    // setAddItemData({});
                  }
                  children={"Add Events"}
                />
              </div>
            </div>
          </div>

          <LegacyCard>
            <div
              style={{
                borderBottom: "1px solid #ccc",
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="inventory-first-row-head overflow_x_scroll_y_hidden">
                {channelsArray &&
                  channelsArray.map((channel, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() =>
                          fetchChannelInfo(channel.id, property_id.id, index)
                        }
                        className={`booking-first-row-head-divs ${
                          uniqueChannelId === channel.id ? "active" : ""
                        }`}
                      >
                        {channel.channel_name}
                      </div>
                    );
                  })}
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <div>
                      <BgThemeButton
                        onClick={() =>
                          setInventoriesOptionShowAddModelMenuActive(true)
                        }
                        children={"Inventory Options"}
                      />
                    </div> */}
                {/* <div>
                      <BgOutlineThemeButton
                        onClick={unholdAddModalButtonClickHandler}
                        children={"Unhold Inventory"}
                      />
                    </div>
                    <div>
                      <BgOutlineThemeButton
                        onClick={unblockAddModalButtonClickHandler}
                        children={"Unblock Inventory"}
                      />
                    </div>
                    <div>
                      <BgOutlineThemeButton
                        onClick={blockAddModalButtonClickHandler}
                        children={"Block Inventory"}
                      />
                    </div>
                    <div>
                      <BgThemeButton
                        onClick={addModalButtonClickHandler}
                        children={"Bulk Updates"}
                      />
                    </div> */}
              </div>
            </div>

            {loader ? (
              <Loader />
            ) : (
              <>
                {orders && orders.length > 0 ? (
                  <>
                    <IndexTable
                      resourceName={resourceName}
                      itemCount={currentOrders?.length}
                      selectedItemsCount={
                        allResourcesSelected ? "All" : selectedResources.length
                      }
                      onSelectionChange={handleSelectionChange}
                      headings={[
                        { title: "Event Name" },
                        { title: "Client Name" },
                        { title: "Event ID" },
                        // { title: "Event Slot" },
                        // { title: "Event Type" },
                        { title: "No Of Pax" },
                        { title: "Paid Amount" },
                        { title: "Balance" },
                        { title: "Status" },
                      ]}
                    >
                      {rowMarkup}
                    </IndexTable>
                    <CustomPagination
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                      totalItems={orders.length}
                      onPageChange={handlePageChange}
                      clearSelection={clearSelection}
                    />
                  </>
                ) : (
                  <NoDataFound />
                )}
              </>
            )}
            {/* Single select */}
            {showLowerMenuSingleSelect && (
              <div className=" room-type-bottom-container">
                <div className="room-type-bottom-container-div">
                  <div>
                    <Button plain destructive onClick={showModelMenu}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Icon source={DeleteMajor} color="base" />
                        </span>
                        <span>Remove</span>
                      </div>
                    </Button>
                  </div>
                  <form
                    onSubmit={(e) => updateFunction(selectedResources[0], e)}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="room-type-bottom-div">
                      <div>Status</div>
                      <div style={{ width: "250px" }}>
                        <VerticalInputSelectCustomCopy
                          options={eventStatusOptions}
                          type={"text"}
                          name="status"
                          value={addItemData.status}
                          onChange={setAddItemDataHandleChange}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        color: "#3968ED",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {/* <BgOutlineThemeButton
                          onClick={() =>
                            navigate(`/events/edit/${selectedResources[0]}`)
                          }
                          type={"button"}
                          // onClick={editModalButtonClickHandler}
                          children={"Edit More"}
                        /> */}
                      <BgThemeButton type={"submit"} children={"Update"} />
                    </div>
                  </form>
                </div>
              </div>
            )}

            {/* Multiple select */}
            {showLowerMenuMultipleSelect && (
              <div className=" room-type-bottom-container">
                <div className="room-type-bottom-container-div">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Button plain destructive onClick={showModelMenu}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Icon source={DeleteMajor} color="base" />
                        </span>
                        <span>Remove</span>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </LegacyCard>
        </div>

        {/* Add Events Button Click */}
        {showAddStockModelMenuActive && (
          <>
            <div className="bg-backdrop">
              <form
                onSubmit={(e) => handleSubmitAddExpenseHandler(e)}
                style={{ width: "700px" }}
                className="stock-add-model-container"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <div className="add-ota-model-container-heading">
                    Add Events
                  </div>
                  <div
                    onClick={removeAddModalContainer}
                    style={{ cursor: "pointer" }}
                  >
                    <Icon source={CancelMajor} color="base" />
                  </div>
                </div>

                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      type={"text"}
                      titleName="Event Name"
                      name="event_name"
                      value={addItemData.event_name}
                      onChange={setAddItemDataHandleChange}
                    />
                  </div>
                  <div className="form-container-div">
                    {eventCategoriesOptions &&
                    eventCategoriesOptions.length <= 1 ? (
                      <div style={{ marginTop: "25px" }}>
                        Add event categories first.
                      </div>
                    ) : (
                      <VerticalInputSelectCustomCopy
                        options={eventCategoriesOptions}
                        required={true}
                        type={"text"}
                        titleName="Event Category"
                        name="category_id"
                        value={addItemData.category_id}
                        onChange={setAddItemDataHandleChange}
                      />
                    )}
                  </div>
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      min={currentDate}
                      titleName="Date"
                      name="event_date"
                      type={"date"}
                      value={addItemData.event_date}
                      onChange={setAddItemDataHandleChange}
                    />
                  </div>
                </div>

                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      type={"text"}
                      titleName="Event Type"
                      name="event_type"
                      value={addItemData.event_type}
                      onChange={setAddItemDataHandleChange}
                    />
                  </div>

                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      type={"text"}
                      titleName="No. of pax"
                      name="event_pax"
                      value={addItemData.event_pax}
                      onChange={setAddItemDataHandleChange}
                      placeholder={"min-max"}
                    />
                  </div>
                  <div className="form-container-div">
                    <VerticalInputSelectCustomCopy
                      options={eventStatusOptions}
                      required={true}
                      titleName="Status"
                      type={"number"}
                      name="status"
                      value={addItemData.status}
                      onChange={setAddItemDataHandleChange}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      gap: "10px",
                    }}
                  >
                    <BgThemeButton type="submit" children={"Add"} />
                    <Button
                      onClick={() => setShowAddStockModelMenuActive(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}

        {/* Add Item Button Click */}
        {showAddItemModelMenuActive && (
          <>
            <div className="bg-backdrop">
              <section className="stock-add-model-container">
                <div style={{ marginLeft: "-200px" }}>
                  <Page
                    backAction={{
                      content: "Products",
                      url: `/hotelier/purchase`,
                    }}
                    title="Add Issue Items"
                    compactTitle
                  ></Page>
                </div>

                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <TextField
                      type="text"
                      className="textfield"
                      focused={false}
                      label="User Type"
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-container-div">
                    <TextField
                      type="text"
                      className="textfield"
                      focused={false}
                      label="Issued To"
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-container-div">
                    <TextField
                      type="date"
                      className="textfield"
                      focused={false}
                      label="Issued Date"
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <Select
                      className="textfield"
                      focused={false}
                      label="Item Name"
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-container-div">
                    <Select
                      className="textfield"
                      focused={false}
                      label="Select Category"
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-container-div">
                    <TextField
                      className="textfield"
                      focused={false}
                      label="Qty"
                      autoComplete="off"
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "20px",
                      width: "350px",
                    }}
                    className="form-container-div"
                  >
                    <WithoutBgButtonBlue children={"Add More"} />
                  </div>
                </div>
                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <Select
                      className="textfield"
                      focused={false}
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-container-div">
                    <Select
                      className="textfield"
                      focused={false}
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-container-div">
                    <TextField
                      className="textfield"
                      focused={false}
                      autoComplete="off"
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "350px",
                    }}
                    className="form-container-div"
                  >
                    <WithoutBgButtonBlue children={"Add More"} />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <BgThemeButton children={"Add"} />
                  <Button onClick={() => setShowAddItemModelMenuActive(false)}>
                    Cancel
                  </Button>
                </div>
              </section>
            </div>
          </>
        )}

        {/* Settlement */}
        {showCheckOutSettlementDetails && (
          <div className="bg-backdrop">
            <div
              style={{ width: "700px" }}
              className="dashboard_checkin_modal_container"
            >
              <div className="booking_details_child">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginLeft: "-22px" }}>
                    <Page
                      backAction={{
                        content: "Products",
                        url: `/dashboard`,
                      }}
                      title="Settlement"
                      compactTitle
                    ></Page>
                  </div>
                  <div>
                    {/* <h2>
                          <strong>
                            Booking ID: #
                            {checkOutBookingDetail?.unique_booking_id}
                          </strong>
                        </h2> */}

                    <div>Pending Balance : {billBalance && billBalance}</div>
                  </div>
                </div>
              </div>

              <form
                onSubmit={(event) => handleSubmitSettlement(event)}
                className="settlement-add-model-container"
              >
                <div className="form-container-div-half">
                  <div className="form-container-div">
                    {/* <VerticalInputFieldCustom
                        required={true}
                        titleName="Name"
                        name="settlement_name"
                        type="text"
                        value={settlementAddData.settlement_name}
                        onChange={setAddSettlementDataHandleChange}
                      /> */}

                    <VerticalInputSelectCustomCopy
                      required={true}
                      options={paymentModeOptions}
                      titleName="Payment Mode"
                      name="payment_mode"
                      value={settlementAddData.payment_mode}
                      onChange={setAddSettlementDataHandleChange}
                    />
                  </div>
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="Notes"
                      name="notes"
                      value={settlementAddData.notes}
                      onChange={setAddSettlementDataHandleChange}
                    />
                  </div>
                  {settlementAddData.payment_mode === "other" && (
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        options={paymentModeOptions}
                        titleName="Specify Payment mode"
                        name="other_payment_mode"
                        value={settlementAddData.other_payment_mode}
                        onChange={setAddSettlementDataHandleChange}
                      />
                    </div>
                  )}
                </div>

                {/* <div className="form-container-div-half">
                    <div
                      style={{ width: "100%" }}
                      className="form-container-div"
                    >
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Notes"
                        name="notes"
                        value={settlementAddData.notes}
                        onChange={setAddSettlementDataHandleChange}
                      />
                    </div>
                  </div>
                   */}
                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      type={"date"}
                      titleName="Date"
                      name="date"
                      min={currentDate}
                      value={settlementAddData.date}
                      onChange={setAddSettlementDataHandleChange}
                    />
                  </div>

                  <div style={{ width: "100%" }} className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      type={"number"}
                      titleName="Amount"
                      name="amount"
                      value={settlementAddData.amount}
                      onChange={setAddSettlementDataHandleChange}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {parseInt(settlementAddData.amount) ===
                    parseInt(billBalance) ? (
                      <BgThemeButton
                        // onClick={(e) => handleSubmitAddMeals(e)}
                        type="submit"
                        children={"Settle"}
                      />
                    ) : (
                      <BgThemeButtonDisabled
                        // onClick={(e) => handleSubmitAddMeals(e)}
                        type="submit"
                        children={"Settle"}
                        disabled={true}
                      />
                    )}

                    <div
                      style={{
                        color: "#3968ED",
                        textDecoration: "underline",
                        textUnderlineOffset: "3px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        // setShowCheckOutBookingDetails(true);
                        setShowCheckOutSettlementDetails(false);
                        clearSelection();
                        setSettlementAddData({
                          settlement_name: "",
                          payment_mode: "",
                          notes: "",
                          date: "",
                          amount: "",
                          property_id: property_id?.id,
                          other_payment_mode: "",
                        });
                      }}
                    >
                      Go back
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Edit Button Click */}
        {showEditModelMenuActive && (
          <>
            <div className="bg-backdrop">
              <form
                onSubmit={(e) => updateFunction(selectedResources[0], e)}
                style={{ width: "700px" }}
                className="stock-add-model-container"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <div className="add-ota-model-container-heading">
                    Edit Event
                  </div>
                  <div
                    onClick={() => setShowEditModelMenuActive(false)}
                    style={{ cursor: "pointer" }}
                  >
                    <Icon source={CancelMajor} color="base" />
                  </div>
                </div>

                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      type={"text"}
                      titleName="Event Name"
                      name="event_name"
                      value={addItemData.event_name}
                      onChange={setAddItemDataHandleChange}
                    />
                  </div>
                  <div className="form-container-div">
                    <VerticalInputSelectCustomCopy
                      options={eventCategoriesOptions}
                      required={true}
                      type={"text"}
                      titleName="Event Category"
                      name="category_id"
                      value={addItemData.category_id}
                      onChange={setAddItemDataHandleChange}
                    />
                  </div>
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      min={currentDate || addItemData.event_date}
                      required={true}
                      titleName="Date"
                      name="event_date"
                      type={"date"}
                      value={addItemData.event_date}
                      onChange={setAddItemDataHandleChange}
                    />
                  </div>
                </div>

                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      type={"text"}
                      titleName="Event Type"
                      name="event_type"
                      value={addItemData.event_type}
                      onChange={setAddItemDataHandleChange}
                    />
                  </div>

                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      type={"text"}
                      titleName="No. of pax"
                      name="event_pax"
                      value={addItemData.event_pax}
                      onChange={setAddItemDataHandleChange}
                    />
                  </div>
                  <div className="form-container-div">
                    <VerticalInputSelectCustomCopy
                      options={eventStatusOptions}
                      required={true}
                      titleName="Status"
                      type={"number"}
                      name="status"
                      value={addItemData.status}
                      onChange={setAddItemDataHandleChange}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      gap: "10px",
                    }}
                  >
                    <BgThemeButton type="submit" children={"Update"} />
                    <Button onClick={() => setShowEditModelMenuActive(false)}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}

        {showModelMenuActive && (
          <>
            <div className="bg-backdrop">
              <section className="room-type-model-container ">
                <h4>Are You Sure?</h4>
                <p>You Want To Remove?</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    onClick={() => setShowModelMenuActive(!showModelMenuActive)}
                  >
                    Discard
                  </Button>
                  <Button onClick={deleteData} destructive>
                    Yes, Remove
                  </Button>
                </div>
              </section>
            </div>
          </>
        )}

        {/* Add Button Click */}
        {showAddCategoryModelMenuActive && (
          <EventCategories
          // setShowAddUnitsMenuActive={setShowAddUnitsMenuActive}
          // setShowAddDepartmentMenuActive={setShowAddDepartmentMenuActive}
          // setShowAddModelMenuActive={setShowAddModelMenuActive}
          />
        )}
      </>
    </>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "moneySpent":
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case "taggedWith":
        return `Tagged with ${value}`;
      case "accountStatus":
        return value.map((val) => `Customer ${val}`).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};
export default Events;
