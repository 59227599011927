import React from "react";
import PurchaseIssueItem from "../Components/RequestItemList";
import RequestItemList from "../Components/RequestItemList";
import StockStatus from "../Components/StockStatus";

const StockStatusPageNew = () => {
  return (
    <>
      <div className="main_container">
       <StockStatus />
      </div>
    </>
  );
};

export default StockStatusPageNew;
