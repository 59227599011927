import React from "react";
import AddRoomType from "../Components/AddRoomsType";

const RoomTypeAddPage = () => {
  return (
    <>
      <div className="main_container">
        <AddRoomType />
      </div>
    </>
  );
};

export default RoomTypeAddPage;
