import { Icon, IndexTable, LegacyCard, Pagination, Text } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { showErrorToast } from "../../assets/toastUtils";
import Loader from "../UI/Loader/Loader";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import { convertTo12HourFormat, formatIndianCurrency } from "../../utils/utils";
import CustomPaginationArrows from "../CustomComponents/CustomPagination/CustomPaginationArrows";
import IFrameBillUiRoom from "../Folio/IFrameBillUiRoom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { CancelMajor } from "@shopify/polaris-icons";
import { getDecryptedData } from "../../utils/encryptStorage";

const FolioAuditLogs = () => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [showIframePopup, setShowIframePopup] = useState(false);

  const handlePageChange = (page, newUrl) => {
    console.log(page, newUrl,"called");
    setCurrentPage(page);
    if (newUrl) {
      fetchAuditLogs(newUrl);
    }
  };

  const property_id = useSelector((state) => state.property.value);
 const userToken = getDecryptedData('encryptToken');;

  const [orders, setOrders] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (property_id) {
      fetchAuditLogs();
    }
  }, [property_id]);

  const fetchAuditLogs = async (newUrl) => {
    try {
      setLoader(true);
      const response = await fetch(
        // {{development}}/api/v1/night/audit/logs/{property_id}
        `${process.env.REACT_APP_BASE_URL}/api/v1/night/audit/logs/${
          property_id?.id
        }${"/" + newUrl && newUrl}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        setOrders(data.data.data);
        setTotalPages(data?.data?.last_page);
        setLoader(false);
      } else {
        showErrorToast(data.message);
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
      setLoader(false);
    }
  };

  const [iframeContent, setIframeContent] = useState("");
  const [iframeContentCont, setIframeContentCont] = useState("");
  const [iframePreviewBill, setIframePreviewBill] = useState("");
  const [iframePrintBill, setIframePrintBill] = useState("");
  const [showBillPreviewSingle, setShowBillPreviewSingle] = useState(false);
  const [showBillPreview, setShowBillPreview] = useState(false);

  const [printRequested, setPrintRequested] = useState(false);
  const [printRequestedSingle, setPrintRequestedSingle] = useState(false);



  const printIframe = (id) => {
    // setIsLoading(false);
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);

    iframe.style.width = "600px";

    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
  };

  const getBillIframe = (id) => {
    fetch(
      // https://dev.revcatalyst.in/v1/layout/preview/night/audit/82/1
      `${process.env.REACT_APP_BASE_URL}/api/v1/preview/night/audit/${id}/${property_id?.id}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.text())
      .then((data) => {
        if (data) {
          // printIframe("receipt");
          setIframeContent(data);

          // setShowBillPreview(true);
          // setIsLoading(false);
          if (data.trim() !== "") {
            if (iframeContent.trim() !== "") {
              setIframeContent(data);
              setPrintRequested(true);

              setTimeout(() => {
                printIframe("receipt");
              }, 400);
            }
          } else {
            console.log("Empty iframe content");
          }
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const getBillIframePreview = (id) => {

    fetch(
      // https://dev.revcatalyst.in/api/v1/layout/preview/4/bill?previewType=foodOrder&previewId=25
      `${process.env.REACT_APP_BASE_URL}/api/v1/preview/night/audit/${id}/${property_id?.id}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.text())
      .then((data) => {
        if (data) {
          // setShowBillPreview(true);
          // setIsLoading(false);
          if (data.trim() !== "") {
            setIframeContentCont(data);
            setShowBillPreview(true);
            setShowIframePopup(true);

            // .then((data) => {
            //   setIframeSrc(data);
            //   setShowIframePopup(true);
            //   setIframeContent(null);
            //   // setShowBillPreview(true);
            // })

            // printIframe("receipt");
            setPrintRequestedSingle(true);

            // setTimeout(() => {
            //   printIframe("receipt");
            // }, 1000);
          } else {
            console.log("Empty iframe content");
          }
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

 
  const rowMarkup = orders?.map((order) => (
    <IndexTable.Row id={order?.id} key={order.id} position={order.index}>
      <IndexTable.Cell>
        {
          <UnderscoreRemoveCapitalize
            text={order?.started_by ? order?.started_by : "N/A"}
          />
        }
      </IndexTable.Cell>
      <IndexTable.Cell>
        {order?.closed_by ? order?.closed_by : "N/A"}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {order?.day_start_date} -{convertTo12HourFormat(order?.day_start_time)}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {order?.day_close_date} - {convertTo12HourFormat(order?.day_close_time)}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {formatIndianCurrency(order?.opening_balance)}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {formatIndianCurrency(order?.opening_balance)}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {order?.day_close_date && (
          <div className="flex flex_gap_10">
            <div
              onClick={() => getBillIframePreview(order.id)}
              className="pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M7 8.75C7.9665 8.75 8.75 7.9665 8.75 7C8.75 6.0335 7.9665 5.25 7 5.25C6.0335 5.25 5.25 6.0335 5.25 7C5.25 7.9665 6.0335 8.75 7 8.75Z"
                  fill="#3968ED"
                />
                <path
                  d="M13.5361 6.85125C13.0215 5.52022 12.1282 4.36914 10.9665 3.54029C9.80489 2.71143 8.42584 2.24113 6.99981 2.1875C5.57379 2.24113 4.19473 2.71143 3.03309 3.54029C1.87145 4.36914 0.978134 5.52022 0.463564 6.85125C0.428812 6.94737 0.428812 7.05263 0.463564 7.14875C0.978134 8.47978 1.87145 9.63086 3.03309 10.4597C4.19473 11.2886 5.57379 11.7589 6.99981 11.8125C8.42584 11.7589 9.80489 11.2886 10.9665 10.4597C12.1282 9.63086 13.0215 8.47978 13.5361 7.14875C13.5708 7.05263 13.5708 6.94737 13.5361 6.85125ZM6.99981 9.84375C6.43737 9.84375 5.88756 9.67697 5.41991 9.36449C4.95226 9.05202 4.58777 8.60788 4.37253 8.08826C4.15729 7.56863 4.10098 6.99685 4.21071 6.44521C4.32043 5.89358 4.59127 5.38687 4.98898 4.98917C5.38668 4.59146 5.89339 4.32062 6.44503 4.21089C6.99666 4.10117 7.56844 4.15748 8.08807 4.37272C8.6077 4.58795 9.05183 4.95245 9.36431 5.4201C9.67678 5.88775 9.84356 6.43756 9.84356 7C9.84241 7.75385 9.54243 8.4765 9.00937 9.00956C8.47631 9.54261 7.75367 9.84259 6.99981 9.84375Z"
                  fill="#3968ED"
                />
              </svg>
            </div>
            <div className="pointer" onClick={() => getBillIframe(order.id)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 14 12"
                fill="none"
              >
                <path
                  d="M11.0007 2.66667H3.00065V0H11.0007V2.66667ZM11.0007 6.33333C11.1895 6.33333 11.348 6.26933 11.476 6.14133C11.604 6.01333 11.6678 5.85511 11.6673 5.66667C11.6673 5.47778 11.6033 5.31933 11.4753 5.19133C11.3473 5.06333 11.1891 4.99956 11.0007 5C10.8118 5 10.6533 5.064 10.5253 5.192C10.3973 5.32 10.3335 5.47822 10.334 5.66667C10.334 5.85556 10.398 6.014 10.526 6.142C10.654 6.27 10.8122 6.33378 11.0007 6.33333ZM9.66732 10.6667V8H4.33398V10.6667H9.66732ZM11.0007 12H3.00065V9.33333H0.333984V5.33333C0.333984 4.76667 0.528429 4.29156 0.917318 3.908C1.30621 3.52444 1.77843 3.33289 2.33398 3.33333H11.6673C12.234 3.33333 12.7091 3.52511 13.0927 3.90867C13.4762 4.29222 13.6678 4.76711 13.6673 5.33333V9.33333H11.0007V12Z"
                  fill="#3968ED"
                />
              </svg>
            </div>
          </div>
        )}

        {showBillPreview && (
          <>
            <iframe
              srcDoc={iframeContentCont}
              id="receipt"
              style={{ display: "none" }}
              title="Receipt"
            />
            <iframe
              srcDoc={iframeContent}
              id="receipt"
              style={{ display: "none" }}
              title="Receipt"
            />
          </>
        )}
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  const [downloadUrl, setDownloadUrl] = useState("");

  const handleDownload = () => {
    const html2pdf = require('html2pdf.js');
    const iframe = document.getElementById("receipt");
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    const htmlContent = iframeDocument.documentElement.outerHTML;
    html2pdf()
      .from(htmlContent)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        const filename = "receipt.pdf";
        pdf.save(filename);
      });

    iframe.style.height = "100%"; 
  };

  if (loader) {
    return <Loader />;
  }
  // console.log("orders", orders);

  return (
    <>
      <div className="room-types-full-container">
        <div className="room-types-full-container-div">
          <div className="room-type-heading">Night Audit Logs </div>

          <CustomPaginationArrows
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            apiUrl={""}
          />
        </div>

        {orders.length === 0 && <NoDataFound />}

        {orders && orders.length > 0 && (
          <>
            <LegacyCard>
              <IndexTable
                resourceName={resourceName}
                itemCount={orders.length}
                selectable={false}
                headings={[
                  { title: "Started By" },
                  { title: "Closed By" },
                  { title: "Day Start" },
                  { title: "Day Close" },
                  // { title: "Date" },
                  { title: "Opening Balance" },
                  { title: "Closing Balance" },
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </LegacyCard>
          </>
        )}

        {iframeContentCont && showIframePopup && (
          <>
            <div className="bg-backdrop">
              <section className="communication_iframe_container">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                    padding: "0 25px",
                  }}
                >
                  <div
                    className="add-ota-model-container-heading"
                    style={{ display: "flex", gap: "10px" }}
                  >
                    Report 
                    <div
                      onClick={() => printIframe("receipt")}
                      style={{ cursor: "pointer" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="12"
                        viewBox="0 0 14 12"
                        fill="none"
                      >
                        <path
                          d="M11.0007 2.66667H3.00065V0H11.0007V2.66667ZM11.0007 6.33333C11.1895 6.33333 11.348 6.26933 11.476 6.14133C11.604 6.01333 11.6678 5.85511 11.6673 5.66667C11.6673 5.47778 11.6033 5.31933 11.4753 5.19133C11.3473 5.06333 11.1891 4.99956 11.0007 5C10.8118 5 10.6533 5.064 10.5253 5.192C10.3973 5.32 10.3335 5.47822 10.334 5.66667C10.334 5.85556 10.398 6.014 10.526 6.142C10.654 6.27 10.8122 6.33378 11.0007 6.33333ZM9.66732 10.6667V8H4.33398V10.6667H9.66732ZM11.0007 12H3.00065V9.33333H0.333984V5.33333C0.333984 4.76667 0.528429 4.29156 0.917318 3.908C1.30621 3.52444 1.77843 3.33289 2.33398 3.33333H11.6673C12.234 3.33333 12.7091 3.52511 13.0927 3.90867C13.4762 4.29222 13.6678 4.76711 13.6673 5.33333V9.33333H11.0007V12Z"
                          fill="#3968ED"
                        />
                      </svg>
                    </div>
                    <div onClick={handleDownload} style={{ cursor: "pointer" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M6.99957 10.1107L3.11068 6.22179L4.19957 5.09401L6.22179 7.11623V0.777344H7.77734V7.11623L9.79957 5.09401L10.8885 6.22179L6.99957 10.1107ZM2.3329 13.2218C1.90512 13.2218 1.53879 13.0693 1.2339 12.7645C0.929012 12.4596 0.776827 12.0935 0.777345 11.6662V9.3329H2.3329V11.6662H11.6662V9.3329H13.2218V11.6662C13.2218 12.094 13.0693 12.4603 12.7645 12.7652C12.4596 13.0701 12.0935 13.2223 11.6662 13.2218H2.3329Z"
                          fill="#3968ED"
                        />
                      </svg>
                     
                    </div>
                    {downloadUrl && (
                      <a href={downloadUrl} download="receipt.html">
                        Download
                      </a>
                    )}
                  </div>
                  <div
                    onClick={() => setShowIframePopup(false)}
                    style={{ cursor: "pointer" }}
                  >
                    <Icon source={CancelMajor} color="base" />
                  </div>
                </div>
                <iframe
                  srcDoc={iframeContentCont}
                  id="receipt"
                  // style={{ display: "none" }}
                  title="Receipt"
                />
                {/* <PreviewCommunicationVoucher uniquebookingid={bookingId} /> */}
              </section>
            </div>
          </>
        )}
      </div>
      {/* <CustomPaginationArrows/> */}
    </>
  );
};
export default FolioAuditLogs;
