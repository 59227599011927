import React, { useState } from "react";
import { useRef, useEffect } from "react";
import "./DashboardFilter.css";

const DropdownFilter = ({ values, onSelect, selectedValue, maxWidth }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setOpenPopup(false);
    }
  };

  useEffect(() => {
    if (openPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openPopup]);

  const handleSelect = (label, value) => {
    onSelect(label, value);
    setOpenPopup(false);
  };
  function handlePopup() {
    setOpenPopup(!openPopup);
  }
  return (
    <div className="custom-select" style={{ background: "white" }}>
      <div
        className="input_div_dashboard"
        onClick={handlePopup}
        style={{ maxWidth: maxWidth }}
      >
        <input value={selectedValue} className="input_value_dashboard"></input>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
        >
          <path d="M1 1L5 5L9 1" stroke="#333333" />
        </svg>
        {openPopup && (
          <div className="popup_box_dashboard" ref={popupRef}>
            {values.map((option, index) => (
              <label
                key={index}
                onClick={() => handleSelect(option.label, option.value)}
                className="input_options_dashboard"
              >
                {option.label}
              </label>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default DropdownFilter;
