import React from "react";
import FolioGuestDetails from "../Components/Folio/FolioGuestDetails";

const FolioGuestDetailsPage = () => {
    return (
        <>
            {/* <div className="main_container"> */}
            <div style={{margin: "0", padding: '70px 0'}}>
            <FolioGuestDetails />

            </div>
            {/* </div> */}
        </>
    );
};

export default FolioGuestDetailsPage;
