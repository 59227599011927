import React, { useState } from "react";
import CustomerSelectAddBox from "../CustomComponents/CustomSelectAddBox";
import { BgOutlineThemeButton, BgThemeButton } from "../UI/Buttons";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";

const AddCompetitors = ({ property_id, fetchInfo, userToken, setShow }) => {
  const [competitorsIdsArray, setCompetitorsIdsArray] = useState([]);

  const handleCompetitorsIdsArrayChange = (newArray) => {
    setCompetitorsIdsArray(newArray);
  };
  const handleSubmitAddCompetitor = (event) => {
    event.preventDefault();
    const competitor = competitorsIdsArray;
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/assign/competitors/${property_id}`,
      {
        method: "POST",
        body: JSON.stringify({ competitor: competitorsIdsArray }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setShow(false);
          fetchInfo(property_id);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };
  return (
    <>
      <div className="bg-backdrop">
        <form className="room-plan-add-model-container">
          <h4>Add Competitor</h4>

          <div className="form-container-div-half">
            <div className="form-container-div">
              <CustomerSelectAddBox
                onCompetitorsIdsChange={handleCompetitorsIdsArrayChange}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <BgThemeButton
              onClick={(e) => handleSubmitAddCompetitor(e)}
              children={"Add"}
            />

            <BgOutlineThemeButton
              onClick={() => setShow(false)}
              children={"Discard"}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default AddCompetitors;
