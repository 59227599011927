import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  valueValue: 0,
};

export const permissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    savePermission: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { savePermission } = permissionSlice.actions;

export default permissionSlice.reducer;
