import React, { createContext, useContext, useRef } from "react";
import CustomToastContainer from "./CustomToastContainer";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const toastRef = useRef(null);

  return (
    <ToastContext.Provider value={toastRef}>
      {children}
    <CustomToastContainer position={"top-center"}  ref={toastRef} rtl={false} />
    {/* <CustomToastContainer position={"bottom-right"}  ref={toastRef} rtl={false} /> */}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
