import React from "react";
import EditDiscount from "../Components/EditDiscount";

const EditDiscountsPage = () => {
  return (
    <>
     <div className="main_container">
          <EditDiscount />
      </div>
    </>
  );
};

export default EditDiscountsPage;
