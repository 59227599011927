import React from 'react'
import ModifyReservationComponent from '../../Components/CustomComponents/ModifyReservationComponent/ModifyReservationComponent'

const ModifyReservation = () => {
  return (
    <div className="main_container">

    <ModifyReservationComponent/>




    </div>
  )
}

export default ModifyReservation