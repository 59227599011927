import React from "react";
import "./reservationView.css";

const Tooltip = ({booking}) => {
  console.log(booking);
 
  return (
    <div className="tooltip">
      <div className="tooltip-text-container">
        <span className="tooltip-text-heading">Guest Name</span> 
        <span className="tooltip-text-booking-names">{booking.booking_guest}</span>
      </div>
      <div className="tooltip-text-container">
        <span className="tooltip-text-heading">Check-in</span> 
         <span className="tooltip-text-booking-names">{booking.check_in || booking.check_in_date}</span>
      </div>
      <div className="tooltip-text-container">
        <span className="tooltip-text-heading">Check-out</span> 
        <span className="tooltip-text-booking-names">{booking.check_out || booking.check_out_date}</span>
      </div>
      <div className="tooltip-text-container">
        <span className="tooltip-text-heading">Status</span> 
       <span className="tooltip-text-booking-names" style={{textTransform:"capitalize"}}> {booking.status.replaceAll(/_/g, " ")}</span>
      </div>
    </div>
  );
};

export default Tooltip;
