import { Button, TextField } from "@shopify/polaris";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import VerticalInputFieldCustom from "../../CustomComponents/VerticalInputFieldCustom";
import { BgThemeButtonFullWidth } from "../../UI/Buttons";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";

const SignUpForm = () => {
  const initialUserData = {
    email: "",
    name: "",
    password: "",
    c_password: "",
    role: "superadmin",
  };

  const navigate = useNavigate();
  const [userData, setUserData] = useState(initialUserData);
  const [isFormValid, setIsFormValid] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  useEffect(() => {
    const isValid =
      userData.email &&
      userData.name &&
      userData.password &&
      userData.c_password &&
      userData.password === userData.c_password &&
      !passwordError;

    setIsFormValid(isValid);
  }, [userData]);

  const setUserDataHandleChange = (event) => {
    const { name, value } = event.target;
    setUserData({
      ...userData,
      [name]: value,
    });

    if (name === "c_password" && value !== userData.password) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const submitUserData = (e) => {
    e.preventDefault();
    if (!isFormValid) {
      console.log("Form is not valid");
      return;
    }
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/register`, {
      method: "POST",
      body: JSON.stringify(userData),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setUserData(initialUserData);
          navigate("/login");
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred.");
      });
  };

  return (
    <form onSubmit={(e) => submitUserData(e)}>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div>
          <VerticalInputFieldCustom
            name="email"
            onChange={(e) => setUserDataHandleChange(e)}
            value={userData.email}
            className="textfield"
            focused={false}
            autoComplete="off"
            required={true}
            titleName={"Email"}
            type={"email"}
          />
        </div>
        <div>
          <VerticalInputFieldCustom
            name="name"
            onChange={(e) => setUserDataHandleChange(e)}
            value={userData.name}
            className="textfield"
            focused={false}
            required={true}
            autoComplete="off"
            titleName={"Full Name"}
          />
        </div>

        <div>
          <VerticalInputFieldCustom
            name="password"
            onChange={(e) => setUserDataHandleChange(e)}
            value={userData.password}
            className="textfield"
            focused={false}
            required={true}
            autoComplete="off"
            titleName={"Password"}
            type={"password"}
          />
        </div>
        <div>
          <VerticalInputFieldCustom
            name="c_password"
            onChange={(e) => setUserDataHandleChange(e)}
            value={userData.c_password}
            className="textfield"
            focused={false}
            required={true}
            autoComplete="off"
            titleName={"Confirm Password"}
            type={"password"}
          />
          {passwordError && (
            <p style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
              Passwords do not match.
            </p>
          )}
        </div>

        <div style={{ marginBottom: "20px" }}>
          <BgThemeButtonFullWidth
            style={{ width: "100%" }}
            type={"submit"}
            children={"Create RevCatalyst ID"}
          />
        </div>
      </div>
    </form>
  );
};
export default SignUpForm;
