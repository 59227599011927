import React from "react";
import AddProperty from "../Components/AddProperty";
import AddPropertyNew from "../Components/Setup/AddPropertyNew";

const AddPropertyPage = () => {
  return (
    <>
      <div className="main_container">
        {/* <AddProperty /> */}
        <AddPropertyNew />
      </div>
    </>
  );
};

export default AddPropertyPage;
