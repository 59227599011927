import React from "react";
import HouseKeepingStaff from "../Components/Dashboard/HouseKeepingStaff/HouseKeepingStaff";
import HouseKeepingStaffCrud from "../Components/Settings/HouseKeepingStaffCrud";

const HousekeepingStaffPage = () => {
  return (
    <>
      <div className="main_container">
        <HouseKeepingStaffCrud />
      </div>
    </>
  );
};

export default HousekeepingStaffPage;