import React, { useState } from "react";

const DragDropListComponent = ({ data, onDataChange }) => {
  const [items, setItems] = useState(data);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("index", index.toString());
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, newIndex) => {
    const draggedIndex = parseInt(e.dataTransfer.getData("index"), 10);
    const reorderedItems = [...items];
    const draggedItem = reorderedItems[draggedIndex];
    reorderedItems.splice(draggedIndex, 1);
    reorderedItems.splice(newIndex, 0, draggedItem);
    setItems(reorderedItems);
    onDataChange(reorderedItems); 
  };

  return (
    <div>
      {items.map((item, index) => (
        <div
          key={item.id}
          draggable
          onDragStart={(e) => handleDragStart(e, index)}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, index)}
          style={{
            padding: "8px",
            marginBottom: "4px",
            border: "1px solid #ccc",
            cursor: "move",
          }}
        >
          {item.name}
        </div>
      ))}
    </div>
  );
};

export default DragDropListComponent;

// Usage
// const App = () => {
//   const initialData = [
//     { id: 1, name: "Item 1" },
//     { id: 2, name: "Item 2" },
//     { id: 3, name: "Item 3" },
//     { id: 4, name: "Item 4" },
//   ];

//   const [data, setData] = useState(initialData);

//   const handleDataChange = (updatedData) => {
//     setData(updatedData);
//     console.log("Updated Data:", updatedData);
//   };

//   return (
//     <div>
//       <CustomComponent data={data} onDataChange={handleDataChange} />
//     </div>
//   );
// };

// export default App;
