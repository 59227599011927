import React from "react";
import EventCommunicationVoucher from "../../Components/Communication/EventCommunicationVoucher/EventCommunicationVoucher";

const EventCommunicationVoucherPage = () => {
  return (
    <>
      <div className="main_container">
        <EventCommunicationVoucher />
      </div>
    </>
  );
};

export default EventCommunicationVoucherPage;
