import React from "react";

const LoaderSmall = () => {
  return (
    <div
     className="spinner_parent_small"
    >
      <div className="spinner"></div>
    </div>
  );
};

export default LoaderSmall;
