import React, { useEffect, useState } from "react";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "../../../Components/CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { CheckWithoutBgUnderlineBtn } from "../../../Components/UI/Buttons";
import BookingDetailSliderNew from "../../../Components/CustomComponents/BookingSoldSlider/BookingDetailsSlider";
import { showSuccessToast, showErrorToast } from "../../../assets/toastUtils";
import Loader from "../../../Components/UI/Loader/Loader";
import NoDataFound from "../../../Components/CustomComponents/NoDataFound";

const UserCredits = () => {
  const { ID } = useParams();
  const [data, setData] = useState([]);
  const property_id = useSelector((state) => state.property.value);
  const userToken = localStorage.getItem("token");

  const fetchAgentCredits = async () => {
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/advance/payment/transaction/${ID}/${property_id.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const data = await response.json();
      setData(data.data);
      setLoader(false);
    } catch (error) {
      console.error("Fetch agent data failed", error);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchAgentCredits();
    }
  }, [property_id]);

  const [openSingleBookingDetails, setOpenSingleBookingDetails] =
    useState(false);
  const [bookingDetails, setBookingDetails] = useState({});
  const [chargesDetails, setChargesDetails] = useState({});
  const [masterFolioDetails, setMasterFolioDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const handleBookingIdClick = (bookingId) => {
    setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${bookingId}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setOpenSingleBookingDetails(true);
          setLoader(false);
          setBookingDetails(data?.booking);
          setChargesDetails(data?.charges);
          setMasterFolioDetails(data?.masterFolio);
        } else {
          setLoader(false);
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  return (
    <div
      style={{
        width: "100%",
        padding: "10px",
        borderRadius: "10px",
        background: "#fff",
      }}
    >
      {loader ? (
        <Loader />
      ) : (
       data.length > 0  ?   <CustomShopifyIndexTable
       headings={[
         "S No.",
         "Date",
         "Folio No.",
         "Booking ID",
         "Total Credits",
         "Credit Amount",
         "Debit Amount",
         "Transaction Type",
         "Used",
         "Available",
       ]}
       style={{ border: "1px solid #ccc" }}
     >
       {data.map((item, index) => {
         return (
           <CustomShopifyIndexTableRow>
             <CustomShopifyIndexTableCell>
               {index + 1}
             </CustomShopifyIndexTableCell>
             <CustomShopifyIndexTableCell>
               {item.date}
             </CustomShopifyIndexTableCell>
             <CustomShopifyIndexTableCell>
               {item.folio_id || "NA"}
             </CustomShopifyIndexTableCell>
             <CustomShopifyIndexTableCell>
               {item.unique_booking_id ? (
                 <CheckWithoutBgUnderlineBtn
                   style={{ fontSize: "12px" }}
                   onClick={() =>
                     handleBookingIdClick(item.unique_booking_id)
                   }
                 >
                   {item.unique_booking_id}
                 </CheckWithoutBgUnderlineBtn>
               ) : (
                 "NA"
               )}
             </CustomShopifyIndexTableCell>
             <CustomShopifyIndexTableCell>
               {item.total_advance_amount || "NA"}
             </CustomShopifyIndexTableCell>
             <CustomShopifyIndexTableCell>
               {item.credit_amount || "NA"}
             </CustomShopifyIndexTableCell>
             <CustomShopifyIndexTableCell>
               {item.debit_amount || "NA"}
             </CustomShopifyIndexTableCell>
             <CustomShopifyIndexTableCell>
               {item.transaction_type || "NA"}
             </CustomShopifyIndexTableCell>
             <CustomShopifyIndexTableCell>
               {item.used_advance_amount || "NA"}
             </CustomShopifyIndexTableCell>
             <CustomShopifyIndexTableCell>
               {item.available_advance_amount || "NA"}
             </CustomShopifyIndexTableCell>
           </CustomShopifyIndexTableRow>
         );
       })}
     </CustomShopifyIndexTable> : <NoDataFound/>
      )}
      {openSingleBookingDetails && (
        <BookingDetailSliderNew
          data={bookingDetails}
          chargesDetails={chargesDetails}
          masterFolioDetails={masterFolioDetails}
          setShowBookingDetails={setOpenSingleBookingDetails}
          getSingleBookingDataHandler={handleBookingIdClick}
        />
      )}
    </div>
  );
};

export default UserCredits;
