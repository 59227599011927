import React from "react";
import AddHoldRoomType from "../Components/AddHoldRoomType";

const HoldRoomAddPage = () => {
  return (
    <>
      <div className="main_container">
        <AddHoldRoomType />
      </div>
    </>
  );
};

export default HoldRoomAddPage;
