import React from "react";
import StayView from "../Components/StayView/StayView";
import StayViewNew from "../Components/StayView/StayViewNew";

const StayViewPage = () => {
    return (
        <>
            <div className="main_container">
                {/* <StayView /> */}
                <StayViewNew />
            </div>
        </>
    );
};

export default StayViewPage;
