import React from "react";
import WebsiteMenuLocationComp from "../../Components/Websites/WebsiteMenuLocationComp";

const WebsiteMenusLocations = () => {
  return (
    <div >
      <WebsiteMenuLocationComp />
    </div>
  );
};

export default WebsiteMenusLocations;
