import React from "react";

function HandleNaN({ value, positive, currency, decimal }) {
  const numericValue = parseFloat(value);
  const formattedCost = isNaN(numericValue) ? 0 : (positive ? Math.max(0, numericValue) : numericValue);

  return <span >{currency} {decimal ? formattedCost?.toFixed(2) : formattedCost}</span>;
}

export default HandleNaN;
