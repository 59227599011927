export const FaPlay = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.125 0.25H5.875V5.875H0.25V7.125H5.875V12.75H7.125V7.125H12.75V5.875H7.125V0.25Z"
        fill="white"
      />
    </svg>
  );
};
export const FaPrint = () => {
  return <>icon</>;
};
export const FaDownload = () => {
  return <>icon</>;
};
export const FaCopy = () => {
  return <>icon</>;
};
export const FaTimes = () => {
  return <>icon</>;
};
