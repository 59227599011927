import React from "react";
import Sidebar from "../Components/Sidebar";

import EditAgentProfile from "../Components/EditAgentProfile";

import CreateBooking from "../Components/CreateBooking";
import CreateBookingNew from "../Components/CreateBookingNew";
import CreateBookingNewUi from "../Components/CustomComponents/CreateBookingNewPage/CreateBookingNewPage";
const CreateBookingPageNew = () => {
  return (
    <>
      <div className="main_container">
        <CreateBookingNewUi />
      </div>
    </>
  );
};

export default CreateBookingPageNew;
