import React, { useState, useEffect } from "react";
import "./SettlementPopup.css";
import VerticalInputSelectCustomCopy from "../VerticalInputSelectCustomCopy";
import { Page } from "@shopify/polaris";
import { showErrorToast } from "../../../assets/toastUtils";
import {
  BgThemeButton,
  BgThemeButtonDisabled,
  WithoutBgButtonBlue,
} from "../../UI/Buttons";
import UnderscoreRemoveCapitalize from "../UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import GoBackButtonCustom from "../../GoBackButtonCustom/GoBackButtonCustom";
import { formatIndianCurrency } from "../../../utils/utils";
import TextDateFormatter from "../../Communication/TextDateFormatter/TextDateFormatter";
// bookingId={bookingId}
// settlementDataFromComponent={settlementDataFromComponent}
// handleSubmitAddMeals={handleSubmitAddMeals}
// balanceAmount ={checkOutBookingDetail?.balance_amount }
const SettlementPopup = ({
  handleSubmitAddMeals,
  bookingId,
  totalRoomsAmount,
  totalHousekeepingAmount,
  totalFAndBAmount,
  totalMiscAmount,
  setShowCheckOutSettlementDetails,
  totalRoomServiceAmount,
  // balanceAmount,
  balanceAmount,
}) => {
  const role = JSON.parse(localStorage.getItem("role"));
  const creditAmount = "";
  const advance_payment_amount = "";
  const [fieldSet, setFieldSet] = useState({
    notes: "",
    date: "",
    paymentMode: "",
    amount: "",
    settlement_name: "",
    // otherSettlementType: "",
  });
  const paymentModeOptions = [
    { label: "Select Option" },
    { label: "Cash", value: "cash" },
    { label: "Bank Payment", value: "bank_payment" },
    { label: "UPI", value: "upi" },
    { label: "Credit Card", value: "credit_card" },
    { label: "Debit Card", value: "debit_card" },
  ];
  const [fieldSetsToShow, setFiledSetsToShow] = useState([]);
  const [amountsPaid, setAmountsPaid] = useState({
    totalPaidAmount: 0,
    creditPaidAmount: 0,
    advancePaidAmount: 0,
    totalRoom: 0,
    totalFBAmount: 0,
    totalHousekeepingAmount: 0,
    totalRoomServiceAmount: 0,
    TotalMiscAmount: 0,
  });
  const [areInputFieldsFilled, setAreInputFieldsFilled] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFieldSet({ ...fieldSet, [name]: value });
  };
  useEffect(() => {
    if (isFieldSetValid(fieldSet)) {
      setAreInputFieldsFilled(true);
    } else {
      setAreInputFieldsFilled(false);
    }
  }, [fieldSet, areInputFieldsFilled]);

  const handleAdd = () => {
    const validatedFieldSet = isFieldSetValid(fieldSet);
    if (validatedFieldSet) {
      // console.log('Total Paid Amount:', amountsPaid.totalPaidAmount + parseFloat(fieldSet.amount) <= balanceAmount);

      // console.log('Credit Paid Amount:', amountsPaid.creditPaidAmount +
      //   (fieldSet.paymentMode === "credit" ? parseFloat(fieldSet.amount) : 0) <= creditAmount);

      // console.log('Advance Paid Amount:', amountsPaid.advancePaidAmount +
      //   (fieldSet.paymentMode === "advance_payment" ? parseFloat(fieldSet.amount) : 0) <= advance_payment_amount);

      // console.log('Total Room Amount:', amountsPaid.totalRoom +
      //   (fieldSet.settlement_name === "room_settlement" ? parseFloat(fieldSet.amount) : 0) <= totalRoomsAmount);

      // console.log('Total Food and Beverage Amount:', amountsPaid.totalFBAmount +
      //   (fieldSet.settlement_name === "food_settlement" ? parseFloat(fieldSet.amount) : 0) <= totalFAndBAmount);

      // console.log('Total Housekeeping Amount:', amountsPaid.totalHousekeepingAmount +
      //   (fieldSet.settlement_name === "housekeeping_settlement" ? parseFloat(fieldSet.amount) : 0) <= totalHousekeepingAmount);

      // console.log('Total Room Service Amount:', amountsPaid.totalRoomServiceAmount +
      //   (fieldSet.settlement_name === "room_service_settlement" ? parseFloat(fieldSet.amount) : 0) <= totalRoomServiceAmount);

      // console.log('Total Misc Amount:', amountsPaid.TotalMiscAmount +
      //   (fieldSet.settlement_name === "misc_settlement" ? parseFloat(fieldSet.amount) : 0) <= totalMiscAmount);

      if (
        // amountsPaid.totalPaidAmount + parseFloat(fieldSet.amount) <=
        //   balanceAmount &&
        amountsPaid.creditPaidAmount +
          (fieldSet.paymentMode === "credit"
            ? parseFloat(fieldSet.amount)
            : 0) <=
          creditAmount &&
        amountsPaid.advancePaidAmount +
          (fieldSet.paymentMode === "advance_payment"
            ? parseFloat(fieldSet.amount)
            : 0) <=
          advance_payment_amount &&
        amountsPaid.totalRoom +
          (fieldSet.settlement_name === "room_settlement"
            ? parseFloat(fieldSet.amount)
            : 0) <=
          totalRoomsAmount &&
        amountsPaid.totalFBAmount +
          (fieldSet.settlement_name === "food_settlement"
            ? parseFloat(fieldSet.amount)
            : 0) <=
          totalFAndBAmount &&
        amountsPaid.totalHousekeepingAmount +
          (fieldSet.settlement_name === "housekeeping_settlement"
            ? parseFloat(fieldSet.amount)
            : 0) <=
          totalHousekeepingAmount &&
        amountsPaid.totalRoomServiceAmount +
          (fieldSet.settlement_name === "room_service_settlement"
            ? parseFloat(fieldSet.amount)
            : 0) <=
          totalRoomServiceAmount &&
        amountsPaid.TotalMiscAmount +
          (fieldSet.settlement_name === "misc_settlement"
            ? parseFloat(fieldSet.amount)
            : 0) <=
          totalMiscAmount
      ) {
        setFiledSetsToShow([...fieldSetsToShow, fieldSet]);
        // Reset the field set
        setFieldSet({
          notes: "",
          date: "",
          paymentMode: "",
          amount: "",
          settlement_name: "",
          otherSettlementType: "",
        });
      } else {
        if (
          amountsPaid.advancePaidAmount +
            (fieldSet.paymentMode === "advance_payment"
              ? parseFloat(fieldSet.amount)
              : 0) >
          advance_payment_amount
        ) {
          showErrorToast("Paid amount cannot exceed Advanced amount");
        }
        if (
          amountsPaid.totalRoom +
            (fieldSet.settlement_name === "room_settlement"
              ? parseFloat(fieldSet.amount)
              : 0) >
          totalRoomsAmount
        ) {
          showErrorToast(
            "Room settlement amount cannot exceed the total room settlement."
          );
        }
        if (
          amountsPaid.totalFBAmount +
            (fieldSet.settlement_name === "food_settlement"
              ? parseFloat(fieldSet.amount)
              : 0) >
          totalFAndBAmount
        ) {
          showErrorToast(
            "F&B settlement amount cannot exceed the total F&B settlement."
          );
        }
        if (
          amountsPaid.totalHousekeepingAmount +
            (fieldSet.settlement_name === "housekeeping_settlement"
              ? parseFloat(fieldSet.amount)
              : 0) >
          totalHousekeepingAmount
        ) {
          showErrorToast(
            "Housekeeping settlement amount cannot exceed the total Housekeeping settlement."
          );
        }
        if (
          amountsPaid.totalRoomServiceAmount +
            (fieldSet.settlement_name === "room_service_settlement"
              ? parseFloat(fieldSet.amount)
              : 0) >
          totalRoomServiceAmount
        ) {
          showErrorToast(
            "Room SERVICE settlement amount cannot exceed the total ROOM SERVICE settlement."
          );
        }
        if (
          amountsPaid.TotalMiscAmount +
            (fieldSet.settlement_name === "misc_settlement"
              ? parseFloat(fieldSet.amount)
              : 0) >
          totalMiscAmount
        ) {
          showErrorToast(
            "MISC settlement amount cannot exceed the total MISC settlement."
          );
        }
        if (
          amountsPaid.creditPaidAmount +
            (fieldSet.paymentMode === "credit"
              ? parseFloat(fieldSet.amount)
              : 0) >
          creditAmount
        ) {
          showErrorToast("Credit amount cannot exceed credit amount");
        }
        // if (
        //   amountsPaid.totalPaidAmount + parseFloat(fieldSet.amount) >
        //   balanceAmount
        // ) {
        //   showErrorToast("Paid amount cannot exceed balance amount");
        // }
      }
      setAreInputFieldsFilled(false);
    } else {
      showErrorToast("Please fill in all fields");
    }
  };
  const isFieldSetValid = (fieldSet) => {
    if (fieldSet.settlement_name === "other") {
      return (
        fieldSet.paymentMode &&
        fieldSet.notes &&
        fieldSet.date &&
        fieldSet.amount &&
        fieldSet.otherSettlementType
      );
    } else {
      return (
        fieldSet.paymentMode &&
        fieldSet.notes &&
        fieldSet.date &&
        fieldSet.amount &&
        fieldSet.settlement_name
      );
    }
  };
  useEffect(() => {
    const calculateTotalPaidAmount = (qwerty) => {
      return qwerty.reduce(
        (total, fieldSet) => total + (parseFloat(fieldSet.amount) || 0),
        0
      );
    };
    const totalAdvancePaidAmount = calculateTotalPaidAmount(
      fieldSetsToShow.filter((set) => set.paymentMode === "advance_payment")
    );
    const totalAmountPaid = calculateTotalPaidAmount(fieldSetsToShow);
    const totalCreditPaidAmount = calculateTotalPaidAmount(
      fieldSetsToShow.filter((set) => set.paymentMode === "credit")
    );
    const totalFBAmount = calculateTotalPaidAmount(
      fieldSetsToShow.filter((set) => set.settlement_name === "food_settlement")
    );
    const totalRoomAmount = calculateTotalPaidAmount(
      fieldSetsToShow.filter((set) => set.settlement_name === "room_settlement")
    );
    const TotalMiscAmount = calculateTotalPaidAmount(
      fieldSetsToShow.filter((set) => set.settlement_name === "misc_settlement")
    );
    const totalRoomServiceAmount = calculateTotalPaidAmount(
      fieldSetsToShow.filter(
        (set) => set.settlement_name === "room_service_settlement"
      )
    );
    const totalHousekeepingAmount = calculateTotalPaidAmount(
      fieldSetsToShow.filter(
        (set) => set.settlement_name === "housekeeping_settlement"
      )
    );
    setAmountsPaid({
      totalHousekeepingAmount,
      advancePaidAmount: totalAdvancePaidAmount,
      totalFBAmount,
      totalRoom: totalRoomAmount,
      totalPaidAmount: totalAmountPaid,
      TotalMiscAmount,
      totalRoomServiceAmount,
      creditPaidAmount: totalCreditPaidAmount,
    });
  }, [
    fieldSetsToShow,
    balanceAmount,
    creditAmount,
    advance_payment_amount,
    amountsPaid.TotalMiscAmount,
    amountsPaid.advancePaidAmount,
    amountsPaid.creditPaidAmount,
    amountsPaid.totalPaidAmount,
    amountsPaid.totalRoom,
    amountsPaid.totalRoomServiceAmount,
  ]);
  const settlementNameOptions = [
    { label: "Select Option", value: "" },
    { label: "Room Settlement", value: "ROOM" },
    { label: "F&B Settlement", value: "F&B" },
    { label: "Housekeeping", value: "HOUSEKEEPING" },
    { label: "ROOM SERVICE ", value: "ROOM SERVICE" },
    { label: "MISC", value: "MISC" },
  ];
  const paymentModeOptionsForAgents = [
    { label: "Select Option", value: "" },
    { label: "Cash", value: "cash" },
    { label: "Bank Payment", value: "bank_payment" },
    { label: "UPI", value: "upi" },
    { label: "Credit Card", value: "credit_card" },
    { label: "Debit Card", value: "debit_card" },
    { label: "Company Credits", value: "credit" },
    { label: "Advance Payments", value: "advance_payment" },
    { label: "Other", value: "other" },
  ];
  const handleRemove = (index) => {
    setFiledSetsToShow(fieldSetsToShow.filter((_, i) => i !== index));
  };
  return (
    <div className="bg-backdrop">
      <div style={{ width: "75%" }} className="settlementPopUpWrapper">
        {fieldSetsToShow && (
          <div className="">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "20px",
                paddingBottom: "0px",
              }}
            >
              <div>
                <GoBackButtonCustom
                  buttonNextText={"Settlement"}
                  onClick={() => setShowCheckOutSettlementDetails(false)}
                />
              </div>
              <div></div>
            </div>
          </div>
        )}
        <div>
          <div className="flex" style={{ border: "1px solid #ccc" }}>
            {totalRoomsAmount && totalRoomsAmount != 0 && (
              <div style={{ flexBasis: "20%", padding: "10px 10px 0px 20px" }}>
                <div className="settlementValuesHeading">ROOM</div>
                <div className="flex justify_content_between align_items_center">
                  <div>Paid Amt.</div>
                  <div>{formatIndianCurrency(amountsPaid.totalRoom)}</div>
                </div>
                <div className="flex justify_content_between align_items_center">
                  <div>Due Amt.</div>
                  <div>{formatIndianCurrency(totalRoomsAmount)}</div>
                </div>
                <div className="flex justify_content_between align_items_center">
                  <div>Pending Balance</div>
                  <div>
                    {" "}
                    {formatIndianCurrency(
                      totalRoomsAmount - amountsPaid.totalRoom || 0
                    )}
                  </div>
                </div>
              </div>
            )}
            {totalFAndBAmount && totalFAndBAmount != 0 ? (
              <div
                style={{
                  flexBasis: "20%",
                  padding: "10px 10px 0px 10px",
                  borderLeft: "1px solid #ccc",
                }}
              >
                <div className="settlementValuesHeading">F&B</div>
                <div className="flex justify_content_between align_items_center">
                  <div>Paid Amt.</div>
                  <div>
                    {formatIndianCurrency(amountsPaid.totalFBAmount || 0)}
                  </div>
                </div>
                <div className="flex justify_content_between align_items_center">
                  <div>Due Amt.</div>
                  <div>{formatIndianCurrency(totalFAndBAmount || 0)}</div>
                </div>
                <div className="flex justify_content_between align_items_center">
                  <div>Pending Balance</div>
                  <div>
                    {formatIndianCurrency(
                      totalFAndBAmount - amountsPaid.totalFBAmount || 0
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {totalHousekeepingAmount && totalHousekeepingAmount != 0 ? (
              <div
                style={{
                  flexBasis: "20%",
                  padding: "10px 10px 0px 10px",
                  borderLeft: "1px solid #ccc",
                }}
              >
                <div className="settlementValuesHeading">HOUSEKEEPING</div>
                <div className="flex justify_content_between align_items_center">
                  <div>Paid Amt.</div>
                  <div>
                    {" "}
                    {formatIndianCurrency(
                      amountsPaid.totalHousekeepingAmount || 0
                    )}
                  </div>
                </div>
                <div className="flex justify_content_between align_items_center">
                  <div>Due Amt.</div>
                  <div>
                    {formatIndianCurrency(totalHousekeepingAmount || 0)}
                  </div>
                </div>
                <div className="flex justify_content_between align_items_center">
                  <div>Pending Balance</div>
                  <div>
                    {" "}
                    {formatIndianCurrency(
                      totalHousekeepingAmount -
                        amountsPaid.totalHousekeepingAmount || 0
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {totalRoomServiceAmount && totalRoomServiceAmount != 0 ? (
              <div
                style={{
                  flexBasis: "20%",
                  padding: "10px 10px 0px 10px",
                  borderLeft: "1px solid #ccc",
                }}
              >
                <div className="settlementValuesHeading">ROOM SERVICE</div>
                <div className="flex justify_content_between align_items_center">
                  <div>Paid Amt.</div>
                  <div>
                    {" "}
                    {formatIndianCurrency(
                      amountsPaid.totalRoomServiceAmount || 0
                    )}
                  </div>
                </div>
                <div className="flex justify_content_between align_items_center">
                  <div>Due Amt.</div>
                  <div>{formatIndianCurrency(totalRoomServiceAmount || 0)}</div>
                </div>
                <div className="flex justify_content_between align_items_center">
                  <div>Pending Balance</div>
                  <div>
                    {" "}
                    {formatIndianCurrency(
                      totalRoomServiceAmount -
                        amountsPaid.totalRoomServiceAmount || 0
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {totalRoomServiceAmount && totalRoomServiceAmount != 0 ? (
              <div
                style={{
                  flexBasis: "20%",
                  padding: "10px 20px 10px 10px",
                  borderLeft: "1px solid #ccc",
                }}
              >
                <div className="settlementValuesHeading">MISC.</div>
                <div className="flex justify_content_between align_items_center">
                  <div>Paid Amt.</div>
                  <div>
                    {" "}
                    {formatIndianCurrency(amountsPaid.TotalMiscAmount || 0)}
                  </div>
                </div>
                <div className="flex justify_content_between align_items_center">
                  <div>Due Amt.</div>
                  <div>{formatIndianCurrency(totalMiscAmount || 0)}</div>
                </div>
                <div className="flex justify_content_between align_items_center">
                  <div>Pending Balance</div>
                  <div>
                    {" "}
                    {formatIndianCurrency(
                      totalMiscAmount - amountsPaid.TotalMiscAmount || 0
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div style={{ padding: "20px" }}>
          <table className="settlementTable">
            <thead>
              <tr>
                <th className="settlementBodyLabel">Settlement Name</th>
                <th className="settlementBodyLabel">Payment Mode</th>
                <th className="settlementBodyLabel">Date</th>
                <th className="settlementBodyLabel">Notes</th>
                <th className="settlementBodyLabel">Amt.</th>
                <th className="settlementBodyLabel"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <select
                    className="settlementBodyInput"
                    name="settlement_name"
                    value={fieldSet.settlement_name || ""}
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value={"Select Option"}>Select Option</option>
                    {totalRoomsAmount && totalRoomsAmount != 0 ? (
                      <option value={"room_settlement"}>Room Settlement</option>
                    ) : null}
                    {totalHousekeepingAmount && totalHousekeepingAmount != 0 ? (
                      <option value={"housekeeping_settlement"}>
                        Housekeeping Settlement
                      </option>
                    ) : null}
                    {totalFAndBAmount && totalFAndBAmount != 0 ? (
                      <option value={"food_settlement"}>Food Settlement</option>
                    ) : null}
                    {totalRoomServiceAmount && totalRoomServiceAmount != 0 ? (
                      <option value={"room_service_settlement"}>
                        Room & Service Settlement
                      </option>
                    ) : null}
                    {totalMiscAmount && totalMiscAmount != 0 ? (
                      <option value={"misc_settlement"}>Misc Settlement</option>
                    ) : null}
                  </select>
                  {fieldSet.settlement_name.toLowerCase() === "other" && (
                    <input
                      type="text"
                      className="settlementBodyInput"
                      name="otherSettlementType"
                      value={fieldSet.otherSettlementType || ""}
                      onChange={(e) => handleInputChange(e)}
                    />
                  )}
                </td>
                <td>
                  <select
                    className="settlementBodyInput"
                    name="paymentMode"
                    value={fieldSet.paymentMode || ""}
                    onChange={(e) => handleInputChange(e)}
                  >
                    {/* {
                      role && role === "agent"
                        ? paymentModeOptionsForAgents
                        : paymentModeOptions
                    } */}
                    {paymentModeOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <input
                    type="date"
                    name="date"
                    placeholder="Enter Date"
                    className="settlementBodyInput"
                    value={fieldSet.date || ""}
                    onChange={(e) => handleInputChange(e)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="Enter Notes"
                    className="settlementBodyInput"
                    name="notes"
                    value={fieldSet.notes || ""}
                    onChange={(e) => handleInputChange(e)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="amount"
                    placeholder="Enter Amt."
                    className="settlementBodyInput"
                    value={fieldSet.amount || ""}
                    onChange={(e) => handleInputChange(e)}
                  />
                </td>
                <td className="settlementBodyButtonContainer">
                  <span>
                    <button
                      onClick={handleAdd}
                      className="settlementBodyButton"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_1270_978)">
                          <path
                            d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                            stroke="#3968ED"
                          ></path>
                          <path
                            d="M10.5866 7H3.41333"
                            stroke="#3968ED"
                            strokeMiterlimit="10"
                          ></path>
                          <path
                            d="M7 3.41344L7 10.5867"
                            stroke="#3968ED"
                            strokeMiterlimit="10"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_1270_978">
                            <rect width="14" height="14" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </span>
                </td>
              </tr>
              <tr>
                <td colSpan={6} style={{ height: "1px", padding: "10px 0" }}>
                  <hr />
                </td>
              </tr>
              {fieldSetsToShow?.map((set, index) => (
                <tr key={index}>
                  <td className="settlementTableFieldSetToShowTableData">
                    <UnderscoreRemoveCapitalize text={set.settlement_name} />{" "}
                    {set.settlement_name.toLowerCase() === "other" ? (
                      <>, {set.otherSettlementType}</>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="settlementTableFieldSetToShowTableData">
                    <UnderscoreRemoveCapitalize text={set.paymentMode} />
                  </td>
                  <td className="settlementTableFieldSetToShowTableData">
                    <TextDateFormatter
                      showYear={true}
                      as={"div"}
                      date={set.date}
                    />
                  </td>
                  <td className="settlementTableFieldSetToShowTableData">
                    {set.notes}
                  </td>
                  <td className="settlementTableFieldSetToShowTableData">
                    {formatIndianCurrency(set.amount)}
                  </td>
                  <td>
                    <WithoutBgButtonBlue
                      className="settlementBodyButton"
                      onClick={() => handleRemove(index)}
                      style={{ fontSize: "12px" }}
                    >
                      remove
                    </WithoutBgButtonBlue>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {/* {parseInt(settlementAddData.amount) ===
            parseInt(checkOutBookingDetail?.balance_amount) ? ( */}
            <div
              style={{
                marginTop: "0px",
                marginLeft: "20px",
                marginBottom: "20px",
              }}
            >
              {fieldSetsToShow.length > 0 ? (
                <BgThemeButton
                  onClick={() =>
                    handleSubmitAddMeals(
                      fieldSetsToShow,
                      amountsPaid.totalPaidAmount,
                      balanceAmount - amountsPaid.totalPaidAmount
                    )
                  }
                  type="submit"
                  children={"Settle"}
                />
              ) : (
                <BgThemeButtonDisabled
                  // onClick={(e) => handleSubmitAddMeals(e)}
                  type="submit"
                  children={"Settle"}
                  disabled={true}
                />
              )}
              {/* <BgThemeButton
              onClick={() =>
                handleSubmitAddMeals(
                  fieldSetsToShow,
                  amountsPaid.totalPaidAmount,
                  balanceAmount -  amountsPaid.totalPaidAmount,
                  )
                }
                type="submit"
                children={"Settle"}
                /> */}
            </div>
            {/* // ) : (
            //   <BgThemeButtonDisabled
            //     // onClick={(e) => handleSubmitAddMeals(e)}
            //     type="submit"
            //     children={"Settle"}
            //     disabled={true}
            //   />
            // )} */}
            {/* <div
              style={{
                color: "#3968ED",
                textDecoration: "underline",
                textUnderlineOffset: "3px",
                cursor: "pointer",
              }}
              onClick={() => {
                setShowCheckOutBookingDetails(true);
                setShowCheckOutSettlementDetails(false);
                setSettlementAddData({
                  settlement_name: "",
                  payment_mode: "",
                  notes: "",
                  date: "",
                  amount: "",
                  property_id: property_id?.id,
                  other_payment_mode: "",
                });
              }}
            >
              Go back to check-out details
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SettlementPopup;
