import React from "react";
import EditRoomsType from "../Components/EditRoomsType";

const RoomTypeEditPage = () => {
  return (
    <>
      <div className="main_container">
        <EditRoomsType />
      </div>
    </>
  );
};

export default RoomTypeEditPage;
