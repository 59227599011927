import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { BgOutlineThemeButton, BgThemeButton } from "../../../UI/Buttons";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../assets/toastUtils";
import VerticalInputFieldCustom from "../../VerticalInputFieldCustom.jsx";
import {
  grandTotalForInclusive,
  grandTotalForExclusive,
  taxAmountForInclusive,
  taxAmountForExclusive,
  calculateSubtotalForInclusive,
  calculateSubtotalForExclusive,
} from "../calculationFunctions/CalculationFunctions.jsx";
import {
  calculateTotal,
  calculateNumberOfNights,
} from "../calculationFunctions/CalculateKeyTotalFromArrayOfObjects";
import { getDecryptedData } from "../../../../utils/encryptStorage.js";

const ModifyReservationArrivalsPaxChange = ({
  bookingData,
  checkEmptyValuesInObjects,
  propertyCurrentDate,
  addOnCost,
  reasons,
  setReasons,
  setShowModifyPopup,
  handleReasonChange,
  getBookingData,
}) => {
  const [loader,setLoader] = useState(false);
  const property_id = useSelector((state) => state.property.value);
  const userToken = getDecryptedData('encryptToken');
  const navigate = useNavigate();

  //====================pax change==========================

  const [paxes, setPaxes] = useState([]);

  useEffect(() => {
    if (bookingData && bookingData.items) {
      const newEditedRoomCategory = bookingData?.items?.map((item) => ({
        booking_id: item.id,
        room_id: item.room_id,
        rate_plan_id: item.rate_plan_id,
        adults: item.adults,
        children: item.children,
        no_child: item.no_child,
        no_adult: item.no_adult,
        tax_type: item.tax_type,
        tax_rate: item.tax_rate,
        no_of_rooms: item.no_of_rooms,
        no_of_nights: item.no_nights,
        room_rate_per_night: item.room_rate,
        category: item.room_type_name,
        extraAdult: item.extra_adult,
        paxes: item.paxes,
        newExtraAdult: "",
        newExtraAdultCost: "",
        discount_amount: item.discount_amount,
      }));

      setPaxes(newEditedRoomCategory);
    }
  }, [bookingData]);

  const handleModifyPaxExtraAdult = (e, index, item) => {
    const { name, value } = e.target;
    if (+value > item?.limits?.allowedExtraAdult * +item.no_of_rooms) {
      showErrorToast("Not allowed, please increase the number of rooms");
      return;
    }

    setPaxes((prevPaxes) => {
      const updatedPaxes = [...prevPaxes];
      updatedPaxes[index] = {
        ...updatedPaxes[index],
        [name]: value,
      };
      return updatedPaxes;
    });

    setShowPaxCost(false);
    setPaxTotal(0);
  };

  const handleModifyPaxExtraAdultCost = (e, index) => {
    const { name, value } = e.target;
    setPaxes((prevPaxes) => {
      const updatedPaxes = [...prevPaxes];
      updatedPaxes[index] = {
        ...updatedPaxes[index],
        [name]: value,
      };
      return updatedPaxes;
    });
    setShowPaxCost(false);
    setPaxTotal(0);
    return; // Exit the function early
  };
  const [showPaxCost, setShowPaxCost] = useState(false);
  const [paxTotal, setPaxTotal] = useState(0);

  const [enabledPaxes, setEnabledPaxes] = useState([]);

  const handlePaxCalculation = () => {
    const keysToCheck = ["newExtraAdult", "newExtraAdultCost"];
    if (checkEmptyValuesInObjects(paxes, keysToCheck, enabledPaxes)) {
      return;
    }
    const extractPrices = (paxes) => {
      let childPrice = 0;
      let extraAdultPrice = 0;

      if (Array.isArray(paxes)) {
        paxes.forEach((pax) => {
          if (pax.pax_type === "child") {
            childPrice += parseFloat(pax.price); // Accumulate child prices
          } else if (pax.pax_type === "adult") {
            extraAdultPrice += parseFloat(pax.price); // Accumulate adult prices
          }
        });
      } else {
        console.error("Invalid paxes data:", paxes);
      }

      return { childPrice, extraAdultPrice };
    };

    const oldNights = calculateNumberOfNights(
      new Date(bookingData.check_in),
      new Date(propertyCurrentDate) // This is the current date
    );

    const newNights = calculateNumberOfNights(
      new Date(propertyCurrentDate),
      new Date(bookingData.check_out)
    );

    const rooms = paxes.map((item) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);
      const taxType = item.tax_type;
      const taxRate = item.tax_rate;
      const ratePerNight = item.room_rate_per_night;
      const totalDiscount =
        (+item.discount_amount / +item.no_of_nights) * +oldNights;

      const extraAdultCost = extraAdultPrice;

      return {
        BookingItemId: item.booking_id,
        RoomID: item.room_id,
        RatePlanID: item.rate_plan_id,
        baseAdult: item.no_adult,
        Child: item.no_child,
        extraAdult: item.extraAdult,
        Rate: ratePerNight,
        TaxRate: item.tax_rate,
        RoomDiscount: totalDiscount,
        NetTotal:
          taxType === "inclusive"
            ? grandTotalForInclusive(
                +ratePerNight,
                +oldNights,

                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultCost,
                +totalDiscount
              )
            : grandTotalForExclusive(
                +ratePerNight,
                +oldNights,

                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultCost,
                +taxRate,
                +totalDiscount
              ),
        TaxAmount:
          taxType === "inclusive"
            ? taxAmountForInclusive(
                +ratePerNight,
                +oldNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultCost,
                +taxRate,
                +totalDiscount
              )
            : taxAmountForExclusive(
                +ratePerNight,
                +oldNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultCost,
                +taxRate,
                +totalDiscount
              ),
        TaxbleTotal:
          taxType === "inclusive"
            ? calculateSubtotalForInclusive(
                +ratePerNight,
                +oldNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultCost,
                +taxRate,
                +totalDiscount
              )
            : calculateSubtotalForExclusive(
                +ratePerNight,
                +oldNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultCost,
                +totalDiscount
              ),
      };
    });

    const roomsNew = paxes.map((item) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);
      const taxType = item.tax_type;
      const taxRate = item.tax_rate;
      const ratePerNight = item.room_rate_per_night;
      const extraAdult = item.newExtraAdult ? item.newExtraAdult : 1;

      const extraAdultCost = item.newExtraAdult
        ? item.newExtraAdultCost
        : extraAdultPrice;

      const totalDiscount =
        (+item.discount_amount / +item.no_of_nights) * +newNights;

      return {
        BookingItemId: item.booking_id,
        RoomID: item.room_id,
        RatePlanID: item.rate_plan_id,
        baseAdult: item.no_adult,
        Child: item.no_child,
        extraAdult: item.newExtraAdult ? item.newExtraAdult : item.extraAdult,
        Rate: ratePerNight,
        TaxRate: item.tax_rate,
        RoomDiscount: totalDiscount,
        NetTotal:
          taxType === "inclusive"
            ? grandTotalForInclusive(
                +ratePerNight,
                +newNights,

                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +totalDiscount
              )
            : grandTotalForExclusive(
                +ratePerNight,
                +newNights,

                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +taxRate,
                +totalDiscount
              ),
        TaxAmount:
          taxType === "inclusive"
            ? taxAmountForInclusive(
                +ratePerNight,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +taxRate,
                +totalDiscount
              )
            : taxAmountForExclusive(
                +ratePerNight,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +taxRate,
                +totalDiscount
              ),
        TaxbleTotal:
          taxType === "inclusive"
            ? calculateSubtotalForInclusive(
                +ratePerNight,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +taxRate,
                +totalDiscount
              )
            : calculateSubtotalForExclusive(
                +ratePerNight,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +totalDiscount
              ),
      };
    });

    function mergeRoomArrays(rooms, roomsNew) {
      if (rooms.length !== roomsNew.length) {
        throw new Error(
          "The lengths of the rooms and roomsNew arrays do not match."
        );
      }

      return roomsNew.map((roomNew, index) => {
        const room = rooms[index];

        return {
          ...roomNew,
          RoomDiscount: +room.RoomDiscount + +roomNew.RoomDiscount,
          NetTotal: +room.NetTotal + +roomNew.NetTotal,
          TaxAmount: +room.TaxAmount + +roomNew.TaxAmount,
          TaxbleTotal: +room.TaxbleTotal + +roomNew.TaxbleTotal,
        };
      });
    }

    const mergedArray = mergeRoomArrays(rooms, roomsNew);

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "update_adult",
      bookingNetTotal:
        +calculateTotal(mergedArray, "NetTotal") + +addOnCost.NetTotal,
      bookingTaxableAmount:
        +calculateTotal(mergedArray, "TaxbleTotal") + addOnCost.taxableAmount,
      bookingTaxAmount:
        +calculateTotal(mergedArray, "TaxAmount") + addOnCost.taxAmount,
      bookingDiscountAmount:
        +bookingData.total_discount_amount + +addOnCost.discountAmount,
      bookingDueAmount:
        +(+calculateTotal(mergedArray, "NetTotal") + +addOnCost.NetTotal) -
        +bookingData.paid_amount,
      paidAmount: bookingData.paid_amount,
      Room: mergedArray,
      requestReason: reasons.paxDetailReason,
    };
    setPaxTotal(requestBody.bookingNetTotal);
    setShowPaxCost(true);
  };

  const handlePaxCalculationSubmit = async () => {
    if (reasons.paxDetailReason === "") {
      showErrorToast("Please enter a reason for changing pax details");
      return;
    }
    const extractPrices = (paxes) => {
      let childPrice = 0;
      let extraAdultPrice = 0;

      if (Array.isArray(paxes)) {
        paxes.forEach((pax) => {
          if (pax.pax_type === "child") {
            childPrice += parseFloat(pax.price);
          } else if (pax.pax_type === "adult") {
            extraAdultPrice += parseFloat(pax.price);
          }
        });
      } else {
        console.error("Invalid paxes data:", paxes);
      }

      return { childPrice, extraAdultPrice };
    };

    const oldNights = calculateNumberOfNights(
      new Date(bookingData.check_in),
      new Date(propertyCurrentDate) // This is the current date
    );

    const newNights = calculateNumberOfNights(
      new Date(propertyCurrentDate),
      new Date(bookingData.check_out)
    );

    const rooms = paxes.map((item) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);
      const taxType = item.tax_type;
      const taxRate = item.tax_rate;
      const ratePerNight = item.room_rate_per_night;
      const extraAdult = item.extraAdult;

      const totalDiscount =
        (+item.discount_amount / +item.no_of_nights) * +oldNights;
      const extraAdultCost = extraAdultPrice;

      return {
        BookingItemId: item.booking_id,
        RoomID: item.room_id,
        RatePlanID: item.rate_plan_id,
        baseAdult: item.no_adult,
        Child: item.no_child,
        extraAdult: item.extraAdult,
        Rate: ratePerNight,
        TaxRate: item.tax_rate,
        RoomDiscount: totalDiscount,
        NetTotal:
          taxType === "inclusive"
            ? grandTotalForInclusive(
                +ratePerNight,
                +oldNights,

                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +totalDiscount
              )
            : grandTotalForExclusive(
                +ratePerNight,
                +oldNights,

                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +taxRate,
                +totalDiscount
              ),
        TaxAmount:
          taxType === "inclusive"
            ? taxAmountForInclusive(
                +ratePerNight,
                +oldNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +taxRate,
                +totalDiscount
              )
            : taxAmountForExclusive(
                +ratePerNight,
                +oldNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +taxRate,
                +totalDiscount
              ),
        TaxbleTotal:
          taxType === "inclusive"
            ? calculateSubtotalForInclusive(
                +ratePerNight,
                +oldNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +taxRate,
                +totalDiscount
              )
            : calculateSubtotalForExclusive(
                +ratePerNight,
                +oldNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +totalDiscount
              ),
      };
    });

    const roomsNew = paxes.map((item) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);
      const taxType = item.tax_type;
      const taxRate = item.tax_rate;
      const ratePerNight = item.room_rate_per_night;
      const extraAdult = item.newExtraAdult ? item.newExtraAdult : 1;

      const extraAdultCost = item.newExtraAdult
        ? item.newExtraAdultCost
        : extraAdultPrice;

      const totalDiscount =
        (+item.discount_amount / +item.no_of_nights) * +newNights;

      return {
        BookingItemId: item.booking_id,
        RoomID: item.room_id,
        RatePlanID: item.rate_plan_id,
        baseAdult: item.no_adult,
        Child: item.no_child,
        extraAdult: item.newExtraAdult ? item.newExtraAdult : item.extraAdult,
        Rate: ratePerNight,
        TaxRate: item.tax_rate,
        RoomDiscount: totalDiscount,
        NetTotal:
          taxType === "inclusive"
            ? grandTotalForInclusive(
                +ratePerNight,
                +newNights,

                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +totalDiscount
              )
            : grandTotalForExclusive(
                +ratePerNight,
                +newNights,

                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +taxRate,
                +totalDiscount
              ),
        TaxAmount:
          taxType === "inclusive"
            ? taxAmountForInclusive(
                +ratePerNight,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +taxRate,
                +totalDiscount
              )
            : taxAmountForExclusive(
                +ratePerNight,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +taxRate,
                +totalDiscount
              ),
        TaxbleTotal:
          taxType === "inclusive"
            ? calculateSubtotalForInclusive(
                +ratePerNight,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +taxRate,
                +totalDiscount
              )
            : calculateSubtotalForExclusive(
                +ratePerNight,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +extraAdult,
                +extraAdultCost,
                +totalDiscount
              ),
      };
    });

    function mergeRoomArrays(rooms, roomsNew) {
      if (rooms.length !== roomsNew.length) {
        throw new Error(
          "The lengths of the rooms and roomsNew arrays do not match."
        );
      }

      return roomsNew.map((roomNew, index) => {
        const room = rooms[index];

        return {
          ...roomNew,
          RoomDiscount: +room.RoomDiscount + +roomNew.RoomDiscount,
          NetTotal: +room.NetTotal + +roomNew.NetTotal,
          TaxAmount: +room.TaxAmount + +roomNew.TaxAmount,
          TaxbleTotal: +room.TaxbleTotal + +roomNew.TaxbleTotal,
        };
      });
    }

    const mergedArray = mergeRoomArrays(rooms, roomsNew);

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "update_adult",
      bookingNetTotal:
        +calculateTotal(mergedArray, "NetTotal") + addOnCost.NetTotal,
      bookingTaxableAmount:
        +calculateTotal(mergedArray, "TaxbleTotal") + +addOnCost.taxableAmount,
      bookingTaxAmount:
        +calculateTotal(mergedArray, "TaxAmount") + +addOnCost.taxAmount,
      bookingDiscountAmount:
        +bookingData.total_discount_amount + +addOnCost.discountAmount,
      bookingDueAmount:
        +(calculateTotal(mergedArray, "NetTotal") + addOnCost.NetTotal) -
        +bookingData.paid_amount,
      paidAmount: bookingData.paid_amount,
      Room: mergedArray,
      requestReason: reasons.paxDetailReason,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(requestBody),
    };

    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modify/adult/${property_id.id}`,
        requestOptions
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      showSuccessToast("Guest Details updated Successfully");
      setShowPaxCost(false);
      setPaxTotal(0);
      // fetchBookingDetails();
      getBookingData(bookingData.unique_booking_id);
      setShowModifyPopup(false);
      setLoader(false);
      return data;
    } catch (error) {
      console.error(error);
      showErrorToast(error.message); 
      setLoader(false);
      return null;
    }
  };

  const handlePaxChangeDisableEnable = async (e, index, room_id) => {
    const checked = e.target.checked;
    if (checked) {
      try {
        setLoader(true)
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/check/availability/${property_id?.id}?check_in=${bookingData.check_in}&check_out=${bookingData.check_out}&room_id=${room_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setPaxes((prevPaxes) => {
          const updatedPaxes = [...prevPaxes];
          updatedPaxes[index] = {
            ...prevPaxes[index],
            limits: data,
          };
          return updatedPaxes;
        });

        if (data.message === "Unauthorised.") {
          navigate("/");
        }
      } catch (error) {
        console.error(error);
      }

      setEnabledPaxes((prevState) => [...prevState, index]);
      setLoader(false)
    } else {
      setEnabledPaxes((prevState) =>
        prevState.filter((item) => item !== index)
      );
      setPaxes((prevPaxes) => {
        const updatedPaxes = [...prevPaxes];
        updatedPaxes[index] = {
          ...prevPaxes[index],
          limits: null,
          newExtraAdult: "",
          newExtraAdultCost: "",
        };
        return updatedPaxes;
      });
    }
    setPaxTotal(0);
    setShowPaxCost(false);
  };

  
  return (
    <from>
      <table
        className="modifyReservationPopupTable"
        style={{ borderSpacing: "10px", width: "80%" }}
      >
        <thead>
          <tr>
            <th></th>
            <th>Room Category</th>
            <th>Extra Adults</th>
            <th>Change To</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          {paxes.map((item, index) => (
            <tr key={index}>
              <td
                style={{
                  verticalAlign: "bottom",
                  paddingBottom: "10px",
                }}
              >
                <input
                  type="checkbox"
                  style={{ cursor: "pointer" }}
                  onChange={(e) =>
                    handlePaxChangeDisableEnable(e, index, item.room_id)
                  }
                  checked={enabledPaxes.includes(index)}
                />
              </td>
              <td>
                <VerticalInputFieldCustom
                  value={item?.category?.toUpperCase()}
                  disabled={true}
                />
              </td>
              <td>
                <VerticalInputFieldCustom
                  value={item.extraAdult}
                  disabled={true}
                />
              </td>
              <td>
                <VerticalInputFieldCustom
                  value={item.newExtraAdult}
                  type={"number"}
                  name="newExtraAdult"
                  disabled={!enabledPaxes.includes(index)}
                  onChange={(e) => handleModifyPaxExtraAdult(e, index, item)}
                />
              </td>
              <td>
                <VerticalInputFieldCustom
                  name="newExtraAdultCost"
                  value={item.newExtraAdultCost}
                  type={"number"}
                  disabled={!enabledPaxes.includes(index)}
                  onChange={(e) => handleModifyPaxExtraAdultCost(e, index)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="modify_reservation_reason_container">
        <VerticalInputFieldCustom
          titleName={"Reason to Modify*"}
          name="paxDetailReason"
          // guestDetailsReason
          value={reasons.paxDetailReason}
          onChange={handleReasonChange}
        />

        <div className="modify_reservation_reason_container flex flex_gap_10 align_items_center">
          <label>New Total:</label>

          <VerticalInputFieldCustom value={paxTotal} disabled={true} />

          <div>Previous Total : {bookingData?.grand_total}</div>
        </div>
      </div>

      <div className="flex flex_gap_10">
        <BgThemeButton
          children={!showPaxCost ? "Calculate" : "Modify"}
          onClick={
            !showPaxCost ? handlePaxCalculation : handlePaxCalculationSubmit
          }
          loader={loader}
        />
        <BgOutlineThemeButton
          children={"Cancel"}
          onClick={() => setShowModifyPopup(false)}
        />
      </div>
    </from>
  );
};

export default ModifyReservationArrivalsPaxChange;
