import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./dateRangePicker.css";
import TextDateFormatter from "../../Communication/TextDateFormatter/TextDateFormatter";

const SelectDateRangeWithoutInput = ({ dataFromChild }) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  function formatDate(inputDate) {
    let currentDate = new Date(inputDate);
    let day = currentDate.getDate() + 1;

    if (
      day >
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ).getDate()
    ) {
      day = 1;
      currentDate.setDate(day);
      currentDate.setMonth(currentDate.getMonth() + 1);

      if (currentDate.getMonth() === 0) {
        currentDate.setFullYear(currentDate.getFullYear());
      }
    }

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const formattedDate = `${year}-${month}-${String(day).padStart(2, "0")}`;

    return formattedDate;
  }

  const CustomInput = ({ onClick }) => (
    <div className="dateRangePickerContainer" onClick={onClick}>
      <div className="dateRangePicker" style={{ marginRight: "10px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clipPath="url(#clip0_1270_814)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.625 2H11.75V1.25C11.75 0.835786 11.4142 0.5 11 0.5C10.5858 0.5 10.25 0.835786 10.25 1.25V2H5V1.25C5 0.835786 4.66421 0.5 4.25 0.5C3.83579 0.5 3.5 0.835786 3.5 1.25V2H2.375C1.775 2 1.25 2.525 1.25 3.125V14.375C1.25 14.975 1.775 15.5 2.375 15.5H13.625C14.225 15.5 14.75 14.975 14.75 14.375V3.125C14.75 2.525 14.225 2 13.625 2ZM2.75 14H13.25V6.5H2.75V14Z"
              fill="#888888"
            />
          </g>
          <defs>
            <clipPath id="clip0_1270_814">
              <rect
                width="15"
                height="15"
                fill="white"
                transform="translate(0.5 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      {formattedDate ? (
        <div>
          {" "}
          <TextDateFormatter as={`span`} date={startDate} showYear={true} />-
          <TextDateFormatter as={`span`} date={endDate} showYear={true} />
        </div>
      ) : (
        //           color: #888;

        // leading-trim: both;

        // text-edge: cap;
        // font-family: Inter;
        // font-size: 12px;
        // font-style: normal;
        // font-weight: 400;
        // line-height: normal;
        <div style={{ color: "#888" }}>dd/mm/yyyy - dd/mm/yyyy</div>
      )}
    </div>
  );

  const handleDateChange = (update) => {
    setDateRange(update);
  };

  const minSelectableDate = new Date();

  const [formattedDate, setFormattedDate] = useState();

  useEffect(() => {
    if (startDate && endDate) {
      const from_date = startDate.toISOString().split("T")[0];
      const to_date = endDate.toISOString().split("T")[0];
      setFormattedDate({
        from_date: formatDate(from_date),
        to_date: to_date,
      });
      dataFromChild(formatDate(from_date), formatDate(to_date));
    }
  }, [startDate, endDate]);

  return (
    <div>
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={handleDateChange}
        // minDate={minSelectableDate}
        customInput={<CustomInput />}
      />
    </div>
  );
};

export default SelectDateRangeWithoutInput;
