import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Collapsible,
  DropZone,
  Icon,
  LegacyCard,
  LegacyStack,
  Select,
  TextField,
  Text,
  Thumbnail,
  ButtonGroup,
  Page,
  Badge,
  Tag,
} from "@shopify/polaris";
import { ChevronDownMinor, NoteMinor } from "@shopify/polaris-icons";
import { Link, useNavigate } from "react-router-dom";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
} from "./UI/Buttons";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import { useSelector } from "react-redux";
import VerticalTextAreaCustom from "./CustomComponents/VerticalTextAreaCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import CustomToggleButton from "./UI/CustomToggleButton";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import { getDecryptedData } from "../utils/encryptStorage";

const AddAgentProfile = () => {
  const [files, setFiles] = useState([]);
  const [value, setValue] = useState("Jaded Pixel");
  const [selected, setSelected] = useState("today");

  const handleChange = useCallback((newValue) => setValue(newValue), []);

  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const [buttonLoader, setButtonLoader] = useState(false);

  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(true);
  const [open4, setOpen4] = useState(true);
  const [open5, setOpen5] = useState(true);
  const [open6, setOpen6] = useState(true);
  const [open7, setOpen7] = useState(true);
  const [open8, setOpen8] = useState(true);
  const [open9, setOpen9] = useState(true);

  const handleToggle1 = useCallback(() => setOpen1((open1) => !open1), []);
  const handleToggle2 = useCallback(() => setOpen2((open2) => !open2), []);
  const handleToggle3 = useCallback(() => setOpen3((open3) => !open3), []);
  const handleToggle4 = useCallback(() => setOpen4((open4) => !open4), []);
  const handleToggle5 = useCallback(() => setOpen5((open5) => !open5), []);
  const handleToggle6 = useCallback(() => setOpen6((open6) => !open6), []);
  const handleToggle7 = useCallback(() => setOpen7((open7) => !open7), []);
  const handleToggle8 = useCallback(() => setOpen8((open8) => !open8), []);
  const handleToggle9 = useCallback(() => setOpen9((open9) => !open9), []);

  // upload files
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );

  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);

  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

  const fileUpload = !files.length && (
    <DropZone.FileUpload actionHint="Accepts .gif, .jpg, and .png" />
  );

  const uploadedFiles = files.length > 0 && (
    <LegacyStack vertical>
      {files.map((file, index) => (
        <LegacyStack alignment="center" key={index}>
          <Thumbnail
            size="small"
            alt={file.name}
            source={
              validImageTypes.includes(file.type)
                ? window.URL.createObjectURL(file)
                : NoteMinor
            }
          />
          <div>
            {file.name}{" "}
            <Text variant="bodySm" as="p">
              {file.size} bytes
            </Text>
          </div>
        </LegacyStack>
      ))}
    </LegacyStack>
  );

  // For status options
  const [selectedOption, setSelectedOption] = useState("today");

  const statusOptions = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Last 7 days", value: "lastWeek" },
  ];

  const handleSelectStatusChange = useCallback(
    (value) => setSelectedOption(value),
    []
  );

  const navigate = useNavigate();

  const [addCategories, setAddCategories] = useState([]);
  const [loader, setLoader] = useState(true);

  const fetchAgentCategories = async (id) => {
    // /api/v1/agent/categories/1
    if (!id) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agent/categories/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAddCategories(data.data, "fetchAgentCategories");
    } catch (error) {
      console.error(error);
    }
  };

  setTimeout(() => {
    setLoader(false);
  }, 1000);

  useEffect(() => {
    if (property_id) {
      fetchAgentCategories(property_id?.id);
    }
  }, [property_id]);

  const [addPropertyData, setAddPropertyData] = useState({
    agent_name: "",
    agent_email: "",
    agent_phone: "",
    agent_category: "",
    company_name: "",
    business_type: "",
    agent_pan: "",
    agent_gst: "",
    // settled_from: "",
    credit_limit: "",
    balance: "",
    agent_description: "",
    assigned_properties: "",

    max_hold_time: "",
    credit_amount: "",
    used_amount: "",
    balance_amount: "",
  });

  // Onchange Function
  const setAddItemDataHandleChange = (event) => {
    setAddPropertyData({
      ...addPropertyData,
      [event.target.name]: event.target.value,
    });
  };

  // Validate function
  const validate = (values) => {
    const errors = {};
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneNumberRegex = /^([+]\d{2})?\d{10}$/;

    if (!values.property_email) {
      // errors.property_email = "Email is required!";
    } else {
      if (emailRegex.test(values.property_email)) {
        errors.property_email = "";
      } else {
        errors.property_email = "This is not a valid email format!";
      }
    }

    if (!values.property_phone) {
      errors.property_phone = "Phone number is required!";
    } else {
      if (phoneNumberRegex.test(values.property_phone)) {
        errors.property_phone = "";
      } else {
        errors.property_phone = "This is not a valid Phone Number";
      }
    }

    return errors;
  };

  const fillTheDescriptionHandle = (event) => {
    const selectedId = parseInt(event.target.value);

    setAddPropertyData({
      ...addPropertyData,
      agent_category: event.target.value,
    });

    setSelectedOption(selectedId);

    const selectedObject = addCategories?.find(
      (item) => item.id === selectedId
    );
    if (selectedObject) {
      setAddPropertyData({
        ...addPropertyData,
        agent_category: event.target.value,
        agent_description: selectedObject.agent_cat_description,
      });
    } else {
      console.log("Selected object not found in the array.");
    }
  };

  const [showCreditLimit, setShowCreditLimit] = useState(false);
  const fillSettleFromHandle = (event) => {
    setAddPropertyData({
      ...addPropertyData,
      settled_from: event.target.value,
    });

    if (event.target.value === "credit") {
      setShowCreditLimit(true);
    } else if (event.target.value === "both") {
      setShowCreditLimit(true);
    } else {
      setShowCreditLimit(false);
    }
  };

  const [temporaryBooking, setTemporaryBooking] = useState("disabled");
  const [showMaxHold, setShowMaxHold] = useState(false);
  const temporaryBookingsHandle = useCallback(
    (data) => {
      setTemporaryBooking(data);
      if (temporaryBooking === "enabled") {
        setShowMaxHold(true);
      } else {
        setShowMaxHold(false);
      }
    },
    [showMaxHold, temporaryBooking]
  );

  // for Tag

  const [selectedTags, setSelectedTags] = useState([]);

  const removeTag = useCallback(
    (tag) => () => {
      setSelectedTags((previousTags) =>
        previousTags.filter((previousTag) => previousTag !== tag)
      );
    },
    [selectedTags]
  );

  const tagMarkup = selectedTags.map((option) => (
    <Tag key={option} onRemove={removeTag(option)}>
      {option}
    </Tag>
  ));

  // NEW
  const [allProperties, setAllProperties] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [selectedPropertiesId, setSelectedPropertiesId] = useState([]);
  const addAsignHotel = (e) => {
    const foundSelectedPropertyId = allProperties.find(
      (property) => property.property_name === e.target.value
    )?.id;

    // Check if the foundSelectedPropertyId is not already in the selectedPropertiesId array
    const isIdExists = selectedPropertiesId.some(
      (id) => id === foundSelectedPropertyId
    );

    if (!isIdExists && foundSelectedPropertyId !== undefined) {
      setSelectedProperties((prevState) => [...prevState, e.target.value]);
      setSelectedTags((prevState) => [...prevState, e.target.value]);
      setSelectedPropertiesId((prevState) => [
        ...prevState,
        foundSelectedPropertyId,
      ]);
    } else {
      showErrorToast("Already Exists");
    }
  };

  useEffect(() => {
    setAddPropertyData({
      ...addPropertyData,
      assigned_properties: selectedPropertiesId,
    });
  }, [selectedPropertiesId]);

  // const handleOnKeyDown = useCallback(
  //   (e) => {
  //     if (e.key === "Enter" && onkeyPressValue.trim() !== "") {
  //       setOnKeyPressValue("");
  //       // valueArray.push(inputValue)
  //       setSelectedTags((prevArray) => [...prevArray, onkeyPressValue]);

  //       // onkeyPressValue = ""
  //       setOnKeyPressValue("");
  //     }
  //   },
  //   [onkeyPressValue, selectedTags]
  // );

  // const setOnKeyPressValueHandleChange = useCallback(
  //   (e) => {
  //     if (e.target.name === "amenities") {
  //       setSelectedTags(selectedTags);
  //     }
  //     setOnKeyPressValue(e.target.value);
  //   },
  //   [selectedTags]
  // );

  const fetchPropertiesData = async () => {
    try {
      // /api/v1/user-profile
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/user-profile`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setAllProperties(data.data.properties);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPropertiesData();
  }, []);

  const handleSubmitAddRoomType = (event) => {
    event.preventDefault();

    if (!addPropertyData.credit_limit) {
      showErrorToast("Please Enter Credit Limit");
      return;
    }

    setButtonLoader(true);

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/agents/store/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addPropertyData,
          assigned_properties: selectedPropertiesId,
          account_id: property_id.account_id,
          temporary_booking: temporaryBooking === "enabled" ? 1 : 0,
          max_hold_time:
            temporaryBooking === "enabled" ? addPropertyData.max_hold_time : 0,

          credit_amount: addPropertyData.credit_limit,
          used_amount: 0,
          balance_amount: addPropertyData.credit_limit,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setButtonLoader(false);
          showSuccessToast("Agent Created Successfully!");
          navigate("/agents");
        } else {
          showErrorToast(data.message);
          setButtonLoader(false);
        }
      })
      .catch((error) => {
        setButtonLoader(false);
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  return (
    <>
      <div className="edit-rooms-full-container">
        <div>
          <Page
            backAction={{ content: "Products", url: `/agents` }}
            title="Add Agent"
            compactTitle
          ></Page>
        </div>
        <form
          onSubmit={handleSubmitAddRoomType}
          className="edit-rooms-full-container-div"
        >
          <div className="edit-rooms-full-container-left">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                width: "591px",
              }}
            >
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle1}
                      aria-expanded={open1}
                      aria-controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Contact Person</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open1}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-wrapper-container">
                        <div className="edit-rooms-form-container">
                          <div className="form-container-div-full">
                            <VerticalInputFieldCustom
                              required={true}
                              name={"agent_name"}
                              className="textfield"
                              focused={false}
                              titleName="Name"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                              value={addPropertyData.agent_name}
                            />
                          </div>
                        </div>
                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              type={"number"}
                              name={"agent_phone"}
                              className="textfield"
                              focused={false}
                              titleName="Phone Number"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                              value={addPropertyData.agent_phone}
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              type={"email"}
                              required={true}
                              name={"agent_email"}
                              className="textfield"
                              focused={false}
                              titleName="Email"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                              value={addPropertyData.agent_email}
                            />
                          </div>
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle2}
                      aria-expanded={open2}
                      aria-controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Business Details</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open2}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-wrapper-container">
                        <div className="edit-rooms-form-container">
                          <div className="form-container-div-full">
                            <VerticalInputFieldCustom
                              required={true}
                              name={"company_name"}
                              className="textfield"
                              focused={false}
                              titleName="Company Name"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                              value={addPropertyData.company_name}
                            />
                          </div>
                        </div>
                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              name={"agent_gst"}
                              className="textfield"
                              focused={false}
                              titleName="GST No."
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                              value={addPropertyData.agent_gst}
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              name={"business_type"}
                              className="textfield"
                              focused={false}
                              titleName="Business Type"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                              value={addPropertyData.business_type}
                            />
                          </div>
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              {/* <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle3}
                      aria-expanded={open3}
                      aria-controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Address</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open3}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-wrapper-container">
                        <div className="edit-rooms-form-container">
                          <div className="form-container-div-full">
                            <VerticalInputFieldCustom
                              required={true}
                              name={"business_type"}
                              className="textfield"
                              focused={false}
                              titleName="City"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                              value={addPropertyData.ag}
                            />
                          </div>
                        </div>
                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <TextField
                              className="textfield"
                              focused={false}
                              label="Country"
                              onChange={handleChange}
                              autoComplete="off"
                            />
                          </div>
                          <div className="form-container-div">
                            <TextField
                              className="textfield"
                              focused={false}
                              label="Pincode"
                              onChange={handleChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="form-container-div">
                          <TextField
                            label="Address"
                            onChange={handleChange}
                            multiline={4}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div> */}
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle4}
                      aria-expanded={open4}
                      aria-controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Agent Categories</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open4}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-container">
                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <div style={{ marginBottom: "4px" }}>
                              Select Agent Category
                              <span style={{ color: "red" }}>*</span>
                            </div>
                            <select
                              style={{
                                width: "100%",
                                minHeight: "2.25rem",
                                border: "1px solid rgba(171, 177, 186, 1)",
                                borderRadius: "3px",
                              }}
                              onChange={(e) => fillTheDescriptionHandle(e)}
                            >
                              <option value="">Select Option</option>
                              {addCategories?.map((x, i) => {
                                return (
                                  <>
                                    <option key={x.id} value={x.id}>
                                      {x.agent_cat_name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="form-container-div-full">
                          <VerticalTextAreaCustom
                            required={true}
                            name={"agent_description"}
                            className="textfield"
                            focused={false}
                            titleName="Description"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                            value={addPropertyData.agent_description}
                          />
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
            </div>
            <div className="rooms-edit-left-container-buttons">
              <div>
                {/* {loader ? (
                  <BgThemeButtonDisabled children={"Add "} />
                ) : (
                  <BgThemeButton type={"submit"} children={"Add"} />
                )} */}

                <BgThemeButton
                  type="submit"
                  children={"Add"}
                  loader={buttonLoader}
                />
              </div>
              <div>
                <Link style={{ textDecoration: "none" }} to={"/agents"}>
                  <BgOutlineThemeButton children={"Discard"} />
                </Link>
              </div>
            </div>
          </div>

          <div className="edit-rooms-full-container-right">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle7}
                      aria-expanded={open7}
                      aria-controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Temporary Bookings</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open7}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div style={{ marginBottom: "7px" }}>
                        <CustomToggleButton
                          positive={"Enable"}
                          negative={"Disable"}
                          byDefaultEnable={false}
                          func={temporaryBookingsHandle}
                          title={"Temporary Bookings"}
                        />
                      </div>
                      {showMaxHold && (
                        <div className="form-container-div-full">
                          <VerticalInputFieldCustom
                            type={"number"}
                            required={true}
                            name={"max_hold_time"}
                            className="textfield"
                            focused={false}
                            titleName="Max Hold Time (hrs)"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                            value={addPropertyData.max_hold_time}
                          />
                        </div>
                      )}

                      {/* <div className="form-container-div-half">
                        <div className="form-container-div">
                          <div style={{ marginBottom: "4px" }}>
                            Settle From
                            <span style={{ color: "red" }}>*</span>
                          </div>
                          <select
                            style={{
                              width: "100%",
                              minHeight: "2.25rem",
                              border: "1px solid rgba(171, 177, 186, 1)",
                              borderRadius: "3px",
                            }}
                            required
                            onChange={(e) => fillSettleFromHandle(e)}
                          >
                            <option value="">Select Option</option>

                            <option key={1} value="credit">
                              Credit
                            </option>
                            <option key={2} value="advance_payment">
                              Advance Payment
                            </option>
                            <option key={3} value="both">
                              Both
                            </option>
                          </select>
                        </div>
                      </div> */}

                      {showCreditLimit && (
                        <div className="form-container-div-full">
                          <VerticalInputFieldCustom
                            required={true}
                            type={"number"}
                            name={"credit_limit"}
                            className="textfield"
                            focused={false}
                            titleName="Credit Limit"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                            value={addPropertyData.credit_limit}
                          />
                        </div>
                      )}

                      <div className="form-container-div-full">
                        <VerticalInputFieldCustom
                          required={true}
                          type={"number"}
                          name={"credit_limit"}
                          className="textfield"
                          focused={false}
                          titleName="Credit Amount"
                          onChange={setAddItemDataHandleChange}
                          autoComplete="off"
                          value={addPropertyData.credit_limit}
                        />
                      </div>
                      {/* <div className="form-container-div-full">
                        <VerticalInputFieldCustom
                          required={true}
                          type={"number"}
                          name={"advance_amount"}
                          className="textfield"
                          focused={false}
                          titleName="Advance Amount"
                          onChange={setAddItemDataHandleChange}
                          autoComplete="off"
                          value={addPropertyData.advance_amount}
                        />
                      </div> */}
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>

              {/* <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle8}
                      aria-expanded={open8}
                      aria-controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Assign Hotel</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open8}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-container">
                        <div className="form-container-div">
                          <div style={{ marginBottom: "4px" }}>
                            Select Agent Category
                            <span style={{ color: "red" }}>*</span>
                          </div>
                          <select
                            style={{
                              width: "100%",
                              minHeight: "2.25rem",
                              border: "1px solid rgba(171, 177, 186, 1)",
                              borderRadius: "3px",
                            }}
                            onChange={(e) => addAsignHotel(e)}
                          >
                            <option value="">Select Option</option>
                            {allProperties?.map((x) => {
                              return (
                                <>
                                  <option
                                    id={x.property_name}
                                    key={x.property_id}
                                    value={x.property_name}
                                  >
                                    {x.property_name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>

                        <div style={{ marginTop: "10px" }}>
                          <LegacyStack spacing="tight">{tagMarkup}</LegacyStack>
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div> */}
              {/* <div>
              <LegacyCard sectioned>
                <LegacyStack vertical>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "300px",
                      alignItems: "center",
                      fontWeight: "700",
                      fontSize: "14px",
                    }}
                  >
                    Pricing
                    <Button
                      onClick={handleToggle9}
                      aria-expanded={open9}
                      aria-controls="basic-collapsible"
                    >
                      <Icon source={ChevronDownMinor} />
                    </Button>
                  </div>
                  <Collapsible
                    open={open9}
                    id="basic-collapsible"
                    transition={{
                      duration: "500ms",
                      timingFunction: "ease-in-out",
                    }}
                    expandOnPrint
                  >
                    <div className="edit-rooms-form-container">
                      <div className="form-container-div-full">
                        <TextField
                          className="textfield"
                          focused={false}
                          label="Rack Price"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-container-div-full">
                        <TextField
                          className="textfield"
                          focused={false}
                          label="Extra Adults"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <TextField
                          className="textfield"
                          focused={false}
                          label="Child with Bed"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-container-div">
                        <TextField
                          className="textfield"
                          focused={false}
                          label="Child Without Bed"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </Collapsible>
                </LegacyStack>
              </LegacyCard>
            </div> */}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddAgentProfile;
