import {
  TextField,
  IndexTable,
  LegacyCard,
  IndexFilters,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Badge,
  Avatar,
  Button,
  Icon,
} from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import logo from "../assets/images/room_type.png";
import { Link } from "react-router-dom";
import { BgOutlineThemeButton, BgThemeButton } from "./UI/Buttons";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import Loader from "./UI/Loader/Loader";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import image from "../assets/images/dashboard.png";
import { getDecryptedData } from "../utils/encryptStorage";

const Property = () => {
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showLowerMenuAddMenu, setShowLowerMenuAddMenu] = useState(false);
  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [orders, setOrders] = useState([]);

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [itemStrings, setItemStrings] = useState([
    "All",
    "Unpaid",
    "Open",
    "Closed",
    "Local delivery",
    "Local pickup",
  ]);

  const deleteView = (index) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    setSelected(0);
  };

  const duplicateView = async (name) => {
    setItemStrings([...itemStrings, name]);
    setSelected(itemStrings.length);
    await sleep(1);
    return true;
  };

  // const tabs = itemStrings.map((item, index) => ({
  //   content: item,
  //   index,
  //   onAction: () => {},
  //   id: `${item}-${index}`,
  //   isLocked: index === 0,
  //   actions:
  //     index === 0
  //       ? []
  //       : [
  //           {
  //             type: "rename",
  //             onAction: () => {},
  //             onPrimaryAction: async (value) => {
  //               const newItemsStrings = tabs.map((item, idx) => {
  //                 if (idx === index) {
  //                   return value;
  //                 }
  //                 return item.content;
  //               });
  //               await sleep(1);
  //               setItemStrings(newItemsStrings);
  //               return true;
  //             },
  //           },
  //           {
  //             type: "duplicate",
  //             onPrimaryAction: async (value) => {
  //               await sleep(1);
  //               duplicateView(value);
  //               return true;
  //             },
  //           },
  //           {
  //             type: "edit",
  //           },
  //           {
  //             type: "delete",
  //             onPrimaryAction: async () => {
  //               await sleep(1);
  //               deleteView(index);
  //               return true;
  //             },
  //           },
  //         ],
  // }));

  const [selected, setSelected] = useState(0);
  const onCreateNewView = async (value) => {
    await sleep(500);
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    return true;
  };

  const sortOptions = [
    { label: "Order", value: "order asc", directionLabel: "Ascending" },
    { label: "Order", value: "order desc", directionLabel: "Descending" },
    { label: "Customer", value: "customer asc", directionLabel: "A-Z" },
    { label: "Customer", value: "customer desc", directionLabel: "Z-A" },
    { label: "Date", value: "date asc", directionLabel: "A-Z" },
    { label: "Date", value: "date desc", directionLabel: "Z-A" },
    { label: "Total", value: "total asc", directionLabel: "Ascending" },
    { label: "Total", value: "total desc", directionLabel: "Descending" },
  ];

  const [sortSelected, setSortSelected] = useState(["order asc"]);
  const { mode, setMode } = useSetIndexFiltersMode();
  const onHandleCancel = () => {};

  const onHandleSave = async () => {
    await sleep(1);
    return true;
  };

  const primaryAction =
    selected === 0
      ? {
          type: "save-as",
          onAction: onCreateNewView,
          disabled: false,
          loading: false,
        }
      : {
          type: "save",
          onAction: onHandleSave,
          disabled: false,
          loading: false,
        };
  const [accountStatus, setAccountStatus] = useState(undefined);
  const [moneySpent, setMoneySpent] = useState(undefined);
  const [taggedWith, setTaggedWith] = useState("");
  const [queryValue, setQueryValue] = useState("");

  const handleAccountStatusChange = useCallback(
    (value) => setAccountStatus(value),
    []
  );
  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );
  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleAccountStatusRemove = useCallback(
    () => setAccountStatus(undefined),
    []
  );
  const handleMoneySpentRemove = useCallback(
    () => setMoneySpent(undefined),
    []
  );
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAccountStatusRemove();
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [
    handleAccountStatusRemove,
    handleMoneySpentRemove,
    handleQueryValueRemove,
    handleTaggedWithRemove,
  ]);

  const filters = [
    {
      key: "accountStatus",
      label: "Account status",
      filter: (
        <ChoiceList
          title="Account status"
          titleHidden
          choices={[
            { label: "Enabled", value: "enabled" },
            { label: "Not invited", value: "not invited" },
            { label: "Invited", value: "invited" },
            { label: "Declined", value: "declined" },
          ]}
          selected={accountStatus || []}
          onChange={handleAccountStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "taggedWith",
      label: "Tagged with",
      filter: (
        <TextField
          label="Tagged with"
          value={taggedWith}
          onChange={handleTaggedWithChange}
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: true,
    },
    {
      key: "moneySpent",
      label: "Money spent",
      filter: (
        <RangeSlider
          label="Money spent is between"
          labelHidden
          value={moneySpent || [0, 500]}
          prefix="$"
          output
          min={0}
          max={2000}
          step={1}
          onChange={handleMoneySpentChange}
        />
      ),
    },
  ];

  // const appliedFilters = [];
  // if (accountStatus && !isEmpty(accountStatus)) {
  //   const key = "accountStatus";
  //   appliedFilters.push({
  //     key,
  //     label: disambiguateLabel(key, accountStatus),
  //     onRemove: handleAccountStatusRemove,
  //   });
  // }
  // if (moneySpent) {
  //   const key = "moneySpent";
  //   appliedFilters.push({
  //     key,
  //     label: disambiguateLabel(key, moneySpent),
  //     onRemove: handleMoneySpentRemove,
  //   });
  // }
  // if (!isEmpty(taggedWith)) {
  //   const key = "taggedWith";
  //   appliedFilters.push({
  //     key,
  //     label: disambiguateLabel(key, taggedWith),
  //     onRemove: handleTaggedWithRemove,
  //   });
  // }

  // const image = (
  //   <div style={{ objectFit: "cover" }}>
  //     <img
  //       src={logo}
  //       width="65px"
  //       style={{ objectFit: "cover", width: "100%", height: "100%" }}
  //       height="45px"
  //     />
  //   </div>
  // );

  const [addRoomTypeData, setAddRoomTypeData] = useState({
    room_total: 0,
    rack_price: 0,
    max_child: 0,
    room_name: "",
    max_adults: 0,
    base_child: 0,
    base_adult: 0,
    infants: 0,
    extra_bed_limit: 0,
    room_size: 0,
    room_size_unit: "",
    room_bed_type: "",
    room_description: "",
    amenities: "",
    property_id: "99f12663-7d6d-4d28-8f08-786b7daa73f1",
    breakfast: 0,
    lunch: 100,
    dinner: 100,
    extra_adults: 0,
    child_with_Bed: 0,
    child_without_Bed: 0,
    extra_bedding: "enabled",
    page_title: "",
    meta_description: "",
    url_handle: "",

    // room_view_type: "",
    // room_images: "",
    // status: "active",
  });

  const order = [
    {
      id: "99e340db-f1e1-4f9f-ac04-8a768db6d52f",
      property_id: "99f12663-7d6d-4d28-8f08-786b7daa73f1",
      room_name: "Deluxe",
      room_total: 10,
      rack_price: 4000,
      extra_adults: 100,
      child_with_Bed: 100,
      child_without_Bed: 50,
      breakfast: 100,
      lunch: 100,
      dinner: 100,
      max_child: 2,
      max_adults: 2,
      base_child: 2,
      base_adult: 2,
      infants: 2,
      extra_bed_limit: 2,
      room_size: 200,
      room_size_unit: "sqft",
      room_bed_type: "king",
      room_view_type: "valley",
      room_images: null,
      room_description: "",
      amenities: null,
      page_title: "room",
      meta_description: "qwerty",
      url_handle: "room",
      extra_bedding: "enabled",
      status: "active",
      created_at: "2023-08-14T11:40:47.000000Z",
      updated_at: "2023-08-14T11:40:47.000000Z",
    },
  ];

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(orders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);

      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const getItemChecked = (id) => {};

  getItemChecked(selectedResources[0]);

  // const rowMarkup = orders.map(
  //   ({
  //     id,
  //     image,
  //     room_name,
  //     room_total,
  //     base_adult,
  //     max_adults,
  //     max_child,
  //     room_size,
  //     rack_price,
  //     roomType,
  //     totalRooms,
  //     baseAdults,
  //     maxAdults,
  //     maxChild,
  //     roomSize,
  //     rackPrice,
  //     roomPrice,
  //     index,
  //   }) => (
  //     <IndexTable.Row
  //       id={id}
  //       key={id}
  //       selected={selectedResources.includes(id)}
  //       position={index}
  //     >
  //       <IndexTable.Cell>{image}</IndexTable.Cell>
  //       <IndexTable.Cell>
  //         <CustomCssComponentTableText
  //           innerText={room_name}
  //         ></CustomCssComponentTableText>
  //       </IndexTable.Cell>
  //       <IndexTable.Cell>
  //         <CustomCssComponentTableText
  //           innerText={room_total}
  //         ></CustomCssComponentTableText>
  //       </IndexTable.Cell>
  //       <IndexTable.Cell>
  //         <CustomCssComponentTableText
  //           innerText={base_adult}
  //         ></CustomCssComponentTableText>
  //       </IndexTable.Cell>
  //       <IndexTable.Cell>
  //         <CustomCssComponentTableText
  //           innerText={max_adults}
  //         ></CustomCssComponentTableText>
  //       </IndexTable.Cell>
  //       <IndexTable.Cell>
  //         <CustomCssComponentTableText
  //           innerText={max_child}
  //         ></CustomCssComponentTableText>
  //       </IndexTable.Cell>
  //       <IndexTable.Cell>
  //         <CustomCssComponentTableText
  //           innerText={room_size}
  //         ></CustomCssComponentTableText>
  //       </IndexTable.Cell>
  //       <IndexTable.Cell>
  //         <CustomCssComponentTableText
  //           subScript={<span>&#8377;</span>}
  //           innerText={rack_price}
  //         ></CustomCssComponentTableText>
  //       </IndexTable.Cell>
  //       {/* <IndexTable.Cell>
  //         <span>&#8377;</span> {roomPrice}
  //       </IndexTable.Cell> */}
  //     </IndexTable.Row>
  //   )
  // );

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const removeItemConfirm = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };
  const userToken = getDecryptedData('encryptToken');

  // FETCH DATA
  const fetchInfo = async () => {
    // http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/properties
    try {
      setLoader(true); // Set loading before sending API request
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/properties`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setData(data.properties);
      // setLoader(false); // Stop loading
    } catch (error) {
      // setLoader(false); // Stop loading in case of error
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  setTimeout(() => {
    setLoader(false);
  }, 1000);

  // delete data
  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    clearSelection();

    // "http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/rooms/delete"
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/delete`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(deletedIds),
      }
    );

    const data = await response.json();
    clearSelection();
    // now do whatever you want with the data
    fetchInfo();
    setShowModelMenuActive(!showModelMenuActive);
    // clearSelection();
    // setShowLowerMenuMultipleSelect(false)
  };

  // Update Data
  const [updatedData, setUpdatedData] = useState();
  // useEffect(() => {
  // `http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/rooms/edit/${id}`
  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/edit/${id}`
      );
      const data = await response.json();
      const updatedData = {
        id: data.room.id,
        room_total: data.room.room_total,
        rack_price: data.room.rack_price,
        max_child: data.room.max_child,
        room_name: data.room.room_name,
        max_adults: data.room.max_adults,
        base_child: data.room.base_child,
        base_adult: data.room.base_adult,
        infants: data.room.infants,
        extra_bed_limit: data.room.extra_bed_limit,
        room_size: data.room.room_size,
        room_size_unit: data.room.room_size_unit,
        room_bed_type: data.room.room_bed_type,
        room_description: data.room.room_description,
        amenities: data.room.amenities,
        property_id: data.room.property_id,
        breakfast: data.room.room_total,
        lunch: data.room.lunch,
        dinner: data.room.dinner,
        extra_adults: data.room.extra_adults,
        child_with_Bed: data.room.child_with_Bed,
        child_without_Bed: data.room.child_without_Bed,
        extra_bedding: data.room.extra_bedding,
        page_title: data.room.page_title,
        meta_description: data.room.meta_description,
        url_handle: data.room.url_handle,
      };
      setAddRoomTypeData(updatedData);
      setUpdatedData(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  // }, [selectedResources])

  // Onchange Function
  const setAddItemDataHandleChange = (event) => {
    setAddRoomTypeData({
      ...addRoomTypeData,
      [event.target.name]: event.target.value,
    });
  };

  const updateFunction = (id) => {
    // `http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/rooms/update/${id}`
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/rooms/update/${id}`, {
      method: "POST",
      body: JSON.stringify(addRoomTypeData),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) => fetchInfo());
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="room-types-full-container">
            <div className="room-types-full-container-div">
              <div className="room-type-heading">Properties</div>
              {/* <div>
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/property/addproperty`}
                >
                  <BgThemeButton children={"Add Property"} />
                </Link>
              </div> */}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                flexDirection: "column",
              }}
            >
              {data.map((item) => {
                return (
                  <div className="property-details-section">
                    <div
                      style={{
                        color: "#333",
                        fontFamily: "Inter",
                        fontSize: "13px",
                        fontWeight: 400,
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          gap: "15px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <img
                            src={logo}
                            style={{
                              objectFit: "cover",
                              width: "65px",
                              height: "65px",
                            }}
                          />
                        </div>
                        <div>
                          <div
                            style={{
                              fontSize: "19px",
                              fontWeight: 600,
                              marginBottom: "5px",
                              textTransform: "capitalize",
                            }}
                          >
                            {item.property_name}
                          </div>
                          <div>{item.property_address}</div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "40px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "7px",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "19px",
                              fontWeight: 600,
                            }}
                          >
                            -
                          </div>
                          <div>Total Rooms</div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "7px",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                            }}
                          >
                            -
                          </div>
                          <div>Avg. Rate</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );

  // function disambiguateLabel(key, value) {
  //   switch (key) {
  //     case "moneySpent":
  //       return `Money spent is between $${value[0]} and $${value[1]}`;
  //     case "taggedWith":
  //       return `Tagged with ${value}`;
  //     case "accountStatus":
  //       return value.map((val) => `Customer ${val}`).join(", ");
  //     default:
  //       return value;
  //   }
  // }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};
export default Property;
