// React Imports
import * as React from "react";

// Polaris Imports
import { BarChart, DonutChart } from "@shopify/polaris-viz";
import { Card } from "@shopify/polaris";
import "@shopify/polaris-viz/build/esm/styles.css";
import { Page } from "@shopify/polaris";

const DonutCustomChart = ({ state, data, title }) => {
  const data1 = [
    {
      name: "Values in Thousand",
      color: "#7C9CF3",
      barMargin: "None",
      data: [
        { value: 2000, key: "Sep" },
        { value: 200, key: "Jan" },
        { value: 2000, key: "Sep" },
        { value: 2000, key: "Sep" },
      ],
    },
  ];

  // React.useEffect(() => {
  //   setRenderedChartData(data);
  // }, [data]);

  const testData = [
    {
      name: "Available Rooms",
      data: {
        key: "2023-12-06",
        value: 29,
        room_name: "Penthouse",
        percent: 48,
      },
    },
    {
      name: "Occupied Rooms",
      data: {
        key: "2023-12-06",
        value: 11,
        room_name: "Penthouse",
        percent: 48,
      },
    },
    {
      name: "Hold Rooms",
      data: {
        key: "2023-12-06",
        value: 0,
        room_name: "Penthouse",
        percent: 48,
      },
    },
  ];

  const [renderedChartData, setRenderedChartData] = React.useState(data);

  React.useEffect(() => {
    setRenderedChartData(data);
  }, [data]);

  return (
    <div style={{ position: "relative" }}>
      <Card>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "40px",
            }}
          >
            <div className="report-chart-heading">{title}</div>
            {/* <div>
              Total Reservation:
              <span className="report-chart-heading">7,600</span>
            </div> */}
          </div>

          <div style={{ height: 400, width: 800, margin: "0 auto" }}>
            {state === "true" ? (
              <DonutChart
                legendFullWidth={true}
                theme="Light"
                showLegend={true}
                isAnimated={true}
                data={renderedChartData}
                legendPosition="left"
                state="Loading"
                renderInnerValueContent={function noRefCheck() {}}
              />
            ) : (
              <DonutChart
                legendFullWidth={true}
                theme="Light"
                showLegend={true}
                isAnimated={true}
                data={renderedChartData}
                legendPosition="left"
              />
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default DonutCustomChart;
