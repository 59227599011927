import React from "react";
import "./customIndexTable.css";

const CustomTableCheckRow = ({
  children,
  id,
  selectedItems,
  setSelectedItems,
  showCheckbox,
}) => {
  const handleCheckboxChange = () => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(id)) {
        return prevSelectedItems?.filter((itemId) => itemId !== id);
      } else {
        return [...prevSelectedItems, id];
      }
    });
  };

  return (

    <tr
      onClick={showCheckbox ? handleCheckboxChange : null}
      className={
        selectedItems?.includes(id)
          ? "custom_index_table_body_row_selected"
          : "custom_index_table_body_row"
      }
    >
      {showCheckbox && (
        <td>
          <input
            type="checkbox"
            checked={selectedItems?.includes(id)}
            onChange={handleCheckboxChange}
            className="custom-checkbox"
            onClick={(e) => e.stopPropagation()} // Prevent checkbox click from triggering row click
          />
        </td>
      )}
      {children}
    </tr>
    
  );
};

export default CustomTableCheckRow;
