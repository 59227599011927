import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { showErrorToast } from "../../assets/toastUtils";

const RoomAvailabilityAndRatesCommunication = ({ data, name }) => {
  const property_id = useSelector((state) => state.property.value);

  return (
    <div style={{ width: "600px", margin: "auto" }}>
      <div style={{ textAlign: "center", fontSize: "30px", fontWeight: "700" }}>
        {property_id.property_name}
      </div>
      <table
        style={{
          background: "#fff",
          width: "600px",
          margin: "10px auto",
          borderRadius: "10px",
          fontFamily: "Inter",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <th
            colSpan={4}
            style={{
              borderBottom: "1px solid #3968ED",
              padding: "10px 0",
              textAlign: "center",
            }}
          >
            {/* <img src="/images/golden-fern-logo.png" alt="logo" /> */}
          </th>
        </thead>
        <thead>
          <th colSpan={4} style={{ padding: "10px 0" }}>
            <h1
              style={{
                fontFamily: "Inter",
                fontWeight: "500px",
                fontSize: "22px",
                color: "#333",
              }}
            >
              Room Availability & Rates
            </h1>
            <p
              style={{
                marginTop: "5px",
                fontFamily: "Inter",
                fontWeight: "400px",
                fontSize: "14px",
                color: "#333",
              }}
            >
              Room Category: Deluxe
            </p>
          </th>
        </thead>

        <tbody>
          <tr>
            <td
              colSpan={4}
              style={{
                padding: "10px",
                lineHeight: "30px",
                color: "#666",
                fontFamily: "Inter",
                fontSize: "12px",
              }}
            >
              Hi, {name} <br />
              Discover comfortable accommodations at our hotel. We offer a
              variety of rooms to suit your needs. Check availability and
              explore competitive rates for a memorable stay. Book now for the
              best deals!.
              <br />
            </td>
          </tr>
          <tr>
            <td colSpan={4} style={{ padding: "10px" }}>
              <table
                style={{
                  width: "100%",
                  background: "#ECF1FD",
                  fontFamily: "Inter",
                  borderCollapse: "Collapse",
                }}
              >
                <thead style={{ color: "#666", fontSize: "12px" }}>
                  <th
                    style={{
                      padding: "10px 10px",
                      borderBottom: "1px solid #CCC",
                      textAlign: "left",
                    }}
                  >
                    Date
                  </th>
                  <th
                    style={{
                      padding: "10px 10px",
                      borderBottom: "1px solid #CCC",
                      textAlign: "left",
                    }}
                  >
                    Availability
                  </th>
                  <th
                    style={{
                      padding: "10px 10px",
                      borderBottom: "1px solid #CCC",
                      textAlign: "left",
                    }}
                  >
                    Rate (per night)
                  </th>
                  <th
                    style={{
                      padding: "10px 10px",
                      borderBottom: "1px solid #CCC",
                      textAlign: "left",
                    }}
                  >
                    Rate (Discounted)
                  </th>
                </thead>

                {data.map((data) => {
                  return (
                    <tbody
                      style={{
                        color: "#000",
                        fontSize: "12px",
                        fontFamily: "Inter",
                      }}
                    >
                      <tr>
                        <td
                          style={{
                            padding: "10px 10px",
                            borderBottom: "1px solid #CCC",
                            textAlign: "left",
                          }}
                        >
                          {data.date}
                        </td>
                        <td
                          style={{
                            padding: "10px 10px",
                            borderBottom: "1px solid #CCC",
                            textAlign: "left",
                          }}
                        >
                          {data?.available_rooms}
                          {/* {data?.rates?.map(() => {
                            return (
                              <tr>
                                <td>5</td>
                              </tr>
                            );
                          })} */}
                        </td>
                        <td
                          style={{
                            padding: "10px 10px",
                            borderBottom: "1px solid #CCC",
                            textAlign: "left",
                          }}
                        >
                          {data?.rates?.map((rate) => {
                            return (
                              <tr>
                                <td>
                                  {rate.rate_plan_type}: {+rate.rate_bar_price}
                                </td>
                              </tr>
                            );
                          })}
                        </td>
                        <td
                          style={{
                            padding: "10px 10px",
                            borderBottom: "1px solid #CCC",
                            textAlign: "left",
                          }}
                        >
                          {data?.rates?.map((rate) => {
                            return (
                              <tr>
                                <td>{+rate.rate_bar_price}</td>
                              </tr>
                            );
                          })}
                        </td>
                      </tr>
                      {/* <tr>
                        <td
                          style={{
                            padding: "10px 10px",
                            borderBottom: "1px solid #CCC",
                            textAlign: "left",
                          }}
                        >
                          12 Aug, 2023
                        </td>
                        <td
                          style={{
                            padding: "10px 10px",
                            borderBottom: "1px solid #CCC",
                            textAlign: "left",
                          }}
                        >
                          5
                        </td>
                        <td
                          style={{
                            padding: "10px 10px",
                            borderBottom: "1px solid #CCC",
                            textAlign: "left",
                          }}
                        >
                          ₹3,000
                        </td>
                        <td
                          style={{
                            padding: "10px 10px",
                            borderBottom: "1px solid #CCC",
                            textAlign: "left",
                          }}
                        >
                          ₹2,500
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            padding: "10px 10px",
                            borderBottom: "1px solid #CCC",
                            textAlign: "left",
                          }}
                        >
                          12 Aug, 2023
                        </td>
                        <td
                          style={{
                            padding: "10px 10px",
                            borderBottom: "1px solid #CCC",
                            textAlign: "left",
                          }}
                        >
                          5
                        </td>
                        <td
                          style={{
                            padding: "10px 10px",
                            borderBottom: "1px solid #CCC",
                            textAlign: "left",
                          }}
                        >
                          ₹3,000
                        </td>
                        <td
                          style={{
                            padding: "10px 10px",
                            borderBottom: "1px solid #CCC",
                            textAlign: "left",
                          }}
                        >
                          ₹2,500
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            padding: "10px 10px",
                            borderBottom: "1px solid #CCC",
                            textAlign: "left",
                          }}
                        >
                          12 Aug, 2023
                        </td>
                        <td
                          style={{
                            padding: "10px 10px",
                            borderBottom: "1px solid #CCC",
                            textAlign: "left",
                          }}
                        >
                          5
                        </td>
                        <td
                          style={{
                            padding: "10px 10px",
                            borderBottom: "1px solid #CCC",
                            textAlign: "left",
                          }}
                        >
                          ₹3,000
                        </td>
                        <td
                          style={{
                            padding: "10px 10px",
                            borderBottom: "1px solid #CCC",
                            textAlign: "left",
                          }}
                        >
                          ₹2,500
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            padding: "10px 10px",
                            borderBottom: "1px solid #CCC",
                            textAlign: "left",
                          }}
                        >
                          12 Aug, 2023
                        </td>
                        <td
                          style={{
                            padding: "10px 10px",
                            borderBottom: "1px solid #CCC",
                            textAlign: "left",
                          }}
                        >
                          5
                        </td>
                        <td
                          style={{
                            padding: "10px 10px",
                            borderBottom: "1px solid #CCC",
                            textAlign: "left",
                          }}
                        >
                          ₹3,000
                        </td>
                        <td
                          style={{
                            padding: "10px 10px",
                            borderBottom: "1px solid #CCC",
                            textAlign: "left",
                          }}
                        >
                          ₹2,500
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            padding: "10px 10px",

                            textAlign: "left",
                          }}
                        >
                          12 Aug, 2023
                        </td>
                        <td
                          style={{
                            padding: "10px 10px",

                            textAlign: "left",
                          }}
                        >
                          5
                        </td>
                        <td
                          style={{
                            padding: "10px 10px",

                            textAlign: "left",
                          }}
                        >
                          ₹3,000
                        </td>
                        <td
                          style={{
                            padding: "10px 10px",

                            textAlign: "left",
                          }}
                        >
                          ₹2,500
                        </td>
                      </tr> */}
                    </tbody>
                  );
                })}
              </table>
            </td>
          </tr>

          <tr>
            <td colSpan={4} style={{ padding: "10px" }}>
              <p
                style={{
                  borderTop: "1px solid #CCC",
                  borderBottom: "1px solid #CCC",
                  padding: "20px 0px",
                  color: "#888",
                  fontWeight: "500px",
                  fontSize: "12px",
                  fontFamily: "Inter",
                }}
              >
                If you have any questions, contact us at{" "}
                <a
                  href="mailto:eco@goldenfernresort.com"
                  style={{ textDecoration: "none" }}
                >
                  eco@goldenfernresort.com
                </a>{" "}
                or call at{" "}
                <a href="tel:7778889990" style={{ textDecoration: "none" }}>
                  {" "}
                  77788-89990
                </a>
              </p>
            </td>
          </tr>

          <tr>
            <td
              colSpan={4}
              style={{ textAlign: "center", padding: "40px 0px 30px 0" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="81"
                height="15"
                viewBox="0 0 81 15"
                fill="none"
              >
                <g clipPath="url(#clip0_1010_2334)">
                  <path
                    d="M28.9705 11.6906C28.1748 11.6906 27.4913 11.5169 26.9199 11.1697C26.354 10.8283 25.8973 10.3257 25.6053 9.72315C25.3031 9.05195 25.147 8.32143 25.148 7.58226C25.1489 6.8431 25.3069 6.113 25.6109 5.44264C25.9054 4.84174 26.3625 4.34035 26.9276 3.99836C27.4968 3.65188 28.1744 3.47827 28.9605 3.47751C29.5729 3.46411 30.1803 3.59321 30.7369 3.85507C31.2292 4.08886 31.6496 4.45619 31.9526 4.91724C32.2562 5.39609 32.4256 5.95115 32.4422 6.52186H30.4105C30.3668 6.16051 30.2061 5.82458 29.9542 5.56773C29.8264 5.44442 29.6757 5.34859 29.5112 5.28589C29.3466 5.2232 29.1714 5.1949 28.996 5.20268C28.684 5.19584 28.3782 5.2928 28.1245 5.47902C27.8611 5.68583 27.6605 5.96506 27.5461 6.28418C27.3979 6.69319 27.3274 7.12744 27.3384 7.56356C27.3274 8.00457 27.3967 8.44384 27.5427 8.85886C27.6557 9.18097 27.8569 9.46289 28.1223 9.67084C28.3452 9.82889 28.605 9.92371 28.8752 9.94568C29.1455 9.96765 29.4166 9.91598 29.6611 9.79593C29.8604 9.6905 30.0295 9.53373 30.1518 9.34104C30.2878 9.12463 30.3751 8.87988 30.4071 8.62459H32.4389C32.4158 9.19154 32.2481 9.7424 31.9526 10.2224C31.6603 10.6874 31.2485 11.0608 30.7624 11.3016C30.203 11.5728 29.5893 11.706 28.9705 11.6906Z"
                    fill="#3968ED"
                  />
                  <path
                    d="M36.0372 11.6841C35.5823 11.6923 35.1309 11.6016 34.7127 11.418C34.3378 11.2499 34.0191 10.9732 33.7956 10.622C33.5609 10.2276 33.445 9.77112 33.4625 9.30962C33.4499 8.92402 33.5315 8.54129 33.7001 8.19628C33.8528 7.90247 34.0754 7.6529 34.3474 7.47073C34.6342 7.28171 34.9492 7.14189 35.28 7.05678C35.6407 6.96118 36.0082 6.89539 36.3792 6.86004C36.831 6.81228 37.1952 6.76565 37.4716 6.72244C37.6831 6.70147 37.8881 6.63563 38.0734 6.52911C38.1341 6.48718 38.1833 6.42983 38.2159 6.36267C38.2486 6.2955 38.2638 6.22081 38.2599 6.14587V6.11402C38.2681 5.97228 38.2454 5.83044 38.1933 5.69883C38.1413 5.56722 38.0612 5.44916 37.959 5.35322C37.7592 5.17354 37.4753 5.0837 37.1075 5.0837C36.7797 5.06616 36.4552 5.15793 36.1826 5.34526C35.9632 5.50485 35.8028 5.73567 35.7274 6.0003L33.729 5.83768C33.8213 5.37577 34.0264 4.94538 34.3252 4.58673C34.6373 4.22162 35.0323 3.94081 35.4754 3.76906C35.9978 3.5677 36.5528 3.47006 37.1108 3.48135C37.5303 3.47848 37.9484 3.52968 38.3554 3.63373C38.7328 3.72864 39.0906 3.89152 39.4123 4.11478C39.7179 4.32969 39.9685 4.61677 40.1429 4.95178C40.3295 5.32908 40.4215 5.74806 40.4104 6.17088V11.5352H38.3676V10.4321H38.3076C38.1826 10.6808 38.0127 10.9031 37.8069 11.0871C37.5881 11.2817 37.3343 11.4309 37.0597 11.5261C36.7301 11.6377 36.3843 11.6912 36.0372 11.6841ZM36.6545 10.1614C36.9454 10.1677 37.2335 10.1014 37.4938 9.96808C37.7261 9.84779 37.923 9.66648 38.0645 9.44268C38.2037 9.21819 38.2759 8.95691 38.2721 8.69097V7.84715C38.1862 7.90038 38.0935 7.94132 37.9968 7.96884C37.8802 8.00523 37.7492 8.03821 37.6049 8.06778C37.4605 8.09734 37.3151 8.12236 37.1696 8.14511L36.7755 8.20424C36.5467 8.23523 36.3228 8.29639 36.1094 8.3862C35.9353 8.45533 35.7821 8.57026 35.6653 8.7194C35.5576 8.87015 35.5025 9.05358 35.5087 9.24025C35.5027 9.37234 35.5288 9.50388 35.5849 9.62301C35.6409 9.74214 35.725 9.8451 35.8296 9.92259C36.0723 10.091 36.3616 10.1748 36.6545 10.1614Z"
                    fill="#3968ED"
                  />
                  <path
                    d="M46.0172 9.79443L45.784 9.83878C45.6841 9.85783 45.5826 9.86734 45.4809 9.86721C45.3506 9.86968 45.2211 9.84692 45.099 9.80011C44.9896 9.75478 44.8992 9.67145 44.8437 9.56472C44.7759 9.41663 44.7447 9.25381 44.7526 9.09049V5.23757H46.2137V3.58063H44.7526V1.67578H42.5987V3.58063H41.5374V5.23757H42.5987V9.38049C42.5785 9.83457 42.6893 10.2847 42.9174 10.6746C43.1352 11.018 43.4529 11.2826 43.8256 11.4309C44.2596 11.6008 44.7241 11.6733 45.1878 11.6436C45.4275 11.6383 45.6662 11.6097 45.9006 11.5583C46.096 11.5116 46.247 11.4707 46.3558 11.4366L46.0172 9.79443Z"
                    fill="#3968ED"
                  />
                  <path
                    d="M49.8275 11.6848C49.3726 11.6932 48.9211 11.6025 48.503 11.4187C48.1278 11.2506 47.8087 10.9739 47.5848 10.6227C47.35 10.2284 47.2342 9.7718 47.2517 9.3103C47.239 8.9247 47.3207 8.54197 47.4893 8.19696C47.6413 7.90296 47.8636 7.65331 48.1355 7.47141C48.4223 7.28239 48.7373 7.14257 49.0681 7.05746C49.4291 6.96194 49.7971 6.89615 50.1684 6.86072C50.6202 6.81296 50.9833 6.76633 51.2597 6.72311C51.4713 6.70234 51.6762 6.63649 51.8615 6.52979C51.9226 6.48815 51.972 6.43089 52.005 6.36368C52.0379 6.29646 52.0531 6.22163 52.0491 6.14655V6.1147C52.057 5.97299 52.0341 5.83125 51.9821 5.69969C51.93 5.56813 51.8502 5.45003 51.7482 5.3539C51.5476 5.17421 51.2638 5.08437 50.8967 5.08437C50.5686 5.06691 50.2437 5.15867 49.9707 5.34593C49.7516 5.50585 49.5913 5.73654 49.5155 6.00098L47.5238 5.83494C47.6165 5.37296 47.822 4.94258 48.1211 4.58399C48.4333 4.21832 48.8288 3.9371 49.2724 3.76519C49.7947 3.56361 50.3497 3.46596 50.9078 3.47747C51.3256 3.47665 51.7418 3.52937 52.1468 3.63441C52.5238 3.72953 52.8812 3.89241 53.2026 4.11546C53.5085 4.33006 53.7591 4.61722 53.9332 4.95246C54.1204 5.32956 54.2124 5.74868 54.2008 6.17156V11.5359H52.159V10.4327H52.098C51.9736 10.682 51.8036 10.9044 51.5972 11.0878C51.3768 11.2835 51.1211 11.433 50.8445 11.5279C50.5166 11.6387 50.1727 11.6918 49.8275 11.6848ZM50.4437 10.1621C50.7347 10.1687 51.0228 10.1023 51.283 9.96876C51.5156 9.84849 51.7129 9.6672 51.8548 9.44336C51.9937 9.21875 52.0654 8.95747 52.0613 8.69165V7.84783C51.9756 7.90148 51.8829 7.94245 51.786 7.96951C51.675 8.00591 51.5395 8.03888 51.3941 8.06845C51.2486 8.09802 51.1043 8.12304 50.96 8.14578L50.5658 8.20492C50.3371 8.23601 50.1132 8.29716 49.8997 8.38687C49.7255 8.45586 49.5723 8.57082 49.4556 8.72008C49.3479 8.87083 49.2928 9.05425 49.299 9.24093C49.2924 9.3731 49.3183 9.50486 49.3743 9.6241C49.4304 9.74333 49.5148 9.84621 49.6199 9.92327C49.8626 10.0918 50.1519 10.1756 50.4448 10.1621H50.4437Z"
                    fill="#3968ED"
                  />
                  <path
                    d="M58.0278 0.929688V11.5343H55.8739V0.929688H58.0278Z"
                    fill="#3968ED"
                  />
                  <path
                    d="M61.0765 14.5172C60.8198 14.5187 60.5634 14.497 60.3104 14.4524C60.109 14.4192 59.9114 14.3647 59.7209 14.2898L60.2072 12.6431C60.4288 12.7161 60.6587 12.7593 60.8911 12.7716C61.0789 12.7832 61.2654 12.7326 61.4229 12.6272C61.5944 12.4934 61.7222 12.3094 61.7893 12.0995L61.9159 11.7583L59.1302 3.57031H61.3951L63.0028 9.4111H63.0827L64.7048 3.58055H66.9852L63.9676 12.3906C63.835 12.7959 63.636 13.1751 63.3792 13.5119C63.1296 13.8329 62.8076 14.087 62.441 14.2523C62.0096 14.4384 61.5445 14.5287 61.0765 14.5172Z"
                    fill="#3968ED"
                  />
                  <path
                    d="M74.5493 5.84876L72.5775 5.97386C72.5423 5.80134 72.466 5.64041 72.3555 5.50532C72.2376 5.35821 72.0878 5.24138 71.918 5.16415C71.7146 5.07425 71.4945 5.03079 71.273 5.03678C70.9777 5.02679 70.6855 5.10121 70.4292 5.25171C70.3269 5.30567 70.2409 5.38708 70.1803 5.48722C70.1197 5.58737 70.0867 5.7025 70.085 5.82033C70.0843 5.91262 70.1039 6.00391 70.1423 6.08739C70.1807 6.17087 70.2371 6.2444 70.3071 6.30251C70.4551 6.43292 70.7094 6.5383 71.0698 6.61866L72.4765 6.91661C73.2315 7.07431 73.7943 7.32943 74.1652 7.68197C74.3495 7.85914 74.4944 8.07482 74.5902 8.31439C74.686 8.55397 74.7303 8.81184 74.7203 9.07052C74.7295 9.56621 74.5728 10.0502 74.2762 10.442C73.955 10.8532 73.5338 11.1705 73.0549 11.362C72.4859 11.5905 71.8785 11.7022 71.2674 11.6907C70.2393 11.6907 69.4215 11.4704 68.8138 11.03C68.5208 10.8254 68.2738 10.5591 68.0894 10.2488C67.905 9.9385 67.7874 9.59145 67.7446 9.23087L69.8619 9.11715C69.8869 9.26513 69.9411 9.40635 70.0211 9.53224C70.101 9.65814 70.2051 9.76608 70.3271 9.84952C70.6109 10.0099 70.929 10.0961 71.2531 10.1007C71.5773 10.1052 71.8975 10.028 72.1856 9.87567C72.289 9.81885 72.3758 9.73508 72.4374 9.63284C72.499 9.5306 72.5332 9.41351 72.5364 9.29342C72.536 9.19684 72.5133 9.10173 72.4703 9.01579C72.4273 8.92985 72.3651 8.85548 72.2889 8.79872C72.0644 8.64313 71.8087 8.54129 71.5406 8.50077L70.196 8.2267C69.4374 8.07128 68.8737 7.80176 68.5051 7.41813C68.3207 7.22516 68.1764 6.99584 68.0809 6.74406C67.9855 6.49229 67.941 6.22331 67.95 5.95339C67.9381 5.47903 68.0816 5.01425 68.3575 4.63307C68.6545 4.24363 69.0522 3.94734 69.5055 3.77787C70.0606 3.56665 70.6498 3.46479 71.2419 3.47764C72.2226 3.47764 72.995 3.68993 73.559 4.11449C73.8337 4.31615 74.0638 4.5748 74.2344 4.8736C74.4051 5.17241 74.5123 5.50467 74.5493 5.84876Z"
                    fill="#3968ED"
                  />
                  <path
                    d="M80.0184 9.79443L79.7864 9.83878C79.6864 9.85783 79.5849 9.86734 79.4833 9.86721C79.3529 9.86979 79.2233 9.84702 79.1013 9.80011C78.9917 9.75503 78.9013 9.67164 78.846 9.56472C78.7777 9.41681 78.7464 9.25385 78.7549 9.09049V5.23757H80.216V3.58063H78.7549V1.67578H76.6011V3.58063H75.5397V5.23757H76.6011V9.38049C76.5802 9.83434 76.6902 10.2844 76.9175 10.6746C77.1349 11.018 77.4522 11.2825 77.8245 11.4309C78.2586 11.6005 78.7231 11.6731 79.1868 11.6436C79.4265 11.6383 79.6652 11.6097 79.8996 11.5583C80.0961 11.5116 80.2471 11.4707 80.3548 11.4366L80.0184 9.79443Z"
                    fill="#3968ED"
                  />
                  <path
                    d="M20.4327 1.81388V13.1861C20.4335 13.4246 20.3885 13.6608 20.3001 13.8814C20.2117 14.102 20.0817 14.3025 19.9175 14.4715C19.7533 14.6406 19.5582 14.7747 19.3433 14.8664C19.1284 14.9581 18.8979 15.0054 18.6652 15.0057H1.75393C1.34169 15.0057 0.946337 14.8379 0.654841 14.5394C0.363346 14.2408 0.199585 13.8358 0.199585 13.4136V1.59212C0.199585 1.16986 0.363346 0.764902 0.654841 0.466322C0.946337 0.167742 1.34169 0 1.75393 0H18.6629C18.8954 -4.6864e-08 19.1257 0.0469264 19.3404 0.138096C19.5552 0.229266 19.7504 0.362894 19.9147 0.531343C20.0791 0.699791 20.2094 0.899758 20.2983 1.11982C20.3871 1.33988 20.4328 1.57573 20.4327 1.81388Z"
                    fill="#3968ED"
                  />
                  <path
                    d="M15.8541 0H1.75625C0.897806 0 0.201904 0.712814 0.201904 1.59212V13.4079C0.201904 14.2872 0.897806 15 1.75625 15H15.8541C16.7126 15 17.4085 14.2872 17.4085 13.4079V1.59212C17.4085 0.712814 16.7126 0 15.8541 0Z"
                    fill="#5780EF"
                  />
                  <path
                    d="M12.8298 0H1.75625C0.897806 0 0.201904 0.712814 0.201904 1.59212V13.4079C0.201904 14.2872 0.897806 15 1.75625 15H12.8298C13.6883 15 14.3842 14.2872 14.3842 13.4079V1.59212C14.3842 0.712814 13.6883 0 12.8298 0Z"
                    fill="#7C9CF3"
                  />
                  <path
                    d="M24.3597 3.57812L21.6462 11.5319H20.4338V9.26994H20.4738L22.0814 3.57812H24.3597Z"
                    fill="#3968ED"
                  />
                  <path
                    d="M7.40962 3.46706C6.99493 3.46009 6.58967 3.59406 6.25719 3.84803C5.90452 4.12977 5.65369 4.52396 5.54441 4.96819H5.46337V3.58078H3.375V11.5345H5.52887V7.03453C5.52328 6.73326 5.59773 6.43608 5.74426 6.17479C5.88438 5.92975 6.08704 5.72844 6.33047 5.59253C6.59067 5.44959 6.88237 5.37753 7.17758 5.38328C7.3414 5.38407 7.50498 5.39623 7.6672 5.41967C7.8089 5.43613 7.94919 5.4635 8.08687 5.50155V3.54439C7.97725 3.51763 7.86597 3.49863 7.7538 3.48752C7.6395 3.47454 7.52462 3.46771 7.40962 3.46706Z"
                    fill="white"
                  />
                  <path
                    d="M14.8793 4.47991C14.5486 4.14645 14.1518 3.8897 13.7157 3.72707C13.2567 3.55678 12.7717 3.47168 12.2835 3.47574C11.5866 3.45855 10.8984 3.63753 10.2939 3.99318C9.7306 4.33656 9.27517 4.83818 8.98164 5.43859C8.66023 6.10798 8.50069 6.84671 8.51644 7.5925C8.51644 8.43177 8.67151 9.15769 8.98164 9.77028C9.27736 10.3669 9.73903 10.8604 10.3084 11.1884C10.8835 11.5212 11.5704 11.688 12.369 11.6888C12.9483 11.7 13.5242 11.5969 14.0654 11.3851C14.5263 11.2041 14.9385 10.9133 15.2678 10.5368C15.5798 10.1721 15.7934 9.73032 15.8873 9.25512L13.8956 9.11979C13.8265 9.31192 13.7161 9.48565 13.5725 9.62813C13.4263 9.76835 13.252 9.8743 13.0618 9.93859C12.8497 10.0098 12.6277 10.0448 12.4045 10.0421C12.0779 10.0504 11.755 9.96996 11.4686 9.80895C11.2068 9.65375 10.9952 9.42321 10.8602 9.14594C10.7095 8.82733 10.6356 8.47634 10.6448 8.12244H15.9329V7.51744C15.9443 6.91386 15.8503 6.31306 15.6553 5.74337C15.4901 5.26906 15.2252 4.83774 14.8793 4.47991ZM10.6503 6.75436C10.6594 6.47965 10.732 6.21108 10.8624 5.97081C11.0014 5.71531 11.2046 5.50251 11.4508 5.35444C11.7142 5.19899 12.014 5.12034 12.3179 5.12699C12.6068 5.12093 12.8921 5.19311 13.1451 5.33624C13.3781 5.47335 13.57 5.67313 13.7002 5.91395C13.8379 6.1725 13.9075 6.4633 13.9022 6.75777L10.6503 6.75436Z"
                    fill="white"
                  />
                  <path
                    d="M20.4338 9.26994V11.5319H19.2203L16.5046 3.57812H18.7795L20.3905 9.26994H20.4338Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1010_2334">
                    <rect
                      width="80.1562"
                      height="15"
                      fill="white"
                      transform="translate(0.201904)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </td>
          </tr>
          <td
            colSpan={4}
            style={{
              height: "20px",
              background: "#3968ED",
              borderRadius: "0 0 10px 10px",
              overflow: "hidden",
            }}
          ></td>
        </tbody>
      </table>
    </div>
  );
};

export default RoomAvailabilityAndRatesCommunication;
