import React from "react";
import PurchaseIssueItem from "../Components/PurchaseIssueItem";

const PurchaseIssueItemPage = () => {
  return (
    <>
      <div className="main_container">
       <PurchaseIssueItem />
      </div>
    </>
  );
};

export default PurchaseIssueItemPage;
