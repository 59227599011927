import React from "react";
import EditPageComp from "../../Components/Websites/EditPageComp";

const EditPage = () => {
    return (
        <>
            <div className="main_container">
                <EditPageComp />
            </div>
        </>
    );
};

export default EditPage;
