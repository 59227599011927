import React, { useEffect, useState } from "react";

const CustomToggleButton = (props) => {
  const [isToggled, setIsToggled] = useState(props.byDefaultEnable);
  const [beddingFinalValue, setBeddingFinalValue] = useState("");

  const handleToggle = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  useEffect(() => {
    setIsToggled(props.byDefaultEnable);
  }, [props.byDefaultEnable]);

  useEffect(() => {
    setBeddingFinalValue((prevBeddingFinalValue) => {
      if (isToggled === false) {
        return "disabled";
      } else {
        return "enabled";
      }
    });

    // Introduce a slight delay for calling props.func
    const timerId = setTimeout(() => {
      props.func(beddingFinalValue);
    }, 0);

    return () => clearTimeout(timerId);
  }, [isToggled, beddingFinalValue, props.func]);

  return (
    <>
      <div className="bedding-wrapper">
        {props.notShowTitle === true ? (
          <></>
        ) : (
          <div styl>
            <p className="bedding_text" style={{ marginBottom: "0px" }}>
              {props.title}
            </p>
          </div>
        )}

        <div className="flex align_items_center">
          <span className="toggle_text">
            {isToggled ? props.positive : props.negative}
          </span>
          <label className="toggle-container">
            <input
              name="extra_bedding"
              type="checkbox"
              checked={isToggled}
              onChange={handleToggle}
              className="toggle-checkbox-custom"
            />
            <span className="toggle-slider"></span>
          </label>
        </div>
      </div>
    </>
  );
};

export default CustomToggleButton;
