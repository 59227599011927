import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import { formatIndianCurrency } from "../../utils/utils";
import { CheckWithoutBgUnderlineBtn } from "../UI/Buttons";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";

const ReportAgentSaleReport = ({ departureReport, loader }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const rowMarkup =
    departureReport &&
    departureReport.length > 0 &&
    departureReport?.map((order) => (
      <IndexTable.Row id={order.id} key={order.id} position={order.index}>
        <IndexTable.Cell>{order.company_name}</IndexTable.Cell>
        <IndexTable.Cell>
          {formatIndianCurrency(order.current_date_amount)}
        </IndexTable.Cell>
        <IndexTable.Cell>{order.current_date_nights}</IndexTable.Cell>
        <IndexTable.Cell>
          {formatIndianCurrency(order.mtd_amount)}
        </IndexTable.Cell>
        <IndexTable.Cell>{order.mtd_nights}</IndexTable.Cell>
        <IndexTable.Cell>
          {formatIndianCurrency(order.ytd_amount)}
        </IndexTable.Cell>
        <IndexTable.Cell>{order.ytd_nights}</IndexTable.Cell>
      </IndexTable.Row>
    ));

  const data = [
    {
      agent_id: 2,
      company_name: "Sam Tours and Travels",
      current_date_amount: 0,
      current_date_nights: 0,
      mtd_amount: 0,
      mtd_nights: 0,
      ytd_amount: 0,
      ytd_nights: 0,
    },
    {
      agent_id: 3,
      company_name: "B2B",
      current_date_amount: 0,
      current_date_nights: 0,
      mtd_amount: 0,
      mtd_nights: 0,
      ytd_amount: 0,
      ytd_nights: 0,
    },
    {
      ytd_amount: "Total 200",
      ytd_nights: 0,
    },
  ];

  const downloadPdf = () => {
    const doc = new jsPDF();

    const tableData = data;

    doc?.autoTable({
      head: names.slice(0, 1),
      body: tableData,
    });

    doc.save("table.pdf");
  };

  const names = [
    {
      company_name: "Name",
      current_date_amount: "Amount",
      current_date_nights: "Nights",
      mtd_amount: "MTD Amt.",
      mtd_amount: "MTD Nts.",
      ytd_amount: "YTD Amt.",
      ytd_nights: "YTD Nts.",
    },
  ];

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div id="receipt" className="room-types-full-container reports-new-background">
          <div className="room-types-full-container-div">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <div className="room-type-heading">Agent Sales Report </div>
              {departureReport.length > 0 && (
                <div className="flex flex_gap_15">
                  <ButtonToDownloadTablePDF
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Agent Sales Report "}
                    ignoreFromPrint={"pdf"}
                    pageFormat={'A3'}
                  />
                  <DownloadCsvTables tableData={departureReport} names={names} />
                </div>
              )}
            </div>
          </div>

          {departureReport && departureReport.length > 0 ? (
            <>
              <LegacyCard>
                <IndexTable
                  resourceName={resourceName}
                  itemCount={departureReport.length}
                  selectable={false}
                  headings={[
                    { title: "Company Name" },
                    { title: "Current Date Amount" },
                    { title: "Current Date Nights" },
                    { title: "MTD Amount" },
                    { title: "MTD Night" },
                    { title: "YTD Amount" },
                    { title: "YTD Night" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>
              </LegacyCard>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>

        // </div>
      )}
    </>
  );
};
export default ReportAgentSaleReport;
