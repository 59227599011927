import React, { useState, useEffect } from "react";
import SidebarInnerLists from "./SidebarInnerLists";

import { BgThemeButton } from "../UI/Buttons";

import Loader from "../UI/Loader/Loader";

import HoldRoomsAgentsPopup from "./AgentHoldRooms/HoldRoomsAgentsPopup";

import AgentHoldRoomsUpliftMenuButton from "./AgentHoldRooms/AgentHoldRoomsUpliftMenuButton/AgentHoldRoomsUpliftMenuButton";

import { customers_inner_list } from "../../assets/constant";

import CommunicationCustomDatePicker from "./CommunicationCustomDatePicker";
import BookingSearchInputSmall from "./BookingSearchInputSmall";
import {
  calculateTotalArrayObjectKey,
  getCurrentPropertyDateHandler,
} from "../../utils/utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "./CustomShopifyIndexTable/CustomShopifyIndexTable";
import TruncatedText from "./TruncatedText/TruncatedText";
import AgentContractRoomsSliderSideBar from "./AgentHoldRooms/AgentContractRoomsSidebarContent";
import NoDataFound from "./NoDataFound";
import SliderComponentSkeleton from "../NewCustomComp/SliderComponentSkelon/SliderComponentSkeleton";
import AgentContractRoomsSidebarContent from "./AgentHoldRooms/AgentContractRoomsSidebarContent";
import ReleaseRoomsManualAgentsPopup from "./AgentHoldRooms/ReleaseRoomsManualAgentsPopup";
import ConfirmPopup from "../NewCustomComp/confirmPopup/ConfirmPopup";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { getDecryptedData } from "../../utils/encryptStorage";

const HoldRoomCreateAgent = () => {
  const navigate = useNavigate();
  const userToken = getDecryptedData('encryptToken');
  const [propertyCurrentDate, setPropertyCurrentDate] = useState();

  const property_id = useSelector((state) => state.property.value);
  useEffect(() => {
    if (property_id) {
      getCurrentPropertyDateHandler(property_id?.id, userToken)
        .then((date) => {
          setPropertyCurrentDate(date);
        })
        .catch((error) => console.error(error));
    }
  }, [property_id]);
  const [loader, setLoader] = useState(false);

  // =============side bar ========
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = (e, id) => {
    if (e) {
      e.stopPropagation();
    }
    fetchSingleAgentDetails(id);
    setIsSidebarOpen(!isSidebarOpen);
  };

  // uplift menu component
  // const options = ["Create Booking", "View Details", "Release Rooms", "Delete"];

  const [deleteId, setDeleteId] = useState(null);

  const handleUpliftMenuClick = (option, id) => {
    if (option === "View Details") {
      toggleSidebar("", id);
    } else if (option === "Release Rooms") {
      const filterData = agentContracts.filter((item) => item.id === id);
      setReleaseRoomData(filterData[0]);
      setShowReleaseRoomPopup(true);
    } else if (option === "Delete") {
      setDeleteId(id);
    } else if (option === "Create Booking") {
      navigate(`/agent-contracts/create-booking/${id}`);
    } else {
      return;
    }
  };

  const handleChildData = (data) => {};

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // 👇 Get input value
      // if (property_id) {
      // fetchInfo(property_id?.id);
      // }
    }
  };

  const [showHoldRoomsPopup, setShowHoldRoomsPopup] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);

  // new api immplememttions

  const [agentContracts, setAgentContracts] = useState([]);

  const fetchHoldRoomsListing = async (queryString) => {
    const url = queryString
      ? `${process.env.REACT_APP_BASE_URL}/api/v1/get/agent/contract/${property_id.id}?${queryString}`
      : `${process.env.REACT_APP_BASE_URL}/api/v1/get/agent/contract/${property_id.id}`;
    setLoader(true);
    try {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch current date from the API");
      }
      const data = await response.json();
      setAgentContracts(data.data);
      setLoader(false);
    } catch (error) {
      console.error(error);
      return "Error fetching data";
    }
  };

  useEffect(() => {
    const queryString = "dayTypeFilter=+90";
    if (property_id) {
      fetchHoldRoomsListing(queryString);
    }
  }, [userToken, property_id]);

  const options = ["Create Booking", "View Details", "Release Rooms", "Delete"];
  const optionsTwo = ["View Details", "Release Rooms", "Delete"];

  const rowMarkup = agentContracts.map(
    (
      { id, reference_person_name, roomDetails, from_date, to_date, status },
      index
    ) => (
      <CustomShopifyIndexTableRow
        key={index}
        id={id}
        showCheckbox={false}
        setSelectedItems={setSelectedRows}
        selectedItems={selectedRows}
      >
        <CustomShopifyIndexTableCell>
          <span
            style={{
              zIndex: "100000",
              cursor: "pointer",
              textDecoration: "underline",
              color: "#3968FF",
            }}
            onClick={(e) => toggleSidebar(e, id)}
          >
            {reference_person_name}
          </span>
        </CustomShopifyIndexTableCell>
        <CustomShopifyIndexTableCell>
          {calculateTotalArrayObjectKey(roomDetails, "hold_rooms")}
        </CustomShopifyIndexTableCell>
        <CustomShopifyIndexTableCell>
          {roomDetails.map((item, index) => (
            <>
              <div key={index}>
                {
                  <TruncatedText
                    longText={` ${item.hold_rooms} X    ${item.room_name}`}
                    width={"80px"}
                  />
                }
              </div>
            </>
          ))}
        </CustomShopifyIndexTableCell>
        <CustomShopifyIndexTableCell> {from_date}</CustomShopifyIndexTableCell>
        <CustomShopifyIndexTableCell> {to_date}</CustomShopifyIndexTableCell>
        <CustomShopifyIndexTableCell>
          <span
            style={{
              backgroundColor: status === "active" ? "#15AA12" : "#888888",
              color: "white",
              padding: "5px 10px",
              borderRadius: "20px",
            }}
          >
            {status}
          </span>
        </CustomShopifyIndexTableCell>

        <CustomShopifyIndexTableCell>
          <AgentHoldRoomsUpliftMenuButton
            menuOptions={status === "active" ? options : optionsTwo}
            handleUpliftMenuClick={handleUpliftMenuClick}
            style={{ zIndex: "2000" }}
            id={id}
          />
        </CustomShopifyIndexTableCell>
      </CustomShopifyIndexTableRow>
    )
  );

  //Date Range functionality

  const [customStyle, setCustomStyle] = useState(true);

  const [dayTypeFilter, setDayTypeFilter] = useState("-90");
  const [selectedDatesRange, setSelectedDatesRange] = useState({
    from_date: "",
    to_date: "",
  });
 

  const handleSelectedRangeChange = (selectedValue) => {
    if (selectedValue) {
      setSelectedDatesRange({
        from_date: "",
        to_date: "",
      });
      setDayTypeFilter(selectedValue);
      const queryString = `dayTypeFilter=${selectedValue}`;
      fetchHoldRoomsListing(queryString);
    }
  };
  const handleDateRangeSelected = ({ from_date, to_date }) => {
    if (from_date && to_date) {
      setDayTypeFilter("");
    }
    const queryString = `from_date=${from_date}&to_date=${to_date}`;
    fetchHoldRoomsListing(queryString);
  };

  const handleApplyButtonClick = ({ from_date, to_date }) => {
    setSelectedDatesRange({ from_date, to_date });
    if (from_date && to_date) {
      setDayTypeFilter("");
    }
    // console.log("apply");
    // fetchInfo(property_id?.id, selectedDatesRange.from_date, selectedDatesRange.to_date);
  };
  const handleOpenDatePickerData = () => {};

  const [singleAgentData, setSingleAgentData] = useState({});

  const fetchSingleAgentDetails = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        // https://dev.revcatalyst.in/api/v1/get/agent/contract/1?agentContractId=5
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/agent/contract/${property_id.id}?agentContractId=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch current date from the API");
      }
      const data = await response.json();
      setSingleAgentData(data.data);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
      return "Error fetching data";
    } finally {
      setLoader(false);
    }
  };

  const totalHoldRooms = agentContracts
    .map((item) => calculateTotalArrayObjectKey(item.roomDetails, "hold_rooms"))
    .reduce((total, item) => total + item, 0);

  const [showSlider, setShowSlider] = useState(false);
  const [showReleaseRoomPopup, setShowReleaseRoomPopup] = useState(false);
  const [releaseRoomData, setReleaseRoomData] = useState({});

  const handleContractsDelete = async () => {
    // delete/agent/contract/{property_id}

    try {
      setLoader(true);
      const response = await fetch(
        // https://dev.revcatalyst.in/api/v1/get/agent/contract/1?agentContractId=5
        `${process.env.REACT_APP_BASE_URL}/api/v1/delete/agent/contract/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          method: "DELETE",
          body: JSON.stringify({
            id: deleteId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch current date from the API");
      }
      const data = await response.json();
      setLoader(false);
      showSuccessToast(data.message);
    } catch (error) {
      console.error(error);
      showErrorToast(
        error.message || "An error occurred while processing your request."
      );
      setLoader(false);
      return "Error fetching data";
    } finally {
      setLoader(false);
      fetchHoldRoomsListing();
      setDeleteId("");
    }
  };

  return (
    <>
      <SidebarInnerLists innerLists={customers_inner_list} />
      <div className="room-types-full-container">
        <div className="room-types-full-container-div">
          <div className="room-type-heading">Agent Contracts</div>
          <div className="flex flex_gap_10">
            {/* <BgOutlineThemeButton
              children={"Contract"}
              onClick={handleNavigate}
            /> */}
            <BgThemeButton
              children={"Create Contract"}
              onClick={() => navigate("/agent-contracts/create")}
            />
          </div>
        </div>

        <div
          className="flex align_items_center flex_gap_10"
          style={{
            background: "#fff",
            padding: "5px 5px",
            borderRadius: "4px",
            margin: "10px 0px",
          }}
        >
          <div style={{ width: "200px" }}>
            <CommunicationCustomDatePicker
              onSelectedRangeChange={handleSelectedRangeChange}
              onApplyButtonClick={handleApplyButtonClick}
              onDateRangeSelected={handleDateRangeSelected}
              customStyle={customStyle}
              sendDataToParent={handleOpenDatePickerData}
            />
          </div>
          <div style={{ borderLeft: "1px solid #333", padding: "0 10px" }}>
            <b>{totalHoldRooms}</b> <span>Total Hold Rooms</span>
          </div>
          <div style={{ marginRight: "10px", marginLeft: "auto" }}>
            {/* <BookingSearchInputSmall
              onKeyDown={handleKeyDown}
              sendDataToParent={handleChildData}
              // setBookingId={setBookingId}
            /> */}
          </div>
        </div>

        <div
          className="booking-choice-full-parent-container-right"
          style={{ background: "#fff" }}
        >
          {loader ? (
            <Loader />
          ) : agentContracts.length > 0 ? (
            <CustomShopifyIndexTable
              headings={[
                "Company Name",
                "Hold Rooms/Day",
                "Room Category",
                "Hold Start Date",
                "Hold End Date",
                "Status",
                "",
              ]}
              // allItems={data}
              selectedItems={selectedRows}
              setSelectedItems={setSelectedRows}
              showCheckbox={false}
              style={{ border: "1px solid #ddd" }}
            >
              {rowMarkup}
            </CustomShopifyIndexTable>
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
      {/* <Outlet/> */}

      {showHoldRoomsPopup && (
        <div className="global_popup_container_background">
          <HoldRoomsAgentsPopup
            closePopup={setShowHoldRoomsPopup}
            propertyCurrentDate={propertyCurrentDate}
          />
        </div>
      )}

      {isSidebarOpen && (
        <SliderComponentSkeleton
          show={isSidebarOpen}
          setShow={setIsSidebarOpen}
          title={singleAgentData.reference_person_name}
        >
          <AgentContractRoomsSidebarContent
            propertyCurrentDate={propertyCurrentDate}
            setShow={setIsSidebarOpen}
            singleAgentData={singleAgentData}
            fetchHoldRoomsListing={fetchHoldRoomsListing}
          />
        </SliderComponentSkeleton>
      )}

      {showReleaseRoomPopup && (
        <div className="global_popup_container_background">
          <ReleaseRoomsManualAgentsPopup
            setShowReleaseRoomPopup={setShowReleaseRoomPopup}
            data={releaseRoomData}
          />
        </div>
      )}
      {deleteId && (
        <ConfirmPopup
          heading={"Are you sure ?"}
          subHeading={"Do you want to delete ?"}
          showPopup={deleteId}
          setShowPopup={setDeleteId}
          handleConfirmClick={handleContractsDelete}
        />
      )}
    </>
  );
};

export default HoldRoomCreateAgent;
