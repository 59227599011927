import React, { useEffect, useState } from "react";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import {
  BgOutlineThemeButton,
  BgThemeButton,
} from "../../Components/UI/Buttons";
import { PrimaryInput } from "../../Components/NewCustomComp/Inputs/Inputs";
import DateRangePicker from "../../Components/NewCustomComp/DateRangePicker/DateRangePicker";
import TimePicker from "../../Components/NewCustomComp/timePicker/TimePicker";
import {
  calculateDaysAndNights,
  findEmptyKeysInArray,
  getBaseUrl,
  getCurrentPropertyDateHandler,
} from "../../utils/utils";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SvgAlert, SvgGoBack, SvgMinus, SvgPlus } from "../../assets/svgIcons";
import styles from "./websiteEditOffers.module.scss";
import Loader from "../../Components/UI/Loader/Loader";
import ReactQuillTextEditor from "../../Components/NewCustomComp/ReactOuillTextEditor/ReactQuillTextEditor";
import UploadFileComponent from "../../Components/NewCustomComp/UploadFileComponent/UploadFileComponent";
import { SearchSelect } from "../../Components/NewCustomComp/Search/Search";
import { getDecryptedData } from "../../utils/encryptStorage";

const WebsitesCreateEditOffers = () => {
  const { offerID } = useParams();

  const [loader, setLoader] = useState(false);
  const property_list = useSelector((state) => state.properties.properties);
  const userToken = getDecryptedData('encryptToken');
  const navigate = useNavigate();
  const baseUrl = getBaseUrl();
  const property_id = useSelector((state) => state.property.value)?.id;
  const property = useSelector((state) => state.property.value);

  const [createOfferData, setCreateOfferData] = useState({
    package_title: "",
    featured_description: "",
    long_description: "",
    required_nights: "",
    required_days: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    terms_and_conditions: "",
    min_los: "",
    max_los: "",
    addons: [],
  });
  const handleOffersInputChange = (e) => {
    const { name, value } = e.target;
    setCreateOfferData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTextEditorInputChange = (name, value) => {
    setCreateOfferData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // Handler for DateRangePicker
  const handleDateRangeChange = (start, end) => {
    setCreateOfferData((prevData) => ({
      ...prevData,
      start_date: start,
      end_date: end,
    }));
  };

  // Handler for TimePicker
  const handleTimeChange = (name, value) => {
    setCreateOfferData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //==============================================

  const [rooms, setRooms] = useState([]);
  const [ratePlans, setRatePlans] = useState([]);

  const [offerData, setOfferData] = useState([]);
  const [fromData, setFromData] = useState({
    hotelId: "",
    hotelName: "",
    roomId: "",
    roomName: "",
    mealPlanId: "",
    mealPlanType: "",
    roomRate: "",
    extraAdultCost: "",
    childWithBedCost: "",
    childWithoutBedCost: "",
    base_adult: "",
    base_child: "",
    max_adult: "",
    max_child: "",
    max_occupancy: "",
    offerPrice: "",
  });

  const handlePropertyChangeFetchRooms = async (id) => {
    const hotelName = property_list.find(
      (item) => +item.id === +id
    ).property_name;
    try {
      setLoader(true);
      const response = await fetch(`${baseUrl}/api/v1/rooms/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });

      const data = await response.json();

      if (data.success) {
        setRooms(data.rooms); // Set rooms if successful
        setFromData((prev) => ({
          ...prev,
          hotelId: id,
          hotelName: hotelName,
          roomId: "",
          roomName: "",
          mealPlanId: "",
          mealPlanType: "",
        }));
      } else {
        showErrorToast("Something went wrong...");
      }
    } catch (error) {
      console.log(error);
      showErrorToast("Failed to fetch rooms.");
    } finally {
      setLoader(false);
    }
  };

  const handleRoomCategoryChange = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${baseUrl}/api/v1/get/rooms/rates/${id}/${fromData.hotelId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.roomRates) {
        setRatePlans(data.roomRates);
        setFromData((prev) => ({
          ...prev,
          roomId: id,
          roomName: rooms.find((room) => +room.id === +id)?.room_name,
          max_adult: rooms.find((room) => +room.id === +id)?.max_adults,
          max_child: rooms.find((room) => +room.id === +id)?.max_child,
          base_adult: rooms.find((room) => +room.id === +id)?.base_adult,
          base_child: rooms.find((room) => +room.id === +id)?.base_child,
          max_occupancy: rooms.find((room) => +room.id === +id)?.max_occupancy,
          mealPlanId: "",
          mealPlanType: "",
        }));
      } else {
        showErrorToast("Failed to fetch room rates.");
      }
    } catch (error) {
      showErrorToast("Failed to fetch meal plans.");
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const handleMealPlanChange = (id) => {
    const selectedPlan = ratePlans.find((plan) => +plan.id === +id);

    if (selectedPlan) {
      setFromData((prev) => ({
        ...prev,
        mealPlanId: id,
        mealPlanType: selectedPlan.rate_plan_type,
        roomRate: selectedPlan.rate_bar_price,
        childWithBedCost: selectedPlan.extra_adult,
        childWithoutBedCost: selectedPlan.extra_child,
        extraAdultCost: selectedPlan.extra_adult,
      }));
    }
  };

  const handleInputRatesChange = (e) => {
    const { name, value } = e.target;
    setFromData((prev) => ({ ...prev, [name]: value }));
  };

  const handleToOfferClick = () => {
    if (
      createOfferData.package_title === "" ||
      createOfferData.featured_description === "" ||
      createOfferData.long_description === "" ||
      createOfferData.required_nights === "" ||
      createOfferData.required_days === "" ||
      createOfferData.start_date === "" ||
      createOfferData.end_date === "" ||
      createOfferData.start_time === "" ||
      createOfferData.end_time === "" ||
      createOfferData.terms_and_conditions === "" ||
      createOfferData.min_los === "" ||
      createOfferData.max_los === ""
    ) {
      showErrorToast("Please fill all fields");
      return;
    }

    const {
      hotelId,
      hotelName,
      roomId,
      roomName,
      mealPlanId,
      mealPlanType,
      extraAdultCost,
      childWithBedCost,
      childWithoutBedCost,
      roomRate,
      base_adult,
      base_child,
      max_adult,
      max_child,
      max_occupancy,
    } = fromData;

    // Ensure all rate fields are filled
    if (
      hotelId === undefined ||
      hotelId === null ||
      roomId === undefined ||
      roomId === null ||
      mealPlanId === undefined ||
      mealPlanId === null ||
      extraAdultCost === undefined ||
      extraAdultCost === null ||
      childWithBedCost === undefined ||
      childWithBedCost === null ||
      childWithoutBedCost === undefined ||
      childWithoutBedCost === null ||
      base_adult === undefined ||
      base_adult === null ||
      base_child === undefined ||
      base_child === null ||
      max_adult === undefined ||
      max_adult === null ||
      max_child === undefined ||
      max_child === null ||
      max_occupancy === undefined ||
      max_occupancy === null
    ) {
      showErrorToast("Please fill out all rate fields.");
      return;
    }

    // Check for duplicate entry based on mealPlanId
    const isDuplicate =
      offerData &&
      offerData.length > 0 &&
      offerData.some((offer) => offer.mealPlanId === mealPlanId);
    if (isDuplicate) {
      showErrorToast("Duplicate entry: This meal plan is already added.");
      return;
    }

    const requiredNights = createOfferData.required_nights;

    // If valid, add the new offer to the array
    const newOffer = {
      hotelId,
      hotelName,
      roomId,
      roomName,
      mealPlanId,
      mealPlanType,
      extraAdultCost,
      childWithBedCost,
      childWithoutBedCost,
      roomRate,
      max_adult,
      max_child,
      base_adult,
      base_child,
      max_occupancy,
      offerPrice: +requiredNights * +roomRate,
    };

    setOfferData((prev) => [...prev, newOffer]);

    setFromData({
      hotelId: null,
      hotelName: "",
      roomId: null,
      roomName: "",
      mealPlanId: null,
      mealPlanType: "",
      extraAdultCost: "",
      childWithBedCost: "",
      childWithoutBedCost: "",
      roomRate: "",
      base_adult: "",
      base_child: "",
      max_adult: "",
      max_child: "",
      max_occupancy: "",
    });
  };

  const handleRemoveOffer = (index) => {
    setOfferData((prev) => prev.filter((_, i) => i !== index));
  };

  const submitOfferData = async () => {
    const validAddOns = createOfferData?.addons?.map((item) => {
      if (!item.no_of_nights || !item.quantity) {
        return false;
      } else {
        return true;
      }
    });

    if (validAddOns?.includes(false)) {
      showErrorToast("Please fill all the required fields.");
      return;
    }
    const totalAddOnCost =
      createOfferData?.addons?.reduce((acc, curr) => {
        const costForAddOn = +curr.cost * +curr.no_of_nights * +curr.quantity;
        return acc + costForAddOn;
      }, 0) || 0;

    const transformedData = offerData.reduce((acc, current) => {
      const existingHotel = acc.find(
        (hotel) => hotel.hotelId === current.hotelId
      );

      const roomDetails = {
        roomName: current.roomName,
        mealPlanId: current.mealPlanId,
        mealPlanType: current.mealPlanType,
        extraAdultCost: current.extraAdultCost,
        childWithBedCost: current.childWithBedCost,
        childWithoutBedCost: current.childWithoutBedCost,
        roomRate: current.roomRate,
        roomId: current.roomId,
        min_los: createOfferData.min_los,
        max_los: createOfferData.max_los,
        base_adult: current.base_adult,
        base_child: current.base_child,
        max_adult: current.max_adult,
        max_child: current.max_child,
        max_occupancy: current.max_occupancy,
        addon_cost: totalAddOnCost,
        offerPrice: +current.offerPrice + +totalAddOnCost,
      };

      if (existingHotel) {
        existingHotel.rooms.push(roomDetails);
      } else {
        acc.push({
          hotelId: current.hotelId,
          hotelName: current.hotelName,
          rooms: [roomDetails],
        });
      }

      return acc;
    }, []);

    const dayNights = calculateDaysAndNights(
      createOfferData.start_date,
      createOfferData.end_date
    );

    const package_cost = offerData?.reduce((acc, current) => {
      return acc + +current.roomRate;
    }, 0);

    const postData = {
      ...createOfferData,
      package_detailed_data: transformedData,
      is_active: true,
    };

    try {
      setLoader(true);
      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/packages/${property_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          method: "POST",
          body: JSON.stringify(postData),
        }
      );

      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        navigate(-1);
      } else {
        showErrorToast(data.message || "Failed to update stay restrictions.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      showErrorToast("An error occurred while updating stay restrictions.");
    } finally {
      setLoader(false);
    }
  };

  //============== edit single offer ===================

  const fetchOfferToEdit = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/packages/${property_id}?id=${offerID}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json().then((data) => {
        if (data.success) {
          setLoader(false);

          setCreateOfferData({
            package_title: data?.data.package_title || "",
            featured_description: data?.data?.featured_description || "",
            long_description: data.data?.long_description || "",
            terms_and_conditions: data.data?.terms_and_conditions || "",
            required_nights: data.data?.required_nights || "",
            required_days: data.data?.required_days || "",
            start_date: data.data?.start_date || "",
            end_date: data.data?.end_date || "",
            start_time: data.data?.start_time || "",
            end_time: data.data?.end_time || "",
            addons: data.data?.addons || [],
            min_los:
              data.data?.package_detailed_data[0]?.rooms[0]?.min_los || "",
            max_los:
              data.data?.package_detailed_data[0]?.rooms[0]?.max_los || "",
          });

          setOfferData(convertedData(data.data, data.data?.required_nights));
        } else {
          showErrorToast("Something went wrong...");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (property_id && offerID) {
      fetchOfferToEdit();
      fetchPropertyImages(offerID);
    }

    if (property_id) {
      getAddons();
    }
  }, [property_id, offerID]);

  const convertedData = (data, nights) => {
    return data?.package_detailed_data?.flatMap((hotel) =>
      hotel.rooms.map((room) => ({
        hotelId: hotel.hotelId,
        hotelName: hotel.hotelName,
        roomName: room.roomName,
        roomImage: room.roomImage,
        price: room.price,
        roomRate: room.roomRate,
        extraAdultCost: room.extraAdultCost,
        nights: room.nights,
        days: room.days,
        mealPlanId: room.mealPlanId,
        mealPlanType: room.mealPlanType,
        childWithBedCost: room.childWithBedCost,
        childWithoutBedCost: room.childWithoutBedCost,
        base_adult: room.base_adult,
        base_child: room.base_child,
        max_adult: room.max_adult,
        max_child: room.max_child,
        max_occupancy: room.max_occupancy, // Fixed this line
        roomId: room.roomId,
        offerPrice: +room.roomRate * +nights,
        ...room,
      }))
    );
  };

  const [images, setImages] = useState({
    package_featured_image: "",
    package_banner_image: "",
  });

  const fetchPropertyImages = async (id) => {
    try {
      setLoader(true);

      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/packages/media/1?media_type=package&associated_id=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        setImages(data.media);
      }
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  const handleFileChangeParentBanner = (data, name) => {
    setImages({ ...images, package_banner_image: data });
    setImages((prev) => ({
      ...prev,
      package_banner_image: data,
    }));
  };
  const handleFileChangeParentFeature = (data, name) => {
    setImages((prev) => ({
      ...prev,
      package_featured_image: data,
    }));
  };

  const dataURLtoBlob = (dataurl) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };
  const handleUploadImage = async (e, name) => {
    e.preventDefault();
    e.stopPropagation();
    if (!images[name]) {
      showErrorToast("Please upload an image before proceeding.");
      return;
    }
    const postData = new FormData();
    postData.append("media_type", "package");
    postData.append("associated_id", offerID);
    const imageBlob = dataURLtoBlob(images[name]);
    postData.append(name, imageBlob);

    try {
      setLoader(true);

      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/packages/media/${property_id}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          method: "POST",
          body: postData,
        }
      );

      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        fetchPropertyImages(offerID);
      } else {
        showErrorToast(data.message || "Failed to upload image.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      showErrorToast("An error occurred while uploading the image.");
    } finally {
      setLoader(false);
    }
  };

  const handleUpdateOffer = async () => {
    const validAddOns = createOfferData?.addons?.map((item) => {
      if (!item.no_of_nights || !item.quantity) {
        return false;
      } else {
        return true;
      }
    });

    if (validAddOns?.includes(false)) {
      showErrorToast("Please fill all the required fields.");
      return;
    }

    const totalAddOnCost =
      createOfferData?.addons?.reduce((acc, curr) => {
        if (!curr.no_of_nights || !curr.quantity) {
          showErrorToast("Please fill all the required fields.");
          return;
        }
        const costForAddOn = +curr.cost * +curr.no_of_nights * +curr.quantity;
        return acc + costForAddOn;
      }, 0) || 0;
    const transformedData = offerData.reduce((acc, current) => {
      const existingHotel = acc.find(
        (hotel) => hotel.hotelId === current.hotelId
      );

      const roomDetails = {
        roomName: current.roomName,
        mealPlanId: current.mealPlanId,
        mealPlanType: current.mealPlanType,
        extraAdultCost: current.extraAdultCost,
        childWithBedCost: current.childWithBedCost,
        childWithoutBedCost: current.childWithoutBedCost,
        roomRate: current.roomRate,
        min_los: createOfferData.min_los,
        max_los: createOfferData.max_los,
        base_adult: current.base_adult,
        base_child: current.base_child,
        max_adult: current.max_adult,
        max_child: current.max_child,
        max_occupancy: current.max_occupancy,
        roomId: current.roomId,
        addon_cost: totalAddOnCost,
        offerPrice: +current.offerPrice + +totalAddOnCost,
      };

      if (existingHotel) {
        existingHotel.rooms.push(roomDetails);
      } else {
        acc.push({
          hotelId: current.hotelId,
          hotelName: current.hotelName,
          rooms: [roomDetails],
        });
      }

      return acc;
    }, []);

    const postData = {
      ...createOfferData,
      package_detailed_data: transformedData,
      is_active: true,

      id: offerID,
    };

    try {
      setLoader(true);

      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/packages/${property_id}?id=${offerID}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          method: "POST",
          body: JSON.stringify(postData),
        }
      );

      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        navigate(-1);
      } else {
        showErrorToast(data.message || "Failed to update offer.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      showErrorToast("An error occurred while updating stay restrictions.");
    } finally {
      setLoader(false);
    }
  };

  // ================ add ons =============

  const [addons, setAddons] = useState([]);

  const getAddons = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/get/property/addon/${property_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      await response.json().then((data) => {
        if (data.success) {
          setLoader(false);
          setAddons(data.propertyAddonItem.data);
        } else {
          showErrorToast("Something went wrong...");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddOnsOptionChange = (data) => {
    const formattedData = {
      ...data,
      cost: (+data.price * +data.tax) / 100 + +data.price,
      no_of_nights: 1,
      quantity: 1,
    };

    setCreateOfferData((prev) => {
      const isDuplicate = prev.addons?.some(
        (item) => item.add_on === data.add_on
      );

      if (isDuplicate) {
        showErrorToast("Add-on already exists");
        return prev;
      }
      return {
        ...prev,
        addons: prev.addons ? [...prev.addons, formattedData] : [formattedData],
      };
    });
  };

  const handleRemoveAddOns = (index) => {
    setCreateOfferData((perv) => ({
      ...perv,
      addons: perv.addons.filter((_, i) => i !== index),
    }));
  };

  const handleAddOnsInputsChange = (e, index) => {
    const { name, value } = e.target;
    if (value === "0" || value === "0.00") {
      return;
    }
    setCreateOfferData((prev) => ({
      ...prev,
      addons: prev.addons.map((addon, i) =>
        i === index ? { ...addon, [name]: value } : addon
      ),
    }));
  };

  const [propertyCurrentDate, setPropertyCurrentDate] = useState();

  useEffect(() => {
    if (property_id) {
      getCurrentPropertyDateHandler(property_id, userToken)
        .then((date) => {
          setPropertyCurrentDate(date);
        })
        .catch((error) => console.error(error));
    }
  }, [property_id]);

  return (
    <>
      <div className={"main_container"}>
        {loader ? (
          <div className="w-100 h-100 flex justify_content_center align_items_center">
            <Loader />
          </div>
        ) : (
          <div style={{ width: "70%", margin: "auto" }}>
            <div className="room-type-heading mb-20 mt-10 flex align_items_center flex_gap_15">
              <span className="cursor-pointer" onClick={() => navigate(-1)}>
                <SvgGoBack />
              </span>
              <span>{offerID ? "Edit Offer" : "Create Offer"}</span>
            </div>
            <div
              style={{
                padding: "20px",
                background: "#fff",
                borderRadius: "10px",
              }}
            >
              <div>
                <div className="flex flex_gap_10">
                  <div className="w-50">
                    <PrimaryInput
                      label="Package Name"
                      size="medium"
                      name="package_title"
                      onChange={handleOffersInputChange}
                      value={createOfferData.package_title}
                    />
                  </div>

                  <div className="w-50">
                    <DateRangePicker
                      minDate={new Date(propertyCurrentDate)}
                      label="Select Date"
                      onChange={handleDateRangeChange}
                      size={"medium"}
                      defaultDate={{
                        startDate: createOfferData.start_date,
                        endDate: createOfferData.end_date,
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex_gap_10 mt-10">
                  <div className="w-50">
                    <TimePicker
                      label="Start Time"
                      onChange={(value) =>
                        handleTimeChange("start_time", value)
                      }
                      size={"medium"}
                      value={createOfferData.start_time}
                    />
                  </div>
                  <div className="w-50">
                    <TimePicker
                      label="End Time"
                      onChange={(value) => handleTimeChange("end_time", value)}
                      size={"medium"}
                      value={createOfferData.end_time}
                    />
                  </div>
                </div>
                <div className="flex flex_gap_10 mt-10">
                  <div className="w-50">
                    <PrimaryInput
                      label="Min. LOS"
                      size="medium"
                      name="min_los"
                      onChange={handleOffersInputChange}
                      value={createOfferData.min_los}
                      type={"number"}
                    />
                  </div>
                  <div className="w-50">
                    <PrimaryInput
                      label="Max LOS"
                      size="medium"
                      name="max_los"
                      onChange={handleOffersInputChange}
                      value={createOfferData.max_los}
                      type={"number"}
                    />
                  </div>
                </div>
                <div className="flex flex_gap_10 mt-10">
                  <div className="w-50">
                    <PrimaryInput
                      label="Required days"
                      size="medium"
                      name="required_days"
                      onChange={handleOffersInputChange}
                      value={createOfferData.required_days}
                      type={"number"}
                    />
                  </div>
                  <div className="w-50">
                    <PrimaryInput
                      label="Required Nights"
                      size="medium"
                      name="required_nights"
                      onChange={handleOffersInputChange}
                      value={createOfferData.required_nights}
                      type={"number"}
                    />
                  </div>
                </div>
                <div className="mt-10">
                  <ReactQuillTextEditor
                    handleTextChange={handleTextEditorInputChange}
                    name={"featured_description"}
                    editorHtml={createOfferData.featured_description}
                    label={"Short Description"}
                    labelSize={"medium"}
                  />
                </div>

                <div className="mt-10">
                  <ReactQuillTextEditor
                    handleTextChange={handleTextEditorInputChange}
                    name={"long_description"}
                    editorHtml={createOfferData.long_description}
                    label={"Long Description"}
                    labelSize={"medium"}
                  />
                </div>
                <div className="mt-10">
                  <ReactQuillTextEditor
                    handleTextChange={handleTextEditorInputChange}
                    name={"terms_and_conditions"}
                    editorHtml={createOfferData.terms_and_conditions}
                    label={"Terms and Conditions"}
                    labelSize={"medium"}
                  />
                </div>
              </div>

              <div
                className={`flex align_items_center flex_gap_10 mt-10 ${styles.selectTagContainer}`}
              >
                <div className="w-33 flex flex_direction_col flex_gap_5">
                  <label htmlFor="property-select">Select Property</label>
                  <select
                    id="property-select"
                    value={fromData.hotelId || ""}
                    onChange={(e) =>
                      handlePropertyChangeFetchRooms(e.target.value)
                    }
                  >
                    <option value="" disabled>
                      Select Property
                    </option>
                    {property_list.map((property) => (
                      <option key={property.id} value={property.id}>
                        {property.property_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="w-33 flex flex_direction_col flex_gap_5">
                  <label htmlFor="room-select">Select Room</label>
                  <select
                    id="room-select"
                    value={fromData.roomId || ""}
                    onChange={(e) => handleRoomCategoryChange(e.target.value)}
                    disabled={!rooms?.length}
                  >
                    <option value="" disabled>
                      Select Room
                    </option>
                    {rooms.map((room) => (
                      <option key={room.id} value={room.id}>
                        {room.room_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="w-33 flex flex_direction_col flex_gap_5">
                  <label htmlFor="meal-plan-select">Select Meal Plan</label>
                  <select
                    id="meal-plan-select"
                    value={fromData.mealPlanId || ""}
                    onChange={(e) => handleMealPlanChange(e.target.value)}
                    disabled={!ratePlans?.length}
                  >
                    <option value="" disabled>
                      Select Meal Plan
                    </option>
                    {ratePlans?.map((plan) => (
                      <option key={plan.id} value={plan.id}>
                        {plan.rate_plan_type}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {fromData?.hotelId && fromData.mealPlanId && fromData.roomId && (
                <>
                  <table className={`{styles.table} mt-10`}>
                    <tbody>
                      <tr>
                        <td>
                          <PrimaryInput
                            size={"small"}
                            type="number"
                            label={"Room Rate"}
                            name="roomRate"
                            value={fromData.roomRate}
                            onChange={handleInputRatesChange}
                          />
                        </td>
                        <td>
                          <PrimaryInput
                            size={"small"}
                            type="number"
                            name={"extraAdultCost"}
                            label={"Extra Adult"}
                            value={fromData.extraAdultCost}
                            onChange={handleInputRatesChange}
                          />
                        </td>
                        <td>
                          <PrimaryInput
                            size={"small"}
                            type="number"
                            label={"Child (with bed)"}
                            name={"childWithBedCost"}
                            value={fromData.childWithBedCost}
                            onChange={handleInputRatesChange}
                          />
                        </td>
                        <td>
                          <PrimaryInput
                            size={"small"}
                            type="number"
                            label={"Child (without bed)"}
                            value={fromData.childWithoutBedCost}
                            name={"childWithoutBedCost"}
                            onChange={handleInputRatesChange}
                          />
                        </td>
                        <td>
                          <PrimaryInput
                            size={"small"}
                            type="number"
                            label={"Base Adult"}
                            value={fromData.base_adult}
                            name={"base_adult"}
                            onChange={handleInputRatesChange}
                          />
                        </td>
                        <td>
                          <PrimaryInput
                            size={"small"}
                            type="number"
                            label={"Base Child"}
                            value={fromData.base_child}
                            name={"base_child"}
                            onChange={handleInputRatesChange}
                          />
                        </td>
                        <td>
                          <PrimaryInput
                            size={"small"}
                            type="number"
                            label={"Max Adult"}
                            value={fromData.max_adult}
                            name={"max_adult"}
                            onChange={handleInputRatesChange}
                          />
                        </td>
                        <td>
                          <PrimaryInput
                            size={"small"}
                            type="number"
                            label={"Max Child"}
                            value={fromData.max_child}
                            name={"max_child"}
                            onChange={handleInputRatesChange}
                          />
                        </td>
                        <td>
                          <PrimaryInput
                            size={"small"}
                            type="number"
                            label={"Max Occupancy"}
                            value={fromData.max_occupancy}
                            name={"max_occupancy"}
                            onChange={handleInputRatesChange}
                          />
                        </td>
                        <td style={{ verticalAlign: "bottom" }}>
                          <span
                            className="cursor-pointer w-100 h-100 flex align_items_end"
                            onClick={handleToOfferClick}
                          >
                            <SvgPlus />
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}
              <hr className="mt-20 mb-20" />
              <h2 className={styles.headings}>Offer Details</h2>
              <div className={styles.offer_table}>
                {offerData?.length > 0 ? (
                  <>
                    <table className={styles.table}>
                      <thead>
                        <tr>
                          <th>Hotel Name</th>
                          <th>Room Name</th>
                          <th>Meal Plan </th>
                          <th>Room Rate</th>
                          <th>Extra Adult</th>
                          <th>Child (With Bed) </th>
                          <th>Child (Without Bed)</th>
                          <th>Base Adult</th>
                          <th>Base Child</th>
                          <th>Max Adult</th>
                          <th>Max Child</th>
                          <th>Max Occupancy</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {offerData.map((item, index) => (
                          <tr key={index}>
                            <td>{item.hotelName}</td>
                            <td>{item.roomName}</td>
                            <td>{item.mealPlanType}</td>
                            <td>{item.roomRate}</td>
                            <td>{item.extraAdultCost}</td>
                            <td>{item.childWithBedCost}</td>
                            <td>{item.childWithoutBedCost}</td>
                            <td>{item.base_adult}</td>
                            <td>{item.base_child}</td>
                            <td>{item.max_adult}</td>
                            <td>{item.max_child}</td>
                            <td>{item.max_occupancy}</td>

                            <td>
                              <span onClick={() => handleRemoveOffer(index)}>
                                <SvgMinus />
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <div className={styles.noAddonsContainer}>
                    <div className={styles.noAddonsMessage}>
                      <SvgAlert />
                      <p>
                        Please add properties, rooms, and rate plans to view the
                        offer details.
                      </p>
                    </div>
                  </div>
                )}
              </div>

              {offerID ? (
                <div>
                  <hr className="mt-10 mb-10" />
                  <div className={`mb-10 ${styles.headings} `}>
                    Package Images
                  </div>

                  <div className="flex flex_gap_20 mb-10">
                    <div className="flex align_items_center">
                      <UploadFileComponent
                        fileType={"image"}
                        defaultFile={images?.package_banner_image?.src}
                        onFileChange={handleFileChangeParentBanner}
                      />
                      <div>
                        <BgThemeButton
                          onClick={(e) =>
                            handleUploadImage(e, "package_banner_image")
                          }
                        >
                          Upload
                        </BgThemeButton>
                      </div>
                    </div>

                    <div className="flex align_items_center">
                      <UploadFileComponent
                        fileType={"image"}
                        defaultFile={images?.package_featured_image?.src}
                        onFileChange={handleFileChangeParentFeature}
                      />
                      <div>
                        <BgThemeButton
                          onClick={(e) =>
                            handleUploadImage(e, "package_featured_image")
                          }
                        >
                          Upload
                        </BgThemeButton>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div>
                <hr className="mt-10 mb-10" />
                <div className={`mb-10 ${styles.headings}`}>Add Ons</div>
                <div className="w-100">
                  <SearchSelect
                    handleOptionChange={handleAddOnsOptionChange}
                    size={"medium"}
                    options={addons}
                    optionKeyName={"add_on"}
                  />
                  {createOfferData?.addons?.length > 0 ? (
                    <>
                      <table className={`${styles.table} mt-10`}>
                        <thead>
                          <tr>
                            <th>Sr. No</th>
                            <th>Addon</th>
                            <th>Price</th>
                            <th>Tax</th>
                            <th>Qty</th>
                            <th>Nights</th>

                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {createOfferData.addons.map((data, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{data.add_on}</td>
                              <td>{data.price}</td>
                              <td>{data.tax}%</td>
                              <td>
                                <div style={{ width: "50px" }}>
                                  <PrimaryInput
                                    size={"small"}
                                    value={data.quantity}
                                    name={"quantity"}
                                    onChange={(e) =>
                                      handleAddOnsInputsChange(e, index)
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <div style={{ width: "50px" }}>
                                  <PrimaryInput
                                    size={"small"}
                                    value={data.no_of_nights}
                                    name={"no_of_nights"}
                                    onChange={(e) =>
                                      handleAddOnsInputsChange(e, index)
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <span
                                  className="cursor-pointer"
                                  onClick={() => handleRemoveAddOns(index)}
                                >
                                  <SvgMinus />
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <div className={styles.noAddonsContainer}>
                      <div className={styles.noAddonsMessage}>
                        <SvgAlert />
                        <p>
                          No addons are selected . Please select an addon from
                          the list above.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className={` flex align_items_center justify_content_between mt-20`} >
                <div className="flex align_items_center flex_gap_10 ">
                  <BgThemeButton
                    disabled={offerData?.length === 0}
                    onClick={offerID ? handleUpdateOffer : submitOfferData}
                    children={offerID ? "Update" : "Create"}
                  />

                  <BgOutlineThemeButton onClick={() => navigate(-1)}>
                    Cancel
                  </BgOutlineThemeButton>
                </div>
              </div>

            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WebsitesCreateEditOffers;




// import React, { useEffect, useState } from "react";
// import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
// import {
//   BgOutlineThemeButton,
//   BgThemeButton,
// } from "../../Components/UI/Buttons";
// import { PrimaryInput } from "../../Components/NewCustomComp/Inputs/Inputs";
// import DateRangePicker from "../../Components/NewCustomComp/DateRangePicker/DateRangePicker";
// import TimePicker from "../../Components/NewCustomComp/timePicker/TimePicker";
// import {
//   calculateDaysAndNights,
//   findEmptyKeysInArray,
//   getBaseUrl,
//   getCurrentPropertyDateHandler,
// } from "../../utils/utils";
// import { useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import { SvgAlert, SvgGoBack, SvgMinus, SvgPlus } from "../../assets/svgIcons";
// import styles from "./websiteEditOffers.module.scss";
// import Loader from "../../Components/UI/Loader/Loader";
// import ReactQuillTextEditor from "../../Components/NewCustomComp/ReactOuillTextEditor/ReactQuillTextEditor";
// import UploadFileComponent from "../../Components/NewCustomComp/UploadFileComponent/UploadFileComponent";
// import { SearchSelect } from "../../Components/NewCustomComp/Search/Search";

// const WebsitesCreateEditOffers = () => {
//   const { offerID } = useParams();

//   const [loader, setLoader] = useState(false);
//   const property_list = useSelector((state) => state.properties.properties);
//   const userToken = getDecryptedData('encryptToken');
//   const navigate = useNavigate();
//   const baseUrl = getBaseUrl();
//   const property_id = useSelector((state) => state.property.value)?.id;
//   const property = useSelector((state) => state.property.value);

//   const [createOfferData, setCreateOfferData] = useState({
//     package_title: "",
//     featured_description: "",
//     long_description: "",
//     required_nights: "",
//     required_days: "",
//     start_date: "",
//     end_date: "",
//     start_time: "",
//     end_time: "",
//     terms_and_conditions: "",
//     min_los: "",
//     max_los: "",
//     addons: [],
//   });
//   const handleOffersInputChange = (e) => {
//     const { name, value } = e.target;
//     setCreateOfferData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleTextEditorInputChange = (name, value) => {
//     setCreateOfferData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };
//   // Handler for DateRangePicker
//   const handleDateRangeChange = (start, end) => {
//     setCreateOfferData((prevData) => ({
//       ...prevData,
//       start_date: start,
//       end_date: end,
//     }));
//   };

//   // Handler for TimePicker
//   const handleTimeChange = (name, value) => {
//     setCreateOfferData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   //==============================================

//   const [rooms, setRooms] = useState([]);
//   const [ratePlans, setRatePlans] = useState([]);

//   const [offerData, setOfferData] = useState([]);
//   const [fromData, setFromData] = useState({
//     hotelId: "",
//     hotelName: "",
//     roomId: "",
//     roomName: "",
//     mealPlanId: "",
//     mealPlanType: "",
//     roomRate: "",
//     extraAdultCost: "",
//     childWithBedCost: "",
//     childWithoutBedCost: "",
//     base_adult: "",
//     base_child: "",
//     max_adult: "",
//     max_child: "",
//     max_occupancy: "",
//     offerPrice: "",
//   });

//   const handlePropertyChangeFetchRooms = async (id) => {
//     const hotelName = property_list.find(
//       (item) => +item.id === +id
//     ).property_name;
//     try {
//       setLoader(true);
//       const response = await fetch(`${baseUrl}/api/v1/rooms/${id}`, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${userToken}`,
//         },
//       });

//       const data = await response.json();

//       if (data.success) {
//         setRooms(data.rooms); // Set rooms if successful
//         setFromData((prev) => ({
//           ...prev,
//           hotelId: id,
//           hotelName: hotelName,
//           roomId: "",
//           roomName: "",
//           mealPlanId: "",
//           mealPlanType: "",
//         }));
//       } else {
//         showErrorToast("Something went wrong...");
//       }
//     } catch (error) {
//       console.log(error);
//       showErrorToast("Failed to fetch rooms.");
//     } finally {
//       setLoader(false);
//     }
//   };

//   const handleRoomCategoryChange = async (id) => {
//     try {
//       setLoader(true);
//       const response = await fetch(
//         `${baseUrl}/api/v1/get/rooms/rates/${id}/${fromData.hotelId}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//         }
//       );

//       const data = await response.json();

//       if (data.roomRates) {
//         setRatePlans(data.roomRates);
//         setFromData((prev) => ({
//           ...prev,
//           roomId: id,
//           roomName: rooms.find((room) => +room.id === +id)?.room_name,
//           max_adult: rooms.find((room) => +room.id === +id)?.max_adults,
//           max_child: rooms.find((room) => +room.id === +id)?.max_child,
//           base_adult: rooms.find((room) => +room.id === +id)?.base_adult,
//           base_child: rooms.find((room) => +room.id === +id)?.base_child,
//           max_occupancy: rooms.find((room) => +room.id === +id)?.max_occupancy,
//           mealPlanId: "",
//           mealPlanType: "",
//         }));
//       } else {
//         showErrorToast("Failed to fetch room rates.");
//       }
//     } catch (error) {
//       showErrorToast("Failed to fetch meal plans.");
//       console.log(error);
//     } finally {
//       setLoader(false);
//     }
//   };

//   const handleMealPlanChange = (id) => {
//     const selectedPlan = ratePlans.find((plan) => +plan.id === +id);

//     if (selectedPlan) {
//       setFromData((prev) => ({
//         ...prev,
//         mealPlanId: id,
//         mealPlanType: selectedPlan.rate_plan_type,
//         roomRate: selectedPlan.rate_bar_price,
//         childWithBedCost: selectedPlan.extra_adult,
//         childWithoutBedCost: selectedPlan.extra_child,
//         extraAdultCost: selectedPlan.extra_adult,
//       }));
//     }
//   };

//   const handleInputRatesChange = (e) => {
//     const { name, value } = e.target;
//     setFromData((prev) => ({ ...prev, [name]: value }));
//   };

//   const handleToOfferClick = () => {
//     if (
//       createOfferData.package_title === "" ||
//       createOfferData.featured_description === "" ||
//       createOfferData.long_description === "" ||
//       createOfferData.required_nights === "" ||
//       createOfferData.required_days === "" ||
//       createOfferData.start_date === "" ||
//       createOfferData.end_date === "" ||
//       createOfferData.start_time === "" ||
//       createOfferData.end_time === "" ||
//       createOfferData.terms_and_conditions === "" ||
//       createOfferData.min_los === "" ||
//       createOfferData.max_los === ""
//     ) {
//       showErrorToast("Please fill all fields");
//       return;
//     }

//     const {
//       hotelId,
//       hotelName,
//       roomId,
//       roomName,
//       mealPlanId,
//       mealPlanType,
//       extraAdultCost,
//       childWithBedCost,
//       childWithoutBedCost,
//       roomRate,
//       base_adult,
//       base_child,
//       max_adult,
//       max_child,
//       max_occupancy,
//     } = fromData;

//     // Ensure all rate fields are filled
//     if (
//       hotelId === undefined ||
//       hotelId === null ||
//       roomId === undefined ||
//       roomId === null ||
//       mealPlanId === undefined ||
//       mealPlanId === null ||
//       extraAdultCost === undefined ||
//       extraAdultCost === null ||
//       childWithBedCost === undefined ||
//       childWithBedCost === null ||
//       childWithoutBedCost === undefined ||
//       childWithoutBedCost === null ||
//       base_adult === undefined ||
//       base_adult === null ||
//       base_child === undefined ||
//       base_child === null ||
//       max_adult === undefined ||
//       max_adult === null ||
//       max_child === undefined ||
//       max_child === null ||
//       max_occupancy === undefined ||
//       max_occupancy === null
//     ) {
//       showErrorToast("Please fill out all rate fields.");
//       return;
//     }

//     // Check for duplicate entry based on mealPlanId
//     const isDuplicate =
//       offerData &&
//       offerData.length > 0 &&
//       offerData.some((offer) => offer.mealPlanId === mealPlanId);
//     if (isDuplicate) {
//       showErrorToast("Duplicate entry: This meal plan is already added.");
//       return;
//     }

//     const requiredNights = createOfferData.required_nights;
//     const offer_tax = +roomRate > 7500 ? 18 :12
//     const offer_tax_amount = ((+requiredNights * +roomRate) * offer_tax) / 100
//     // If valid, add the new offer to the array
//     const newOffer = {
//       hotelId,
//       hotelName,
//       roomId,
//       roomName,
//       mealPlanId,
//       mealPlanType,
//       extraAdultCost,
//       childWithBedCost,
//       childWithoutBedCost,
//       roomRate,
//       max_adult,
//       max_child,
//       base_adult,
//       base_child,
//       max_occupancy,
//       offerPrice: (+requiredNights * +roomRate) + offer_tax_amount, 
//       offer_tax: offer_tax,
//       offer_tax_amount : offer_tax_amount
//     };

//     setOfferData((prev) => [...prev, newOffer]);

//     setFromData({
//       hotelId: null,
//       hotelName: "",
//       roomId: null,
//       roomName: "",
//       mealPlanId: null,
//       mealPlanType: "",
//       extraAdultCost: "",
//       childWithBedCost: "",
//       childWithoutBedCost: "",
//       roomRate: "",
//       base_adult: "",
//       base_child: "",
//       max_adult: "",
//       max_child: "",
//       max_occupancy: "",
//     });
//   };

//   const handleRemoveOffer = (index) => {
//     setOfferData((prev) => prev.filter((_, i) => i !== index));
//   };

//   const submitOfferData = async () => {
//     const validAddOns = createOfferData?.addons?.map((item) => {
//       if (!item.no_of_nights || !item.quantity) {
//         return false;
//       } else {
//         return true;
//       }
//     });

//     if (validAddOns?.includes(false)) {
//       showErrorToast("Please fill all the required fields.");
//       return;
//     }
//     const totalAddOnCost =
//       createOfferData?.addons?.reduce((acc, curr) => {
        
//         return acc + curr.add_on_cost;
//       }, 0) || 0;

//     const transformedData = offerData.reduce((acc, current) => {
//       const existingHotel = acc.find(
//         (hotel) => hotel.hotelId === current.hotelId
//       );

//       const roomDetails = {
//         roomName: current.roomName,
//         mealPlanId: current.mealPlanId,
//         mealPlanType: current.mealPlanType,
//         extraAdultCost: current.extraAdultCost,
//         childWithBedCost: current.childWithBedCost,
//         childWithoutBedCost: current.childWithoutBedCost,
//         roomRate: current.roomRate,
//         roomId: current.roomId,
//         min_los: createOfferData.min_los,
//         max_los: createOfferData.max_los,
//         base_adult: current.base_adult,
//         base_child: current.base_child,
//         max_adult: current.max_adult,
//         max_child: current.max_child,
//         max_occupancy: current.max_occupancy,
//         addon_cost: totalAddOnCost,
//         offerPrice: +current.offerPrice + +totalAddOnCost,
//       };

//       if (existingHotel) {
//         existingHotel.rooms.push(roomDetails);
//       } else {
//         acc.push({
//           hotelId: current.hotelId,
//           hotelName: current.hotelName,
//           rooms: [roomDetails],
//         });
//       }

//       return acc;
//     }, []);

//     const dayNights = calculateDaysAndNights(
//       createOfferData.start_date,
//       createOfferData.end_date
//     );

//     const package_cost = offerData?.reduce((acc, current) => {
//       return acc + +current.roomRate;
//     }, 0);

//     const postData = {
//       ...createOfferData,
//       package_detailed_data: transformedData,
//       is_active: true,
//     };

//     try {
//       setLoader(true);
//       const response = await fetch(
//         `${baseUrl}/api/v1/booking-engine/packages/${property_id}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//           method: "POST",
//           body: JSON.stringify(postData),
//         }
//       );

//       const data = await response.json();
//       if (data.success) {
//         showSuccessToast(data.message);
//         navigate(-1);
//       } else {
//         showErrorToast(data.message || "Failed to update stay restrictions.");
//       }
//     } catch (error) {
//       console.error("An error occurred:", error);
//       showErrorToast("An error occurred while updating stay restrictions.");
//     } finally {
//       setLoader(false);
//     }
//   };

//   //============== edit single offer ===================

//   const fetchOfferToEdit = async () => {
//     try {
//       const response = await fetch(
//         `${baseUrl}/api/v1/booking-engine/packages/${property_id}?id=${offerID}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//         }
//       );
//       const data = await response.json().then((data) => {
//         if (data.success) {
//           setLoader(false);

//           setCreateOfferData({
//             package_title: data?.data.package_title || "",
//             featured_description: data?.data?.featured_description || "",
//             long_description: data.data?.long_description || "",
//             terms_and_conditions: data.data?.terms_and_conditions || "",
//             required_nights: data.data?.required_nights || "",
//             required_days: data.data?.required_days || "",
//             start_date: data.data?.start_date || "",
//             end_date: data.data?.end_date || "",
//             start_time: data.data?.start_time || "",
//             end_time: data.data?.end_time || "",
//             addons: data.data?.addons || [],
//             min_los:
//               data.data?.package_detailed_data[0]?.rooms[0]?.min_los || "",
//             max_los:
//               data.data?.package_detailed_data[0]?.rooms[0]?.max_los || "",
//           });

//           setOfferData(convertedData(data.data, data.data?.required_nights));
//         } else {
//           showErrorToast("Something went wrong...");
//         }
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     if (property_id && offerID) {
//       fetchOfferToEdit();
//       fetchPropertyImages(offerID);
//     }

//     if (property_id) {
//       getAddons();
//     }
//   }, [property_id, offerID]);

//   const convertedData = (data, nights) => {
//     return data?.package_detailed_data?.flatMap((hotel) =>
//       hotel.rooms.map((room) => ({
//         hotelId: hotel.hotelId,
//         hotelName: hotel.hotelName,
//         roomName: room.roomName,
//         roomImage: room.roomImage,
//         price: room.price,
//         roomRate: room.roomRate,
//         extraAdultCost: room.extraAdultCost,
//         nights: room.nights,
//         days: room.days,
//         mealPlanId: room.mealPlanId,
//         mealPlanType: room.mealPlanType,
//         childWithBedCost: room.childWithBedCost,
//         childWithoutBedCost: room.childWithoutBedCost,
//         base_adult: room.base_adult,
//         base_child: room.base_child,
//         max_adult: room.max_adult,
//         max_child: room.max_child,
//         max_occupancy: room.max_occupancy, // Fixed this line
//         roomId: room.roomId,
//         offerPrice: +room.roomRate * +nights,
//         ...room,
//       }))
//     );
//   };

//   const [images, setImages] = useState({
//     package_featured_image: "",
//     package_banner_image: "",
//   });

//   const fetchPropertyImages = async (id) => {
//     try {
//       setLoader(true);

//       const response = await fetch(
//         `${baseUrl}/api/v1/booking-engine/packages/media/1?media_type=package&associated_id=${id}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//         }
//       );
//       const data = await response.json();
//       if (data.success) {
//         setImages(data.media);
//       }
//     } catch (error) {
//     } finally {
//       setLoader(false);
//     }
//   };

//   const handleFileChangeParentBanner = (data, name) => {
//     setImages({ ...images, package_banner_image: data });
//     setImages((prev) => ({
//       ...prev,
//       package_banner_image: data,
//     }));
//   };

//   const handleFileChangeParentFeature = (data, name) => {
//     setImages((prev) => ({
//       ...prev,
//       package_featured_image: data,
//     }));
//   };

//   const dataURLtoBlob = (dataurl) => {
//     const arr = dataurl.split(",");
//     const mime = arr[0].match(/:(.*?);/)[1];
//     const bstr = atob(arr[1]);
//     let n = bstr.length;
//     const u8arr = new Uint8Array(n);
//     while (n--) {
//       u8arr[n] = bstr.charCodeAt(n);
//     }
//     return new Blob([u8arr], { type: mime });
//   };

//   const handleUploadImage = async (e, name) => {
//     e.preventDefault();
//     e.stopPropagation();
//     if (!images[name]) {
//       showErrorToast("Please upload an image before proceeding.");
//       return;
//     }
//     const postData = new FormData();
//     postData.append("media_type", "package");
//     postData.append("associated_id", offerID);
//     const imageBlob = dataURLtoBlob(images[name]);
//     postData.append(name, imageBlob);

//     try {
//       setLoader(true);

//       const response = await fetch(
//         `${baseUrl}/api/v1/booking-engine/packages/media/${property_id}`,
//         {
//           headers: {
//             Authorization: `Bearer ${userToken}`,
//           },
//           method: "POST",
//           body: postData,
//         }
//       );

//       const data = await response.json();
//       if (data.success) {
//         showSuccessToast(data.message);
//         fetchPropertyImages(offerID);
//       } else {
//         showErrorToast(data.message || "Failed to upload image.");
//       }
//     } catch (error) {
//       console.error("An error occurred:", error);
//       showErrorToast("An error occurred while uploading the image.");
//     } finally {
//       setLoader(false);
//     }
//   };

//   const handleUpdateOffer = async () => {
//     const validAddOns = createOfferData?.addons?.map((item) => {
//       if (!item.no_of_nights || !item.quantity) {
//         return false;
//       } else {
//         return true;
//       }
//     });

//     if (validAddOns?.includes(false)) {
//       showErrorToast("Please fill all the required fields.");
//       return;
//     }

//     const totalAddOnCost =
//       createOfferData?.addons?.reduce((acc, curr) => {
//         if (!curr.no_of_nights || !curr.quantity) {
//           showErrorToast("Please fill all the required fields.");
//           return;
//         }
      
//         return acc + curr.add_on_cost;
//       }, 0) || 0;
//     const transformedData = offerData.reduce((acc, current) => {
//       const existingHotel = acc.find(
//         (hotel) => hotel.hotelId === current.hotelId
//       );

//       const roomDetails = {
//         roomName: current.roomName,
//         mealPlanId: current.mealPlanId,
//         mealPlanType: current.mealPlanType,
//         extraAdultCost: current.extraAdultCost,
//         childWithBedCost: current.childWithBedCost,
//         childWithoutBedCost: current.childWithoutBedCost,
//         roomRate: current.roomRate,
//         min_los: createOfferData.min_los,
//         max_los: createOfferData.max_los,
//         base_adult: current.base_adult,
//         base_child: current.base_child,
//         max_adult: current.max_adult,
//         max_child: current.max_child,
//         max_occupancy: current.max_occupancy,
//         roomId: current.roomId,
//         addon_cost: totalAddOnCost,
//         offerPrice: +current.offerPrice + totalAddOnCost,
//       };

//       if (existingHotel) {
//         existingHotel.rooms.push(roomDetails);
//       } else {
//         acc.push({
//           hotelId: current.hotelId,
//           hotelName: current.hotelName,
//           rooms: [roomDetails],
//         });
//       }

//       return acc;
//     }, []);

//     const postData = {
//       ...createOfferData,
//       package_detailed_data: transformedData,
//       is_active: true,

//       id: offerID,
//     };

//     try {
//       setLoader(true);

//       const response = await fetch(
//         `${baseUrl}/api/v1/booking-engine/packages/${property_id}?id=${offerID}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//           method: "POST",
//           body: JSON.stringify(postData),
//         }
//       );

//       const data = await response.json();
//       if (data.success) {
//         showSuccessToast(data.message);
//         navigate(-1);
//       } else {
//         showErrorToast(data.message || "Failed to update offer.");
//       }
//     } catch (error) {
//       console.error("An error occurred:", error);
//       showErrorToast("An error occurred while updating stay restrictions.");
//     } finally {
//       setLoader(false);
//     }
//   };

//   // ================ add ons =============

//   const [addons, setAddons] = useState([]);

//   const getAddons = async () => {
//     try {
//       const response = await fetch(
//         `${baseUrl}/api/v1/get/property/addon/${property_id}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//         }
//       );
//       await response.json().then((data) => {
//         if (data.success) {
//           setLoader(false);
//           setAddons(data.propertyAddonItem.data);
//         } else {
//           showErrorToast("Something went wrong...");
//         }
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleAddOnsOptionChange = (data) => {
//     const formattedData = {
//       add_on: data.add_on,
//       hsncode: data.hsncode,
//       no_of_nights: 1,
//       quantity: 1,
//       tax: 18,
//       add_on_tax_amount: (+data.price * 18 )/ 100,
//       add_on_cost:((+data.price * 18 )/ 100  + +data.price), 
//       price: +data.price,
//     };

//     setCreateOfferData((prev) => {
//       const isDuplicate = prev.addons?.some(
//         (item) => item.add_on === data.add_on
//       );

//       if (isDuplicate) {
//         showErrorToast("Add-on already exists");
//         return prev;
//       }
//       return {
//         ...prev,
//         addons: prev.addons ? [...prev.addons, formattedData] : [formattedData],
//       };
//     });
//   };

//   const handleRemoveAddOns = (index) => {
//     setCreateOfferData((perv) => ({
//       ...perv,
//       addons: perv.addons.filter((_, i) => i !== index),
//     }));
//   };

//   const handleAddOnsInputsChange = (e, index) => {
//     const { name, value } = e.target;
    
//     if (value === "0" || value === "0.00") {
//       return;
//     }
  
//     setCreateOfferData((prev) => ({
//       ...prev,
//       addons: prev.addons.map((addon, i) => {
//         if (i === index) {
//           const updatedAddon = { ...addon, [name]: value };
//           const updatedQuantity = +updatedAddon.quantity || 1; // ensure quantity is a number, default to 1
//           const updatedNights = +updatedAddon.no_of_nights || 1; // ensure no_of_nights is a number, default to 1
  
//           const newTaxAmount = (+addon.price * 18) / 100 * updatedQuantity * updatedNights;
//           const newAddOnCost = newTaxAmount + (+addon.price * updatedQuantity * updatedNights);
  
//           return {
//             ...updatedAddon,
//             add_on_tax_amount: newTaxAmount,
//             add_on_cost: newAddOnCost
//           };
//         }
//         return addon;
//       })
//     }));
//   };
 
//   const [propertyCurrentDate, setPropertyCurrentDate] = useState();

//   useEffect(() => {
//     if (property_id) {
//       getCurrentPropertyDateHandler(property_id, userToken)
//         .then((date) => {
//           setPropertyCurrentDate(date);
//         })
//         .catch((error) => console.error(error));
//     }
//   }, [property_id]);

//   return (
//     <>
//       <div className={"main_container"}>
//         {loader ? (
//           <div className="w-100 h-100 flex justify_content_center align_items_center">
//             <Loader />
//           </div>
//         ) : (
//           <div style={{ width: "70%", margin: "auto" }}>
//             <div className="room-type-heading mb-20 mt-10 flex align_items_center flex_gap_15">
//               <span className="cursor-pointer" onClick={() => navigate(-1)}>
//                 <SvgGoBack />
//               </span>
//               <span>{offerID ? "Edit Offer" : "Create Offer"}</span>
//             </div>
//             <div
//               style={{
//                 padding: "20px",
//                 background: "#fff",
//                 borderRadius: "10px",
//               }}
//             >
//               <div>
//                 <div className="flex flex_gap_10">
//                   <div className="w-50">
//                     <PrimaryInput
//                       label="Package Name"
//                       size="medium"
//                       name="package_title"
//                       onChange={handleOffersInputChange}
//                       value={createOfferData.package_title}
//                     />
//                   </div>

//                   <div className="w-50">
//                     <DateRangePicker
//                       minDate={new Date(propertyCurrentDate)}
//                       label="Select Date"
//                       onChange={handleDateRangeChange}
//                       size={"medium"}
//                       defaultDate={{
//                         startDate: createOfferData.start_date,
//                         endDate: createOfferData.end_date,
//                       }}
//                     />
//                   </div>
//                 </div>

//                 <div className="flex flex_gap_10 mt-10">
//                   <div className="w-50">
//                     <TimePicker
//                       label="Start Time"
//                       onChange={(value) =>
//                         handleTimeChange("start_time", value)
//                       }
//                       size={"medium"}
//                       value={createOfferData.start_time}
//                     />
//                   </div>
//                   <div className="w-50">
//                     <TimePicker
//                       label="End Time"
//                       onChange={(value) => handleTimeChange("end_time", value)}
//                       size={"medium"}
//                       value={createOfferData.end_time}
//                     />
//                   </div>
//                 </div>
//                 <div className="flex flex_gap_10 mt-10">
//                   <div className="w-50">
//                     <PrimaryInput
//                       label="Min. LOS"
//                       size="medium"
//                       name="min_los"
//                       onChange={handleOffersInputChange}
//                       value={createOfferData.min_los}
//                       type={"number"}
//                     />
//                   </div>
//                   <div className="w-50">
//                     <PrimaryInput
//                       label="Max LOS"
//                       size="medium"
//                       name="max_los"
//                       onChange={handleOffersInputChange}
//                       value={createOfferData.max_los}
//                       type={"number"}
//                     />
//                   </div>
//                 </div>
//                 <div className="flex flex_gap_10 mt-10">
//                   <div className="w-50">
//                     <PrimaryInput
//                       label="Required days"
//                       size="medium"
//                       name="required_days"
//                       onChange={handleOffersInputChange}
//                       value={createOfferData.required_days}
//                       type={"number"}
//                     />
//                   </div>
//                   <div className="w-50">
//                     <PrimaryInput
//                       label="Required Nights"
//                       size="medium"
//                       name="required_nights"
//                       onChange={handleOffersInputChange}
//                       value={createOfferData.required_nights}
//                       type={"number"}
//                     />
//                   </div>
//                 </div>
//                 <div className="mt-10">
//                   <ReactQuillTextEditor
//                     handleTextChange={handleTextEditorInputChange}
//                     name={"featured_description"}
//                     editorHtml={createOfferData.featured_description}
//                     label={"Short Description"}
//                     labelSize={"medium"}
//                   />
//                 </div>

//                 <div className="mt-10">
//                   <ReactQuillTextEditor
//                     handleTextChange={handleTextEditorInputChange}
//                     name={"long_description"}
//                     editorHtml={createOfferData.long_description}
//                     label={"Long Description"}
//                     labelSize={"medium"}
//                   />
//                 </div>
//                 <div className="mt-10">
//                   <ReactQuillTextEditor
//                     handleTextChange={handleTextEditorInputChange}
//                     name={"terms_and_conditions"}
//                     editorHtml={createOfferData.terms_and_conditions}
//                     label={"Terms and Conditions"}
//                     labelSize={"medium"}
//                   />
//                 </div>
//               </div>

//               <div
//                 className={`flex align_items_center flex_gap_10 mt-10 ${styles.selectTagContainer}`}
//               >
//                 <div className="w-33 flex flex_direction_col flex_gap_5">
//                   <label htmlFor="property-select">Select Property</label>
//                   <select
//                     id="property-select"
//                     value={fromData.hotelId || ""}
//                     onChange={(e) =>
//                       handlePropertyChangeFetchRooms(e.target.value)
//                     }
//                   >
//                     <option value="" disabled>
//                       Select Property
//                     </option>
//                     {property_list.map((property) => (
//                       <option key={property.id} value={property.id}>
//                         {property.property_name}
//                       </option>
//                     ))}
//                   </select>
//                 </div>

//                 <div className="w-33 flex flex_direction_col flex_gap_5">
//                   <label htmlFor="room-select">Select Room</label>
//                   <select
//                     id="room-select"
//                     value={fromData.roomId || ""}
//                     onChange={(e) => handleRoomCategoryChange(e.target.value)}
//                     disabled={!rooms?.length}
//                   >
//                     <option value="" disabled>
//                       Select Room
//                     </option>
//                     {rooms.map((room) => (
//                       <option key={room.id} value={room.id}>
//                         {room.room_name}
//                       </option>
//                     ))}
//                   </select>
//                 </div>

//                 <div className="w-33 flex flex_direction_col flex_gap_5">
//                   <label htmlFor="meal-plan-select">Select Meal Plan</label>
//                   <select
//                     id="meal-plan-select"
//                     value={fromData.mealPlanId || ""}
//                     onChange={(e) => handleMealPlanChange(e.target.value)}
//                     disabled={!ratePlans?.length}
//                   >
//                     <option value="" disabled>
//                       Select Meal Plan
//                     </option>
//                     {ratePlans?.map((plan) => (
//                       <option key={plan.id} value={plan.id}>
//                         {plan.rate_plan_type}
//                       </option>
//                     ))}
//                   </select>
//                 </div>
//               </div>

//               {fromData?.hotelId && fromData.mealPlanId && fromData.roomId && (
//                 <>
//                   <table className={`{styles.table} mt-10`}>
//                     <tbody>
//                       <tr>
//                         <td>
//                           <PrimaryInput
//                             size={"small"}
//                             type="number"
//                             label={"Room Rate"}
//                             name="roomRate"
//                             value={fromData.roomRate}
//                             onChange={handleInputRatesChange}
//                           />
//                         </td>
//                         <td>
//                           <PrimaryInput
//                             size={"small"}
//                             type="number"
//                             name={"extraAdultCost"}
//                             label={"Extra Adult"}
//                             value={fromData.extraAdultCost}
//                             onChange={handleInputRatesChange}
//                           />
//                         </td>
//                         <td>
//                           <PrimaryInput
//                             size={"small"}
//                             type="number"
//                             label={"Child (with bed)"}
//                             name={"childWithBedCost"}
//                             value={fromData.childWithBedCost}
//                             onChange={handleInputRatesChange}
//                           />
//                         </td>
//                         <td>
//                           <PrimaryInput
//                             size={"small"}
//                             type="number"
//                             label={"Child (without bed)"}
//                             value={fromData.childWithoutBedCost}
//                             name={"childWithoutBedCost"}
//                             onChange={handleInputRatesChange}
//                           />
//                         </td>
//                         <td>
//                           <PrimaryInput
//                             size={"small"}
//                             type="number"
//                             label={"Base Adult"}
//                             value={fromData.base_adult}
//                             name={"base_adult"}
//                             onChange={handleInputRatesChange}
//                           />
//                         </td>
//                         <td>
//                           <PrimaryInput
//                             size={"small"}
//                             type="number"
//                             label={"Base Child"}
//                             value={fromData.base_child}
//                             name={"base_child"}
//                             onChange={handleInputRatesChange}
//                           />
//                         </td>
//                         <td>
//                           <PrimaryInput
//                             size={"small"}
//                             type="number"
//                             label={"Max Adult"}
//                             value={fromData.max_adult}
//                             name={"max_adult"}
//                             onChange={handleInputRatesChange}
//                           />
//                         </td>
//                         <td>
//                           <PrimaryInput
//                             size={"small"}
//                             type="number"
//                             label={"Max Child"}
//                             value={fromData.max_child}
//                             name={"max_child"}
//                             onChange={handleInputRatesChange}
//                           />
//                         </td>
//                         <td>
//                           <PrimaryInput
//                             size={"small"}
//                             type="number"
//                             label={"Max Occupancy"}
//                             value={fromData.max_occupancy}
//                             name={"max_occupancy"}
//                             onChange={handleInputRatesChange}
//                           />
//                         </td>
//                         <td style={{ verticalAlign: "bottom" }}>
//                           <span
//                             className="cursor-pointer w-100 h-100 flex align_items_end"
//                             onClick={handleToOfferClick}
//                           >
//                             <SvgPlus />
//                           </span>
//                         </td>
//                       </tr>
//                     </tbody>
//                   </table>
//                 </>
//               )}
//               <hr className="mt-20 mb-20" />
//               <h2 className={styles.headings}>Offer Details</h2>
//               <div className={styles.offer_table}>
//                 {offerData?.length > 0 ? (
//                   <>
//                     <table className={styles.table}>
//                       <thead>
//                         <tr>
//                           <th>Hotel Name</th>
//                           <th>Room Name</th>
//                           <th>Meal Plan </th>
//                           <th>Room Rate</th>
//                           <th>Extra Adult</th>
//                           <th>Child (With Bed) </th>
//                           <th>Child (Without Bed)</th>
//                           <th>Base Adult</th>
//                           <th>Base Child</th>
//                           <th>Max Adult</th>
//                           <th>Max Child</th>
//                           <th>Max Occupancy</th>
//                           <th></th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {offerData.map((item, index) => (
//                           <tr key={index}>
//                             <td>{item.hotelName}</td>
//                             <td>{item.roomName}</td>
//                             <td>{item.mealPlanType}</td>
//                             <td>{item.roomRate}</td>
//                             <td>{item.extraAdultCost}</td>
//                             <td>{item.childWithBedCost}</td>
//                             <td>{item.childWithoutBedCost}</td>
//                             <td>{item.base_adult}</td>
//                             <td>{item.base_child}</td>
//                             <td>{item.max_adult}</td>
//                             <td>{item.max_child}</td>
//                             <td>{item.max_occupancy}</td>

//                             <td>
//                               <span onClick={() => handleRemoveOffer(index)}>
//                                 <SvgMinus />
//                               </span>
//                             </td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </table>
//                   </>
//                 ) : (
//                   <div className={styles.noAddonsContainer}>
//                     <div className={styles.noAddonsMessage}>
//                       <SvgAlert />
//                       <p>
//                         Please add properties, rooms, and rate plans to view the
//                         offer details.
//                       </p>
//                     </div>
//                   </div>
//                 )}
//               </div>

//               {offerID ? (
//                 <div>
//                   <hr className="mt-10 mb-10" />
//                   <div className={`mb-10 ${styles.headings} `}>
//                     Package Images
//                   </div>

//                   <div className="flex flex_gap_20 mb-10">
//                     <div className="flex align_items_center">
//                       <UploadFileComponent
//                         fileType={"image"}
//                         defaultFile={images?.package_banner_image?.src}
//                         onFileChange={handleFileChangeParentBanner}
//                       />
//                       <div>
//                         <BgThemeButton
//                           onClick={(e) =>
//                             handleUploadImage(e, "package_banner_image")
//                           }
//                         >
//                           Upload
//                         </BgThemeButton>
//                       </div>
//                     </div>

//                     <div className="flex align_items_center">
//                       <UploadFileComponent
//                         fileType={"image"}
//                         defaultFile={images?.package_featured_image?.src}
//                         onFileChange={handleFileChangeParentFeature}
//                       />
//                       <div>
//                         <BgThemeButton
//                           onClick={(e) =>
//                             handleUploadImage(e, "package_featured_image")
//                           }
//                         >
//                           Upload
//                         </BgThemeButton>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ) : null}

//               <div>
//                 <hr className="mt-10 mb-10" />
//                 <div className={`mb-10 ${styles.headings}`}>Add Ons</div>
//                 <div className="w-100">
//                   <SearchSelect
//                     handleOptionChange={handleAddOnsOptionChange}
//                     size={"medium"}
//                     options={addons}
//                     optionKeyName={"add_on"}
//                   />
//                   {createOfferData?.addons?.length > 0 ? (
//                     <>
//                       <table className={`${styles.table} mt-10`}>
//                         <thead>
//                           <tr>
//                             <th>Sr. No</th>
//                             <th>Addon</th>
//                             <th>Price</th>
//                             <th>Tax</th>
//                             <th>Qty</th>
//                             <th>Nights</th>

//                             <th></th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {createOfferData.addons.map((data, index) => (
//                             <tr key={index}>
//                               <td>{index + 1}</td>
//                               <td>{data.add_on}</td>
//                               <td>{data.price}</td>
//                               <td>{data.tax}%</td>
//                               <td>
//                                 <div style={{ width: "50px" }}>
//                                   <PrimaryInput
//                                     size={"small"}
//                                     value={data.quantity}
//                                     name={"quantity"}
//                                     onChange={(e) =>
//                                       handleAddOnsInputsChange(e, index)
//                                     }
//                                   />
//                                 </div>
//                               </td>
//                               <td>
//                                 <div style={{ width: "50px" }}>
//                                   <PrimaryInput
//                                     size={"small"}
//                                     value={data.no_of_nights}
//                                     name={"no_of_nights"}
//                                     onChange={(e) =>
//                                       handleAddOnsInputsChange(e, index)
//                                     }
//                                   />
//                                 </div>
//                               </td>
//                               <td>
//                                 <span
//                                   className="cursor-pointer"
//                                   onClick={() => handleRemoveAddOns(index)}
//                                 >
//                                   <SvgMinus />
//                                 </span>
//                               </td>
//                             </tr>
//                           ))}
//                         </tbody>
//                       </table>
//                     </>
//                   ) : (
//                     <div className={styles.noAddonsContainer}>
//                       <div className={styles.noAddonsMessage}>
//                         <SvgAlert />
//                         <p>
//                           No addons are selected . Please select an addon from
//                           the list above.
//                         </p>
//                       </div>
//                     </div>
//                   )}
//                 </div>
//               </div>

//               <div className={` flex align_items_center justify_content_between mt-20`} >
//                 <div className="flex align_items_center flex_gap_10 ">
//                   <BgThemeButton
//                     disabled={offerData?.length === 0}
//                     onClick={offerID ? handleUpdateOffer : submitOfferData}
//                     children={offerID ? "Update" : "Create"}
//                   />

//                   <BgOutlineThemeButton onClick={() => navigate(-1)}>
//                     Cancel
//                   </BgOutlineThemeButton>
//                 </div>
//               </div>
              
//             </div>
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// export default WebsitesCreateEditOffers;

