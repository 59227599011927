import React, { useEffect } from "react";
import Dashboard from "../../Components/Dashboard";
import Reports from "../../Components/Reports";
import FrontOfficeReports from "../../Components/Reports/FrontOfficeReports";
import ProductionReports from "../../Components/Reports/ProductionReports";

const ReportsFrontsOfficePage = () => {
  useEffect(() => {
    // Set the meta title
    document.title = "Reports - RevCatalyst - Boosting Your Hotel Performance";

    // Create or update the meta description tag
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property.";
  }, []);

  return (
    <>
      <div className="main_container">
        <FrontOfficeReports />
      </div>
    </>
  );
};

export default ReportsFrontsOfficePage;
