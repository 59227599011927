import { useSelector } from "react-redux";
import "./WebsiteSubMenus.css";
import React, { useEffect, useState } from "react";
import { showErrorToast } from "../../assets/toastUtils";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import SidebarInnerLists from "../../Components/CustomComponents/SidebarInnerLists";
import { websites_inner_lists } from "../../assets/constant";
import GoBackButtonCustom from "../../Components/GoBackButtonCustom/GoBackButtonCustom";

const WebsiteSubMenus = () => {
  const userToken = localStorage.getItem("token");
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(true);

  const [data, setData] = useState([
    {
      name: "Main Menu",
      slug: "/main-menu",
      id: "1",
      items: [
        { name: "Hotels", slug: "", id: "32" },
        { name: "Destinations", slug: "", id: "33" },
        { name: "Offers", slug: "", id: "34" },
        { name: "Blogs", slug: "", id: "4" },
        { name: "About", slug: "", id: "3" },
        { name: "Book Now", slug: "", id: "g" },
      ],
    },
    {
      name: "Menu Bar",
      slug: "/main-menu",
      id: "2",
      items: [
        { name: "Our Story", slug: "", id: "rr" },
        { name: "fAQs", slug: "", id: "er" },
        { name: "legal notice", slug: "", id: "e" },
        { name: "terms and conditions", slug: "", id: "vv" },
        { name: "refund and cancellation policies", slug: "", id: "vef" },
      ],
    },
    {
      name: "Footer Menu",
      slug: "/main-menu",
      id: "3",
      items: [
        { name: "Hotels", slug: "", id: "vf" },
        { name: "our destinations", slug: "", id: "ghg" },
        { name: "top sightseeings", slug: "", id: "gfh" },
        { name: "contact us", slug: "", id: "gh" },
        { name: "follow us", slug: "", id: "dhg" },
      ],
    },
  ]);

  // const data1 = [
  //   {
  //     id: 10,
  //     property_id: 10,
  //     name: "Main Menu",
  //     slug: "main-menu",
  //     created_at: "2024-01-24T06:32:08.000000Z",
  //     updated_at: "2024-01-24T06:32:08.000000Z",
  //     items: [
  //       {
  //         id: 14,
  //         property_id: 10,
  //         main_menu_id: 10,
  //         slug: ' /hotels',
  //         name: "About Us",
  //         created_at: "2024-01-24T06:32:08.000000Z",
  //         updated_at: "2024-01-24T06:32:08.000000Z",
  //       },
  //     ],
  //   },
  // ];

  const [websiteMenus, setWebsiteMenus] = useState([]);
  const [addMenuPopup, setAddMenuPopup] = useState(false);
  const [editMenuPopup, setEditMenuPopup] = useState(false);
  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const { id } = useParams();

  const [activeMenu, setActiveMenu] = useState(id);

  const getAllMenusHandler = () => {
    // setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/show/main/menu/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // setOpenSingleBookingDetails(true);
          setLoader(false);
          setWebsiteMenus(data.data);
        } else {
          setLoader(false);
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        // setLoader(false);
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  useEffect(() => {
    if (property_id) {
      getAllMenusHandler();
    }
  }, [property_id]);

  useEffect(() => {
    setSearchTerm(id);
  }, [id]);

  const [searchTerm, setSearchTerm] = useState(null);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchTerm(inputValue);
  };

  const [draggedItem, setDraggedItem] = useState(null);
  const [dragOnButtonDown, setDragOnButtonDown] = useState(false);

  const handleDragStart = (item) => {
    setDraggedItem(item);
  };

  const handleDragOver = (id, index, e) => {
    if (!draggedItem) return;

    setWebsiteMenus((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item.id == id) {
          const updatedMenuItems = [...item.items];
          const fromIndex = updatedMenuItems.indexOf(draggedItem);
          const toIndex = index;
          const temp = updatedMenuItems[fromIndex];
          updatedMenuItems[fromIndex] = updatedMenuItems[toIndex];
          updatedMenuItems[toIndex] = temp;

          updatedMenuItems.forEach((item, index) => {
            item.order = index;
          });

          return {
            ...item,
            items: updatedMenuItems,
          };
        }
        return item;
      });

      return updatedData;
    });
  };

  const navigate = useNavigate();

  const handleDragEnd = () => {
    setDraggedItem(null);
  };

  return (
    <div className="main_container">
      {/* <SidebarInnerLists innerLists={websites_inner_lists} /> */}
      <div className="websiteMenuSingleItemContainer">
        <GoBackButtonCustom
          onClick={() => navigate(-1)}
          buttonNextText={"Sub Menus"}
        />
        {/* <h4 className="websiteMenuSingleItemHeading">Main Menu</h4> */}

        <div className="websiteMenuSingleItemTitleContainer websiteMenuSingleItemSearchInputContainer">
          <label className="websiteMenuSingleItemTitleLabel">Title</label>
          <select
            type="text"
            placeholder="main menu"
            className="websiteMenuSingleItemTitleInput"
            value={searchTerm}
            onChange={handleInputChange}
          >
            {websiteMenus.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>

        <div className="websiteMenuSingleItemTitleContainer">
          <h5 className="singleItemHeading">Menu Items</h5>
          {websiteMenus
            .filter((item) => String(item.id) === searchTerm)
            .map((menu) => {
              return (
                <React.Fragment key={menu.id}>
                  <div className="websiteMenuSingleItemMenuItemsContainer">
                    {menu.items.map((item, index) => (
                      <React.Fragment key={item.id}>
                        <div className="websiteMenuSingleItemMenuItemsHr" />
                        <div
                          key={item.id}
                          className="websiteMenuSingleItemMenuItemsList"
                          draggable={dragOnButtonDown}
                          onDragStart={() => handleDragStart(item)}
                          onDragOver={(e) => handleDragOver(menu.id, index, e)}
                          onDragEnd={handleDragEnd}
                        >
                          <button
                            onMouseDown={() => setDragOnButtonDown(true)}
                            onMouseUp={() => setDragOnButtonDown(false)}
                            className="websiteMenuSingleItemMenuItemsListDragButton"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="12"
                              viewBox="0 0 9 12"
                              fill="none"
                            >
                              <g clipPath="url(#clip0_1_20365)">
                                <path
                                  d="M1.44742 2.89485C2.24681 2.89485 2.89485 2.24681 2.89485 1.44742C2.89485 0.648033 2.24681 0 1.44742 0C0.648033 0 0 0.648033 0 1.44742C0 2.24681 0.648033 2.89485 1.44742 2.89485Z"
                                  fill="#AAAAAA"
                                />
                                <path
                                  d="M6.74845 2.89485C7.54784 2.89485 8.19587 2.24681 8.19587 1.44742C8.19587 0.648033 7.54784 0 6.74845 0C5.94906 0 5.30103 0.648033 5.30103 1.44742C5.30103 2.24681 5.94906 2.89485 6.74845 2.89485Z"
                                  fill="#AAAAAA"
                                />
                                <path
                                  d="M1.44742 7.44758C2.24681 7.44758 2.89485 6.79955 2.89485 6.00016C2.89485 5.20077 2.24681 4.55273 1.44742 4.55273C0.648033 4.55273 0 5.20077 0 6.00016C0 6.79955 0.648033 7.44758 1.44742 7.44758Z"
                                  fill="#AAAAAA"
                                />
                                <path
                                  d="M6.74845 7.44758C7.54784 7.44758 8.19587 6.79955 8.19587 6.00016C8.19587 5.20077 7.54784 4.55273 6.74845 4.55273C5.94906 4.55273 5.30103 5.20077 5.30103 6.00016C5.30103 6.79955 5.94906 7.44758 6.74845 7.44758Z"
                                  fill="#AAAAAA"
                                />
                                <path
                                  d="M1.44742 12.0062C2.24681 12.0062 2.89485 11.3581 2.89485 10.5588C2.89485 9.75936 2.24681 9.11133 1.44742 9.11133C0.648033 9.11133 0 9.75936 0 10.5588C0 11.3581 0.648033 12.0062 1.44742 12.0062Z"
                                  fill="#AAAAAA"
                                />
                                <path
                                  d="M6.74845 12.0062C7.54784 12.0062 8.19587 11.3581 8.19587 10.5588C8.19587 9.75936 7.54784 9.11133 6.74845 9.11133C5.94906 9.11133 5.30103 9.75936 5.30103 10.5588C5.30103 11.3581 5.94906 12.0062 6.74845 12.0062Z"
                                  fill="#AAAAAA"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1_20365">
                                  <rect
                                    width="8.19588"
                                    height="12"
                                    fill="white"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </button>
                          {item.name}
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default WebsiteSubMenus;
