import React from "react";
import Purchase from "../Components/Purchase";

const PurchasePage = () => {
  return (
    <>
      <div className="main_container">
        <Purchase />
      </div>
    </>
  );
};

export default PurchasePage;
