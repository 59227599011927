import React from "react";
import Purchase from "../Components/Purchase";
import FbKotList from "../Components/FbKotList";
import TableKoT from "../Components/Dashboard/FoodDashboard/FandB-KoT/TableKoT";
import OrderListFAndBDashBoard from "../Components/Dashboard/FoodDashboard/OrderListFAndBDashBoard/OrderListFAndBDashBoard";

const FbKotListPage = () => {
  return (
    <>
      <div className="main_container">
        <OrderListFAndBDashBoard />
      </div>
    </>
  );
};

export default FbKotListPage;
