import React, { useEffect, useRef } from "react";
import { NavLink, Outlet } from "react-router-dom";
import NavTabs from "../Components/NewCustomComp/NavTabs/NavTabs";

const ChannelManagerSettingsPage = () => {
  const ref = useRef(null);

  useEffect(() => {
    // Set the meta title
    document.title =
      "Room Type - RevCatalyst - Boosting Your Hotel Performance";

    // Create or update the meta description tag
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property.";
  }, []);

  const links = [
    { label: "Profile", to: "profile" },
    { label: "Brand", to: "brand" },
    { label: "Distance", to: "distance" },
    { label: "Privacy Policy", to: "privacy-policy" },
    { label: "Channel", to: "channel" },
  ];

  return (
    <div className="main_container">
      <NavTabs mainData={links} />
      <div style={{ margin: "20px auto", width: "80%" }}>
        <Outlet />
      </div>
    </div>
  );
};

export default ChannelManagerSettingsPage;
