import { Button } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import VerticalInputFieldCustom from "../VerticalInputFieldCustom";
import VerticalTextAreaCustom from "../VerticalTextAreaCustom";
import { BgOutlineBlackButton } from "../../UI/Buttons";
import { showErrorToast } from "../../../assets/toastUtils";

const DeletePopupWithReason = ({
  title,
  onDeleteWithReason,
  wordLimit,
  deleteWithReasonPopup,
  setDeleteWithReasonPopup,
  deleteFunction,
  id,
  btnText,
  cancelText,
  fullTitle,
  withoutReason,
  containerWidth,
}) => {
  const [reason, setReason] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);

  // const handleRemove = () => {

  // if (reasonLength > 0) {
  //   setButtonDisabled(false);
  // } else {
  //   setButtonDisabled(true);
  // }

  // deleteFunction()
  // };

  useEffect(() => {
    const reasonLength = reason.trim().length;
    if (reasonLength > 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [reason]);

  const handleChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length > wordLimit) {
      showErrorToast("You have exceeded the word limit");
    } else {
      setReason(inputValue.slice(0, wordLimit));
    }
  };

  useEffect(() => {
    onDeleteWithReason(reason, id);
  }, [reason, id]);

  return (
    <div className="bg-backdrop">
      <section
        className="room-type-model-container"
        style={{ width: containerWidth ? containerWidth : "300px" }}
      >
        <h4 style={{ color: "#E03838", marginBottom: "8px" }}>Are You Sure?</h4>
        <p className="deleteContainer" style={{ marginBottom: "8px" }}>
          {fullTitle ? (
            fullTitle
          ) : (
            <>
              {" "}
              {cancelText ? (
                cancelText
              ) : (
                <div> You Want to Cancel This {title}?</div>
              )}
            </>
          )}
        </p>
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            {!withoutReason ? (
              <>
                <div className="reason-input-button">Enter Reason</div>
                <div className="reason-input-button">
                  {reason?.trim()?.length}/{wordLimit}
                </div>
              </>
            ) : null}
          </div>

          {!withoutReason ? (
            <VerticalTextAreaCustom
              name={"reason"}
              className="textfield"
              focused={false}
              type="text"
              value={reason}
              autoComplete="off"
              onChange={handleChange}
              cols={9}
            />
          ) : null}
        </div>
        <div
          style={{
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
            width: "100%",
            marginTop: "20px",
          }}
        >
          {!withoutReason ? (
            <>
              <BgOutlineBlackButton
                onClick={() => setDeleteWithReasonPopup(false)}
              >
                Discard
              </BgOutlineBlackButton>
              <Button
                disabled={buttonDisabled}
                destructive
                onClick={deleteFunction}
              >
                {btnText ? btnText : "Yes, Cancel"}
              </Button>
            </>
          ) : (
            <div
              className="flex justify_content_center gap_7"
              style={{ width: "100%" }}
            >
              <BgOutlineBlackButton
                onClick={() => setDeleteWithReasonPopup(false)}
              >
                Discard
              </BgOutlineBlackButton>
              <Button
                // disabled={buttonDisabled}
                destructive
                onClick={deleteFunction}
              >
                {btnText ? btnText : "Yes, Cancel"}
              </Button>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default DeletePopupWithReason;
