import React, { useState, useEffect } from "react";
import "./Toastify.css";

const CustomToast = ({ id, type, message, duration, onClose, position }) => {
  const [visible, setVisible] = useState(true);
  const [progress, setProgress] = useState(100);

  const isTopPosition = position.includes("top");
  // const startY = isTopPosition ? '-100%' : '100%';
  const exitY = isTopPosition ? '-100%' : '100%'; 
  const isBottomPosition = position.includes("bottom");
  const [toastHeight, setToastHeight] = useState(0);

  useEffect(() => {
    // Calculate toast height once it's rendered
    const toastElement = document.getElementById(`toast-${id}`);
    if (toastElement) {
      const height = toastElement.offsetHeight;
      setToastHeight(height);
    }
  }, [id]);

  useEffect(() => {
    if (!visible) {
      const hideTimer = setTimeout(() => {
        onClose && onClose(id);
      }, 300); 
      return () => clearTimeout(hideTimer);
    }
  }, [id, onClose, visible]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, duration);

    return () => clearTimeout(timer);
  }, [duration]);

  // useEffect(() => {
  //   if (!visible) {
  //     const hideTimer = setTimeout(() => {
  //       onClose && onClose(id);
  //     }, progress * duration / 100); 
  //     return () => clearTimeout(hideTimer);
  //   }
  // }, [id, onClose, visible, duration, progress]);

    // Adjust the positioning based on toast height
    const topPositionStyle = isTopPosition
    ? { top: `${id * (toastHeight + 10)}px` }
    : undefined;
  const bottomPositionStyle = isBottomPosition
    ? { bottom: `${id * (toastHeight + 10)}px` }
    : undefined;

  useEffect(() => {
    let start;
    const step = (timestamp) => {
      if (!start) start = timestamp;
      const progressTime = timestamp - start;
      const progressPercentage = 100 - (progressTime / duration) * 100;
      setProgress(progressPercentage);
  
      // Check if progress is nearly empty
      if (progressPercentage <= 5) {
        
        setVisible(false);
      } else {
       
        if (progressTime < duration) {
          requestAnimationFrame(step);
        }
      }
    };
  
    setTimeout(() => {
      requestAnimationFrame(step);
    }, 100); // Start progress bar animation after bounce completes
  }, [duration]);

  const getIcon = (type) => {
    switch (type) {
      case "success":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            width="24px"
            height="24px"
          >
            <path
              fill="#4caf50"
              d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
            />
            <path
              fill="#ccff90"
              d="M34.602,14.602L21,28.199l-5.602-5.598l-2.797,2.797L21,33.801l16.398-16.402L34.602,14.602z"
            />
          </svg>
        );
      case "error":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="24px"
            height="24px"
          >
            <path
              d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"
              fill="#e74c3c"
            />
          </svg>
        );
      case "info":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="24px"
            height="24px"
          >
            <path
              d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"
              fill="#e74c3c"
            />
          </svg>
        );
      case "warning":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="24px"
            height="24px"
          >
            <path
              d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"
              fill="#e74c3c"
            />
          </svg>
        );
      default:
        return "";
    }
  };

  return (
    <div
    id={`toast-${id}`}
    className={`toast ${type} ${visible ? "show" : "hide"} ${
      isBottomPosition ? "bottom" : "top"
    }`}
    role="alert"
    style={{
      ...topPositionStyle,
      ...bottomPositionStyle,
      opacity: visible ? 0.8 : 1,
      "--exitY": exitY,
    }}
  
    >
      <div className="toast-content">
        <span className="toast-icon">{getIcon(type)}</span>
        <div className="toast-message">{message}</div>
      </div>
      <button className="close-button" onClick={() => setVisible(false)}>
        ✖
      </button>
      <div className="progress-bar" style={{ width: `${progress}%` }}></div>
    </div>
  );
};

export default CustomToast;
