import React, { useState, useEffect, useRef } from "react";
import "./multiSelectSearchDropdown.css";

const MultiSelectSearchDropdown = ({
  options,
  onChange,
  optionKey,
  getKeyName,
  titleName,
  error
}) => {
  const [searchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(-1);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const optionRefs = useRef([]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const sanitizeString = (str) =>
    str
      .replace(/[^\w\s]|_/g, "")
      .replace(/\s+/g, " ")
      .toLowerCase();

  const handleSearch = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);

    const filtered = optionKey
      ? options?.filter((option) =>
          sanitizeString(option[optionKey]).includes(sanitizeString(searchText))
        )
      : options?.filter((option) =>
          sanitizeString(option).includes(sanitizeString(searchText))
        );

    setFilteredOptions(filtered);
  };

  const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} className="multi-select-dropdown-highlight">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const handleCheckboxChange = (option) => {
    const index = selectedOptions.findIndex(
      (selectedOption) => selectedOption === option
    );
    let updatedSelectedOptions;
    if (index === -1) {
      updatedSelectedOptions = [...selectedOptions, option];
    } else {
      updatedSelectedOptions = selectedOptions.filter(
        (selectedOption) => selectedOption !== option
      );
    }
    setSelectedOptions(updatedSelectedOptions);
    sendToParent(updatedSelectedOptions);
  };

  const handleOptionCheckbox = (option) => {
    const index = selectedOptions.findIndex(
      (selectedOption) => selectedOption[optionKey] === option[optionKey]
    );
    let updatedSelectedOptions;
    if (index === -1) {
      updatedSelectedOptions = [...selectedOptions, option];
    } else {
      updatedSelectedOptions = selectedOptions.filter(
        (selectedOption) => selectedOption[optionKey] !== option[optionKey]
      );
    }
    setSelectedOptions(updatedSelectedOptions);
    sendToParent(updatedSelectedOptions);
  };

  const handleSelectAll = () => {
    setSelectedOptions(filteredOptions);
    sendToParent(filteredOptions);
  };

  const handleClearAll = () => {
    setSelectedOptions([]);
    sendToParent([]);
  };

  const sendToParent = (options) => {
    if (getKeyName) {
      onChange(options.map((option) => option[getKeyName]));
    } else {
      onChange(options);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setFocusedOptionIndex((prevIndex) =>
        prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setFocusedOptionIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1
      );
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (
        focusedOptionIndex >= 0 &&
        focusedOptionIndex < filteredOptions.length
      ) {
        const option = filteredOptions[focusedOptionIndex];
        optionKey ? handleOptionCheckbox(option) : handleCheckboxChange(option);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  }, [isOpen]);

  useEffect(() => {
    if (focusedOptionIndex !== -1 && optionRefs.current[focusedOptionIndex]) {
      optionRefs.current[focusedOptionIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [focusedOptionIndex]);

  return (
    <>
      <div>{titleName}</div>
      <div
        className="multi-select-search-dropdown"
        ref={dropdownRef}
        style={isOpen ? { outline: "1px solid #3968ed" } : {}}
      >
        <div
          className="multi-select-search-dropdown-selected-option"
          onClick={toggleDropdown}
          style={{border: error ? "1px solid red" : "",
            backgroundColor: error ? "#FFEAEA" : "",
            cursor:error ? "not-allowed" : "pointer"}}
        >
          <span>{`${selectedOptions.length} of ${options.length} SELECTED`}</span>
          <span
            className={
              isOpen
                ? "multi-select-search-dropdown-icon"
                : "multi-select-search-dropdown-icon-close"
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              className="customRoomChoiceSelectIcon"
            >
              <path d="M1 1L5 5L9 1" stroke="#333333"></path>
            </svg>
          </span>
        </div>
        {isOpen && (
          <div
            className="multi-select-search-dropdown-dropdown-content"
            onKeyDown={handleKeyDown}
            tabIndex="0"
          >
            <div className="multi-select-search-dropdown-input-container">
              <input
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={handleSearch}
                className="multi-select-search-dropdown-input"
                ref={inputRef}
              />
              <div className="multi-select-search-dropdown-button-container">
                <button onClick={handleClearAll}>Clear All</button>
                <button onClick={handleSelectAll}>Select All</button>
              </div>
            </div>

            <div className="multi-select-search-dropdown-options-list-container overflowContainer">
              {filteredOptions.length > 0 ? (
                <ul className="multi-select-search-dropdown-options-list">
                  {optionKey
                    ? filteredOptions?.map((option, index) => (
                        <li
                          key={`${option[optionKey]}-${index}`}
                          className={`multi-select-search-dropdown-option-list-items ${
                            focusedOptionIndex === index ? "focused" : ""
                          }`}
                          ref={(el) => (optionRefs.current[index] = el)}
                        >
                          <input
                            type="checkbox"
                            checked={selectedOptions.some(
                              (selectedOption) =>
                                selectedOption[optionKey] === option[optionKey]
                            )}
                            onChange={() => handleOptionCheckbox(option)}
                            id={`${option[optionKey]}-${index}`}
                            className="multi-select-search-checkbox"
                          />
                          <label
                            className="multi-select-search-label"
                            htmlFor={`${option[optionKey]}-${index}`}
                          >
                            {getHighlightedText(
                              option[optionKey],
                              searchText
                            )}
                          </label>
                        </li>
                      ))
                    : filteredOptions?.map((option, index) => (
                        <li
                          key={`${option}-${index}`}
                          className={`multi-select-search-dropdown-option-list-items ${
                            focusedOptionIndex === index ? "focused" : ""
                          }`}
                          ref={(el) => (optionRefs.current[index] = el)}
                        >
                          <input
                            type="checkbox"
                            checked={selectedOptions.includes(option)}
                            onChange={() => handleCheckboxChange(option)}
                            id={`${option}-${index}`}
                            className="multi-select-search-checkbox"
                          />
                          <label
                            className="multi-select-search-label"
                            htmlFor={`${option}-${index}`}
                          >
                            {getHighlightedText(option, searchText)}
                          </label>
                        </li>
                      ))}
                </ul>
              ) : (
                <div style={{ fontSize: "12px", padding: "5px" }}>
                  {" "}
                  No data found{" "}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MultiSelectSearchDropdown;