import NightAudit from "../Components/NightAudit/NightAudit";
import '../Components/NightAudit/NightAudit.css';
import { StepProvider } from "../Components/NightAudit/StepProvide";

const NightAuditPage = () => {
    return (
        <>
            <div className="night_audit_page_main">
                <div className="main_container">
                    {/* <StepProvider> */}
                        <NightAudit />
                    {/* </StepProvider> */}
                </div>
            </div>
        </>
    );
};
export default NightAuditPage;
