import React, { useState, useEffect, useRef } from 'react';
import styles from './CustomSlider.module.scss';

const CustomSlider = ({ showValues, min = 0, max = 100, defaultValue = [0, 100], doubleRange = false }) => {
    const initialValues = doubleRange ? defaultValue : [defaultValue[0], max];
    const [values, setValues] = useState(initialValues);
    const sliderRef = useRef(null);
    const [isDragging, setIsDragging] = useState([false, false]);

    const handleMouseMove = (e, index) => {
        if (isDragging[index]) {
            const sliderRect = sliderRef.current.getBoundingClientRect();
            const newValue = ((e.clientX - sliderRect.left) / sliderRect.width) * (max - min) + min;
            const newValues = [...values];
            newValues[index] = Math.round(Math.min(Math.max(newValue, min), max));
            if (index === 0 && newValues[0] > newValues[1]) {
                newValues[0] = newValues[1];
            }
            if (index === 1 && newValues[1] < newValues[0]) {
                newValues[1] = newValues[0];
            }
            setValues(newValues);
        }
    };

    const handleMouseUp = (index) => {
        const newDraggingState = [...isDragging];
        newDraggingState[index] = false;
        setIsDragging(newDraggingState);
    };

    const handleMouseDown = (index) => {
        const newDraggingState = [false, false];
        newDraggingState[index] = true;
        setIsDragging(newDraggingState);
    };

    useEffect(() => {
        const moveListener = (e) => {
            isDragging.forEach((dragging, index) => {
                if (dragging) {
                    handleMouseMove(e, index);
                }
            });
        };
        const upListener = () => {
            setIsDragging([false, false]);
        };

        if (isDragging.some(dragging => dragging)) {
            window.addEventListener('mousemove', moveListener);
            window.addEventListener('mouseup', upListener);
        } else {
            window.removeEventListener('mousemove', moveListener);
            window.removeEventListener('mouseup', upListener);
        }
        return () => {
            window.removeEventListener('mousemove', moveListener);
            window.removeEventListener('mouseup', upListener);
        };
    }, [isDragging]);

    const getLeftPosition = (val) => {
        return `${((val - min) / (max - min)) * 100}%`;
    };

    console.log(doubleRange ? values : values[0]); // Log single value if doubleRange is false

    return (
        <div className={styles.custom_slider}>
            <div className={styles.slider_track} ref={sliderRef}>
                <div
                    className={styles.slider_thumb}
                    style={{ left: getLeftPosition(values[0]) }}
                    onMouseDown={() => handleMouseDown(0)}
                ></div>
                {doubleRange && (
                    <div
                        className={styles.slider_thumb}
                        style={{ left: getLeftPosition(values[1]) }}
                        onMouseDown={() => handleMouseDown(1)}
                    ></div>
                )}

                {doubleRange && (
                    <div
                        className={styles.slider_fill}
                        style={{
                            left: getLeftPosition(values[0]),
                            width: doubleRange
                                ? `${parseFloat(getLeftPosition(values[1])) - parseFloat(getLeftPosition(values[0]))}%`
                                : getLeftPosition(values[0]),
                        }}
                    ></div>
                )}

            </div>
            {showValues && (
                <div className={styles.slider_values}>
                    {Array.from({ length: (max - min) / 20 + 1 }, (_, i) => (
                        <span key={i} style={{ left: getLeftPosition(min + i * 20) }}>
                            {min + i * 20}
                        </span>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomSlider;
