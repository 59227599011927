import React, { useEffect, useState } from "react";
import "./WaiterDashboardCreateTableKot.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import VerticalInputSelectCustomCopy from "../../../CustomComponents/VerticalInputSelectCustomCopy";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../assets/toastUtils";
import VerticalInputFieldCustom from "../../../CustomComponents/VerticalInputFieldCustom";
import { getDecryptedData } from "../../../../utils/encryptStorage";

const WaiterDashboardCreateTableKot = () => {
  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [table, setTable] = useState("");

  const [menuItems, setMenuItems] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);

  const [kots, setKots] = useState({
    kot_type: "restro_bill",
    payment_type: "",
    table_no: "",
    room_no: "",
    paid_amount: "",
    kot_date: "",
    sub_total: "",
    discount: "0",
    balance_amount: "0",
    gst: "",
    gst_type: "exclusive",
    grand_total: "",
    balance_amount: "0",
    item_details: [],
    unique_booking_id: "",
  });

  const fetchData = async (propertyId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/menus/${propertyId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const result = await response.json();
      setMenuItems(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchInfo = async (id) => {
    try {
      // setLoader(true);
      const response = await fetch(
        // `${process.env.REACT_APP_BASE_URL}/api/v1/event/update/${id}/${property_id?.id}`
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/table_list/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setTablesData(data.tabledata);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
      fetchData(property_id?.id);
    }
  }, [property_id]);

  const [selectedCategory, setSelectedCategory] = useState();

  const [selectedItems, setSelectedItems] = useState({
    item: "",
    quantity: "",
    price: "",
    menue_item_id: "",
  });
  const [menuItemsToDisplay, setMenuItemsToDisplay] = useState([]);

  //   item_price
  // :
  // "30.00"
  // menue_item_category
  // :
  // "DRINKS"
  // menue_item_id
  // :
  // 2046
  // quantity
  // :
  // "1"
  // total_amount
  // :
  // "30.00"

  useEffect(() => {
    const newTotalPrice = menuItemsToDisplay.reduce(
      (total, item) => total + Number(item.quantity) * Number(item.item_price),
      0
    );
    setTotalPrice(newTotalPrice);
  }, [menuItemsToDisplay]);

  const handleCategorySelect = (value) => {
    const filteredItems = menuItems?.menu?.filter(
      (item) => item.item_category === value
    );

    if (filteredItems?.length > 0) {
      setSelectedCategory(filteredItems[0]);
    } else {
      setSelectedCategory(null);
    }
  };

  const [tablesData, setTablesData] = useState([]);
  const [roomsData, setRoomsData] = useState([]);
  const [date, setDate] = useState([]);

  // const tableOptions = [
  //   { label: "Select an option", value: "" }, // Your first option
  //   ...(tablesData?.map((table) => ({
  //     label: table.table_no,
  //     value: table.room_no,
  //   })) || []),
  // ];

  const roomsOptions = [
    { label: "Select an option", value: "" },
    ...(roomsData
      ?.filter((room) => room.status === "occupied")
      .map((room) => ({ label: room.room_no, value: room.room_no })) || []),
  ];
  const tableOptions = [
    { label: "Select an option", value: "" },
    ...(tablesData
      ?.filter((room) => room.status === "vacant")
      .map((table) => ({
        label: table.table_no,
        value: table.room_no,
      })) || []),
  ];

  const handleAddItemsClick = () => {
    if (selectedCategory && selectedItems.item && selectedItems.quantity) {
      const newItem = {
        item_name: selectedItems.item,
        menue_item_id: selectedItems.menue_item_id,
        quantity: selectedItems.quantity,
        menue_item_category: selectedCategory.item_category,
        item_price: selectedItems.price,
        total_amount: selectedItems.quantity * selectedItems.price,
      };
      // showErrorToast("Item already exists");
      const itemExists = menuItemsToDisplay.some(
        (item) => item.item_name === newItem.item_name
      );

      if (itemExists) {
        showErrorToast("Item already exists.");
        return;
      }

      if (!itemExists) {
        setMenuItemsToDisplay([...menuItemsToDisplay, newItem]);
        setSelectedItems({
          item: "",
          quantity: "",
          price: "",
          menue_item_id: "",
        });
      }
    } else {
      showErrorToast("Please select item and quantity");
    }
  };

  const handleRemoveItemsClick = (index) => {
    const updatedMenuItemsToDisplay = [...menuItemsToDisplay];
    updatedMenuItemsToDisplay.splice(index, 1);
    setMenuItemsToDisplay(updatedMenuItemsToDisplay);
  };

  const handleQuantityChange = (e) => {
    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      quantity: e.target.value,
    }));
  };

  const handleSubmitAddKot = async (e) => {
    e.preventDefault();

    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/create/kots/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({
            ...kots,
            item_details: menuItemsToDisplay,
            grand_total: totalPrice,
            sub_total: totalPrice,
            kot_date: date,
            paid_amount: totalPrice,
            table_no: table,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        navigate("/dashboard");
        fetchInfo(property_id?.id);
        showSuccessToast(data.message);
        setKots({
          kot_type: "",
          payment_type: "",
          paid_amount: "",
          kot_date: "",
          table_no: "",
          room_no: "",
          sub_total: "",
          discount: "",
          balance_amount: "",
          gst: "",
          gst_type: "",
          grand_total: "",
          balance_amount: "",
          item_details: [],
        });
        // setShowAddStockModelMenuActive(false);
        // window.location.reload()
      } else {
        showErrorToast(data.message);
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
      setLoader(false);
    }
  };

  return (
    <div className="main_container">
      <form
        onSubmit={(e) => handleSubmitAddKot(e)}
        className="waiterDashboardCreateKotContainer"
      >
        <h4 className="waiterDashboardCreateKotHeading">Create Restro KOT</h4>

        <div className="waiterDashboardCreateKotTable">
          <p className="waiterDashboardCreateKotItemsHeading">Tables</p>

          <div className="form-container-div-half">
            <div className="form-container-div">
              <VerticalInputSelectCustomCopy
                titleName={"Table No."}
                options={tableOptions}
                value={table}
                // value={kots.table_no}
                name="table_no"
                className="addStaffInputs"
                onChange={(e) => setTable(e.target.value)}
                required={true}
              />
            </div>
            <div className="form-container-div">
              <VerticalInputFieldCustom
                titleName={"Date"}
                options={tableOptions}
                type={"date"}
                className="addStaffInputs"
                onChange={(e) => setDate(e.target.value)}
                required={true}
              />
            </div>
          </div>
        </div>

        <div className="waiterDashboardCreateKotTable waiterDashboardCreateKotItemsContainer">
          <p className="waiterDashboardCreateKotItemsHeading">Items</p>
          <div className="waiterDashboardCreateKotItemsSelectContainer">
            <div>
              <label className="waiterDashboardCreateKotItemsLabel">
                Item Category <br />
                <select
                  defaultValue=""
                  onChange={(e) => handleCategorySelect(e.target.value)}
                  className="waiterDashboardCreateKotItemsSelect"
                  value={selectedCategory?.item_category}
                >
                  <option value={""}>Select an option</option>
                  {menuItems?.menu?.map((item) => (
                    <option key={item.item_category} value={item?.id}>
                      {item?.item_category}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            <div>
              <label className="waiterDashboardCreateKotItemsLabel">
                Item Name <br />
                <select
                  className="waiterDashboardCreateKotItemsSelect"
                  value={selectedItems.item}
                  onChange={(e) =>
                    setSelectedItems({
                      ...selectedItems,
                      item: e.target.value,
                      price: selectedCategory?.menu_items?.find(
                        (item) => item.item_name === e.target.value
                      )?.item_price,
                      menue_item_id: selectedCategory?.menu_items?.find(
                        (item) => item.item_name === e.target.value
                      )?.id,
                    })
                  }
                >
                  <option value="">Select item name</option>
                  {selectedCategory?.menu_items?.map((item) => (
                    <option key={item.id} value={item.item_name}>
                      {item.item_name}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            <div>
              <label>
                Qty <br />
                <input
                  className="waiterDashboardCreateKotItemsSelect waiterDashboardCreateKotItemsSelectQuantity"
                  onChange={(e) => handleQuantityChange(e)}
                  value={selectedItems.quantity}
                  type="number"
                  min="1"
                />
              </label>
            </div>
            {/* <div>
              {Number(selectedItems.price) * Number(selectedItems.quantity)}
            </div> */}
            <div className="waiterDashboardCreateKotItemsButtonContainer">
              <button
                type="button"
                className="waiterDashboardCreateKotItemsButton"
                onClick={handleAddItemsClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <g clipPath="url(#clip0_2599_3401)">
                    <path
                      d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                      stroke="#3968ED"
                    />
                    <path
                      d="M10.5868 7H3.41357"
                      stroke="#3968ED"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M7 3.41344L7 10.5867"
                      stroke="#3968ED"
                      strokeMiterlimit="10"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2599_3401">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>{" "}
                Add
              </button>
            </div>
          </div>

          <div className="waiterDashboardCreateKotItemsHr"></div>
          {menuItemsToDisplay.map((item, index) => (
            <React.Fragment key={index}>
              <div className="menuItemsToDisplayContainer">
                <p className="waiterDashboardCreateKotDisplayItems">
                  {item.quantity} {item.item_name}
                </p>
                <button
                  className="waiterDashboardCreateKotItemsDeleteButton"
                  onClick={() => handleRemoveItemsClick(index)}
                >
                  Remove
                </button>
              </div>
              <div className="waiterDashboardCreateKotItemsHr"></div>
            </React.Fragment>
          ))}
        </div>
        {/* {totalPrice} */}

        <button type="submit" className="waiterDashboardCreateKotButton">
          Create KOT
        </button>
      </form>
    </div>
  );
};

export default WaiterDashboardCreateTableKot;
