import "./CreateMessageInputBox.css";
const CreateMessageInputBox = ({
  label,
  value,
  onChange,
  alignment,
  name,
  error,
  disabled,
}) => {
  return (
    <div>
      {alignment === "vertical" ? (
        <div className="lms_create_reservation_vertical_inputs_container">
          <label className="Create_booking_label">{label}</label>
          <input
            style={{
              border: error ? "1px solid #E03838" : "1px solid #ddd",
              backgroundColor: error ? "#FFEAEA" : "",
              cursor: disabled ? "not-allowed" : "",
            }}
            onChange={onChange}
            value={value}
            name={name}
            disabled={disabled}
            type="number"
            className={error ? "create_booking_error-border" : ""}
          ></input>
        </div>
      ) : (
        <div className="gap_7 flex">
          <label className="Create_booking_label_small">{label}</label>
          <input
            style={{
              border: error ? "1px solid #E03838" : "1px solid #ddd",
              backgroundColor: error ? "#FFEAEA" : "",
              cursor: disabled ? "not-allowed" : "",
            }}
            onChange={onChange}
            value={value}
            name={name}
            disabled={disabled}
            className={error ? "create_booking_error-border adlut_box_input" : "adlut_box_input"}
          ></input>
        </div>
      )}
    </div>
  );
};
export default CreateMessageInputBox;
