import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import styles from "../bookingSummary/bookingSummary.module.scss";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

// Register the elements with Chart.js
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const RoomCategoryInsights = () => {
  const [filter, setFilter] = useState("Today");

  const data = {
    labels: [
      "Deluxe Room",
      "Super Deluxe Room",
      "Executive Room",
      "Family Suite",
    ],
    datasets: [
      {
        label: "Room Nights",
        data: [20, 17, 14, 10], // Replace with dynamic data as needed
        backgroundColor: "rgba(54, 162, 235, 0.5)",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false, // Allow the chart to fill its container
    plugins: {
      legend: {
        display: false, // Hide the default legend
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `Room Nights: ${context.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 5,
        },
      },
    },
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    // Update data based on filter here if necessary
  };

  return (
    <div
      style={{
        padding: "20px",
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        background: "#FFF",
        height: "auto", // Automatically adjust height
      }}
    >
      <div
        className="flex justify_content_between align_items_center"
        style={{ marginBottom: "20px" }}
      >
        <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>
          Room Category Insights
        </h2>
        <div className={styles["filter"]}>
          <select
            value={filter}
            onChange={handleFilterChange}
            style={{ float: "right" }}
          >
            <option value="Today">Today</option>
            <option value="YTD">YTD</option>
            <option value="MTD">MTD</option>
          </select>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          height: "250px", // Set the height of the chart container
        }}
      >
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default RoomCategoryInsights;
