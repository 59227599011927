import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Collapsible,
  DropZone,
  Icon,
  LegacyCard,
  LegacyStack,
  Select,
  TextField,
  Text,
  Thumbnail,
  ButtonGroup,
  Page,
  Badge,
  Tag,
  ChoiceList,
} from "@shopify/polaris";
import { ChevronDownMinor, NoteMinor } from "@shopify/polaris-icons";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  BgThemeButtonFullWidth,
} from "./UI/Buttons";

import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";

import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalTextAreaCustom from "./CustomComponents/VerticalTextAreaCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import { useNavigate } from "react-router-dom";
import CustomToggleButton from "./UI/CustomToggleButton";
import { useSelector } from "react-redux";
import DropZoneFileUploader from "./CustomComponents/DropZoneFileUploader";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import Loader from "./UI/Loader/Loader";
import SelectDateRangeWithoutInput from "./CustomComponents/SelectDateRangeWithoutInput/SelectDateRangeWithoutInput";
import SelectDateRange from "./CustomComponents/SelectDateRange/SelectDateRange";
import HandleNaN from "./CustomComponents/HandleNaN/HandleNaN";
import DateRangePicker from "./CustomComponents/SelectDateRange/DateRangePicker";
import { getDecryptedData } from "../utils/encryptStorage";

const AddEvent = () => {
  const [files, setFiles] = useState([]);
  const [value, setValue] = useState("Jaded Pixel");
  const [selected, setSelected] = useState("today");

  // errors Handle

  const [loader, setLoader] = useState(false);
  const [showAddSlot, setShowAddSlot] = useState(false);
  const property_id = useSelector((state) => state.property.value);

  const [formErrors, setFormErrors] = useState({});

  const handleChange = useCallback((newValue) => setValue(newValue), []);

  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [options, setOptions] = useState([]);

  const fetchInfo = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/banquet/menus/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOptions(data.menu);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

   useEffect(() => {
    if (property_id  && userToken)  {
      fetchInfo(property_id?.id);
    }
  }, [property_id, userToken]);

  const toggleAccordion = (category_id) => {
    setActiveAccordion((prevId) =>
      prevId === category_id ? null : category_id
    );
    setSelectedOptions({ ...selectedOptions });
    toggleIconRotation(category_id);
  };

  const toggleIconRotation = (category_id) => {
    const icon = document.getElementById(`icon-${category_id}`);
    if (icon) {
      icon.classList.toggle("rotateicon");
    }
  };

  // const handleSelectAll = () => {
  //   if (selectAll) {
  //     setSelectedOptions({});
  //   } else {
  //     const allSelected = {};
  //     options.forEach((option) => {
  //       allSelected[option.id] = option.permission.map(
  //         (permission) => permission.value
  //       );
  //     });
  //     setSelectedOptions(allSelected);
  //   }
  //   setSelectAll(!selectAll);
  // };

  // const handleMenuItemsChange = (itemId, itemName, menuItemId) => {
  //   setSelectedOptions((prevSelectedOptions) => {
  //     const optionSelectedPermissions = prevSelectedOptions[itemName] || [];
  //     const updatedPermissions = optionSelectedPermissions.includes(
  //       menuItemId
  //     )
  //       ? optionSelectedPermissions.filter((perm) => perm !== menuItemId)
  //       : [...optionSelectedPermissions, menuItemId];

  //     return {
  //       ...prevSelectedOptions,
  //       [itemName]: updatedPermissions,
  //     };
  //   });
  // };

  const handleMenuItemsChange = (categoryId, itemName, menuItemId) => {
    setSelectedOptions((prevSelectedOptions) => {
      const optionSelectedPermissions = prevSelectedOptions[itemName] || [];
      const updatedPermissions = optionSelectedPermissions.includes(menuItemId)
        ? optionSelectedPermissions.filter((perm) => perm !== menuItemId)
        : [...optionSelectedPermissions, menuItemId];

      return {
        ...prevSelectedOptions,
        [itemName]: updatedPermissions,
      };
    });
  };

  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(true);
  const [open4, setOpen4] = useState(true);
  const [open5, setOpen5] = useState(true);
  const [open6, setOpen6] = useState(true);
  const [open7, setOpen7] = useState(true);
  const [open8, setOpen8] = useState(true);
  const [open9, setOpen9] = useState(true);

  const handleToggle1 = useCallback(() => setOpen1((open1) => !open1), []);
  const handleToggle2 = useCallback(() => setOpen2((open2) => !open2), []);
  const handleToggle3 = useCallback(() => setOpen3((open3) => !open3), []);
  const handleToggle4 = useCallback(() => setOpen4((open4) => !open4), []);
  const handleToggle5 = useCallback(() => setOpen5((open5) => !open5), []);
  const handleToggle6 = useCallback(() => setOpen6((open6) => !open6), []);
  const handleToggle7 = useCallback(() => setOpen7((open7) => !open7), []);
  const handleToggle8 = useCallback(() => setOpen8((open8) => !open8), []);
  const handleToggle9 = useCallback(() => setOpen9((open9) => !open9), []);

  // upload files
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );

  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const fileUpload = !files.length && (
    <DropZone.FileUpload actionHint="Accepts .gif, .jpg, and .png" />
  );

  const currentDate = new Date().toISOString().split("T")[0];

  const uploadedFiles = files.length > 0 && (
    <LegacyStack vertical>
      {files.map((file, index) => (
        <LegacyStack alignment="center" key={index}>
          <Thumbnail
            size="small"
            alt={file.name}
            source={
              validImageTypes.includes(file.type)
                ? window.URL.createObjectURL(file)
                : NoteMinor
            }
          />
          <div>
            {file.name}{" "}
            <Text variant="bodySm" as="p">
              {file.size} bytes
            </Text>
          </div>
        </LegacyStack>
      ))}
    </LegacyStack>
  );

  const [addItemData, setAddItemData] = useState({
    event_name: "",
    category_id: "",
    no_of_pax: "",
    banquet_id: "",
    client_name: "",
    client_phone: "",
    client_email: "",
    no_of_plates: "",
    per_plate_cost: "",
    event_slots: [],
    per_plate_cost: "",
    no_of_plates: "",
    tax_applied: "18",
    gst_type: "exclusive",
    tax_amount: "",
    grand_total: "",
    event_menues: {},
  });

  // Onchange Function
  const setAddItemDataHandleChange = (event) => {
    setAddItemData({
      ...addItemData,
      // property_id: property_id?.id,
      [event.target.name]: event.target.value,
    });
  };

  // For status options
  const [selectedOption, setSelectedOption] = useState("today");

  const statusOptions = [
    { label: "Select option" },
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];
  const slotOptions = [
    { label: "Select option" },
    { label: "Morning", value: "Morning" },
    { label: "Afternoon", value: "Afternoon" },
    { label: "Evening", value: "Evening" },
  ];

  const bedTypeOptions = [
    { label: "Select option", value: "" },
    { label: "Futon", value: "Futon" },
    { label: "King", value: "King" },
    { label: "Murphy Bed", value: "Murphy Bed" },
    { label: "Queen", value: "Queen" },
    { label: "Sofa Bed", value: "Sofa Bed" },
    { label: "Tatami Mats", value: "Tatami Mats" },
    { label: "Twin", value: "Twin" },
    { label: "Single", value: "Single" },
    { label: "Full", value: "Full" },
    { label: "Run of the House", value: "Run of the House" },
    { label: "Dorm Bed", value: "Dorm Bed" },
  ];

  const roomAreaTypeOptions = [
    { label: "Select option", value: "" },
    { label: "Square Meters", value: "Square Meters" },
    { label: "Square Feet", value: "Square Feet" },
  ];

  const userToken = getDecryptedData('encryptToken');

  const roomViewTypeOptions = [
    { label: "Select option", value: "" },
    { label: "Airport view", value: "Airport view" },
    { label: "Bay view", value: "Bay view" },
    { label: "City view", value: "City view" },
    { label: "Courtyard view", value: "Courtyard view" },
    { label: "Golf view", value: "Golf view" },
    { label: "Harbor view", value: "Harbor view" },
    { label: "Intercoastal view", value: "Intercoastal view" },
    { label: "Lake view", value: "Lake view" },
    { label: "Marina view", value: "Marina view" },
    { label: "Mountain view", value: "Mountain view" },
    { label: "Ocean view", value: "Ocean view" },
    { label: "Pool view", value: "Pool view" },
    { label: "River view", value: "River view" },
    { label: "Water view", value: "Water view" },
    { label: "Beach view", value: "Beach view" },
    { label: "Garden view", value: "Garden view" },
    { label: "Park view", value: "Park view" },
    { label: "Forest view", value: "Forest view" },
    { label: "Rain fores", value: ">Rain" },
    { label: "Various views", value: "Various views" },
    { label: "Limited view", value: "Limited view" },
    { label: "Slope view", value: "Slope view" },
    { label: "Strip view", value: "Strip view" },
    { label: "Countryside view", value: "Countryside view" },
    { label: "Sea view", value: "Sea view" },
    { label: "Valley view", value: "Valley view" },
  ];

  const handleSelectStatusChange = useCallback(
    (value) => setSelectedOption(value),
    []
  );

  // for Tag
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);

  const removeTag = useCallback(
    (tag) => () => {
      setSelectedTags((previousTags) =>
        previousTags.filter((previousTag) => previousTag !== tag)
      );
    },
    [selectedTags]
  );

  const [onkeyPressValue, setOnKeyPressValue] = useState("");

  const handleOnKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter" && onkeyPressValue.trim() !== "") {
        e.preventDefault();
        setOnKeyPressValue("");
        // valueArray.push(inputValue)
        setSelectedTags((prevArray) => [...prevArray, onkeyPressValue]);

        // onkeyPressValue = ""
        setOnKeyPressValue("");
      }
    },
    [onkeyPressValue, selectedTags]
  );

  const addCustomSlotHandler = useCallback(
    (e) => {
      if (addItemData.date !== "" && addItemData.slot !== "") {
        const valueToBeAdded = `${addItemData.date} | ${addItemData.slot}`;

        if (selectedTags.includes(valueToBeAdded)) {
          showErrorToast("Already exists");
        } else {
          setSelectedTags((prevArray) => [...prevArray, valueToBeAdded]);
          setAddItemData({
            ...addItemData,
            date: "",
            slot: "",
          });
        }
        // setSelectedDates((prevArray) => [...prevArray, addItemData.date]);
        // setSelectedSlots((prevArray) => [...prevArray, addItemData.slot]);
      } else {
        showErrorToast("Please fill the above inputs");
      }
    },
    [onkeyPressValue, selectedTags, addItemData]
  );

  // const addCustomSlotHandler = useCallback(
  //   (e) => {
  //     if (addItemData.date !== "" && addItemData.slot !== "") {
  //       const valueToBeAdded = `${addItemData.date} (${addItemData.slot})`;

  //       // Check if the value to be added already exists in the arrays
  //       if (!selectedDates.includes(addItemData.date)) {
  //         setSelectedDates((prevArray) => [...prevArray, addItemData.date]);
  //         setSelectedTags((prevArray) => [...prevArray, valueToBeAdded]);
  //       }
  //       if (!selectedSlots.includes(addItemData.slot)) {
  //         setSelectedSlots((prevArray) => [...prevArray, addItemData.slot]);
  //         setSelectedTags((prevArray) => [...prevArray, valueToBeAdded]);
  //       }
  //     } else {
  //       showErrorToast("Please fill the above inputs");
  //     }
  //   },
  //   [onkeyPressValue, selectedDates, selectedSlots, addItemData]
  // );

  const setOnKeyPressValueHandleChange = useCallback(
    (e) => {
      if (e.target.name === "event_slots") {
        setSelectedTags(selectedTags);
      }
      setOnKeyPressValue(e.target.value);
    },
    [selectedTags]
  );

  const tagMarkup = selectedTags.map((option) => (
    <Tag key={option} onRemove={removeTag(option)}>
      {option}
    </Tag>
  ));

  // dynamic inputs
  const [dynamicInputList, setDynamicInputList] = useState([
    {
      id: 1,
      select_add_on: "",
      add_on_cost: "",
    },
  ]);

  const handleAddClick = (id) => {
    const lastItem = dynamicInputList[dynamicInputList.length - 1];
    if (
      (lastItem && lastItem.select_add_on === "") ||
      lastItem.add_on_cost === "" ||
      lastItem.add_on_cost === " "
    ) {
      showErrorToast("Please add the above fields first...!!");
    } else {
      setDynamicInputList([
        ...dynamicInputList,
        {
          id: id + 1,
          select_add_on: "",
          add_on_cost: "",
        },
      ]);
    }
  };

  const handleRemoveClick = (idToRemove) => {
    const updatedList = dynamicInputList.filter(
      (item) => item.id !== idToRemove
    );
    setDynamicInputList(updatedList);
  };

  const dynamicPreviewBillHandleChange = (e, index) => {
    const { name, value } = e.target;

    const isDuplicate = dynamicInputList.some(
      (item, i) =>
        i !== index && item.select_add_on === value && name === "select_add_on"
    );

    if (isDuplicate) {
      showErrorToast("Duplicate entry.");
      return;
    }

    setDynamicInputList((prevInputList) => {
      const updatedInputList = [...prevInputList];
      updatedInputList[index][name] = value;
      return updatedInputList;
    });
  };

  const [selectedDatesRange, setSelectedDatesRange] = useState({
    from_date: "",
    to_date: "",
  });

  const handleDateRangeChange = (dates, formattedFromDate, formattedToDate) => {
    setSelectedDatesRange({
      from_date: formattedFromDate,
      to_date: formattedToDate,
    });

    if (formattedToDate === "") {
    } else {
      if (property_id) {
        fetchEventSlots(
          property_id.id,
          formattedFromDate,
          formattedToDate,
          addItemData.banquet_id
        );
      }
    }
  };

  // const dataFromChild = (from_date, to_date) => {
  //   setSelectedDatesRange({
  //     from_date: from_date,
  //     to_date: to_date,
  //   });
  //   if (property_id) {
  //     fetchEventSlots(
  //       property_id.id,
  //       from_date,
  //       to_date,
  //       addItemData.banquet_id
  //     );
  //   }
  // };

  // CALCULATIONS

  // calculate total plate plus food cost
  const [platesCost, setPlatesCost] = useState(null);
  useEffect(() => {
    const FoodCost = addItemData.no_of_plates * addItemData.per_plate_cost;
    setPlatesCost(FoodCost);
  }, [addItemData.no_of_plates, addItemData.per_plate_cost]);

  // calculate the total addon cost
  const [totalAddonCost, setTotalAddonCost] = useState(null);
  useEffect(() => {
    const totalAddonCost = dynamicInputList.reduce(
      (prev, curr, index, array) => +prev + +curr.add_on_cost,
      0
    );
    setTotalAddonCost(totalAddonCost, "totalAddonCost");
  }, [dynamicInputList]);

  // Calculate total before tax and apply tax and calculate subtotal, grandtotal
  const [grandTotal, setGrandTotal] = useState("");
  const [taxAmount, setTaxAmount] = useState("");
  const [subtotal, setSubtotal] = useState("");

  useEffect(() => {
    const newSubtotal = parseFloat(totalAddonCost) + parseFloat(platesCost);

    setSubtotal(newSubtotal);
    // Calculate the combined discount (property_discount_percent + tacPercent)
    const discountPercent = 0;

    if (!isNaN(discountPercent) && discountPercent <= 100) {
      const discountAmount = (discountPercent / 100) * newSubtotal;

      // Calculate the tax amount and add it to the subtotal if taxApplied is valid
      const taxPercent = parseFloat(addItemData.tax_applied);

      if (!isNaN(taxPercent) && taxPercent >= 0) {
        const taxAmount = (taxPercent / 100) * newSubtotal;
        let grandTotal;

        // Handle GST type (inclusive/exclusive)
        if (addItemData.gst_type === "exclusive") {
          grandTotal = newSubtotal + taxAmount;
        } else {
          grandTotal = newSubtotal;
        }

        setSubtotal(newSubtotal - discountAmount);
        setTaxAmount(taxAmount); // Set tax amount
        setGrandTotal(grandTotal);
      } else {
        setSubtotal(newSubtotal - discountAmount);
        setTaxAmount(0); // Set tax amount to 0 when taxPercent is invalid
        setGrandTotal(newSubtotal - discountAmount);
      }
    } else {
      setSubtotal(newSubtotal);
      setTaxAmount(0); // Set tax amount to 0 when discountPercent is invalid
      setGrandTotal(newSubtotal);
    }
  }, [addItemData, subtotal, platesCost, totalAddonCost]);

  useEffect(() => {
    if (property_id) {
      setAddItemData({
        ...addItemData,
        event_slots: selectedTags,
        property_id: property_id?.id,
      });
    }
  }, [property_id]);

  const [bedding, setBedding] = useState("");

  const beddingFinalValue = useCallback((data) => {
    setBedding(data);
  }, []);

  useEffect(() => {
    if (selectedTags) {
      setAddItemData({
        ...addItemData,
        // banquet_parking: bedding,
        event_slots: selectedTags,
        property_id: property_id?.id,
      });
    }
  }, [selectedTags, bedding]);

  useEffect(() => {
    if (property_id) {
      fetchEventCategories(property_id?.id);
      fetchAllBanquets(property_id?.id);
      fetchAllRoomTypes(property_id?.id);
    }
  }, [property_id]);

  const [eventCategories, setEventCategories] = useState([]);

  const fetchEventCategories = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        // `${process.env.REACT_APP_BASE_URL}/api/v1/event/update/${id}/${property_id?.id}`
        `${process.env.REACT_APP_BASE_URL}/api/v1/event/category/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setEventCategories(data.categories);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const [eventSlots, setEventSlots] = useState(null);

  const fetchEventSlots = async (propertyId, from_date, to_date, banquetId) => {
    try {
      if (from_date === "" || to_date === "") {
        showErrorToast("Please select the Date Range First..");
        return;
      }
      if (addItemData.banquet_id === "") {
        showErrorToast("Please select the banquet first..");
        return;
      }
      // setLoader(true);
      const response = await fetch(
        // `${process.env.REACT_APP_BASE_URL}/api/v1/event/update/${id}/${property_id?.id}`
        `${process.env.REACT_APP_BASE_URL}/api/v1/check/banquet/slot/${banquetId}/${propertyId}?start_date=${from_date}&end_date=${to_date}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setEventSlots(data.slots);
      // setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const [allBanquets, setAllBanquets] = useState([]);

  const fetchAllBanquets = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        // `${process.env.REACT_APP_BASE_URL}/api/v1/event/update/${id}/${property_id?.id}`
        `${process.env.REACT_APP_BASE_URL}/api/v1/banquet/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAllBanquets(data.banquet);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const [allRoomTypes, setAllRoomTypes] = useState([]);

  const fetchAllRoomTypes = async (propertyId) => {
    try {
      setLoader(true);
      const response = await fetch(
        // `${process.env.REACT_APP_BASE_URL}/api/v1/event/update/${id}/${property_id?.id}`
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAllRoomTypes(data.rooms);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const [availableSlots, setAvailableSlots] = useState([]);

  const fetchAvailableSlots = async (id, date) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/check/banquet/slot/${date}/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json().then((data) => {
        // if (data.success) {
        setAvailableSlots(data.slots);
        // } else {
        //   showErrorToast("Error fetching Slots");
        // }
      });
    } catch (error) {
      console.error(error);
      showErrorToast("Something went wrong");
    }
  };

  // const choiceListArray = [
  //   {
  //     label: "Automatic Discount",
  //     value: "automatic",
  //   },
  //   {
  //     label: "Discount Code",
  //     value: "discount_code",
  //   },
  // ];
  const choiceListArray = allBanquets.map((banquet, index) => {
    const label = index === 0 ? "Select an option" : banquet.name;
    return { label, value: banquet.id };
  });

  const [hotelRoomArray, setHotelRoomArray] = useState([]);

  const holdHandleCheckboxChange = async (e, room) => {
    const roomId = room.value;
    const isChecked = e.target.checked;
    if (selectedDatesRange.from_date === "") {
      showErrorToast("Please fill the above dates.");
      return;
    }

    if (isChecked) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/inventories/edit/${roomId}/${selectedDatesRange.from_date}/${property_id?.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          const availableRooms = data.available_rooms;

          setHotelRoomArray((prevRoomData) => [
            ...prevRoomData,
            {
              property_id: property_id?.id,
              available_rooms: availableRooms,
              room_id: roomId,
              booking_rooms: "",
              from_date: selectedDatesRange.from_date,
              to_date: selectedDatesRange.to_date,
            },
          ]);
        } else {
          // Handle error if the fetch request is not successful
          console.error("Failed to fetch available rooms data");
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    } else {
      setHotelRoomArray((prevRoomData) =>
        prevRoomData.filter((data) => data.room_id !== roomId)
      );
    }
  };

  const handleHoldInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRoomData = [...hotelRoomArray];

    // Find the index of the current room data
    const roomDataIndex = updatedRoomData.findIndex(
      (data) => data.room_id === hotelRoomArray[index].room_id
    );

    if (!isNaN(value)) {
      if (
        name === "booking_rooms" &&
        parseInt(value) > hotelRoomArray[index].available_rooms
      ) {
        showErrorToast("Booking Rooms should be less then Available Rooms.");
        return;
      }

      updatedRoomData[roomDataIndex][name] = value;
      setHotelRoomArray(updatedRoomData);
    } else {
      showErrorToast("Please enter a valid number.");
    }
  };

  const choiceListRoomsArray = allRoomTypes.map((room) => {
    return { label: room.room_name, value: room.id };
  });

  const [selectedTimes, setSelectedTimes] = useState({});

  const handleCheckboxChange = (date, time) => {
    setSelectedTimes((prevSelectedTimes) => {
      const updatedTimes = { ...prevSelectedTimes };
      if (updatedTimes[date]) {
        if (updatedTimes[date].includes(time)) {
          updatedTimes[date] = updatedTimes[date].filter(
            (selectedTime) => selectedTime !== time
          );
        } else {
          updatedTimes[date] = [...updatedTimes[date], time];
        }
      } else {
        updatedTimes[date] = [time];
      }
      return updatedTimes;
    });
  };

  // useEffect(() => {
  //   if (addItemData.date != "") {
  //     fetchAvailableSlots(property_id.id, addItemData.date);
  //   }
  // }, [property_id, addItemData.date]);

  const eventCategoriesOptions = [
    { label: "Select Option" },
    ...eventCategories.map((category) => {
      return { label: category.category_name, value: category.id };
    }),
  ];

  const slotSelectOptions = [
    { label: "Select Option" },
    ...availableSlots?.map((slot) => {
      return { label: slot, value: slot };
    }),
  ];
  const [selectedDateFilter, setSelectedDateFilter] = useState([]);

  const [selectedDiscountMethod, setSelectedDiscountMethod] = useState([]);
  const handleDiscountMethodChange = useCallback(
    (value) => setSelectedDiscountMethod(value),
    []
  );

  const navigate = useNavigate();

  const [roomTypeAddImages, setRoomTypeAddImages] = useState([]);

  // Validate function
  const validate = (values) => {
    const errors = {};
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneNumberRegex = /^([+]\d{2})?\d{10}$/;
    const pincodeRegex = /^[1-9][0-9]{5}$/;

    if (!values.client_email) {
      // errors.property_email = "Email is required!";
    } else {
      if (emailRegex.test(values.client_email)) {
        errors.client_email = "";
      } else {
        errors.client_email = "This is not a valid email format!";
      }
    }

    if (!values.client_phone) {
      errors.client_phone = "Phone number is required!";
    } else {
      if (phoneNumberRegex.test(values.client_phone)) {
        errors.client_phone = "";
      } else {
        errors.client_phone = "This is not a valid Phone Number!";
      }
    }

    return errors;
  };

  const updateDataFromChild = (updatedImages) => {
    setRoomTypeAddImages(updatedImages);
  };

  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const urls = roomTypeAddImages.map((image) => URL.createObjectURL(image));
    setImageUrls(urls);

    return () => {
      urls.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [roomTypeAddImages]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const imageDataArray = files.map((file) => file);
    setRoomTypeAddImages(imageDataArray);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.dataTransfer.files);
    setRoomTypeAddImages([...roomTypeAddImages, ...newFiles]);
    // updateDataFromChild(selectedImages);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    setAddItemData({
      ...addItemData,
      event_slots: selectedTimes,
      add_on: dynamicInputList,
      grand_total: grandTotal,
      tax_amount: taxAmount,
      sub_total: subtotal,
    });
  }, [selectedTimes, subtotal, grandTotal, taxAmount]);

  const handleAddEvent = (e) => {
    e.preventDefault();

    const errors = validate(addItemData);
    setFormErrors(errors);
    const hasErrors = Object.values(errors).some((error) => !!error);

    if (hasErrors === true) {
      showErrorToast("Please check the above errors.");
      return;
    }
    // setFormErrors(validate());
    // setLoader(true);

    // if (addItemData.min_pax > addItemData.max_pax) {
    //   showErrorToast("Minimum pax should be less then maximum pax...");
    //   return;
    // }

    if (addItemData.event_slots.length < 1) {
      showErrorToast("Minimum one slot is required...!!");
      return;
    }
    if (Object.keys(selectedOptions).length === 0) {
      showErrorToast("Please select the menus first...!!");
      return null; // or return an error message, component, etc.
    }

    setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/create/event/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addItemData,
          hotel_rooms: hotelRoomArray,
          balance_amount: addItemData.grand_total,
          event_menues: selectedOptions,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setLoader(false);
          showSuccessToast(data.message);
          navigate("/events");
        } else {
          showErrorToast(data.message);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
        setLoader(false);
      });
  };

  const addonsForBanquetEvents = [
    { id: 0, label: "Select Option" },
    { id: 1, label: "Live Music or DJ", value: "Live Music or DJ" },
    { id: 2, label: "Photo Booth", value: "Photo Booth" },
    {
      id: 3,
      label: "Interactive Performances",
      value: "Interactive Performances",
    },
    { id: 4, label: "Themed Decor", value: "Themed Decor" },
    { id: 5, label: "Lighting Effects", value: "Lighting Effects" },
    { id: 6, label: "Floral Arrangements", value: "Floral Arrangements" },
    { id: 7, label: "Food Stations", value: "Food Stations" },
    { id: 8, label: "Customized Menus", value: "Customized Menus" },
    {
      id: 9,
      label: "Dessert Buffet or Sweet Stations",
      value: "Dessert Buffet or Sweet Stations",
    },
    {
      id: 10,
      label: "Audiovisual Enhancements",
      value: "Audiovisual Enhancements",
    },
    { id: 11, label: "Live Streaming", value: "Live Streaming" },
    { id: 12, label: "Games or Contests", value: "Games or Contests" },
    {
      id: 13,
      label: "Silent Auction or Raffle",
      value: "Silent Auction or Raffle",
    },
    { id: 14, label: "Lounge Areas", value: "Lounge Areas" },
    { id: 15, label: "Gift Bags or Favors", value: "Gift Bags or Favors" },
    { id: 16, label: "Wellness Stations", value: "Wellness Stations" },
    {
      id: 17,
      label: "Customized Fitness Activities",
      value: "Customized Fitness Activities",
    },
    {
      id: 18,
      label: "Event Planner or Coordinator",
      value: "Event Planner or Coordinator",
    },
    {
      id: 19,
      label: "Valet Services or Transportation",
      value: "Valet Services or Transportation",
    },
    { id: 20, label: "Art Installations", value: "Art Installations" },
    { id: 21, label: "Green Initiatives", value: "Green Initiatives" },
  ];

  const renderSchedule = () => {
    return Object.keys(eventSlots).map((date) => (
      <div
        key={date}
        style={{
          display: "flex",
          flexDirection: "column",
          borderBottom: "0.5px solid #CCC",
          padding: "15px 0",
        }}
      >
        <div className="dynamic_event_slot_date">{date}</div>
        {eventSlots[date].length < 1 ? (
          <div>No slot available... </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            {eventSlots[date].map((time, index) => {
              return (
                <>
                  <label key={index} style={{ marginRight: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="checkbox"
                          value={time}
                          checked={selectedTimes[date]?.[time]}
                          onChange={() => handleCheckboxChange(date, time)}
                        />
                      </div>
                      <div>{time}</div>
                    </div>
                  </label>
                </>
              );
            })}
          </div>
        )}
      </div>
    ));
  };

  // const scheduleData = {
  //   "2023-11-07": ["Morning", "Afternoon", "Evening"],
  //   "2023-11-09": ["Morning"],
  //   "2023-11-08": ["Morning"],
  //   "2023-11-10": ["Morning"],
  // };

  useEffect(() => {
    const formattedData = [];

    for (const date in selectedTimes) {
      const times = selectedTimes[date];
      if (times.length > 0) {
        const entry = `${date} | ${times.join(", ")}`;
        formattedData.push(entry);
      }
    }

    // setSelectedTags(formattedData);
  }, [selectedTimes]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <form
            onSubmit={(e) => handleAddEvent(e)}
            className="edit-rooms-full-container"
          >
            <div>
              <Page
                backAction={{ content: "Products", url: `/events` }}
                title="Create Event"
                compactTitle
              >
                <div className="edit-rooms-full-container-div">
                  <div className="edit-rooms-full-container-left">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        width: "591px",
                      }}
                    >
                      <div>
                        <LegacyCard sectioned>
                          <LegacyStack vertical>
                            <div
                              onClick={handleToggle1}
                              aria-expanded={open1}
                              aria-controls="basic-collapsible"
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontWeight: "700",
                                fontSize: "14px",
                                cursor: "pointer",
                              }}
                            >
                              <div>Event details</div>
                              <div>
                                <Icon source={ChevronDownMinor} />
                              </div>
                            </div>
                            <Collapsible
                              open={open1}
                              id="basic-collapsible"
                              transition={{
                                duration: "500ms",
                                timingFunction: "ease-in-out",
                              }}
                              expandOnPrint
                            >
                              <div className="edit-rooms-form-wrapper-container">
                                <div className="edit-rooms-form-container">
                                  <div className="form-container-div-half">
                                    <div className="form-container-div">
                                      <VerticalInputFieldCustom
                                        required={true}
                                        focused={false}
                                        name="event_name"
                                        titleName="Event Name"
                                        onChange={setAddItemDataHandleChange}
                                        autoComplete="off"
                                        value={addItemData.event_name}
                                      />
                                    </div>
                                  </div>

                                  {/* Event Category */}
                                  <div className="form-container-div-half">
                                    <div className="form-container-div">
                                      {eventCategoriesOptions &&
                                      eventCategoriesOptions.length <= 1 ? (
                                        <div style={{ marginTop: "25px" }}>
                                          Add event categories first.
                                        </div>
                                      ) : (
                                        <VerticalInputSelectCustomCopy
                                          options={eventCategoriesOptions}
                                          required={true}
                                          type={"text"}
                                          titleName="Event Type"
                                          name="category_id"
                                          value={addItemData.category_id}
                                          onChange={setAddItemDataHandleChange}
                                        />
                                      )}
                                    </div>
                                    <div className="form-container-div">
                                      <VerticalInputFieldCustom
                                        required={true}
                                        type={"number"}
                                        name="no_of_pax"
                                        value={addItemData.no_of_pax}
                                        focused={false}
                                        titleName="No of Pax"
                                        onChange={setAddItemDataHandleChange}
                                        autoComplete="off"
                                      />
                                    </div>
                                    <div className="form-container-div-half">
                                      <div className="form-container-div">
                                        <VerticalInputSelectCustomCopy
                                          options={choiceListArray}
                                          required={true}
                                          type={"number"}
                                          name="banquet_id"
                                          value={addItemData.banquet_id}
                                          focused={false}
                                          titleName="Select Banquet"
                                          onChange={setAddItemDataHandleChange}
                                          autoComplete="off"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Collapsible>
                          </LegacyStack>
                        </LegacyCard>
                      </div>
                      <div>
                        <LegacyCard sectioned>
                          <LegacyStack vertical>
                            <div
                              onClick={handleToggle4}
                              aria_expanded={open4}
                              aria_controls="basic-collapsible"
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontWeight: "700",
                                fontSize: "14px",
                              }}
                            >
                              <div>Plates & Cost</div>
                              <div>
                                <Icon source={ChevronDownMinor} />
                              </div>
                            </div>
                            <Collapsible
                              open={open4}
                              id="basic-collapsible"
                              transition={{
                                duration: "500ms",
                                timingFunction: "ease-in-out",
                              }}
                              expandOnPrint
                            >
                              <div className="edit-rooms-form-container">
                                <div className="form-container-div-half">
                                  <div className="form-container-div">
                                    <VerticalInputFieldCustom
                                      required={true}
                                      type={"number"}
                                      name="no_of_plates"
                                      value={addItemData.no_of_plates}
                                      focused={false}
                                      titleName="No of Plates"
                                      onChange={setAddItemDataHandleChange}
                                      autoComplete="off"
                                    />
                                  </div>
                                  <div className="form-container-div">
                                    <VerticalInputFieldCustom
                                      required={true}
                                      type={"number"}
                                      name="per_plate_cost"
                                      value={addItemData.per_plate_cost}
                                      focused={false}
                                      titleName="Per Plate Cost"
                                      onChange={setAddItemDataHandleChange}
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>

                                <div className="form-container-div">
                                  <div>Total Cost : {platesCost}</div>
                                </div>
                              </div>
                            </Collapsible>
                          </LegacyStack>
                        </LegacyCard>
                      </div>

                      <div>
                        <LegacyCard sectioned>
                          <LegacyStack vertical>
                            <div
                              onClick={handleToggle7}
                              aria_expanded={open7}
                              aria_controls="basic-collapsible"
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontWeight: "700",
                                fontSize: "14px",
                              }}
                            >
                              <div>Event Date & Slot</div>
                              <div>
                                <Icon source={ChevronDownMinor} />
                              </div>
                            </div>
                            <Collapsible
                              open={open7}
                              id="basic-collapsible"
                              transition={{
                                duration: "500ms",
                                timingFunction: "ease-in-out",
                              }}
                              expandOnPrint
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  alignItems: "flex-end",
                                  gap: "10px",
                                }}
                                className="edit-rooms-form-container"
                              >
                                <div
                                  style={{ width: "100%" }}
                                  className="form-container-div-full"
                                >
                                  {/* <div style={{ marginBottom: "5px" }}>
                                    Date
                                  </div> */}
                                  {/* <DateRangePicker
                                    selectedDatesRange={selectedDatesRange}
                                    onDateRangeChange={handleDateRangeChange}
                                  /> */}
                                  <SelectDateRange minDate={new Date()} dataFromChild={handleDateRangeChange} />
                                 
                                </div>
                                {/* <div
                                  style={{ width: "100%" }}
                                  className="form-container-div-full"
                                >
                                  {eventSlots !== null ? (
                                    <BgOutlineThemeButton
                                      style={{ width: "100%" }}
                                      type={"button"}
                                      // onClick={addCustomSlotHandler}
                                      onClick={() => setShowAddSlot(true)}
                                      children={"Select Slots"}
                                    />
                                  ) : (
                                    <BgOutlineThemeButton
                                      style={{
                                        width: "100%",
                                        cursor: "no-drop",
                                        opacity: "0.6",
                                      }}
                                      type={"button"}
                                      children={"Select Slots"}
                                    />
                                  )}
                                </div> */}

                                {/* <LegacyStack spacing="tight">
                                  {tagMarkup}
                                </LegacyStack> */}
                              </div>
                              {/* <LegacyStack spacing="tight">
                                  <div>{renderSchedule()}</div>
                                </LegacyStack> */}
                              {eventSlots !== null && (
                                <div>{renderSchedule()}</div>
                              )}
                            </Collapsible>
                          </LegacyStack>
                        </LegacyCard>
                      </div>

                      <div>
                        <LegacyCard sectioned>
                          <LegacyStack vertical>
                            <div
                              onClick={handleToggle5}
                              aria_expanded={open5}
                              aria_controls="basic-collapsible"
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontWeight: "700",
                                fontSize: "14px",
                              }}
                            >
                              <div>Hotel Rooms</div>
                              <div>
                                <Icon source={ChevronDownMinor} />
                              </div>
                            </div>

                            <Collapsible
                              open={open5}
                              id="basic-collapsible"
                              transition={{
                                duration: "500ms",
                                timingFunction: "ease-in-out",
                              }}
                              expandOnPrint
                            >
                              <div className="edit-rooms-form-container">
                                <div className="form-container-div-full">
                                  {/* {choiceListRoomsArray.map((room) => {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                        className="custom_checkbox"
                                      >
                                        <div style={{ paddingBottom: "10px" }}>
                                          <label
                                            style={{
                                              display: "flex",
                                              justifyContent: "start",
                                              alignItems: "center",
                                              gap: "8px",
                                            }}
                                            for={room.value}
                                          >
                                            <input
                                              selected
                                              type="checkbox"
                                              id={room.value}
                                              name="fav_language"
                                              value={room.value}
                                            />
                                            <div>{room.label}</div>
                                            <span class="custom_checkmark"></span>
                                          </label>
                                        </div>
                                        <div>
                                          <input
                                            style={{
                                              width: "50px",
                                              height: "25px",
                                            }}
                                            type="number"
                                            value={"Qty: 2"}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })} */}

                                  {/* <div className="form-container-div-half">
                                    <div className="form-container-div">
                                      <VerticalInputFieldCustom
                                        required={true}
                                        type="date"
                                        name="from_date"
                                        className="textfield"
                                        titleName="Start Date"
                                        // min={currentDate}
                                        // value={holdInventoryData.from_date}
                                        // onChange={setHoldInventoryHandleChange}
                                      />
                                    </div>
                                    <div
                                      style={{}}
                                      className="form-container-div"
                                    >
                                      <VerticalInputFieldCustom
                                        required={true}
                                        type="date"
                                        name="to_date"
                                        className="textfield"
                                        titleName="End Date"
                                        focused={false}
                                        // onChange={setHoldInventoryHandleChange}
                                        autoComplete="off"
                                        // min={holdInventoryData.from_date || currentDate}
                                        // value={holdInventoryData.to_date}
                                      />
                                    </div>
                                  </div> */}

                                  <>
                                    {choiceListRoomsArray.length < 1 ? (
                                      <div>
                                        No room types are added in the property.
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "13px",
                                          color: "#333",
                                          fontFamily: "Inter",
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          flexDirection: "column",
                                        }}
                                      >
                                        {choiceListRoomsArray.map((room) => {
                                          const roomDataIndex =
                                            hotelRoomArray.findIndex(
                                              (data) =>
                                                data.room_id === room.value
                                            );
                                          return (
                                            <div
                                              key={room.id}
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                gap: "10px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  alignItems: "center",
                                                  gap: "10px",
                                                }}
                                              >
                                                <input
                                                  style={{ cursor: "pointer" }}
                                                  id={room.id}
                                                  type="checkbox"
                                                  name="update_type"
                                                  onChange={(e) =>
                                                    holdHandleCheckboxChange(
                                                      e,
                                                      room
                                                    )
                                                  }
                                                  value={room.id}
                                                  checked={roomDataIndex !== -1}
                                                />
                                                <label
                                                  style={{ cursor: "pointer" }}
                                                  htmlFor={room.id}
                                                >
                                                  {room.label}
                                                </label>
                                              </div>

                                              {roomDataIndex !== -1 && (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    gap: "20px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "fex-start",
                                                      alignItems: "center",
                                                    }}
                                                    className="form-container-div"
                                                  >
                                                    <div
                                                      style={{ width: "65px" }}
                                                    >
                                                      Available:
                                                    </div>
                                                    <div
                                                      style={{ width: "40px" }}
                                                    >
                                                      <input
                                                        onWheel={(e) =>
                                                          e.target.blur()
                                                        }
                                                        style={{
                                                          width: "100%",
                                                          padding: "2px 2px",
                                                        }}
                                                        type="number"
                                                        name="available_rooms"
                                                        className="textfield"
                                                        // titleName="Available Rooms:"
                                                        value={
                                                          hotelRoomArray[
                                                            roomDataIndex
                                                          ].available_rooms
                                                        }
                                                        disabled
                                                        onChange={(e) =>
                                                          handleHoldInputChange(
                                                            e,
                                                            roomDataIndex
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "fex-start",
                                                      alignItems: "center",
                                                    }}
                                                    className="form-container-div"
                                                  >
                                                    <div
                                                      style={{
                                                        marginRight: "6px",
                                                      }}
                                                    >
                                                      Qty:
                                                    </div>
                                                    <div
                                                      style={{ width: "40px" }}
                                                    >
                                                      <input
                                                        required
                                                        onWheel={(e) =>
                                                          e.target.blur()
                                                        }
                                                        style={{
                                                          width: "100%",
                                                          padding: "2px 2px",
                                                        }}
                                                        min={1}
                                                        // required={true}
                                                        type="number"
                                                        name="booking_rooms"
                                                        className="textfield"
                                                        // titleName="Hold Rooms"
                                                        value={
                                                          hotelRoomArray[
                                                            roomDataIndex
                                                          ].booking_rooms
                                                        }
                                                        onChange={(e) =>
                                                          handleHoldInputChange(
                                                            e,
                                                            roomDataIndex
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )}
                                  </>
                                  {/* <ChoiceList
                                    // title="Method"
                                    choices={choiceListRoomsArray}
                                    selected={selectedDiscountMethod}
                                    onChange={handleDiscountMethodChange}
                                    allowMultiple
                                  /> */}
                                </div>
                              </div>
                            </Collapsible>
                          </LegacyStack>
                        </LegacyCard>
                      </div>

                      <div>
                        <LegacyCard sectioned>
                          <LegacyStack vertical>
                            <div
                              onClick={handleToggle2}
                              aria_expanded={open2}
                              aria_controls="basic-collapsible"
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontWeight: "700",
                                fontSize: "14px",
                              }}
                            >
                              <div>Add Ons</div>
                              <div>
                                <Icon source={ChevronDownMinor} />
                              </div>
                            </div>

                            <Collapsible
                              open={open2}
                              id="basic-collapsible"
                              transition={{
                                duration: "500ms",
                                timingFunction: "ease-in-out",
                              }}
                              expandOnPrint
                            >
                              <div className="edit-rooms-form-container">
                                <div className="form-container-div-half">
                                  <table
                                    style={{
                                      backgroundColor: "white",
                                      padding: "0 10px 20px 0px",
                                      borderCollapse: "collapse",
                                      borderRadius: "10px",
                                      fontFamily: "Inter",
                                      width: "100%",
                                    }}
                                  >
                                    <tr
                                      style={{
                                        textAlign: "left",
                                      }}
                                    >
                                      <th style={{ padding: "0px" }}>
                                        Select Add On
                                      </th>
                                      <th style={{ padding: "0px" }}>
                                        Add On Cost
                                      </th>
                                      {/* <th style={{ padding: "10px" }}>Total Amount</th> */}
                                      <th
                                        style={{
                                          width: "10px",
                                          padding: "10px",
                                        }}
                                      ></th>
                                    </tr>

                                    {dynamicInputList &&
                                      dynamicInputList.map((bill, index) => {
                                        return (
                                          // <div>{bill.reference}</div>
                                          <tr>
                                            <>
                                              <td
                                                style={{ paddingRight: "10px" }}
                                              >
                                                <VerticalInputSelectCustomCopy
                                                  options={
                                                    addonsForBanquetEvents
                                                  }
                                                  className="inputDynamicPreviewBill"
                                                  type="text"
                                                  name="select_add_on"
                                                  value={
                                                    dynamicInputList[index]
                                                      ?.select_add_on
                                                  }
                                                  style={{ width: "100%" }}
                                                  onChange={(e) =>
                                                    dynamicPreviewBillHandleChange(
                                                      e,
                                                      index
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <VerticalInputFieldCustom
                                                  className="inputDynamicPreviewBill"
                                                  type="text"
                                                  name="add_on_cost"
                                                  value={
                                                    dynamicInputList[index]
                                                      ?.add_on_cost
                                                  }
                                                  style={{ width: "100px" }}
                                                  onChange={(e) =>
                                                    dynamicPreviewBillHandleChange(
                                                      e,
                                                      index
                                                    )
                                                  }
                                                />
                                              </td>

                                              <td
                                                style={{
                                                  padding: "10px",
                                                  width: "10px",
                                                }}
                                              >
                                                {dynamicInputList.length ===
                                                index + 1 ? (
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      handleAddClick(bill.id)
                                                    }
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="14"
                                                      height="14"
                                                      viewBox="0 0 14 14"
                                                      fill="none"
                                                    >
                                                      <g clipPath="url(#clip0_1270_978)">
                                                        <path
                                                          d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                                          stroke="#3968ED"
                                                        />
                                                        <path
                                                          d="M10.5866 7H3.41333"
                                                          stroke="#3968ED"
                                                          strokeMiterlimit="10"
                                                        />
                                                        <path
                                                          d="M7 3.41344L7 10.5867"
                                                          stroke="#3968ED"
                                                          strokeMiterlimit="10"
                                                        />
                                                      </g>
                                                      <defs>
                                                        <clipPath id="clip0_1270_978">
                                                          <rect
                                                            width="14"
                                                            height="14"
                                                            fill="white"
                                                          />
                                                        </clipPath>
                                                      </defs>
                                                    </svg>
                                                  </div>
                                                ) : (
                                                  <div
                                                    onClick={() =>
                                                      handleRemoveClick(bill.id)
                                                    }
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="14"
                                                      height="14"
                                                      viewBox="0 0 14 14"
                                                      fill="none"
                                                    >
                                                      <g clipPath="url(#clip0_1270_876)">
                                                        <path
                                                          d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                                          stroke="#3968ED"
                                                        />
                                                        <path
                                                          d="M10.5866 7H3.41333"
                                                          stroke="#3968ED"
                                                          strokeMiterlimit="10"
                                                        />
                                                      </g>
                                                      <defs>
                                                        <clipPath id="clip0_1270_876">
                                                          <rect
                                                            width="14"
                                                            height="14"
                                                            fill="white"
                                                          />
                                                        </clipPath>
                                                      </defs>
                                                    </svg>
                                                  </div>
                                                )}
                                              </td>
                                            </>
                                          </tr>
                                        );
                                      })}
                                    <div style={{ marginTop: "10px" }}>
                                      Total Addon Cost : {totalAddonCost}
                                    </div>
                                  </table>
                                </div>
                              </div>
                            </Collapsible>
                          </LegacyStack>
                        </LegacyCard>
                      </div>

                      <div>
                        <LegacyCard sectioned>
                          <LegacyStack vertical>
                            <div
                              onClick={handleToggle6}
                              aria_expanded={open6}
                              aria_controls="basic-collapsible"
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontWeight: "700",
                                fontSize: "14px",
                              }}
                            >
                              <div>Event Menus</div>
                              <div>
                                <Icon source={ChevronDownMinor} />
                              </div>
                            </div>
                            <Collapsible
                              open={open6}
                              id="basic-collapsible"
                              transition={{
                                duration: "500ms",
                                timingFunction: "ease-in-out",
                              }}
                              expandOnPrint
                            >
                              <form
                                onSubmit={(e) => e.preventDefault()}
                                style={{ width: "100%" }}
                                className="stock-add-model-container-two"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <div style={{ cursor: "pointer" }}></div>
                                </div>
                                <div className="permission_container">
                                  <h1 className="permission_heading">Menus</h1>
                                  {/* <div className="permission_checkBox_container  ">
                                    <label>
                                      <input
                                        style={{ marginRight: "10px", marginLeft: "10px" }}
                                        type="checkbox"
                                        checked={selectAll}
                                        onChange={handleSelectAll}
                                      />
                                      Select All
                                    </label>
                                  </div> */}
                                  <div className="permission_item-accords">
                                    {options?.map((option) => (
                                      <div
                                        style={{ padding: "10px" }}
                                        key={option.id}
                                        className={`accordion-item ${
                                          activeAccordion === option.category_id
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <div
                                          // style={{ cursor: "pointer" }}
                                          className="addstaff_accordion-title"
                                          onClick={() =>
                                            toggleAccordion(option.category_id)
                                          }
                                          style={{
                                            backgroundColor:
                                              "rgba(235, 236, 239, 1)",
                                            cursor: "pointer",
                                            width: "100%",
                                            padding: "10px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <span
                                              style={{
                                                marginLeft: "10px",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {option.item_category}
                                            </span>
                                          </div>

                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              gap: "8px",
                                            }}
                                          >
                                            <div>
                                              (
                                              {selectedOptions[
                                                option.item_category
                                              ]
                                                ? selectedOptions[
                                                    option.item_category
                                                  ].length
                                                : 0}
                                              /{option.menu_items.length})
                                            </div>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="12"
                                              height="8"
                                              viewBox="0 0 12 8"
                                              fill="none"
                                            >
                                              <path
                                                d="M11 1.5L6 6.5L0.999999 1.5"
                                                stroke="#888888"
                                                strokeWidth="1.5"
                                              />
                                            </svg>
                                          </div>
                                        </div>
                                        <div className="addstaff_accordion-content">
                                          {activeAccordion ===
                                            option.category_id && (
                                            <div>
                                              {option.menu_items.map(
                                                (permission) => (
                                                  <div
                                                    key={permission.id}
                                                    className="addstaff_secondCkeckbox_container"
                                                  >
                                                    <label
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        cursor: "pointer",
                                                        width: "fit-content",
                                                      }}
                                                      key={permission.id}
                                                    >
                                                      <input
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        type="checkbox"
                                                        checked={
                                                          selectedOptions[
                                                            option.item_category
                                                          ] &&
                                                          selectedOptions[
                                                            option.item_category
                                                          ].includes(
                                                            permission.banquet_menu_name
                                                          )
                                                        }
                                                        onChange={() =>
                                                          handleMenuItemsChange(
                                                            option.category_id,
                                                            option.item_category,
                                                            permission.banquet_menu_name
                                                          )
                                                        }
                                                        className="addstaff_secondCkeckbox"
                                                      />
                                                      <div>
                                                        {
                                                          permission.banquet_menu_name
                                                        }
                                                      </div>
                                                    </label>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    gap: "10px",
                                    marginTop: "10px",
                                  }}
                                ></div>
                              </form>
                            </Collapsible>
                          </LegacyStack>
                        </LegacyCard>
                      </div>

                      <div className="rooms-edit-left-container-buttons">
                        {/* <div>
                          <BgThemeButton
                            disabled={true}
                            style={{ opacity: "0.5", cursor: "no-drop" }}
                            type="submit"
                            children={"Create"}
                          />
                        </div> */}
                        <div>
                          <BgThemeButton type="submit" children={"Create"} />
                        </div>
                        <div style={{ color: "rgba(0, 122, 92, 1)" }}>
                          <Link to="/events" style={{ textDecoration: "none" }}>
                            <BgOutlineThemeButton children={"Discard"} />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="edit-rooms-full-container-right">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                      }}
                    >
                      <div>
                        <LegacyCard sectioned>
                          <LegacyStack vertical>
                            <div
                              onClick={handleToggle3}
                              aria_expanded={open3}
                              aria_controls="basic-collapsible"
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontWeight: "700",
                                fontSize: "14px",
                              }}
                            >
                              <div>Client Info</div>
                              <div>
                                <Icon source={ChevronDownMinor} />
                              </div>
                            </div>
                            <Collapsible
                              open={open3}
                              id="basic-collapsible"
                              transition={{
                                duration: "500ms",
                                timingFunction: "ease-in-out",
                              }}
                              expandOnPrint
                            >
                              <div className="edit-rooms-form-wrapper-container">
                                <div className="form-container-div-half">
                                  <div className="form-container-div">
                                    <VerticalInputFieldCustom
                                      required={true}
                                      type={"text"}
                                      name="client_name"
                                      value={addItemData.client_name}
                                      focused={false}
                                      titleName="Client Name"
                                      onChange={setAddItemDataHandleChange}
                                      autoComplete="off"
                                      // min={1}
                                    />
                                  </div>
                                </div>
                                <div className="form-container-div-half">
                                  <div className="form-container-div">
                                    <VerticalInputFieldCustom
                                      error={
                                        formErrors.client_phone ? true : false
                                      }
                                      required={true}
                                      type={"number"}
                                      name="client_phone"
                                      value={addItemData.client_phone}
                                      focused={false}
                                      titleName="Phone"
                                      onChange={setAddItemDataHandleChange}
                                      autoComplete="off"
                                      // min={1}
                                    />
                                    {formErrors.client_phone && (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                          gap: "5px",
                                          marginTop: "3px",
                                        }}
                                      >
                                        <div>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                          >
                                            <g clipPath="url(#clip0_1_301)">
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM6.3 4.2C6.3 3.8134 6.6134 3.5 7 3.5C7.3866 3.5 7.7 3.8134 7.7 4.2V7C7.7 7.3866 7.3866 7.7 7 7.7C6.6134 7.7 6.3 7.3866 6.3 7V4.2ZM7 10.5C7.3866 10.5 7.7 10.1866 7.7 9.8C7.7 9.4134 7.3866 9.1 7 9.1C6.6134 9.1 6.3 9.4134 6.3 9.8C6.3 10.1866 6.6134 10.5 7 10.5Z"
                                                fill="#E03838"
                                              />
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_1_301">
                                                <rect
                                                  width="14"
                                                  height="14"
                                                  fill="white"
                                                />
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        </div>
                                        <p style={{ color: "red" }}>
                                          {formErrors.client_phone}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                  <div className="form-container-div">
                                    <VerticalInputFieldCustom
                                      error={
                                        formErrors.client_email ? true : false
                                      }
                                      required={true}
                                      type={"email"}
                                      name="client_email"
                                      value={addItemData.client_email}
                                      focused={false}
                                      titleName="Email"
                                      onChange={setAddItemDataHandleChange}
                                      autoComplete="off"
                                    />
                                    {formErrors.client_email && (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "start",
                                          gap: "5px",
                                          marginTop: "3px",
                                        }}
                                      >
                                        <div>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                          >
                                            <g clipPath="url(#clip0_1_301)">
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM6.3 4.2C6.3 3.8134 6.6134 3.5 7 3.5C7.3866 3.5 7.7 3.8134 7.7 4.2V7C7.7 7.3866 7.3866 7.7 7 7.7C6.6134 7.7 6.3 7.3866 6.3 7V4.2ZM7 10.5C7.3866 10.5 7.7 10.1866 7.7 9.8C7.7 9.4134 7.3866 9.1 7 9.1C6.6134 9.1 6.3 9.4134 6.3 9.8C6.3 10.1866 6.6134 10.5 7 10.5Z"
                                                fill="#E03838"
                                              />
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_1_301">
                                                <rect
                                                  width="14"
                                                  height="14"
                                                  fill="white"
                                                />
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        </div>
                                        <p style={{ color: "red" }}>
                                          {formErrors.client_email}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Collapsible>
                          </LegacyStack>
                        </LegacyCard>
                      </div>

                      <div>
                        <LegacyCard sectioned>
                          <LegacyStack vertical>
                            <div
                              onClick={handleToggle8}
                              aria-expanded={open8}
                              aria-controls="basic-collapsible"
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontWeight: "700",
                                fontSize: "14px",
                              }}
                            >
                              <div>Taxes & Amount</div>
                              <div>
                                <Icon source={ChevronDownMinor} />
                              </div>
                            </div>
                            <Collapsible
                              open={open8}
                              id="basic-collapsible"
                              transition={{
                                duration: "500ms",
                                timingFunction: "ease-in-out",
                              }}
                              expandOnPrint
                            >
                              <div className="edit-rooms-form-container">
                                <div className="form-container-div-full">
                                  <select
                                    style={{
                                      width: "100%",
                                      minHeight: "2.25rem",
                                      border:
                                        "1px solid rgba(171, 177, 186, 1)",
                                      borderRadius: "3px",
                                    }}
                                    name="tax_applied"
                                    onChange={setAddItemDataHandleChange}
                                    value={addItemData.tax_applied}
                                  >
                                    <option name="tax_applied" value="">
                                      Select Option
                                    </option>
                                    <option
                                      selected
                                      name="tax_applied"
                                      value="18"
                                    >
                                      18%
                                    </option>
                                    <option name="tax_applied" value="12">
                                      12%
                                    </option>
                                    <option name="tax_applied" value="5">
                                      5%
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="edit-rooms-form-container">
                                <div className="form-container-div-full">
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "8px",
                                      color: "#333",
                                      fontFamily: "Inter",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      name="gst_type"
                                      value="inclusive"
                                      id="inclusive"
                                      onChange={setAddItemDataHandleChange}
                                    />

                                    <label htmlFor="inclusive">Inclusive</label>

                                    <input
                                      type="radio"
                                      name="gst_type"
                                      value="exclusive"
                                      id="exclusive"
                                      onChange={setAddItemDataHandleChange}
                                      // checked
                                    />
                                    <label htmlFor="exclusive">Exclusive</label>
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: "15px",
                                    alignItems: "center",
                                    color: "#333",
                                    flexDirection: "column",
                                    fontSize: "15px",
                                    fontWeight: 400,
                                    gap: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <div>Subtotal</div>
                                    <div>
                                      {" "}
                                      {
                                        <HandleNaN
                                          value={parseFloat(subtotal).toFixed(
                                            2
                                          )}
                                        />
                                      }
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <div>Tax Amount</div>
                                    <div>
                                      <HandleNaN
                                        value={parseFloat(taxAmount).toFixed(2)}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      width: "100%",
                                      fontWeight: 700,
                                    }}
                                  >
                                    <div>Grand Total</div>
                                    <div>
                                      <HandleNaN
                                        value={parseFloat(grandTotal).toFixed(
                                          2
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Collapsible>
                          </LegacyStack>
                        </LegacyCard>
                      </div>

                      {/* <div>
                        <LegacyCard sectioned>
                          <LegacyStack vertical>
                            <div
                              onClick={handleToggle8}
                              aria_expanded={open8}
                              aria_controls="basic-collapsible"
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontWeight: "700",
                                fontSize: "14px",
                              }}
                            >
                              <div>
                                event_slots{" "}
                                <span style={{ color: "red" }}> *</span>
                              </div>
                              <div>
                                <Icon source={ChevronDownMinor} />
                              </div>
                            </div>
                            <Collapsible
                              open={open8}
                              id="basic-collapsible"
                              transition={{
                                duration: "500ms",
                                timingFunction: "ease-in-out",
                              }}
                              expandOnPrint
                            >
                              <div className="edit-rooms-form-container">
                                <div className="form-container-div-full">
                                  <VerticalInputFieldCustom
                                    type="text"
                                    name="event_slots"
                                    value={onkeyPressValue}
                                    focused={false}
                                    onChange={(e) =>
                                      setOnKeyPressValueHandleChange(e)
                                    }
                                    onKeyDown={handleOnKeyDown}
                                    autoComplete="off"
                                  />
                                </div>
                                <LegacyStack spacing="tight">
                                  {tagMarkup}
                                </LegacyStack>
                              </div>
                            </Collapsible>
                          </LegacyStack>
                        </LegacyCard>
                      </div> */}

                      {/* <div>
                        <LegacyCard sectioned>
                          <LegacyStack vertical>
                            <div
                              onClick={handleToggle9}
                              aria-expanded={open9}
                              aria-controls="basic-collapsible"
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontWeight: "700",
                                fontSize: "14px",
                                cursor: "pointer",
                              }}
                            >
                              <div>Address</div>
                              <div>
                                <Icon source={ChevronDownMinor} />
                              </div>
                            </div>
                            <Collapsible
                              open={open9}
                              id="basic-collapsible"
                              transition={{
                                duration: "500ms",
                                timingFunction: "ease-in-out",
                              }}
                              expandOnPrint
                            >
                              <div className="edit-rooms-form-wrapper-container">
                                <div className="edit-rooms-form-container">
                                  <div className="form-container-div-half">
                                    <div className="form-container-div">
                                      <VerticalInputFieldCustom
                                        required={true}
                                        focused={false}
                                        name="banquet_city"
                                        titleName="City"
                                        onChange={setAddItemDataHandleChange}
                                        autoComplete="off"
                                        value={addItemData.banquet_city}
                                      />
                                    </div>
                                    <div className="form-container-div">
                                      <VerticalInputFieldCustom
                                        type="text"
                                        required={true}
                                        focused={false}
                                        name="banquet_state"
                                        titleName="State"
                                        onChange={setAddItemDataHandleChange}
                                        autoComplete="off"
                                        value={addItemData.banquet_state}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-container-div">
                                  <VerticalTextAreaCustom
                                    required={true}
                                    name="banquet_address"
                                    value={addItemData.banquet_address}
                                    focused={false}
                                    titleName="Address"
                                    onChange={setAddItemDataHandleChange}
                                    autoComplete="off"
                                    cols={10}
                                    rows={5}
                                  />
                                </div>
                                <div className="form-container-div"></div>
                              </div>
                            </Collapsible>
                          </LegacyStack>
                        </LegacyCard>
                      </div> */}
                      {/* <div>
                    <LegacyCard sectioned>
                      <LegacyStack vertical>
                        <div
                          onClick={handleToggle9}
                          aria_expanded={open9}
                          aria_controls="basic-collapsible"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          Pricing
                          <div>
                            <Icon source={ChevronDownMinor} />
                          </div>
                        </div>
                        <Collapsible
                          open={open9}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                          <div className="edit-rooms-form-container">
                            <div className="form-container-div-full">
                              <VerticalInputFieldCustom
                                required={true}
                                type={"number"}
                                name="rack_price"
                                value={addItemData.rack_price}
                                focused={false}
                                titleName="Rack Price"
                                onChange={setAddItemDataHandleChange}
                                autoComplete="off"
                                min={1}
                              />
                            </div>
                            <div className="form-container-div-full">
                              <VerticalInputFieldCustom
                                required={true}
                                name="extra_adults"
                                value={addItemData.extra_adults}
                                focused={false}
                                titleName="Extra Adults"
                                onChange={setAddItemDataHandleChange}
                                autoComplete="off"
                                min={1}
                              />
                            </div>
                          </div>
                          <div className="form-container-div-half">
                            <div className="form-container-div">
                              <VerticalInputFieldCustom
                                required={true}
                                name="child_with_Bed"
                                value={addItemData.child_with_Bed}
                                focused={false}
                                titleName="Child With Bed"
                                onChange={setAddItemDataHandleChange}
                                autoComplete="off"
                                min={1}
                              />
                            </div>
                            <div className="form-container-div">
                              <VerticalInputFieldCustom
                                required={true}
                                name="child_without_Bed"
                                value={addItemData.child_without_Bed}
                                focused={false}
                                titleName="Child Without Bed"
                                onChange={setAddItemDataHandleChange}
                                autoComplete="off"
                                min={1}
                              />
                            </div>
                          </div>
                        </Collapsible>
                      </LegacyStack>
                    </LegacyCard>
                  </div> */}
                    </div>
                  </div>
                </div>

                {/* Add Slot Click */}
                {showAddSlot && (
                  <>
                    <div className="bg-backdrop">
                      <form
                        onSubmit={(e) => e.preventDefault()}
                        className="stock-add-model-container"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "15px",
                          }}
                        >
                          <div className="add-ota-model-container-heading">
                            Add Slots
                          </div>
                          <div
                            onClick={() => setShowAddSlot(false)}
                            style={{ cursor: "pointer" }}
                          >
                            <Icon source={CancelMajor} color="base" />
                          </div>
                        </div>

                        <div>{renderSchedule()}</div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            gap: "10px",
                            marginTop: "10px",
                          }}
                        >
                          <BgThemeButton
                            // onClick={(e) => handleSubmitAddRooms(e)}
                            type="submit"
                            children={"Add"}
                          />
                          <Button onClick={() => setShowAddSlot(false)}>
                            Cancel
                          </Button>
                        </div>
                      </form>
                    </div>
                  </>
                )}
              </Page>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default AddEvent;
