import React, { useState } from "react";
import styles from "./AccordionComponent.module.scss";

const AccordionComponent = ({ questions ,accordionStyle="onlyLinks" }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={accordionStyle ==="onlyLinks" ? styles.accordionContainerOnlyLinks : accordionStyle === "separate" ? styles.accordionContainerSeparate : styles.accordionContainer   }>
      {questions.map((item, index) => (
        <div key={index} className={styles.accordionItem} style={accordionStyle ==="onlyLinks" & activeIndex === index ? {borderBottom:"1px solid #e0e0e0"} : {}}>
          <div
            className={styles.accordionHeader}
            onClick={() => toggleAccordion(index)}
          >
            <h3>{index+1}.   {item.question}</h3>

            <svg
              width="12"
              height="7"
              viewBox="0 0 12 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`${styles.icon} ${
                activeIndex === index ? styles.rotate : ""
              }`}
            >
              <path
                d="M6.05334 6.66666C5.86356 6.66666 5.66803 6.59189 5.52426 6.44812L0.520938 1.45055C0.22764 1.15726 0.22764 0.679928 0.520938 0.38663C0.814236 0.0933319 1.29156 0.0933318 1.58486 0.38663L6.05334 4.85511L10.5218 0.386628C10.8151 0.0933302 11.2867 0.0933301 11.58 0.386628C11.8733 0.679926 11.8733 1.15725 11.58 1.4448L6.57668 6.44812C6.4329 6.59189 6.23737 6.66666 6.04759 6.66666L6.05334 6.66666Z"
                fill="#858585"
              />
            </svg>
          </div>
          <div
            className={`${styles.accordionContent} ${
              activeIndex === index ? styles.open : ""
            }`}
          >
            <p>{item.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AccordionComponent;
