import {
  TextField,
  IndexTable,
  LegacyCard,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Badge,
  Avatar,
  Button,
  Icon,
  Select,
  Checkbox,
  Page,
  LegacyStack,
  Collapsible,
} from "@shopify/polaris";

import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import logo from "../assets/images/room_type.png";
import { Link, useNavigate } from "react-router-dom";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  WithoutBgButtonBlue,
} from "./UI/Buttons";

import { ChevronDownMinor, NoteMinor } from "@shopify/polaris-icons";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import { useSelector } from "react-redux";
import NoDataFound from "./CustomComponents/NoDataFound";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import AddMenuCategory from "./UI/AddMenuCategory";
import Loader from "./UI/Loader/Loader";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import { getDecryptedData } from "../utils/encryptStorage";

const FbMenuNew = () => {
  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);
  const role = JSON.parse(localStorage.getItem("role"));
  const [loader, setLoader] = useState(false);
  const [orders, setOrders] = useState([]);
  const [menuCategories, setMenuCategories] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedMenuItems, setSelectedMenuItems] = useState(null);

  const navigate = useNavigate();

  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showMenuCategory, setShowMenuCategory] = useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddStockModelMenuActive, setShowAddStockModelMenuActive] =
    useState(false);

  const [showAddItemModelMenuActive, setShowAddItemModelMenuActive] =
    useState(false);

  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);

  const [menuItems, setMenuItems] = useState({
    property_id: property_id?.id,
    item_name: "",
    item_category: "",
    item_price: "",
    short_code: "",
    tax_rate: "",
    item_type: "",
  });

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [itemStrings, setItemStrings] = useState([
    "Past 7 Days",
    "Past 30 Days",
    "Past 90 Days",
    "Select Date Range",
  ]);

  // const handleChange = useCallback((newValue) => setValue(newValue), []);

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(true);
  const [open4, setOpen4] = useState(true);
  const [open5, setOpen5] = useState(true);
  const [open6, setOpen6] = useState(true);
  const [open7, setOpen7] = useState(true);
  const [open8, setOpen8] = useState(true);
  const [open9, setOpen9] = useState(true);
  const [open10, setOpen10] = useState(true);
  const [open11, setOpen11] = useState(true);
  const [open12, setOpen12] = useState(true);
  const [open13, setOpen13] = useState(true);
  const [open14, setOpen14] = useState(true);
  const [open15, setOpen15] = useState(true);

  const handleToggle1 = useCallback(() => setOpen1((open1) => !open1), []);
  const handleToggle2 = useCallback(() => setOpen2((open2) => !open2), []);
  const handleToggle3 = useCallback(() => setOpen3((open3) => !open3), []);
  const handleToggle4 = useCallback(() => setOpen4((open4) => !open4), []);
  const handleToggle5 = useCallback(() => setOpen5((open5) => !open5), []);
  const handleToggle6 = useCallback(() => setOpen6((open6) => !open6), []);
  const handleToggle7 = useCallback(() => setOpen7((open7) => !open7), []);
  const handleToggle8 = useCallback(() => setOpen8((open8) => !open8), []);
  const handleToggle9 = useCallback(() => setOpen9((open9) => !open9), []);
  const handleToggle10 = useCallback(() => setOpen10((open10) => !open10), []);
  const handleToggle11 = useCallback(() => setOpen11((open11) => !open11), []);
  const handleToggle12 = useCallback(() => setOpen12((open12) => !open12), []);
  const handleToggle13 = useCallback(() => setOpen13((open13) => !open13), []);
  const handleToggle14 = useCallback(() => setOpen14((open14) => !open14), []);
  const handleToggle15 = useCallback(() => setOpen15((open15) => !open15), []);

  const [selected, setSelected] = useState(0);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(orders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      // getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const [openAccordions, setOpenAccordions] = useState(Array(9).fill(false));

  const handleToggle = (index) => {
    const updatedAccordions = [...openAccordions];
    updatedAccordions[index] = !updatedAccordions[index];
    setOpenAccordions(updatedAccordions);
  };

  const addModalButtonClickHandler = () => {
    setShowAddStockModelMenuActive(!showAddStockModelMenuActive);
  };

  const editModalButtonClickHandler = () => {
    setShowEditModelMenuActive(!showEditModelMenuActive);
  };

  const removeAddModalContainer = () => {
    setShowAddStockModelMenuActive(!showAddStockModelMenuActive);
  };

  const getItemChecked = (id) => {};

  getItemChecked(selectedResources[0]);

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const [deletedSingleIds, setDeletedSingleIds] = useState([]);
  const [editSingleIds, setEditdSingleIds] = useState([]);

  const removeItemConfirm = async () => {
    const deletedIds = {
      ids: deletedSingleIds,
    };
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/menus/delete/${property_id?.id}`,
        {
          method: "DELETE",
          body: JSON.stringify(deletedIds),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        showSuccessToast(data.message);
        clearSelection();
        setShowModelMenuActive(false);
        setLoader(false);
      } else {
        showErrorToast(data.message);
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
      setLoader(false);
    }
  };

  const setItemsDataHandleChange = (event) => {
    setMenuItems({
      ...menuItems,
      [event.target.name]: event.target.value,
    });
  };

  const fetchInfo = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/menus/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data.menu);
      const firstCategory = data.menu[0]?.item_category;
      const firstMenuItems = data.menu[0]?.menu_items;
      setSelectedCategory(firstCategory);
      setSelectedMenuItems(firstMenuItems);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const handleSubmitAddItem = async () => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/create/menus/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({ ...menuItems }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        setShowAddItemModelMenuActive(false);
        // clearSelection();
        showSuccessToast(data.message);
        setMenuItems({
          item_name: "",
          item_category: "",
          item_price: "",
        });
        fetchInfo(property_id?.id);
        setLoader(false);
        // window.location.reload()
      } else {
        showErrorToast(data.message);
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
      setLoader(false);
    }
  };

  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/menus/edit/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        property_id: property_id?.id,
        item_name: data.menu.item_name,
        item_category: data.menu.item_category,
        item_price: data.menu.item_price,
        tax_rate: data.menu.tax_rate,
        short_code: data.menu.short_code,
        item_type: data.menu.item_type,
      };
      setMenuItems(updatedData);
      setShowEditModelMenuActive(true);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const taxArrayOptions = [
    {
      label: "Select Option",
      value: "",
    },
    {
      label: "5",
      value: "5",
    },
    {
      label: "12",
      value: "12",
    },
    {
      label: "18",
      value: "18",
    },
  ];

  const handleUpdateItem = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/menus/update/${editSingleIds}/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({ ...menuItems }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        setShowEditModelMenuActive(false);
        clearSelection();
        showSuccessToast(data.message);
        setMenuItems({
          property_id: property_id?.id,
          item_name: "",
          item_category: "",
          item_price: "",
        });
        setLoader(false);
        // window.location.reload()
      } else {
        showErrorToast(data.message);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchMenuCategories = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/menus/categories/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setMenuCategories(data.menue_categories);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
      fetchMenuCategories(property_id?.id);
    }
  }, [property_id]);

  const menuItemsOptions = [
    {
      label: "Select Option",
      value: "",
    },
    {
      label: "Drink",
      value: "drink",
    },
    {
      label: "Vegetarian",
      value: "veg",
    },
    {
      label: "Non-Vegetarian",
      value: "non_veg",
    },
    {
      label: "Eggetarian",
      value: "eggetarian",
    },
  ];

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {role === "owner" && (
            <div
              style={{
                margin: "2px",
                display: "flex",
                gap: "10px",
                background: "white",
                padding: "10px",
                marginBottom: "30px",
              }}
            >
              <BgThemeButton
                icon={
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 2.825C2 2.36937 2.36937 2 2.825 2H12.175C12.6306 2 13 2.36937 13 2.825V6.125C13 6.58064 12.6306 6.95 12.175 6.95H8.93611C8.48048 6.95 8.11111 6.58064 8.11111 6.125V4.81111C8.11111 4.4736 7.83751 4.2 7.5 4.2C7.16249 4.2 6.88889 4.4736 6.88889 4.81111V6.125C6.88889 6.58064 6.51952 6.95 6.06389 6.95H2.825C2.36937 6.95 2 6.58064 2 6.125V2.825Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clipRule="evenodd"
                      d="M5.85 4.75C5.85 3.83873 6.58873 3.1 7.5 3.1C8.41127 3.1 9.15 3.83873 9.15 4.75V7.16117C9.15 7.28262 9.22966 7.38968 9.34598 7.42458L10.828 7.86918C11.4796 8.06465 11.8903 8.70721 11.7941 9.38064L11.4457 11.8195C11.3489 12.4968 10.7688 13 10.0845 13H5.82786C5.29154 13 4.80417 12.6882 4.57942 12.2012L3.44634 9.74621C3.3065 9.44321 3.27865 9.08528 3.38768 8.76863C3.50265 8.43474 3.78294 8.13136 4.22126 8.06874C4.79052 7.9874 5.33019 8.174 5.85 8.51934V4.75ZM7.5 4.2C7.19624 4.2 6.95 4.44624 6.95 4.75V9.03609C6.95 9.80061 6.06524 10.0928 5.56023 9.67465C5.03915 9.24315 4.68535 9.13147 4.42226 9.15262C4.42099 9.16334 4.42042 9.17647 4.42141 9.1918C4.42342 9.22306 4.43147 9.25571 4.4451 9.28525L5.57817 11.7402C5.62312 11.8376 5.7206 11.9 5.82786 11.9H10.0845C10.2213 11.9 10.3374 11.7994 10.3567 11.6639L10.7051 9.22508C10.7244 9.09039 10.6422 8.96188 10.5119 8.92279L9.0299 8.47819C8.44829 8.30371 8.05 7.76839 8.05 7.16117V4.75C8.05 4.44624 7.80376 4.2 7.5 4.2Z"
                      fill="white"
                    />
                  </svg>
                }
                onClick={() => navigate("/kot/create-kot-manager")}
                children={"KOT"}
                style={{ height: "30px" }}
              />

              <BgThemeButton
                icon={
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clipRule="evenodd"
                      d="M12.9115 7.27807C11.638 4.08549 9.18887 2.63066 6.74082 2.75763C4.32096 2.88315 2.0378 4.55138 1.07 7.30112L1 7.5L1.07 7.69888C2.0378 10.4486 4.32096 12.1169 6.74082 12.2424C9.18887 12.3693 11.638 10.9145 12.9115 7.72193L13 7.5L12.9115 7.27807ZM6.99511 10.4878C8.64066 10.4878 9.97463 9.15013 9.97463 7.5C9.97463 5.84988 8.64066 4.51218 6.99511 4.51218C5.34957 4.51218 4.0156 5.84988 4.0156 7.5C4.0156 9.15013 5.34957 10.4878 6.99511 10.4878Z"
                      fill="white"
                    />
                    <path
                      d="M8.78282 7.5C8.78282 8.49008 7.98244 9.29269 6.99511 9.29269C6.00779 9.29269 5.2074 8.49008 5.2074 7.5C5.2074 6.50993 6.00779 5.70731 6.99511 5.70731C7.98244 5.70731 8.78282 6.50993 8.78282 7.5Z"
                      fill="white"
                    />
                  </svg>
                }
                onClick={() => navigate("/kot-room-table-view")}
                children={"Table/Room"}
                style={{ height: "30px" }}
              />
              <BgOutlineThemeButton
                icon={
                  <svg
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clipRule="evenodd"
                      d="M0.699219 1.24995C0.699219 0.670052 1.16932 0.199951 1.74922 0.199951H12.2492C12.8291 0.199951 13.2992 0.670052 13.2992 1.24995V11.75C13.2992 12.3299 12.8291 12.8 12.2492 12.8H1.74922C1.16932 12.8 0.699219 12.3299 0.699219 11.75V1.24995ZM5.59922 2.99995H11.1992V4.39995H5.59922V2.99995ZM11.1992 5.79995H5.59922V7.19995H11.1992V5.79995ZM5.59922 8.59995H11.1992V9.99995H5.59922V8.59995ZM3.49922 4.39995C3.88582 4.39995 4.19922 4.08655 4.19922 3.69995C4.19922 3.31335 3.88582 2.99995 3.49922 2.99995C3.11262 2.99995 2.79922 3.31335 2.79922 3.69995C2.79922 4.08655 3.11262 4.39995 3.49922 4.39995ZM4.19922 6.49995C4.19922 6.88655 3.88582 7.19995 3.49922 7.19995C3.11262 7.19995 2.79922 6.88655 2.79922 6.49995C2.79922 6.11335 3.11262 5.79995 3.49922 5.79995C3.88582 5.79995 4.19922 6.11335 4.19922 6.49995ZM3.49922 9.99995C3.88582 9.99995 4.19922 9.68655 4.19922 9.29995C4.19922 8.91335 3.88582 8.59995 3.49922 8.59995C3.11262 8.59995 2.79922 8.91335 2.79922 9.29995C2.79922 9.68655 3.11262 9.99995 3.49922 9.99995Z"
                      fill="#3968ED"
                    />
                  </svg>
                }
                onClick={() => navigate("/hotelier/menus")}
                children={"Menu"}
                style={{ height: "30px" }}
              />
            </div>
          )}
          <div className="room-types-full-container-div">
            <div className="room-type-heading">
              <div style={{ marginBottom: "2rem" }}>Food Menu</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <div>
                {selectedResources.length > 0 && (
                  <div>
                    <Button plain destructive onClick={showModelMenu}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Icon source={DeleteMajor} color="base" />
                        </span>
                        <span>Remove</span>
                      </div>
                    </Button>
                  </div>
                )}
              </div>
              <div>
                <BgOutlineThemeButton
                  children={"Categories"}
                  onClick={() => setShowMenuCategory(!showMenuCategory)}
                />
              </div>
              <div style={{ textDecoration: "none" }}>
                <BgThemeButton
                  onClick={() =>
                    setShowAddItemModelMenuActive(!showAddItemModelMenuActive)
                  }
                  children={"Add Menu"}
                />
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "20px", width: "100%" }}>
            <div
              style={{
                width: "10%",
                backgroundColor: "white",
                borderRadius: "10px",
                // minHeight: "200px",
                height: "fit-content",
                border: "1px solid rgba(204, 204, 204, 1)",
                position: "sticky",
                top: "100px",
              }}
            >
              {orders && orders.length > 0 ? (
                <>
                  {orders.map((menu) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedCategory(menu.item_category);
                          setSelectedMenuItems(menu.menu_items);
                        }}
                        key={menu.item_category}
                        style={{
                          color:
                            selectedCategory === menu.item_category
                              ? "rgba(57, 104, 237, 1)"
                              : "black",
                          borderBottom: "0.5px solid rgba(204, 204, 204, 1)",
                          width: "100%",
                          cursor: "pointer",
                        }}
                      >
                        <div style={{ fontSize: "12px", padding: "5px 20px" }}>
                          {menu.item_category}
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div>No orders found</div>
              )}
            </div>
            <div style={{ width: "90%" }}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "20px",
                  flexDirection: "column",
                }}
              >
                <div className="banquet_menu_main">
                  {selectedMenuItems && selectedMenuItems?.length > 0 ? (
                    <IndexTable
                      selectable={false}
                      resourceName={resourceName}
                      itemCount={selectedMenuItems?.length}
                      selectedItemsCount={
                        allResourcesSelected ? "All" : selectedResources.length
                      }
                      onSelectionChange={handleSelectionChange}
                      headings={[
                        { title: "Item Name" },
                        { title: "Item Category" },
                        { title: "Item Price" },
                        { title: "Tax Rate" },
                        { title: "HSN Code" },
                        { title: "" },
                      ]}
                    >
                      {selectedMenuItems.map(
                        ({
                          id,
                          item_name,
                          item_category,
                          item_price,
                          item_category_name,
                          tax_rate,
                          short_code,
                          index,
                          item_type,
                        }) => {
                          let svg = (
                            <svg
                              width="8"
                              height="8"
                              viewBox="0 0 8 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="4" cy="4" r="4" fill="#15AA12" />
                            </svg>
                          );
                          switch (item_type) {
                            case "drink":
                              svg = (
                                <svg
                                  width="8"
                                  height="8"
                                  viewBox="0 0 8 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle cx="4" cy="4" r="4" fill="#15AA12" />
                                </svg>
                              );
                              break;
                            case "veg":
                              svg = (
                                <svg
                                  width="8"
                                  height="8"
                                  viewBox="0 0 8 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle cx="4" cy="4" r="4" fill="#15AA12" />
                                </svg>
                              );
                              break;
                            case "non_veg":
                              svg = (
                                <svg
                                  width="8"
                                  height="8"
                                  viewBox="0 0 8 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle cx="4" cy="4" r="4" fill="#E03838" />
                                </svg>
                              );
                              break;
                            case "eggetarian":
                              svg = (
                                <svg
                                  width="8"
                                  height="8"
                                  viewBox="0 0 8 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle cx="4" cy="4" r="4" fill="#BD941B" />
                                </svg>
                              );
                              break;
                            default:
                              svg = (
                                <svg
                                  width="8"
                                  height="8"
                                  viewBox="0 0 8 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle cx="4" cy="4" r="4" fill="#15AA12" />
                                </svg>
                              );
                          }

                          return (
                            <IndexTable.Row
                              id={id}
                              key={id}
                              selected={selectedResources.includes(id)}
                              position={index}
                            >
                              <IndexTable.Cell>
                                {" "}
                                <span style={{ marginRight: "5px" }}>
                                  {svg}
                                </span>{" "}
                                {item_name}
                              </IndexTable.Cell>
                              <IndexTable.Cell>
                                {item_category_name}
                              </IndexTable.Cell>
                              <IndexTable.Cell>{item_price}</IndexTable.Cell>
                              <IndexTable.Cell>
                                {tax_rate ? tax_rate : 0}%
                              </IndexTable.Cell>
                              <IndexTable.Cell>
                                {short_code ? short_code : "N/A"}
                              </IndexTable.Cell>
                              <IndexTable.Cell>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    gap: "30px",
                                  }}
                                >
                                  <div
                                    onClick={() => [
                                      getOneData(id),
                                      setEditdSingleIds([id]),
                                    ]}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                    >
                                      <path
                                        d="M11.3919 1.98174L14.0182 4.60884L14.4562 4.1707C15.1813 3.44536 15.1813 2.26952 14.4562 1.54418C13.7308 0.818608 12.5547 0.818608 11.8294 1.54418L11.3919 1.98174Z"
                                        fill="#3968ED"
                                      />
                                      <path
                                        d="M12.8124 5.81229L10.1861 3.18518L1.85249 11.5201L1 15L4.47873 14.1472L12.8124 5.81229Z"
                                        fill="#3968ED"
                                      />
                                    </svg>
                                  </div>
                                  <div
                                    onClick={() => [
                                      showModelMenu(),
                                      setDeletedSingleIds([id]),
                                    ]}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M11.2001 3.2H13.6001C14.0419 3.2 14.4001 3.55817 14.4001 4L14.4001 4.8H1.6001L1.60012 4C1.60012 3.55817 1.95829 3.2 2.40012 3.2H4.80012V1.2C4.80012 0.537258 5.33738 0 6.00012 0H10.0001C10.6629 0 11.2001 0.537259 11.2001 1.2V3.2ZM6.40012 1.6V3.2H9.60012V1.6H6.40012Z"
                                        fill="#E03838"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M2.40012 6.4H13.6001V14.8C13.6001 15.4627 13.0629 16 12.4001 16H3.60012C2.93738 16 2.40012 15.4627 2.40012 14.8V6.4ZM5.6001 8.8H4.0001V13.6H5.6001V8.8ZM8.8001 8.8H7.2001V13.6H8.8001V8.8ZM10.4001 8.8H12.0001V13.6H10.4001V8.8Z"
                                        fill="#E03838"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </IndexTable.Cell>
                            </IndexTable.Row>
                          );
                        }
                      )}
                    </IndexTable>
                  ) : (
                    <NoDataFound />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Add Item Button Click */}
      {showAddItemModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <form onSubmit={(e) => e.preventDefault()}>
              <section
                style={{ width: "700px" }}
                className="stock-add-model-container"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <div className="add-ota-model-container-heading">
                    Add Menu
                  </div>
                  <div
                    onClick={() => setShowAddItemModelMenuActive(false)}
                    style={{ cursor: "pointer" }}
                  >
                    <Icon source={CancelMajor} color="base" />
                  </div>
                </div>

                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      type={"text"}
                      name={"item_name"}
                      className="textfield"
                      focused={false}
                      titleName="Item Name"
                      onChange={setItemsDataHandleChange}
                      value={menuItems.item_name}
                    />
                  </div>
                  <div className="form-container-div">
                    <VerticalInputSelectCustomCopy
                      options={taxArrayOptions}
                      required={true}
                      type={"text"}
                      name={"tax_rate"}
                      className="textfield"
                      focused={false}
                      titleName="Tax Rate"
                      onChange={setItemsDataHandleChange}
                      value={menuItems.tax_rate}
                    />
                  </div>
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      options={taxArrayOptions}
                      // required={true}
                      type={"text"}
                      name={"short_code"}
                      className="textfield"
                      focused={false}
                      titleName="HSN Code"
                      onChange={setItemsDataHandleChange}
                      value={menuItems.short_code}
                    />
                  </div>
                </div>

                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <div style={{ marginBottom: "4px" }}>
                      Item Category
                      <span style={{ color: "red" }}>*</span>
                    </div>
                    <select
                      style={{
                        width: "100%",
                        minHeight: "2.25rem",
                        border: "1px solid rgba(171, 177, 186, 1)",
                        borderRadius: "3px",
                      }}
                      required="true"
                      name="item_category"
                      onChange={setItemsDataHandleChange}
                      value={menuItems.item_category}
                    >
                      <option value="">Select Option</option>
                      {menuCategories?.map((x) => {
                        return (
                          <>
                            <option key={x.id} value={x.id}>
                              {x.category_name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  <div style={{ width: "70%" }} className="form-container-div">
                    <VerticalInputSelectCustomCopy
                      options={menuItemsOptions}
                      required={true}
                      name={"item_type"}
                      className="textfield"
                      focused={false}
                      titleName="Item Type"
                      onChange={setItemsDataHandleChange}
                      value={menuItems.item_type}
                    />
                  </div>
                  <div style={{ width: "30%" }} className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      type={"number"}
                      name={"item_price"}
                      className="textfield"
                      focused={false}
                      titleName="Item Price"
                      onChange={setItemsDataHandleChange}
                      value={menuItems.item_price}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      gap: "10px",
                    }}
                  >
                    <BgThemeButton
                      children={"Add"}
                      loader={loader}
                      onClick={(e) => handleSubmitAddItem(e)}
                    />
                    <Button
                      onClick={() => setShowAddItemModelMenuActive(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </section>
            </form>
          </div>
        </>
      )}

      {/* Edit Button Click */}
      {showEditModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section
              style={{ width: "700px" }}
              className="stock-add-model-container"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <div className="add-ota-model-container-heading">Edit Menu</div>
                <div
                  onClick={() => {
                    setShowEditModelMenuActive(false);
                    clearSelection();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Icon source={CancelMajor} color="base" />
                </div>
              </div>

              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    type={"text"}
                    name={"item_name"}
                    className="textfield"
                    focused={false}
                    titleName="Item Name"
                    onChange={setItemsDataHandleChange}
                    value={menuItems.item_name}
                  />
                </div>
                <div className="form-container-div">
                  <VerticalInputSelectCustomCopy
                    options={taxArrayOptions}
                    required={true}
                    type={"text"}
                    name={"tax_rate"}
                    className="textfield"
                    focused={false}
                    titleName="Tax Rate"
                    onChange={setItemsDataHandleChange}
                    value={menuItems.tax_rate}
                  />
                </div>
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    options={taxArrayOptions}
                    // required={true}
                    type={"text"}
                    name={"short_code"}
                    className="textfield"
                    focused={false}
                    titleName="HSN Code"
                    onChange={setItemsDataHandleChange}
                    value={menuItems.short_code}
                  />
                </div>
              </div>

              <div className="form-container-div-half">
                <div className="form-container-div">
                  <div style={{ marginBottom: "4px" }}>
                    Item Category
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <select
                    style={{
                      width: "100%",
                      minHeight: "2.25rem",
                      border: "1px solid rgba(171, 177, 186, 1)",
                      borderRadius: "3px",
                    }}
                    required="true"
                    name="item_category"
                    onChange={setItemsDataHandleChange}
                    value={menuItems.item_category}
                  >
                    <option value="">Select Option</option>
                    {menuCategories?.map((x) => {
                      return (
                        <>
                          <option key={x.id} value={x.id}>
                            {x.category_name}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div style={{ width: "70%" }} className="form-container-div">
                  <VerticalInputSelectCustomCopy
                    options={menuItemsOptions}
                    required={true}
                    name={"item_type"}
                    className="textfield"
                    focused={false}
                    titleName="Item Type"
                    onChange={setItemsDataHandleChange}
                    value={menuItems.item_type}
                  />
                </div>
                <div style={{ width: "30%" }} className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    type={"number"}
                    name={"item_price"}
                    className="textfield"
                    focused={false}
                    titleName="Item Price"
                    onChange={setItemsDataHandleChange}
                    value={menuItems.item_price}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    gap: "10px",
                  }}
                >
                  <BgThemeButton
                    children={"Update"}
                    onClick={() => handleUpdateItem(selectedResources[0])}
                  />
                  <Button
                    onClick={() => {
                      setShowEditModelMenuActive(false);
                      clearSelection();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </section>
          </div>
        </>
      )}

      {showModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="room-type-model-container ">
              <h4>Are You Sure?</h4>
              <p>You Want To Remove?</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={() => {
                    setShowModelMenuActive(!showModelMenuActive);
                    clearSelection();
                  }}
                >
                  Discard
                </Button>
                <Button onClick={removeItemConfirm} destructive>
                  Yes, Remove
                </Button>
              </div>
            </section>
          </div>
        </>
      )}

      {showMenuCategory && (
        <AddMenuCategory setShowMenuCategory={setShowMenuCategory} />
      )}
    </>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "moneySpent":
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case "taggedWith":
        return `Tagged with ${value}`;
      case "accountStatus":
        return value.map((val) => `Customer ${val}`).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};
export default FbMenuNew;
