import React from "react";  
import styles from "./SpinnerLoader.module.scss";

const SpinnerLoader = ({ size }) => {
  let height;
  let width;

  switch (size) {
    case "small":
      height = "25px";
      width = "25px";

      break;

    case "medium":
      height = "50px";
      width = "50px";

      break;

    case "large":
      height = "75px";
      width = "75px";

    default:
      height = "75px";
      width = "75px";
      break;
  }

  return (
    <div
      className={styles.spinner}
      style={{
        width: width,
        height: height,
        borderWidth:
          size === "small"
            ? "3px"
            : size === "medium"
            ? "5px"
            : size === "large"
            ? "7px"
            : "7px",
      }}
    ></div>
  );
};

export default SpinnerLoader;
