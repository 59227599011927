import React from "react";
import "./CustomPagination.css";

const CustomPagination = ({
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
  clearSelection,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageClick = (pageNumber) => {
    clearSelection();
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      onPageChange(pageNumber);
    }
  };

  const handlePrevClick = () => {
    clearSelection();
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    clearSelection();
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 3;

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const firstPage = Math.max(1, currentPage - 1);
      const lastPage = Math.min(totalPages, firstPage + maxPagesToShow - 1);

      if (firstPage > 1) {
        pageNumbers.push(1);
        if (firstPage > 2) {
          pageNumbers.push("..."); 
        }
      }

      for (let i = firstPage; i <= lastPage; i++) {
        pageNumbers.push(i);
      }

      if (lastPage < totalPages) {
        if (lastPage < totalPages - 1) {
          pageNumbers.push("...");
        }
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers.map((page, index) => (
      <button
        key={index}
        onClick={() => handlePageClick(page)}
        disabled={page === currentPage}
        className={
          page === currentPage
            ? "custom_items_pagination_item pagination_item_active"
            : "custom_items_pagination_item"
        }
      >
        {page}
      </button>
    ));
  };

  if (totalItems <= itemsPerPage) {
    return null;
  }

  return (
    <div className="custom_items_pagination_main">
      <div className="custom_items_pagination">
        <button
          onClick={handlePrevClick}
          disabled={currentPage === 1}
          className="custom_items_pagination_prev_btn"
        >
          &lt;
        </button>

        {renderPageNumbers()}

        <button
          onClick={handleNextClick}
          disabled={currentPage === totalPages}
          className="custom_items_pagination_next_btn"
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default CustomPagination;
