import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getDecryptedData } from "../../utils/encryptStorage";


const admin_menus = [
  {
    name: "Dashboard",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="20"
        viewBox="0 0 22 20"
      >
        <path d="M3.63614 10.8333H9.09069C9.33179 10.8333 9.56302 10.7455 9.73351 10.5893C9.904 10.433 9.99978 10.221 9.99978 10V3.33333C9.99978 3.11232 9.904 2.90036 9.73351 2.74408C9.56302 2.5878 9.33179 2.5 9.09069 2.5H3.63614C3.39504 2.5 3.1638 2.5878 2.99332 2.74408C2.82283 2.90036 2.72705 3.11232 2.72705 3.33333V10C2.72705 10.221 2.82283 10.433 2.99332 10.5893C3.1638 10.7455 3.39504 10.8333 3.63614 10.8333ZM2.72705 16.6667C2.72705 16.8877 2.82283 17.0996 2.99332 17.2559C3.1638 17.4122 3.39504 17.5 3.63614 17.5H9.09069C9.33179 17.5 9.56302 17.4122 9.73351 17.2559C9.904 17.0996 9.99978 16.8877 9.99978 16.6667V13.3333C9.99978 13.1123 9.904 12.9004 9.73351 12.7441C9.56302 12.5878 9.33179 12.5 9.09069 12.5H3.63614C3.39504 12.5 3.1638 12.5878 2.99332 12.7441C2.82283 12.9004 2.72705 13.1123 2.72705 13.3333V16.6667ZM11.818 16.6667C11.818 16.8877 11.9137 17.0996 12.0842 17.2559C12.2547 17.4122 12.4859 17.5 12.7271 17.5H18.1816C18.4227 17.5 18.6539 17.4122 18.8244 17.2559C18.9949 17.0996 19.0907 16.8877 19.0907 16.6667V10.8333C19.0907 10.6123 18.9949 10.4004 18.8244 10.2441C18.6539 10.0878 18.4227 10 18.1816 10H12.7271C12.4859 10 12.2547 10.0878 12.0842 10.2441C11.9137 10.4004 11.818 10.6123 11.818 10.8333V16.6667ZM12.7271 8.33333H18.1816C18.4227 8.33333 18.6539 8.24554 18.8244 8.08926C18.9949 7.93297 19.0907 7.72101 19.0907 7.5V3.33333C19.0907 3.11232 18.9949 2.90036 18.8244 2.74408C18.6539 2.5878 18.4227 2.5 18.1816 2.5H12.7271C12.4859 2.5 12.2547 2.5878 12.0842 2.74408C11.9137 2.90036 11.818 3.11232 11.818 3.33333V7.5C11.818 7.72101 11.9137 7.93297 12.0842 8.08926C12.2547 8.24554 12.4859 8.33333 12.7271 8.33333Z" />
      </svg>
    ),
    id: 1,
    link: "/channel-manager",
  },
  {
    id: 2,
    name: "Inventory",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="#666"
      >
        <mask
          id="mask0_8453_8641"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="21"
          height="20"
        >
          <rect x="0.5" width="20" height="20" fill="#fff" />
        </mask>
        <g mask="url(#mask0_8453_8641)">
          <path d="M6.74996 12.9167H14.25V13.5417C14.25 13.7222 14.309 13.8715 14.427 13.9896C14.5451 14.1077 14.6944 14.1667 14.875 14.1667C15.0555 14.1667 15.2048 14.1077 15.3229 13.9896C15.4409 13.8715 15.5 13.7222 15.5 13.5417V10.7084C15.5 10.2917 15.3854 9.92016 15.1562 9.59377C14.927 9.26738 14.625 9.0278 14.25 8.87502V7.50002C14.25 7.04169 14.0868 6.64933 13.7604 6.32294C13.434 5.99655 13.0416 5.83335 12.5833 5.83335H8.41663C7.95829 5.83335 7.56593 5.99655 7.23954 6.32294C6.91315 6.64933 6.74996 7.04169 6.74996 7.50002V8.87502C6.37496 9.0278 6.07288 9.26738 5.84371 9.59377C5.61454 9.92016 5.49996 10.2917 5.49996 10.7084V13.5417C5.49996 13.7222 5.55899 13.8715 5.67704 13.9896C5.7951 14.1077 5.9444 14.1667 6.12496 14.1667C6.30551 14.1667 6.45482 14.1077 6.57288 13.9896C6.69093 13.8715 6.74996 13.7222 6.74996 13.5417V12.9167ZM6.74996 11.6667V10.7084C6.74996 10.5139 6.8194 10.3472 6.95829 10.2084C7.09718 10.0695 7.26385 10 7.45829 10H13.5416C13.7361 10 13.9027 10.0695 14.0416 10.2084C14.1805 10.3472 14.25 10.5139 14.25 10.7084V11.6667H6.74996ZM7.99996 8.75002V7.08335H13V8.75002H7.99996ZM3.83329 18.3334C3.37496 18.3334 2.9826 18.1702 2.65621 17.8438C2.32982 17.5174 2.16663 17.125 2.16663 16.6667V3.33335C2.16663 2.87502 2.32982 2.48266 2.65621 2.15627C2.9826 1.82988 3.37496 1.66669 3.83329 1.66669H17.1666C17.625 1.66669 18.0173 1.82988 18.3437 2.15627C18.6701 2.48266 18.8333 2.87502 18.8333 3.33335V16.6667C18.8333 17.125 18.6701 17.5174 18.3437 17.8438C18.0173 18.1702 17.625 18.3334 17.1666 18.3334H3.83329ZM3.83329 16.6667H17.1666V3.33335H3.83329V16.6667Z" />
        </g>
      </svg>
    ),
    link: "/channel-manager/inventory",
    permission: "read_inventory",
    internalItems: [
      {
        name: "Rooms",
        link: "/rooms",
      },
      {
        name: "Room Types",
        link: "/roomstype",
      },
      {
        name: "Meal Plans",
        link: "/roomsplans",
      },
      {
        name: "Room Rate Plan",
        link: "/roomsrateplans",
      },
      {
        name: "Inventory",
        link: "/inventory",
      },
    ],
  },
  {
    id: 3,
    name: "Bookings",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="18"
        viewBox="0 0 15 18"
        fill="#666"
      >
        <path d="M6.625 11.875L9.52083 8.97919C9.6875 8.81252 9.88889 8.72919 10.125 8.72919C10.3611 8.72919 10.5625 8.81252 10.7292 8.97919C10.8958 9.14585 10.9792 9.34724 10.9792 9.58335C10.9792 9.81946 10.8958 10.0209 10.7292 10.1875L7.20833 13.7084C7.04167 13.875 6.84722 13.9584 6.625 13.9584C6.40278 13.9584 6.20833 13.875 6.04167 13.7084L4.27083 11.9375C4.10417 11.7709 4.02083 11.5695 4.02083 11.3334C4.02083 11.0972 4.10417 10.8959 4.27083 10.7292C4.4375 10.5625 4.63889 10.4792 4.875 10.4792C5.11111 10.4792 5.3125 10.5625 5.47917 10.7292L6.625 11.875ZM1.66667 17.3334C1.20833 17.3334 0.815972 17.1702 0.489583 16.8438C0.163194 16.5174 0 16.125 0 15.6667V4.00002C0 3.54169 0.163194 3.14933 0.489583 2.82294C0.815972 2.49655 1.20833 2.33335 1.66667 2.33335H2.5V1.50002C2.5 1.26391 2.57986 1.06599 2.73958 0.90627C2.89931 0.746548 3.09722 0.666687 3.33333 0.666687C3.56944 0.666687 3.76736 0.746548 3.92708 0.90627C4.08681 1.06599 4.16667 1.26391 4.16667 1.50002V2.33335H10.8333V1.50002C10.8333 1.26391 10.9132 1.06599 11.0729 0.90627C11.2326 0.746548 11.4306 0.666687 11.6667 0.666687C11.9028 0.666687 12.1007 0.746548 12.2604 0.90627C12.4201 1.06599 12.5 1.26391 12.5 1.50002V2.33335H13.3333C13.7917 2.33335 14.184 2.49655 14.5104 2.82294C14.8368 3.14933 15 3.54169 15 4.00002V15.6667C15 16.125 14.8368 16.5174 14.5104 16.8438C14.184 17.1702 13.7917 17.3334 13.3333 17.3334H1.66667ZM1.66667 15.6667H13.3333V7.33335H1.66667V15.6667ZM1.66667 5.66669H13.3333V4.00002H1.66667V5.66669Z" />
      </svg>
    ),
    link: "/channel-manager/bookings",
    permission: "read_reservations",
  },
 
  {
    id: 10,
    link: "/channel-manager/settings/channel",
    name: "Settings",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        viewBox="0 0 17 18"
      >
        <g clipPath="url(#clip0_1329_1622)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.02247 0.818359C7.61537 0.818359 7.2702 1.11776 7.21268 1.52084L6.90885 3.64704C6.33417 3.86623 5.80383 4.17532 5.33501 4.55712L3.34029 3.75645C2.96236 3.60479 2.5305 3.75404 2.32696 4.10667L1.40031 5.71186C1.19656 6.06448 1.28325 6.51313 1.60366 6.76453L3.29375 8.09121C3.24661 8.38724 3.22204 8.69085 3.22204 9.00018C3.22204 9.30951 3.24661 9.6131 3.29375 9.90913L1.60366 11.2358C1.28325 11.4872 1.19656 11.9359 1.40031 12.2885L2.32696 13.8937C2.5305 14.2463 2.96236 14.3956 3.34029 14.2439L5.33501 13.4432C5.80383 13.825 6.33417 14.1341 6.90885 14.3533L7.21268 16.4795C7.2702 16.8826 7.61537 17.182 8.02247 17.182H9.87616C10.2833 17.182 10.6284 16.8826 10.686 16.4795L10.9898 14.3533C11.5645 14.1341 12.0948 13.8251 12.5636 13.4433L14.5581 14.2439C14.9361 14.3956 15.3679 14.2463 15.5715 13.8937L16.4983 12.2885C16.7019 11.9359 16.6152 11.4872 16.295 11.2358L14.6049 9.9092C14.652 9.61314 14.6766 9.30952 14.6766 9.00018C14.6766 8.89915 14.674 8.79874 14.6688 8.699C14.6582 8.49333 14.6366 8.29054 14.6049 8.09116L16.295 6.76453C16.6152 6.51313 16.7019 6.06448 16.4983 5.71186L15.5715 4.10667C15.3679 3.75404 14.9361 3.60479 14.5581 3.75645L12.5636 4.55707C12.0948 4.17529 11.5645 3.86623 10.9898 3.64704L10.686 1.52084C10.6284 1.11776 10.2833 0.818359 9.87616 0.818359H8.02247ZM8.9494 11.4547C10.305 11.4547 11.4039 10.3558 11.4039 9.00018C11.4039 7.64457 10.305 6.54563 8.9494 6.54563C7.59379 6.54563 6.49485 7.64457 6.49485 9.00018C6.49485 10.3558 7.59379 11.4547 8.9494 11.4547Z"
            fill=""
          />
        </g>
        <defs>
          <clipPath id="clip0_1329_1622">
            <rect
              width="16.3636"
              height="16.3636"
              fill="white"
              transform="translate(0.63623 0.818359)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    permission: "read_settings",
  } 
];



const ChannelManagerNavigationLinks = () => {
  const userToken = getDecryptedData('encryptToken');
  const role = JSON.parse(localStorage.getItem("role"));
  // const role = "f_and_b";

  const [activeNav, setActiveNav] = useState(admin_menus)


  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  const { width } = dimensions;
  const [limitOnFront, setLimitOnFront] = useState(6);

  useEffect(() => {
    if (width > 1800) {
      setLimitOnFront(10);
    } else if (width > 1100) {
      setLimitOnFront(6);
    } else if (width > 800) {
      setLimitOnFront(4);
    } else {
      setLimitOnFront(2);
    }
  }, [width]);

  const [showMenuLinks, setShowMenuLinks] = useState(false);
  const location = useLocation();
  const otherMenusRef = useRef(null);
  const activeIndex = activeNav.findIndex(
    (item) => item.link === location.pathname
  );

  const endIndex = typeof limitOnFront === "number" ? limitOnFront : 4;


  const handleClickOutside = (event) => {
    if (
      otherMenusRef.current &&
      !otherMenusRef.current.contains(event.target)
    ) {
      setShowMenuLinks(false);
    }
  };

   useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="footer_nav_bar">
        <div className="footer_nav_bar_menus">
          {activeNav &&
            activeNav?.slice(0, endIndex)?.map((item, index) => (
              <div key={index}>
                <div
                    key={item.id}
                    className={`footer_nav_bar_item ${
                      index === activeIndex ? "active" : ""
                    }`}
                  >
                    <Link
                      onClick={() => setShowMenuLinks(false)}
                      to={item.link}
                      className="item_list"
                    >
                      {item.icon} {item.name}
                    </Link>
                  </div>
              </div>
            ))}
        </div>

        <div className="footer_nav_bar_other_menus" ref={otherMenusRef}>
          {showMenuLinks && (
            <div className="other_menus_item">
              {activeNav &&
                activeNav.slice(endIndex).map((item) => (
                  <>
                     <div key={item.id}>
                        <Link
                          onClick={() => setShowMenuLinks(false)}
                          to={item.link}
                          className="menus_item_list_item"
                        >
                          {item.icon} {item.name}
                        </Link>
                      </div>
                  </>
                ))}
            </div>
          )}
          {activeNav && activeNav.length > endIndex ? (
            // <BgOutlineThemeButton
            //   onClick={() => setShowMenuLinks(!showMenuLinks)}
            //   icon={
            //     showMenuLinks ? (
            //       <div>
            //         <svg
            //           xmlns="http://www.w3.org/2000/svg"
            //           width="12"
            //           height="8"
            //           viewBox="0 0 12 8"
            //           fill="none"
            //         >
            //           <path
            //             d="M11 1L6 6L0.999999 1"
            //             stroke="#3968ED"
            //             strokeWidth="1.5"
            //           />
            //         </svg>
            //       </div>
            //     ) : (
            //       <div>
            //         <svg
            //           xmlns="http://www.w3.org/2000/svg"
            //           width="12"
            //           height="8"
            //           viewBox="0 0 12 8"
            //           fill="none"
            //         >
            //           <path
            //             d="M1 7L6 2L11 7"
            //             stroke="#3968ED"
            //             strokeWidth="1.5"
            //           />
            //         </svg>
            //       </div>
            //     )
            //   }
            //   children={"Menu"}
            // />
            <div
              className="footer_nav_bar_menu_item "
              style={{ position: "relative" }}
              onClick={() => setShowMenuLinks(!showMenuLinks)}
            >
              <svg
                width="4"
                height="16"
                viewBox="0 0 4 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14C0 13.45 0.195833 12.9792 0.5875 12.5875C0.979167 12.1958 1.45 12 2 12C2.55 12 3.02083 12.1958 3.4125 12.5875C3.80417 12.9792 4 13.45 4 14C4 14.55 3.80417 15.0208 3.4125 15.4125C3.02083 15.8042 2.55 16 2 16ZM2 10C1.45 10 0.979167 9.80417 0.5875 9.4125C0.195833 9.02083 0 8.55 0 8C0 7.45 0.195833 6.97917 0.5875 6.5875C0.979167 6.19583 1.45 6 2 6C2.55 6 3.02083 6.19583 3.4125 6.5875C3.80417 6.97917 4 7.45 4 8C4 8.55 3.80417 9.02083 3.4125 9.4125C3.02083 9.80417 2.55 10 2 10ZM2 4C1.45 4 0.979167 3.80417 0.5875 3.4125C0.195833 3.02083 0 2.55 0 2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0C2.55 0 3.02083 0.195833 3.4125 0.5875C3.80417 0.979167 4 1.45 4 2C4 2.55 3.80417 3.02083 3.4125 3.4125C3.02083 3.80417 2.55 4 2 4Z"
                  fill="#888888"
                />
              </svg>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default ChannelManagerNavigationLinks;
