import React, { useEffect } from "react";
import RoomTypes from "../Components/RoomTypes";
import { useSelector } from "react-redux";
import RoomTypesCommon from "../Components/RoomTypesCommon";

const RoomsTypePage = () => {
  useEffect(() => {
    // Set the meta title
    document.title =
      "Room Type - RevCatalyst - Boosting Your Hotel Performance";

    // Create or update the meta description tag
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property.";
  }, []);
  const activeApp =
    useSelector((state) => state.activeApp.activeApp) ||
    localStorage.getItem("activeApp");

  return (
    <>
      {activeApp === "pms" && (
        <div className="main_container">
          {" "}
          <RoomTypes />{" "}
        </div>
      )}

      {activeApp === "booking_engine" && <RoomTypesCommon />}
      {activeApp === "channel_manager" && <RoomTypesCommon />}
    </>
  );
};

export default RoomsTypePage;
