import React from "react";
import StoreRequestedItems from "../../Components/Store/StoreRequestedItems";

const StoreRequestedItemsPage = () => {
  return (
    <>
      <div className="main_container">
        <StoreRequestedItems />
      </div>
    </>
  );
};

export default StoreRequestedItemsPage;
