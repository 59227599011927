import React, { useEffect, useState } from "react";
import styles from "./confirmPopup.module.scss";
import { showErrorToast } from "../../../assets/toastUtils";

const ConfirmPopup = ({
  charLimit,
  reasonField,
  heading,
  subHeading,
  setShowPopup,
  handleConfirmClick,
  showPopup,
}) => {
  const [text, setText] = useState("");

  const handleChange = (event) => {
    if (charLimit) {
      if (event.target.value.length <= charLimit) {
        setText(event.target.value);
      }
    } else {
      setText(event.target.value);
    }
  };

  const handlePopupConfirmClick = () => {
    if (reasonField) {
      if (!text) {
        showErrorToast("Please enter a reason");
        return;
      } else {
        handleConfirmClick(text);
      }
    } else {
      handleConfirmClick();
    }
  };

  useEffect(() => {
    if (showPopup) {
      setText("");
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup function to ensure overflow is reset if the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showPopup]);

  return (
    <div className={styles.parentContainer} onClick={() => setShowPopup(false)}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <h2>{heading}</h2>
        <p>{subHeading}</p>

        {reasonField && (
          <div className={styles.reasonContainer}>
            <label>Reason</label>
            <textarea value={text} onChange={handleChange} />
            {charLimit && (
              <span className={styles.charCount}>
                {text.length} / {charLimit}
              </span>
            )}
          </div>
        )}

        <div className={styles.buttonsContainer}>
          <button className={styles.noBtn} onClick={() => setShowPopup(false)}>
            No
          </button>
          <button className={styles.yesBtn} onClick={handlePopupConfirmClick}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
