import React, { useEffect, useState } from "react";

const BookingStatusUpdate = ({ status }) => {
  const confirmed = (
    <div
      style={{
        color: "#15AA12",
        padding: "6px",
        backgroundColor: "#DFF8DF",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        // color: "#E03838",
        // padding: "6px",
        // backgroundColor: "#FFEAEA",
        // display: "inline-flex",
        // borderRadius: "10px",
        // fontWeight: "500",
      }}
    >
      Confirmed
    </div>
  );
  const paid = (
    <div
      style={{
        color: "#15AA12",
        padding: "6px",
        backgroundColor: "#DFF8DF",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        // color: "#E03838",
        // padding: "6px",
        // backgroundColor: "#FFEAEA",
        // display: "inline-flex",
        // borderRadius: "10px",
        // fontWeight: "500",
        fontSize: "12px",
      }}
    >
      Paid
    </div>
  );
  const newConfirmed = (
    <div
      style={{
        color: "rgba(255, 255, 255, 1)",
        padding: "0 6px",
        backgroundColor: "rgba(57, 104, 237, 1)",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        height: "20px",
      }}
    >
      Confirmed
    </div>
  );
  const front_desk = (
    <div
    
    >
      Front Desk
    </div>
  );
  const noShow = (
    <div
      style={{
        color: "rgba(255, 255, 255, 1)",
        padding: "0 6px",
        backgroundColor: "rgb(136, 136, 136)",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        height: "20px",
      }}
    >
      No Show
    </div>
  );
  const newLead = (
    <div
      style={{
        color: "rgba(255, 255, 255, 1)",
        padding: "0 6px",
        backgroundColor: "rgba(57, 104, 237, 1)",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        height: "20px",
      }}
    >
      New Lead
    </div>
  );
  const dropped = (
    <div
      style={{
        color: "rgba(255, 255, 255, 1)",
        padding: "0 6px",
        backgroundColor: "#888888",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        height: "20px",
      }}
    >
      Dropped
    </div>
  );
  const converted = (
    <div
      style={{
        color: "rgba(255, 255, 255, 1)",
        padding: "0 6px",
        backgroundColor: "#15AA12",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        height: "20px",
      }}
    >
      Converted
    </div>
  );
  const newCancelled = (
    <div
      style={{
        color: "rgba(255, 255, 255, 1)",
        padding: "0 6px",
        backgroundColor: "rgba(224, 56, 56, 1)",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        height: "20px",
      }}
    >
      Cancelled
    </div>
  );
  const newHold = (
    <div
      style={{
        color: "rgba(255, 255, 255, 1)",
        padding: "0 6px",
        backgroundColor: "rgba(189, 148, 27, 1)",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        height: "20px",
      }}
    >
      Hold
    </div>
  );
  const unpaid = (
    <div
      style={{
        color: "rgba(255, 255, 255, 1)",
        padding: "0 6px",
        backgroundColor: "rgba(189, 148, 27, 1)",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        height: "20px",
        fontSize: "12px",
      }}
    >
      Un-Paid
    </div>
  );

  const newCheckedIn = (
    <div
      style={{
        color: "rgba(255, 255, 255, 1)",
        padding: "0 6px",
        backgroundColor: "rgba(21, 170, 18, 1)",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        height: "20px",
        fontSize: "10px",
      }}
    >
      Arrived
    </div>
  );

  const newCheckedOut = (
    <div
      style={{
        color: "rgba(255, 255, 255, 1)",
        padding: "0 6px",
        backgroundColor: "rgba(255, 128, 64, 1)",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        height: "20px",
        fontSize: "10px",
      }}
    >
      Departed
    </div>
  );

  const checkOut = (
    <div
      style={{
        color: "#15AA12",
        padding: "6px",
        backgroundColor: "#DFF8DF",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        // color: "#E03838",
        // padding: "6px",
        // backgroundColor: "#FFEAEA",
        // display: "inline-flex",
        // borderRadius: "10px",
        // fontWeight: "500",
      }}
    >
      Checked-Out
    </div>
  );
  const checkIn = (
    <div
      style={{
        color: "#15AA12",
        padding: "6px",
        backgroundColor: "#DFF8DF",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        // color: "#E03838",
        // padding: "6px",
        // backgroundColor: "#FFEAEA",
        // display: "inline-flex",
        // borderRadius: "10px",
        // fontWeight: "500",
      }}
    >
      Checked-In
    </div>
  );
  const cancelled = (
    <div
      style={{
        color: "#E03838",
        padding: "6px",
        backgroundColor: "#FFEAEA",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
      }}
    >
      Cancelled
    </div>
  );

  const available = (
    <div
      style={{
        color: "#15AA12",
        padding: "6px",
        backgroundColor: "#DFF8DF",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
      }}
    >
      Clean
    </div>
  );
  const clean = (
    <div
      style={{
        color: "#3968ED",
        padding: "6px",
        backgroundColor: "#ECF1FD",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
      }}
    >
      Clean
    </div>
  );
  const inspected = (
    <div
      style={{
        color: "#15AA12",
        padding: "6px",
        backgroundColor: "#DFF8DF",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
      }}
    >
      Inspected
    </div>
  );

  const dirty = (
    <div
      style={{
        color: "#E03838",
        padding: "6px",
        backgroundColor: "#FFEAEA",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
      }}
    >
      Dirty
    </div>
  );
  const hold = (
    <div
      style={{
        color: "#E0AE18",
        padding: "6px",
        backgroundColor: "#FFF4D2",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
      }}
    >
      Hold
    </div>
  );
  const pending = (
    <div
      style={{
        color: "#E0AE18",
        padding: "6px",
        backgroundColor: "#FFF4D2",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
      }}
    >
      Pending
    </div>
  );
  const property_brochure = (
    <div
   
    >
      Property Brochure
    </div>
  );
  const property_images = (
    <div
   
    >
      Property Images
    </div>
  );
  const payment_link = (
    <div
   
    >
      Payment Link
    </div>
  );
  const rates_and_availability = (
    <div
   
    >
     Rates and Availability
    </div>
  );
  const b2b_rates = (
    <div
   
    >
    B2B Rates
    </div>
  );
  const voucher = (
    <div
   
    >
    Bookings
    </div>
  );
  const bill = (
    <div
   
    >
    Bill
    </div>
  );
  const payment_receipt = (
    <div
   
    >
    Payment Receipt
    </div>
  );
  const sales_offer_creative = (
    <div
   
    >
   Sales/Offer Creatives
    </div>
  );


  // useEffect(() => {
  //   if (status === "booked") {
  //     setButtonStatus(booked);
  //   } else if (status === "available") {
  //     setButtonStatus(available);
  //   }
  // }, [status]);

  useEffect(() => {
    if (status === "checked_in") {
      setButtonStatus(newCheckedIn);
    } else if (status === "checked_out") {
      setButtonStatus(newCheckedOut);
    } else if (status === "confirmed") {
      setButtonStatus(newConfirmed);
    } else if (status === "cancelled") {
      setButtonStatus(newCancelled);
    } else if (status === "hold") {
      setButtonStatus(newHold);
    } else if (status === "pending") {
      setButtonStatus(newHold);
    } else if (status === "no_show") {
      setButtonStatus(noShow);
    } else if (status === "new_lead") {
      setButtonStatus(newLead);
    } else if (status === "property_brochure") {
      setButtonStatus(property_brochure);
    } else if (status === "property_images") {
      setButtonStatus(property_images);
    } else if (status === "rates_and_availability") {
      setButtonStatus(rates_and_availability);
    } else if (status === "payment_link") {
      setButtonStatus(payment_link);
    } else if (status === "b2b_rates") {
      setButtonStatus(b2b_rates);
    } else if (status === "converted") {
      setButtonStatus(converted);
    } else if (status === "paid") {
      setButtonStatus(paid);
    } else if (status === "voucher") {
      setButtonStatus(voucher);
    }
    else if (status === "bill") {
      setButtonStatus(bill);
    } 
    else if (status === "payment-receipt") {
      setButtonStatus(payment_receipt);
    } 
    else if (status === "sales_offer_creative") {
      setButtonStatus(sales_offer_creative);
    } 
     else if (status === "paid") {
      setButtonStatus(paid);
    } else if (status === "front_desk") {
      setButtonStatus(front_desk);
    } else if (status === "dropped") {
      setButtonStatus(dropped);
    } else if (status === "unpaid") {
      setButtonStatus(unpaid);
    } else {
      setButtonStatus("");
    }
  }, [status]);

  const [buttonStatus, setButtonStatus] = useState(status);

  return <div>{buttonStatus}</div>;
};

export default BookingStatusUpdate;
