import React from "react";
import FolioAuditLogs from "../Components/Reports/FolioAuditLogs";

const NightAuditLogsPage = () => {
  return (
    <>
      <div className="main_container">
        <FolioAuditLogs />
      </div>
    </>
  );
};

export default NightAuditLogsPage;
