import { configureStore } from "@reduxjs/toolkit";
import propertyReducer from "../Slice/propertyslice";
import loginTokenReducer from "../Slice/loginTokenSlice";
import isAuthenticatedReducer from "../Slice/isAuthenticatedSlice";
import activeAppSlice from "../Slice/activeAppSlice";
import propertiesReducer  from "../Slice/propertiesSlices"


export const store = configureStore({
    reducer: {
        property: propertyReducer,
        loginToken: loginTokenReducer,
        isAuthenticated: isAuthenticatedReducer,
        activeApp: activeAppSlice,
        properties : propertiesReducer
    },
})