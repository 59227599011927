import React, { useEffect, useState } from "react";

const FoodSaleStatusCustomComp = ({ status }) => {

    const Unpaid = (
        <div
            style={{
                color: "#E0AE18",
                padding: "6px",
                backgroundColor: "#FFF4D2",
                display: "inline-flex",
                borderRadius: "10px",
                fontWeight: "500",
            }}
        >
            Unpaid
        </div>
    );

    const Cancelled = (
        <div
            style={{
                color: "#E03838",
                padding: "6px",
                backgroundColor: "#FFEAEA",
                display: "inline-flex",
                borderRadius: "10px",
                fontWeight: "500",
            }}
        >
            Cancelled
        </div>
    );

    const Paid = (
        <div
            style={{
                color: "#15AA12",
                padding: "6px",
                backgroundColor: "#DFF8DF",
                display: "inline-flex",
                borderRadius: "10px",
                fontWeight: "500",
            }}
        >
            Paid
        </div>
    );

    const parsely_paid = (
        <div
            style={{
                color: "#3968ED",
                padding: "6px",
                backgroundColor: "#ECF1FD",
                display: "inline-flex",
                borderRadius: "10px",
                fontWeight: "500",
            }}
        >
            Partially Paid
        </div>
    );

    useEffect(() => {
        if (status === "paid") {
            setButtonStatus(Paid);
        } else if (status === "parsely_paid") {
            setButtonStatus(parsely_paid);
        } else if (status === "unpaid") {
            setButtonStatus(Unpaid);
        } else if (status === "cancelled") {
            setButtonStatus(Cancelled);
        } else {
            setButtonStatus(Unpaid);
        }
    }, [status]);

    const [buttonStatus, setButtonStatus] = useState(status);

    return <div>{buttonStatus}</div>;
};

export default FoodSaleStatusCustomComp;
