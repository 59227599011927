import React, { useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import Discounts from "../Components/Discounts";
import Booking from "../Components/Booking";
import NewBooking from "../Components/NewBooking";
import CommunicationLeads from "../Components/CommunicationLeads";
import CreateMessageNewPage from "../Components/CustomComponents/CreateMessageNewPage/CreateMessageNewPage";


const CreateMessagePage = () => {
  useEffect(() => {
    // Set the meta title
    document.title = "Booking - RevCatalyst - Boosting Your Hotel Performance";

    // Create or update the meta description tag
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property.";
  }, []);
  return (
    <>
      <div className="main_container">
        <CreateMessageNewPage />
      </div>
    </>
  );
};

export default CreateMessagePage;
