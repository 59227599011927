import "./CreateBookingInputBox.css";
const CreateBookingInputBox = ({
  label,
  value,
  onChange,
  alignment,
  name,
  error,
  disabled
}) => {
  return (
    <div>
      {alignment === "vertical" ? ( 
        <div className="create_reservation_vertical_inputs_container" style={{cursor:disabled ? "not-allowed" : ""}}>
          <label className="Create_booking_label">{label}</label>
          <input
            onChange={onChange}
            value={value}
            name={name}
            type="number"
            min="1"
            disabled={disabled}
            style={{
              border: error ? "1px solid #E03838" : "1px solid #ddd",
              backgroundColor: error ? "#FFEAEA" : "",
              cursor:disabled ? "not-allowed" : ""
              
            }}
            className={error ? "create_booking_error-border" : ""}
          ></input>
        </div>
      ) : (
        <div className="gap_7 flex" style={{cursor:disabled ? "not-allowed" : ""}}>
          <label className="Create_booking_label_small">{label}</label>
          <input
            onChange={onChange}
            value={value}
            
            name={name}
            disabled={disabled}
            style={{
              border: error ? "1px solid #E03838" : "1px solid #ddd",
              backgroundColor: error ? "#FFEAEA" : "",
              cursor:disabled ? "not-allowed" : ""
              
            }}
            className={error ? "create_booking_error-border adlut_box_input" : ""}
            
          ></input>
        </div>
      )}
    </div>
  );
};
export default CreateBookingInputBox;
