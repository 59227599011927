import React, { useState } from "react";
import styles from "./shareOnWhatsApp.module.scss";
import ShareOnWhatsAppEmail from "./ShareOnWhatsAppEmail";
const ShareButton = ({ type , data ,buttonStyles ,propertyName ,adjustLeft }) => {
  const [showPopup, setShowPopup] = useState(false);
 
  return (
    <>
      <div className={styles.containerShareButton} >
        {type === "text" ? (
          <button onClick={() => setShowPopup(!showPopup)} style={buttonStyles}>Share</button>
        ) : (
          <button onClick={() => setShowPopup(!showPopup)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 44 42"
              width="40px"
              height="30px"
            >
              <path
                fill="#1976D2"
                d="M38.1,31.2L19.4,24l18.7-7.2c1.5-0.6,2.3-2.3,1.7-3.9c-0.6-1.5-2.3-2.3-3.9-1.7l-26,10C8.8,21.6,8,22.8,8,24s0.8,2.4,1.9,2.8l26,10c0.4,0.1,0.7,0.2,1.1,0.2c1.2,0,2.3-0.7,2.8-1.9C40.4,33.5,39.6,31.8,38.1,31.2z"
              />
              <path
                fill="#1E88E5"
                d="M11 17A7 7 0 1 0 11 31 7 7 0 1 0 11 17zM37 7A7 7 0 1 0 37 21 7 7 0 1 0 37 7zM37 27A7 7 0 1 0 37 41 7 7 0 1 0 37 27z"
              />
            </svg>
          </button>
        )}
      </div>
  {
    showPopup && (
        <ShareOnWhatsAppEmail
        data={data}
        setShow={setShowPopup}
        propertyName={propertyName}
        show={showPopup}
        adjustLeft={adjustLeft}
      />
    )
  }
    </>
  );
};

export default ShareButton;
