import React from "react";
import "./CreateKotButton.css";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

const CreateKotNavbarButton = () => {
  const kotOptionsArray = ["Restaurant", "Room"];
  const [selectedKotOption, setSelectedKotOption] = useState(0);
  const [showKotOptions, setShowKotOptions] = useState(false);

  const navigate = useNavigate();

  const handleKotOptionClick = (index) => {
    if (index === 0) {
      navigate("/table/create-kot");
    }

    if (index === 1) {
      navigate("/room/create-kot");
    }
    setSelectedKotOption(index);
    setShowKotOptions(false);
  };
  return (
    // <div style={{padding: "20px" }}>
    <div
      className="mobile_nav_button_toggle"
      onClick={() => setShowKotOptions(!showKotOptions)}
      style={{ borderRadius: !showKotOptions ? "4px" : "4px 4px 0px 0px" }}
    >
      <span style={{ marginRight: "5px" }}>Create KOT</span>
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="8"
          viewBox="0 0 13 8"
          fill="none"
          transform={showKotOptions ? "rotate(180)" : ""}
        >
          <path
            d="M1.5 1.5L6.5 6.5L11.5 1.5"
            stroke="white"
            strokeWidth="1.5"
          />
        </svg>
      </span>

      {showKotOptions && (
        <>
          <div className="mobileNavigationKotOptions">
            {kotOptionsArray.map((option, index) => (
              <button
                key={index}
                className="mobileNavigationKotOptionsButton"
                onClick={() => handleKotOptionClick(index)}
              >
                {option}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
    // </div>
  );
};

export default CreateKotNavbarButton;
