import React, { useEffect, useState } from "react";
import VerticalInputFieldCustom from "../../../CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../../../CustomComponents/VerticalInputSelectCustomCopy";
import { Button, Icon } from "@shopify/polaris";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { BgThemeButton } from "../../../UI/Buttons";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../assets/toastUtils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { currentDateSingle } from "../../../../utils/utils";
import { getDecryptedData } from "../../../../utils/encryptStorage";

const RequestItemPopup = ({ setRequestItemPopupShow, department }) => {
  const navigate = useNavigate();
  const userToken = getDecryptedData('encryptToken');
  const permission = JSON.parse(localStorage.getItem("permissions"));

  const user = JSON.parse(localStorage.getItem("user"));
  // UseState for forms
  const [addItemData, setAddItemData] = useState({
    purchased_item_id: "",
    request_by: "",
    request_date: currentDateSingle,
    quantity: "",
    unit: "",
    item_category: "",
    item_department: "",
  });

  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(true);

  const setAddItemDataHandleChange = (event) => {
    setAddItemData({
      ...addItemData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitAddRooms = (event) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/assign/rooms/store/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...addItemData }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const [purchases, setPurchases] = useState([]);



  const fetchPurchases = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/purchased/items/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setPurchases(data.purchase_Items);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };
  useEffect(() => {
    if (property_id) {
      fetchPurchases(property_id?.id);
    }  
  }, [property_id]);
  const [items, setItems] = useState([]);

  const handleChangePurchaseItem = (event, index) => {
    const item_category = event.target.value;
    const selectedItem = purchases?.find(
      (item) => item.item_category === item_category
    );

    if (selectedItem) {
      setAddItemData({
        ...addItemData,
        item_category: selectedItem.item_category,
      });

      const filterItems = purchases?.filter(
        (item) => item.item_category === item_category
      );
      setItems(filterItems);
    } else {
      setItems([]);
      setAddItemData({
        ...addItemData,
        item_category: "Select Option",
      });
    }
  };

  const statusOptions = [
    { label: "Select Option", value: "" },
    { label: "pcs", value: "pcs" },
    { label: "g", value: "g" },
    { label: "kg", value: "kg" },
    { label: "litres", value: "litres" },
  ];

  //   const requestRoomsPost = (event) => {
  //     event.preventDefault();
  //     fetch(
  //       `${process.env.REACT_APP_BASE_URL}/api/v1/save/requested/items/${property_id?.id}`,
  //       {
  //         method: "POST",
  //         body: JSON.stringify({
  //           ...addItemData,
  //           item_department: department,
  //           request_by: user.name,
  //         }),
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${userToken}`,
  //         },
  //       }
  //     )
  //       .then((res) => res.json())
  //       .then((data) => {
  //         if (data.success) {
  //           showErrorToast(data.message);
  //           setRequestItemPopupShow(false);
  //         } else {
  //           showErrorToast(data.message);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //         showErrorToast("An error occurred while processing your request.");
  //       });
  //   };

  const requestRoomsPost = async (event) => {
    event.preventDefault();

    if (!property_id || !property_id.id) {
      showErrorToast("Property ID is invalid.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/save/requested/items/${property_id.id}`,
        {
          method: "POST",
          body: JSON.stringify({
            ...addItemData,
            item_department: department,
            request_by: user.name,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();

      if (data.success) {
        showSuccessToast(data.message);
        setRequestItemPopupShow(false);
        setAddItemData({
          purchased_item_id: "",
          request_by: "",
          request_date: currentDateSingle,
          quantity: "",
          unit: "",
          item_category: "",
          item_department: "",
        });
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  return (
    <div className="bg-backdrop">
      <form
        onSubmit={(e) => requestRoomsPost(e)}
        style={{ width: "360px" }}
        className="stock-add-model-container"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "15px",
          }}
        >
          <div className="add-ota-model-container-heading">Request Item</div>
          <div
            onClick={() => setRequestItemPopupShow(false)}
            style={{ cursor: "pointer" }}
          >
            <Icon source={CancelMajor} color="base" />
          </div>
        </div>

        <div className="form-container-div-half">
          <div className="form-container-div">
            <div style={{ marginBottom: "4px" }}>Select Category</div>
            <select
              style={{
                width: "100%",
                minHeight: "2.25rem",
                border: "1px solid rgba(171, 177, 186, 1)",
                borderRadius: "3px",
              }}
              required={true}
              name="item_category"
              onChange={(e) => handleChangePurchaseItem(e)}
              //   onChange={setAddItemDataHandleChange}
              value={addItemData.item_category}
            >
              <option value="">Select Option</option>
              {[...new Set(purchases?.map((x) => x.item_category))].map(
                (category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                )
              )}
            </select>
          </div>
        </div>

        {addItemData.item_category !== "" && (
          <div className="form-container-div-half">
            <div className="form-container-div">
              <div style={{ marginBottom: "4px" }}>Select Item</div>
              <select
                style={{
                  width: "100%",
                  minHeight: "2.25rem",
                  border: "1px solid rgba(171, 177, 186, 1)",
                  borderRadius: "3px",
                }}
                required={true}
                name="purchased_item_id"
                //   onChange={(e) => handleChangePurchaseItem(e)}
                onChange={setAddItemDataHandleChange}
                value={addItemData.purchased_item_id}
              >
                <option value="">Select Option</option>
                {items?.map((x, index) => (
                  <option key={index} value={x.id}>
                    {x.item_name} ({x.purchased_id})
                    {/* ({x.item_qty} {" "} { x.weight_unit}) */}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}

        <div className="form-container-div-half">
          <div style={{ width: "100%" }} className="form-container-div">
            <VerticalInputFieldCustom
              required={true}
              type={"number"}
              titleName="Quantity"
              name="quantity"
              value={addItemData.quantity}
              onChange={setAddItemDataHandleChange}
            />
          </div>
          <div style={{ width: "100%" }} className="form-container-div">
            <VerticalInputSelectCustomCopy
              required={true}
              options={statusOptions}
              titleName="Unit"
              name="unit"
              value={addItemData.unit}
              onChange={setAddItemDataHandleChange}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "start",
              gap: "10px",
            }}
          >
            <BgThemeButton type="submit" children={"Add"} />
            <Button onClick={() => setRequestItemPopupShow(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RequestItemPopup;
