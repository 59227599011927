import { useEffect, useState } from "react";
import VerticalInputSelectCustomCopy from "../Components/CustomComponents/VerticalInputSelectCustomCopy";
import GoBackButtonCustom from "../Components/GoBackButtonCustom/GoBackButtonCustom";
import { BgThemeButton, WithoutBgButtonBlue } from "../Components/UI/Buttons";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CustomPagination from "../Components/CustomComponents/CustomPagination/CustomPagination";
import { useIndexResourceState } from "@shopify/polaris";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import SearchComponent from "../Components/CustomComponents/SearchComponent/SearchComponent";
import VerticalInputFieldCustom from "../Components/CustomComponents/VerticalInputFieldCustom";
import CustomSelectOptionsComponent from "../Components/CustomComponents/CustomSelectOptionsComponent/CustomSelectOptionsComponent";
import {
  formatIndianCurrency,
  getCurrentPropertyDateHandler,
  globalErrorHandler,
} from "../utils/utils";
import { getDecryptedData } from "../utils/encryptStorage";
const IssueItemsPage = () => {
  const [issueItemsTableData, setIssueItemsTableData] = useState([]);
  const [units, setUnits] = useState([]);
  const [categories, setCategories] = useState([]);
  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);
  const [orders, setOrders] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [purchasedItems, setPurchasedItems] = useState([]);
  const [errors, setErrors] = useState({});

  const [allOrders, setAllOrders] = useState([]);

  const [departments, setDepartments] = useState([]);
  const navigate = useNavigate();
  // Pagignation
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(orders.length / itemsPerPage);
    setCurrentPage(newPage > totalPages ? totalPages : newPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentOrders = orders?.slice(startIndex, endIndex);

  const [propertyCurrentDate, setPropertyCurrentDate] = useState();

  useEffect(() => {
    if (property_id) {
      getCurrentPropertyDateHandler(property_id?.id, userToken)
        .then((date) => setPropertyCurrentDate(date))
        .catch((error) => console.error(error));
    }
  }, [property_id]);

  const [issueItems, setIssueItems] = useState({
    item_category: "", //
    item_name: "", //
    purchased_item_id: "", //

    unique_purchase_id: "",
    purchase_id: "",

    item_price: "",
    unit_name: "",
    quantity: "",

    //     'issue_to
    // 'issue_date
    // 'issue_by
    // 'department
    // 'details

    // 'details.*.item_category
    // 'details.*.item_name
    // 'details.*.purchased_item_id //item name id

    // 'details.*.unique_purchase_id //unique_id
    // 'details.*.purchase_id //unique_id id

    // 'details.*.item_price
    // 'details.*.unit_name
    // 'details.*.quantity
  });
  const [outerData, setOuterData] = useState({
    department_name: "",
    issue_to: "",
    issue_date: "",
  });

  const itemNameOptions = [
    { label: "Select Option" },
    { label: "Apple", value: "apple" },
    { label: "Orange", value: "orange" },
    { label: "Banana", value: "banana" },
    { label: "Tomato", value: "tomato" },
    { label: "Potato", value: "potato" },
    { label: "Onion", value: "onion" },
    { label: "Carrot", value: "carrot" },
    { label: "Lettuce", value: "lettuce" },
    { label: "Cucumber", value: "cucumber" },
    { label: "Broccoli", value: "broccoli" },
    { label: "Milk", value: "milk" },
    { label: "Bread", value: "bread" },
    { label: "Eggs", value: "eggs" },
    { label: "Cheese", value: "cheese" },
    { label: "Chicken", value: "chicken" },
    { label: "Fish", value: "fish" },
    { label: "Rice", value: "rice" },
    { label: "Pasta", value: "pasta" },
    { label: "Flour", value: "flour" },
    // Add more options as needed
  ];
  const itemTypeOptions = [
    { label: "Select Option" },
    { label: "Rakesh", value: "Rakesh" },
    { label: "Rakesh", value: "Rakesh" },
    { label: "Rakesh", value: "Rakesh" },
    { label: "Rakesh", value: "Rakesh" },
    { label: "Rakesh", value: "Rakesh" },
    { label: "Rakesh", value: "Rakesh" },
    { label: "Rakesh", value: "Rakesh" },
  ];

  const bundleOptions = [
    { label: "Select" },
    { label: "Single", value: "single" },
    { label: "Pack", value: "pack" },
    { label: "Box", value: "box" },
    { label: "Bag", value: "bag" },
    { label: "Bundle", value: "bundle" },
    { label: "Case", value: "case" },
    { label: "Set", value: "set" },
    { label: "Carton", value: "carton" },
    { label: "Jar", value: "jar" },
    { label: "Container", value: "container" },
    { label: "Bundle", value: "bundle" },
    { label: "Dozen", value: "dozen" },
    // Add more options as needed
  ];

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   const updatedIssueItems = { ...issueItems, [name]: value };

  //   if (name === "qty") {
  //     const max_qty = +updatedIssueItems.max_qty;
  //     if (value > max_qty) {
  //       showErrorToast("Quantity cannot be greater than max quantity");
  //       return;
  //     }

  //     const remain_qty = updatedIssueItems.max_qty - updatedIssueItems.qty ? updatedIssueItems.max_qty  : 0;
  //     const price = updatedIssueItems.item_price;
  //     const quantity = updatedIssueItems.qty;
  //     const totalAmount = price * quantity;
  //     updatedIssueItems.total_amount = totalAmount;
  //     updatedIssueItems.max_qty = remain_qty;
  //   }

  //   setIssueItems(updatedIssueItems);
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedIssueItems = { ...issueItems, [name]: value };

    if (name === "quantity") {
      const max_qty = +issueItems.max_qty;
      const input_qty = +value;

      if (value === "0") {
        showErrorToast("Quantity cannot be 0");
        setErrors({
          ...errors,
          qty: "Quantity cannot be greater than max quantity",
          quantity: "Quantity cannot be greater than max quantity",
        });
        setTimeout(() => {
          setErrors({});
        }, 1500);
        return;
      }

      if (input_qty > max_qty) {
        setErrors({
          ...errors,
          quantity: "Quantity cannot be greater than max quantity",
        });
        setTimeout(() => {
          setErrors({});
        }, 1500);
        showErrorToast("Quantity cannot be greater than max quantity");
        return;
      }

      const remain_qty = max_qty - input_qty;
      const price = +issueItems.item_price;
      const totalAmount = price * input_qty;

      updatedIssueItems.total_amount = totalAmount;
      updatedIssueItems.remain_qty = remain_qty;
    }

    setIssueItems(updatedIssueItems);
  };

  const handleAddItem = () => {
    console.log(issueItems);
    // if (
    //   !issueItems.issue_to ||
    //   !issueItems.issue_by ||
    //   !issueItems.item_category ||
    //   !issueItems.purchased_item_id ||
    //   !issueItems.item_price ||
    //   !issueItems.qty ||
    //   !issueItems.total_amount
    // ) {
    //   showErrorToast("Please fill out all required fields.");
    //   return;
    // }

    // item_category: "", //
    // item_name: "", //
    // purchased_item_id: "", //

    // unique_purchase_id: "",
    // purchase_id: "",

    // item_price: "",
    // unit_name: "",
    // quantity: "",
    const errors = globalErrorHandler(issueItems, [
      "item_category",
      "item_name",
      "purchased_item_id",

      "unique_purchase_id",
      "purchase_id",

      "item_price",
      "unit_name",
      "quantity",
      "total_amount",
    ]);

    setErrors(errors.errors);
    setTimeout(() => {
      setErrors({});
      return clearTimeout();
    }, 3000);

    if (errors.success)
      return showErrorToast("Please fill out all required fields.");

    const itemExists = issueItemsTableData.some(
      (item) => item.item_name === issueItems.item_name && item.unique_purchase_id === issueItems.unique_purchase_id 
    );
    if (itemExists) {
      showErrorToast("Item with same purchase ID already exists.");
      return;
    }

    const newItem = { ...issueItems };
    setIssueItemsTableData([...issueItemsTableData, newItem]);
    setIssueItems({
      issue_to: "",
      item_name: "",
      item_category: "",
      unit: "",
      purchase_id: "",
      item_price: "",
      qty: "",
      quantity: "",
      max_qty: "",
      total_amount: "",
      staff_id: "",
      item_id: "",
      tax_slab: "",
      item_type: "",
      staff_name: "",
      purchase_id: "",
    });
  };

  const handleDeleteItem = (index) => {
    const newData = [...issueItemsTableData];
    newData.splice(index, 1);
    setIssueItemsTableData(newData);
  };
  const handleUpdateItem = async (id) => {
    const errors = globalErrorHandler(outerData, [
      "department_name",
      "issue_to",
    ]);

    setErrors(errors.errors);
    setTimeout(() => {
      setErrors({});
      return clearTimeout();
    }, 3000);

    console.log(errors.errors);

    console.log({
      ...outerData,
      issue_date: propertyCurrentDate,
      details: issueItemsTableData,
    });
    if (errors.success)
      return showErrorToast("Please fill out all required fields.");

    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/create/issues/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({
            ...outerData,
            issue_date: propertyCurrentDate,
            details: issueItemsTableData,
            total_amount: totalAmt,
            total_items_quantity: totalQty,
            total_items: totalItems,
            reason_of_issue: 'stock'
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        // fetchInfo(property_id?.id);
        // setShowEditModelMenuActive(false);
        // clearSelection();
        showSuccessToast(data.message);
        // setItemsData({
        //   item_name: "",
        //   unit_name: "",
        //   category_name: "",
        //   hsn_code: "",
        //   issue_to: "",
        //   tax_slab: "",
        // });
        navigate("/hotelier/purchase/issueitems");
        // window.location.reload()
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const totalItems = issueItemsTableData.length;
  const totalQty = issueItemsTableData.reduce(
    (acc, item) => acc + parseInt(item.quantity),
    0
  );
  const totalAmt = issueItemsTableData.reduce(
    (acc, item) => acc + parseFloat(item.total_amount),
    0
  );
  // ....................API...................................

  const fetchInfo = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/purchased/${id}`,
        {
          method:'POST',
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      //   setOrders(data.data);
      setAllItems(data.data);
      //   setAllOrders(data.Item);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const fetchUnits = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/unit/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setUnits(data.Unit);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const fetchCategories = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/category/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setCategories(data.category);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const fetchDepartment = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/department/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setDepartments(data.Department);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const fetchStaff = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/account/get-user/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setStaffs(data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const fetchPurchases = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/purchased/items/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setPurchasedItems(data.purchase_Items);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchStaff(property_id?.id);
      fetchInfo(property_id?.id);
      fetchPurchases(property_id?.id);
      //   fetchUnits(property_id?.id);
      //   fetchCategories(property_id?.id);
      fetchDepartment(property_id?.id);
    }
  }, [property_id]);

  const unitOptions = [
    { label: "Select Option" },
    ...units.map((unit) => {
      return { label: unit.units_name, value: unit.id };
    }),
  ];
  const itemCategoryOptions = [
    { label: "Select Option" },
    ...categories.map((category) => {
      return { label: category.category_name, value: category.id };
    }),
  ];
  const departmentNameOptions = [
    { label: "Select Option" },
    ...departments.map((department) => {
      return {
        label: department.department_name,
        value: department.department,
      };
    }),
  ];

  const { selectedResources, clearSelection } =
    useIndexResourceState(currentOrders);

  const getStaffClicked = (item, index) => {
    console.log(item);
    setOuterData({
      ...outerData,
      issue_to: item.name,
      staff_id: item.id,
    });
  };

  const getSelectedItem = (item, index) => {
    // setIssueItems({
    //   ...issueItems,
    //   item_name: item.item_name,
    //   purchased_item_id: item.id,
    //   item_category: item.item_category,
    //   unit_id: item.unit_id,
    //   unit_name: item.unit_name,
    //   tax_rate: item.tax_slab,
    //   total_amount: "",
    //   item_qty: "1",
    //   item_price: "",
    // });

    // {{development}}/api/v1/get/purchased/from/item/1182/4
    setIssueItems({
      ...issueItems,
      qty: "",
      itm_qty: "",
      purchase_id: "",
      total_amount: "",
      item_qty: "",
      item_price: "",
      purchased_item_id: "",
    });

    try {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/purchased/from/item/${item?.item_id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            console.log(data);
            setIssueItems({
              // ...issueItems,
              item_name: data.item_name,
              item_category: data?.category_name,
              dynamicPricingArray: data?.dynamicPricingArray,
              quantity: "",
              max_qty: "",
              remain_qty: "",
            });
          }
        });
    } catch (error) {}
  };

  const purchaseIdHandleChange = (data) => {
    console.log(data);
    setIssueItems({
      ...issueItems,
      purchased_item_id: data?.details?.id,
      unique_purchase_id: data?.unique_purchase_id,
      purchase_id: data?.details?.purchased_id,
      item_price: data?.details?.item_price,
      max_qty: +data?.details?.item_qty,
      remain_qty: +data?.details?.item_qty,
      unit_name: data?.details?.unit_name,
      quantity: "",
      total_amount: "",
    });
    // setIssueItems({
    //   ...issueItems,
    //   item_price: data?.details?.item_price,
    //   // item_qty: data?.details?.item_qty,
    //   max_qty: +data?.details?.item_qty,
    //   remain_qty: +data?.details?.item_qty,
    //   purchase_id: data?.unique_purchase_id,
    //   // purchased_item_id: data?.unique_purchase_id,
    // });
  };

  console.log(outerData);
  return (
    <div className="main_container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 0",
        }}
      >
        <div className="room-type-heading">
          <GoBackButtonCustom
            onClick={() => navigate("/hotelier/purchase/issueitems")}
            buttonNextText={"Add Issue Items"}
          />
        </div>
        <div></div>
      </div>
      <div
        style={{
          background: "rgba(255, 255, 255, 1)",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <div title="Reservation Insights">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "10px",
            }}
          >
            <div className="flex flex_gap_10">
              <div style={{ width: "150px" }}>
                <VerticalInputSelectCustomCopy
                  titleName={"Department Name"}
                  options={departmentNameOptions}
                  required={true}
                  value={outerData?.department_name}
                  onChange={(e) =>
                    setOuterData({
                      ...outerData,
                      department_name: e.target.value,
                    })
                  }
                  name="issue_to"
                  error={errors?.department_name ? true : false}
                />
              </div>

              <div style={{ width: "150px" }}>
                <SearchComponent
                  required={true}
                  titleName="Staff Name"
                  searchArray={staffs}
                  keyName={"name"}
                  selectedValue={outerData?.issue_to}
                  // searchFilterBy="item_name"
                  getSelectedItem={getStaffClicked}
                  error={errors?.issue_to ? true : false}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "10px",
            }}
          >
            <div className="flex flex_gap_10">
              <div style={{ width: "150px" }}>
                <SearchComponent
                  required={true}
                  titleName="Item Name"
                  searchArray={purchasedItems}
                  keyName={"item_name"}
                  selectedValue={issueItems?.item_name}
                  // searchFilterBy="item_name"
                  getSelectedItem={getSelectedItem}
                  error={errors?.item_name ? true : false}
                  uniqueTrue={true}
                  uniqueKey={"item_name"}
                />
                {/* <VerticalInputSelectCustomCopy
                  titleName={"Item Name"}
                  options={itemNameOptions}
                  required={true}
                  value={issueItems.item_name}
                  onChange={(e) => handleChange(e)}
                  name="item_name"
                /> */}
              </div>
              <div style={{ width: "150px" }}>
                <VerticalInputFieldCustom
                  disabled={true}
                  titleName={"Item Category"}
                  required={true}
                  options={itemCategoryOptions}
                  value={issueItems.item_category}
                  onChange={(e) => handleChange(e)}
                  name="item_category"
                  error={errors?.item_category ? true : false}
                />
              </div>

              <div style={{ width: "100px" }}>
                <CustomSelectOptionsComponent
                  title={"Purchase ID"}
                  disabled={issueItems?.item_name ? false : true}
                  defaultValue={issueItems.unique_purchase_id}
                  options={issueItems?.dynamicPricingArray}
                  optionKeyName={"unique_purchase_id"}
                  getSelectedOptionObject={purchaseIdHandleChange}
                  error={errors?.unique_purchase_id ? true : false}
                />
              </div>
              <div style={{ width: "100px" }}>
                <VerticalInputFieldCustom
                  disabled={true}
                  titleName={"Price/Unit"}
                  style={{ width: "100%" }}
                  type="number"
                  value={issueItems.item_price}
                  required={true}
                  onChange={(e) => handleChange(e)}
                  name="item_price"
                  error={errors?.item_price ? true : false}
                />
              </div>
              <div style={{ width: "100px" }}>
                <VerticalInputFieldCustom
                  disabled={issueItems.item_price ? false : true}
                  titleName={"Qty"}
                  type="number"
                  style={{ width: "100%" }}
                  required={true}
                  value={issueItems.quantity}
                  onChange={(e) => handleChange(e)}
                  name="quantity"
                  error={errors?.quantity ? true : false}
                />
              </div>
              <div style={{ width: "100px" }}>
                <VerticalInputFieldCustom
                  titleName={"Total Amount"}
                  disabled={true}
                  style={{ width: "100%" }}
                  type="number"
                  value={issueItems.total_amount}
                  required={true}
                  onChange={(e) => handleChange(e)}
                  name="total_amount"
                  error={errors?.total_amount ? true : false}
                />
              </div>

              {/* <Link to={`/communication/createmessage`}> */}
              <WithoutBgButtonBlue
                style={{ fontSize: "14px", padding: "0" }}
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_5311_4678)">
                      <path
                        d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                        stroke="#3968ED"
                      />
                      <path
                        d="M10.5868 7H3.41357"
                        stroke="#3968ED"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M7 3.4132L7 10.5864"
                        stroke="#3968ED"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5311_4678">
                        <rect width="14" height="14" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                }
                children={"Add"}
                onClick={handleAddItem}
                // onClick={() => handleUpdateItem(selectedResources[0])}
              />
              {/* </Link> */}
            </div>
            <div>
              {issueItems.unit_name && <p>Unit: {issueItems.unit_name}</p>}

              <p>Remaining Qty: {issueItems.remain_qty}</p>
            </div>
          </div>
        </div>
      </div>

      {issueItemsTableData.length > 0 && (
        <>
          <div>
            <table
              id="issue_items_page_table"
              style={{
                backgroundColor: "white",
                padding: "10px 20px",
                borderRadius: "10px",
                fontFamily: "Inter",
                width: "100%",
                marginTop: "25px",
              }}
            >
              <tr
                style={{
                  textAlign: "left",
                  marginBottom: "10px",
                }}
              >
                <th style={{ padding: "0px" }}>Item Name</th>
                <th style={{ padding: "0px" }}>Item Category</th>
                <th style={{ padding: "0px" }}>Unit</th>
                <th style={{ padding: "0px" }}>Purchase ID</th>
                <th style={{ padding: "0px" }}>Price/Unit</th>
                <th style={{ padding: "0px" }}>Qty</th>
                <th style={{ padding: "0px" }}>Total Amt.</th>
              </tr>

              {issueItemsTableData?.map((item, index) => (
                <tr key={index}>
                  <>
                    <td style={{ padding: "10px 0" }}>{item.item_name}</td>
                    <td style={{ padding: "10px 0" }}>{item.item_category}</td>
                    <td style={{ padding: "10px 0" }}>{item.unit_name}</td>
                    <td style={{ padding: "10px 0" }}>
                      {item.unique_purchase_id}
                    </td>
                    <td style={{ padding: "10px 0" }}>
                      {formatIndianCurrency(item.item_price)}
                    </td>
                    <td style={{ padding: "10px 0" }}>{item.quantity}</td>
                    <td style={{ padding: "10px 0" }}>
                      {formatIndianCurrency(item.total_amount)}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        width: "10px",
                      }}
                    >
                      <div
                        onClick={() => handleDeleteItem(index)}
                        style={{ cursor: "pointer" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.2001 3.2H13.6001C14.0419 3.2 14.4001 3.55817 14.4001 4L14.4001 4.8H1.6001L1.60012 4C1.60012 3.55817 1.95829 3.2 2.40012 3.2H4.80012V1.2C4.80012 0.537258 5.33738 0 6.00012 0H10.0001C10.6629 0 11.2001 0.537259 11.2001 1.2V3.2ZM6.40012 1.6V3.2H9.60012V1.6H6.40012Z"
                            fill="#E03838"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.40012 6.4H13.6001V14.8C13.6001 15.4627 13.0629 16 12.4001 16H3.60012C2.93738 16 2.40012 15.4627 2.40012 14.8V6.4ZM5.6001 8.8H4.0001V13.6H5.6001V8.8ZM8.8001 8.8H7.2001V13.6H8.8001V8.8ZM10.4001 8.8H12.0001V13.6H10.4001V8.8Z"
                            fill="#E03838"
                          />
                        </svg>
                      </div>
                    </td>
                  </>
                </tr>
              ))}
            </table>
          </div>
          <div
            className="d_flex"
            style={{ flexDirection: "column", gap: "10px", paddingTop: "10px" }}
          >
            <div
              className="d_flex"
              style={{ gap: "10px", justifyContent: "end" }}
            >
              <div>
                Total Items: <strong>{totalItems}</strong>
              </div>
              <div>
                Total Items Qty: <strong>{totalQty}</strong>
              </div>
              <div>
                Total Items Amt: <strong>{totalAmt}</strong>
              </div>
            </div>
            <div className="d_flex" style={{ justifyContent: "end" }}>
              <BgThemeButton
                onClick={handleUpdateItem}
                children={"Issue"}
                loader={loader}
              ></BgThemeButton>
            </div>
          </div>
          <CustomPagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={orders.length}
            onPageChange={handlePageChange}
            clearSelection={clearSelection}
          />
        </>
      )}
    </div>
  );
};
export default IssueItemsPage;

{
  /* <tr>
    <td>
        <div style={{ width: "150px" }}>
            <VerticalInputSelectCustomCopy
                options={departmentNameOptions}
            />
        </div>
    </td>
    <td>
        <div style={{ width: "150px" }}>
            <VerticalInputSelectCustomCopy
                options={departmentNameOptions}
            />
        </div>
    </td>
    <td>
        <div style={{ width: "150px" }}>
            <VerticalInputSelectCustomCopy

                defaultValue={"arrival"}
                options={departmentNameOptions}
            />
        </div>
    </td>
    <td>
        <div style={{ width: "150px" }}>
            <VerticalInputSelectCustomCopy

                defaultValue={"arrival"}
                options={departmentNameOptions}
            />
        </div>
    </td>
    <td>
        <div style={{ width: "80px" }}>
            <VerticalInputSelectCustomCopy

                defaultValue={"arrival"}
                options={departmentNameOptions}
            />
        </div>
    </td>
    <td>
        <div style={{ width: "80px" }}>
            <VerticalInputSelectCustomCopy

                defaultValue={"arrival"}
                options={departmentNameOptions}
            />
        </div>
    </td>
    <td>
        <div className="create_booking_editable_input_container">

            <div
                className="common_min_input" style={{ marginTop: '4px' }}
            >
                <input

                ></input>

            </div>
        </div>
    </td>
    <td>
        <div className="create_booking_editable_input_container">

            <div
                className="common_min_input" style={{ marginTop: '4px', background: '#fff' }}
            >
                <input

                ></input>

            </div>
        </div>
    </td>
    <td>
        <div className="create_booking_editable_input_container">

            <div
                className="common_min_input" style={{ marginTop: '4px' }}
            >
                <input

                ></input>

            </div>
        </div>
    </td>
    <td
        style={{
            padding: "10px",
            width: "10px",
        }}
    >
        <div
            style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "30px",
            }}
        >
            <div
                // onClick={() => [getOneDataRoom(tax.id)]}
                style={{ cursor: "pointer", color: '#3968ED' }}
            >
                Done
            </div>
            <div
                // onClick={() => [
                //     showModelMenu(),
                //     setDeletedSingleIds(tax.id),
                // ]}
                style={{ cursor: "pointer" }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.2001 3.2H13.6001C14.0419 3.2 14.4001 3.55817 14.4001 4L14.4001 4.8H1.6001L1.60012 4C1.60012 3.55817 1.95829 3.2 2.40012 3.2H4.80012V1.2C4.80012 0.537258 5.33738 0 6.00012 0H10.0001C10.6629 0 11.2001 0.537259 11.2001 1.2V3.2ZM6.40012 1.6V3.2H9.60012V1.6H6.40012Z"
                        fill="#E03838"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.40012 6.4H13.6001V14.8C13.6001 15.4627 13.0629 16 12.4001 16H3.60012C2.93738 16 2.40012 15.4627 2.40012 14.8V6.4ZM5.6001 8.8H4.0001V13.6H5.6001V8.8ZM8.8001 8.8H7.2001V13.6H8.8001V8.8ZM10.4001 8.8H12.0001V13.6H10.4001V8.8Z"
                        fill="#E03838"
                    />
                </svg>
            </div>
        </div>
    </td>
</tr>  */
}

// const handleEditInputChange = (e, index) => {
//     const { name, value } = e.target;

//     const updatedItem = { ...issueItemsTableData[index] };

//     updatedItem[name] = value;

//     if (name === "item_price" || name === "qty") {
//         const pricePerUnit = parseFloat(updatedItem.item_price);
//         const qty = parseFloat(updatedItem.qty);
//         updatedItem.total_amount = isNaN(pricePerUnit) || isNaN(qty) ? "" : (pricePerUnit * qty).toString();
//     }

//     const updatedData = [...issueItemsTableData];

//     updatedData[index] = updatedItem;

//     setIssueItemsTableData(updatedData);
// };

//   const handleDoneItem = (e, index) => {
//     setEditingIndex(null);
// };
