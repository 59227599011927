import {
  TextField,
  IndexTable,
  LegacyCard,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Badge,
  Avatar,
  Button,
  Icon,
  Select,
  Checkbox,
  Page,
  LegacyStack,
  Collapsible,
} from "@shopify/polaris";

import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import logo from "../assets/images/room_type.png";
import { Link, useNavigate } from "react-router-dom";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  WithoutBgButtonBlue,
} from "./UI/Buttons";

import { ChevronDownMinor, NoteMinor } from "@shopify/polaris-icons";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import { useSelector } from "react-redux";
import NoDataFound from "./CustomComponents/NoDataFound";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import AddMenuCategory from "./UI/AddMenuCategory";
import Loader from "./UI/Loader/Loader";
import AddBanquetMenuCategories from "./UI/AddBanquetMenuCategories";
import { getDecryptedData } from "../utils/encryptStorage";

const BanquetFoodMenus = () => {
  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);
  const [orders, setOrders] = useState([]);
  const [menuCategories, setMenuCategories] = useState([]);

  const navigate = useNavigate();

  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showMenuCategory, setShowMenuCategory] = useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddStockModelMenuActive, setShowAddStockModelMenuActive] =
    useState(false);

  const [showAddItemModelMenuActive, setShowAddItemModelMenuActive] =
    useState(false);

  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);

  const [deletedSingleIds, setDeletedSingleIds] = useState([]);
  const [editSingleIds, setEditdSingleIds] = useState([]);

  const [menuItems, setMenuItems] = useState({
    property_id: property_id?.id,
    banquet_menu_category: "",
    banquet_menu_name: "",
    // item_price: "",
  });

  // const handleChange = useCallback((newValue) => setValue(newValue), []);

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  const [openAccordions, setOpenAccordions] = useState(Array(9).fill(false));

  const handleToggle = (index) => {
    const updatedAccordions = [...openAccordions];
    updatedAccordions[index] = !updatedAccordions[index];
    setOpenAccordions(updatedAccordions);
  };

  const [selected, setSelected] = useState(0);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(orders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      // getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const addModalButtonClickHandler = () => {
    setShowAddStockModelMenuActive(!showAddStockModelMenuActive);
  };

  const editModalButtonClickHandler = () => {
    setShowEditModelMenuActive(!showEditModelMenuActive);
  };

  const removeAddModalContainer = () => {
    setShowAddStockModelMenuActive(!showAddStockModelMenuActive);
  };

  const getItemChecked = (id) => {};

  getItemChecked(selectedResources[0]);

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const setItemsDataHandleChange = (event) => {
    setMenuItems({
      ...menuItems,
      [event.target.name]: event.target.value,
    });
  };

  const removeItemConfirm = async () => {
    const deletedIdsQ = {
      ids: deletedSingleIds,
    };
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/banquet/menus/delete/${property_id?.id}`,
        {
          method: "DELETE",
          body: JSON.stringify(deletedIdsQ),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        showSuccessToast(data.message);
        clearSelection();
        setShowModelMenuActive(false);
        setLoader(false);
      } else {
        showErrorToast(data.message);
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
      setLoader(false);
    }
  };

  const fetchInfo = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/banquet/menus/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data.menu);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const handleSubmitAddItem = async () => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/banquet/create/menus/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({
            ...menuItems,
            property_id: property_id?.id,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        setShowAddItemModelMenuActive(false);
        // clearSelection();
        showSuccessToast(data.message);
        setMenuItems({
          banquet_menu_name: "",
          banquet_menu_category: "",
          // item_price: "",
        });
        fetchInfo(property_id?.id);
        setLoader(false);
        // window.location.reload()
      } else {
        showErrorToast(data.message);
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
      setLoader(false);
    }
  };

  const getOneData = async (id) => {
    setShowEditModelMenuActive(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/banquet/menus/edit/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        property_id: property_id?.id,
        banquet_menu_name: data.menu.banquet_menu_name,
        banquet_menu_category: data.menu.banquet_menu_category,
        item_price: data.menu.item_price,
      };
      setMenuItems(updatedData);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const handleUpdateItem = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/banquet/menus/update/${editSingleIds}/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({ ...menuItems }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        setShowEditModelMenuActive(false);
        clearSelection();
        showSuccessToast(data.message);
        setMenuItems({
          property_id: property_id?.id,
          item_name: "",
          item_category: "",
          item_price: "",
        });
        setLoader(false);
        // window.location.reload()
      } else {
        showErrorToast(data.message);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchMenuCategories = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/banquet/menus/categories/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setMenuCategories(data.menue_categories);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
      fetchMenuCategories(property_id?.id);
    }
  }, [property_id]);

  return (
    <>
      <div className="room-types-full-container">
        <div className="room-types-full-container-div">
          <div className="room-type-heading">
            <div style={{ marginLeft: "-1.5rem" }}>
              <Page
                backAction={{
                  content: "Products",
                  url: `/banquethall`,
                }}
                title="Banquet Menu Items"
                compactTitle
              ></Page>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <div>
              {selectedResources.length > 0 && (
                <div>
                  <Button plain destructive onClick={showModelMenu}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        <Icon source={DeleteMajor} color="base" />
                      </span>
                      <span>Remove</span>
                    </div>
                  </Button>
                </div>
              )}
            </div>
            <div>
              <BgOutlineThemeButton
                children={"Categories"}
                onClick={() => setShowMenuCategory(!showMenuCategory)}
              />
            </div>
            <div style={{ textDecoration: "none" }}>
              <BgThemeButton
                onClick={() =>
                  setShowAddItemModelMenuActive(!showAddItemModelMenuActive)
                }
                children={"Add Banquet Menu"}
              />
            </div>
          </div>
        </div>

        {loader ? (
          <Loader />
        ) : (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              flexDirection: "column",
            }}
          >
            {orders.length > 0 ? (
              <>
                {orders.map((item, index) => (
                  <div key={index} className="banquet_menu_main">
                    <LegacyCard sectioned>
                      <LegacyStack vertical>
                        <div
                          onClick={() => handleToggle(index)}
                          aria-expanded={openAccordions[index]}
                          aria-controls={`basic-collapsible-${index}`}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            fontSize: "14px",
                            cursor: "pointer",
                            display: "flex",
                            padding: "16px",
                          }}
                        >
                          <div>{item.item_category}</div>
                          <div>
                            <Icon source={ChevronDownMinor} />
                          </div>
                        </div>
                        <Collapsible
                          open={openAccordions[index]}
                          id={`basic-collapsible-${index}`}
                          transition={{
                            duration: "300ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                          {orders && orders.length > 0 ? (
                            <IndexTable
                              selectable={false}
                              resourceName={resourceName}
                              itemCount={item.menu_items.length}
                              selectedItemsCount={
                                allResourcesSelected
                                  ? "All"
                                  : selectedResources.length
                              }
                              onSelectionChange={handleSelectionChange}
                              headings={[
                                { title: "Item Name" },
                                // { title: "Item Category" },
                                // { title: "Item Price" },
                                { title: "" },
                                { title: "" },
                              ]}
                            >
                              {/* {rowMarkup} */}
                              {item.menu_items.map(
                                ({
                                  id,
                                  banquet_menu_name,
                                  banquet_menu_category,
                                  item_price,
                                  index,
                                }) => (
                                  <IndexTable.Row
                                    id={id}
                                    key={id}
                                    selected={selectedResources.includes(id)}
                                    position={index}
                                  >
                                    <IndexTable.Cell>
                                      {banquet_menu_name}
                                    </IndexTable.Cell>
                                    {/* <IndexTable.Cell>
                                    {banquet_menu_category}
                                  </IndexTable.Cell> */}
                                    <IndexTable.Cell>
                                      {/* {item_price} */}
                                    </IndexTable.Cell>
                                    <IndexTable.Cell>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          gap: "50px",
                                        }}
                                      >
                                        <div
                                          onClick={() => [
                                            getOneData(id),
                                            setEditdSingleIds([id]),
                                          ]}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                          >
                                            <path
                                              d="M11.3919 1.98174L14.0182 4.60884L14.4562 4.1707C15.1813 3.44536 15.1813 2.26952 14.4562 1.54418C13.7308 0.818608 12.5547 0.818608 11.8294 1.54418L11.3919 1.98174Z"
                                              fill="#3968ED"
                                            />
                                            <path
                                              d="M12.8124 5.81229L10.1861 3.18518L1.85249 11.5201L1 15L4.47873 14.1472L12.8124 5.81229Z"
                                              fill="#3968ED"
                                            />
                                          </svg>
                                        </div>
                                        <div
                                          style={{ cursor: "pointer" }}
                                          onClick={() => [
                                            showModelMenu(),
                                            setDeletedSingleIds([id]),
                                          ]}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M11.2001 3.2H13.6001C14.0419 3.2 14.4001 3.55817 14.4001 4L14.4001 4.8H1.6001L1.60012 4C1.60012 3.55817 1.95829 3.2 2.40012 3.2H4.80012V1.2C4.80012 0.537258 5.33738 0 6.00012 0H10.0001C10.6629 0 11.2001 0.537259 11.2001 1.2V3.2ZM6.40012 1.6V3.2H9.60012V1.6H6.40012Z"
                                              fill="#E03838"
                                            />
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M2.40012 6.4H13.6001V14.8C13.6001 15.4627 13.0629 16 12.4001 16H3.60012C2.93738 16 2.40012 15.4627 2.40012 14.8V6.4ZM5.6001 8.8H4.0001V13.6H5.6001V8.8ZM8.8001 8.8H7.2001V13.6H8.8001V8.8ZM10.4001 8.8H12.0001V13.6H10.4001V8.8Z"
                                              fill="#E03838"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                    </IndexTable.Cell>
                                  </IndexTable.Row>
                                )
                              )}
                            </IndexTable>
                          ) : (
                            <NoDataFound />
                          )}
                        </Collapsible>
                      </LegacyStack>
                    </LegacyCard>
                  </div>
                ))}
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
        )}
      </div>

      {/* Add stock Button Click */}
      {showAddStockModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="stock-add-model-container">
              <div style={{ marginLeft: "-200px" }}>
                <Page
                  backAction={{
                    content: "Products",
                    url: `/hotelier/purchase`,
                  }}
                  title="Add Issue Items"
                  compactTitle
                ></Page>
              </div>

              <div className="form-container-div-half">
                <div className="form-container-div">
                  <TextField
                    type="text"
                    className="textfield"
                    focused={false}
                    label="User Type"
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    type="text"
                    className="textfield"
                    focused={false}
                    label="Issued To"
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    type="date"
                    className="textfield"
                    focused={false}
                    label="Issued Date"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="form-container-div-half">
                <div className="form-container-div">
                  <Select
                    className="textfield"
                    focused={false}
                    label="Item Name"
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <Select
                    className="textfield"
                    focused={false}
                    label="Select Category"
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    className="textfield"
                    focused={false}
                    label="Qty"
                    autoComplete="off"
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                    width: "350px",
                  }}
                  className="form-container-div"
                >
                  <WithoutBgButtonBlue children={"Add More"} />
                </div>
              </div>
              <div className="form-container-div-half">
                <div className="form-container-div">
                  <Select
                    className="textfield"
                    focused={false}
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <Select
                    className="textfield"
                    focused={false}
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    className="textfield"
                    focused={false}
                    autoComplete="off"
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "350px",
                  }}
                  className="form-container-div"
                >
                  <WithoutBgButtonBlue children={"Add More"} />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <BgThemeButton children={"Update"} />
                <Button onClick={() => setShowAddItemModelMenuActive(false)}>
                  Cancel
                </Button>
              </div>
            </section>
          </div>
        </>
      )}

      {/* Add Item Button Click */}
      {showAddItemModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <form onSubmit={(e) => e.preventDefault()}>
              <section
                style={{ width: "700px" }}
                className="stock-add-model-container"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <div className="add-ota-model-container-heading">
                    Add Banquet Menu
                  </div>
                  <div
                    onClick={() => setShowAddItemModelMenuActive(false)}
                    style={{ cursor: "pointer" }}
                  >
                    <Icon source={CancelMajor} color="base" />
                  </div>
                </div>

                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      type={"text"}
                      name={"banquet_menu_name"}
                      className="textfield"
                      focused={false}
                      titleName="Item Name"
                      onChange={setItemsDataHandleChange}
                      value={menuItems.banquet_menu_name}
                    />
                  </div>
                  <div className="form-container-div">
                    <div style={{ marginBottom: "4px" }}>
                      Item Category
                      <span style={{ color: "red" }}>*</span>
                    </div>
                    {menuCategories.length > 0 ? (
                      <select
                        style={{
                          width: "100%",
                          minHeight: "2.25rem",
                          border: "1px solid rgba(171, 177, 186, 1)",
                          borderRadius: "3px",
                        }}
                        required="true"
                        name="banquet_menu_category"
                        onChange={setItemsDataHandleChange}
                      >
                        <option value="">Select Option</option>
                        {menuCategories?.map((x) => {
                          return (
                            <>
                              <option key={x.id} value={x.id}>
                                {x.category_name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    ) : (
                      <div style={{ marginTop: "10px" }}>
                        Please add item categories first.
                      </div>
                    )}
                  </div>
                  {/* <div style={{ width: "30%" }} className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      type={"number"}
                      name={"item_price"}
                      className="textfield"
                      focused={false}
                      titleName="Item Price"
                      onChange={setItemsDataHandleChange}
                      value={menuItems.item_price}
                    />
                  </div> */}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      gap: "10px",
                    }}
                  >
                    <BgThemeButton
                      children={"Add"}
                      onClick={(e) => handleSubmitAddItem(e)}
                    />
                    <Button
                      onClick={() => setShowAddItemModelMenuActive(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </section>
            </form>
          </div>
        </>
      )}

      {/* Edit Button Click */}
      {showEditModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section
              style={{ width: "700px" }}
              className="stock-add-model-container"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <div className="add-ota-model-container-heading">
                  Edit Banquet Menu
                </div>
                <div
                  onClick={() => {
                    setShowEditModelMenuActive(false);
                    clearSelection();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Icon source={CancelMajor} color="base" />
                </div>
              </div>

              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    type={"text"}
                    name={"banquet_menu_name"}
                    className="textfield"
                    focused={false}
                    titleName="Item Name"
                    onChange={setItemsDataHandleChange}
                    value={menuItems.banquet_menu_name}
                  />
                </div>

                <div className="form-container-div">
                  <div style={{ marginBottom: "4px" }}>
                    Item Category
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <select
                    style={{
                      width: "100%",
                      minHeight: "2.25rem",
                      border: "1px solid rgba(171, 177, 186, 1)",
                      borderRadius: "3px",
                    }}
                    required="true"
                    name="banquet_menu_category"
                    onChange={setItemsDataHandleChange}
                    value={menuItems.banquet_menu_category}
                  >
                    <option value="">Select Option</option>
                    {menuCategories?.map((x) => {
                      return (
                        <>
                          <option key={x.id} value={x.id}>
                            {x.category_name}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    gap: "10px",
                  }}
                >
                  <BgThemeButton
                    children={"Update"}
                    onClick={() => handleUpdateItem(selectedResources[0])}
                  />
                  <Button
                    onClick={() => {
                      setShowEditModelMenuActive(false);
                      clearSelection();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </section>
          </div>
        </>
      )}

      {showModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="room-type-model-container ">
              <h4>Are You Sure?</h4>
              <p>You Want To Remove?</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={() => {
                    setShowModelMenuActive(!showModelMenuActive);
                    clearSelection();
                  }}
                >
                  Discard
                </Button>
                <Button onClick={removeItemConfirm} destructive>
                  Yes, Remove
                </Button>
              </div>
            </section>
          </div>
        </>
      )}

      {showMenuCategory && (
        <AddBanquetMenuCategories setShoeMenuCategory={showMenuCategory} />
      )}
    </>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "moneySpent":
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case "taggedWith":
        return `Tagged with ${value}`;
      case "accountStatus":
        return value.map((val) => `Customer ${val}`).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};
export default BanquetFoodMenus;
