import React, { useEffect } from "react";
import RoomPlans from "../Components/RoomPlans";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import RoomPlansCommon from "../Components/RoomPlansCommon";

const RoomsPlanPage = () => {

  useEffect(() => {
    // Set the meta title
    document.title = 'Rooms Plan - RevCatalyst - Boosting Your Hotel Performance';

    // Create or update the meta description tag
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement('meta');
      metaDescription.name = 'description';
      document.head.appendChild(metaDescription);
    }
    metaDescription.content = "Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property.";
  }, []);
  
  const activeApp =
  useSelector((state) => state.activeApp.activeApp) ||
  sessionStorage.getItem("activeApp");

  return (
    <>
     
        {
          activeApp ==="pms" && ( <div className="main_container"><RoomPlans /></div>)
        }
        {
          activeApp ==="booking_engine" && <RoomPlansCommon  />
        }
      
    </>
  );
};

export default RoomsPlanPage;
