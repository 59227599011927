import React from "react";
import KotBill from "../Components/KotBill";

const KotBillPage = () => {
    return (
        <>
            <div className="main_container">
                <KotBill />
            </div>
        </>
    );
};

export default KotBillPage;
