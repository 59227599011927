import React from "react";
import Folio from "../Components/Folio/Folio";
import FolioLog from "../Components/Folio/FolioLog";

const FolioLogsPage = () => {
  return (
    <>
      {/* <div className="main_container"> */}
      <div style={{ margin: "0", padding: "70px 0" }}>
        <FolioLog />
      </div>
      {/* </div> */}
    </>
  );
};

export default FolioLogsPage;
