import React from "react";
import Sidebar from "../Components/Sidebar";
import Agent from "../Components/Agent";
import Agent2 from "../Components/Agent2";


const AgentSinglePage = () => {
  return (
    <>
      <div className="main_container">
        <Agent2 />
      </div>
    </>
  );
};

export default AgentSinglePage;
