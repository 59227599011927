
import { IndexTable, LegacyCard, Tooltip } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import { formatIndianCurrency } from "../../utils/utils";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";

const ReportMonthlySalesReport = ({ departureReport, loader }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const rowMarkup = departureReport?.map(
    ({
      id,
      Bill_Date,
      Bill_No,
      Folio_No,
      Guest_Name,
      Meal_Plan,
      Room_Rent_Taxable_Amt,
      Room_Rent_CGST,
      Room_Rent_SGST,
      Room_GST_Rate,

      Food_Bill_Amt,
      Food_GST,
      Food_CGST,
      Food_SGST,
    }) => (
      <CustomReportTableRow
        CustomReportTableRow
        id={id}
        key={id}
        // bodyStyles={{
        //   backgroundColor: "red",
        //   fontSize: "10px",
        // }}
      >
        <CustomReportTableCell>{Bill_Date}</CustomReportTableCell>
        <CustomReportTableCell>{Bill_No}</CustomReportTableCell>
        <CustomReportTableCell>{Folio_No}</CustomReportTableCell>
        <CustomReportTableCell>{Guest_Name}</CustomReportTableCell>
        <CustomReportTableCell>
          {Meal_Plan.length > 10 ? (
            <>
              <Tooltip content={Meal_Plan}>
                <div>{Meal_Plan.slice(0, 10) + "...."}</div>
              </Tooltip>
            </>
          ) : (
            <>{Meal_Plan}</>
          )}
        </CustomReportTableCell>
        <CustomReportTableCell>{Room_Rent_Taxable_Amt}</CustomReportTableCell>
        <CustomReportTableCell>{+Room_GST_Rate}%</CustomReportTableCell>
        <CustomReportTableCell>{Room_Rent_CGST}</CustomReportTableCell>
        <CustomReportTableCell>{Room_Rent_SGST}</CustomReportTableCell>
        <CustomReportTableCell>{Food_Bill_Amt}</CustomReportTableCell>
        <CustomReportTableCell>{+Food_GST}%</CustomReportTableCell>
        <CustomReportTableCell>{Food_CGST}</CustomReportTableCell>
        <CustomReportTableCell>{Food_SGST}</CustomReportTableCell>
      </CustomReportTableRow>
    )
  );

 

  const names = [
    {
      agent_id: "Agent ID",
      company_name: "Name",
      current_date_amount: "Amount",
      current_date_nights: "Nights",
      mtd_amount: "MTD Amt.",
      mtd_nights: "MTD Nts.",
      ytd_amount: "YTD Amt.",
      ytd_nights: "YTD Nts.",
    },
  ];

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="room-types-full-container"  id="receipt">
          <div className="room-types-full-container-div" >
            <div className="room-type-heading">Sales Report </div>

            <div>
              {departureReport.length > 0 && (
                <div className="flex flex_gap_15" id={"ignoreFromPrint"}>
                  <ButtonToDownloadTablePDF
                    itemToDownloadID={"receipt"}
                    downloadFileName={"sales report"}
                    ignoreFromPrint={"ignoreFromPrint"}
                    pdfMargins={[0.2, 0.2, 0.2, 0.2]}
                    pdfOrientation={"landscape"}

                  />
                  <DownloadCSVButton  downloadFileName={"sales report"} itemToDownloadID={"receipt"} />
                </div>
              )}
            </div>
          </div>

          {/* {departureReport && departureReport.length > 0 ? (
            <LegacyCard>
              <IndexTable
                resourceName={resourceName}
                itemCount={departureReport.length}
                selectable={false}
                headings={[
                  { title: "Charge Type" },
                  { title: "Subtotal" },
                  { title: "Tax Rate" },
                  { title: "CGST" },
                  { title: "SGST" },
                  { title: "Tax Amount" },
                  { title: "Grand Total" },
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </LegacyCard>
          ) : (
            <NoDataFound />
          )} */}
          {departureReport && departureReport.length > 0 ? (
            <>
              <div>
                <CustomReportTable
                  headings={[
                    "Date",
                    "Invoice/Bill No.",
                    "Folio No.",
                    "Guest Name",
                    "Meal Plan",
                    "Taxable Amt.",
                    "Tax Rate",
                    "CGST",
                    "SGST",
                    "Taxable Amt.",
                    "Tax Rate",
                    "CGST",
                    "SGST",
                  ]}
                  commonHeadings={[
                    "",
                    "",
                    "",
                    "",
                    "",
                    "Room Sale",
                    "Room Sale",
                    "Room Sale",
                    "Room Sale",
                    "Food Sale",
                    "Food Sale",
                    "Food Sale",
                    "Food Sale",
                  ]}
                  headerStyles={{
                    backgroundColor: "#dddddd",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                  tableStyles={{textAlign:"left"}}
                >
                  {rowMarkup}
                </CustomReportTable>
              </div>
            </>
          ) : (
            <NoDataFound />
          )}
          {/* {departureReport &&
                departureReport.length > 0 &&
                departureReport.map((order, ke  y) => {
                  return (
                    <div key={key} className="mt-20">
                      <div
                        style={{ marginBottom: "10px" }}
                        className="flex flex_gap_10"
                      >
                        <div>
                          {" "}
                          <strong>Guest Name</strong> : {order.guest_name}
                        </div>
                        <div>
                          <strong>Bill No </strong> : {order.billno}
                        </div>
                      </div>

                      <LegacyCard>
                        <IndexTable
                          resourceName={resourceName}
                          itemCount={order.summary.length}
                          selectable={false}
                          headings={[
                            { title: "Charge Type" },
                            { title: "Subtotal" },
                            { title: "Tax Rate" },
                            { title: "CGST" },
                            { title: "SGST" },
                            { title: "Tax Amount" },
                            { title: "Grand Total" },
                          ]}
                        >
                          <>
                            {order.summary.map((order) => (
                              <>
                                <IndexTable.Row
                                  id={order.id}
                                  key={order.id}
                                  position={order.index}
                                >
                                  <IndexTable.Cell>
                                    <div style={{ width: "100px" }}>
                                      <UnderscoreRemoveCapitalize
                                        text={order.charge_type}
                                      />
                                    </div>
                                  </IndexTable.Cell>
                                  <IndexTable.Cell>
                                    {formatIndianCurrency(
                                      order.total_room_total
                                    )}
                                  </IndexTable.Cell>
                                  <IndexTable.Cell>
                                    {order.tax_rate} %
                                  </IndexTable.Cell>
                                  <IndexTable.Cell>
                                    {formatIndianCurrency(order.total_cgst)}
                                  </IndexTable.Cell>
                                  <IndexTable.Cell>
                                    {formatIndianCurrency(order.total_sgst)}
                                  </IndexTable.Cell>
                                  <IndexTable.Cell>
                                    {formatIndianCurrency(
                                      order.total_tax_amount
                                    )}
                                  </IndexTable.Cell>
                                  <IndexTable.Cell>
                                    {formatIndianCurrency(
                                      order.total_net_total
                                    )}
                                  </IndexTable.Cell>
                                </IndexTable.Row>
                              </>
                            ))}
                         
                          </>
                        </IndexTable>
                      </LegacyCard>
                    </div>
                  );
                })}
            </div>
          ) : (
            <NoDataFound />
          )} */}
        </div>

        // </div>
      )}
    </>
  );
};
export default ReportMonthlySalesReport;