import React from 'react';
import './SkeletonLoadingTable.css'; // Import the CSS file for custom styles

const SkeletonLoadingTable = () => {
  const rows = 2; // Number of skeleton rows to display

  return (
    <table className="skeleton-table">
      <thead>
        <tr>
          {Array.from({ length: 7 }).map((_, index) => (
            <th key={index} className=' '>
              <div className="skeleton skeleton-text"></div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: rows }).map((_, rowIndex) => (
          <tr key={rowIndex} className=''>
            {Array.from({ length: 7 }).map((_, cellIndex) => (
              <td key={cellIndex} >
                <div className="skeleton skeleton-rect"></div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SkeletonLoadingTable;