import React, { useEffect, useState } from "react";
import { formatIndianCurrency } from "../../../utils/utils";

const RoundOffNumber = ({ totalAmount, onRoundOffChange }) => {
    const [rounded, setRounded] = useState(null);
    const [roundOffValue, setRoundOffValue] = useState(null);

    useEffect(() => {
        onRoundOffChange(rounded, roundOffValue);
    }, [rounded, onRoundOffChange]);

    const handleRoundChange = (event) => {
        const direction = event.target.value;
        const decimalPart = totalAmount % 1;

        if (direction === "") {
            // Reset the rounded value and roundOffValue
            setRounded(totalAmount);
            setRoundOffValue(null);
        } else if (direction === "plus") {
            if (decimalPart > 0) {
                setRounded(Math.ceil(totalAmount));
                setRoundOffValue(`+${(Math.ceil(totalAmount) - totalAmount).toFixed(2)}`);
            } else {
                setRounded(totalAmount);
                setRoundOffValue(null);
            }
        } else if (direction === "minus") {
            if (decimalPart > 0) {
                setRounded(Math.floor(totalAmount));
                setRoundOffValue(`-${(totalAmount - Math.floor(totalAmount)).toFixed(2)}`);
            } else {
                setRounded(totalAmount);
                setRoundOffValue(null);
            }
        }
    };

    return (
        <div className="flex" style={{ height: '100%' }}>
            {/* <h1>{totalAmount}</h1> */}
            <select onChange={handleRoundChange} style={{ width: '100px' }}>
                <option value="">Option</option>
                <option value="plus">Round Up</option>
                <option value="minus">Round Down</option>
            </select>
            <p style={{ marginLeft: '5px' }}>  {roundOffValue}</p>
            {/* <p style={{marginLeft: '5px'}}> Rounded off <strong>{formatIndianCurrency(rounded)}</strong></p> */}
        </div>
    );
};

export default RoundOffNumber;