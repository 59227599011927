import React from "react";
import Sidebar from "../Components/Sidebar";
import Dashboard from "../Components/Dashboard";
import Dashboard2 from "../Components/Dashboard2";

import CompetitiveAnalysis from "../Components/CompetitiveAnalysis";

const CompetitiveAnalysisPage = () => {
  return (
    <>
      <div className="main_container">
        <CompetitiveAnalysis />
      </div>
    </>
  );
};

export default CompetitiveAnalysisPage;
