// Polaris Imports
import {
    IndexTable,
    useIndexResourceState,
} from "@shopify/polaris";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Chart, ArcElement } from "chart.js";
import { LegacyCard } from "@shopify/polaris";
import { useSelector } from "react-redux";
import { showErrorToast } from "../../../assets/toastUtils";
import Loader from "../../UI/Loader/Loader";
import "./StoreDashboard.css";
import NoDataFound from "../../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../../CustomComponents/CustomCssComponentTableText";
import GoBackButtonCustom from "../../GoBackButtonCustom/GoBackButtonCustom";
import { getDecryptedData } from "../../../utils/encryptStorage";

const StoreOutofStock = () => {
    const userToken = getDecryptedData('encryptToken');
    const property_id = useSelector((state) => state.property.value);
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const resourceName = {
        singular: "allsettlement",
        plural: "allsettlements",
    };

    const {
        selectedResources,
        allResourcesSelected,
        handleSelectionChange,
        clearSelection,
    } = useIndexResourceState();

    // Fetch the room type click data
    const [roomTypeData, setRoomTypeData] = useState([]);
    const [ourStoresData, setOurStoresData] = useState([]);
    const [outOfStockData, setOutOfStockData] = useState([]);
    const [purchasedAndIssuedData, setPurchasedAndIssuedData] = useState([]);

    const fetchStores = async (id) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/get/our/store/${id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            const data = await response.json();
            if (data.success) {
                setOurStoresData(data.data);
                setOutOfStockData(data.out_of_stoke);
                setPurchasedAndIssuedData(data.total_data);
            } else {
                showErrorToast(data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        if (property_id) {
            fetchStores(property_id.id);
        }
    }, [property_id]);


    const rowMarkup = outOfStockData?.map(
        ({
            id,
            item_name,
            category_name,
            item_weight,
            weight_unit,
            index,
        }) => (
            <IndexTable.Row
                id={id}
                key={id}
                selected={selectedResources.includes(id)}
                position={index}
            >
                <IndexTable.Cell>
                    <CustomCssComponentTableText
                        innerText={item_name}
                    ></CustomCssComponentTableText>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <CustomCssComponentTableText
                        innerText={category_name}
                    ></CustomCssComponentTableText>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <CustomCssComponentTableText
                        innerText={item_weight}
                    ></CustomCssComponentTableText>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <CustomCssComponentTableText
                        innerText={weight_unit}
                    ></CustomCssComponentTableText>
                </IndexTable.Cell>
            </IndexTable.Row>
        )
    );

    return (
        <>
            <div className="main_container">
                <div className="mb-10 mt-20">
                    <GoBackButtonCustom
                        buttonNextText={"Out of Stock"}
                        onClick={() => navigate("/store/purchase")}
                    />
                </div>
                {loader ? (
                    <Loader />
                ) : (
                    <>

                        <section className="dashboard_page">

                            <div
                                className="dashboard_page_right"
                                style={{ margin: "0", width: "100%" }}
                            >
                                <div
                                    className=""
                                    style={{ background: "#fff", borderRadius: "10px" }}
                                >
                                    <div className="flex flex-gap-10 " style={{ padding: '16px' }}>
                                        <div className="dashboard-heading">Out of Stock</div>
                                    </div>
                                    {outOfStockData && outOfStockData.length > 0 ? (
                                        <LegacyCard>
                                            <IndexTable
                                                selectable={false}
                                                resourceName={resourceName}
                                                itemCount={outOfStockData.length}
                                                selectedItemsCount={
                                                    allResourcesSelected ? "All" : selectedResources.length
                                                }
                                                onSelectionChange={handleSelectionChange}
                                                headings={[
                                                    { title: "Item Name" },
                                                    { title: "Category" },
                                                    { title: "Qty" },
                                                    { title: "Unit" },
                                                ]}
                                            >
                                                {rowMarkup}
                                            </IndexTable>
                                        </LegacyCard>
                                    ) : (
                                        <NoDataFound />
                                    )}
                                </div>
                            </div>
                        </section>
                    </>
                )}
            </div>
        </>
    );
};

export default StoreOutofStock;
