import React from "react";
import CustomerSingle from "../Components/CustomerSingle";

const CustomerSinglePage = () => {
    return (
        <>
            <div className="main_container">
                <CustomerSingle />
            </div>
        </>
    );
};

export default CustomerSinglePage;
