import React, { useEffect, useState } from "react";
import "./BookingSoldSlider.css";
import NoDataFound from "../NoDataFound";
import TextDateFormatter from "../../Communication/TextDateFormatter/TextDateFormatter";
import BookingDetails from "../BookingDetails/BookingDetails";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { useSelector } from "react-redux";
import BookingStatusUpdate from "../BookingStatusUpdate";
import TruncatedText from "../TruncatedText/TruncatedText";
import BookingDetailsSlider from "./BookingDetailsSlider";
import BookingDetailSliderNew from "../BookingDetails/BookingDetailsSliderNew";
import { BgOutlineThemeButton, WithoutBgButtonBlue } from "../../UI/Buttons";
import DeletePopupWithReason from "../DeletePopup/DeletePopupWithReason";
import { useNavigate } from "react-router-dom";
import { getDecryptedData } from "../../../utils/encryptStorage";

const InventorySliderComponent = ({
  roomId,
  date,
  totalBookingData,
  setShowTotalBookings,
  inventorySelectedRoomName,
  inventorySelectedDate,
  refreshUrl,
  fetchSoldRoomsData,
  fetchInfo,
  showTotalBookings,
}) => {
  const navigate = useNavigate();
  const [showBookingDetails, setShowBookingDetails] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(false);
  const [activeFilter, setActiveFilter] = useState(1);

  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);

  const submitBookingData = (bookingId) => {
    // e.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${bookingId}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setShowBookingDetails(true);
          setBookingDetails(data.booking);
          // setShowTotalBookings(false);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  const NoOfRooms = totalBookingData?.bookings?.items?.map((room) => {
    return room.room_no;
  });

  // const [filteredData, setFilteredData] = useState(null);
  // const desiredRoomId = 11;

  // const data = totalBookingData?.bookings[1]?.items;
  // const filtered =
  //   roomId &&
  //   totalBookingData?.bookings[0]?.items?.find(
  //     (entry) => entry.room_id === roomId
  //   );

  const [masterFolioDetails, setMasterFolioDetails] = useState(null);
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false);
  const [singleData, setSingleData] = useState(false);

  const [openSingleBookingDetails, setOpenSingleBookingDetails] =
    useState(false);

  const [chargesDetails, setChargesDetails] = useState(null);

  const getSingleBookingDataHandler = (bookingId) => {
    // setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${bookingId}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setOpenSingleBookingDetails(true);
          // setLoader(false);
          setBookingDetails(data?.booking);
          setChargesDetails(data?.charges);
          setMasterFolioDetails(data?.masterFolio);
        } else {
          // setLoader(false);
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        // setLoader(false);
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const [reasonFromChild, setReasonFromChild] = useState("");
  const [deleteKotId, setDeleteKotId] = useState("");

  const handleDeleteWithReason = (reason) => {
    setReasonFromChild(reason);
  };

  const handleCancelStatusButton = async (e) => {
    const dataSend = {
      from_date: singleData.check_in,
      to_date: singleData.check_out,
      id: singleData.hold_inventory_id,
      hold_rooms: singleData.no_of_rooms,
      room_id: singleData.room_id,
    };

    const response = await fetch(
      
      `${process.env.REACT_APP_BASE_URL}/api/v1/release/hold/inventory/${property_id?.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(dataSend),
      }
    );
    const data = await response
      .json()
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          // fetchSoldRoomsData(
          //   singleData.room_id,
          //   inventorySelectedDate,
          //   property_id?.id,
          //   inventorySelectedRoomName,
          //   inventorySelectedDate
          // );
          fetchInfo(property_id?.id);
          setShowTotalBookings(false);
          // fetchSoldRoomsData(
          //   data.room_id,
          //   data.date,
          //   property_id?.id,
          //   data?.room_name,
          //   data?.date
          // )
          // addModalButtonClickHandler();
          // setBlockShowAddModelMenuActive(false);
          // setShowAddModelMenuActive(false);
          // setUnblockShowAddModelMenuActive(false);
          // fetchInfo(property_id?.id);
          // setInventoriesOptionShowAddModelMenuActive(false);
        } else {
          showErrorToast(data.message);
          console.log("Post request failed with status:", response.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleBookRoomClick = async (booking) => {
    if (activeFilter === 2) {
      navigate(`/agent-hold-rooms/create-booking/${booking.hold_inventory_id}`);
    } else if (activeFilter === 3) {
      navigate(`/agent-contracts/create-booking/${booking.hold_inventory_id}`);
    }
  };

  return (
    <>
      <div>
        <div onClick={(e) => e.stopPropagation()}>
          {/* <div className="totalBookingsHr" /> */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #ccc",
            }}
          >
            <div
              className="pointer"
              onClick={() => setActiveFilter(1)}
              style={{
                width: "50%",
                textAlign: "center",
                padding: "10px",
                borderBottom:
                  activeFilter === 1
                    ? "2px solid rgba(57, 104, 237, 1)"
                    : "none",
                color: activeFilter === 1 ? "rgba(57, 104, 237, 1)" : "#000",
                fontSize: "14px",
              }}
            >
              Sold
            </div>
            <div
              className="pointer"
              onClick={() => setActiveFilter(2)}
              style={{
                width: "50%",
                textAlign: "center",
                padding: "10px",
                borderBottom:
                  activeFilter === 2
                    ? "2px solid rgba(57, 104, 237, 1)"
                    : "none",
                color: activeFilter === 2 ? "rgba(57, 104, 237, 1)" : "#000",
                fontSize: "14px",
              }}
            >
              Hold
            </div>
            <div
              className="pointer"
              onClick={() => setActiveFilter(3)}
              style={{
                width: "50%",
                textAlign: "center",
                padding: "10px",
                borderBottom:
                  activeFilter === 3
                    ? "2px solid rgba(57, 104, 237, 1)"
                    : "none",
                color: activeFilter === 3 ? "rgba(57, 104, 237, 1)" : "#000",
                fontSize: "14px",
              }}
            >
              Contract
            </div>
          </div>

          {activeFilter === 1 && (
            <div className="totalBookingsInvoicesMultipleGuests">
              {totalBookingData?.soldRooms?.length > 0 ? (
                <>
                  {totalBookingData?.soldRooms?.map((booking, index) => (
                    <>
                      <div className="totalBookingsTablesContainerForSingleGuest">
                        <div className="totalBookingsTableContainer">
                          <table className="totalBookingsTable">
                            <tbody>
                              <tr>
                                <td className="totalBookingsTableData">Name</td>
                                <td className="totalBookingsTableDataInfo">
                                  {booking?.guest_name &&
                                  booking?.guest_name ? (
                                    booking?.guest_name
                                  ) : (
                                    <span>N/A</span>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="totalBookingsTableData">
                                  Room No
                                </td>
                                <td className="totalBookingsTableDataInfo">
                                  {booking.assigned_rooms ? (
                                    booking.assigned_rooms
                                  ) : (
                                    <span>N/A</span>
                                  )}
                                </td>
                              </tr>
                              {/* <tr>
                                <td className="totalBookingsTableData">
                                  No Of Rooms
                                </td>
                                <td className="totalBookingsTableDataInfo">
                                  {booking?.items[0]?.no_of_rooms}
                                </td>
                              </tr> */}
                              <tr>
                                <td className="totalBookingsTableData">
                                  Booking ID
                                </td>
                                <td className="totalBookingsTableDataInfo">
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      color: "#3968ED",
                                    }}
                                    onClick={() =>
                                      getSingleBookingDataHandler(
                                        booking?.unique_booking_id
                                      )
                                    }
                                  >
                                    #{booking?.unique_booking_id}
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td className="totalBookingsTableData">
                                  Check In
                                </td>
                                <td className="totalBookingsTableDataInfo">
                                  {booking?.check_in ? (
                                    <TextDateFormatter
                                      as={"div"}
                                      date={booking?.check_in}
                                      showYear={true}
                                    />
                                  ) : (
                                    <span>N/A</span>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="totalBookingsTableData">
                                  Check Out
                                </td>
                                <td className="totalBookingsTableDataInfo">
                                  {booking?.check_out ? (
                                    <TextDateFormatter
                                      as={"div"}
                                      date={booking?.check_out}
                                      showYear={true}
                                    />
                                  ) : (
                                    <span>N/A</span>
                                  )}
                                </td>
                              </tr>
                              {/* <tr>
                                <td className="totalBookingsTableData">
                                  Meal Plan
                                </td>
                                <td
                                  align="right"
                                  className="totalBookingsTableDataInfo"
                                  style={{ textAlign: "end" }}
                                >
                                  {booking?.meal_plan}
                                </td>
                                <td align="end">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                      width: "100%",
                                    }}
                                  >
                                     {booking?.items[0]?.room_plan} 
                                     <TruncatedText
                                  width={"150px"}
                                  longText={booking?.items[0]?.room_plan}
                                  fontSize={"14px"}
                                  justifyContent="end"
                                /> 
                                  </div>
                                </td>
                              </tr> */}
                              <tr>
                                <td className="totalBookingsTableData">
                                  Guest Status
                                </td>
                                <td className="totalBookingsTableDataInfo">
                                  <BookingStatusUpdate
                                    status={booking?.status}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <hr className="totalBookingsHr" />
                    </>
                  ))}

                  {openSingleBookingDetails && (
                    <BookingDetailSliderNew
                      data={bookingDetails}
                      chargesDetails={chargesDetails}
                      masterFolioDetails={masterFolioDetails}
                      setShowBookingDetails={setOpenSingleBookingDetails}
                      getSingleBookingDataHandler={getSingleBookingDataHandler}
                    />
                  )}
                </>
              ) : (
                <NoDataFound />
              )}
            </div>
          )}
          {activeFilter === 2 && (
            <div className="totalBookingsInvoicesMultipleGuests">
              {totalBookingData?.holdRooms?.length > 0 ? (
                <>
                  {totalBookingData?.holdRooms?.map((booking, index) => (
                    <>
                      <div className="totalBookingsTablesContainerForSingleGuest">
                        <div className="totalBookingsTableContainer">
                          <table className="totalBookingsTable">
                            <tbody>
                              <tr>
                                <td className="totalBookingsTableData">
                                  Guest Name
                                </td>
                                <td className="totalBookingsTableDataInfo">
                                  {booking?.guest_name && booking?.guest_name}
                                </td>
                              </tr>
                              <tr>
                                <td className="totalBookingsTableData">
                                  Phone
                                </td>
                                <td className="totalBookingsTableDataInfo">
                                  {booking?.guest_phone
                                    ? booking?.guest_phone
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td className="totalBookingsTableData">
                                  Email
                                </td>
                                <td className="totalBookingsTableDataInfo">
                                  {booking?.guest_email
                                    ? booking?.guest_email
                                    : "N/A"}
                                </td>
                              </tr>

                              <tr>
                                <td className="totalBookingsTableData">
                                  Room Name
                                </td>
                                <td className="totalBookingsTableDataInfo">
                                  {booking?.room_name
                                    ? booking?.room_name
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td className="totalBookingsTableData">
                                  Booking Status
                                </td>
                                <td className="totalBookingsTableDataInfo">
                                  <BookingStatusUpdate
                                    status={booking?.status}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="totalBookingsTableData">
                                  No Of Rooms
                                </td>
                                <td className="totalBookingsTableDataInfo">
                                  {booking?.no_of_rooms
                                    ? booking?.no_of_rooms
                                    : "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td className="totalBookingsTableData">
                                  Booking ID
                                </td>
                                <td className="totalBookingsTableDataInfo">
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      color: "#3968ED",
                                    }}
                                    onClick={() =>
                                      getSingleBookingDataHandler(
                                        booking?.unique_booking_id
                                      )
                                    }
                                  >
                                    #
                                    {booking?.unique_booking_id
                                      ? booking?.unique_booking_id
                                      : "N/A"}
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td className="totalBookingsTableData">
                                  Hold Till
                                </td>
                                <td className="totalBookingsTableDataInfo">
                                  {booking?.hold_date && booking?.hold_time && (
                                    <span>
                                      {
                                        <TextDateFormatter
                                          date={booking?.hold_date}
                                          showYear={true}
                                          as={"span"}
                                        />
                                      }{" "}
                                      - {booking?.hold_time}
                                    </span>
                                  )}
                                </td>
                              </tr>

                              {booking.type === "HoldInventory" && (
                                <div className="flex mt-10 flex_gap_10">
                                  <WithoutBgButtonBlue
                                    onClick={() => handleBookRoomClick(booking)}
                                  >
                                    Book Rooms
                                  </WithoutBgButtonBlue>
                                  <WithoutBgButtonBlue
                                    danger={true}
                                    onClick={() => {
                                      setShowDeleteConfirmPopup(true);
                                      setSingleData(booking);
                                    }}
                                  >
                                    Release All
                                  </WithoutBgButtonBlue>
                                </div>
                              )}

                              {/* <tr>
                                <td className="totalBookingsTableData">
                                Hold Date
                                </td>
                                <td className="totalBookingsTableDataInfo">
                                  {booking?.check_in}
                                </td>
                              </tr>
                              <tr>
                                <td className="totalBookingsTableData">
                                Hold By
                                </td>
                                <td className="totalBookingsTableDataInfo">
                                  {booking?.check_in}
                                </td>
                              </tr> */}
                              {/* <tr>
                                <td className="totalBookingsTableData">
                                  Check Out
                                </td>
                                <td className="totalBookingsTableDataInfo">
                                  {booking?.check_out}
                                </td>
                              </tr> */}
                              {/* <tr>
                                <td className="totalBookingsTableData">
                                  Meal Plan
                                </td>
                               
                                <td align="end">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                      width: "100%",
                                    }}
                                  >
                                    {booking?.items[0]?.room_plan}
                                   
                                  </div>
                                </td>
                              </tr> */}
                              {/* <tr>
                                <td className="totalBookingsTableData">
                                  Guest Status
                                </td>
                                <td className="totalBookingsTableDataInfo">
                                  <BookingStatusUpdate
                                    status={booking?.status}
                                  />
                                </td>
                              </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <hr className="totalBookingsHr" />
                    </>
                  ))}

                  {openSingleBookingDetails && (
                    <BookingDetailSliderNew
                      data={bookingDetails}
                      chargesDetails={chargesDetails}
                      masterFolioDetails={masterFolioDetails}
                      setShowBookingDetails={setOpenSingleBookingDetails}
                      getSingleBookingDataHandler={getSingleBookingDataHandler}
                    />
                  )}
                  {showDeleteConfirmPopup && (
                    <DeletePopupWithReason
                      title={"KOT"}
                      textAreaName={"reason_of_cancelation"}
                      onDeleteWithReason={handleDeleteWithReason}
                      wordLimit={10}
                      deleteWithReasonPopup={showDeleteConfirmPopup}
                      setDeleteWithReasonPopup={setShowDeleteConfirmPopup}
                      deleteFunction={() =>
                        handleCancelStatusButton(deleteKotId)
                      }
                      id={deleteKotId}
                      fullTitle="You Want to Release All?"
                      btnText="Yes, Release"
                      withoutReason={true}
                      containerWidth={"fit-content"}
                    />
                  )}
                </>
              ) : (
                <NoDataFound />
              )}
            </div>
          )}
          {activeFilter === 3 && (
            <div className="totalBookingsInvoicesMultipleGuests">
              {totalBookingData?.holdRooms?.length > 0 ? (
                <>
                  {totalBookingData?.agentContractData?.map(
                    (booking, index) => (
                      <>
                        <div className="totalBookingsTablesContainerForSingleGuest">
                          <div className="totalBookingsTableContainer">
                            <table className="totalBookingsTable">
                              <tbody>
                                <tr>
                                  <td className="totalBookingsTableData">
                                    Guest Name
                                  </td>
                                  <td className="totalBookingsTableDataInfo">
                                    {booking?.guest_name && booking?.guest_name}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="totalBookingsTableData">
                                    Phone
                                  </td>
                                  <td className="totalBookingsTableDataInfo">
                                    {booking?.guest_phone
                                      ? booking?.guest_phone
                                      : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="totalBookingsTableData">
                                    Email
                                  </td>
                                  <td className="totalBookingsTableDataInfo">
                                    {booking?.guest_email
                                      ? booking?.guest_email
                                      : "N/A"}
                                  </td>
                                </tr>

                                <tr>
                                  <td className="totalBookingsTableData">
                                    Room Name
                                  </td>
                                  <td className="totalBookingsTableDataInfo">
                                    {booking?.room_name
                                      ? booking?.room_name
                                      : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="totalBookingsTableData">
                                    Booking Status
                                  </td>
                                  <td className="totalBookingsTableDataInfo">
                                    <BookingStatusUpdate
                                      status={booking?.status}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="totalBookingsTableData">
                                    No Of Rooms
                                  </td>
                                  <td className="totalBookingsTableDataInfo">
                                    {booking?.no_of_rooms
                                      ? booking?.no_of_rooms
                                      : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="totalBookingsTableData">
                                    Booking ID
                                  </td>
                                  <td className="totalBookingsTableDataInfo">
                                    <div
                                      style={{
                                        cursor: "pointer",
                                        color: "#3968ED",
                                      }}
                                      onClick={() =>
                                        getSingleBookingDataHandler(
                                          booking?.unique_booking_id
                                        )
                                      }
                                    >
                                      #
                                      {booking?.unique_booking_id
                                        ? booking?.unique_booking_id
                                        : "N/A"}
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td className="totalBookingsTableData">
                                    Hold Till
                                  </td>
                                  <td className="totalBookingsTableDataInfo">
                                    {booking?.hold_date &&
                                      booking?.hold_time && (
                                        <span>
                                          {
                                            <TextDateFormatter
                                              date={booking?.hold_date}
                                              showYear={true}
                                              as={"span"}
                                            />
                                          }{" "}
                                          - {booking?.hold_time}
                                        </span>
                                      )}
                                  </td>
                                </tr>

                                {booking.type === "HoldInventory" && (
                                  <div className="flex mt-10 flex_gap_10">
                                    <WithoutBgButtonBlue
                                      onClick={() =>
                                        handleBookRoomClick(booking)
                                      }
                                    >
                                      Book Rooms
                                    </WithoutBgButtonBlue>
                                    <WithoutBgButtonBlue
                                      danger={true}
                                      onClick={() => {
                                        setShowDeleteConfirmPopup(true);
                                        setSingleData(booking);
                                      }}
                                    >
                                      Release All
                                    </WithoutBgButtonBlue>
                                  </div>
                                )}

                                {/* <tr>
                                <td className="totalBookingsTableData">
                                Hold Date
                                </td>
                                <td className="totalBookingsTableDataInfo">
                                  {booking?.check_in}
                                </td>
                              </tr>
                              <tr>
                                <td className="totalBookingsTableData">
                                Hold By
                                </td>
                                <td className="totalBookingsTableDataInfo">
                                  {booking?.check_in}
                                </td>
                              </tr> */}
                                {/* <tr>
                                <td className="totalBookingsTableData">
                                  Check Out
                                </td>
                                <td className="totalBookingsTableDataInfo">
                                  {booking?.check_out}
                                </td>
                              </tr> */}
                                {/* <tr>
                                <td className="totalBookingsTableData">
                                  Meal Plan
                                </td>
                               
                                <td align="end">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                      width: "100%",
                                    }}
                                  >
                                    {booking?.items[0]?.room_plan}
                                   
                                  </div>
                                </td>
                              </tr> */}
                                {/* <tr>
                                <td className="totalBookingsTableData">
                                  Guest Status
                                </td>
                                <td className="totalBookingsTableDataInfo">
                                  <BookingStatusUpdate
                                    status={booking?.status}
                                  />
                                </td>
                              </tr> */}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <hr className="totalBookingsHr" />
                      </>
                    )
                  )}

                  {openSingleBookingDetails && (
                    <BookingDetailSliderNew
                      data={bookingDetails}
                      chargesDetails={chargesDetails}
                      masterFolioDetails={masterFolioDetails}
                      setShowBookingDetails={setOpenSingleBookingDetails}
                      getSingleBookingDataHandler={getSingleBookingDataHandler}
                    />
                  )}
                  {showDeleteConfirmPopup && (
                    <DeletePopupWithReason
                      title={"KOT"}
                      textAreaName={"reason_of_cancelation"}
                      onDeleteWithReason={handleDeleteWithReason}
                      wordLimit={10}
                      deleteWithReasonPopup={showDeleteConfirmPopup}
                      setDeleteWithReasonPopup={setShowDeleteConfirmPopup}
                      deleteFunction={() =>
                        handleCancelStatusButton(deleteKotId)
                      }
                      id={deleteKotId}
                      fullTitle="You Want to Release All?"
                      btnText="Yes, Release"
                      withoutReason={true}
                      containerWidth={"fit-content"}
                    />
                  )}
                </>
              ) : (
                <NoDataFound />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InventorySliderComponent;
