// Polaris Imports
import {
  Button,
  HorizontalStack,
  Icon,
  IndexTable,
  Pagination,
  Tag,
  Text,
  Tooltip,
  useIndexResourceState,
} from "@shopify/polaris";
import { PolarisVizProvider } from "@shopify/polaris-viz";
import { useCallback, useEffect, useRef, useState } from "react";

import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { Page, LegacyCard, DataTable } from "@shopify/polaris";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import StatusUpdate from "./CustomComponents/StatusUpdate";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
} from "./UI/Buttons";
import Loader from "./UI/Loader/Loader";
import CustomCarousel from "./CustomComponents/CustomCarousel/CustomCarousel";
import LoaderSmall from "./UI/Loader/LoaderSmall";
import NoDataFoundSmall from "./CustomComponents/NoDataFoundSmall";
import CustomAddHousekeepingStaff from "./CustomComponents/CustomAddHousekeepingStaff";
import BookingAllFilterComponent from "./Dashboard/HouseKeepingManager/BookingAllFilterComponent";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import GoBackButtonCustom from "./GoBackButtonCustom/GoBackButtonCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import AddCompanyCredits from "./UI/AddCompanyCredits";
import AddAdvancePayments from "./UI/AddAdvancePayments";
import SidebarInnerLists from "./CustomComponents/SidebarInnerLists";
import { getDecryptedData } from "../utils/encryptStorage";

Chart.register(ArcElement);

const AgentAccountCrud = () => {
  const { agentid } = useParams();
  const currentDate = new Date().toISOString().split("T")[0];
  const [fetchedData, setFetchedData] = useState(null);
  const [openDayStatus, setOpenDayStatus] = useState();
  const [showDashBoardCheckIn, setShowDashBoardCheckIn] = useState(false);
  const [showDashBoardCheckOut, setShowDashBoardCheckOut] = useState(false);
  const [showDashboardAllCheckIn, setShowDashboardAllCheckIn] = useState(false);
  const [showManualSearchInput, setShowManualSearchInput] = useState(false);
  const [showWebInput, setShowWebInput] = useState(false);
  const [showBookingDetails, setShowBookingDetails] = useState(false);

  const [clientEmail, setClientEmail] = useState("");

  const handleEmailButtonClick = async (e) => {
    e.preventDefault();
    if (clientEmail === "" || bookingId === "") {
      showErrorToast("Please enter the email..!");
      return;
    }
    try {
      // setLoader(true);
      const response = await fetch(
        // http://127.0.0.1:8000/api/v1/send/event/bill/23/abhimanyuun001@gmail.com/1
        // web/checkin/email/{booking_id}/{property_id}/{email}
        `${process.env.REACT_APP_BASE_URL}/api/v1/web/checkin/email/${bookingId}/${property_id.id}/${clientEmail}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        setClientEmail("");
        setShowDashBoardCheckIn(false);
        setShowWebInput(false);
        setShowManualSearchInput(false);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [state, setState] = useState({});
  const callback = (payload) => {
    setState(payload);
  };

  const [showCheckOutBookingDetails, setShowCheckOutBookingDetails] =
    useState(false);
  const [
    showCheckOutIdClickBookingDetails,
    setShowCheckOutIdClickBookingDetails,
  ] = useState(false);

  const [settlementDoneCheckOut, setSettlementDoneCheckOut] = useState(false);

  const [showCheckOutSettlementDetails, setShowCheckOutSettlementDetails] =
    useState(false);
  const [openBookingDetails, setOpenBookingDetails] = useState(true);
  const userToken = getDecryptedData('encryptToken');

  const [bookingDetails, setBookingDetails] = useState([]);
  const [allSettlementsData, setAllSettlementsData] = useState([]);

  // show click arrow buttons
  const [showArrivalConfirmed, setShowArrivalConfirmed] = useState(false);
  const [showAllSettlement, setShowAllSettlement] = useState(false);
  const [showInHouseCheckIn, setShowInHouseCheckIn] = useState(false);
  const [showDepartureCheckOut, setShowDepartureCheckOut] = useState(false);

  const [checkOutBookingDetail, setCheckOutBookingDetail] = useState([]);
  const property_id = useSelector((state) => state.property.value);

  const [dayTypeFilter, setDayTypeFilter] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [valueArray, setValueArray] = useState([]);
  const accordRef = useRef(null);

  const toggleAccord = () => {
    setIsOpen(!isOpen);
  };

  const permissions = JSON.parse(localStorage.getItem("permissions"));

  // Reservations
  const dateOptions = [
    { id: 0, value: "-1", label: "Today" },
    { id: 1, value: "-7", label: "Past 7 Days" },
    { id: 2, value: "-30", label: "Past 30 Days" },
    { id: 3, value: "-90", label: "Past 90 Days" },
    { id: 4, value: "+7", label: "Future 7 Days" },
    { id: 5, value: "+30", label: "Future 30 Days" },
    { id: 6, value: "+90", label: "Future 90 Days" },
  ];

  const handleAccordClick = (id, label, value) => {
    setValueArray([{ id: id, label: label, value: value }]);
    setDayTypeFilter(value);
  };

  const handleShowDashBoardCheckIn = (e) => {
    e.stopPropagation();
    setShowDashBoardCheckIn(!showDashBoardCheckIn);
    setShowWebInput(false);
    setShowManualSearchInput(false);

    setBookingId("");
    setClientEmail("");
  };

  const handleShowDashBoardCheckOut = (e) => {
    e.stopPropagation();
    setBookingId("");
    setShowDashBoardCheckOut(!showDashBoardCheckOut);
  };

  const handleShowManualSearchInput = () => {
    {
      showWebInput === true && setShowWebInput(false);
    }
    setShowManualSearchInput(!showManualSearchInput);
  };

  const handleShowWebInput = () => {
    {
      showManualSearchInput === true && setShowManualSearchInput(false);
    }
    setShowWebInput(!showWebInput);
  };

  const [bookingOpenIndex, setOpenBookingIndex] = useState(1);
  const [bookingAccordianActive, setBookingAccordianActive] = useState("");

  const handleOpenBookigDetails = (idx) => {
    setOpenBookingIndex(idx);
    if (bookingOpenIndex === idx) {
      setBookingAccordianActive("active");
    } else {
      setBookingAccordianActive("");
    }
  };

  const handleShowDashBoardAllCheckIn = () => {
    setShowDashboardAllCheckIn(!showDashboardAllCheckIn);
  };

  const [loader, setLoader] = useState(false);
  const [confirmedBookings, setConfirmedBookings] = useState([]);
  const [checkInBookings, setCheckInBookings] = useState([]);
  const [checkOutBookings, setCheckOutBookings] = useState([]);
  const navigate = useNavigate();

  const fetchDashboardInfo = async (propertyId, dayTypeFilter) => {
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/dashboard/${property_id?.id}?dayTypeFilter=${dayTypeFilter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (!response.ok) {
        setLoader(false);
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      if (data.message === "Unauthenticated.") {
        navigate("/login");
      }
      setLoader(false);
      setFetchedData(data);
      // setConfirmedBookings(data.confirmed);
      setCheckInBookings(data.current_dirty_rooms);
      setCheckOutBookings(data.checkout);
    } catch (error) {
      if (error === "Unauthenticated.") {
        navigate("/login");
      }
      setLoader(false);
      console.error(error);
    }
  };
  const fetchRoomUnderCleaning = async (propertyId, dayTypeFilter) => {
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/room/under/cleaning/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (!response.ok) {
        setLoader(false);
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      if (data.message === "Unauthenticated.") {
        navigate("/login");
      }
      setLoader(false);
      // setFetchedData(data);
      fetchDashboardInfo(property_id.id, dayTypeFilter);
      setConfirmedBookings(data.data);
    } catch (error) {
      if (error === "Unauthenticated.") {
        navigate("/login");
      }
      setLoader(false);
      console.error(error);
    }
  };

  const [selectedDatesRange, setSelectedDatesRange] = useState({
    from_date: "",
    to_date: "",
  });

  const dataFromChild = (from_date, to_date) => {
    setSelectedDatesRange({
      from_date: from_date,
      to_date: to_date,
    });
  };

  const openDayStatusHandler = useCallback((data) => {
    setOpenDayStatus(data);
  }, []);

  const chart_data = {
    datasets: [
      {
        data: [
          fetchedData?.total_revenue,
          fetchedData?.restro_bill + fetchedData?.room_bill,
          // fetchedData?.room_bill,
          fetchedData?.event_revenue,
          fetchedData?.tax_amount,
        ],
        backgroundColor: [
          "#239B56",
          "#bc5090",
          // "#ff6361",
          "#ffa600",
          "#3968ED",
        ],
        display: true,
        // borderColor: "#D1D6DC"
      },
    ],
  };

  // const BottomTableRows = [
  //   ["Golden Fern Resort", 4500, 4500, 4500, 4500, 4500, 4500],
  //   ["Hotel Snow Castle", 4500, 4500, 4500, 4500, 4500, 4500],
  //   ["Hotel Woodrina", 4500, 4500, 4500, 4500, 4500, 4500],
  //   ["Hill Top Resort", 4500, 4500, 4500, 4500, 4500, 4500],
  //   ["Hotel Bobs N Barley", 4500, 4500, 4500, 4500, 4500, 4500],
  // ];

  const checkin_out = [
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="33"
          viewBox="0 0 34 33"
          fill="none"
        >
          <path
            d="M22.9386 23.3476V28.2858H6.93359V4.62012H22.9386V9.55833"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M16.3335 16.5L25.3335 16.5"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M16.2936 12.1795L12.5122 16.0588C12.4557 16.1157 12.4107 16.1836 12.38 16.2585C12.3493 16.3335 12.3335 16.4139 12.3335 16.4952C12.3335 16.5765 12.3493 16.6569 12.38 16.7319C12.4107 16.8068 12.4557 16.8747 12.5122 16.9316L16.2936 20.8108C16.3777 20.9 16.4859 20.9613 16.6042 20.9868C16.7226 21.0122 16.8456 21.0008 16.9576 20.9538C17.0695 20.9068 17.1651 20.8265 17.2321 20.7232C17.2991 20.62 17.3344 20.4985 17.3335 20.3744L17.3335 12.5578C17.3202 12.4407 17.2753 12.3297 17.2037 12.2376C17.1321 12.1454 17.0367 12.0756 16.9284 12.0362C16.8201 11.9969 16.7032 11.9894 16.591 12.0147C16.4788 12.0401 16.3758 12.0972 16.2936 12.1795Z"
            fill="white"
          />
        </svg>
      ),
      heading: "Arrival",
      value: fetchedData?.confirmed_bookings,
      onclick: () => {
        setShowArrivalConfirmed(true);
      },
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="31"
          height="30"
          viewBox="0 0 31 30"
          fill="none"
        >
          <path
            d="M13.2822 1.24488C13.864 0.778844 14.5872 0.524902 15.3326 0.524902C16.078 0.524902 16.8011 0.778844 17.3829 1.24488L27.2266 9.11988C28.0047 9.74238 28.4585 10.6855 28.4585 11.683V24.8436C28.4585 25.7139 28.1128 26.5485 27.4974 27.1638C26.8821 27.7792 26.0475 28.1249 25.1772 28.1249H18.6147C18.2418 28.1249 17.8841 27.9767 17.6204 27.713C17.3567 27.4493 17.2085 27.0916 17.2085 26.7186V16.8749H13.4585V26.7186C13.4585 27.0916 13.3103 27.4493 13.0466 27.713C12.7829 27.9767 12.4252 28.1249 12.0522 28.1249H5.48975C4.6195 28.1249 3.78491 27.7792 3.16955 27.1638C2.5542 26.5485 2.2085 25.7139 2.2085 24.8436V11.6811C2.2085 10.6855 2.66225 9.74238 3.44037 9.11988L13.2822 1.24488ZM15.626 3.4405C15.5429 3.37417 15.4398 3.33805 15.3335 3.33805C15.2272 3.33805 15.1241 3.37417 15.041 3.4405L5.19725 11.3155C5.1421 11.3595 5.09761 11.4155 5.06709 11.4791C5.03657 11.5427 5.02081 11.6124 5.021 11.683V24.8436C5.021 25.1024 5.231 25.3124 5.48975 25.3124H10.646V15.4686C10.646 15.0957 10.7942 14.738 11.0579 14.4743C11.3216 14.2105 11.6793 14.0624 12.0522 14.0624H18.6147C18.9877 14.0624 19.3454 14.2105 19.6091 14.4743C19.8728 14.738 20.021 15.0957 20.021 15.4686V25.3124H25.1772C25.3016 25.3124 25.4208 25.263 25.5087 25.1751C25.5966 25.0872 25.646 24.9679 25.646 24.8436V11.6811C25.6459 11.6109 25.63 11.5415 25.5995 11.4782C25.569 11.415 25.5246 11.3593 25.4697 11.3155L15.626 3.4405Z"
            fill="white"
          />
        </svg>
      ),
      heading: "In House",
      value: fetchedData?.checkin_bookings,
      onclick: () => {
        setShowInHouseCheckIn(true);
      },
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="33"
          viewBox="0 0 34 33"
          fill="none"
        >
          <path
            d="M22.9386 23.3476V28.2858H6.93359V4.62012H22.9386V9.55833"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M21.3335 16.5L12.3335 16.5"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M21.3734 20.8205L25.1547 16.9412C25.2113 16.8843 25.2563 16.8164 25.287 16.7415C25.3177 16.6665 25.3335 16.5861 25.3335 16.5048C25.3335 16.4235 25.3177 16.3431 25.287 16.2681C25.2563 16.1932 25.2113 16.1253 25.1547 16.0684L21.3734 12.1892C21.2893 12.1 21.1811 12.0387 21.0628 12.0132C20.9444 11.9878 20.8213 11.9992 20.7094 12.0462C20.5975 12.0932 20.5019 12.1735 20.4349 12.2768C20.3679 12.38 20.3326 12.5015 20.3335 12.6256L20.3335 20.4422C20.3467 20.5593 20.3917 20.6703 20.4633 20.7624C20.5349 20.8546 20.6303 20.9244 20.7386 20.9638C20.8469 21.0031 20.9637 21.0106 21.076 20.9853C21.1882 20.9599 21.2912 20.9028 21.3734 20.8205Z"
            fill="white"
          />
        </svg>
      ),
      heading: "Departure",
      value: fetchedData?.checkout_bookings,
      onclick: () => {
        setShowDepartureCheckOut(true);
      },
    },
  ];

  const [bookingId, setBookingId] = useState("");

  const onKeyPressHandler = (e) => {
    setBookingId(e.target.value);
  };

  const submitBookingData = (e, bookingId) => {
    if (e) {
      e.preventDefault();
    }
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${bookingId}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setShowBookingDetails(true);
          setBookingDetails(data.booking);
          // showSuccessToast(data.message);
          setShowArrivalConfirmed(false);
          const updatedData = {
            guest_name: data?.booking?.guest[0]?.guest_name,
            guest_phone: data?.booking?.guest[0]?.guest_phone,
            guest_city: data?.booking?.guest[0]?.guest_city,
            guest_state: data?.booking?.guest[0]?.guest_state,
            guest_pincode: data?.booking?.guest[0]?.guest_pincode,
            guest_address: data?.booking?.guest[0]?.guest_address,
            guest_email: data?.booking?.guest[0]?.guest_email,
            identity_proof_type: data?.booking?.guest[0]?.identity_proof_type,
            identity_proof_no: data?.booking?.guest[0]?.identity_proof_no,
            guest_country: "India",
          };
          setCheckInData(updatedData);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  const submitCheckOutBookingData = (e) => {
    e.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${bookingId}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setCheckOutBookingDetail(data.booking);
          showSuccessToast(data.message);
          setShowCheckOutBookingDetails(true);
          setShowDashBoardCheckOut(false);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  const [openAccordion, setOpenAccordion] = useState("first");
  const [bookingsButtonActive, setBookingsButtonActive] = useState(1);

  const [availabilityButtonActive, setAvailabilityButtonActive] =
    useState("Available");

  const bookingActiveHandle = (id) => {
    setBookingsButtonActive(id);
  };

  const toggleAccordion = (accordion) => {
    if (openAccordion === accordion) {
      setOpenAccordion(null); // Close the currently open accordion
    } else {
      setOpenAccordion(accordion); // Open the clicked accordion
    }
  };

  const [checkInData, setCheckInData] = useState({
    assign_room: "",
    guest_name: "",
    guest_phone: "",
    guest_email: "",
    guest_city: "",
    guest_state: "",
    guest_country: "India",
    guest_pincode: "",
    guest_address: "",
    identity_proof_type: "",
    identity_proof_no: "",
    identity_proof_type_other: "",
  });

  const [selectedRooms, setSelectedRooms] = useState([]);
  const [roomNumbers, setRoomNumbers] = useState([]);

  useEffect(() => {
    const extractedRoomNumbers = selectedRooms.map((room) => room.room_no);

    setRoomNumbers(extractedRoomNumbers);
  }, [selectedRooms]);

  const completeCheckIn = (e, bookingId) => {
    e.preventDefault();

    if (checkInData.identity_proof_type === null) {
      showErrorToast("Please select the Identity Proof");
      return;
    }

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/booking/checkin/${bookingDetails.id}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...checkInData,
          assign_room: roomNumbers,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setShowBookingDetails(false);
          setBookingDetails(data.booking);
          showSuccessToast(data.message);
          setShowDashBoardCheckIn(false);
          fetchDashboardInfo(property_id?.id, dayTypeFilter);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const completeCheckOut = (e) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/booking/checkout/${checkOutBookingDetail.id}/${property_id?.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setBookingDetails(data.booking);
          showSuccessToast(data.message);
          setShowDashBoardCheckIn(false);
          setSettlementDoneCheckOut(false);
          fetchDashboardInfo(property_id?.id, dayTypeFilter);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const assignRoomsHandle = (e, bookingId) => {
    // every
    // some

    const checkMaxRooms =
      selectedData &&
      selectedData.every((room) => {
        return (
          room.maxRooms ===
          (room.selectedRoooms ? room.selectedRoooms.length : 0)
        );
      });

    if (!checkMaxRooms) {
      showErrorToast("Please assign the rooms first.");
      return;
    }

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/assign/room/no/${shortBookingId}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          assign_room: selectedData,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // setShowBookingDetails(false);
          // setBookingDetails(data.booking);
          setShowInHouseCheckIn(false);
          setShowAssignRoomPopup(false);
          showSuccessToast(data.message);
          // setShowDashBoardCheckIn(false);
          window.location.reload();
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  // UseState for forms
  const [settlementAddData, setSettlementAddData] = useState({
    settlement_name: "",
    payment_mode: "",
    notes: "",
    date: "",
    amount: "",
    property_id: property_id?.id,
    other_payment_mode: "",
  });

  const paymentModeOptions = [
    { label: "Select Option" },
    { label: "Cash", value: "cash" },
    { label: "Bank Payment", value: "bank_payment" },
    { label: "UPI", value: "upi" },
    { label: "Credit Card", value: "credit_card" },
    { label: "Debit Card", value: "debit_card" },
    { label: "Other", value: "other" },
  ];

  const proofOptions = [
    { label: "Select option" },
    { label: "Aadhar Card", value: "aadhar" },
    { label: "PAN Card", value: "pan" },
    { label: "Passport", value: "passport" },
    { label: "Driving License", value: "driving_license" },
    { label: "Other", value: "other" },
  ];

  const setAddItemDataHandleChange = (event) => {
    setSettlementAddData({
      ...settlementAddData,
      [event.target.name]: event.target.value,
    });
  };

  const setCheckInDetailsHandleChange = (event) => {
    setCheckInData({
      ...checkInData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitAddMeals = (event) => {
    event.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/store/settlements/${bookingId}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...settlementAddData,
          property_id: property_id?.id,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setShowCheckOutSettlementDetails(false);
          setSettlementDoneCheckOut(true);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  useEffect(() => {
    document.title = "RevCatalyst - Boosting Your Hotel Performance";

    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property.";
  }, []);

  const fetchAllSettlements = async (propertyId, bookingId) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/settlements/${bookingId}/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAllSettlementsData(data.settlements);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const [suggestions, setSuggestions] = useState([]);

  const fetchSuggession = async (propertyId) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/suggestions/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setSuggestions(data.suggestions);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const [assignRoomsData, setAssignRoomsData] = useState([]);

  const assignRoomArray = [
    { label: "Select Option" },
    ...assignRoomsData.map((room) => {
      return { label: room.room_no, value: room.id };
    }),
  ];

  // for Tag
  const [selectedTags, setSelectedTags] = useState([]);

  const removeTag = useCallback(
    (tag) => () => {
      setSelectedTags((previousTags) =>
        previousTags.filter((previousTag) => previousTag !== tag)
      );
    },
    [selectedTags]
  );

  const [onkeyPressValue, setOnKeyPressValue] = useState("");

  const handleOnKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter" && onkeyPressValue.trim() !== "") {
        e.preventDefault();
        setOnKeyPressValue("");
        // valueArray.push(inputValue)
        setSelectedTags((prevArray) => [...prevArray, onkeyPressValue]);

        // onkeyPressValue = ""
        setOnKeyPressValue("");
      }
    },
    [onkeyPressValue, selectedTags]
  );

  // const setOnKeyPressValueHandleChange = useCallback(
  //   (e) => {
  //     setSelectedTags(selectedTags);

  //     setOnKeyPressValue(e.target.value);
  //   },
  //   [selectedTags]
  // );

  // const setOnKeyPressValueHandleChange = (event) => {
  //   const selectedRoomId = parseInt(event.target.value, 10);

  //   // Check if the room is not already in the selectedRooms array
  //   if (!selectedRooms.find((room) => room.id === selectedRoomId)) {
  //     const selectedRoom = assignRoomsData.find(
  //       (room) => room.id === selectedRoomId
  //     );

  //     // Add the selected room to the array
  //     setSelectedRooms([...selectedRooms, selectedRoom]);
  //   }
  // };

  const setOnKeyPressValueHandleChange = (e) => {
    const selectedId = parseInt(e.target.value, 10); // Assuming the value is numeric

    // Find the room object based on the selectedId
    const selectedRoom = assignRoomsData.find((room) => room.id === selectedId);

    if (!selectedRoom) {
      showErrorToast("Invalid Room ID");
      return;
    }

    // Check if the selected room already exists in selectedRooms
    if (selectedRooms.some((item) => item.id === selectedId)) {
      showErrorToast("Duplicate Entry");
    } else {
      setSelectedRooms((prevValue) => [
        ...prevValue,
        { room_no: selectedRoom.room_no, id: selectedId },
      ]);
    }
  };

  const handlelistRemove = (id) => {
    setSelectedRooms((prevValue) => prevValue.filter((item) => item.id !== id));
  };

  const tagMarkup = selectedTags.map((option) => (
    <Tag key={option} onRemove={removeTag(option)}>
      {option}
    </Tag>
  ));

  const handleShowAllSettlement = (propertyId, bookingId) => {
    setShowCheckOutBookingDetails(false);
    setShowAllSettlement(!showAllSettlement);
    fetchAllSettlements(propertyId, bookingId);
  };
  const resourceName = {
    singular: "allsettlement",
    plural: "allsettlements",
  };
  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(allSettlementsData);

  const [showAssignRoomPopup, setShowAssignRoomPopup] = useState(false);
  const [clickedBookingId, setClickedBookingId] = useState("");
  const [roomNo, setRoomNo] = useState("");
  const [shortBookingId, setShortBookingId] = useState("");

  const fetchAssignRooms = async (propertyId, bookingId) => {
    setShowAssignRoomPopup(true);
  };

  const markAsCompletedHandler = (status, roomId) => {
    fetch(
      // housekeeping/update/room/no/{status}/{room_no}/{property_id}
      `${process.env.REACT_APP_BASE_URL}/api/v1/housekeeping/update/room/no/${status}/${roomId}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          fetchRoomUnderCleaning(property_id.id);
          showSuccessToast(data.message);
          // fetchDashboardInfo(property_id.id, dayTypeFilter);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  const sortedCheckOutBookings = confirmedBookings.sort((a, b) => {
    if (a.status === "completed" && a.room_status !== "clean") return -1;
    if (a.room_status === "In progress") return 1;
    return 0;
  });

  const confirmedRowMarkup = sortedCheckOutBookings?.map(
    ({
      id,
      room_no,
      housekeeping_item,
      status,
      index,
      booking_id,
      room_status,
    }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>{room_no}</IndexTable.Cell>
        <IndexTable.Cell>{booking_id}</IndexTable.Cell>
        <IndexTable.Cell>
          {" "}
          {housekeeping_item &&
            housekeeping_item.map((staff) => {
              return <div>{staff.assigned_to}</div>;
            })}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <StatusUpdate status={status} />
        </IndexTable.Cell>
        <IndexTable.Cell>
          {status === "completed" && room_status !== "clean" && (
            <BgOutlineThemeButton
              onClick={() => [markAsCompletedHandler("clean", room_no)]}
              style={{ margin: "auto", height: "27px" }}
              children={"Inspected"}
            />
          )}
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const checkInRowMarkup = checkInBookings?.map(
    ({
      id,
      room_no,
      unique_booking_id,
      room_assigned_to_housekeeping,
      index,
    }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>{room_no}</IndexTable.Cell>
        <IndexTable.Cell>{unique_booking_id}</IndexTable.Cell>
        <IndexTable.Cell>
          <span style={{ textAlign: "-webkit-right" }}>
            {room_assigned_to_housekeeping === false ? (
              <BgOutlineThemeButton
                onClick={() => [
                  setClickedBookingId(unique_booking_id),
                  setShortBookingId(id),
                  fetchAssignRooms(property_id?.id, id),
                  setRoomNo(room_no),
                ]}
              >
                Assign
              </BgOutlineThemeButton>
            ) : (
              <Tooltip content={"Room is already assigned."}>
                <BgOutlineThemeButton
                  style={{ cursor: "no-drop", opacity: ".5" }}
                >
                  Assign
                </BgOutlineThemeButton>
              </Tooltip>
            )}
          </span>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  // For Bookings showing below

  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedRoooms, selectedRooooms] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [selected, setSelected] = useState({});

  const [selectedRoomType, setSelectedRoomType] = useState(11);
  const onChangeFunc = (e, index, roomId) => {
    setSelectedRoomType(e.target.value);
    // fetchAfterPlans(e.target.value, roomId);
  };

  const [competitorsIdsArray, setCompetitorsIdsArray] = useState([]);
  // Callback function to receive data from the child component
  const handleCompetitorsIdsArrayChange = (newArray) => {
    setCompetitorsIdsArray(newArray);
  };

  const handleSubmitAddCompetitor = (event) => {
    event.preventDefault();

    if (competitorsIdsArray.length < 1) {
      showErrorToast("Please select the staff");
      return;
    }

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/create/housekeeping/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          housekeepingStaff_id: competitorsIdsArray,
          booking_id: clickedBookingId,
          room_no: roomNo,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setShowAssignRoomPopup(false);
          fetchDashboardInfo(property_id?.id, dayTypeFilter);
          fetchRoomUnderCleaning(property_id.id);
          // fetchInfo(property_id?.id);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // Fetch the room type click data
  const [agents, setAgents] = useState([]);
  const fetchInfo = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        setAgents(data.data);
        setLoader(false);
      } else {
        showErrorToast(data.message);
        setAgents([]);
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id.id);
    }
  }, [property_id]);

  const agentArray = [
    { label: "Select an option", value: "" }, // Your first option
    ...(agents?.map((agent) => ({
      label: agent.agent_name,
      value: agent.agent_information,
    })) || []),
  ];

  useEffect(() => {
    const newData = assignRoomsData.map((room) => ({
      name: room.room_name,
      id: room.id,
      selectedRoooms: selectedRoooms[room.id] || [],
      maxRooms: parseInt(room.no_of_rooms, 10),
    }));
    setSelectedData(newData);
  }, [selectedRoooms, assignRoomsData]);

  const customers_inner_list = [
    {
      name: "Used Credit",
      link: `/credit/${agentid}`,
      permission: "read_customers",
    },
    {
      name: "Payments",
      link: `/transfer/${agentid}`,
      permission: "read_agents",
    },
    {
      name: "Added Credits",
      link: `/agents/credits/${agentid}`,
      permission: "read_agents",
    },
    // {
    //   name: "Advance Payments",
    //   link: `/agents/advance/payments/${agentid}`,
    //   permission: "read_agents",
    // },
    {
      name: "Bookings",
      link: `/agents/bookings/${agentid}`,
      permission: "read_agents",
    },
    {
      name: "Credit Finance Logs",
      link: `/agents/credit/Logs/${agentid}`,
      permission: "read_agents",
    },
    {
      name: "City Ledger Report",
      link: `/agents/city/Ledger/${agentid}`,
      permission: "read_agents",
    },
  ];

  return (
    <div className="main_container">
      <SidebarInnerLists innerLists={customers_inner_list} />
      {loader ? (
        <Loader />
      ) : (
        <>
          <section className="dashboard_page">
            <div
              className="dashboard_page_left"
              style={{ width: "49%", margin: "0" }}
            >
              <AddCompanyCredits agentArray={agentArray} />
            </div>
            <div
              className="dashboard_page_right"
              style={{ width: "49%", margin: "0" }}
            >
              <AddAdvancePayments agentArray={agentArray} />
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default AgentAccountCrud;
