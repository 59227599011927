import React, { useEffect, useState } from "react";

const RoomTypesExtraBeddingToggle = (props) => {
  const [isToggled, setIsToggled] = useState(props.byDefaultEnable);

  const handleToggle = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  useEffect(() => {
    setIsToggled(props.byDefaultEnable);
  }, [props.byDefaultEnable]);

  useEffect(() => {
    const beddingFinalValue = isToggled ? "enabled" : "disabled";
    props.func(beddingFinalValue, props.name);
  }, [isToggled, props.func, props.name]);

  return (
    <div className="bedding-wrapper">
      {!props.notShowTitle && (
        <div>
          <p className="bedding_text">{props.title}</p>
        </div>
      )}
      <div className="flex align_items_center">
        <span className="toggle_text">
          {isToggled ? props.positive : props.negative}
        </span>
        <label className="toggle-container">
          <input
            name={props.name}
            type="checkbox"
            checked={isToggled}
            onChange={handleToggle}
            className="toggle-checkbox-custom"
          />
          <span className="toggle-slider"></span>
        </label>
      </div>
    </div>
  );
};

export default RoomTypesExtraBeddingToggle;