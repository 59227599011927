import React from "react";
import RecentBookings from "./recentBookings/RecentBookings";
import InventoryInsights from "./inventoryInsights/InventoryInsights";
import BookingSummary from "./bookingSummary/BookingSummery";
import RoomCategoryInsights from "./roomCategoryInsights/RoomCategoryInsight";
import ChannelManagerRoomRevenue from "./channelManagerRoomRevenue/ChannelManagerRoomRevenue";

const ChannelManagerDashboard = () => {
  return (
    <div className="main_container">
      <div className="flex flex_gap_20 justify_content_between">
      <div className="flex flex_direction_col flex_gap_10" style={{ width: "70%" }}>
        <BookingSummary />
        <ChannelManagerRoomRevenue />
        <RoomCategoryInsights />
      </div>

      <div className="flex flex_direction_col flex_gap_10" style={{ width: "30%" }}>
        <InventoryInsights />
        <RecentBookings />
      </div>
    </div>
    </div>
  );
};

export default ChannelManagerDashboard;
