import React, { useEffect, useRef } from "react";
import styles from "./shareOnWhatsApp.module.scss";
import { SvgCancel } from "../../../assets/svgIcons";

const ShareOnWhatsAppEmail = ({ data, setShow, show ,propertyName ,adjustLeft }) => {

  
  const popupRef = useRef(null);
  const WhatsAppIcon = () => {
    return (
      <>
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 448 512"
          style={{
            color: "white",
            height: "35px",
            width: "35px",
            position: "absolute",
          }}
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
        </svg>
      </>
    );
  };

  const EmailIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        style={{
          color: "white",
          height: "28px",
          width: "28px",
          position: "absolute",
        }}
      >
        <g data-name="27-Email-Message">
          <path
            d="M31.59 11.19 27 7.83V5a1 1 0 0 0-1-1h-4.22L16.59.19a1 1 0 0 0-1.18 0L10.22 4H6a1 1 0 0 0-1 1v2.83L.41 11.19A1 1 0 0 0 0 12v17a3 3 0 0 0 3 3h26a3 3 0 0 0 3-3V12a1 1 0 0 0-.41-.81zM27 10.31l2.2 1.61-2.2 1.31zM16 2.24 18.4 4h-4.8zM7 6h18v8.43l-9 5.4-9-5.4zm-2 4.31v2.93l-2.2-1.32zM2 28.59V13.77l9.26 5.56zM3.41 30 13 20.38l2.46 1.47a1 1 0 0 0 1 0L19 20.38 28.59 30zM30 28.59l-9.26-9.26L30 13.77z"
            fill="#fff"
          />
          <path d="M10 9h12v2H10zM12 13h8v2h-8z" fill="#fff" />
        </g>
      </svg>
    );
  };

  const handleWhatsApp = () => {
    const getWhatsAppMessage = (data) => {
      return `
        *Booking Details:*
        - Property Name: *${propertyName}*
        - Booking ID: *${data.unique_booking_id}*
        - Booking Source: *${data.booking_source}*
        - Check-in: *${data.check_in}*
        - Check-out: *${data.check_out}*
        - Total Amount: *${data.grand_total}*
        - Balance Amount: *${data.balance_amount}*
        - Status: *${data.status}*
  
        *Guest Details:*
        - Name: *${data.guest[0].guest_name ? data.guest[0].guest_name : ""}*
        - Email: *${data.guest[0].guest_email ? data.guest[0].guest_email : ""}*
      `;
    };
  
    const whatsAppMessage = getWhatsAppMessage(data);
    const whatsAppUrl = `https://wa.me/?text=${encodeURIComponent(whatsAppMessage)}`;
    window.open(whatsAppUrl, "_blank");
  };

  const handleEmail = () => {
    const emailSubject = `Booking Details for Property: ${propertyName}`;
    const emailBody = `
      Booking Details:
      - Property Name: ${propertyName}
      - Booking ID: ${data.unique_booking_id}
      - Booking Source: ${data.booking_source}
      - Check-in: ${data.check_in}
      - Check-out: ${data.check_out}
      - Total Amount: ${data.grand_total}
      - Balance Amount: ${data.balance_amount}
      - Status: ${data.status}
      Guest Details:
      - Name: ${data.guest[0].guest_name}
      - Email: ${data.guest[0].guest_email ? data.guest[0].guest_email : ""}
    `;

    window.location.href = `mailto:${
      data.guest[0].guest_email ? data.guest[0].guest_email :""
    }?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(
      emailBody
    )}`;
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto"; 
    }
    return () => {
      document.body.style.overflow = "auto"; 
    };
  }, [show]);

  if (!data){
    return null
    
  }

  return (
    <div className={styles["popup-overlay"]} style={{left : adjustLeft} }>
      <div className={styles["popup-container"]} ref={popupRef}>
        <div className={styles["popup-header"]}>
          <h2>Share Booking Details</h2>
          <button className={styles["close-btn"]} onClick={handleClose}>
            <SvgCancel color={"#333"} />
          </button>
        </div>
        <div className={styles["popup-content"]}>
          <p>You are about to share details for:</p>
          <h3>Booking ID : #{data?.unique_booking_id}</h3>
        </div>
        <div className={styles["popup-actions"]}>
          <button className={styles["popup-btn-email"]} onClick={handleEmail}>
            <EmailIcon />
          </button>
          <button
            className={styles["popup-btn-whatsapp"]}
            onClick={handleWhatsApp}
          >
            <WhatsAppIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareOnWhatsAppEmail;
