import React from "react";
import styles from "./inventoryInsights.module.scss"
// import "./InventoryInsights.css";

const InventoryInsights = () => {
  const insightsData = [
    { value: "100", label: "Total", color: "blue" },
    { value: "60", label: "Sold", color: "green" },
    { value: "60%", label: "Occupancy", color: "blue" },
    { value: "40", label: "Unsold", color: "orange" },
  ];

  return (
    <div className={styles["inventory-container"]}>
      <div className={"flex justify_content_between align_items_center"} style={{marginBottom:"20px "}}>
        <h2>Inventory Insights</h2>
       <div className={styles.filter}>
       <select className={styles["date-selector"]}>
          <option>Today</option>
          {/* Add more options if needed */}
        </select>
       </div>
      </div>
      <div className={styles["insights"]}>
        {insightsData.map((insight, index) => (
          <div
            key={index}
            className={styles["insight-box"]}
            style={{ borderColor: insight.color }}
          >
            <h3>{insight.value}</h3>
            <p>{insight.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InventoryInsights;