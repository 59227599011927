import React, { useEffect, useState } from "react";
import styles from "./AddPropertyTarget.module.scss";
import Loader from "../UI/Loader/Loader";
import { useSelector } from "react-redux";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { PrimaryButton } from "../NewCustomComp/Buttons/Buttons";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { SvgCancel, SvgMinus, SvgPlus } from "../../assets/svgIcons";
import { SearchOrAdd } from "../NewCustomComp/Search/Search";
import { PrimaryInput } from "../NewCustomComp/Inputs/Inputs";
import { SelectBasic } from "../NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import { getDecryptedData } from "../../utils/encryptStorage";

const AddPropertyTarget = ({
  closePopup,
  refreshListApi,
  settings,
  getSettings,
  fetchSettingsData,
}) => {
  const [loader, setLoader] = useState(false);
  const [currentStep, setCurrentStep] = useState("setOccupancy");
  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value)?.id;

  //  setOccupancy
  const [selectedArea, setSelectedArea] = useState("");
  const areaOptions = [
    { label: "select area", value: "" },
    { label: "Hilly Areas", value: "Hilly Areas" },
    { label: "Plain Areas", value: "Plain Areas" },
  ];
  // end set occupancy

  // new
  const [expenseItems, setExpenseItems] = useState([]);
  const [currentItem, setCurrentItem] = useState([{ type: "", amount: "" }]);

  const handleExpenseChange = (key, value) => {
    setCurrentItem({ ...currentItem, [key]: value });
  };

  const addExpenseItem = () => {
    if (currentItem.type && currentItem.amount) {
      const existingItemIndex = expenseItems.findIndex(
        (item) => item.type === currentItem.type
      );
  
      if (existingItemIndex !== -1) {
        // If the expense type already exists, update the amount
        const updatedItems = expenseItems.map((item, index) =>
          index === existingItemIndex
            ? { ...item, amount: +item.amount + +currentItem.amount }
            : item
        );
        setExpenseItems(updatedItems);
      } else {
        // If the expense type doesn't exist, add it to the list
        setExpenseItems([...expenseItems, currentItem]);
      }
  
      // Reset current item
      setCurrentItem({ type: "", amount: "" });
    }
  };

  const handleDelete = async (index, id) => {
    setExpenseItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  const [profitMargin, setProfitMargin] = useState({ value: "" }); 

  const handleProfitChange = (data) => {
    setProfitMargin({ value: Number(data) });
  };

  const [rooms, setRooms] = useState({
    totalRooms: "",
  });

  const handleAreaChange = (e) => {
    const { name, value } = e.target;
    if (!value) {
      return;
    }
    setSelectedArea(e.target.value);
  };

  const handleRoomsChange = (e) => {
    const { name, value } = e.target;

    setRooms((prev) => {
      return {
        ...prev,
        [name]: +value,
      };
    });
  };

  // Get Occupancies API

  const [occupancies, setOccupancies] = useState([]);
  const [recommended, setRecommended] = useState([]);
  useEffect(() => {
    const fetchOccupancies = async () => {
      if (!selectedArea) return; // Only make the API call if area is selected

      const formData = new FormData();
      formData.append("area_type", selectedArea);

      const requestOptions = {
        method: "POST",
        body: formData,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/occupancies`,
          requestOptions
        );
        const result = await response.json();
        setRecommended(result?.occupancies);
        setOccupancies(
          result?.occupancies.map((item, index) => {
            return {
              ...item,
              recommendPercent: item?.occupancy_percent,
            };
          }) || []
        );
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (!getSettings) {
      fetchOccupancies();
    }
  }, [selectedArea]);

  const handleOccupancyChange = (month, value) => {
    if (+value > 100) {
      return;
    }
    const updatedOccupancies = occupancies.map((item) =>
      item.month === month ? { ...item, occupancy_percent: +value } : item
    );
    setOccupancies(updatedOccupancies);
  };

  // Update Occupancy

  const addonsForBanquetEvents = [
    { id: 1, label: "Loans (Liability)", value: "Loans (Liability)" },
    { id: 2, label: "Secured Loans", value: "Secured Loans" },
    { id: 3, label: "Unsecured Loans", value: "Unsecured Loans" },
    { id: 4, label: "Current Liabilities", value: "Current Liabilities" },
    { id: 5, label: "Duties & Taxes", value: "Duties & Taxes" },
    {
      id: 6,
      label: "Accountancy Charges Payable",
      value: "Accountancy Charges Payable",
    },
    {
      id: 7,
      label: "Electricity Bill Payable",
      value: "Electricity Bill Payable",
    },
    { id: 8, label: "GST Payable", value: "GST Payable" },
    {
      id: 9,
      label: "Staff Salary & Wages Payable",
      value: "Staff Salary & Wages Payable",
    },
    {
      id: 10,
      label: "Telephone Bill Payable",
      value: "Telephone Bill Payable",
    },
    { id: 11, label: "Crockery & Cutlery", value: "Crockery & Cutlery" },
    { id: 12, label: "Furniture & Fixture", value: "Furniture & Fixture" },
    { id: 13, label: "Televisions", value: "Televisions" },
    { id: 14, label: "ODC & Labour Charges", value: "ODC & Labour Charges" },
    { id: 15, label: "Cable Charges Exp.", value: "Cable Charges Exp." },
    { id: 16, label: "Bank Charges", value: "Bank Charges" },
    { id: 17, label: "Cable Charges Exp.", value: "Cable Charges Exp." },
    { id: 18, label: "Depreciation Expenses", value: "Depreciation Expenses" },
    { id: 19, label: "Discount/Allowance", value: "Discount/Allowance" },
    {
      id: 20,
      label: "Fee and Subscription Expenses",
      value: "Fee and Subscription Expenses",
    },
    { id: 21, label: "Garbage Expenses", value: "Garbage Expenses" },
    { id: 22, label: "Gas Cylinder LPG.", value: "Gas Cylinder LPG." },
    { id: 23, label: "Interest Paid on GST", value: "Interest Paid on GST" },
    {
      id: 24,
      label: "Laundry & Dry Cleaning Expenses",
      value: "Laundry & Dry Cleaning Expenses",
    },
    {
      id: 25,
      label: "Repair & Maintenance Electrical",
      value: "Repair & Maintenance Electrical",
    },
    {
      id: 26,
      label: "Sale Promotion Expenses",
      value: "Sale Promotion Expenses",
    },
    { id: 27, label: "Staff Welfare", value: "Staff Welfare" },
    { id: 28, label: "Staff Uniform", value: "Staff Uniform" },
    { id: 29, label: "Staff Accommodation", value: "Staff Accommodation" },
    { id: 30, label: "Telephone Expenses", value: "Telephone Expenses" },
    { id: 31, label: "Water Expenses", value: "Water Expenses" },
    { id: 32, label: "Security Services", value: "Security Services" },
    { id: 33, label: "Landscaping Expenses", value: "Landscaping Expenses" },
    { id: 34, label: "HVAC Maintenance", value: "HVAC Maintenance" },
    { id: 35, label: "Pest Control Services", value: "Pest Control Services" },
    { id: 36, label: "Building Insurance", value: "Building Insurance" },
    {
      id: 37,
      label: "Fire Safety Compliance",
      value: "Fire Safety Compliance",
    },
    {
      id: 38,
      label: "Parking Lot Maintenance",
      value: "Parking Lot Maintenance",
    },
    { id: 39, label: "Common Area Cleaning", value: "Common Area Cleaning" },
    { id: 40, label: "Elevator Maintenance", value: "Elevator Maintenance" },
    { id: 41, label: "Roof Repairs", value: "Roof Repairs" },
    {
      id: 42,
      label: "Window Cleaning Services",
      value: "Window Cleaning Services",
    },
    { id: 43, label: "Exterior Painting", value: "Exterior Painting" },
    { id: 44, label: "Waste Management", value: "Waste Management" },
  ];

  //===========  submit handle api calls  ================
  const handleSubmitAll = async (e) => {
    e.preventDefault();
    if (settings) {
      handleUpdateTargets();
    } else {
      handleUpdateOccupancy();
    }
  };

  const handleUpdateOccupancy = async () => {
    const raw = JSON.stringify({
      property_id: property_id,
      year: new Date().getFullYear(),
      occupancies: occupancies,
    });

    try {
      setLoader(true);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/update-occupancy`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          method: "POST",
          body: raw,
        }
      );
      const data = await response.json();

      if (data.message === "Unauthorised.") {
        console.log("Unauthorized access.");
      }

      if (data.success) {
        showSuccessToast("Occupancy updated successfully.");
        savePropertyExpenses(expenseItems);
      }
    } catch (error) {
      console.error("Error updating occupancy:", error);
      showErrorToast("An error occurred while updating occupancy.");
    } finally {
      setLoader(false);
    }
  };

  const savePropertyExpenses = async (items) => {
    const data = items.map((item) => ({
      amount: item.amount,
      expense_amount: item.amount,
      expense_name: item.type,
      property_id: property_id,
      type: item.type,
      area_type: selectedArea,
    }));

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
      },
      body: JSON.stringify(data), // Convert the data to JSON string
      redirect: "follow",
    };

    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/property-expenses/save`,
        requestOptions
      );
      const result = await response.json(); // Parse the response as JSON

      if (result.message === "Unauthorised.") {
        console.log("Unauthorized access.");
      }

      if (result.success) {
        showSuccessToast("Expenses added successfully.");
        submitPropertyTargets(
          property_id,
          selectedArea,
          allCalculations(expenseItems, buffer, profitMargin)?.totalExpenses,
          buffer.value,
          profitMargin === "" ? 0 : profitMargin.value
        );
      }
    } catch (error) {
      console.error("Error saving property expenses:", error);
      showErrorToast("An error occurred while saving property expenses.");
    } finally {
      setLoader(false);
    }
  };

  const submitPropertyTargets = async (
    property_id,
    area,
    totalExpanse,
    buffer,
    profitMargin
  ) => {
    const requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    try {
      setLoader(true);
      const response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/v1/admin/add-property-target?property_id=${property_id}&total_expense=${totalExpanse}&targeted_yearly_earning=${allCalculations(
          expenseItems,
          buffer,
          profitMargin
        ).totalYearlyEarningsWithProfit.toFixed(2)}&total_rooms=${
          rooms.totalRooms
        }&added_margin=${profitMargin}&added_buffer=${buffer}&area_type=${area}`,
        requestOptions
      );
      const result = await response.json();
      if (result.message === "Unauthorised.") {
      }
      if (result.success) {
        showSuccessToast("Property Targets added successfully.");
        fetchRateList();
      }
    } catch (error) {
      console.error(error);
      showErrorToast("Error while adding property targets");
    } finally {
      setLoader(false);
    }
  };

  const fetchRateList = async () => {
    const requestOptions = {
      method: "POST",
      redirect: "follow",
    };
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/dyanamic-price?property_id=${property_id}&ota_charge_percentage=0`,
        requestOptions
      );
      const result = await response.json();
      showSuccessToast("dynamic rates list added successfully");
      refreshListApi(property_id, new Date().getFullYear());
    } catch (error) {
      console.log(error);
      showErrorToast("Error while adding dynamic rates list");
    } finally {
      closePopup();
      setLoader(false);
    }
  };

  const handleUpdateTargets = async () => {
    const data = {
      property_id: property_id,
      area: selectedArea,
      total_expense: allCalculations(expenseItems, buffer.value, profitMargin.value)
        .totalExpenses,
      added_buffer: allCalculations(expenseItems, buffer.value, profitMargin.value).buffer,
      buffer_amount: allCalculations(expenseItems, buffer.value, profitMargin.value)
        .bufferExp,
      amount_after_buffer: allCalculations(expenseItems, buffer.value, profitMargin.value)
        .totalExpWithBuffer,
      targeted_yearly_earning: allCalculations(
        expenseItems,
        buffer.value,
        profitMargin.value
      ).totalYearlyEarning,
      added_margin: allCalculations(expenseItems, buffer.value, profitMargin.value).profit,
      margin_amount: allCalculations(expenseItems, buffer.value, profitMargin.value)
        .profitExp,
      amount_after_margin: allCalculations(expenseItems, buffer.value, profitMargin.value)
        .totalYearlyEarningsWithProfit,
      total_rooms: rooms.totalRooms,
      expenses: expenseItems.map((item) => ({
        amount: item.amount,
        name: item.type,
      })),
      occupancies: occupancies.map((item) => ({
        year: new Date().getFullYear(),
        month: item.month,
        occupancy_percent: item.occupancy_percent,
      })),
      modification_type: "update",
      reason: "Annual review",
    };

    // Prepare request options
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Ensure correct Content-Type header
        Authorization: `Bearer ${userToken}`, // Include Authorization token if required
      },
      body: JSON.stringify(data), // Ensure data is stringified
    };

    try {
      setLoader(true); // Show loader while request is being processed

      // Construct the URL
      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/admin/property-targets`;

      // Fetch API call
      const response = await fetch(url, requestOptions);

      // Parse response as JSON
      const result = await response.json();

      if (response.ok) {
        // Check for success response
        if (result.success) {
          showSuccessToast("Property Targets added successfully.");
          fetchRateList(); // Optionally refresh rate list after success
        } else {
          showErrorToast(result.message || "Failed to add Property Targets.");
        }
      } else {
        showErrorToast("Error: " + result.message || "Something went wrong.");
      }
    } catch (error) {
      // Handle errors
      console.error("Error while adding property targets:", error);
      showErrorToast("Error while adding property targets");
    } finally {
      setLoader(false); // Hide loader after request completes
    }
  };
  //============= submit api end  =========================

  // Validation
  const handleNextStepTwo = () => {
    if (expenseItems?.length <= 0) {
      showErrorToast("Please Add at-least One Expense");
      return;
    } else {
      setCurrentStep("setGenerateTarget");
    }
  };
  const handleNextStepOne = () => {
    if (!selectedArea || !rooms.totalRooms) {
      showErrorToast("Fill the No. of Rooms Before Stepping Next");
      return;
    } else {
      setCurrentStep("setTarget");
    }
  };

  const [buffer, setBuffer] = useState({ value: "" });

  const handleBufferChange = (data) => {
    setBuffer({value: data });
  };

  // For Adding Other Expense
  const addOtherExpense = (data) => {
    setCurrentItem({ amount: "", type: data });
  };

  // calculations

  function allCalculations(expenseItems, buffer, profitMargin) {
    const totalExpenses =
      expenseItems?.reduce((acc, curr) => acc + Number(curr.amount || 0), 0) ||
      0;

    const bufferPercentage = Number(buffer) || 20; // Convert buffer value to number
    const profitMarginPercentage = Number(profitMargin) || 0; // Convert profit margin to number

    const bufferExp = (totalExpenses * bufferPercentage) / 100;
    const totalExpBuffer = totalExpenses + bufferExp;

    const profitExp = (totalExpBuffer * profitMarginPercentage) / 100;
    const totalYearlyEarningsWithProfit = totalExpBuffer + profitExp;

    return {
      totalExpenses: Number(totalExpenses),
      buffer: Number(bufferPercentage),
      bufferExp: Number(bufferExp),
      totalExpWithBuffer: Number(totalExpBuffer),
      totalYearlyEarning: Number(totalExpBuffer),
      profit: Number(profitMarginPercentage),
      profitExp: Number(profitExp),
      totalYearlyEarningsWithProfit: Number(totalYearlyEarningsWithProfit),
    };
  }

  // Settings
  const [showSteps, setShowSteps] = useState(true);

  useEffect(() => {
    if (settings) {
      setCurrentStep("setGenerateTarget");
      setShowSteps(!settings);
    } else {
      setCurrentStep("setOccupancy");
    }
  }, [settings]);

  // const [recommended, setRecommended] = useState([]);
  const getRecommendedEdit = async (area_type) => {
    const formData = new FormData();

    formData.append("area_type", area_type);

    const requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/occupancies`,
        requestOptions
      );
      const result = await response.json();
      if ((result.message = "Occupancies")) {
        setRecommended(result.occupancies);
      } else {
        showErrorToast("Data Not Found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (getSettings && getSettings?.occupansies?.length > 0) {
      setOccupancies(
        getSettings?.occupansies.map((item, index) => {
          return {
            ...item,
            recommendPercent: item?.occupancy_percent,
          };
        })
      );

      setRooms({
        totalRooms: getSettings?.result?.total_rooms,
      });
      setExpenseItems(
        getSettings?.expenses?.map((item, index) => ({
          ...item,
          amount: item.expense_amount,
          type: item.expense_name,
        }))
      );
      setSelectedArea(getSettings?.result?.area_type);
    }
  }, [getSettings]);

  const handleEditClick = (e) => {
    e.preventDefault();
    setCurrentStep("setOccupancy");
    setShowSteps(true);
  };

  // Buffer Listing
  const [options, setOptions] = useState([]);

  const fetchPropertySettings = async () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/property-settings`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        setProfitMargin({ value: data.data.find((selected)=> selected?.is_suggested ===1)?.property_margin})
        setBuffer({value:data.data.find((selected)=> selected?.is_suggested ===1)?.buffer_margin});
        setOptions(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };



  useEffect(() => {
    if (settings && getSettings?.result) {
      setProfitMargin({ value: getSettings.result.added_margin });
      setBuffer({ value: getSettings.result.added_buffer });
    }
  }, [settings, getSettings]);

 
  useEffect(() => {
    if(selectedArea ){
      getRecommendedEdit(selectedArea)
    }
  },[selectedArea])


  

  return (
    <div style={{ maxWidth: "800px" }}>
      <div
        className={`${styles.hotelName} flex justify_content_between align_items_center`}
      >
        <h3>
          {showSteps === true && !settings && "Add Targets"}
          {showSteps === false && settings === true && "Edit Targets"}
          {showSteps === true && settings === true && "Update Targets"}
        </h3>

        <span className={styles.crossBtn} onClick={closePopup}>
          <SvgCancel color={"#888888"} />
        </span>
      </div>
      <div
        className={`${styles.targetSteps_wrapper}`}
        style={{ width: "600px" }}
      >
        {showSteps ? (
          <>
            <div
              onClick={() => setCurrentStep("setOccupancy")}
              className={`${styles.cursor} ${
                currentStep === "setOccupancy"
                  ? styles.targetSteps_card
                  : styles.targetSteps_card_white
              }`}
            >
              <h1>Step 1</h1>
            </div>
            <div
              onClick={() => {
                handleNextStepOne();
                fetchPropertySettings();
              }}
              className={
                currentStep === "setTarget"
                  ? styles.targetSteps_card
                  : styles.targetSteps_card_white
              }
            >
              <h1>Step 2</h1>
            </div>
            <div
              onClick={handleNextStepTwo}
              className={`${
                currentStep === "setGenerateTarget"
                  ? styles.targetSteps_card
                  : styles.targetSteps_card_white
              }`}
            >
              <h1>Step 3</h1>
            </div>
          </>
        ) : null}
      </div>

      {loader ? (
        <Loader />
      ) : (
        <>
          {currentStep === "setOccupancy" && (
            <div style={{ width: "600px", margin: "0 auto" }}>
              <div className={` ${styles.white_wrapper}`}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <div style={{ width: "50%" }}>
                    <VerticalInputSelectCustomCopy
                      titleName={"Area"}
                      options={areaOptions}
                      value={selectedArea}
                      onChange={handleAreaChange}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <VerticalInputFieldCustom
                      titleName={"Total No. of Rooms :"}
                      value={rooms?.totalRooms || ""}
                      name={"totalRooms"}
                      onChange={handleRoomsChange}
                      type={"number"}
                    />
                  </div>
                </div>
                <div className={styles.firstFormWrapper}>
                  {(selectedArea && occupancies && occupancies?.length) ===
                  0 ? (
                    <Loader />
                  ) : (
                    <>
                      <div className={styles.months}>
                        {occupancies?.length > 0 && (
                          <div className={`${styles.monthHead} `}>
                            {/* <span style={{ color: "red" }}>Recommended</span>{" "} */}
                            <span style={{ paddingRight: "10px" }}>
                              Occupancies
                            </span>
                            <span style={{ color: "red" }}>Recommended</span>{" "}
                          </div>
                        )}
                        <div className="flex flex_gap_10 align_items_center">
                          <div>
                            {occupancies.slice(0, 6).map((items, index) => (
                              <div key={index} className={styles.firstFormCard}>
                                <span>{items.month}</span>
                                <span>
                                  <VerticalInputFieldCustom
                                    type={"number"}
                                    onChange={(e) =>
                                      handleOccupancyChange(
                                        items.month,
                                        e.target.value
                                      )
                                    }
                                    value={items?.occupancy_percent || ""}
                                  />
                                </span>
                              </div>
                            ))}
                          </div>
                          <div>
                            {recommended
                              .slice(0, 6)
                              .map((items, innerIndex) => (
                                <div
                                  key={innerIndex}
                                  className={`${styles.recommended_percent} ${styles.firstFormCard}`}
                                >
                                  {items.occupancy_percent}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>

                      <div className={styles.months}>
                        {occupancies?.length > 0 && (
                          <div className={`${styles.monthHead} `}>
                            {/* <span style={{ color: "red" }}>Recommended</span>{" "} */}
                            <span style={{ paddingRight: "10px" }}>
                              Occupancies
                            </span>
                            <span style={{ color: "red" }}>Recommended</span>{" "}
                          </div>
                        )}
                        <div className="flex flex_gap_10 align_items_center">
                          <div>
                            {occupancies.slice(6, 12).map((items, index) => (
                              <div key={index} className={styles.firstFormCard}>
                                <span>{items.month}</span>
                                <span>
                                  <VerticalInputFieldCustom
                                    type={"number"}
                                    onChange={(e) =>
                                      handleOccupancyChange(
                                        items.month,
                                        e.target.value
                                      )
                                    }
                                    value={items?.occupancy_percent || ""}
                                  />
                                </span>
                              </div>
                            ))}
                          </div>

                          <div>
                            {recommended
                              .slice(6, 12)
                              .map((items, innerIndex) => (
                                <div
                                  key={innerIndex}
                                  className={`${styles.recommended_percent} ${styles.firstFormCard}`}
                                >
                                  {items.occupancy_percent}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>

                      {/* <div className={styles.months}>
                        {occupancies?.length > 0 && (
                          <div className={styles.monthHead}>
                            <span style={{ color: "red" }}>Recommended</span>
                            <span style={{ paddingRight: "10px" }}>
                              Occupancies
                            </span>
                          </div>
                        )}
                        {occupancies.slice(6, 12).map((items, index) => (
                          <div key={index + 6} className={styles.firstFormCard}>
                            <span>{items.month}</span>

                            <div
                              key={index}
                              className={styles.recommended_percent}
                            >
                              {items.recommendPercent}
                            </div>

                            <span>
                              <VerticalInputFieldCustom
                                type={"number"}
                                min={0}
                                max={100}
                                onChange={(e) =>
                                  handleOccupancyChange(
                                    items.month,
                                    e.target.value
                                  )
                                }
                                value={items?.occupancy_percent || ""}
                              />
                            </span>
                          </div>
                        ))}
                      </div> */}
                    </>
                  )}
                </div>
              </div>

              <div>
                <PrimaryButton
                  size={"medium"}
                  onClick={() => {
                    handleNextStepOne();
                    fetchPropertySettings();
                  }}
                  // onClick={handleUpdateOccupancy}
                  title={"Next"}
                />
              </div>
            </div>
          )}

          {currentStep === "setTarget" && (
            <div style={{ width: "600px", margin: "0 auto" }}>
              <div
                className={`${styles.white_wrapper} ${styles.secondForm_wrapper}`}
              >
                <div className={`${styles.secondForm_expCard}`}>
                  <span style={{ width: "40%" }}>
                    <SearchOrAdd
                      label={"Expense Type"}
                      defaultSelected={currentItem.type}
                      options={addonsForBanquetEvents}
                      handleOptionChange={addOtherExpense}
                      optionKeyName={"label"}
                      selectedKeyName={"value"}
                    />
                  </span>
                  <span>
                    <VerticalInputFieldCustom
                      titleName={"Expense Amount (₹)"}
                      type={"number"}
                      value={currentItem.amount}
                      onChange={(e) =>
                        handleExpenseChange("amount", e.target.value)
                      }
                    />
                  </span>
                  <span style={{ cursor: "pointer" }} onClick={addExpenseItem}>
                    <SvgPlus />
                  </span>
                </div>
              </div>

              {expenseItems?.length > 0 ? (
                <>
                  <div className={`${styles.white_wrapper}`}>
                    <table className={styles.secondForm_table}>
                      <tr>
                        <th className={styles.w_50}>Expense Type</th>
                        <th className={styles.w_35}>Expense Amount</th>
                        <th />
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          <div className={styles.overflowContainer}>
                            <table style={{ width: "100%" }}>
                              <tbody>
                                {expenseItems.map((items, index) => (
                                  <>
                                    <tr key={index}>
                                      <td
                                        style={{ width: "43%" }}
                                        className={`${styles.w_70} ${styles.borderTop}`}
                                      >
                                        {items.type}
                                      </td>
                                      <td
                                        className={`${styles.w_25} ${styles.borderTop}`}
                                      >
                                        ₹ {items.amount}
                                      </td>
                                      {expenseItems?.length > 0 ? (
                                        <td
                                          className={`${styles.w_5} ${styles.borderTop}`}
                                        >
                                          <span
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              handleDelete(index, items.id)
                                            }
                                          >
                                            <SvgMinus />
                                          </span>
                                        </td>
                                      ) : null}
                                    </tr>
                                  </>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>

                      <tr className={styles.totalExpanse}>
                        <td colSpan={3}>
                          <hr />
                        </td>
                      </tr>

                      {/* //expanse */}
                      <tr className={styles.totalExpanse}>
                        <td className={`${styles.w_72} ${styles.td_gap}`}>
                          Total Expenses Amount :
                        </td>
                        <td>
                          ₹{" "}
                          {
                            allCalculations(
                              expenseItems,
                              buffer.value,
                              profitMargin.value
                            )?.totalExpenses
                          }
                        </td>
                      </tr>

                      <tr className={styles.totalExpanse}>
                        <td className={`${styles.w_72} ${styles.td_flex}`}>
                          Buffer :
                        </td>
                        <td className="flex justify_content_end align_items_center">
                          <div className={styles.resultInputs}>
                            <SelectBasic
                              options={options.map((item) => ({
                                label: item?.buffer_margin,
                                value:  item?.buffer_margin
                              }))}
                              optionKeyName={"label"}
                              selectedKeyName={"value"}
                              showKeyName={"value"}
                              defaultSelected={buffer?.value}
                              size={"small"}
                              handleSelectTagChange={handleBufferChange}
                            />
                          </div>
                        </td>
                      </tr>

                      <tr className={styles.totalExpanse}>
                        <td className={`${styles.w_72} ${styles.td_flex}`}>
                          Buffer Amount :
                        </td>
                        <td>
                          {
                            allCalculations(
                              expenseItems,
                              buffer.value,
                              profitMargin.value
                            )?.bufferExp
                          }
                        </td>
                      </tr>

                      <tr className={styles.totalExpanse}>
                        <td className={`${styles.w_72} ${styles.td_flex}`}>
                          Total Expense with Buffer
                        </td>
                        <td>
                          {
                            allCalculations(
                              expenseItems,
                              buffer.value,
                              profitMargin.value
                            )?.totalExpWithBuffer
                          }
                        </td>
                      </tr>

                      <tr className={styles.totalExpanse}>
                        <td className={`${styles.w_72} ${styles.td_flex}`}>
                          {" "}
                          Profit Margin (%) :
                        </td>
                        <td
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div className={styles.resultInputs}>
                            <SelectBasic
                              options={options.map((item) => ({
                                label: item?.property_margin,
                                value:  item?.property_margin
                              }))}
                              optionKeyName={"label"}
                              selectedKeyName={"value"}
                              showKeyName={"value"}
                              defaultSelected={profitMargin?.value}
                              size={"small"}
                              handleSelectTagChange={handleProfitChange}
                            />
                          </div>
                        </td>
                      </tr>

                      <tr className={styles.totalExpanse}>
                        <td className={`${styles.w_72} ${styles.td_gap}`}>
                          Profit Amount :
                        </td>
                        <td>
                          ₹{" "}
                          {allCalculations(
                            expenseItems,
                            buffer.value,
                            profitMargin.value
                          )?.profitExp.toFixed(2)}
                        </td>
                      </tr>
                      <tr className={styles.totalExpanse}>
                        <td className={`${styles.w_72} ${styles.td_gap}`}>
                          Targeted Yearly Earning :
                        </td>
                        <td>
                          {" "}
                          ₹{" "}
                          {allCalculations(
                            expenseItems,
                            buffer.value,
                            profitMargin.value
                          )?.totalYearlyEarningsWithProfit?.toFixed(2)}
                        </td>
                      </tr>
                    </table>
                  </div>
                </>
              ) : null}

              <div>
                <PrimaryButton
                  size={"medium"}
                  onClick={handleNextStepTwo}
                  title={"Next"}
                />
              </div>
            </div>
          )}

          {currentStep === "setGenerateTarget" && (
            <form
              style={{ width: "600px" }}
              onSubmit={showSteps ? handleSubmitAll : handleEditClick}
              action=""
            >
              <div className={styles.thirdForm_wrapper}>
                <div className={`${styles.white_wrapper}`}>
                  <h4>
                    {" "}
                    <span style={{ fontSize: "14px", fontWeight: "600" }}>
                      Total Number of Rooms :
                    </span>{" "}
                    {rooms.totalRooms}
                  </h4>
                  <h4 style={{ marginTop: "10px" }}>
                    {" "}
                    <span style={{ fontSize: "14px", fontWeight: "600" }}>
                      Area :
                    </span>{" "}
                    {selectedArea}
                  </h4>
                  <div
                    style={{ justifyContent: "left", margin: "0" }}
                    className={styles.firstFormWrapper}
                  >
                    <div
                      style={{ width: "80%", gap: "30px" }}
                      className={styles.firstFormWrapper}
                    >
                      <div
                        style={{
                          width: "50%",
                          paddingRight: "15px",
                          borderRight: "0.5px solid #CCCCCC",
                        }}
                        className={styles.firstForm_monthData}
                      >
                        <div
                          style={{ display: "flex" }}
                          className={`${styles.firstFormCard}`}
                        >
                          <span style={{ width: "60%", fontWeight: "600" }}>
                            Months
                          </span>
                          <span style={{ fontWeight: "600" }}>Occupancy</span>
                        </div>
                        {occupancies &&
                          occupancies?.length > 0 &&
                          occupancies?.slice(0, 6).map((items, index) => (
                            <div
                              style={{ display: "flex" }}
                              key={index}
                              className={`${styles.firstFormCard}`}
                            >
                              <span style={{ width: "60%" }}>
                                {items.month} :{" "}
                              </span>
                              <span>{items?.occupancy_percent}</span>
                            </div>
                          ))}
                        {/* )} */}
                      </div>

                      <div
                        style={{ width: "50%" }}
                        className={styles.firstForm_monthData}
                      >
                        <div
                          style={{ display: "flex" }}
                          className={`${styles.firstFormCard}`}
                        >
                          <span style={{ width: "60%", fontWeight: "600" }}>
                            Months
                          </span>
                          <span style={{ fontWeight: "600" }}>Occupancy</span>
                        </div>

                        {occupancies &&
                          occupancies?.length > 0 &&
                          occupancies?.slice(6, 12).map((items, index) => (
                            <div
                              style={{ display: "flex" }}
                              key={index}
                              className={styles.firstFormCard}
                            >
                              <span style={{ width: "60%" }}>
                                {items.month} :{" "}
                              </span>
                              <span>{items?.occupancy_percent}</span>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`${styles.white_wrapper} ${styles.thirdForm_cards}`}
                >
                  <h4>Property Targets</h4>
                  <div className={styles.secondForm_data}>
                    <div className={styles.thirdForm_sndResult}>
                      <span className={styles.thirdForm_sndResult_one}>
                        Total Expenses:
                      </span>
                      <span>
                        ₹{" "}
                        {Number(
                          allCalculations(
                            expenseItems,
                            buffer.value,
                            profitMargin.value
                          )?.totalExpenses
                        )?.toFixed(2)}
                      </span>
                    </div>
                    <div className={styles.thirdForm_sndResult}>
                      <span className={styles.thirdForm_sndResult_one}>
                        Buffer:
                      </span>
                      <span>
                        {/* {Number(
                          allCalculations(expenseItems, buffer, profitMargin)
                            ?.buffer
                        ).toFixed(2)}{" "} */}
                        {buffer?.value}%
                      </span>
                    </div>
                    <div className={styles.thirdForm_sndResult}>
                      <span className={styles.thirdForm_sndResult_one}>
                        Buffer Amount:
                      </span>
                      <span>
                        ₹{" "}
                        {Number(
                          allCalculations(
                            expenseItems,
                            buffer.value,
                            profitMargin.value
                          )?.bufferExp
                        )?.toFixed(2)}
                      </span>
                    </div>
                    <div className={styles.thirdForm_sndResult}>
                      <span className={styles.thirdForm_sndResult_one}>
                        Total Expenses with Buffer:
                      </span>
                      <span>
                        ₹{" "}
                        {Number(
                          allCalculations(
                            expenseItems,
                            buffer.value,
                            profitMargin.value
                          )?.totalExpWithBuffer
                        )?.toFixed(2)}
                      </span>
                    </div>
                    <div className={styles.thirdForm_sndResult}>
                      <span className={styles.thirdForm_sndResult_one}>
                        Profit Margin:
                      </span>

                      <span> {profitMargin?.value}%</span>
                    </div>
                    <div className={styles.thirdForm_sndResult}>
                      <span className={styles.thirdForm_sndResult_one}>
                        Profit Amount:
                      </span>
                      <span>
                        ₹{" "}
                        {Number(
                          allCalculations(
                            expenseItems,
                            buffer.value,
                            profitMargin.value
                          )?.profitExp
                        )?.toFixed(2)}
                      </span>
                    </div>
                    <div className={styles.thirdForm_sndResult}>
                      <span className={styles.thirdForm_sndResult_one}>
                        Targeted Yearly Earning :
                      </span>
                      <span>
                        ₹{" "}
                        {Number(
                          allCalculations(
                            expenseItems,
                            buffer.value,
                            profitMargin.value
                          )?.totalYearlyEarningsWithProfit
                        )?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <PrimaryButton
                  size={"medium"}
                  type="submit"
                  title={showSteps ? "Submit" : "Edit"}
                />
              </div>
            </form>
          )}
        </>
      )}
    </div>
  );
};

export default AddPropertyTarget;
