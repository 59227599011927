import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getBaseUrl } from "../../utils/utils";
import Loader from "../UI/Loader/Loader";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "../CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";
import { BgThemeButton } from "../UI/Buttons";
import styles from "./channelListing.module.scss";
import InventoryChannelMapping from "./InventoryChannelMapping";
import { showErrorToast } from "../../assets/toastUtils";
import InventoryAddChannel from "./InventoryAddChannel";
import Pagination from "../NewCustomComp/pagination/Pagination";
import NoDataFound from "../CustomComponents/NoDataFound";
import { getDecryptedData } from "../../utils/encryptStorage";

const ChannelListing = () => {
  const property_id =
    useSelector((state) => state.property.value) ||
    localStorage.getItem("selectedProperty");
  const userToken = getDecryptedData('encryptToken');
  const baseUrl = getBaseUrl();
  const [channels, setChannels] = useState([]);
  const [loader, setLoader] = useState(false);
  const fetchChannels = async () => {
    try {
      setLoader(true);

      const response = await fetch(`${baseUrl}/api/v1/channels/list`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json();
      if (data.success) {
        setChannels(data.data);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (property_id.id) {
      fetchChannels();
    }
  }, [property_id.id]);

  // channel mapping popup

  const [channelMappingPopup, setChannelMappingPopup] = useState(false);
  const [selectedChannelMapping, setSelectedChannelMapping] = useState(null);

  const handleChannelMappingPopupShow = (channelId, channelName) => {
    setSelectedChannelMapping({
      channelId: channelId,
      channelName: channelName,
    });
    setChannelMappingPopup(true);
  };

  const [categories, setCategories] = useState([]);
  const fetchRooms = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/v1/rooms/${property_id.id}`);

      const data = await response.json().then((data) => {
        if (data.success) {
          setCategories(data.rooms);

          setLoader(false);
        } else {
          showErrorToast("Something went wrong...");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (channelMappingPopup) {
      fetchRooms();
    }
  }, [channelMappingPopup]);

  // add channel popup
  const [addChannelPopup, setAddChannelPopup] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState({});

  const onPageChange = (data) => {
    console.log(data);
    const nextPageUrl = channels?.next_page_url;
  };

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <>
          <BgThemeButton
            style={{ float: "right", margin: "10px 0" }}
            onClick={() => setAddChannelPopup(true)}
          >
            Add Channel
          </BgThemeButton>
          <div>
            {channels && channels?.data?.length === 0 ? (
              <div>
                <NoDataFound />
              </div>
            ) : (
              <>
                <CustomShopifyIndexTable
                  headings={["Channel Name", "Channel ID", "", ""]}
                  showCheckbox={false}
                >
                  {channels?.data &&
                    channels?.data.map((channel) => (
                      <>
                        <CustomShopifyIndexTableRow key={channel?.id}>
                          <CustomShopifyIndexTableCell>
                            <span className={styles.channelName}>
                              {channel?.channel_name}
                            </span>
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            {channel?.id}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell>
                            <BgThemeButton
                              children={"Channel Mapping"}
                              className={styles.btnSmall}
                              onClick={() =>
                                handleChannelMappingPopupShow(
                                  channel?.id,
                                  channel?.channel_name
                                )
                              }
                            />
                          </CustomShopifyIndexTableCell>
                        </CustomShopifyIndexTableRow>
                      </>
                    ))}
                </CustomShopifyIndexTable>
                <div className={styles.paginationContainer}>
                  <Pagination
                    type={"advance"}
                    totalPages={+channels.last_page}
                    currentPage={+channels.current_page}
                    onPageChange={onPageChange}
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}

      {channelMappingPopup && (
        <InventoryChannelMapping
          show={channelMappingPopup}
          setShow={setChannelMappingPopup}
          propertyData={property_id}
          selectedChannelMapping={selectedChannelMapping}
          roomCategories={categories}
        />
      )}

      {addChannelPopup && (
        <InventoryAddChannel
          show={addChannelPopup}
          setShow={setAddChannelPopup}
          fetchInfo={fetchChannels}
          roomId={selectedRoom.id}
          propertyId={property_id.id}
        />
      )}
    </div>
  );
};

export default ChannelListing;
