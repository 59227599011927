import {
  TextField,
  IndexTable,
  LegacyCard,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Badge,
  Avatar,
  Button,
  Icon,
  Select,
  Checkbox,
  Page,
  Tooltip,
} from "@shopify/polaris";

import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BgOutlineBlackButton,
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  PlainCancelButton,
  WithoutBgButtonBlue,
} from "../../UI/Buttons";
import { useSelector } from "react-redux";
import NoDataFound from "../../CustomComponents/NoDataFound";
import KOTStatusCustomComp from "../../CustomComponents/KOTStatusCustomComp";
import Loader from "../../UI/Loader/Loader";
import CustomPagination from "../../CustomComponents/CustomPagination/CustomPagination";
import TextDateFormatter from "../../Communication/TextDateFormatter/TextDateFormatter";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { getDecryptedData } from "../../../utils/encryptStorage";

const FoodKotList = () => {
  const [orders, setOrders] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const permission = JSON.parse(localStorage.getItem("permissions"));
  const [permissions, setPermissions] = useState(permission);
  const [unique_booking_id, set_unique_booking_id] = useState("");

  // Pagignation
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(orders.length / itemsPerPage);
    setCurrentPage(newPage > totalPages ? totalPages : newPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentOrders = orders.slice(startIndex, endIndex);

  //
  useEffect(() => {
    setPermissions(permissions);
  }, [permissions]);
  const [selectedCategory, setSelectedCategory] = useState("");

  const [kots, setKots] = useState({
    kot_type: "",
    payment_type: "",
    table_no: "",
    room_no: "",
    paid_amount: "",
    kot_date: "",
    sub_total: "",
    discount: "",
    balance_amount: "",
    gst: "",
    gst_type: "",
    grand_total: "",
    balance_amount: "",
    item_details: [],
    unique_booking_id: "",
  });

  const [itemDetails, setItemDetails] = useState([
    {
      menue_item_category: "",
      menue_item_id: "",
      item_price: "",
      // item_weight: "",
      quantity: "",
      total_amount: "",
    },
  ]);

  // const handleRemoveStock = (index) => {
  //     const updatedItemDetails = [...itemDetails];
  //     updatedItemDetails.splice(index, 1);
  //     setItemDetails(updatedItemDetails);
  // };

  // const handleAddStock = () => {
  //     setItemDetails([
  //         ...itemDetails,
  //         {
  //             menue_item_id: "",
  //             item_price: "",
  //             item_weight: "",
  //             quantity: "",
  //             total_amount: "",
  //         },
  //     ]);
  // };

  const calculateAllKotSubTotal = () => {
    let allItemsSubTotal = 0;
    for (const item of itemDetails) {
      allItemsSubTotal += parseFloat(item.total_amount || 0);
    }
    return allItemsSubTotal.toFixed(2);
  };

  // const handleAddKotChange = (e) => {
  //     const { name, value } = e.target;
  //     if (name === "room_no") {
  //         fetchGuestDeatails(value, property_id?.id);
  //     }
  //     setKots((prevState) => {
  //         let newKots = { ...prevState };

  //         // Handle the discount input
  //         if (
  //             name === "discount" ||
  //             name === "gst" ||
  //             name === "gst_type" ||
  //             name === "paid_amount"
  //         ) {
  //             newKots[name] = value;
  //             const subTotal = parseFloat(kots.sub_total || 0);
  //             const discount = parseFloat(newKots.discount || 0);
  //             const amountAfterDiscount = subTotal - (subTotal * discount) / 100;
  //             const gst = parseFloat(newKots.gst || 0);
  //             const gstType = newKots.gst_type;

  //             let grandTotal = amountAfterDiscount;

  //             if (gstType === "exclusive") {
  //                 grandTotal = amountAfterDiscount + (amountAfterDiscount * gst) / 100;
  //             }
  //             // Calculate the grand total after discount and GST
  //             // const amountAfterGst = amountAfterDiscount + (amountAfterDiscount * gst) / 100;
  //             const paidAmount = parseFloat(newKots.paid_amount || 0);
  //             const dueAmount = grandTotal - paidAmount;

  //             if (paidAmount > grandTotal) {
  //                 // If paid amount is more than grand total, set it to grand total
  //                 newKots["paid_amount"] = grandTotal;
  //                 newKots["balance_amount"] = 0;
  //             } else {
  //                 newKots["balance_amount"] = dueAmount.toFixed(2);
  //             }
  //             newKots["grand_total"] = grandTotal.toFixed(2);
  //             // newKots["balance_amount"] = dueAmount.toFixed(2);
  //         } else {
  //             newKots[name] = value;
  //         }
  //         return newKots;
  //     });
  // };

  // const handleTableInputChange = (e, index) => {
  //     const { name, value } = e.target;
  //     const newItems = [...itemDetails];
  //     const currentItem = newItems[index];

  //     currentItem[name] = value;

  //     // Calculate the total amount
  //     if (name === "item_price" || name === "quantity") {
  //         const qty = parseFloat(currentItem.item_price || 0);
  //         const price = parseFloat(currentItem.quantity || 0);
  //         const subTotal = qty * price;

  //         currentItem.total_amount = subTotal.toFixed(2);
  //     }
  //     setItemDetails(newItems);
  // };

  // const fetchTablesData = async (id) => {
  //     try {
  //         setLoader(true);
  //         const response = await fetch(
  //             `${process.env.REACT_APP_BASE_URL}/api/v1/get/table_list/${id}`,
  //             {
  //                 headers: {
  //                     "Content-Type": "application/json",
  //                     Authorization: `Bearer ${userToken}`,
  //                 },
  //             }
  //         );
  //         const data = await response.json();
  //         if (data.message === "Unauthorised.") {
  //             navigate("/");
  //         }
  //         setTablesData(data.tabledata);
  //         setLoader(false);
  //     } catch (error) {
  //         setLoader(false);
  //         console.error(error);
  //     }
  // };

  // const fetchRoomsData = async (propertyId) => {
  //     if (!propertyId) {
  //         navigate("/login");
  //     }
  //     try {
  //         setLoader(true);
  //         const response = await fetch(
  //             `${process.env.REACT_APP_BASE_URL}/api/v1/assign/rooms/${propertyId}`,
  //             {
  //                 headers: {
  //                     "Content-Type": "application/json",
  //                     Authorization: `Bearer ${userToken}`,
  //                 },
  //             }
  //         );
  //         const data = await response.json();
  //         if (data.message === "Unauthorised.") {
  //             navigate("/");
  //         }
  //         setRoomsData(data.rooms);
  //         setLoader(false);
  //     } catch (error) {
  //         setLoader(false);
  //     }
  // };

  // const handleChangePurchaseItemName = (
  //     event,
  //     index,
  //     selectedCategoryItems
  // ) => {
  //     const selectedId = parseInt(event.target.value);
  //     const selectedItem = selectedCategoryItems?.find(
  //         (item) => item.id === selectedId
  //     );
  //     if (selectedItem) {
  //         const updatedItemDetails = [...itemDetails];

  //         updatedItemDetails[index] = {
  //             ...updatedItemDetails[index],
  //             menue_item_id: selectedId,
  //             item_price: selectedItem.item_price,
  //         };

  //         setItemDetails(updatedItemDetails);
  //     } else {
  //         const updatedItemDetails = [...itemDetails];
  //         updatedItemDetails[index] = {
  //             ...updatedItemDetails[index],
  //             menue_item_id: "",
  //             item_price: "",
  //         };

  //         setItemDetails(updatedItemDetails);
  //     }
  // };

  useEffect(() => {
    const newSubTotal = calculateAllKotSubTotal();

    setKots((prevStocks) => ({
      ...prevStocks,
      item_details: itemDetails,
      sub_total: newSubTotal,
    }));
  }, [itemDetails]);

  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);
  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(currentOrders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  useEffect(() => {
    setKots({
      ...kots,
      unique_booking_id: unique_booking_id,
    });
  }, [unique_booking_id]);

  const rowMarkup = currentOrders?.map(
    ({
      id,
      kot_no,
      kot_date,
      sub_total,
      balance_amount,
      status,
      bill_no,
      index,
    }) => {
      let billButton;
      let cancelButton;

      if (status === "In KOT" || status === "In Processing") {
        cancelButton = (
          <>
            {permissions && permissions.includes("cancel_kot_bill") ? (
              <PlainCancelButton
                onClick={() => handleCancelStatusButton(id)}
                children={"Cancel"}
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                  >
                    <path d="M1 9L9 1" stroke="#888888" strokeWidth="1.2" />
                    <path d="M9 9L1 1" stroke="#888888" strokeWidth="1.2" />
                  </svg>
                }
              />
            ) : (
              <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
                <PlainCancelButton
                  style={{ opacity: "0.6", cursor: "no-drop" }}
                  children={"Cancel"}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                    >
                      <path d="M1 9L9 1" stroke="#888888" strokeWidth="1.2" />
                      <path d="M9 9L1 1" stroke="#888888" strokeWidth="1.2" />
                    </svg>
                  }
                />
              </Tooltip>
            )}
          </>
        );
      }

      if (status === "In KOT") {
        billButton = (
          <>
            {permissions && permissions.includes("generate_kot_bill") ? (
              <BgOutlineBlackButton
                onClick={() => handleGenerateBillButton(id)}
                children={"Generate Bill"}
              />
            ) : (
              <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
                <BgThemeButtonDisabled
                  onClick={() => handleGenerateBillButton(id)}
                  children={"Generate Bill"}
                />
              </Tooltip>
            )}
          </>
        );
      } else {
        billButton = (
          <>
            {/* {permissions && permissions.includes("view_kot_bill") ? ( */}
            <Link
              to={`/hotelier/kotlisting/bill/${id}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
              target="_blank"
            >
              <BgOutlineThemeButton children={"View Bill"} />
            </Link>
          </>
        );
      }

      return (
        <IndexTable.Row
          id={id}
          key={id}
          selected={selectedResources.includes(id)}
          position={index}
        >
          <IndexTable.Cell>{`KOT-${kot_no ? kot_no : ""}`}</IndexTable.Cell>
          <IndexTable.Cell>
            <TextDateFormatter as={"span"} date={kot_date} showYear={true} />
          </IndexTable.Cell>
          <IndexTable.Cell>₹ {sub_total}</IndexTable.Cell>
          <IndexTable.Cell>{balance_amount}</IndexTable.Cell>
          <IndexTable.Cell>
            <KOTStatusCustomComp status={status}></KOTStatusCustomComp>
          </IndexTable.Cell>
          <IndexTable.Cell>{cancelButton}</IndexTable.Cell>
          <IndexTable.Cell>{billButton}</IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const deletedIds = {
    ids: selectedResources,
  };

  const removeItemConfirm = async () => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/kots/delete/${property_id?.id}`,
        {
          method: "DELETE",
          body: JSON.stringify(deletedIds),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        showSuccessToast(data.message);
        clearSelection();
        setShowModelMenuActive(false);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  // useEffect(() => {
  //     setKots((prevStocks) => ({
  //         ...prevStocks,
  //         table_no: '',
  //         room_no: '',
  //         unique_booking_id: "",
  //     }));
  //     // setSelectedRoomGuestDetails(null)
  // }, [kots.kot_type]);

  const fetchInfo = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/kots/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      const sortedData = data.kot?.sort(
        (a, b) => new Date(b.kot_date) - new Date(a.kot_date)
      );
      setOrders(sortedData);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/kots/edit/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        kot_type: data.kot.kot_type,
        payment_type: data.kot.payment_type,
        table_no: data.kot.table_no,
        room_no: data.kot.room_no,
        paid_amount: data.kot.paid_amount,
        kot_date: data.kot.kot_date,
        sub_total: data.kot.sub_total,
        discount: data.kot.discount,
        balance_amount: data.kot.balance_amount,
        gst: data.kot.gst,
        gst_type: data.kot.gst_type,
        grand_total: data.kot.grand_total,
        item_details: [],
      };
      setKots(updatedData);
      data.kot.items.map((item, index) =>
        setItemDetails([
          {
            menue_item_id: item.menue_item_id,
            item_price: item.item_price,
            quantity: item.quantity,
            total_amount: item.total_amount,
          },
        ])
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateKOT = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/kots/update/${id}/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({ ...kots }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        clearSelection();
        showSuccessToast(data.message);
        setKots({
          kot_type: "",
          payment_type: "",
          paid_amount: "",
          table_no: "",
          room_no: "",
          kot_date: "",
          sub_total: "",
          discount: "",
          balance_amount: "",
          gst: "",
          gst_type: "",
          grand_total: "",
          balance_amount: "",
          item_details: [],
        });
        setShowEditModelMenuActive(false);
        // window.location.reload()
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const handleGenerateBillButton = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/kots/generate/bill/${id}/${property_id?.id}`,
        {
          // method: "POST",
          // body: JSON.stringify({ ...kots }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        clearSelection();
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const handleCancelStatusButton = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/kots/status/update/${id}/${property_id?.id}?status=cancelled`,
        {
          // method: "POST",
          // body: JSON.stringify({ status: "cancelled" }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        clearSelection();
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

   useEffect(() => {
    if (property_id  && userToken)  {
      fetchInfo(property_id?.id);
    }
  }, [property_id, userToken]);
  const [selectedKotStatus, setSelectedKotStatus] = useState([]);
  const handleKotFilterStatus = useCallback(
    (value) => setSelectedKotStatus(value),
    []
  );

  return (
    <>
      <div className="main_container">
        <div className="room-types-full-container-div">
          <div className="room-type-heading">Kot List</div>
        </div>
        <div className="flex flex_gap_20">
          <div
            style={{
              width: "20%",
              background: "white",
              borderRadius: "8px",
              height: "100%",
              padding: "10px",
              position: "sticky",
              top: "70px",
            }}
          >
            <ChoiceList
              allowMultiple
              title="Filter"
              choices={[
                { label: "All", value: "all" },
                { label: "Delivered", value: "delivered" },
                { label: "Undelivered", value: "undelivered" },
                { label: "Cancelled", value: "cancelled" },
              ]}
              selected={selectedKotStatus}
              onChange={handleKotFilterStatus}
            />
          </div>
          <div style={{ width: "80%" }}>
            {loader ? (
              <Loader />
            ) : (
              <>
                <div>
                  <LegacyCard>
                    {orders && orders.length > 0 ? (
                      <IndexTable
                        resourceName={resourceName}
                        itemCount={currentOrders.length}
                        selectedItemsCount={
                          allResourcesSelected
                            ? "All"
                            : selectedResources.length
                        }
                        onSelectionChange={handleSelectionChange}
                        headings={[
                          { title: "KOT No." },
                          { title: "Date" },
                          { title: "Total Amount" },
                          { title: "Balance" },
                          { title: "Status" },
                          { title: "" },
                          { title: "Bill" },
                        ]}
                      >
                        {rowMarkup}
                      </IndexTable>
                    ) : (
                      <NoDataFound />
                    )}

                    {/* Single select */}
                    {showLowerMenuSingleSelect && (
                      <div className=" room-type-bottom-container">
                        <div className="room-type-bottom-container-div">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              gap: "20px",
                            }}
                          >
                            {permissions &&
                            permissions.includes("delete_kot") ? (
                              <Button plain destructive onClick={showModelMenu}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "censter",
                                  }}
                                >
                                  <span>
                                    <Icon source={DeleteMajor} color="base" />
                                  </span>
                                  <span>Remove</span>
                                </div>
                              </Button>
                            ) : (
                              <Tooltip
                                content="Please Contact Your Admin"
                                dismissOnMouseOut
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    opacity: "0.4",
                                    cursor: "no-drop",
                                  }}
                                >
                                  <span>
                                    <Icon source={DeleteMajor} color="base" />
                                  </span>
                                  <span>Remove</span>
                                </div>
                              </Tooltip>
                            )}

                            {/* <BgThemeButton
                      children={"Edit"}
                      onClick={() => setShowEditModelMenuActive(!showEditModelMenuActive)}
                    /> */}
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Multiple select */}
                    {showLowerMenuMultipleSelect && (
                      <div className=" room-type-bottom-container">
                        <div className="room-type-bottom-container-div">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            {permissions &&
                            permissions.includes("delete_kot") ? (
                              <Button plain destructive onClick={showModelMenu}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "censter",
                                  }}
                                >
                                  <span>
                                    <Icon source={DeleteMajor} color="base" />
                                  </span>
                                  <span>Remove</span>
                                </div>
                              </Button>
                            ) : (
                              <Tooltip
                                content="Please Contact Your Admin"
                                dismissOnMouseOut
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    opacity: "0.4",
                                    cursor: "no-drop",
                                  }}
                                >
                                  <span>
                                    <Icon source={DeleteMajor} color="base" />
                                  </span>
                                  <span>Remove</span>
                                </div>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </LegacyCard>

                  <CustomPagination
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    totalItems={orders.length}
                    onPageChange={handlePageChange}
                    clearSelection={clearSelection}
                  />
                </div>

                {showModelMenuActive && (
                  <>
                    <div className="bg-backdrop">
                      <section className="room-type-model-container ">
                        <h4>Are You Sure?</h4>
                        <p>You Want To Remove?</p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Button
                            onClick={() =>
                              setShowModelMenuActive(!showModelMenuActive)
                            }
                          >
                            Discard
                          </Button>
                          <Button onClick={removeItemConfirm} destructive>
                            Yes, Remove
                          </Button>
                        </div>
                      </section>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FoodKotList;
