import React, { useEffect, useRef, useState } from "react";
import styles from "./tooltipComponent.module.scss"; // Add necessary styles

const TooltipComponent = ({ text, maxLength }) => {
  const tooltipRef = useRef(null);
  const [containerClass, setContainerClass] = useState(
    styles["tooltip-container-top"]
  );

  useEffect(() => {
    if (tooltipRef) {
      const { top, right, bottom, left, width, height } =
        tooltipRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      let newPosition = styles["tooltip-container-top"]; 

      if (top < height) {
        newPosition = styles["tooltip-container-bottom"]; 
      } else if (right + width > viewportWidth) {
        newPosition = styles["tooltip-container-left"]; 
      } else if (left < width) {
        newPosition = styles["tooltip-container-right"];
      }

      setContainerClass(newPosition);
    }
  }, []);

  const truncatedText =
    text.length > maxLength ? text.slice(0, maxLength) + "..." : text;

  return (
    <div className={containerClass} ref={tooltipRef}>
      {truncatedText}
      <div
        className={`${styles["tooltip-text"]}   ${styles["tooltipFontStyle"]}`}
      >
        {text}
      </div>
    </div>
  );
};

export default TooltipComponent;
