import CryptoJS from 'crypto-js';


const secretKey = process.env.REACT_APP_SECRET_KEY;



export const saveEncryptedData = (keyName, data) => {
    const dataToEncrypt = typeof data === 'string' ? data : JSON.stringify(data);
    const encryptedToken = CryptoJS.AES.encrypt(dataToEncrypt, secretKey).toString();
    localStorage.setItem(keyName, encryptedToken);
};

export const getDecryptedData = (keyName) => {
    const encryptedData = localStorage.getItem(keyName);

    if (!encryptedData) {
        return null; // If no data found
    }

    try {
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);

        // If data was stored as JSON, parse it back to an object
        return decryptedData;
    } catch (error) {
        console.error('Error decrypting data:', error);
        return null;
    }
};
