import React, { useEffect, useState } from "react";
import styles from "./PropertyTargets.module.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useParams } from "react-router-dom";

// import { PrimaryInput } from '../NewCustomComp/Inputs/Inputs';
// import { PrimaryButton } from '../NewCustomComp/Buttons/Buttons';

import { CurtainTable } from "../NewCustomComp/Curtain/Curtain";
import { SvgSettings } from "../../assets/svgIcons";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { getBaseUrl, getDateAndMonth } from "../../utils/utils";
// import { current } from '@reduxjs/toolkit';
import { BgOutlineThemeButton, BgThemeButton } from "../UI/Buttons";
// import AddPropertyTargets from './AddPropertyTarget';
import AddPropertyTarget from "./AddPropertyTarget";
import { useSelector } from "react-redux";
import Loader from "../UI/Loader/Loader";
import NoDataFound from "../CustomComponents/NoDataFound";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const PropertyTargets = () => {
  const [loading, setLoading] = useState("");
  const baseUrl = getBaseUrl();
  const pathname = useParams();
  const { propertyId } = pathname;

  // Arrays
  const tableHeading = [
    { heading: "Months" },
    { heading: "Occupancy (%)" },
    { heading: "ADR (₹)"},
    { heading: "RevPAR (₹)" },
    { heading: "Revenue (₹)" },
  ];
  const tableHeadingSlider = [
    { heading: "Date" },
    { heading: "Occupancy  (%)" },
    { heading: "ADR (₹)" },
    { heading: "RevPAR (₹)" },
    { heading: "Revenue (₹)" },
  ];

  // Array End

  // Curtain Function
  const [show, setShow] = useState("");
  const [activeMonth, setActiveMonth] = useState(null);
  const handleCurtain = (month, start, end) => {
    if (!month) {
      setActiveMonth();
      setShow(false);
    } else {
      if (activeMonth) {
        setActiveMonth();
        setShow(false);
      } else {
        setActiveMonth(month === activeMonth ? null : month);
        setShow(true);
        // fetch api call
        fetchDaysReport(start, end);
      }
    }
  };

  // Curtain end

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>API's<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // Listing Api (Months)
  const property_id = useSelector((state) => state.property.value)?.id;

  const [tableData, setTableData] = useState([]);

  const fetchPropertyReports = async (id, year) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("property_id", id);
    formData.append("year", year);

    const requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/admin/reports-monthly`,
        requestOptions
      );
      const data = await response.json();
      if (data.status) {
        setTableData(data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Listing Api (Days)
  const [daysData, setDaysData] = useState("");
  const fetchDaysReport = async (start, end) => {
    const requestOptions = {
      method: "POST",
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/admin/reports-monthly-detailed?property_id=${property_id}&year=2024&start_date=${start}&end_date=${end}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        setDaysData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Get Settings Data Api
  const [getSettings, setGetSettings] = useState({});
  const fetchSettingsData = async (id) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/admin/fetch-property-targets?property_id=${id}`,
        requestOptions
      );
      const result = await response.json();
      if (result.success) {
        setGetSettings(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //     if (property_id) {
  //         fetchPropertyReports(property_id, new Date().getFullYear())
  //     }
  // }, [property_id])
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>API's End<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  useEffect(() => {
    if (property_id) {
      fetchPropertyReports(property_id, new Date().getFullYear());
    }
  }, [property_id]);
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>API's End<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // Setting Popup
  const [settings, setSettings] = useState(false);
  const handleSettings = () => {
    setSettings(!settings);
  };

  // Switch Form
  const [switchForm, setSwitchForm] = useState(false);
  const handleSwitch = () => {
    setSwitchForm(!switchForm);
  };

  // RupeeToPercentage
  const [switchValues, setSwitchValues] = useState("number");
  const handelSwitchValues = () => {
    if (switchValues === "number") {
      setSwitchValues("percentage");
    } else if (switchValues === "percentage") {
      setSwitchValues("number");
    }
  };

  const [addTargetsModel, setAddTargetsModel] = useState(false);
  const handleAddTarget = () => {
    setAddTargetsModel(!addTargetsModel);
  };

  // recommended



  return (
    <>

      {loading ? (
        <Loader />
      ) : (
        <>
          {tableData && tableData?.tableContent?.length > 0 ? (
            <div className={styles.targetsMain}>
              <div className="flex flex_gap_10 align_items_center justify_content_between mb-20 ">
                <h4 className="room-type-heading align_items_center">
                  Yearly Targets
                </h4>
                <div className={`flex flex_gap_10 align_items_center`}>
                  {tableData.targetExist ? (
                    <BgThemeButton
                      onClick={() => {
                        handleSettings();
                        // fetchPropertySettings();
                        fetchSettingsData(property_id);
                      }}
                    >
                      Edit Target
                    </BgThemeButton>
                  ) : (
                    <BgThemeButton onClick={() => setAddTargetsModel(true)}>
                      Add Targets
                    </BgThemeButton>
                  )}
                </div>
              </div>
              {addTargetsModel && (
                <div className="bg-backdrop">
                  <div
                    className={`${styles.popAddTargets} ${addTargetsModel ? styles.show : styles.hide
                      }`}
                  >
                    <AddPropertyTarget
                      closePopup={handleAddTarget}
                      refreshListApi={fetchPropertyReports}
                      fetchSettingsData={fetchSettingsData}
                    />
                  </div>
                </div>
              )}

              {settings && (
                <div className="bg-backdrop">
                  <div
                    className={`${styles.popAddTargets} ${settings ? styles.show : styles.hide
                      }`}
                  >
                    <AddPropertyTarget
                      getSettings={getSettings}
                      closePopup={handleSettings}
                      settings={true}
                      refreshListApi={fetchPropertyReports}
                    />
                  </div>
                </div>
              )}

              {tableData.targetExist ? (
                <>
                  <div className={`${styles.graphsWrapper} mt-10`}>
                    {tableData &&
                      tableData?.graphData?.length > 0 &&
                      tableData.graphData.map((items, index) => {
                        const data = {
                          labels: items.GraphCurr,
                          datasets: [
                            {
                              label: "Current",
                              data: items.GraphCurr,
                              borderColor: "#15AA12",
                              backgroundColor: "#15AA12",
                              tension: 0.4,
                              pointRadius: 0,
                              // fill:items.color
                              // pointHoverRadius: 0,
                            },
                            {
                              label: "Target",
                              data: items.GraphTrg,
                              borderColor: "#FF385C",
                              backgroundColor: "#FF385C",
                              tension: 0.4,
                              pointRadius: 0,
                              // fill:items.color
                              // pointHoverRadius: 0,
                            },
                          ],
                        };
                        const options = {
                          plugins: {
                            legend: {
                              display: false, // Disable legend (label box)
                            },
                          },
                          scales: {
                            x: {
                              display: false,
                              grid: {
                                display: false, // Hide grid lines on x-axis
                              },
                            },
                            y: {
                              display: false,
                              grid: {
                                display: false, // Hide grid lines on y-axis
                              },
                            },
                          },
                        };
                        return (
                          <div
                            key={index}
                            className={`${styles.targetsGraphs} ${styles.whiteWrapper}`}
                          >
                            <div className={styles.graphHeading}>
                              <h5>{items.title}</h5>
                              <h2>{items.percentage}</h2>
                            </div>
                            <div className={styles.graphImg}>
                              <Line options={options} data={data} />
                            </div>
                          </div>
                        );
                      })}
                  </div>

                  <div className={`${styles.tableWrapper}`}>
                    <table className={`${styles.tableMain}`}>
                      <thead>
                        <tr>
                          {tableHeading.map((items, index) => (
                            <th className={`${styles.tableHeading}`} key={index}>
                              {items.heading}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {tableData &&
                          tableData?.tableContent?.length > 0 &&
                          tableData?.tableContent.map((items, index) => (
                            <tr key={index}>
                              <td
                                style={{ borderLeft: "none" }}
                                className={`${styles.tableDataTd} ${styles.tableDataMonth
                                  } ${activeMonth === items.month ? styles.active : ""
                                  }`}
                              >
                                <div>{items.month}</div>
                                <div
                                  className="cursor-pointer"
                                  onClick={() =>
                                    handleCurtain(
                                      items.month,
                                      items.start_date,
                                      items.end_date
                                    )
                                  }
                                >
                                  <svg
                                    style={
                                      activeMonth === items.month
                                        ? { transform: "rotate(180deg)" }
                                        : {}
                                    }
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="8"
                                      cy="8.5"
                                      r="7.5"
                                      fill={
                                        activeMonth === items.month
                                          ? "#3968ED"
                                          : "white"
                                      }
                                      stroke={
                                        activeMonth === items.month ? "" : "#3968ED"
                                      }
                                    />
                                    <path
                                      d="M6.40039 5.69995L9.20039 8.49995L6.40039 11.3"
                                      stroke={
                                        activeMonth === items.month
                                          ? "white"
                                          : "#3968ED"
                                      }
                                      strokeWidth="1.2"
                                    />
                                  </svg>
                                </div>
                              </td>
                              <td className={`${styles.tableDataTd}`}>
                                <div className={styles.innerData}>
                                  <table className={styles.innerTable}>
                                    <tbody>
                                      <tr>
                                        <td className={styles.innerHeading}>
                                          Targeted
                                        </td>
                                        <td className={styles.innerHeading}>
                                          Achieved
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className={styles.innerTd}>
                                          {items.occTrg.toFixed(2)}
                                        </td>
                                        <td className={styles.innerTd}>
                                          {items.occCurr.toFixed(2)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {/* {checkHighOrLow((items.occTrg),(items.occCurr))} */}
                                  {items.occTrg > items.occCurr ? (
                                    <div
                                      // onClick={handelSwitchValues}
                                      className={`${styles.overview} `}
                                    >
                                      <svg
                                        width="18"
                                        height="19"
                                        viewBox="0 0 18 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <mask
                                          id="mask0_11772_5052"
                                          style={{ maskType: "alpha" }}
                                          maskUnits="userSpaceOnUse"
                                          x="0"
                                          y="0"
                                          width="18"
                                          height="19"
                                        >
                                          <rect
                                            x="18"
                                            y="18.5"
                                            width="18"
                                            height="18"
                                            transform="rotate(-180 18 18.5)"
                                            fill="#D9D9D9"
                                          />
                                        </mask>
                                        <g mask="url(#mask0_11772_5052)">
                                          <path
                                            d="M12.9767 7.25002C13.1337 7.25002 13.2602 7.30747 13.3561 7.42236C13.452 7.53726 13.5 7.6713 13.5 7.82449C13.5 7.86279 13.4477 7.99683 13.343 8.22662L9.54942 12.3915C9.46221 12.4873 9.375 12.5543 9.28779 12.5926C9.20058 12.6309 9.10465 12.65 9 12.65C8.89535 12.65 8.79942 12.6309 8.71221 12.5926C8.625 12.5543 8.53779 12.4873 8.45058 12.3915L4.65698 8.22662C4.60465 8.16917 4.56541 8.10694 4.53925 8.03992C4.51308 7.9729 4.5 7.90109 4.5 7.82449C4.5 7.6713 4.54797 7.53726 4.6439 7.42236C4.73983 7.30747 4.86628 7.25002 5.02326 7.25002L12.9767 7.25002Z"
                                            fill="#E03838"
                                          />
                                        </g>
                                      </svg>

                                      {/* {switchValues === "number" ? (
                                        <span className={styles.overviewTxtRed}>
                                          {" "}
                                          ₹ {(Number(items.occDiff).toFixed(2).replace("-",''))}
                                        </span>
                                      ) : ( */}
                                        <span className={styles.overviewTxtRed}>
                                          {(items.occPercDiff).replace("-",'')}
                                        </span>
                                      {/* )} */}
                                    </div>
                                  ) : (
                                    <div
                                      onClick={handelSwitchValues}
                                      className={`${styles.overview} cursor-pointer`}
                                    >
                                      <svg
                                        width="18"
                                        height="19"
                                        viewBox="0 0 18 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <mask
                                          id="mask0_11762_3315"
                                          style={{ maskType: "alpha" }}
                                          maskUnits="userSpaceOnUse"
                                          x="0"
                                          y="0"
                                          width="18"
                                          height="19"
                                        >
                                          <rect
                                            y="0.5"
                                            width="18"
                                            height="18"
                                            fill="#D9D9D9"
                                          />
                                        </mask>
                                        <g mask="url(#mask0_11762_3315)">
                                          <path
                                            d="M5.02326 11.75C4.86628 11.75 4.73983 11.6925 4.6439 11.5776C4.54797 11.4627 4.5 11.3287 4.5 11.1755C4.5 11.1372 4.55233 11.0032 4.65698 10.7734L8.45058 6.60849C8.53779 6.51274 8.625 6.44572 8.71221 6.40742C8.79942 6.36912 8.89535 6.34998 9 6.34998C9.10465 6.34998 9.20058 6.36912 9.28779 6.40742C9.375 6.44572 9.46221 6.51274 9.54942 6.60849L13.343 10.7734C13.3953 10.8308 13.4346 10.8931 13.4608 10.9601C13.4869 11.0271 13.5 11.0989 13.5 11.1755C13.5 11.3287 13.452 11.4627 13.3561 11.5776C13.2602 11.6925 13.1337 11.75 12.9767 11.75H5.02326Z"
                                            fill="#15AA12"
                                          />
                                        </g>
                                      </svg>

                                      {switchValues === "number" ? (
                                        <span className={styles.overviewTxt}>
                                          ₹ {Number(items.occDiff).toFixed(2)}
                                        </span>
                                      ) : (
                                        <span className={styles.overviewTxt}>
                                          {items.occPercDiff}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td className={`${styles.tableDataTd}`}>
                                <div className={styles.innerData}>
                                  <table className={styles.innerTable}>
                                    <tbody>
                                      <tr>
                                        <td className={styles.innerHeading}>
                                          Targeted
                                        </td>
                                        <td className={styles.innerHeading}>
                                          Achieved
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className={styles.innerTd}>
                                          {items.adrTrg.toFixed(2)}
                                        </td>
                                        <td className={styles.innerTd}>
                                          {items.adrCurr.toFixed(2)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {/* {checkHighOrLow((items.adrTrg),(items.adrCurr))} */}
                                  {items.adrTrg > items.adrCurr ? (
                                    <div
                                      onClick={handelSwitchValues}
                                      className={`${styles.overview} cursor-pointer`}
                                    >
                                      <svg
                                        width="18"
                                        height="19"
                                        viewBox="0 0 18 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <mask
                                          id="mask0_11772_5052"
                                          style={{ maskType: "alpha" }}
                                          maskUnits="userSpaceOnUse"
                                          x="0"
                                          y="0"
                                          width="18"
                                          height="19"
                                        >
                                          <rect
                                            x="18"
                                            y="18.5"
                                            width="18"
                                            height="18"
                                            transform="rotate(-180 18 18.5)"
                                            fill="#D9D9D9"
                                          />
                                        </mask>
                                        <g mask="url(#mask0_11772_5052)">
                                          <path
                                            d="M12.9767 7.25002C13.1337 7.25002 13.2602 7.30747 13.3561 7.42236C13.452 7.53726 13.5 7.6713 13.5 7.82449C13.5 7.86279 13.4477 7.99683 13.343 8.22662L9.54942 12.3915C9.46221 12.4873 9.375 12.5543 9.28779 12.5926C9.20058 12.6309 9.10465 12.65 9 12.65C8.89535 12.65 8.79942 12.6309 8.71221 12.5926C8.625 12.5543 8.53779 12.4873 8.45058 12.3915L4.65698 8.22662C4.60465 8.16917 4.56541 8.10694 4.53925 8.03992C4.51308 7.9729 4.5 7.90109 4.5 7.82449C4.5 7.6713 4.54797 7.53726 4.6439 7.42236C4.73983 7.30747 4.86628 7.25002 5.02326 7.25002L12.9767 7.25002Z"
                                            fill="#E03838"
                                          />
                                        </g>
                                      </svg>
                                      {switchValues === "number" ? (
                                        <span className={styles.overviewTxtRed}>
                                          ₹ {(Number(items.adrDiff).toFixed(2)).replace("-",'')}
                                        </span>
                                      ) : (
                                        <span className={styles.overviewTxtRed}>
                                          {(items.adrPercDiff).replace("-",'')}
                                        </span>
                                      )}
                                    </div>
                                  ) : (
                                    <div
                                      onClick={handelSwitchValues}
                                      className={`${styles.overview} cursor-pointer`}
                                    >
                                      <svg
                                        width="18"
                                        height="19"
                                        viewBox="0 0 18 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <mask
                                          id="mask0_11762_3315"
                                          style={{ maskType: "alpha" }}
                                          maskUnits="userSpaceOnUse"
                                          x="0"
                                          y="0"
                                          width="18"
                                          height="19"
                                        >
                                          <rect
                                            y="0.5"
                                            width="18"
                                            height="18"
                                            fill="#D9D9D9"
                                          />
                                        </mask>
                                        <g mask="url(#mask0_11762_3315)">
                                          <path
                                            d="M5.02326 11.75C4.86628 11.75 4.73983 11.6925 4.6439 11.5776C4.54797 11.4627 4.5 11.3287 4.5 11.1755C4.5 11.1372 4.55233 11.0032 4.65698 10.7734L8.45058 6.60849C8.53779 6.51274 8.625 6.44572 8.71221 6.40742C8.79942 6.36912 8.89535 6.34998 9 6.34998C9.10465 6.34998 9.20058 6.36912 9.28779 6.40742C9.375 6.44572 9.46221 6.51274 9.54942 6.60849L13.343 10.7734C13.3953 10.8308 13.4346 10.8931 13.4608 10.9601C13.4869 11.0271 13.5 11.0989 13.5 11.1755C13.5 11.3287 13.452 11.4627 13.3561 11.5776C13.2602 11.6925 13.1337 11.75 12.9767 11.75H5.02326Z"
                                            fill="#15AA12"
                                          />
                                        </g>
                                      </svg>
                                      {switchValues === "number" ? (
                                        <span className={styles.overviewTxt}>
                                          ₹ {Number(items.adrDiff).toFixed(2)}
                                        </span>
                                      ) : (
                                        <span className={styles.overviewTxt}>
                                          {items.adrPercDiff}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td className={`${styles.tableDataTd}`}>
                                <div className={styles.innerData}>
                                  <table className={styles.innerTable}>
                                    <tbody>
                                      <tr>
                                        <td className={styles.innerHeading}>
                                          Targeted
                                        </td>
                                        <td className={styles.innerHeading}>
                                          Achieved
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className={styles.innerTd}>
                                          {items.parTrg.toFixed(2)}
                                        </td>
                                        <td className={styles.innerTd}>
                                          {items.parCurr.toFixed(2)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  {/* {checkHighOrLow((items.parTrg),(items.parCurr))} */}
                                  {items.parTrg > items.parCurr ? (
                                    <div
                                      onClick={handelSwitchValues}
                                      className={`${styles.overview} cursor-pointer`}
                                    >
                                      <svg
                                        width="18"
                                        height="19"
                                        viewBox="0 0 18 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <mask
                                          id="mask0_11772_5052"
                                          style={{ maskType: "alpha" }}
                                          maskUnits="userSpaceOnUse"
                                          x="0"
                                          y="0"
                                          width="18"
                                          height="19"
                                        >
                                          <rect
                                            x="18"
                                            y="18.5"
                                            width="18"
                                            height="18"
                                            transform="rotate(-180 18 18.5)"
                                            fill="#D9D9D9"
                                          />
                                        </mask>
                                        <g mask="url(#mask0_11772_5052)">
                                          <path
                                            d="M12.9767 7.25002C13.1337 7.25002 13.2602 7.30747 13.3561 7.42236C13.452 7.53726 13.5 7.6713 13.5 7.82449C13.5 7.86279 13.4477 7.99683 13.343 8.22662L9.54942 12.3915C9.46221 12.4873 9.375 12.5543 9.28779 12.5926C9.20058 12.6309 9.10465 12.65 9 12.65C8.89535 12.65 8.79942 12.6309 8.71221 12.5926C8.625 12.5543 8.53779 12.4873 8.45058 12.3915L4.65698 8.22662C4.60465 8.16917 4.56541 8.10694 4.53925 8.03992C4.51308 7.9729 4.5 7.90109 4.5 7.82449C4.5 7.6713 4.54797 7.53726 4.6439 7.42236C4.73983 7.30747 4.86628 7.25002 5.02326 7.25002L12.9767 7.25002Z"
                                            fill="#E03838"
                                          />
                                        </g>
                                      </svg>
                                      {switchValues === "number" ? (
                                        <span className={styles.overviewTxtRed}>
                                          ₹ {(Number(items.parDiff).toFixed(2)).replace("-",'')}
                                        </span>
                                      ) : (
                                        <span className={styles.overviewTxtRed}>
                                          {(items.parPercDiff).replace("-",'')}
                                        </span>
                                      )}
                                    </div>
                                  ) : (
                                    <div
                                      onClick={handelSwitchValues}
                                      className={`${styles.overview} cursor-pointer`}
                                    >
                                      <svg
                                        width="18"
                                        height="19"
                                        viewBox="0 0 18 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <mask
                                          id="mask0_11762_3315"
                                          style={{ maskType: "alpha" }}
                                          maskUnits="userSpaceOnUse"
                                          x="0"
                                          y="0"
                                          width="18"
                                          height="19"
                                        >
                                          <rect
                                            y="0.5"
                                            width="18"
                                            height="18"
                                            fill="#D9D9D9"
                                          />
                                        </mask>
                                        <g mask="url(#mask0_11762_3315)">
                                          <path
                                            d="M5.02326 11.75C4.86628 11.75 4.73983 11.6925 4.6439 11.5776C4.54797 11.4627 4.5 11.3287 4.5 11.1755C4.5 11.1372 4.55233 11.0032 4.65698 10.7734L8.45058 6.60849C8.53779 6.51274 8.625 6.44572 8.71221 6.40742C8.79942 6.36912 8.89535 6.34998 9 6.34998C9.10465 6.34998 9.20058 6.36912 9.28779 6.40742C9.375 6.44572 9.46221 6.51274 9.54942 6.60849L13.343 10.7734C13.3953 10.8308 13.4346 10.8931 13.4608 10.9601C13.4869 11.0271 13.5 11.0989 13.5 11.1755C13.5 11.3287 13.452 11.4627 13.3561 11.5776C13.2602 11.6925 13.1337 11.75 12.9767 11.75H5.02326Z"
                                            fill="#15AA12"
                                          />
                                        </g>
                                      </svg>

                                      {switchValues === "number" ? (
                                        <span className={styles.overviewTxt}>
                                          ₹ {Number(items.parDiff).toFixed(2)}
                                        </span>
                                      ) : (
                                        <span className={styles.overviewTxt}>
                                          {items.parPercDiff}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td className={`${styles.tableDataTd}`}>
                                <div className={styles.innerData}>
                                  <table className={styles.innerTable}>
                                    <tbody>
                                      <tr>
                                        <td className={styles.innerHeading}>
                                          Targeted
                                        </td>
                                        <td className={styles.innerHeading}>
                                          Achieved
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className={styles.innerTd}>
                                          {items.revTrg.toFixed(2)}
                                        </td>
                                        <td className={styles.innerTd}>
                                          {items.revCurr.toFixed(2)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {/* {checkHighOrLow((items.revTrg),(items.revCurr))} */}
                                  {items.revTrg > items.revCurr ? (
                                    <div
                                      onClick={handelSwitchValues}
                                      className={`${styles.overview} cursor-pointer`}
                                    >
                                      <svg
                                        width="18"
                                        height="19"
                                        viewBox="0 0 18 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <mask
                                          id="mask0_11772_5052"
                                          style={{ maskType: "alpha" }}
                                          maskUnits="userSpaceOnUse"
                                          x="0"
                                          y="0"
                                          width="18"
                                          height="19"
                                        >
                                          <rect
                                            x="18"
                                            y="18.5"
                                            width="18"
                                            height="18"
                                            transform="rotate(-180 18 18.5)"
                                            fill="#D9D9D9"
                                          />
                                        </mask>
                                        <g mask="url(#mask0_11772_5052)">
                                          <path
                                            d="M12.9767 7.25002C13.1337 7.25002 13.2602 7.30747 13.3561 7.42236C13.452 7.53726 13.5 7.6713 13.5 7.82449C13.5 7.86279 13.4477 7.99683 13.343 8.22662L9.54942 12.3915C9.46221 12.4873 9.375 12.5543 9.28779 12.5926C9.20058 12.6309 9.10465 12.65 9 12.65C8.89535 12.65 8.79942 12.6309 8.71221 12.5926C8.625 12.5543 8.53779 12.4873 8.45058 12.3915L4.65698 8.22662C4.60465 8.16917 4.56541 8.10694 4.53925 8.03992C4.51308 7.9729 4.5 7.90109 4.5 7.82449C4.5 7.6713 4.54797 7.53726 4.6439 7.42236C4.73983 7.30747 4.86628 7.25002 5.02326 7.25002L12.9767 7.25002Z"
                                            fill="#E03838"
                                          />
                                        </g>
                                      </svg>
                                      {switchValues === "number" ? (
                                        <span className={styles.overviewTxtRed}>
                                          ₹ {(Number(items.revDiff).toFixed(2)).replace("-",'')}
                                        </span>
                                      ) : (
                                        <span className={styles.overviewTxtRed}>
                                          {(items.revPercDiff).replace("-",'')}
                                        </span>
                                      )}
                                    </div>
                                  ) : (
                                    <div
                                      onClick={handelSwitchValues}
                                      className={`${styles.overview} cursor-pointer`}
                                    >
                                      <svg
                                        width="18"
                                        height="19"
                                        viewBox="0 0 18 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <mask
                                          id="mask0_11762_3315"
                                          style={{ maskType: "alpha" }}
                                          maskUnits="userSpaceOnUse"
                                          x="0"
                                          y="0"
                                          width="18"
                                          height="19"
                                        >
                                          <rect
                                            y="0.5"
                                            width="18"
                                            height="18"
                                            fill="#D9D9D9"
                                          />
                                        </mask>
                                        <g mask="url(#mask0_11762_3315)">
                                          <path
                                            d="M5.02326 11.75C4.86628 11.75 4.73983 11.6925 4.6439 11.5776C4.54797 11.4627 4.5 11.3287 4.5 11.1755C4.5 11.1372 4.55233 11.0032 4.65698 10.7734L8.45058 6.60849C8.53779 6.51274 8.625 6.44572 8.71221 6.40742C8.79942 6.36912 8.89535 6.34998 9 6.34998C9.10465 6.34998 9.20058 6.36912 9.28779 6.40742C9.375 6.44572 9.46221 6.51274 9.54942 6.60849L13.343 10.7734C13.3953 10.8308 13.4346 10.8931 13.4608 10.9601C13.4869 11.0271 13.5 11.0989 13.5 11.1755C13.5 11.3287 13.452 11.4627 13.3561 11.5776C13.2602 11.6925 13.1337 11.75 12.9767 11.75H5.02326Z"
                                            fill="#15AA12"
                                          />
                                        </g>
                                      </svg>

                                      {switchValues === "number" ? (
                                        <span className={styles.overviewTxt}>
                                          ₹ {Number(items.revDiff).toFixed(2)}
                                        </span>
                                      ) : (
                                        <span className={styles.overviewTxt}>
                                          {items.revPercDiff}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>

                    <div
                      className={`${styles.curtainMain} ${show === false
                        ? styles.slideOff
                        : show === true
                          ? styles.slideOn
                          : ""
                        }`}
                    >
                      <CurtainTable handleClose={handleCurtain}>
                        <table
                          className={`${styles.tableMain} ${styles.curtainTable_main}`}
                        >
                          <thead>
                            <tr>
                              {tableHeadingSlider.map((items, index) => (
                                <th
                                  className={`${styles.tableHeading}`}
                                  key={index}
                                >
                                  {items.heading}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {daysData &&
                              daysData?.tableContent?.length > 0 &&
                              daysData.tableContent.map((items, index) => (
                                // {tableContent.map((items,index)=>
                                <tr key={index}>
                                  <td
                                    style={{ borderLeft: "none" }}
                                    className={`${styles.tableDataTd} ${styles.tableDataMonth}`}
                                  >
                                    <div>{getDateAndMonth(items.date)}</div>
                                  </td>
                                  <td className={`${styles.tableDataTd}`}>
                                    <div className={styles.innerData}>
                                      <table className={styles.innerTable}>
                                        <tbody>
                                          <tr>
                                            <td className={styles.innerHeading}>
                                              Targeted
                                            </td>
                                            <td className={styles.innerHeading}>
                                              Achieved
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className={styles.innerTd}>
                                              {items.occTrg.toFixed(2)}
                                            </td>
                                            <td className={styles.innerTd}>
                                              {items.occCurr.toFixed(2)}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      {/* {checkHighOrLow((items.occTrg),(items.occCurr))} */}
                                      {items.occTrg > items.occCurr ? (
                                        <div
                                          // onClick={handelSwitchValues}
                                          className={`${styles.overview}`}
                                        >
                                          <svg
                                            width="18"
                                            height="19"
                                            viewBox="0 0 18 19"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <mask
                                              id="mask0_11772_5052"
                                              style={{ maskType: "alpha" }}
                                              maskUnits="userSpaceOnUse"
                                              x="0"
                                              y="0"
                                              width="18"
                                              height="19"
                                            >
                                              <rect
                                                x="18"
                                                y="18.5"
                                                width="18"
                                                height="18"
                                                transform="rotate(-180 18 18.5)"
                                                fill="#D9D9D9"
                                              />
                                            </mask>
                                            <g mask="url(#mask0_11772_5052)">
                                              <path
                                                d="M12.9767 7.25002C13.1337 7.25002 13.2602 7.30747 13.3561 7.42236C13.452 7.53726 13.5 7.6713 13.5 7.82449C13.5 7.86279 13.4477 7.99683 13.343 8.22662L9.54942 12.3915C9.46221 12.4873 9.375 12.5543 9.28779 12.5926C9.20058 12.6309 9.10465 12.65 9 12.65C8.89535 12.65 8.79942 12.6309 8.71221 12.5926C8.625 12.5543 8.53779 12.4873 8.45058 12.3915L4.65698 8.22662C4.60465 8.16917 4.56541 8.10694 4.53925 8.03992C4.51308 7.9729 4.5 7.90109 4.5 7.82449C4.5 7.6713 4.54797 7.53726 4.6439 7.42236C4.73983 7.30747 4.86628 7.25002 5.02326 7.25002L12.9767 7.25002Z"
                                                fill="#E03838"
                                              />
                                            </g>
                                          </svg>

                                          {/* {switchValues === "number" ? (
                                            <span className={styles.overviewTxtRed}>
                                              {" "}
                                              ₹ {(Number(items.occDiff).toFixed(2)).replace("-",'')}
                                            </span>
                                          ) : ( */}
                                            <span className={styles.overviewTxtRed}>
                                              {(items.occPercDiff).replace("-",'')}
                                            </span>
                                          {/* )} */}
                                        </div>
                                      ) : (
                                        <div

                                          onClick={handelSwitchValues}
                                          className={`${styles.overview} cursor-pointer`}
                                        >
                                          <svg
                                            width="18"
                                            height="19"
                                            viewBox="0 0 18 19"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <mask
                                              id="mask0_11762_3315"
                                              style={{ maskType: "alpha" }}
                                              maskUnits="userSpaceOnUse"
                                              x="0"
                                              y="0"
                                              width="18"
                                              height="19"
                                            >
                                              <rect
                                                y="0.5"
                                                width="18"
                                                height="18"
                                                fill="#D9D9D9"
                                              />
                                            </mask>
                                            <g mask="url(#mask0_11762_3315)">
                                              <path
                                                d="M5.02326 11.75C4.86628 11.75 4.73983 11.6925 4.6439 11.5776C4.54797 11.4627 4.5 11.3287 4.5 11.1755C4.5 11.1372 4.55233 11.0032 4.65698 10.7734L8.45058 6.60849C8.53779 6.51274 8.625 6.44572 8.71221 6.40742C8.79942 6.36912 8.89535 6.34998 9 6.34998C9.10465 6.34998 9.20058 6.36912 9.28779 6.40742C9.375 6.44572 9.46221 6.51274 9.54942 6.60849L13.343 10.7734C13.3953 10.8308 13.4346 10.8931 13.4608 10.9601C13.4869 11.0271 13.5 11.0989 13.5 11.1755C13.5 11.3287 13.452 11.4627 13.3561 11.5776C13.2602 11.6925 13.1337 11.75 12.9767 11.75H5.02326Z"
                                                fill="#15AA12"
                                              />
                                            </g>
                                          </svg>

                                          {switchValues === "number" ? (
                                            <span className={styles.overviewTxt}>
                                              ₹ {Number(items.occDiff).toFixed(2)}
                                            </span>
                                          ) : (
                                            <span className={styles.overviewTxt}>
                                              {items.occPercDiff}
                                            </span>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                  <td className={`${styles.tableDataTd}`}>
                                    <div className={styles.innerData}>
                                      <table className={styles.innerTable}>
                                        <tbody>
                                          <tr>
                                            <td className={styles.innerHeading}>
                                              Targeted
                                            </td>
                                            <td className={styles.innerHeading}>
                                              Achieved
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className={styles.innerTd}>
                                              {+items.adrTrg.toFixed(2)}
                                            </td>
                                            <td className={styles.innerTd}>
                                              {+items.adrCurr.toFixed(2)}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      {/* {checkHighOrLow((items.adrTrg),(items.adrCurr))} */}
                                      {items.adrTrg > items.adrCurr ? (
                                        <div

                                          onClick={handelSwitchValues}
                                          className={`${styles.overview} cursor-pointer`}
                                        >
                                          <svg
                                            width="18"
                                            height="19"
                                            viewBox="0 0 18 19"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <mask
                                              id="mask0_11772_5052"
                                              style={{ maskType: "alpha" }}
                                              maskUnits="userSpaceOnUse"
                                              x="0"
                                              y="0"
                                              width="18"
                                              height="19"
                                            >
                                              <rect
                                                x="18"
                                                y="18.5"
                                                width="18"
                                                height="18"
                                                transform="rotate(-180 18 18.5)"
                                                fill="#D9D9D9"
                                              />
                                            </mask>
                                            <g mask="url(#mask0_11772_5052)">
                                              <path
                                                d="M12.9767 7.25002C13.1337 7.25002 13.2602 7.30747 13.3561 7.42236C13.452 7.53726 13.5 7.6713 13.5 7.82449C13.5 7.86279 13.4477 7.99683 13.343 8.22662L9.54942 12.3915C9.46221 12.4873 9.375 12.5543 9.28779 12.5926C9.20058 12.6309 9.10465 12.65 9 12.65C8.89535 12.65 8.79942 12.6309 8.71221 12.5926C8.625 12.5543 8.53779 12.4873 8.45058 12.3915L4.65698 8.22662C4.60465 8.16917 4.56541 8.10694 4.53925 8.03992C4.51308 7.9729 4.5 7.90109 4.5 7.82449C4.5 7.6713 4.54797 7.53726 4.6439 7.42236C4.73983 7.30747 4.86628 7.25002 5.02326 7.25002L12.9767 7.25002Z"
                                                fill="#E03838"
                                              />
                                            </g>
                                          </svg>
                                          {switchValues === "number" ? (
                                            <span className={styles.overviewTxtRed}>
                                              ₹ {(Number(items.adrDiff).toFixed(2)).replace("-",'')}
                                            </span>
                                          ) : (
                                            <span className={styles.overviewTxtRed}>
                                              {(items.adrPercDiff).replace("-",'')}
                                            </span>
                                          )}
                                        </div>
                                      ) : (
                                        <div
                                          onClick={handelSwitchValues}
                                          className={`${styles.overview} cursor-pointer`}
                                        >
                                          <svg
                                            width="18"
                                            height="19"
                                            viewBox="0 0 18 19"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <mask
                                              id="mask0_11762_3315"
                                              style={{ maskType: "alpha" }}
                                              maskUnits="userSpaceOnUse"
                                              x="0"
                                              y="0"
                                              width="18"
                                              height="19"
                                            >
                                              <rect
                                                y="0.5"
                                                width="18"
                                                height="18"
                                                fill="#D9D9D9"
                                              />
                                            </mask>
                                            <g mask="url(#mask0_11762_3315)">
                                              <path
                                                d="M5.02326 11.75C4.86628 11.75 4.73983 11.6925 4.6439 11.5776C4.54797 11.4627 4.5 11.3287 4.5 11.1755C4.5 11.1372 4.55233 11.0032 4.65698 10.7734L8.45058 6.60849C8.53779 6.51274 8.625 6.44572 8.71221 6.40742C8.79942 6.36912 8.89535 6.34998 9 6.34998C9.10465 6.34998 9.20058 6.36912 9.28779 6.40742C9.375 6.44572 9.46221 6.51274 9.54942 6.60849L13.343 10.7734C13.3953 10.8308 13.4346 10.8931 13.4608 10.9601C13.4869 11.0271 13.5 11.0989 13.5 11.1755C13.5 11.3287 13.452 11.4627 13.3561 11.5776C13.2602 11.6925 13.1337 11.75 12.9767 11.75H5.02326Z"
                                                fill="#15AA12"
                                              />
                                            </g>
                                          </svg>
                                          {switchValues === "number" ? (
                                            <span className={styles.overviewTxt}>
                                              ₹ {Number(items.adrDiff).toFixed(2)}
                                            </span>
                                          ) : (
                                            <span className={styles.overviewTxt}>
                                              {items.adrPercDiff}
                                            </span>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                  <td className={`${styles.tableDataTd}`}>
                                    <div className={styles.innerData}>
                                      <table className={styles.innerTable}>
                                        <tbody>
                                          <tr>
                                            <td className={styles.innerHeading}>
                                              Targeted
                                            </td>
                                            <td className={styles.innerHeading}>
                                              Achieved
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className={styles.innerTd}>
                                              {items.parTrg.toFixed(2)}
                                            </td>
                                            <td className={styles.innerTd}>
                                              {items.parCurr.toFixed(2)}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      {/* {checkHighOrLow((items.parTrg),(items.parCurr))} */}
                                      {items.parTrg > items.parCurr ? (
                                        <div
                                          onClick={handelSwitchValues}
                                          className={`${styles.overview} cursor-pointer`}
                                        >
                                          <svg
                                            width="18"
                                            height="19"
                                            viewBox="0 0 18 19"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <mask
                                              id="mask0_11772_5052"
                                              style={{ maskType: "alpha" }}
                                              maskUnits="userSpaceOnUse"
                                              x="0"
                                              y="0"
                                              width="18"
                                              height="19"
                                            >
                                              <rect
                                                x="18"
                                                y="18.5"
                                                width="18"
                                                height="18"
                                                transform="rotate(-180 18 18.5)"
                                                fill="#D9D9D9"
                                              />
                                            </mask>
                                            <g mask="url(#mask0_11772_5052)">
                                              <path
                                                d="M12.9767 7.25002C13.1337 7.25002 13.2602 7.30747 13.3561 7.42236C13.452 7.53726 13.5 7.6713 13.5 7.82449C13.5 7.86279 13.4477 7.99683 13.343 8.22662L9.54942 12.3915C9.46221 12.4873 9.375 12.5543 9.28779 12.5926C9.20058 12.6309 9.10465 12.65 9 12.65C8.89535 12.65 8.79942 12.6309 8.71221 12.5926C8.625 12.5543 8.53779 12.4873 8.45058 12.3915L4.65698 8.22662C4.60465 8.16917 4.56541 8.10694 4.53925 8.03992C4.51308 7.9729 4.5 7.90109 4.5 7.82449C4.5 7.6713 4.54797 7.53726 4.6439 7.42236C4.73983 7.30747 4.86628 7.25002 5.02326 7.25002L12.9767 7.25002Z"
                                                fill="#E03838"
                                              />
                                            </g>
                                          </svg>
                                          {switchValues === "number" ? (
                                            <span className={styles.overviewTxtRed}>
                                              ₹ {(Number(items.parDiff).toFixed(2)).replace("-",'')}
                                            </span>
                                          ) : (
                                            <span className={styles.overviewTxtRed}>
                                              {(items.parPercDiff).replace("-",'')}
                                            </span>
                                          )}
                                        </div>
                                      ) : (
                                        <div
                                          onClick={handelSwitchValues}
                                          className={`${styles.overview} cursor-pointer`}
                                        >
                                          <svg
                                            width="18"
                                            height="19"
                                            viewBox="0 0 18 19"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <mask
                                              id="mask0_11762_3315"
                                              style={{ maskType: "alpha" }}
                                              maskUnits="userSpaceOnUse"
                                              x="0"
                                              y="0"
                                              width="18"
                                              height="19"
                                            >
                                              <rect
                                                y="0.5"
                                                width="18"
                                                height="18"
                                                fill="#D9D9D9"
                                              />
                                            </mask>
                                            <g mask="url(#mask0_11762_3315)">
                                              <path
                                                d="M5.02326 11.75C4.86628 11.75 4.73983 11.6925 4.6439 11.5776C4.54797 11.4627 4.5 11.3287 4.5 11.1755C4.5 11.1372 4.55233 11.0032 4.65698 10.7734L8.45058 6.60849C8.53779 6.51274 8.625 6.44572 8.71221 6.40742C8.79942 6.36912 8.89535 6.34998 9 6.34998C9.10465 6.34998 9.20058 6.36912 9.28779 6.40742C9.375 6.44572 9.46221 6.51274 9.54942 6.60849L13.343 10.7734C13.3953 10.8308 13.4346 10.8931 13.4608 10.9601C13.4869 11.0271 13.5 11.0989 13.5 11.1755C13.5 11.3287 13.452 11.4627 13.3561 11.5776C13.2602 11.6925 13.1337 11.75 12.9767 11.75H5.02326Z"
                                                fill="#15AA12"
                                              />
                                            </g>
                                          </svg>

                                          {switchValues === "number" ? (
                                            <span className={styles.overviewTxt}>
                                              ₹ {Number(items.parDiff).toFixed(2)}
                                            </span>
                                          ) : (
                                            <span className={styles.overviewTxt}>
                                              {items.parPercDiff}
                                            </span>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                  <td className={`${styles.tableDataTd}`}>
                                    <div className={styles.innerData}>
                                      <table className={styles.innerTable}>
                                        <tbody>
                                          <tr>
                                            <td className={styles.innerHeading}>
                                              Targeted
                                            </td>
                                            <td className={styles.innerHeading}>
                                              Achieved
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className={styles.innerTd}>
                                              {items.revTrg.toFixed(2)}
                                            </td>
                                            <td className={styles.innerTd}>
                                              {items.revCurr.toFixed(2)}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      {/* {checkHighOrLow((items.revTrg),(items.revCurr))} */}
                                      {items.revTrg > items.revCurr ? (
                                        <div
                                          onClick={handelSwitchValues}
                                          className={`${styles.overview} cursor-pointer`}
                                        >
                                          <svg
                                            width="18"
                                            height="19"
                                            viewBox="0 0 18 19"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <mask
                                              id="mask0_11772_5052"
                                              style={{ maskType: "alpha" }}
                                              maskUnits="userSpaceOnUse"
                                              x="0"
                                              y="0"
                                              width="18"
                                              height="19"
                                            >
                                              <rect
                                                x="18"
                                                y="18.5"
                                                width="18"
                                                height="18"
                                                transform="rotate(-180 18 18.5)"
                                                fill="#D9D9D9"
                                              />
                                            </mask>
                                            <g mask="url(#mask0_11772_5052)">
                                              <path
                                                d="M12.9767 7.25002C13.1337 7.25002 13.2602 7.30747 13.3561 7.42236C13.452 7.53726 13.5 7.6713 13.5 7.82449C13.5 7.86279 13.4477 7.99683 13.343 8.22662L9.54942 12.3915C9.46221 12.4873 9.375 12.5543 9.28779 12.5926C9.20058 12.6309 9.10465 12.65 9 12.65C8.89535 12.65 8.79942 12.6309 8.71221 12.5926C8.625 12.5543 8.53779 12.4873 8.45058 12.3915L4.65698 8.22662C4.60465 8.16917 4.56541 8.10694 4.53925 8.03992C4.51308 7.9729 4.5 7.90109 4.5 7.82449C4.5 7.6713 4.54797 7.53726 4.6439 7.42236C4.73983 7.30747 4.86628 7.25002 5.02326 7.25002L12.9767 7.25002Z"
                                                fill="#E03838"
                                              />
                                            </g>
                                          </svg>
                                          {switchValues === "number" ? (
                                            <span className={styles.overviewTxtRed}>
                                              ₹ {(Number(items.revDiff).toFixed(2)).replace("-",'')}
                                            </span>
                                          ) : (
                                            <span className={styles.overviewTxtRed}>
                                              {(items.revPercDiff).replace("-",'')}
                                            </span>
                                          )}
                                        </div>
                                      ) : (
                                        <div
                                          onClick={handelSwitchValues}
                                          className={`${styles.overview} cursor-pointer`}
                                        >
                                          <svg
                                            width="18"
                                            height="19"
                                            viewBox="0 0 18 19"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <mask
                                              id="mask0_11762_3315"
                                              style={{ maskType: "alpha" }}
                                              maskUnits="userSpaceOnUse"
                                              x="0"
                                              y="0"
                                              width="18"
                                              height="19"
                                            >
                                              <rect
                                                y="0.5"
                                                width="18"
                                                height="18"
                                                fill="#D9D9D9"
                                              />
                                            </mask>
                                            <g mask="url(#mask0_11762_3315)">
                                              <path
                                                d="M5.02326 11.75C4.86628 11.75 4.73983 11.6925 4.6439 11.5776C4.54797 11.4627 4.5 11.3287 4.5 11.1755C4.5 11.1372 4.55233 11.0032 4.65698 10.7734L8.45058 6.60849C8.53779 6.51274 8.625 6.44572 8.71221 6.40742C8.79942 6.36912 8.89535 6.34998 9 6.34998C9.10465 6.34998 9.20058 6.36912 9.28779 6.40742C9.375 6.44572 9.46221 6.51274 9.54942 6.60849L13.343 10.7734C13.3953 10.8308 13.4346 10.8931 13.4608 10.9601C13.4869 11.0271 13.5 11.0989 13.5 11.1755C13.5 11.3287 13.452 11.4627 13.3561 11.5776C13.2602 11.6925 13.1337 11.75 12.9767 11.75H5.02326Z"
                                                fill="#15AA12"
                                              />
                                            </g>
                                          </svg>

                                          {switchValues === "number" ? (
                                            <span className={styles.overviewTxt}>
                                              ₹ {Number(items.revDiff).toFixed(2)}
                                            </span>
                                          ) : (
                                            <span className={styles.overviewTxt}>
                                              {items.revPercDiff}
                                            </span>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </CurtainTable>
                    </div>
                  </div>
                </>
              ) : (
                <NoDataFound />
              )}
            </div>
          ) : (
            <NoDataFound />
          )}
        </>
      )}
    </>
  );
};

export default PropertyTargets;
