import React from "react";
import Folio from "../Components/Folio/Folio";
import FolioCharges from "../Components/Folio/FolioCharges";

const FolioChargesPage = () => {
    return (
        <>
            {/* <div className="main_container"> */}
            <div style={{margin: "0", padding: '70px 0'}}>
            <FolioCharges />

            </div>
            {/* </div> */}
        </>
    );
};

export default FolioChargesPage;
