import React from "react";
import FbMenu from "../Components/FbMenu";
import BanquetFoodMenus from "../Components/BanquetFoodMenus";

const BanquetFoodMenuPage = () => {
  return (
    <>
      <div className="main_container">
        <BanquetFoodMenus />
      </div>
    </>
  );
};

export default BanquetFoodMenuPage;
