import React, { useCallback, useState } from "react";

const VerticalInputSelectCustomPermissions = ({
  titleName,
  name,
  onChange,
  value,
  type,
  options,
  optionOnclick,
  required,
  disabled,
  style,
  error,
}) => {
  // const permissions = ["daily_activity_arrival", "departure", "daily_activity_departure"];

  const permissions = JSON.parse(localStorage.getItem("permissions"));
  // const

  return (
    <div>
      <div style={{ marginBottom: "0.25rem" }}>
        <label htmlFor="standard-select">
          {titleName}{" "}
          {required === true ? <span style={{ color: "red" }}> *</span> : ""}
        </label>
      </div>
      <div className="select">
        <select
          disabled={disabled}
          name={name}
          value={value}
          onChange={onChange}
          type={type}
          className="select-custom"
          // style={style}
          style={{
            width: "100%",
            minHeight: "2.25rem",
            backgroundColor: error ? "#FFEAEA" : "",
            border: error ? "1px solid red" : "0.0625rem solid #888",
            borderRadius: "0.25rem",
            paddingLeft: "5px",
            ...style,
          }}
          required={required}
        >
          {options?.map((option, index) => {
            return (
              <>
                {(option?.label === "Select Option" ||
                  option?.value === "arrival" ||
                  option?.value === "rev_par" ||
                  option?.value === "daily_finance_report") && (
                  <option
                    onClick={optionOnclick}
                    key={index}
                    value={option?.value}
                  >
                    {option?.label}
                  </option>
                )}
                {permissions.includes(option?.permission) && (
                  <option
                    onClick={optionOnclick}
                    key={index}
                    value={option?.value}
                  >
                    {option?.label}
                  </option>
                )}
              </>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default VerticalInputSelectCustomPermissions;
