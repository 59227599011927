import React from "react";
import styles from "./bookingSummary.module.scss";

const BookingSummary = () => {
  const data = [
    {
      label: "Confirmed",
      value: 45,
      icon: "/channelManagerDashboard/check.png",
    },
    {
      label: "Cancelled",
      value: 35,
      icon: "/channelManagerDashboard/close.png",
    },
    {
      label: "Room/Night",
      value: 45,
      icon: "/channelManagerDashboard/mode_night.png",
    },
    {
      label: "ADR",
      value: "₹4,000",
      icon: "/channelManagerDashboard/currency_rupee_circle.png",
    },
  ];

  return (
    <div className={styles["booking-summary"]}>
      <div className="flex justify_content_between align_items_center"style={{marginBottom :"20px"}}>
        <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>Bookings</h2>{" "}
        <div className={styles["filter"]}>
          <select>
            <option>Today</option>
            <option>This Week</option>
            <option>This Month</option>
          </select>
        </div>{" "}
      </div>
      <div className={styles["summary-cards"]}>
        {data.map((item, index) => (
          <div className={styles["card"]} key={index}>
            <div className={styles["icon"]}>
              <img src={item.icon} alt="error" />
            </div>
            <div className={styles["info"]}>
              <p className={styles["label"]}>{item.label}</p>
              <p className={styles["value"]}>{item.value}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BookingSummary;
