import React from "react";
import styles from "./paymentsInclusionTable.module.scss"

const InclusionListCommon = ({data}) => {
  return (
    <div>
      <table className={styles.paymentsInclusionTable}>
        <tbody>
          {data?.length > 0 ? (
            <>
              <tr>
                {[
                  "Name",
                  "Qty",
                  "Amt",
                  "Dis. Amt",
                  "Tax Amt",
                  "Total Amt.",
                ].map((header, index) => (
                  <td key={index} className={styles.tableHeader}>{header}</td>
                ))}
              </tr>

              {data.map((row, index) => (
                <tr key={index}>
                  <td className={styles.tableData}>{row?.item_name}</td>
                  <td className={styles.tableData}>{row?.quantity ?  row.quantity:1 }</td>
                  <td className={styles.tableData}>{row?.ad_hoc_price || row.price}</td>
                  <td className={styles.tableData}>{row?.discount_amount || 0}</td>
                  <td className={styles.tableData}>{row?.tax_amount}</td>
                  <td className={styles.tableData}>{row?.total_amount}</td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan="6" className={styles.noDataFound}>
                No Addon Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default InclusionListCommon;
