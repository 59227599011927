import React, { useEffect, useState } from "react";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  CheckWithoutBgUnderlineBtn,
} from "../UI/Buttons";
import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { CancelMajor } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";
import { getDecryptedData } from "../../utils/encryptStorage";

const LocationSetting = () => {
  const selectedProperty = JSON.parse(localStorage.getItem("selectedProperty"));
  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);
  const [editSettingTrue, setEditSettingTrue] = useState(false);

  useEffect(() => {
    setAddPropertyData({
      property_name: selectedProperty.property_name,
      property_address: selectedProperty.property_address,
      property_phone: selectedProperty.property_phone,
      property_landline: "",
      property_email: selectedProperty.property_email,
      property_hotel_type: selectedProperty.property_hotel_type,
      food_prefix: selectedProperty.food_prefix,
      booking_prefix: selectedProperty.booking_prefix,
      property_address: selectedProperty.property_address,
      property_country: selectedProperty.property_country,
      property_city: selectedProperty.property_city,
      property_state: selectedProperty.property_state,
      property_tax_rate: 0,
      property_zip: selectedProperty.property_zip,
      property_description: selectedProperty.property_description,
      property_checkin: selectedProperty.property_checkin,
      property_checkout: selectedProperty.property_checkout,
      property_facebook: "",
      property_instagram: "",
      property_twitter: "",
      property_linkedin: "",
      property_threads: "",
      property_whatsapp: "",
      property_status: "",
      property_hotel_id: "",
    });
  }, [property_id?.id]);

  const [addPropertyData, setAddPropertyData] = useState({
    property_name: selectedProperty.property_name,
    property_phone: selectedProperty.property_phone,
    property_landline: "",
    property_landline2: "",
    property_email: selectedProperty.property_email,
    property_hotel_type: selectedProperty.property_hotel_type,
    food_prefix: selectedProperty.food_prefix,
    booking_prefix: selectedProperty.booking_prefix,
    property_address: selectedProperty.property_address,
    property_country: selectedProperty.property_country,
    property_city: selectedProperty.property_city,
    property_state: selectedProperty.property_state,
    property_tax_rate: 0,
    property_zip: selectedProperty.property_zip,
    property_description: selectedProperty.property_description,
    property_checkin: selectedProperty.property_checkin,
    property_checkout: selectedProperty.property_checkout,
    property_facebook: "",
    property_instagram: "",
    property_twitter: "",
    property_linkedin: "",
    property_threads: "",
    property_whatsapp: "",
    property_status: "",
    property_hotel_id: "",
  });

  // Onchange Function
  const setAddItemDataHandleChange = (event) => {
    setAddPropertyData({
      ...addPropertyData,
      [event.target.name]: event.target.value,
    });
  };

  const handleUpdateProperty = (event) => {
    event.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/properties/update/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          property_name: addPropertyData.property_name,
          property_address: addPropertyData.property_address,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // window.location.reload();
        if (data.message) {
          // navigate("/settings");
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  return (
    <>
      <div
        className="flex justify_content_end"
        style={{ background: "white", padding: "0 20px" }}
      >
        {editSettingTrue ? (
          <div
            onClick={() => setEditSettingTrue(false)}
            style={{ cursor: "pointer" }}
          >
            <Icon source={CancelMajor} color="base" />
          </div>
        ) : (
          <CheckWithoutBgUnderlineBtn
            type={"button"}
            onClick={() => setEditSettingTrue(!editSettingTrue)}
            children={"Edit"}
          />
        )}
      </div>
      {!editSettingTrue ? (
        <div className="setting_location_sec">
          <div className="setting_location">
            <div className="setting_location_div">
              <div className="setting_location_image">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <g clipPath="url(#clip0_1596_697)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.4668 8.13366C17.4668 4.00833 14.1254 0.666992 10.0001 0.666992C5.87478 0.666992 2.53345 4.00833 2.53345 8.13366C2.53345 8.27366 2.53345 8.40433 2.54278 8.54433C2.66411 11.8577 4.40011 15.6563 9.19745 19.0817C9.67345 19.4177 10.3268 19.4177 10.8028 19.0817C15.6001 15.6563 17.3361 11.8577 17.4574 8.54433C17.4668 8.40435 17.4668 8.27363 17.4668 8.13366ZM11.9797 10.1133C12.5052 9.58873 12.8001 8.87659 12.8001 8.13366C12.8001 7.39073 12.5042 6.67953 11.9797 6.15406C11.4552 5.62859 10.743 5.33366 10.0001 5.33366C9.25718 5.33366 8.54598 5.62953 8.02051 6.15406C7.49505 6.67859 7.20011 7.39073 7.20011 8.13366C7.20011 8.87659 7.49505 9.58873 8.02051 10.1133C8.54505 10.6387 9.25718 10.9337 10.0001 10.9337C10.7421 10.9337 11.4542 10.6387 11.9797 10.1133Z"
                      fill="#333333"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1596_697">
                      <rect
                        width="18.6667"
                        height="18.6667"
                        fill="white"
                        transform="translate(0.666748 0.666992)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <h4>{selectedProperty?.property_name}</h4>
                <p>{selectedProperty?.property_address}</p>
              </div>
            </div>
            {/* <div
              style={{ cursor: "pointer" }}
              onClick={() => setEditSettingTrue(true)}
            >
              Edit
            </div> */}
          </div>
        </div>
      ) : (
        <form onSubmit={handleUpdateProperty} className="setting_location_sec">
          <div className="edit-rooms-form-wrapper-container">
            <div className="form-container-div-half">
              <div className="form-container-div">
                <VerticalInputFieldCustom
                  required={true}
                  name="property_name"
                  focused={false}
                  titleName="Legal Property Name"
                  autoComplete="off"
                  onChange={setAddItemDataHandleChange}
                  value={addPropertyData.property_name}
                />
              </div>
              <div className="form-container-div">
                <VerticalInputFieldCustom
                  required={true}
                  onChange={setAddItemDataHandleChange}
                  // type={"number"}
                  name="property_address"
                  focused={false}
                  titleName="Property Address"
                  autoComplete="off"
                  value={addPropertyData.property_address}
                />
              </div>
            </div>
            <div className="flex flex_gap_10">
              <BgThemeButton children={"Update"} type={"submit"} />
            </div>
          </div>
          {/* <div onClick={() => setEditSettingTrue(false)}>edit</div> */}
        </form>
      )}
    </>
  );
};

export default LocationSetting;
