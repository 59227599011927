import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import CustomIndexTable from "../Dashboard/CustomIndexTable/CustomIndexTable";
import CustomIndexTableRow from "../Dashboard/CustomIndexTable/CustomIndexTableRow";
import CustomIndexTableCell from "../Dashboard/CustomIndexTable/CustomIndexTableCell";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import { useState } from "react";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { SvgGetInfo } from "../../assets/svgIcons";

const ReportItemViseDetailedPurchase = ({ departureReport, loader }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [informationPopup, setInformationPopup] = useState(false);

  const rowMarkupAllFoliosCustom = departureReport?.map(
    ({ id, count, order_no, order_type, net_total, date, index }) => {
      return (
        <CustomIndexTableRow
          id={id}
          key={id}
          // selectedItems={selectedArray} // send for checking the check box is checked
          // setSelectedItems={setSelectedArray}
          showCheckbox={false} // show the check box
        >
          <CustomIndexTableCell>{id}</CustomIndexTableCell>

          <CustomIndexTableCell>{count}</CustomIndexTableCell>
          <CustomIndexTableCell>
            {formatIndianCurrency(id)}
          </CustomIndexTableCell>
        </CustomIndexTableRow>
      );
    }
  );

  const calculateTotal = (items, key) => {
    return 0;
  };
  const handleDownload = () => {
    const html2pdf = require("html2pdf.js");
    const originalTable = document.getElementById("receipt");
    const clonedTable = originalTable.cloneNode(true);

    clonedTable.style.fontSize = "12px";
    clonedTable.style.fontFamily = "Arial, sans-serif";
    clonedTable.style.lineHeight = "1.5";
    clonedTable.style.margin = "auto";

    const tempContainer = document.createElement("div");
    tempContainer.appendChild(clonedTable);

    html2pdf()
      .from(tempContainer)
      .set({
        margin: [0.5, 0.5, 0.5, 0.5], // Adjust margin as needed
        filename: "receipt.pdf",
        image: { type: "jpeg", quality: 5 }, // Use higher quality for images
        html2canvas: { scale: 1 }, // Increase scale for higher resolution
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" }, // Adjust PDF format and orientation as needed
      })
      .save();
  };

  const downloadCSV = () => {
    // Prepare CSV content
    const csvHeaders = "Category,Item,Count,Sale";
    const csvContent = `${csvHeaders}\n${departureReport
      .map((category) =>
        category.items
          .map((item) =>
            [category.category, item.item, item.count, item.sale].join(",")
          )
          .join("\n")
      )
      .join("\n")}`;

    // Create a blob with CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link element to trigger download
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "sales_data.csv");
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // console.log(departureReport);

  const rowMarkup = departureReport?.map(
    ({
      id,
      payment_status,
      purchase_item_date,
      supplier_gst,
      invoice_no,
      unique_purchase_id,
      item_qty,
      item_rate,
      total_amount,

      item_name,
      supplier_name,
      unit_name,
    }) => (
      <CustomReportTableRow
        CustomReportTableRow
        id={id}
        key={id}
        // bodyStyles={{
        //   backgroundColor: "red",
        //   fontSize: "10px",
        // }}
      >
        <CustomReportTableCell>{item_name}</CustomReportTableCell>
        <CustomReportTableCell>
          {
            <TextDateFormatter
              date={purchase_item_date}
              as={"div"}
              showYear={true}
            />
          }
        </CustomReportTableCell>
        <CustomReportTableCell>
          {supplier_name ? supplier_name : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {supplier_gst ? supplier_gst : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>{invoice_no}</CustomReportTableCell>
        <CustomReportTableCell>#{unique_purchase_id}</CustomReportTableCell>
        <CustomReportTableCell>{unit_name}</CustomReportTableCell>

        <CustomReportTableCell>{item_qty}</CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(item_rate)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(total_amount)}
        </CustomReportTableCell>
      </CustomReportTableRow>
    )
  );

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>{"Totals"}</CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell>
        {calculateTotalWithReduce(departureReport, "item_qty")}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "item_rate")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "total_amount")
        )}
      </CustomReportTableCell>
    </CustomReportTableRow>
  );
  // ........................
  const extractedData = departureReport?.map((item) => ({
    item_name: item && item?.item_name ? item?.item_name : "N/A",
    purchase_item_date:
      item && item?.purchase_item_date ? item?.purchase_item_date : "N/A",
    supplier_name: item && item?.supplier_name ? item?.supplier_name : "N/A",
    supplier_gst: item && item?.supplier_gst ? item?.supplier_gst : "N/A",
    invoice_no: item && item?.invoice_no ? item?.invoice_no : "N/A",
    unique_purchase_id:
      item && item?.unique_purchase_id ? item?.unique_purchase_id : "N/A",
    unit_name: item && item?.unit_name ? item?.unit_name : "N/A",
    item_qty: item && item?.item_qty ? item?.item_qty : "N/A",
    item_rate: item && item?.item_rate ? item?.item_rate : "N/A",
    total_amount: item && item?.total_amount ? item?.total_amount : "N/A",
  }));

  const names = [
    {
      item_name: "Item Name	",
      purchase_item_date: "Date Of Purchase	",
      supplier_name: "Supplier Name",
      supplier_gst: "GST No",
      invoice_no: "Invoice No",
      unique_purchase_id: "Purchase ID",
      unit_name: "Unit",
      item_qty: "Quantity",
      item_rate: "Price per Unit (₹)	",
      total_amount: "Total Purchase Amount (₹)",
    },
  ];
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div
          id="receipt"
          className="room-types-full-container reports-new-background"
        >
          <div className="room-types-full-container-div">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
                <div className="room-type-heading">
                  Item Wise Supplier Analysis Report
                </div>
                <div
                  className="hoverCursor"
                  onClick={() => setInformationPopup(true)}
                  id="ignoreFromPrint"
                >
                 <SvgGetInfo />
                </div>
              </div>
              {departureReport.length > 0 && (
                <div className="flex flex_gap_15 align_items_center" id="ignoreFromPrint">
                  <ButtonToDownloadTablePDF
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Item_Vise_Detailed_Purchase_Report"}
                    ignoreFromPrint={"ignoreFromPrint"}
                    // pdfStyles={

                    // }
                  />
                  <DownloadCSVButton
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Item_Vise_Detailed_Purchase_Report"}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            {departureReport && departureReport.length > 0 ? (
              <>
                {/* {departureReport &&
                  departureReport.length > 0 &&
                  departureReport.map((order) => {
                    return (
                      <div className="mt-20">
                        <div
                          className="category_name"
                          style={{ marginBottom: "10px" }}
                        >
                          {order.category}
                        </div> */}

                <CustomReportTable
                  headings={[
                    "Item Name",
                    "Date Of Purchase",
                    "Supplier Name",
                    "GST No",
                    "Invoice No",
                    "Purchase ID",
                    "Unit",
                    "Quantity",
                    "Price per Unit (₹)",
                    "Total Purchase Amount (₹)",
                  ]}
                  // commonHeadings={[
                  //   "",
                  //   "",
                  //   "",
                  //   "",
                  //   "Quantity",
                  //   "Quantity",
                  //   "Quantity",
                  //   "Total Purchase Amount  (₹)",
                  //   "Total Purchase Amount  (₹)",
                  //   "Total Purchase Amount  (₹)",
                  //   "Average Purchase Cost per unit (₹)",
                  //   "Average Purchase Cost per unit (₹)",
                  //   "Average Purchase Cost per unit (₹)",
                  // ]}
                  headerStyles={{
                    backgroundColor: "#dddddd",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                  tableStyles={{ textAlign: "left" }}
                >
                  {rowMarkup}
                  {calculateTotals}
                </CustomReportTable>
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      )}

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of Item Wise Supplier Analysis Report"
        >
          <div className="mb-20">
            {/* <h2 className="mb-10"> Accordion Component </h2> */}
            <AccordionComponent
              questions={[
                {
                  question: "What is a   Item Wise Supplier Analysis Report?",
                  answer: (
                    <p>
                      An <b>Item-Wise Detailed Purchase Report</b> provides a
                      comprehensive view of the purchases made for specific
                      items from various suppliers over a selected date range.
                      This report includes details like the item name, purchase
                      date, supplier details, invoice and purchase IDs, quantity
                      purchased, price per unit, and the total purchase amount.
                      It allows businesses to track their procurement activity
                      at a granular level for each item.
                    </p>
                  ),
                },
                {
                  question:
                    "Importance of a Item Wise Supplier Analysis Report",
                  answer: (
                    <div>
                      <p>
                        The Item-Wise Detailed Purchase Report is crucial for
                        businesses due to the following reasons:
                      </p>

                      <ul>
                        <li>
                          <b>Detailed Financial Tracking:</b> It offers an
                          in-depth view of the purchasing history for specific
                          items, providing clarity on expenditure and helping
                          manage costs.
                        </li>
                        <li>
                          <b>Supplier Comparison:</b> Businesses can compare
                          pricing and purchase trends from different suppliers,
                          aiding in identifying the best suppliers for each
                          item.
                        </li>
                        <li>
                          <b>Inventory Management:</b> It helps in keeping track
                          of the quantity of items procured, ensuring effective
                          inventory control and replenishment.
                        </li>
                        <li>
                          <b>Cost Control:</b> The report provides visibility
                          into price variations across different time frames,
                          helping businesses optimize their spending on specific
                          items.
                        </li>
                        <li>
                          <b>Audit and Compliance:</b> With detailed records of
                          supplier details, GST numbers, and invoices, the
                          report serves as an essential tool for audits and
                          ensuring compliance with tax regulations.
                        </li>
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportItemViseDetailedPurchase;
