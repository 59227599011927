import {
  Button,
  HorizontalStack,
  Icon,
  IndexTable,
  LegacyCard,
  Pagination,
  Tag,
  Text,
  Tooltip,
  useIndexResourceState,
} from "@shopify/polaris";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import TextDateFormatter from "../../Communication/TextDateFormatter/TextDateFormatter";
import { BgOutlineThemeButton, BgThemeButton } from "../../UI/Buttons";
import LoaderSmall from "../../UI/Loader/LoaderSmall";
import NoDataFoundSmall from "../../CustomComponents/NoDataFoundSmall";
import GoBackButtonCustom from "../../GoBackButtonCustom/GoBackButtonCustom";
import BookingStatusUpdate from "../../CustomComponents/BookingStatusUpdate";
import BookingDetails from "../../CustomComponents/BookingDetails/BookingDetails";
import BookingDetailsSlider from "../../CustomComponents/BookingSoldSlider/BookingDetailsSlider";
import BookingDetailSliderNew from "../../CustomComponents/BookingSoldSlider/BookingDetailsSlider";
import { getDecryptedData } from "../../../utils/encryptStorage";

const BookingAllFilterComponent = ({
  confirmedBookings,
  checkInBookings,
  checkOutBookings,
}) => {
  const currentDate = new Date().toISOString().split("T")[0];
  const [fetchedData, setFetchedData] = useState(null);
  const [openDayStatus, setOpenDayStatus] = useState();
  const [showDashBoardCheckIn, setShowDashBoardCheckIn] = useState(false);
  const [showDashBoardCheckOut, setShowDashBoardCheckOut] = useState(false);
  const [showDashboardAllCheckIn, setShowDashboardAllCheckIn] = useState(false);
  const [showManualSearchInput, setShowManualSearchInput] = useState(false);
  const [showWebInput, setShowWebInput] = useState(false);
  const [showBookingDetails, setShowBookingDetails] = useState(false);
  const [showCheckInDetailsData, setShowCheckInDetailsData] = useState(false);
  const [bookingDetails, setBookingDetails] = useState([]);
  const [allSettlementsData, setAllSettlementsData] = useState([]);
  const [checkOutBookingDetail, setCheckOutBookingDetail] = useState([]);

  const [bookingsButtonActive, setBookingsButtonActive] = useState(1);

  const [loader, setLoader] = useState(false);

  const [openAccordion, setOpenAccordion] = useState("first");
  //   const [bookingsButtonActive, setBookingsButtonActive] = useState(1);

  const toggleAccordion = (accordion) => {
    if (openAccordion === accordion) {
      setOpenAccordion(null); // Close the currently open accordion
    } else {
      setOpenAccordion(accordion); // Open the clicked accordion
    }
  };

  const property_id = useSelector((state) => state.property.value);
  const userToken = getDecryptedData('encryptToken');

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(confirmedBookings);

  const submitBookingData = (e, bookingId) => {
    // setBookingId(bookingId)
    if (e) {
      e.preventDefault();
    }
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${bookingId}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setShowBookingDetails(true);
          setBookingDetails(data.booking);
          //   showSuccessToast(data.message);
          //   setShowArrivalConfirmed(false);
          const updatedData = {
            guest_name: data?.booking?.guest[0]?.guest_name,
            guest_phone: data?.booking?.guest[0]?.guest_phone,
            guest_city: data?.booking?.guest[0]?.guest_city,
            guest_state: data?.booking?.guest[0]?.guest_state,
            guest_pincode: data?.booking?.guest[0]?.guest_pincode,
            guest_address: data?.booking?.guest[0]?.guest_address,
            guest_email: data?.booking?.guest[0]?.guest_email,
            identity_proof_type: data?.booking?.guest[0]?.identity_proof_type,
            identity_proof_no: data?.booking?.guest[0]?.identity_proof_no,
            guest_country: "India",
          };
          //   setCheckInData(updatedData);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  const confirmedRowMarkup = confirmedBookings?.map(
    ({ id, booked_from, guest, unique_booking_id, check_in, index }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          {guest && guest.length > 0 && guest[0].guest_name}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {
            <div
              style={{
                cursor: "pointer",
                color: "#3968ED",
              }}
              onClick={(e) => submitBookingData(e, unique_booking_id)}
            >
              #{unique_booking_id}
            </div>
          }
        </IndexTable.Cell>
        <IndexTable.Cell>
          <TextDateFormatter as={`div`} date={check_in} showYear={true} />
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const checkInRowMarkup = checkInBookings?.map(
    ({
      id,
      booked_from,
      unique_booking_id,
      check_out,
      index,
      web_checkin,
      guest,
      items,
      assigned_rooms,
    }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          {guest && guest.length > 0 && guest[0].guest_name}
          <div
            style={{
              color: "#3968ED",
              display: "flex",
            }}
          >
            <span
              onClick={(e) => submitBookingData(e, unique_booking_id)}
              style={{ cursor: "pointer" }}
            >
              #{unique_booking_id}
            </span>
          </div>
        </IndexTable.Cell>

        <IndexTable.Cell>
          {web_checkin === 0 ? <span>Manual</span> : <span>Web</span>}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <TextDateFormatter as={`div`} date={check_out} showYear={true} />
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text alignment="right">
            {assigned_rooms ? (
              <>
                <div style={{ textAlign: "end" }}>Assigned Room:</div>
                <div style={{ textAlign: "end", fontWeight: "bold" }}>
                  {assigned_rooms}
                </div>
              </>
            ) : (
              <div style={{ textAlign: "end" }}>No Room Assigned Yet..</div>
            )}
          </Text>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const checkOutRowMarkup = checkOutBookings?.map(
    ({ id, booked_from, guest, unique_booking_id, index }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          {guest && guest.length > 0 && guest[0].guest_name}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <div
            style={{
              cursor: "pointer",
              color: "#3968ED",
            }}
            onClick={(e) => submitBookingData(e, unique_booking_id)}
          >
            #{unique_booking_id}
          </div>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const resourceName = {
    singular: "allsettlement",
    plural: "allsettlements",
  };

  return (
    <section className="dashboard_page">
      <div style={{ width: "100%" }}>
        <div className="checkin_rooms_status" style={{ padding: "0" }}>
          <h4 style={{ padding: "20px 10px" }} className="room_status_heading">
            Todays Guest's
          </h4>
          <div
            className="flex flex_gap_10"
            style={{ marginBottom: "20px", paddingLeft: "10px" }}
          >
            <button
              onClick={() => setBookingsButtonActive(1)}
              className={`frontDeskButtonsBookings ${
                bookingsButtonActive === 1 && "active"
              }`}
            >
              Arrivals
            </button>
            <button
              onClick={() => setBookingsButtonActive(2)}
              className={`frontDeskButtonsBookings ${
                bookingsButtonActive === 2 && "active"
              }`}
            >
              In House
            </button>
            <button
              onClick={() => setBookingsButtonActive(3)}
              className={`frontDeskButtonsBookings ${
                bookingsButtonActive === 3 && "active"
              }`}
            >
              Departures
            </button>
          </div>
          <div>
            {bookingsButtonActive === 1 && (
              <LegacyCard>
                <div
                  className="overflow_y_scroll_x_hidden"
                  style={{
                    padding: "10px",
                    maxHeight: "300px",
                  }}
                >
                  {loader ? (
                    <LoaderSmall />
                  ) : (
                    <>
                      {confirmedBookings && confirmedBookings.length > 0 ? (
                        <IndexTable
                          selectable={false}
                          resourceName={resourceName}
                          itemCount={confirmedBookings.length}
                          selectedItemsCount={
                            allResourcesSelected
                              ? "All"
                              : selectedResources.length
                          }
                          onSelectionChange={handleSelectionChange}
                          headings={[
                            { title: "Name" },
                            { title: "Booking ID" },
                            { title: "Check In Date" },
                            { title: "" },
                          ]}
                        >
                          {confirmedRowMarkup}
                        </IndexTable>
                      ) : (
                        <NoDataFoundSmall />
                      )}
                    </>
                  )}
                </div>
              </LegacyCard>
            )}

            {bookingsButtonActive === 2 && (
              <>
                <section className="">
                  <LegacyCard>
                    <div
                      className="overflow_y_scroll_x_hidden"
                      style={{
                        padding: "10px",
                        maxHeight: "300px",
                      }}
                    >
                      {loader ? (
                        <LoaderSmall />
                      ) : (
                        <>
                          {checkInBookings && checkInBookings.length > 0 ? (
                            <IndexTable
                              selectable={false}
                              resourceName={resourceName}
                              itemCount={checkInBookings.length}
                              selectedItemsCount={
                                allResourcesSelected
                                  ? "All"
                                  : selectedResources.length
                              }
                              onSelectionChange={handleSelectionChange}
                              headings={[
                                { title: "Name" },
                                { title: "Booking Type" },
                                { title: "Check-Out Date" },
                                { title: "" },
                              ]}
                            >
                              {checkInRowMarkup}
                            </IndexTable>
                          ) : (
                            <NoDataFoundSmall />
                          )}
                        </>
                      )}
                    </div>
                  </LegacyCard>
                </section>
              </>
            )}

            {bookingsButtonActive === 3 && (
              <LegacyCard>
                <div
                  className="overflow_y_scroll_x_hidden"
                  style={{
                    padding: "10px",
                    maxHeight: "300px",
                  }}
                >
                  {loader ? (
                    <LoaderSmall />
                  ) : (
                    <>
                      {checkOutBookings && checkOutBookings.length > 0 ? (
                        <IndexTable
                          selectable={false}
                          resourceName={resourceName}
                          itemCount={checkOutBookings.length}
                          selectedItemsCount={
                            allResourcesSelected
                              ? "All"
                              : selectedResources.length
                          }
                          onSelectionChange={handleSelectionChange}
                          headings={[
                            { title: "Name" },
                            { title: "Booking ID" },
                          ]}
                        >
                          {checkOutRowMarkup}
                        </IndexTable>
                      ) : (
                        <NoDataFoundSmall />
                      )}
                    </>
                  )}
                </div>
              </LegacyCard>
            )}
          </div>
        </div>
      </div>

      {/* check In */}
      {showBookingDetails && (
        //   <BookingDetails
        //   bookingDetails={bookingDetails}
        //   setShowBookingDetails={setShowBookingDetails}
        // />

        // <BookingDetailsSlider
        //   bookingDetails={bookingDetails}
        //   setShowBookingDetails={setShowBookingDetails}
        // />

        <BookingDetailSliderNew
          data={bookingDetails}
          setShowBookingDetails={setShowBookingDetails}
        />
      )}
    </section>
  );
};

export default BookingAllFilterComponent;
