// Polaris Imports
import {
  Button,
  Card,
  ChoiceList,
  HorizontalStack,
  Icon,
  IndexTable,
  Pagination,
  Tooltip,
  useIndexResourceState,
  Text,
} from "@shopify/polaris";
import BarCharts from "../BarCharts";
import { PolarisVizProvider } from "@shopify/polaris-viz";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  CheckWithoutBgUnderlineBtn,
  WithoutBgButtonBlue,
} from "../UI/Buttons";

import DatePicker from "react-datepicker";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import CustomProgressBar from "../CustomComponents/CustomProgressBar";
import CustomToggleButton from "../UI/CustomToggleButton";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { Page, LegacyCard, DataTable } from "@shopify/polaris";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import { useSelector } from "react-redux";
import Loader from "../UI/Loader/Loader";
import { useNavigate } from "react-router-dom";
import CompetitorsAfter from "../CompetitorsAfter";
import SelectDateRangeWithoutInput from "../CustomComponents/SelectDateRangeWithoutInput/SelectDateRangeWithoutInput";
import ShowConfirmedArrivals from "../UI/ShowConfirmedArrivals";
import ShowHouseCheckIn from "../UI/ShowHouseCheckIn";
import ShowDepartureCheckOut from "../UI/ShowDepartureCheckOut";
import NoDataFound from "../CustomComponents/NoDataFound";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import { current } from "@reduxjs/toolkit";
import LineChartDashboard from "../LineChartsDashboard";
import SelectDateRange from "../CustomComponents/SelectDateRange/SelectDateRange";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import ReactDatePicker from "react-datepicker";
import SelectDatePickerCustom from "../CustomComponents/SelectDateRange/SelectDatePickerCustom";
import SidebarInnerLists from "../CustomComponents/SidebarInnerLists";
import {
  permission_inner_lists,
  rooms_inner_lists,
} from "../../assets/constant";
import { CSVLink } from "react-csv";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import ReportDepartureTable from "./ReportDepartureTable";
import ReportAccountBalanceTable from "./ReportAccountBalanceTable";
import ReportCancelTable from "./ReportCancelTable";
import ReportNoShowTable from "./ReportNoShowTable";
import ReportRoomAssignment from "./ReportRoomAssignment";
import CustomRoomChoiceSelect from "../CustomComponents/CustomRoomChoiceSelect/CustomRoomChoiceSelect";
import ReportPaymentReconciliation from "./ReportPaymentReconciliation";
import ReportPaymentDailyRevenue from "./ReportPaymentDailyRevenue";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import VerticalInputSelectCustomPermissions from "../CustomComponents/VerticalInputSelectCustomPermissions";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ReportIndividualVendor from "./ReportIndividualVendor";
import ReportConsolidatedVendor from "./ReportConsolidatedVendor";
import ReportInvoiceSettlement from "./ReportInvoiceSettlement";
import ReportItemViseSupplier from "./ReportItemViseSupplier";
import ReportCategoryViseSupplier from "./ReportCategoryViseSupplier";
import MultiSelectSearchDropdown from "../CustomComponents/MultiSelectSearchDropdown/MultiSelectSearchDropdown";
import ReportItemViseDetailedPurchase from "./ReportItemViseDetailedPurchase";
import ReportDetailedIssueItem from "./ReportDetailedIssueItem";
import ReportItemViseConsolidatedFinance from "./ReportItemViseConsolidatedFinance";
import ReportRequisitionRequestReport from "./ReportRequisitionRequestReport";
import ReportCategoryWiseReport from "./ReportCategoryWiseReport";
import { getDecryptedData } from "../../utils/encryptStorage";

Chart.register(ArcElement);

const StoreAndPurchasesReports = () => {
  const currentDate = new Date().toISOString().split("T")[0];
  const [fetchedData, setFetchedData] = useState(null);
  const [openDayStatus, setOpenDayStatus] = useState();
  const [showDashBoardCheckIn, setShowDashBoardCheckIn] = useState(false);
  const [showDashBoardCheckOut, setShowDashBoardCheckOut] = useState(false);
  const [showDashboardAllCheckIn, setShowDashboardAllCheckIn] = useState(false);
  const [showManualSearchInput, setShowManualSearchInput] = useState(false);
  const [showWebInput, setShowWebInput] = useState(false);
  const [showBookingDetails, setShowBookingDetails] = useState(false);

  const [allItems, setAllItems] = useState([]);

  const [showCheckOutBookingDetails, setShowCheckOutBookingDetails] =
    useState(false);
  const [
    showCheckOutIdClickBookingDetails,
    setShowCheckOutIdClickBookingDetails,
  ] = useState(false);

  const [settlementDoneCheckOut, setSettlementDoneCheckOut] = useState(false);

  const [showCheckOutSettlementDetails, setShowCheckOutSettlementDetails] =
    useState(false);
  const [openBookingDetails, setOpenBookingDetails] = useState(true);
  const userToken = getDecryptedData('encryptToken');

  const [bookingDetails, setBookingDetails] = useState([]);
  const [allSettlementsData, setAllSettlementsData] = useState([]);

  const [dailyFinanceDate, setDailyFinanceDate] = useState(currentDate);

  // show click arrow buttons
  const [showArrivalConfirmed, setShowArrivalConfirmed] = useState(false);
  const [showAllSettlement, setShowAllSettlement] = useState(false);
  const [showInHouseCheckIn, setShowInHouseCheckIn] = useState(false);
  const [showDepartureCheckOut, setShowDepartureCheckOut] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);
  const [checkOutBookingDetail, setCheckOutBookingDetail] = useState([]);

  const property_id = useSelector((state) => state.property.value);

  const [dayTypeFilter, setDayTypeFilter] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [valueArray, setValueArray] = useState([]);
  const accordRef = useRef(null);

  const handleSelectedOptionsChange = (options) => {
    setSelectedItems(options);
  };

  const toggleAccord = () => {
    setIsOpen(!isOpen);
    setIsOpen1(false);
    setIsOpen2(false);
  };
  const toggleAccord1 = () => {
    setIsOpen(false);
    setIsOpen1(!isOpen1);
    setIsOpen2(false);
  };
  const toggleAccord2 = () => {
    setIsOpen2(!isOpen2);
    // setIsOpen1(false);
    // setIsOpen(false);
  };
  const toggleAccord3 = () => {
    setIsOpen3(!isOpen3);
    setIsOpen1(false);
    setIsOpen(false);
  };

  const permissions = JSON.parse(localStorage.getItem("permissions"));

  // Reservations
  const dateOptions = [
    { id: 0, value: "-1", label: "Today" },
    { id: 0, value: "-2", label: "Yesterday" },
    { id: 1, value: "-7", label: "Past 7 Days" },
    { id: 2, value: "-30", label: "Past 30 Days" },
    { id: 3, value: "-90", label: "Past 90 Days" },
    { id: 4, value: "-1", label: "Last Year" },
    { id: 5, value: "-3", label: "Last 3 year" },
  ];
  // Reservations
  const dateOptions1 = [
    { id: 0, value: "revenue", label: "Revenue" },
    { id: 1, value: "profit", label: "Profit" },
    { id: 2, value: "reservation", label: "Reservation" },
    { id: 3, value: "expense", label: "Expense" },
    { id: 4, value: "event", label: "Event" },
    { id: 5, value: "f&b", label: "F&B" },
  ];

  const [allRoomTypes, setAllRoomTypes] = useState([]);

  const fetchAllRoomTypes = async (propertyId) => {
    try {
      // setLoader(true);
      const response = await fetch(
        // `${process.env.REACT_APP_BASE_URL}/api/v1/event/update/${id}/${property_id?.id}`
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAllRoomTypes(data.rooms);

      const roomIds = data.rooms.map((room) => room.id);
      setSelectedRoomType(roomIds);

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const choiceListRoomsArray = allRoomTypes.map((room) => {
    return { label: room.room_name, value: room.id };
  });

  const roomIdsArray = allRoomTypes.map((room) => room.id);

  const handleAccordClick = (id, label, value) => {
    setValueArray([{ id: id, label: label, value: value }]);
    setDayTypeFilter(value);
  };

  const handleShowDashBoardCheckIn = (e) => {
    e.stopPropagation();
    setShowDashBoardCheckIn(!showDashBoardCheckIn);
  };

  const handleShowDashBoardCheckOut = (e) => {
    e.stopPropagation();
    setBookingId("");
    setShowDashBoardCheckOut(!showDashBoardCheckOut);
  };

  const handleShowManualSearchInput = () => {
    {
      showWebInput === true && setShowWebInput(false);
    }
    setShowManualSearchInput(!showManualSearchInput);
  };

  const handleShowWebInput = () => {
    {
      showManualSearchInput === true && setShowManualSearchInput(false);
    }
    setShowWebInput(!showWebInput);
  };

  const [bookingOpenIndex, setOpenBookingIndex] = useState(1);
  const [bookingAccordianActive, setBookingAccordianActive] = useState("");

  const handleOpenBookigDetails = (idx) => {
    setOpenBookingIndex(idx);
    if (bookingOpenIndex === idx) {
      setBookingAccordianActive("active");
    } else {
      setBookingAccordianActive("");
    }
  };

  const handleShowDashBoardAllCheckIn = () => {
    setShowDashboardAllCheckIn(!showDashboardAllCheckIn);
  };

  const [loader, setLoader] = useState(true);

  const navigate = useNavigate();

  const [selectedDiscountMethod, setSelectedDiscountMethod] =
    useState("automatic");

  const handleDiscountMethodChange = (value) => {
    setSelectedDiscountMethod(value);
  };

  const [selectedRoomType, setSelectedRoomType] = useState([]);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [selectedBalanceType, setSelectedBalanceType] = useState([]);
  const [selectedBookingStatus, setSelectedBookingStatus] = useState("");

  const handleBalanceTypeStatus = useCallback(
    (value) => setSelectedBalanceType(value),
    []
  );
  const handleBookingStatus = useCallback(
    (value) => setSelectedBookingStatus(value),
    []
  );

  const fetchDashboardInfo = async (propertyId, dayTypeFilter) => {
    // setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/dashboard/${property_id?.id}?dayTypeFilter=${dayTypeFilter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (!response.ok) {
        setLoader(false);
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      if (data.message === "Unauthenticated.") {
        navigate("/login");
      }
      // setLoader(false);
      setFetchedData(data);
    } catch (error) {
      if (error === "Unauthenticated.") {
        navigate("/login");
      }
      // setLoader(false);
      console.error(error);
    }
  };

  // const currentDate = new Date().toISOString().split("T")[0];

  const calculateToDate = (fromDate) => {
    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() - 30);
    return toDate.toISOString().split("T")[0];
  };

  const [selectedDatesRange, setSelectedDatesRange] = useState({
    from_date: calculateToDate(currentDate),
    to_date: currentDate,
  });

  const [selectedDatesRangePaymentRec, setSelectedDatesRangePaymentRec] =
    useState({
      from_date: currentDate,
      to_date: currentDate,
    });
  const [comparisonStartDateRange, setComparisonStartDateRange] = useState({
    from_date: calculateToDate(currentDate),
    to_date: currentDate,
  });
  const [comparisonEndDateRange, setComparisonEndDateRange] = useState({
    from_date: calculateToDate(currentDate),
    to_date: currentDate,
  });

  const dataFromChild = (from_date, to_date) => {
    setSelectedDatesRange({
      from_date: from_date,
      to_date: to_date,
    });
  };

  const dataFromChild1 = (from_date, to_date) => {
    setComparisonStartDateRange({
      from_date: from_date,
      to_date: to_date,
    });
  };

  const dataFromChild2 = (from_date, to_date) => {
    setComparisonEndDateRange({
      from_date: from_date,
      to_date: to_date,
    });
  };
  const [suppliersList, setSuppliersList] = useState([]);

  const fetchSuppliers = async (propertyId, newUrl) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        // /api/v1/expenses/1
        `${process.env.REACT_APP_BASE_URL}/api/v1/suppliers/${propertyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }

      const supplierListArray = [
        { value: "", label: "Select Option" },
        ...data.supplier.data.map((item) => ({
          value: item.id,
          label: item.supplier_name,
        })),
      ];
      setSuppliersList(supplierListArray);

      if (property_id && supplierListArray[1]?.value) {
        fetchInfo(property_id?.id, supplierListArray[1]?.value);
      }

      // setTotalPages(data?.supplier?.last_page);
      // setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const fetchAllItems = async (id) => {
    try {
      // setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/items/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      // setOrders(data.Item);
      setAllItems(data.Item);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      // fetchDashboardInfo(property_id.id, dayTypeFilter);
      // fetchAllRoomTypes(property_id.id);
      fetchSuppliers(property_id?.id);
      fetchAllItems(property_id?.id);
    }
  }, [property_id]);

  const openDayStatusHandler = useCallback((data) => {
    setOpenDayStatus(data);
  }, []);

  const chart_data = {
    datasets: [
      {
        data: [fetchedData?.total_revenue, fetchedData?.foodSale, 0],
        backgroundColor: ["#3968ED", "#15AA12", "#CCCCCC"],
        display: true,
      },
    ],
  };

  const [bookingId, setBookingId] = useState("");

  const [openAccordion, setOpenAccordion] = useState("first");

  // UseState for forms
  const [settlementAddData, setSettlementAddData] = useState({
    settlement_name: "",
    payment_mode: "",
    notes: "",
    date: "",
    amount: "",
    property_id: property_id?.id,
    other_payment_mode: "",
  });

  const paymentModeOptions = [
    { label: "Cash", value: "cash" },
    { label: "Bank Payment", value: "bank_payment" },
    { label: "UPI", value: "upi" },
    { label: "Credit Card", value: "credit_card" },
    { label: "Debit Card", value: "debit_card" },
    { label: "Other", value: "other" },
  ];

  const reportFilterOptions = [
    { label: "Select Option" },
    {
      label: "Individual Vendor Finance Report",
      value: "individual_vendor_finance_report",
      permission: "individual_vendor_finance_report",
    },
    {
      label: "Consolidated Vendor Finance Report",
      value: "consolidated_vendor_finance_report",
      permission: "consolidated_vendor_finance_report",
    },
    {
      label: "Invoice Settlement Report",
      value: "invoice_settlement_report",
      permission: "invoice_settlement_report",
    },
    {
      label: "Item Wise Supplier Analysis",
      value: "item_vise_supplier_analysis",
      permission: "item_vise_supplier_analysis",
    },
    {
      label: "Category Wise Supplier Analysis",
      value: "category_vise_supplier_analysis",
      permission: "category_vise_supplier_analysis",
    },
    {
      label: "Item Wise Detailed  Purchase Report",
      value: "item_wise_detailed_purchase_report",
      permission: "item_wise_detailed_purchase_report",
    },
    {
      label: "Detailed issued Items Report",
      value: "detailed_issued_items_report",
      permission: "detailed_issued_items_report",
    },
    {
      label: "Item wise consolidated Finance Report",
      value: "item_wise_consolidated_finance_report",
      permission: "item_wise_consolidated_finance_report",
    },
    {
      label: "Requisition Request Report",
      value: "requisition_request_report",
      permission: "requisition_request_report",
    },
    {
      label: "Category wise Report",
      value: "category_wise_report",
      permission: "category_wise_report",
    },
  ];

  const renderYearContent = (year) => {
    const tooltipText = `Tooltip for year: ${year}`;
    return <span title={tooltipText}>{year}</span>;
  };

  useEffect(() => {
    document.title = "RevCatalyst - Boosting Your Hotel Performance";

    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property.";
  }, []);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [orders, setOrders] = useState([]);
  const [settlementOrders, setSettlementOrders] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [filterName, setFilterName] = useState(
    "individual_vendor_finance_report"
  );

  // console.log("orders", orders);
  const generateYearOptions = (currentYear) => {
    const numYears = 3;
    const startYear = currentYear - numYears;
    const endYear = currentYear;

    const years = [];
    years.push({ label: "Select a year", value: "" });
    for (let year = startYear; year <= endYear; year++) {
      years.push({ label: year.toString(), value: year });
    }

    return years;
  };

  const data = [
    {
      name: "No. of Bookings",
      color: "#7C9CF3",
      data: chartData,
    },
  ];

  // Example usage:
  const currentYear = new Date().getFullYear();
  const yearOptions = generateYearOptions(currentYear);

  const fetchInfo = async (propertyId, supplier) => {
    setSelectedBookingStatus(supplier);
    try {
      setLoader(true);
      setChartData([]);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/vendor-finance-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&supplier_id=${supplier}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data?.data?.purchase_items);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const setErrorWithTimeout = (setterFunction, keyName, duration = 3000) => {
    setterFunction((prev) => ({ ...prev, [keyName]: true }));
    setTimeout(() => {
      setterFunction((prev) => ({ ...prev, [keyName]: false }));
    }, duration);
  };

  const [individualVendorError, setIndividualVendorError] = useState({
    supplier: false,
  });
  const fetchIndividualVendorReport = async (propertyId, roomIds) => {
    if (!selectedBookingStatus) {
      showErrorToast("Please select the Supplier first!");
      setErrorWithTimeout(setIndividualVendorError, "supplier", 3000);
      return;
    }

    try {
      setChartData([]);

      setLoader(true);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/vendor-finance-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&supplier_id=${selectedBookingStatus}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      console.log(data);
      if (data.success) {
        setOrders(data?.data?.purchase_items);
        setFullData(data);
        setLoader(false);
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchConsolidatedVendorReport = async (propertyId, roomIds) => {
    // if (!selectedRoomType || selectedRoomType.length === 0) {
    //   showErrorToast("Please select the Room Type first!");
    //   return;
    // }

    // if (
    //   filterName === "departure" &&
    //   (!selectedBookingStatus || selectedBookingStatus.length === 0)
    // ) {
    //   showErrorToast("Please select the Status!");
    //   return;
    // }

    // if (
    //   filterName === "in_house" &&
    //   (!selectedBalanceType || selectedBalanceType.length === 0)
    // ) {
    //   showErrorToast("Please select the Status!");
    //   return;
    // }

    // if (
    //   filterName === "payment_reconciliation" &&
    //   (!selectedBalanceType || selectedBalanceType.length === 0)
    // ) {
    //   showErrorToast("Please select the Payment Option!");
    //   return;
    // }

    try {
      setChartData([]);
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/consolidated-vendor-finance-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
        setLoader(false);
      } else {
        setLoader(false);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchInvoiceSettlementReport = async (propertyId, roomIds) => {
    // if (!selectedRoomType || selectedRoomType.length === 0) {
    //   showErrorToast("Please select the Room Type first!");
    //   return;
    // }

    // if (
    //   filterName === "departure" &&
    //   (!selectedBookingStatus || selectedBookingStatus.length === 0)
    // ) {
    //   showErrorToast("Please select the Status!");
    //   return;
    // }

    // if (
    //   filterName === "in_house" &&
    //   (!selectedBalanceType || selectedBalanceType.length === 0)
    // ) {
    //   showErrorToast("Please select the Status!");
    //   return;
    // }

    // if (
    //   filterName === "payment_reconciliation" &&
    //   (!selectedBalanceType || selectedBalanceType.length === 0)
    // ) {
    //   showErrorToast("Please select the Payment Option!");
    //   return;
    // }

    try {
      setChartData([]);
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/invoice-settlement-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
        setLoader(false);
      } else {
        setLoader(false);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const [itemWiseErrors, setItemWiseErrors] = useState({
    item: false,
  });
  const fetchItemViseSupplierAnalysisReport = async (propertyId, roomIds) => {
    if (!selectedItems || selectedItems.length === 0) {
      showErrorToast("Oops!! No Item Selected!");
      setErrorWithTimeout(setItemWiseErrors, "item", 3000);
      return;
    }

    // if (
    //   filterName === "departure" &&
    //   (!selectedBookingStatus || selectedBookingStatus.length === 0)
    // ) {
    //   showErrorToast("Please select the Status!");
    //   return;
    // }

    // if (
    //   filterName === "in_house" &&
    //   (!selectedBalanceType || selectedBalanceType.length === 0)
    // ) {
    //   showErrorToast("Please select the Status!");
    //   return;
    // }

    // if (
    //   filterName === "payment_reconciliation" &&
    //   (!selectedBalanceType || selectedBalanceType.length === 0)
    // ) {
    //   showErrorToast("Please select the Payment Option!");
    //   return;
    // }
    try {
      setChartData([]);
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/item-wise-supplier-analysis-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&selectedDate=${dailyFinanceDate}&item_name=${selectedItems}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
        setLoader(false);
      } else {
        setLoader(false);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const [detailedPurchaseError, setDetailedPurchaseError] = useState({
    item: false,
  });
  const fetchItemViseDetailedPurchaseReport = async (propertyId, roomIds) => {
    if (!selectedItems || selectedItems.length === 0) {
      showErrorToast("Oops!! No Item Selected!");
      setErrorWithTimeout(setDetailedPurchaseError, "item", 3000);
      return;
    }

    // if (
    //   filterName === "departure" &&
    //   (!selectedBookingStatus || selectedBookingStatus.length === 0)
    // ) {
    //   showErrorToast("Please select the Status!");
    //   return;
    // }

    // if (
    //   filterName === "in_house" &&
    //   (!selectedBalanceType || selectedBalanceType.length === 0)
    // ) {
    //   showErrorToast("Please select the Status!");
    //   return;
    // }

    // if (
    //   filterName === "payment_reconciliation" &&
    //   (!selectedBalanceType || selectedBalanceType.length === 0)
    // ) {
    //   showErrorToast("Please select the Payment Option!");
    //   return;
    // }
    try {
      setChartData([]);
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/item-wise-detailed-purchase-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&selectedDate=${dailyFinanceDate}&item_name=${selectedItems}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
        setLoader(false);
      } else {
        setLoader(false);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchCategoryViseSupplierAnalysisReport = async (
    propertyId,
    roomIds
  ) => {
    // if (!selectedRoomType || selectedRoomType.length === 0) {
    //   showErrorToast("Please select the Room Type first!");
    //   return;
    // }

    // if (
    //   filterName === "departure" &&
    //   (!selectedBookingStatus || selectedBookingStatus.length === 0)
    // ) {
    //   showErrorToast("Please select the Status!");
    //   return;
    // }

    // if (
    //   filterName === "in_house" &&
    //   (!selectedBalanceType || selectedBalanceType.length === 0)
    // ) {
    //   showErrorToast("Please select the Status!");
    //   return;
    // }

    // if (
    //   filterName === "payment_reconciliation" &&
    //   (!selectedBalanceType || selectedBalanceType.length === 0)
    // ) {
    //   showErrorToast("Please select the Payment Option!");
    //   return;
    // }

    try {
      setChartData([]);
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/category-wise-supplier-analysis-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
        setLoader(false);
      } else {
        setLoader(false);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const [detailedIssueItemsError, setDetailedIssueItemsError] = useState({
    item: false,
  });
  const fetchDetailedIssuedItemsReportReport = async (propertyId, roomIds) => {
    if (!selectedItems || selectedItems.length === 0) {
      // showErrorToast("Oops!! No Item Selected!");
      setErrorWithTimeout(setDetailedIssueItemsError, "item", 3000);
      return;
    }

    try {
      setChartData([]);
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/detailed-issued-items-report/${propertyId}?selectedDate=${dailyFinanceDate}&item_name=${selectedItems}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
        setLoader(false);
      } else {
        setLoader(false);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const [itemWiseConsolidatedError, setItemWiseConsolidatedError] = useState({
    item:false
  })
  const fetchItemWiseConsolidatedFinanceReport = async (
    propertyId,
    roomIds
  ) => {
    if (!selectedItems || selectedItems.length === 0) {
      showErrorToast("Oops!! No Item Selected!");
      setErrorWithTimeout(setItemWiseConsolidatedError, "item", 3000);
      return;
    }

    // if (
    //   filterName === "departure" &&
    //   (!selectedBookingStatus || selectedBookingStatus.length === 0)
    // ) {
    //   showErrorToast("Please select the Status!");
    //   return;
    // }

    // if (
    //   filterName === "in_house" &&
    //   (!selectedBalanceType || selectedBalanceType.length === 0)
    // ) {
    //   showErrorToast("Please select the Status!");
    //   return;
    // }

    // if (
    //   filterName === "payment_reconciliation" &&
    //   (!selectedBalanceType || selectedBalanceType.length === 0)
    // ) {
    //   showErrorToast("Please select the Payment Option!");
    //   return;
    // }

    try {
      setChartData([]);
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/item-wise-consolidated-finance-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&selectedDate=${dailyFinanceDate}&item_name=${selectedItems}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
        setLoader(false);
      } else {
        setLoader(false);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchRequisitionRequestReport = async (propertyId, roomIds) => {
    // if (!selectedItems || selectedItems.length === 0) {
    //   showErrorToast("Oops!! No Item Selected!");
    //   return;
    // }

    // if (
    //   filterName === "departure" &&
    //   (!selectedBookingStatus || selectedBookingStatus.length === 0)
    // ) {
    //   showErrorToast("Please select the Status!");
    //   return;
    // }

    // if (
    //   filterName === "in_house" &&
    //   (!selectedBalanceType || selectedBalanceType.length === 0)
    // ) {
    //   showErrorToast("Please select the Status!");
    //   return;
    // }

    // if (
    //   filterName === "payment_reconciliation" &&
    //   (!selectedBalanceType || selectedBalanceType.length === 0)
    // ) {
    //   showErrorToast("Please select the Payment Option!");
    //   return;
    // }

    try {
      setChartData([]);
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/requisition-request-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&selectedDate=${dailyFinanceDate}&item_name=${selectedItems}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
        setLoader(false);
      } else {
        setLoader(false);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchCategoryWiseReport = async (propertyId, roomIds) => {
    // if (!selectedItems || selectedItems.length === 0) {
    //   showErrorToast("Oops!! No Item Selected!");
    //   return;
    // }

    // if (
    //   filterName === "departure" &&
    //   (!selectedBookingStatus || selectedBookingStatus.length === 0)
    // ) {
    //   showErrorToast("Please select the Status!");
    //   return;
    // }

    // if (
    //   filterName === "in_house" &&
    //   (!selectedBalanceType || selectedBalanceType.length === 0)
    // ) {
    //   showErrorToast("Please select the Status!");
    //   return;
    // }

    // if (
    //   filterName === "payment_reconciliation" &&
    //   (!selectedBalanceType || selectedBalanceType.length === 0)
    // ) {
    //   showErrorToast("Please select the Payment Option!");
    //   return;
    // }

    try {
      setChartData([]);
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/category-wise-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&selectedDate=${dailyFinanceDate}&item_name=${selectedItems}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
        setLoader(false);
      } else {
        setLoader(false);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchItemViseConsolidatedSupplierAnalysisReport = async (
    propertyId,
    roomIds
  ) => {
    if (!selectedItems || selectedItems.length === 0) {
      showErrorToast("Oops!! No Item Selected!");
      return;
    }

    // if (
    //   filterName === "departure" &&
    //   (!selectedBookingStatus || selectedBookingStatus.length === 0)
    // ) {
    //   showErrorToast("Please select the Status!");
    //   return;
    // }

    // if (
    //   filterName === "in_house" &&
    //   (!selectedBalanceType || selectedBalanceType.length === 0)
    // ) {
    //   showErrorToast("Please select the Status!");
    //   return;
    // }

    // if (
    //   filterName === "payment_reconciliation" &&
    //   (!selectedBalanceType || selectedBalanceType.length === 0)
    // ) {
    //   showErrorToast("Please select the Payment Option!");
    //   return;
    // }

    try {
      setChartData([]);
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/item-wise-consolidated-finance-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
        setLoader(false);
      } else {
        setLoader(false);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  useEffect(() => {
    setChartData(fullData?.graph_data);
  }, [orders, fullData]);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(orders);

  const rowMarkup =
    orders &&
    orders.length > 0 &&
    orders?.map((order) => (
      <IndexTable.Row
        id={order.id}
        key={order.id}
        selected={selectedResources.includes(order.id)}
        position={order.index}
      >
        <IndexTable.Cell>{order.unique_booking_id}</IndexTable.Cell>
        <IndexTable.Cell>
          {order.guest && order.guest.length > 0 && order.guest[0].guest_name}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {" "}
          <TextDateFormatter
            as={"span"}
            showYear={true}
            date={order.check_in}
          />
        </IndexTable.Cell>
        <IndexTable.Cell>
          <TextDateFormatter
            as={"span"}
            showYear={true}
            date={order.check_out}
          />
        </IndexTable.Cell>
        <IndexTable.Cell>
          {order.guest &&
            order.guest.length > 0 &&
            order?.items.map((item) => <div>{item.room_type_name}</div>)}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {order.guest &&
            order.guest.length > 0 &&
            order?.items.map((item) => <div>{item.no_nights}</div>)}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {order.guest &&
            order.guest.length > 0 &&
            order?.items.map((item) => <div>{item.no_adult}</div>)}
        </IndexTable.Cell>
        <IndexTable.Cell>₹{order.balance_amount}</IndexTable.Cell>
        <IndexTable.Cell>
          <StatusUpdate status={order.status} /> {}
        </IndexTable.Cell>
      </IndexTable.Row>
    ));

  const otherMenusRef = useRef(null);
  const handleClickOutside = (event) => {
    if (
      otherMenusRef.current &&
      !otherMenusRef.current.contains(event.target)
    ) {
      setIsOpen(false);
      setIsOpen1(false);
      setIsOpen2(false);
      setIsOpen3(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getDataFromCustomRoomChoiceSelect = (data) => {
    setSelectedRoomType(data);
  };
  const getDataFromCustomRoomChoiceSelectPaymentOptions = (data) => {
    setPaymentOptions(data);
  };
  // ...................................
  // const extractedData = orders.map(item => ({
  //   unique_booking_id: item.unique_booking_id,
  //   guest_name: item.guest[0].guest_name,
  //   check_in: item.check_in,
  //   check_out: item.check_out,
  //   room_type_name: item.items[0].room_type_name,
  //   no_nights: item.items[0].no_nights,
  //   no_adult: item.items[0].no_adult,
  //   assigned_room: item.assignedrooms[0].assigned_room,
  //   status: item.status.replace(/_/g, " "),
  // }));
  const names = [
    {
      agent_id: "Agent ID",
      company_name: "Name",
      current_date_amount: "Amount",
      current_date_nights: "Nights",
      mtd_amount: "mtd_amount",
      mtd_nights: "mtd_nights",
      ytd_amount: "ytd_amount",
      ytd_nights: "ytd_nights",
    },
  ];

  return (
    <>
      <SidebarInnerLists innerLists={permission_inner_lists} zIndex={20} />

      {/* {loader ? (
        <Loader />
      ) : (
        <> */}
      {/* <CSVLink data={orders}>Download me</CSVLink>; */}
      <section className="dashboard_page">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "15px 0",
            }}
          >
            <div style={{ fontSize: "20px" }} className="report-chart-heading">
              Store And Purchases Reports
            </div>
          </div>

          <div>
            <div
              style={{
                background: "rgba(255, 255, 255, 1)",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <div title="Reservation Insights">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "10px",
                  }}
                >
                  <div className="report-chart-heading">Filters</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <div className="flex flex_gap_10">
                    <div style={{ width: "150px" }}>
                      <VerticalInputSelectCustomCopy
                        titleName={"Filter Name"}
                        value={filterName}
                        defaultValue={"arrival"}
                        onChange={(e) => {
                          setFilterName(e.target.value);
                          // setSelectedRoomType([]);
                          setBookingDetails([]);
                          setSelectedBookingStatus([]);
                          setOrders([]);
                          setSelectedItems([]);
                          // setSelectedDatesRange({
                          //   from_date: "",
                          //   to_date: "",
                          // })
                        }}
                        options={reportFilterOptions}
                      />
                    </div>
                    {filterName === "individual_vendor_finance_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                            defaultDate={`${calculateToDate(
                              currentDate
                            )}:${currentDate}`}
                            // minDate={new Date(currentDate)}
                          />
                        </div>

                        <VerticalInputSelectCustomCopy
                          titleName={"Select Supplier"}
                          value={selectedBookingStatus}
                          onChange={(e) => {
                            setSelectedBookingStatus(e.target.value);
                          }}
                          options={suppliersList ? suppliersList : []}
                          error={individualVendorError?.supplier}
                        />
                      </>
                    )}

                    {filterName === "consolidated_vendor_finance_report" && (
                      <>
                        {/* <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            defaultDate={`${calculateToDate(
                              currentDate
                            )}:${currentDate}`}
                            maxDate={new Date(currentDate)}
                            dataFromChild={dataFromChild}
                          />
                        </div> */}
                        <div style={{ width: "200px" }}>
                          <VerticalInputFieldCustom
                            type={"date"}
                            titleName={"Select Date"}
                            onChange={(e) =>
                              setDailyFinanceDate(e.target.value)
                            }
                            value={dailyFinanceDate}
                            name={"dailyFinanceDate"}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "invoice_settlement_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            defaultDate={`${calculateToDate(
                              currentDate
                            )}:${currentDate}`}
                            maxDate={new Date(currentDate)}
                            dataFromChild={dataFromChild}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "requisition_request_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            defaultDate={`${calculateToDate(
                              currentDate
                            )}:${currentDate}`}
                            maxDate={new Date(currentDate)}
                            dataFromChild={dataFromChild}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "category_wise_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            defaultDate={`${calculateToDate(
                              currentDate
                            )}:${currentDate}`}
                            maxDate={new Date(currentDate)}
                            dataFromChild={dataFromChild}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "item_vise_supplier_analysis" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <VerticalInputFieldCustom
                            type={"date"}
                            titleName={"Select Date"}
                            onChange={(e) =>
                              setDailyFinanceDate(e.target.value)
                            }
                            value={dailyFinanceDate}
                            name={"dailyFinanceDate"}
                          />
                        </div>
                        <div style={{ width: "200px" }}>
                          <MultiSelectSearchDropdown
                            options={allItems}
                            getKeyName={"item_name"}
                            optionKey={"item_name"}
                            titleName={"Select Items"}
                            onChange={handleSelectedOptionsChange}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "item_wise_detailed_purchase_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <VerticalInputFieldCustom
                            type={"date"}
                            titleName={"Select Date"}
                            onChange={(e) =>
                              setDailyFinanceDate(e.target.value)
                            }
                            value={dailyFinanceDate}
                            name={"dailyFinanceDate"}
                          />
                        </div>
                        <div style={{ width: "200px" }}>
                          <MultiSelectSearchDropdown
                            options={allItems}
                            getKeyName={"item_name"}
                            optionKey={"item_name"}
                            titleName={"Select Items"}
                            onChange={handleSelectedOptionsChange}
                            error={detailedPurchaseError?.item}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "detailed_issued_items_report" && (
                      <>
                        {/* <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            defaultDate={`${calculateToDate(
                              currentDate
                            )}:${currentDate}`}
                            maxDate={new Date(currentDate)}
                            dataFromChild={dataFromChild}
                          />
                        </div> */}
                        <div style={{ width: "200px" }}>
                          <VerticalInputFieldCustom
                            type={"date"}
                            titleName={"Select Date"}
                            onChange={(e) =>
                              setDailyFinanceDate(e.target.value)
                            }
                            value={dailyFinanceDate}
                            name={"dailyFinanceDate"}
                          />
                        </div>

                        <div style={{ width: "200px" }}>
                          <MultiSelectSearchDropdown
                            options={allItems}
                            getKeyName={"item_name"}
                            optionKey={"item_name"}
                            titleName={"Select Items"}
                            onChange={handleSelectedOptionsChange}
                            error={detailedIssueItemsError?.item}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "item_wise_consolidated_finance_report" && (
                      <>
                        
                        <div style={{ width: "200px" }}>
                          <VerticalInputFieldCustom
                            type={"date"}
                            titleName={"Select Date"}
                            onChange={(e) =>
                              setDailyFinanceDate(e.target.value)
                            }
                            value={dailyFinanceDate}
                            name={"dailyFinanceDate"}
                          />
                        </div>
                        <div style={{ width: "200px" }}>
                          <MultiSelectSearchDropdown
                            options={allItems}
                            getKeyName={"item_name"}
                            optionKey={"item_name"}
                            titleName={"Select Items"}
                            onChange={handleSelectedOptionsChange}
                            error={itemWiseConsolidatedError?.item}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "category_vise_supplier_analysis" && (
                      <>
                        {/* <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            defaultDate={`${calculateToDate(
                              currentDate
                            )}:${currentDate}`}
                            maxDate={new Date(currentDate)}
                            dataFromChild={dataFromChild}
                          />
                        </div> */}

                        <div style={{ width: "200px" }}>
                          <VerticalInputFieldCustom
                            type={"date"}
                            titleName={"Select Date"}
                            onChange={(e) =>
                              setDailyFinanceDate(e.target.value)
                            }
                            value={dailyFinanceDate}
                            name={"dailyFinanceDate"}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="flex flex_gap_10">
                    {filterName === "individual_vendor_finance_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchIndividualVendorReport(property_id.id)
                        }
                      />
                    )}
                    {filterName === "consolidated_vendor_finance_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchConsolidatedVendorReport(property_id.id)
                        }
                      />
                    )}
                    {filterName === "invoice_settlement_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchInvoiceSettlementReport(property_id.id)
                        }
                      />
                    )}
                    {filterName === "item_vise_supplier_analysis" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchItemViseSupplierAnalysisReport(property_id.id)
                        }
                      />
                    )}
                    {filterName === "item_wise_detailed_purchase_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchItemViseDetailedPurchaseReport(property_id.id)
                        }
                      />
                    )}
                    {filterName === "detailed_issued_items_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchDetailedIssuedItemsReportReport(property_id.id)
                        }
                      />
                    )}
                    {filterName === "item_wise_consolidated_finance_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchItemWiseConsolidatedFinanceReport(property_id.id)
                        }
                      />
                    )}
                    {filterName === "requisition_request_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchRequisitionRequestReport(property_id.id)
                        }
                      />
                    )}
                    {filterName === "category_wise_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchCategoryWiseReport(property_id.id)}
                      />
                    )}
                    {filterName === "category_vise_supplier_analysis" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchCategoryViseSupplierAnalysisReport(
                            property_id.id
                          )
                        }
                      />
                    )}

                    <BgOutlineThemeButton
                      onClick={() => {
                        setFilterName("");
                        // setSelectedRoomType([]);
                        setBookingDetails([]);
                        setSelectedBookingStatus([]);
                        setOrders([]);
                      }}
                      type={"button"}
                      children={"Clear"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {orders && orders.length > 0 && chartData && chartData.length > 0 && (
            <BarCharts loader={loader} data={data} leftTitle={"Insights"} />
          )}
          {filterName === "departure" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportDepartureTable departureReport={orders} />
              )}
            </>
          )}
          {filterName === "account_balance" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportAccountBalanceTable departureReport={orders} />
              )}
            </>
          )}
          {filterName === "cancellation" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportCancelTable departureReport={orders} />
              )}
            </>
          )}
          {filterName === "no_show" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportNoShowTable departureReport={orders} />
              )}
            </>
          )}

          {filterName === "room_assignment" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportRoomAssignment departureReport={orders} />
              )}
            </>
          )}

          {filterName === "payment_reconciliation" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportPaymentReconciliation
                  departureReport={orders}
                  settlementOrders={settlementOrders}
                />
              )}
            </>
          )}
          {filterName === "revenue_report" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportPaymentDailyRevenue departureReport={orders} />
              )}
            </>
          )}
          {filterName === "individual_vendor_finance_report" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportIndividualVendor departureReport={orders} />
              )}
            </>
          )}
          {filterName === "consolidated_vendor_finance_report" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportConsolidatedVendor departureReport={orders} />
              )}
            </>
          )}
          {filterName === "invoice_settlement_report" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportInvoiceSettlement departureReport={orders} />
              )}
            </>
          )}
          {filterName === "item_vise_supplier_analysis" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportItemViseSupplier departureReport={orders} />
              )}
            </>
          )}
          {filterName === "item_wise_detailed_purchase_report" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportItemViseDetailedPurchase departureReport={orders} />
              )}
            </>
          )}
          {filterName === "detailed_issued_items_report" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportDetailedIssueItem departureReport={orders} />
              )}
            </>
          )}
          {filterName === "item_wise_consolidated_finance_report" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportItemViseConsolidatedFinance departureReport={orders} />
              )}
            </>
          )}
          {filterName === "requisition_request_report" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportRequisitionRequestReport departureReport={orders} />
              )}
            </>
          )}
          {filterName === "category_wise_report" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportCategoryWiseReport departureReport={orders} />
              )}
            </>
          )}
          {filterName === "category_vise_supplier_analysis" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportCategoryViseSupplier departureReport={orders} />
              )}
            </>
          )}
          {filterName === "in_house" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <>
                  {orders && orders.length > 0 ? (
                    <div
                      style={{
                        background: "rgba(255, 255, 255, 1)",
                        padding: "20px",
                        borderRadius: "10px",
                      }}
                    >
                      <div title="Reservation Insights">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingBottom: "10px",
                          }}
                        >
                          <div
                            style={{ textTransform: "capitalize" }}
                            className="report-chart-heading"
                          >
                            Detailed In-House Report
                          </div>
                          {orders?.length > 0 && (
                            <div className="flex flex_gap_15">
                              <DownloadPdfTables
                                tableData={orders}
                                tableNames={names}
                              />
                              <DownloadCsvTables tableData={orders} />
                            </div>
                          )}
                        </div>
                        <div>
                          {orders && orders.length > 0 ? (
                            <LegacyCard>
                              <IndexTable
                                selectable={false}
                                resourceName={resourceName}
                                itemCount={orders.length}
                                selectedItemsCount={
                                  allResourcesSelected
                                    ? "All"
                                    : selectedResources.length
                                }
                                onSelectionChange={handleSelectionChange}
                                headings={[
                                  { title: "Booking ID" },
                                  { title: "Guest Name" },
                                  { title: "Check In Date" },
                                  { title: "Check Out Date" },
                                  { title: "Room Type" },
                                  { title: "No Of Nights" },
                                  { title: "No Of Guests" },
                                  { title: "Balance" },
                                  { title: "Status" },
                                ]}
                              >
                                {rowMarkup}
                              </IndexTable>
                            </LegacyCard>
                          ) : (
                            <NoDataFound />
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <NoDataFound />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </section>
      {/* </>
      )} */}
    </>
  );
};

export default StoreAndPurchasesReports;
