import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate} from "react-router";
import { BgOutlineThemeButton, BgThemeButton } from "../../../UI/Buttons";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../assets/toastUtils";
import VerticalInputFieldCustom from "../../VerticalInputFieldCustom.jsx";
import {
  grandTotalForInclusive,
  grandTotalForExclusive,
  taxAmountForInclusive,
  taxAmountForExclusive,
  calculateSubtotalForInclusive,
  calculateSubtotalForExclusive,
} from "../calculationFunctions/CalculationFunctions.jsx";
import { calculateTotal } from "../calculationFunctions/CalculateKeyTotalFromArrayOfObjects";
import { getDecryptedData } from "../../../../utils/encryptStorage.js";

const ModifyReservationConfirmChangeRoomCategory = ({
  bookingData,
  addOnCost,
  reasons,
  setReasons,
  setShowModifyPopup,
  handleReasonChange,
  getBookingData,
  fetchBookingDetails
}) => {
  const [loader,setLoader] = useState(false);
  const property_id = useSelector((state) => state.property.value);
  const userToken = getDecryptedData('encryptToken');
  const navigate = useNavigate();

  const [editedRoomCategory, setEditedRoomCategory] = useState([]);

  useEffect(() => {
    if (bookingData && bookingData.items) {
      const newEditedRoomCategory = bookingData.items.map((item) => ({
        room_category: item.room_type_name,
        changed_room_category: "",
        room_id: item.room_id,
        rate_plan_id: item.rate_plan_id,
        meal_plan: item.room_plan,
        room_rate_per_night: item.room_rate,
        ad_Hoc: item.ad_Hoc || "",
        room_rate: [],
        paxes: item.paxes,
        tax_type: item.tax_type,
        no_of_rooms: item.no_of_rooms,
        no_of_nights: item.no_nights,
        booking_id: item.id,
        discount_rate: item.discount_rate,
        id: item.rate_plan_id,
        new_room_rate: "",
        old_tax_rate: item.tax_rate,
        old_tax_amount: item.tax_amount,
        old_discount_amount: item.discount_amount,
        old_grand_total: item.room_grand_total,
        old_sub_total: item.room_total_amount,
      }));

      setEditedRoomCategory(newEditedRoomCategory);
    }
  }, [bookingData]);

  const [roomCategoryOptions, setRoomCategoryOptions] = useState([]);

  const fetchRoomCategory = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setRoomCategoryOptions(data?.rooms);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchRoomCategory();
    }
  }, [property_id]);

  const handleRoomCategoryChange = async (e, index, rooms) => {
    const room_id = e.target.value;
    const changedRoomCategoryObject = roomCategoryOptions.find(
      (category) => category.id === parseInt(room_id)
    );
    const changedRoomCategory = changedRoomCategoryObject?.room_name;
    const isRoomCategoryInArray = editedRoomCategory.find(
      (item) => item.changed_room_category === changedRoomCategory
    );
    if (isRoomCategoryInArray) {
      showErrorToast(
        "Room category already exists. Please choose another category."
      );
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/check/availability/${property_id?.id}?check_in=${bookingData.check_in}&check_out=${bookingData.check_out}&room_id=${room_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();

      if (data.message === "Unauthorised.") {
        navigate("/");
      }

      // Check if rooms is greater than available rooms
      const blockedRooms = data.available_rooms_dates.filter(
        (item) => item.blocked !== 0
      );
      if (rooms > data.available_rooms) {
        showErrorToast(
          `Failed to change room category. Rooms available for this category is ${data.available_rooms}. Please choose another category.`
        );
        return; // Stop executing the function
      } else if (blockedRooms.length > 0) {
        showErrorToast(
          "Room is blocked for selected dates :  " +
            blockedRooms.map((room) => room.date).join(", ")
        );
        return;
      } else {
        setEditedRoomCategory((prevState) => {
          const updatedEditedRoomCategory = [...prevState];
          updatedEditedRoomCategory[index] = {
            ...updatedEditedRoomCategory[index],
            room_id: room_id,
            changed_room_category: changedRoomCategory,
          };
          return updatedEditedRoomCategory;
        });

        setShowRoomCategoryTotal(false);
        setNewRoomCategoryTotal(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [showRoomRateField, setShowRoomRateField] = useState(
    Array(editedRoomCategory.length).fill(false)
  );
  const handleShowRoomRateField = (index) => {
    setShowRoomRateField((prevShowRoomRateField) => {
      const updatedShowRoomRateField = [...prevShowRoomRateField];
      updatedShowRoomRateField[index] = !updatedShowRoomRateField[index];
      return updatedShowRoomRateField;
    });
  };
  useEffect(() => {
    showRoomRateField.forEach((show, index) => {
      if (!show) {
        setEditedRoomCategory((prevEditedRoomCategory) => {
          const updatedEditedRoomCategory = [...prevEditedRoomCategory];
          updatedEditedRoomCategory[index].new_room_rate_per_night = "";
          return updatedEditedRoomCategory;
        });
      }
    });
  }, [showRoomRateField]);

  const [showRoomCategoryTotal, setShowRoomCategoryTotal] = useState(false);
  const [newRoomCategoryTotal, setNewRoomCategoryTotal] = useState(0);

  const handleRoomCategoryTotal = () => {
    // const check = editedRoomCategory.some(
    //   (obj) => obj.changed_room_category !== ""
    // );

    // if (!check) {
    //   showErrorToast("please select the changed room category");
    //   return;
    // }
    const extractPrices = (paxes) => {
      let childPrice = 0;
      let extraAdultPrice = 0;

      if (Array.isArray(paxes)) {
        paxes.forEach((pax) => {
          if (pax.pax_type === "child") {
            childPrice += parseFloat(pax.price); // Accumulate child prices
          } else if (pax.pax_type === "adult") {
            extraAdultPrice += parseFloat(pax.price); // Accumulate adult prices
          }
        });
      } else {
        console.error("Invalid paxes data:", paxes);
      }

      return { childPrice, extraAdultPrice };
    };

    const rooms = editedRoomCategory.map((item) => {
      const rate_per_night = item.new_room_rate_per_night
        ? item.new_room_rate_per_night
        : item.room_rate_per_night;

      const taxRate = +rate_per_night > 7500 ? 18 : 12;
      const discountAmount = item.old_discount_amount ;

      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);
      console.log(childPrice, "discountAmount");
      const modifiedItem = {
        // RoomID: item.singleRoomRate.room_id,
        Rate: rate_per_night,
        RoomTypeName: item.changed_room_category,
        // MealPlan: item.singleRoomRate.rate_plan_type,
        BookingItemId: item.booking_id,
        discountAmount: discountAmount,
        TaxRate: taxRate,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
                +rate_per_night,
                +item.no_of_nights,

                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +discountAmount
              )
            : grandTotalForExclusive(
                +rate_per_night,
                +item.no_of_nights,

                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
                +rate_per_night,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              )
            : taxAmountForExclusive(
                +rate_per_night,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
                +rate_per_night,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              )
            : calculateSubtotalForExclusive(
                +rate_per_night,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +discountAmount
              ),
      };
      return modifiedItem;
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "change_room_category",
      bookingNetTotal: +calculateTotal(rooms, "NetTotal") + +addOnCost.NetTotal,
      bookingTaxableAmount:
        +calculateTotal(rooms, "TaxbleTotal") + +addOnCost.taxableAmount,
      bookingTaxAmount:
        +calculateTotal(rooms, "TaxAmount") + +addOnCost.taxAmount,
      Room: rooms,
    };
    setNewRoomCategoryTotal(requestBody.bookingNetTotal);
    setShowRoomCategoryTotal(true);
  };

  const handleRoomCategorySubmit = async (e) => {
   
    if (reasons.changeRoomCategoryReason === "") {
      showErrorToast("Please enter a reason for changing room category");
      return;
    }

    const extractPrices = (paxes) => {
      let childPrice = 0;
      let extraAdultPrice = 0;

      if (Array.isArray(paxes)) {
        paxes.forEach((pax) => {
          if (pax.pax_type === "child") {
            childPrice += parseFloat(pax.price); // Accumulate child prices
          } else if (pax.pax_type === "adult") {
            extraAdultPrice += parseFloat(pax.price); // Accumulate adult prices
          }
        });
      } else {
        console.error("Invalid paxes data:", paxes);
      }

      return { childPrice, extraAdultPrice };
    };

    const rooms = editedRoomCategory.map((item) => {
      const rate_per_night = item.new_room_rate_per_night
        ? item.new_room_rate_per_night
        : item.room_rate_per_night;

      const taxRate = +rate_per_night > 7500 ? 18 : 12;
      const discountAmount = item.old_discount_amount;

      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);

      const modifiedItem = {
        RoomID: item.room_id,
        Rate: rate_per_night,
        RoomTypeName: item.changed_room_category
          ? item.changed_room_category
          : item.room_category,
        // MealPlan: item.singleRoomRate.rate_plan_type,
        BookingItemId: item.booking_id,
        RoomDiscount: discountAmount,
        TaxRate: taxRate,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
                +rate_per_night,
                +item.no_of_nights,

                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +discountAmount
              )
            : grandTotalForExclusive(
                +rate_per_night,
                +item.no_of_nights,

                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
                +rate_per_night,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              )
            : taxAmountForExclusive(
                +rate_per_night,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
                +rate_per_night,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              )
            : calculateSubtotalForExclusive(
                +rate_per_night,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +discountAmount
              ),
      };
      return modifiedItem;
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "change_room_category",
      bookingNetTotal: +calculateTotal(rooms, "NetTotal") + +addOnCost.NetTotal,
      bookingTaxableAmount:
        +calculateTotal(rooms, "TaxbleTotal") + +addOnCost.taxableAmount,
      bookingTaxAmount:
        +calculateTotal(rooms, "TaxAmount") + +addOnCost.taxAmount,
      Room: rooms,
      requestReason: reasons.changeRoomCategoryReason,
      bookingDiscountAmount:
        +calculateTotal(rooms, "RoomDiscount") + +addOnCost.discountAmount,
      bookingDueAmount:
        +calculateTotal(rooms, "NetTotal") +
        +addOnCost.NetTotal -
        +bookingData.paid_amount,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(requestBody),
    };

    try {
      setLoader(true)
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modif/stay/${property_id.id}`,
        requestOptions
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      showSuccessToast("Room category changed successfully");
      setShowRoomCategoryTotal(false);
      setNewRoomCategoryTotal(0);
      setShowModifyPopup(false);
      setReasons({});
      fetchBookingDetails();
      getBookingData(bookingData.unique_booking_id);
      setLoader(false)
      return data;
    } catch (error) {
      console.error(error);
      showErrorToast(error.message);
      return null;
    }
  };

  const handleDiscountAmountChangeRoomCategory = (
    event,
    index,
    oldRoomRate,
    newRoomRate,
    noOfRooms
  ) => {
    const { name, value } = event.target;
    if (newRoomRate ? +value > +newRoomRate : +value > +oldRoomRate) {
      showErrorToast("enter valid discount amount");
      return;
    } else {
      setEditedRoomCategory((prevState) => {
        const updatedEditedRoomCategory = [...prevState];
        updatedEditedRoomCategory[index] = {
          ...updatedEditedRoomCategory[index],
          [name]: value,
        };
        return updatedEditedRoomCategory;
      });
      setShowRoomCategoryTotal(false);
      setNewRoomCategoryTotal(0);
    }
  };

  const handleNewRoomRateChangeRoomCategory = (event, index) => {
    const { name, value } = event.target;
    setEditedRoomCategory((prevState) => {
      const updatedEditedRoomCategory = [...prevState];
      updatedEditedRoomCategory[index] = {
        ...updatedEditedRoomCategory[index],
        [name]: value,
      };
      return updatedEditedRoomCategory;
    });
    setShowRoomCategoryTotal(false);
    setNewRoomCategoryTotal(0);
  };
  return (
    <div>
      <div>
        <table
          className="modifyReservationPopupTable"
          style={{
            width: "100%",
            borderSpacing: "10px 0px",
          }}
        >
          <thead>
            <tr>
              <th>Room Category</th>
              <th>Change to</th>
              <th>Meal Plan</th>
              <th>Rate/Night</th>
              <th></th>
              <th>Discount Amount</th>
            </tr>
          </thead>
          <tbody>
            {editedRoomCategory.map((items, index) => (
              <tr key={index}>
                <td>
                  <VerticalInputFieldCustom
                    value={items.room_category}
                    disabled={true}
                  />
                </td>
                <td>
                  <div>
                    <div className="select">
                      <select
                        className="select-custom"
                        style={{
                          width: "100%",
                          minHeight: "2.25rem",
                          border: "0.0625rem solid #888",
                          borderRadius: "0.25rem",
                          paddingLeft: "5px",
                        }}
                        onChange={(e) =>
                          handleRoomCategoryChange(e, index, items.no_of_rooms)
                        }
                        value={items.room_id}
                      >
                        <option value="">select</option>
                        {roomCategoryOptions.map((item, index) => {
                          const maxOccupancy =
                            +items.no_of_rooms *
                              (+items.no_adult +
                                +items.no_child +
                                +items.extra_adult) >
                            +item.max_occupancy * +items.no_of_rooms;

                          const extraAdultLimit =
                            +items.no_of_rooms * +items.extra_adult >
                            (+item.max_adults - +items.base_adult) *
                              +items.no_of_rooms;

                          const childLimit =
                            +items.no_child * +items.no_of_rooms >
                            +item.max_child * +items.no_of_rooms;
                          console.log(
                            maxOccupancy,
                            extraAdultLimit,
                            childLimit,
                            "limits"
                          );
                          return (
                            <option
                              key={index}
                              value={item?.id}
                              disabled={
                                items?.room_category === item.room_name ||
                                childLimit ||
                                extraAdultLimit
                                //  ( items?.room_category ===
                                //   item.room_name) || (+items.no_of_rooms*( +items.no_adult + +items.no_child + +items.extraAdult) ) > (+item.max_occupancy * +items.no_of_rooms ) || (+items.no_child * +items.no_of_rooms) > (+item.max_child * +items.no_of_rooms)  || (+items.no_adult * +items.no_of_rooms) > (+item.base_adult * +items.no_of_rooms)
                              }
                            >
                              {item.room_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </td>
                <td>
                  <VerticalInputFieldCustom
                    value={items.meal_plan}
                    disabled={true}
                  />
                </td>
                <td className="d_flex flex_gap_10">
                  <div
                    className="create_booking_editable_input_container"
                    style={{
                      width: "50%",
                      height: "100%",
                    }}
                  >
                    <div
                      className="common_min_input d_flex gap_7"
                      style={{
                        marginTop: "5px",
                        width: "100px",
                        height: "38px",
                      }}
                    >
                      <input
                        defaultValue={items.room_rate_per_night}
                        disabled
                      ></input>
                      <svg
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => handleShowRoomRateField(index)}
                        style={{
                          cursor: "pointer",
                          zIndex: "10000",
                        }}
                      >
                        <g clipPath="url(#clip0_5205_24822)">
                          <path
                            d="M8.40292 1.02256L10.2788 2.97704L10.5917 2.65107C11.1096 2.11144 11.1096 1.23666 10.5917 0.697025C10.0736 0.157225 9.23346 0.157225 8.71536 0.697025L8.40292 1.02256Z"
                            fill="#888888"
                          />
                          <path
                            d="M9.41751 3.87236L7.54162 1.91788L1.58902 8.11881L0.980103 10.7077L3.46491 10.0733L9.41751 3.87236Z"
                            fill="#888888"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_5205_24822">
                            <rect
                              width="10"
                              height="10.4167"
                              fill="white"
                              transform="translate(0.980072 0.291687)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </td>
                <td
                  style={{
                    width: showRoomRateField[index] ? "80px" : "0",
                  }}
                >
                  {showRoomRateField[index] && (
                    <div style={{ width: "100%" }}>
                      <VerticalInputFieldCustom
                        value={items.new_room_rate_per_night}
                        name="new_room_rate_per_night"
                        onChange={(e) =>
                          handleNewRoomRateChangeRoomCategory(e, index)
                        }
                      />
                    </div>
                  )}
                </td>
                <td>
                  <VerticalInputFieldCustom
                    value={Number(items.old_discount_amount)}
                    name="old_discount_amount"
                    onChange={(e) =>
                      handleDiscountAmountChangeRoomCategory(
                        e,
                        index,
                        items.room_rate_per_night,
                        items.new_room_rate_per_night,
                        items.no_of_rooms
                      )
                    }
                    type={"number"}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="modify_reservation_reason_container">
          <VerticalInputFieldCustom
            titleName={"Reason to Modify*"}
            name="changeRoomCategoryReason"
            onChange={handleReasonChange}
          />

          <div className="modify_reservation_reason_container flex flex_gap_10 align_items_center">
            <label>New Total:</label>

            <VerticalInputFieldCustom
              value={newRoomCategoryTotal}
              disabled={true}
            />

            <div>Previous Total : {bookingData?.grand_total}</div>
          </div>
        </div>

        <>
          {/* buttons for new room rate */}

          <div className="flex flex_gap_10">
            <BgThemeButton
              children={!showRoomCategoryTotal ? "Calculate" : "Modify"}
              onClick={
                !showRoomCategoryTotal
                  ? handleRoomCategoryTotal
                  : handleRoomCategorySubmit
              }
              loader={loader}
            />
            <BgOutlineThemeButton
              children={"Cancel"}
              onClick={() => setShowModifyPopup(false)}
            />
          </div>
        </>
      </div>
    </div>
  );
};

export default ModifyReservationConfirmChangeRoomCategory;
