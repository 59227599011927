import React from "react";

import Expenses from "../Components/Expenses";

const ExpensesPage = () => {
  return (
    <>
      <div className="main_container">
        {/* <NewSidebar /> */}
        <Expenses />
      </div>
    </>
  );
};

export default ExpensesPage;
