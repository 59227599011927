export const showSuccessfullToast = (toastRef, message, duration = 4000) => {
    if (toastRef?.current) {
      toastRef.current.addToast("success", message, duration, "fadeIn");
    }
  };
  
  export const showErrorCustomToast = (toastRef, message, duration = 4000) => {
    if (toastRef?.current) {
      toastRef.current.addToast("error", message, duration, "fadeIn");
    }
  };
  
  export const showInfoToast = (toastRef, message, duration = 5000) => {
    if (toastRef?.current) {
      toastRef.current.addToast("info", message, duration, "fadeIn");
    }
  };
  