import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the datalabels plugin
import { useSelector } from "react-redux";
import LoaderSmall from "../../UI/Loader/LoaderSmall";
import { getDecryptedData } from "../../../utils/encryptStorage";

const BookingSourceChart = () => {
  const [loader, setLoader] = useState(false);
  const property_id = useSelector((state) => state.property.value);
  const userToken = getDecryptedData('encryptToken');
  

  const fetchBookingSourceData = async (id) => {
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/booking-data-by-source/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (!response.ok) {
        setLoader(false);
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      setBookingSourceChartData({
        graphData: data?.data,
        total : data?.total_bookings
      });
      setLoader(false);
      //   setBookingSourceChartData(data);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchBookingSourceData(property_id?.id);
    }
  }, [property_id]);
  const [bookingSourceChartData, setBookingSourceChartData] = useState({
    graphData: [],
    total : 0
  });

  const labels = bookingSourceChartData?.graphData?.map((data) => data.name);
  const dataValues = bookingSourceChartData?.graphData?.map((data) => data.thisMonth);
  const percentChange = bookingSourceChartData?.graphData?.map((data) => {
    const { thisMonth, lastMonth } = data
    const change = thisMonth - lastMonth ;
    return change.toFixed(2);
  });

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Bookings",
        data: dataValues,
        backgroundColor: ["#4285F4", "#34A853", "#FBBC05", "#EA4335"], // Colors for each bar
        borderRadius: 10,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: true,
        align: "end",
        anchor: "end",
        color: "#000", // Label color
        font: {
          weight: "bold",
        },
        formatter: function (value, context) {
          const index = context.dataIndex;
          const change = percentChange[index];
          if (change === 0) {
            return "0"; // No change
          }
          const direction = change > 0 ? "↑" : "↓";
          return `${Math.abs(change)} ${direction}`; // Only the difference and direction
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const index = tooltipItem.dataIndex;
            const lastMonthValue = bookingSourceChartData.graphData[index].lastMonth;
            const thisMonthValue = bookingSourceChartData.graphData[index].thisMonth;
            
            return [
              `Last Month: ${lastMonthValue}`,
              `This Month: ${thisMonthValue}`,
            ];
          },
        },
      },
      legend: {
        display: false, // Hide the legend
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        min: Math.min(...dataValues) * 0.8, // Adjust minimum value
        max: Math.max(...dataValues) * 1.5, // Adjust maximum value
        ticks: {
          stepSize: 100, // Adjust step size as needed
        },
        display: false,
      },
      x: {
        grid: {
          display: false, // Hides the grid lines on the x-axis
        },
        border: {
          display: true, // Shows the x-axis line
        },
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      {loader ? (
        <LoaderSmall />
      ) : (
        <>
        <div style={{ position: "absolute", top: "-10px", left: "0px",backgroundColor: "#D0F5D1",color:"#15AA12" ,padding:"2.5px 5px",borderRadius:"5px",fontSize:"12px"}}>
           Total: {bookingSourceChartData?.total} 
        </div>
          <Bar
            data={chartData}
            options={chartOptions}
            plugins={[ChartDataLabels]}
          />
        </>
      )}
    </div>
  );
};

export default BookingSourceChart;
