import React from "react";
import Sidebar from "../Components/Sidebar";
import Agent from "../Components/Agent";
import Items from "../Components/Items";


const ItemPage = () => {
  return (
    <>
      <div className="main_container">
        <Items />
      </div>
    </>
  );
};

export default ItemPage;
