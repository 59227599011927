import React, { useEffect, useState } from "react";

const KOTStatusCustomComp = ({ status }) => {
  const InProcessing = (
    <div
      style={{
        color: "#E0AE18",
        padding: "6px",
        backgroundColor: "#FFF4D2",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
      }}
    >
      In Processing
    </div>
  );

  const delivered = (
    <div
      style={{
        color: "#15AA12",
        padding: "6px",
        backgroundColor: "#DFF8DF",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        fontSize:"12px"
      }}
    >
      Delivered
    </div>
  );

  const Cancelled = (
    <div
      style={{
        color: "#E03838",
        padding: "6px",
        backgroundColor: "#FFEAEA",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        fontSize:"12px"
      }}
    >
      Cancelled
    </div>
  );

  const Billed = (
    <div
      style={{
        color: "#15AA12",
        padding: "6px",
        backgroundColor: "#DFF8DF",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
      }}
    >
      Billed
    </div>
  );


  const undelivered = (
    <div
      style={{
        color: "#E8B41A",
        padding: "6px",
        backgroundColor: "#FFF4D2",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
      }}
    >
      Undelivered
    </div>
  );

  const Preparing = (
    <div
      style={{
        color: "#E8B41A",
        padding: "6px",
        backgroundColor: "#FFF4D2",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
      }}
    >
      Preparing
    </div>
  );

  const InKOT = (
    <div
      style={{
        color: "#3968ED",
        padding: "6px",
        backgroundColor: "#ECF1FD",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
      }}
    >
      Preparing
    </div>
  );

  const NewKot = (
    <div
      style={{

        padding: "6px",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        fontSize:"12px",color: "#3968ED",
        backgroundColor: "#ECF1FD",
      }}
    >
      New Kot
    </div>
  );

  useEffect(() => {
    if (status === "Billed") {
      setButtonStatus(Billed);
    } else if (status === "In Processing") {
      setButtonStatus(InProcessing);
    } else if (status === "In KOT") {
      setButtonStatus(InKOT);
    } else if (status === "Cancelled") {
      setButtonStatus(Cancelled);
    } else if (status === "cancelled") {
      setButtonStatus(Cancelled);
    } else if (status === "delivered") {
      setButtonStatus(delivered);
    } else if (status === "undelivered") {
      setButtonStatus(undelivered);
    } else if (status === "preparing") {
      setButtonStatus(Preparing);
    } else if (status === "new_kot") {
      setButtonStatus(NewKot);
    } else {
      setButtonStatus(Billed);
    }
  }, [status]);

  const [buttonStatus, setButtonStatus] = useState(status);

  return <div>{buttonStatus}</div>;
};

export default KOTStatusCustomComp;
