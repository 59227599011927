import {
  TextField,
  IndexTable,
  LegacyCard,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Button,
  Icon,
  Select,
  Page,
} from "@shopify/polaris";

import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  WithoutBgButtonBlue,
} from "./UI/Buttons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import Loader from "./UI/Loader/Loader";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import NoDataFound from "./CustomComponents/NoDataFound";
import { pms_inner_lists } from "../assets/constant";
import SidebarInnerLists from "./CustomComponents/SidebarInnerLists";
import CustomPagination from "./CustomComponents/CustomPagination/CustomPagination";
import { getDecryptedData } from "../utils/encryptStorage";

const Expenses = () => {
  const [orders, setOrders] = useState([]);
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddStockModelMenuActive, setShowAddStockModelMenuActive] =
    useState(false);

  const [showAddItemModelMenuActive, setShowAddItemModelMenuActive] =
    useState(false);

  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);

  const navigate = useNavigate();
  const userToken = getDecryptedData('encryptToken');

  const property_id = useSelector((state) => state.property.value);

  const [loader, setLoader] = useState(true);

  // Pagignation
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(orders.length / itemsPerPage);
    setCurrentPage(newPage > totalPages ? totalPages : newPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentOrders = orders.slice(startIndex, endIndex);

  const fetchInfo = async (propertyId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        // /api/v1/expenses/1
        `${process.env.REACT_APP_BASE_URL}/api/v1/expenses/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data.expenses);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

   useEffect(() => {
    if (property_id  && userToken)  {
      fetchInfo(property_id?.id);
    }
  }, [property_id, userToken]);

  // setTimeout(() => {
  //   setLoader(false);
  // }, 1000);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(currentOrders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const addModalButtonClickHandler = () => {
    setShowAddStockModelMenuActive(!showAddStockModelMenuActive);
  };

  const editModalButtonClickHandler = () => {
    setShowEditModelMenuActive(!showEditModelMenuActive);
  };

  const removeAddModalContainer = () => {
    setShowAddStockModelMenuActive(!showAddStockModelMenuActive);
  };

  // UseState for forms
  const [addItemData, setAddItemData] = useState({
    expense_name: "",
    expense_type: "",
    expense_category: "",
    expense_date: "",
    expense_amount: "",
  });

  const setAddItemDataHandleChange = (event) => {
    setAddItemData({
      ...addItemData,
      [event.target.name]: event.target.value,
    });
  };

  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/expenses/edit/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        room_name: data.expenses.room_name,
        expense_name: data.expenses.expense_name,
        expense_type: data.expenses.expense_type,
        expense_category: data.expenses.expense_category,
        expense_date: data.expenses.expense_date,
        expense_amount: data.expenses.expense_amount,
      };

      setAddItemData(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  const rowMarkup = currentOrders?.map(
    ({
      id,
      expense_type,
      expense_category,
      expense_name,
      expense_date,
      expense_amount,
      index,
    }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          {" "}
          <CustomCssComponentTableText
            innerText={expense_type}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {" "}
          <CustomCssComponentTableText
            innerText={expense_category}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={expense_name}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={expense_date}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={expense_amount}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const updateFunction = (id, event) => {
    event.preventDefault();

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/expenses/update/${id}/${property_id?.id}`,
      {
        method: "POST",

        body: JSON.stringify({ ...addItemData }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          fetchInfo(property_id?.id);
          setShowEditModelMenuActive(false);
          clearSelection();
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleSubmitAddExpenseHandler = (event) => {
    event.preventDefault();
    // http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/assign/rooms/store
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/create/expense/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...addItemData }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          navigate("/hotelier/expenses");
          showSuccessToast(data.message);
          setShowAddStockModelMenuActive(false);
          fetchInfo(property_id?.id);
          // setAddItemData({
          //   room_id: "",
          //   room_name: "",
          //   room_no: "",
          //   floor_no: "",
          //   status: "booked",
          // });
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    clearSelection();

    // "http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/assign/rooms/delete"
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/expenses/delete/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          clearSelection();
          fetchInfo(property_id?.id);
          clearSelection();
          setShowLowerMenuMultipleSelect(false);
          setShowModelMenuActive(false);
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  return (
    <>
      <SidebarInnerLists innerLists={pms_inner_lists} show={true} />
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="room-types-full-container">
            <div className="room-types-full-container-div">
              <div className="room-type-heading">Expenses</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <div>
                  <div style={{ textDecoration: "none" }}>
                    <BgThemeButton
                      onClick={() => {
                        setShowAddStockModelMenuActive(true);
                        clearSelection();
                        setAddItemData({
                          expense_name: "",
                          expense_type: "",
                          expense_category: "",
                          expense_date: "",
                          expense_amount: "",
                        });
                      }}
                      children={"Add Expense"}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Single select */}
            {showLowerMenuSingleSelect && (
              <div className=" room-type-bottom-container">
                <div className="room-type-bottom-container-div">
                  <div>
                    <Button plain destructive onClick={showModelMenu}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Icon source={DeleteMajor} color="base" />
                        </span>
                        <span>Remove</span>
                      </div>
                    </Button>
                  </div>
                  <form
                    onSubmit={(e) => updateFunction(selectedResources[0], e)}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="room-type-bottom-div">
                      <div>Date</div>
                      <div style={{ width: "220px" }}>
                        <VerticalInputFieldCustom
                          name="expense_date"
                          type={"date"}
                          value={addItemData.expense_date}
                          onChange={setAddItemDataHandleChange}
                        />
                      </div>
                    </div>
                    <div className="room-type-bottom-div">
                      <div>Amount</div>
                      <div style={{ width: "250px" }}>
                        <VerticalInputFieldCustom
                          type={"number"}
                          name="expense_amount"
                          value={addItemData.expense_amount}
                          onChange={setAddItemDataHandleChange}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        color: "#3968ED",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <BgOutlineThemeButton
                        type={"button"}
                        onClick={editModalButtonClickHandler}
                        children={"Edit More"}
                      />
                      <BgThemeButton type={"submit"} children={"Update"} />
                    </div>
                  </form>
                </div>
              </div>
            )}

            {/* Multiple select */}
            {showLowerMenuMultipleSelect && (
              <div className=" room-type-bottom-container">
                <div className="room-type-bottom-container-div">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Button plain destructive onClick={showModelMenu}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Icon source={DeleteMajor} color="base" />
                        </span>
                        <span>Remove</span>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {orders && orders.length > 0 ? (
              <>
                <LegacyCard>
                  <IndexTable
                    resourceName={resourceName}
                    itemCount={currentOrders?.length}
                    selectedItemsCount={
                      allResourcesSelected ? "All" : selectedResources.length
                    }
                    onSelectionChange={handleSelectionChange}
                    headings={[
                      { title: "Expense Type" },
                      { title: "Category" },
                      { title: "Name" },
                      { title: "Date" },
                      { title: "Amount" },
                    ]}
                  >
                    {rowMarkup}
                  </IndexTable>
                </LegacyCard>
                <CustomPagination
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalItems={orders.length}
                  onPageChange={handlePageChange}
                  clearSelection={clearSelection}
                />
              </>
            ) : (
              <NoDataFound />
            )}
          </div>

          {/* Add Expenses Button Click */}
          {showAddStockModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => handleSubmitAddExpenseHandler(e)}
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <div className="add-ota-model-container-heading">
                      Add Expenses
                    </div>
                    <div
                      onClick={removeAddModalContainer}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type={"text"}
                        titleName="Expense Type"
                        name="expense_type"
                        value={addItemData.expense_type}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type={"text"}
                        titleName="Expense Category"
                        name="expense_category"
                        value={addItemData.expense_category}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type={"text"}
                        titleName="Expense Name"
                        name="expense_name"
                        value={addItemData.expense_name}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>

                    <div
                      style={{ display: "flex", gap: "15px" }}
                      className="form-container-div"
                    >
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Date"
                        name="expense_date"
                        type={"date"}
                        value={addItemData.expense_date}
                        onChange={setAddItemDataHandleChange}
                      />
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Amount"
                        type={"number"}
                        name="expense_amount"
                        value={addItemData.expense_amount}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton type="submit" children={"Add"} />
                      <Button
                        onClick={() => setShowAddStockModelMenuActive(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}

          {/* Add Item Button Click */}
          {showAddItemModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="stock-add-model-container">
                  <div style={{ marginLeft: "-200px" }}>
                    <Page
                      backAction={{
                        content: "Products",
                        url: `/hotelier/purchase`,
                      }}
                      title="Add Issue Items"
                      compactTitle
                    ></Page>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <TextField
                        type="text"
                        className="textfield"
                        focused={false}
                        label="User Type"
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <TextField
                        type="text"
                        className="textfield"
                        focused={false}
                        label="Issued To"
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <TextField
                        type="date"
                        className="textfield"
                        focused={false}
                        label="Issued Date"
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <Select
                        className="textfield"
                        focused={false}
                        label="Item Name"
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <Select
                        className="textfield"
                        focused={false}
                        label="Select Category"
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <TextField
                        className="textfield"
                        focused={false}
                        label="Qty"
                        autoComplete="off"
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "20px",
                        width: "350px",
                      }}
                      className="form-container-div"
                    >
                      <WithoutBgButtonBlue children={"Add More"} />
                    </div>
                  </div>
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <Select
                        className="textfield"
                        focused={false}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <Select
                        className="textfield"
                        focused={false}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <TextField
                        className="textfield"
                        focused={false}
                        autoComplete="off"
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "350px",
                      }}
                      className="form-container-div"
                    >
                      <WithoutBgButtonBlue children={"Add More"} />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <BgThemeButton children={"Add"} />
                    <Button
                      onClick={() => setShowAddItemModelMenuActive(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </section>
              </div>
            </>
          )}

          {/* Edit Button Click */}
          {showEditModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => updateFunction(selectedResources[0], e)}
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <div className="add-ota-model-container-heading">
                      Edit Expenses
                    </div>
                    <div
                      onClick={() => setShowEditModelMenuActive(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type={"text"}
                        titleName="Expense Type"
                        name="expense_type"
                        value={addItemData.expense_type}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type={"text"}
                        titleName="Expense Category"
                        name="expense_category"
                        value={addItemData.expense_category}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type={"text"}
                        titleName="Expense Name"
                        name="expense_name"
                        value={addItemData.expense_name}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>

                    <div
                      style={{ display: "flex", gap: "15px" }}
                      className="form-container-div"
                    >
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Date"
                        name="expense_date"
                        type={"date"}
                        value={addItemData.expense_date}
                        onChange={setAddItemDataHandleChange}
                      />
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Amount"
                        type={"number"}
                        name="expense_amount"
                        value={addItemData.expense_amount}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton type="submit" children={"Update"} />
                      <Button onClick={() => setShowEditModelMenuActive(false)}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}

          {showModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-type-model-container ">
                  <h4>Are You Sure?</h4>
                  <p>You Want To Remove?</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        setShowModelMenuActive(!showModelMenuActive)
                      }
                    >
                      Discard
                    </Button>
                    <Button onClick={deleteData} destructive>
                      Yes, Remove
                    </Button>
                  </div>
                </section>
              </div>
            </>
          )}
        </>
      )}
    </>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "moneySpent":
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case "taggedWith":
        return `Tagged with ${value}`;
      case "accountStatus":
        return value.map((val) => `Customer ${val}`).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};
export default Expenses;
