import React from "react";
import WebsitesPagesComp from "../../Components/Websites/WebsitesPagesComp";

const WebsitesPages = () => {
  return (
    <>
      {/* <div className="main_container"> */}
        <WebsitesPagesComp />
      {/* </div> */}
    </>
  );
};

export default WebsitesPages;
