import { useState, useEffect } from "react";
import styles from "./CustomCheckbox.module.scss";
import { CheckWithoutBgUnderlineBtn } from "../../UI/Buttons";

const CustomCheckbox = ({
    title,
    customCheckboxData,
    getSelectedCustomCheckboxDataFromChild,
    size
}) => {
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

    useEffect(() => {
        getSelectedCustomCheckboxDataFromChild(selectedCheckboxes);
    }, [selectedCheckboxes, getSelectedCustomCheckboxDataFromChild]);

    const handleCheckboxChange = (value) => {
        if (selectedCheckboxes.includes(value)) {
            setSelectedCheckboxes(
                selectedCheckboxes.filter((item) => item !== value)
            );
        } else {
            setSelectedCheckboxes([...selectedCheckboxes, value]);
        }
    };

    return (
        <div className={styles.custom_checkbox_cont}>
            <div className="flex justify_content_between mb-10">
                <h2 className={`${styles.custom_checkbox_title} ${styles[size]}`}>{title}</h2>
                <CheckWithoutBgUnderlineBtn
                    style={{ fontSize: "11px" }}
                    onClick={() => setSelectedCheckboxes([])}
                >
                    Clear All
                </CheckWithoutBgUnderlineBtn>
            </div>
            {customCheckboxData.map((item, index) => (
                <div key={index}>
                    <label className={`${styles.custom_checkbox_label} ${styles[size]} ${item.disabled ? styles.disabled_checkbox_input : ''}`}>
                        <input
                            type="checkbox"
                            value={item.value}
                            checked={selectedCheckboxes.includes(item.value)}
                            onChange={() => handleCheckboxChange(item.value)}
                            className={`${styles.custom_checkbox_input} ${styles[size]}`}
                        />
                        <span className={`${styles.custom_checkbox_box} ${styles[size]}`}></span>
                        {item.label}
                    </label>
                </div>
            ))}
        </div>
    );
};
export default CustomCheckbox;
