import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import { calculateTotalWithReduce, formatIndianCurrency } from "../../utils/utils";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import { useState } from "react";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { SvgGetInfo } from "../../assets/svgIcons";

const ReportCityLedgerReport = ({
  departureReport,
  loader,
  fullData,
  donShowTitle,
}) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [informationPopup, setInformationPopup] = useState(false);
  const rowMarkup =
    departureReport &&
    departureReport.length > 0 &&
    departureReport?.map((order) => (
      <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
        <CustomReportTableCell>{order.date}</CustomReportTableCell>
        <CustomReportTableCell>
          {order.guest_name ? order.guest_name : "N/A"}
        </CustomReportTableCell>

        <CustomReportTableCell>
          {" "}
          {order.guest_name ? (
            <div>
              #{order.unique_booking_id} Folio-{order.folio_no} ({order.room_no}
              )
            </div>
          ) : (
            "Paid Credit"
          )}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.kot_no ? order.kot_no : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(order.total_credits)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(order.credit_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(order.debit_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.available_credit_limit
            ? formatIndianCurrency(order.available_credit_limit)
            : "0"}
        </CustomReportTableCell>
      </CustomReportTableRow>
    ));

  // const downloadData = departureReport.map((report) => {
  //   return {
  //     date: report.date,
  //     guest_name: report.guest_name ? report.guest_name : "N/A",
  //     reference: report.guest_name
  //       ? `#${report.unique_booking_id} Folio-${report.folio_no} (${report.room_no})`
  //       : "Paid Credit",
  //     credits: report.credit_amount,
  //     debits: report.debit_amount,
  //     available_credit_limit: report.available_credit_limit,
  //   };
  // });

  const names = [
    {
      date: "Date",
      guest_name: "Guest Name",
      reference: "Ref",
      credits: "Credits",
      debits: "Debits",
      available_credit_limit: "Balance",
    },
  ];

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell colSpan={4}>{"Totals"}</CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell> 
   <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "total_credits")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "credit_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "debit_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "available_credit_limit")
        )}
      </CustomReportTableCell>

    </CustomReportTableRow>
  );

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="room-types-full-container" id="cityLedgerReport">
          <div className="room-types-full-container-div">
            <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
              <div className="room-type-heading">City Ledger Report</div>
              <div
                className="hoverCursor"
                onClick={() => setInformationPopup(true)}
                id="ignoreFromPrint"
              >
                <SvgGetInfo />
              </div>
            </div>
            {departureReport?.length > 0 && (
              <div className="flex flex_gap_15" id="ignoreFromPrint">
                <ButtonToDownloadTablePDF
                  itemToDownloadID={"cityLedgerReport"}
                  downloadFileName={"cityLedgerReport"}
                  ignoreFromPrint={"ignoreFromPrint"}
                />
                <DownloadCSVButton
                  itemToDownloadID={"cityLedgerReport"}
                  downloadFileName={"cityLedgerReport"}
                />
              </div>
            )}
          </div>

          <div style={{ display: "flex", gap: "30px", marginBottom: "20px" }}>
            <div>
              <div>
                {" "}
                <strong>Total Credit Limit</strong> :{" "}
                {formatIndianCurrency(fullData?.companyCredit?.credit_amount)}
              </div>
            </div>
            <div>
              <div>
                {" "}
                <strong>Available Credit Limit</strong> :{" "}
                {formatIndianCurrency(fullData?.companyCredit?.balance_amount)}
              </div>
            </div>
            <div>
              <div>
                {" "}
                <strong>Used Credit Limit</strong> :{" "}
                {formatIndianCurrency(fullData?.companyCredit?.used_amount)}
              </div>
            </div>
          </div>
          {departureReport && departureReport.length > 0 ? (
            <>
              {/* <LegacyCard>
                <IndexTable
                  resourceName={resourceName}
                  itemCount={departureReport.length}
                  selectable={false}
                  headings={[
                    { title: "Date" },
                    { title: "Guest Name" },
                    { title: "Reference" },
                    { title: "KOT No" },
                    { title: "Total Credits" },
                    { title: "Credits " },
                    { title: "Debits" },
                    { title: "Balance" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>
              </LegacyCard> */}

              <CustomReportTable
                allItems={departureReport} // table data
                // selectedItems={selectedArray} //selected items from table
                // setSelectedItems={setSelectedArray} // state from parent component to update table
                showCheckbox={false}
                headings={[
                  "Date",
                  "Guest Name",
                  "Reference",
                  "KOT No",
                  "Total Credits",
                  "Credits",
                  "Debits",
                  "Balance",
                ]}
                headerStyles={{
                  backgroundColor: "#dddddd",
                  fontSize: "12px",
                  textAlign: "center",
                }}
                tableStyles={{ textAlign: "left" }}
              >
                {rowMarkup}
                {calculateTotals}
              </CustomReportTable>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
      )}

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of City Ledger Report"
        >
          <div className="mb-20">
            {/* <h2 className="mb-10"> Accordion Component </h2> */}
            <AccordionComponent
              questions={[
                {
                  question: "What is the City Ledger Report?",
                  answer: (
                    <p>
                      The <b>City Ledger Report</b>, also known as the Agents
                      Credit Report, provides a detailed summary of credit
                      transactions related to bookings managed through various
                      agents. It includes essential data such as invoice dates,
                      invoice numbers, opening and closing balances, booking
                      IDs, folio numbers, KOT (Kitchen Order Ticket) numbers,
                      and credit or debit amounts. This report is vital for
                      tracking financial transactions, managing agent credits,
                      and ensuring accurate billing.
                    </p>
                  ),
                },
                {
                  question:
                    "What is the Importance of the City Ledger Report/Agents Credit Report?",
                  answer: (
                    <div>
                      <p>
                        The City Ledger Report is important for several reasons:
                      </p>

                      <ul>
                        <li>
                          <b>Financial Oversight: </b>It allows management to
                          monitor outstanding balances and transactions
                          associated with agents, ensuring financial accuracy.
                        </li>
                        <li>
                          <b>Transaction Tracking: </b>By detailing credit
                          amounts, debits, and available credits, the report
                          helps in managing credit limits effectively, reducing
                          the risk of overdue payments.
                        </li>
                        <li>
                          <b>Credit Management: </b>It provides a comprehensive
                          view of all transactions, aiding in audits and
                          ensuring compliance with financial regulations.
                        </li>
                        <li>
                          <b>Operational Efficiency: </b>Understanding credit
                          usage and transaction types enables better resource
                          allocation and enhances operational processes.
                        </li>
                        <li>
                          <b>Improved Cash Flow Management: </b>The report helps
                          in forecasting cash flow needs by analyzing credit and
                          debit activities, allowing for more effective
                          financial planning.
                        </li>
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportCityLedgerReport;
