import React from "react";
import WebsitesBlogComp from "../../Components/Websites/WebsitesBlogComp";

const WebsitesBlogs = () => {
  return (
    <>
    
        <WebsitesBlogComp />
     
    </>
  );
};

export default WebsitesBlogs;
