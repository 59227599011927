import React, { useState, useEffect } from "react";
import "./AddPaymentsPopup.css";
import VerticalInputSelectCustomCopy from "../VerticalInputSelectCustomCopy";
import { Icon, Page } from "@shopify/polaris";
import { showErrorToast } from "../../../assets/toastUtils";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  WithoutBgButtonBlue,
} from "../../UI/Buttons";
import UnderscoreRemoveCapitalize from "../UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import GoBackButtonCustom from "../../GoBackButtonCustom/GoBackButtonCustom";
import { calculateTotalArrayObjectKey, formatIndianCurrency, getCurrentPropertyDateHandler } from "../../../utils/utils";
import TextDateFormatter from "../../Communication/TextDateFormatter/TextDateFormatter";
import VerticalInputFieldCustom from "../VerticalInputFieldCustom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDecryptedData } from "../../../utils/encryptStorage";

const AddPaymentsPopup = ({
  handleSubmitAddMeals,
  bookingId,
  totalRoomsAmount,
  totalHousekeepingAmount,
  totalFAndBAmount,
  totalMiscAmount,
  setShowCheckOutSettlementDetails,
  totalRoomServiceAmount,
  masterFolioDetails,
  balanceAmount,
  settlementDataFromComponent,
  checkout
}) => {
  const role = JSON.parse(localStorage.getItem("role"));
  const creditAmount = "";
  const advance_payment_amount = "";

  const navigate = useNavigate();

  const property_id = useSelector((state) => state.property.value);
  const userToken = getDecryptedData('encryptToken');

  const [propertyCurrentDate, setPropertyCurrentDate] = useState();
  useEffect(() => {
    if (property_id) {
      getCurrentPropertyDateHandler(property_id?.id, userToken)
        .then((date) => {
          setPropertyCurrentDate(date);
        })
        .catch((error) => console.error(error));
    }
  }, [property_id]);

  // for folio settlements

  const [agentLists, setAgentLists] = useState([]);

  const fetchAgentLists = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAgentLists(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const agentListOptions = [
    {
      label: "Select option",
      value: "",
    },
    ...agentLists?.map((agent) => ({
      label: agent?.company_name,
      value: agent?.agent_information,
    })),
  ];
  const proofOptions = [
    { label: "Select option" },
    { label: "Aadhar Card", value: "aadhar" },
    { label: "PAN Card", value: "pan" },
    { label: "Passport", value: "passport" },
    { label: "Driving License", value: "driving_license" },
    // { label: "Other", value: "other" },
  ];

  const gstTypeOptions = [
    { label: "select Option", value: "" },
    { label: "Inclusive", value: "inclusive" },
    { label: "Exclusive", value: "exclusive" },
  ];

  const gstOptions = [
    { label: "Select Option", value: "" },
    { label: "8%", value: "8" },
    { label: "12%", value: "12" },
    { label: "15%", value: "15" },
  ];
  const paymentTypeOptions = [
    { label: "Select Option", value: "" },
    { label: "Cash / Advance Payment ", value: "cash" },
    { label: "City Ledger", value: "city_ledger" },
    // { label: "Advance Payment", value: "advance_payment" },
  ];

  const currentDate = new Date().toISOString().split("T")[0];

  const [addChargesData, setAddChargesData] = useState({
    date: currentDate,
    booking_id: "",
    folio_id: "",
    property_id: "",
    notes: "",
    notes: "",
    payment_mode: "",
    payment_type: "",
    amount: "",
  });

  const [selectedBookingId, setSelectedBookingId] = useState("");

  useEffect(() => {
    setAddChargesData({
      ...addChargesData,
      amount: "",
    });
  }, [addChargesData.payment_type]);

  const [roomDetails, setRoomDetails] = useState([]);

  const [agentCreditLimit, setAgentCreditLimit] = useState("");
  useEffect(() => {
    const selectedAgent = agentLists?.find(
      (agent) =>
        Number(agent?.agent_information) ===
        Number(addChargesData?.company_name)
    );

    if (addChargesData.payment_type === "city_ledger") {
      
      const creditLimit   = calculateTotalArrayObjectKey(selectedAgent?.credit, "balance_amount");

      setAgentCreditLimit(creditLimit);
    } else {
      const advanceLimit   = calculateTotalArrayObjectKey(selectedAgent?.advance, "balance_amount");
      setAgentCreditLimit(advanceLimit);
    }

    setAddChargesData({
      ...addChargesData,
      amount: null,
    });
  }, [addChargesData.company_name, agentLists]);
  
  useEffect(() => {
    // Convert input values to numbers
    const rate = parseFloat(addChargesData.rate);
    const quantity = parseFloat(addChargesData.quantity);
    const discount_rate = parseFloat(addChargesData.discount_rate);
    const tax_rate = parseFloat(addChargesData.tax_rate);

    let subtotal = rate * quantity;

    const discountAmount = (subtotal * discount_rate) / 100;
    const priceAfterDiscount = subtotal - discountAmount;

    // Apply GST
    let gstAmount = 0;

    if (addChargesData.gst_type === "exclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / 100;
    } else if (addChargesData.gst_type === "inclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / (100 + tax_rate);
    }

    let totalAmount = priceAfterDiscount + gstAmount;

    setAddChargesData({
      ...addChargesData,
      // subtotal: subtotal.toFixed(2),
      // net_total: totalAmount.toFixed(2),
      // discount_amount: discountAmount.toFixed(2),
      // tax_amount: gstAmount.toFixed(2),
    });
  }, [
    addChargesData.rate,
    addChargesData.quantity,
    addChargesData.discount_rate,
    addChargesData.gst_type,
    addChargesData.tax_rate,
    addChargesData.tax_amount,
  ]);

  const paymentModeOptions = [
    { label: "Select Option" },
    { label: "Cash", value: "cash" },
    { label: "Bank Payment", value: "bank_payment" },
    { label: "UPI", value: "upi" },
    { label: "Credit Card", value: "credit_card" },
    { label: "Debit Card", value: "debit_card" },
    { label: "Other", value: "other" },
  ];

  const setAddChargesHandleChange = (event) => {
    if (
      event.target.name === "amount" &&
      addChargesData.payment_type === "city_ledger"
    ) {
      if (
        agentCreditLimit > 0 &&
        parseFloat(event.target.value) > agentCreditLimit
      ) {
        showErrorToast(
          "Amount should not be greater than Agent Balance Credit Limit"
        );
        return;
      }
    }
    if (event.target.name === "company_name") {
      
      setAddChargesData({
        amount: 0,
      });
    }

    setAddChargesData({
      ...addChargesData,
      [event.target.name]: event.target.value,
      amount: "",
    });
  };

  const handleChargesAmountChange = (event) => {
    const value = event.target.value;

    // Check if the payment type is "city_ledger" or "advance"
    if (addChargesData.payment_type === "city_ledger" || addChargesData.payment_type === "advance_payment") {
        // Validate the entered amount against the agent's credit limit
        if (+value > +agentCreditLimit) {
            showErrorToast("Amount should not be greater than the Agent Credit Limit");
            setAddChargesData({
              ...addChargesData,
              amount: '',
          });
            return;
        }
    }

    setAddChargesData({
        ...addChargesData,
        amount: value,
    });
};


  const handlePaymentTypeChange = (event) => {
    setAddChargesData({
      ...addChargesData,
      [event.target.name]: event.target.value,
      company_name: "",
      amount:"",

    });
    setAgentCreditLimit(0);
  }


  useEffect(() => {
    if (property_id) {
      //   fetchDueCheckOut(property_id?.id);
      //   fetchCheckedIn(property_id?.id);
      //   fetchCheckedOut(property_id?.id);
      //   fetchInHouseGuests(property_id?.id);
      fetchAgentLists();
      // fetchFolioDetails(property_id?.id);
    }
  }, [property_id]);

  return (
    <div className="bg-backdrop">
      <form
        onSubmit={(e) => settlementDataFromComponent(addChargesData, e)}
        style={{ width: "600px" }}
        className="stock-add-model-container"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "15px",
          }}
        >
          <div className="add-ota-model-container-heading">Add Payments</div>
          <div
            onClick={() => setShowCheckOutSettlementDetails(false)}
            style={{ cursor: "pointer" }}
          >
            {/* <Icon source={CancelMajor} color="base" /> */}
          </div>
        </div>
        <div className="form-container-div-half">
          <div className="form-container-div">
            <VerticalInputFieldCustom
            min={propertyCurrentDate}
              required={true}
              focused={false}
              name="date"
              type="date"
              titleName="Date"
              onChange={setAddChargesHandleChange}
              autoComplete="off"
              value={addChargesData.date || currentDate}
            />
          </div>
          <div className="form-container-div">
            <VerticalInputSelectCustomCopy
              options={paymentTypeOptions}
              type="text"
              required={true}
              focused={false}
              name="payment_type"
              titleName="Payment Type"
              onChange={handlePaymentTypeChange}
              autoComplete="off"
              value={addChargesData.payment_type}
            />
          </div>
          <div className="form-container-div">
            {addChargesData.payment_type === "cash" && (
              <VerticalInputSelectCustomCopy
                options={paymentModeOptions}
                type="text"
                required={true}
                focused={false}
                name="payment_mode"
                titleName="Payment Mode"
                onChange={setAddChargesHandleChange}
                autoComplete="off"
                value={addChargesData.payment_mode}
              />
            )}
            {(addChargesData.payment_type === "city_ledger" ||
              addChargesData.payment_type === "advance_payment") && (
              <VerticalInputSelectCustomCopy
                options={agentListOptions}
                type="text"
                required={true}
                focused={false}
                name="company_name"
                titleName="Company Name"
                onChange={setAddChargesHandleChange}
                autoComplete="off"
                value={addChargesData.company_name}
              />
            )}
          </div>
        </div>
        <div className="form-container-div-half">
          <div className="form-container-div">
            <VerticalInputFieldCustom
              required={true}
              focused={false}
              name="notes"
              titleName="Notes"
              onChange={setAddChargesHandleChange}
              autoComplete="off"
              value={addChargesData.notes}
            />
          </div>
          <div className="form-container-div">
            {/* {(addChargesData.payment_type === "city_ledger" ||
            addChargesData.payment_type === "advance_payment") ? (
              <VerticalInputFieldCustom
                // type="number"
                required={true}
                focused={false}
                disabled={true}
                name="amount"
                titleName="Amount"
                onChange={setAddChargesHandleChange}
                autoComplete="off"
                value={addChargesData.amount}
              />
            ) : ( */}
              <VerticalInputFieldCustom
                type="number"
                required={true}
                focused={false}
                disabled={false}
                name="amount"
                titleName="Amount"
                onChange={handleChargesAmountChange}
                autoComplete="off"
                value={addChargesData.amount}
              />
            {/* )} */}
          </div>
        </div>
        {(addChargesData.payment_type === "city_ledger" ||
          addChargesData.payment_type === "advance_payment") && (
          <div className="form-container-div-full">
            <>
              Balance Credit Limit:
              <span>
                {agentCreditLimit
                  ? formatIndianCurrency(agentCreditLimit)
                  : formatIndianCurrency(agentCreditLimit)}
              </span>
            </>
          </div>
        )}

        <div className="form-container-div-full">
          <>
            Pending Balance:
            <span>{formatIndianCurrency(balanceAmount)}</span>
          </>
        </div>
        
        {
          checkout ? ( <div className="flex flex_gap_10">
            {Number(balanceAmount) === Number(addChargesData.amount) ? (
              <BgThemeButton children={"Add"} type={"submit"} />
            ) : (
              <BgThemeButtonDisabled children={"Add"} />
            )}
  
            <BgOutlineThemeButton
              children={"Cancel"}
              type={"button"}
              onClick={() => {setShowCheckOutSettlementDetails(false); setAddChargesData({})}}
            />
          </div>)   : ( <div className="flex flex_gap_10">
            {Number(balanceAmount) >= Number(addChargesData.amount) ? (
              <BgThemeButton children={"Add"} type={"submit"} />
            ) : (
              <BgThemeButtonDisabled children={"Add"} />
            )}
  
            <BgOutlineThemeButton
              children={"Cancel"}
              type={"button"}
              onClick={() => {setShowCheckOutSettlementDetails(false); setAddChargesData({})}}
            />
          </div>)

        }

       
      </form>
    </div>
  );
};
export default AddPaymentsPopup;
