import React from "react";
import user from "../assets/images/user.png";
import { Button } from "@shopify/polaris";
import Form from "./Form";
import { BgThemeButton, WithoutBgButtonBlue } from "./UI/Buttons";

const EditProfile = () => {
  return (
    <div className="edit-profile-page">
      <div className="edit-page-heading">
        <h1>Edit Profile</h1>
      </div>
      <div className="edit-page-container">
        <div className="edit-page-container-child">
          <div className="edit-page-container-user">
            <div className="edit-page-user--image">
              <img src={user} alt="user image" width='100%' height='100%'/>
            </div>
            <div className="edit-page-user--name">John Smith</div>
            <div className="edit-page-user--buttons">
              <BgThemeButton children={"Change Profile"} />
              <WithoutBgButtonBlue children={"Remove"} />
            </div>
            <Form />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
