import React, { useState, useRef, useEffect, useCallback } from "react";
import "./CustomTooltip.css";

const CustomTooltip = ({
  children,
  content,
  hoverDelay = 0,
  active: propActive = false,
  onOpen,
  onClose,
}) => {
  const [active, setActive] = useState(propActive);
  const [position, setPosition] = useState("below");
  const tooltipRef = useRef(null);
  const activatorRef = useRef(null);
  const showTimeout = useRef(null);

  useEffect(() => {
    if (propActive !== active) {
      setActive(propActive);
    }
  }, [propActive]);

  const calculatePosition = useCallback(() => {
    if (active) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const activatorRect = activatorRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - activatorRect.bottom;
      const spaceAbove = activatorRect.top;
      const spaceRight = window.innerWidth - activatorRect.right;
      const spaceLeft = activatorRect.left;

      let newPosition = "below";

      if (spaceBelow < tooltipRect.height && spaceAbove > tooltipRect.height) {
        newPosition = "above";
      } else if (spaceRight < tooltipRect.width && spaceLeft > tooltipRect.width) {
        newPosition = "left";
      } else if (spaceLeft < tooltipRect.width && spaceRight > tooltipRect.width) {
        newPosition = "right";
      }

      setPosition(newPosition);
    }
  }, [active]);

  useEffect(() => {
    calculatePosition();
  }, [active, calculatePosition]);

  const showTooltip = () => {
    showTimeout.current = setTimeout(() => {
      setActive(true);
      onOpen && onOpen();
    }, hoverDelay);
  };

  const hideTooltip = () => {
    clearTimeout(showTimeout.current);
    setActive(false);
    onClose && onClose();
  };

  return (
    <div
      className="custom-tooltip-wrapper"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      onScroll={hideTooltip}
      ref={activatorRef}
    >
      {children}
      {active && (
        <div className="tool-tip">
          <div
            className={`custom-tooltip custom-tooltip-${position}`}
            ref={tooltipRef}
          >
            {content}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
