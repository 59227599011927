import React from "react";
import StayView from "../Components/StayView/StayView";
import ReservationViewNew from "../Components/StayView/ReservationViewNew";

const ReservationViewPage = () => {
  return (
    <>
      <div className="main_container">
        <ReservationViewNew />
      </div>
    </>
  );
};

export default ReservationViewPage;
