import React from "react";
import Sidebar from "../Components/Sidebar";
import AddDiscount from "../Components/AddDiscount";

const AddDiscountPage = () => {
  return (
    <>
      <div className="main_container">
        <AddDiscount />
      </div>
    </>
  );
};

export default AddDiscountPage;
