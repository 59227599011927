import React from "react";
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useSelector } from "react-redux";
import { useCurrentTime, currentDateSingle } from "../../../utils/utils";

const ButtonToDownloadTablePDF = ({
  itemToDownloadID,
  downloadFileName,
  pdfStyles,
  ignoreFromPrint,
  pdfOrientation,
  pdfMargins,
  pageFormat,
  longPdf,
}) => {
  const propertyName = useSelector(
    (state) => state.property.value
  ).property_name;
  const dateTime = `${currentDateSingle}_${useCurrentTime()}`;
  const reportPropertyDateTime = `${propertyName}_${dateTime}`;

  // console.log("reportPropertyDateTime", reportPropertyDateTime)

  const handleDownload = () => {
    const originalTable = document.getElementById(itemToDownloadID);
    const pdf = document.getElementById("pdf");
    if (!originalTable) {
      console.error("Table element not found");
      return;
    }

    const clonedTable = originalTable.cloneNode(true);

    // Apply styles from props or fallback to default styles
    clonedTable.style.fontSize = pdfStyles?.fontSize || "12px";
    clonedTable.style.fontFamily = pdfStyles?.fontFamily || "Arial, sans-serif";
    clonedTable.style.lineHeight = pdfStyles?.lineHeight || "1.5";
    clonedTable.style.margin = pdfStyles?.margin || "auto";
    clonedTable.style.borderRadius = "0";
    clonedTable.style.padding = pdfStyles?.padding || "0";
    // pdf.style.display = "none";

    // Remove elements if ignoreFromPrint is provided
    if (ignoreFromPrint) {
      // Check if ignoreFromPrint is an array, if not, make it an array for consistency
      const elementsToIgnore = Array.isArray(ignoreFromPrint)
        ? ignoreFromPrint
        : [ignoreFromPrint];

      elementsToIgnore.forEach((selector) => {
        // Select elements by ID or class and remove them
        const itemsToRemove = clonedTable.querySelectorAll(
          `#${selector}, .${selector}`
        );
        itemsToRemove.forEach((item) => item.remove());
      });
    }

    const tempContainer = document.createElement("div");
    tempContainer.appendChild(clonedTable);

    // Configure and generate PDF
    const opt = {
      margin: pdfMargins || [0.1, 0.1, 0.1, 0.1],
      filename: `${
        downloadFileName ? downloadFileName : "report"
      }_${reportPropertyDateTime}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 1.5, letterRendering: true },
      jsPDF: {
        unit: "in",
        format: pageFormat || "a4",
        orientation: pdfOrientation || "portrait",
      },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };

    html2pdf()
      .from(tempContainer)
      .set(opt)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        const totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(8);
          pdf.text(
            `Page ${i} of ${totalPages}`,
            pdf.internal.pageSize.getWidth() / 2,
            pdf.internal.pageSize.getHeight() - 0.1,
            { align: "center" }
          );
        }
      })
      .save();
  };

  const handleDownloadWithJspdf = () => {
    const originalTable = document.getElementById(itemToDownloadID);
    if (!originalTable) {
      console.error("Table element not found");
      return;
    }

    const clonedTable = originalTable.cloneNode(true);

    // Apply styles from props or fallback to default styles
    clonedTable.style.fontSize = pdfStyles?.fontSize || "12px";
    clonedTable.style.fontFamily = pdfStyles?.fontFamily || "Arial, sans-serif";
    clonedTable.style.lineHeight = pdfStyles?.lineHeight || "1.5";
    clonedTable.style.margin = pdfStyles?.margin || "auto";
    clonedTable.style.borderRadius = "0";
    clonedTable.style.padding = pdfStyles?.padding || "0";

    // Remove element if ignoreFromPrint is provided
    // Remove elements if ignoreFromPrint is provided
    if (ignoreFromPrint) {
      // Check if ignoreFromPrint is an array, if not, make it an array for consistency
      const elementsToIgnore = Array.isArray(ignoreFromPrint)
        ? ignoreFromPrint
        : [ignoreFromPrint];

      elementsToIgnore.forEach((selector) => {
        // Select elements by ID or class and remove them
        const itemsToRemove = clonedTable.querySelectorAll(
          `#${selector}, .${selector}`
        );
        itemsToRemove.forEach((item) => item.remove());
      });
    }

    const tempContainer = document.createElement("div");
    tempContainer.appendChild(clonedTable);

    // Configure and generate PDF with jsPDF and autoTable
    const doc = new jsPDF({
      orientation: pdfOrientation || "portrait",
      unit: "px",
      format: pageFormat || "a4",
    });

    const pdfTable = tempContainer.querySelector("table");

    autoTable(doc, {
      html: pdfTable,
      startY: pdfMargins ? pdfMargins[0] : 0.2,
      margin: {
        top: pdfMargins ? pdfMargins[0] : 10,
        right: pdfMargins ? pdfMargins[1] : 10,
        bottom: pdfMargins ? pdfMargins[2] : 10,
        left: pdfMargins ? pdfMargins[3] : 10,
      },
      headStyles: {
        fillColor: [60, 141, 188], // Header background color
        textColor: [255, 255, 255], // Header text color
        lineWidth: 0.5, // Header border width
        lineColor: [0, 0, 0], // Header border color
      },
      bodyStyles: {
        fillColor: [255, 255, 255], // Cell background color
        textColor: [0, 0, 0], // Cell text color
        lineWidth: 0.5, // Cell border width
        lineColor: [0, 0, 0], // Cell border color
      },
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(8);
      doc.text(
        `Page ${i} of ${pageCount}`,
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.getHeight() - 5,
        { align: "center" }
      );
    }

    doc.save(
      `${
        downloadFileName ? downloadFileName : "report"
      }_${reportPropertyDateTime}.pdf`
    );
  };

  return (
    <div
      onClick={longPdf ? handleDownloadWithJspdf : handleDownload}
      // onClick={longPdf ?  handleDownload   :  handleDownloadWithJspdf}
      className="flex  flex_gap_5 justify_content_center align_items_center pointer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M6.99957 10.1107L3.11068 6.22179L4.19957 5.09401L6.22179 7.11623V0.777344H7.77734V7.11623L9.79957 5.09401L10.8885 6.22179L6.99957 10.1107ZM2.3329 13.2218C1.90512 13.2218 1.53879 13.0693 1.2339 12.7645C0.929012 12.4596 0.776827 12.0935 0.777345 11.6662V9.3329H2.3329V11.6662H11.6662V9.3329H13.2218V11.6662C13.2218 12.094 13.0693 12.4603 12.7645 12.7652C12.4596 13.0701 12.0935 13.2223 11.6662 13.2218H2.3329Z"
          fill="#3968ED"
        />
      </svg>
      <span style={{ color: "#3968ED", fontSize: "14px", fontStyle: "normal" }}>
        PDF
      </span>
    </div>
  );
};

export default ButtonToDownloadTablePDF;

// const handleDownloadCSV = () => {
//     const originalTable = document.getElementById(itemToDownloadID);
//     const rows = originalTable.querySelectorAll('tr');

//     // Initialize CSV content
//     let csvContent = '';

//     // Iterate over each row
//     rows.forEach(row => {
//         const categoryDiv = row.previousElementSibling;
//         if (categoryDiv && categoryDiv.classList.contains('category_name')) {
//             const categoryName = categoryDiv.textContent.trim();
//             // Append category name to CSV content
//             csvContent += `"${categoryName}"\n`;
//         }

//         const cells = row.querySelectorAll('td, th');
//         // Iterate over each cell in the row
//         cells.forEach((cell, index) => {
//             let cellContent = cell.textContent.trim().replace('₹', 'Rs'); // Replace ₹ symbol with "Rs"
//             // Enclose cell content within double quotes if it contains special characters or leading/trailing spaces
//             if (/[,"]/.test(cellContent) || /^\s|\s$/.test(cellContent)) {
//                 cellContent = `"${cellContent}"`;
//             }
//             // Append cell value to CSV content
//             csvContent += `${cellContent}`;
//             // Add comma except for the last cell in the row
//             if (index < cells.length - 1) {
//                 csvContent += ',';
//             }
//         });
//         // Add new line after each row
//         csvContent += '\n';
//     });

//     // Create a Blob with the CSV content
//     const blob = new Blob([csvContent], { type: 'text/csv' });

//     // Create a temporary link element
//     const link = document.createElement('a');
//     link.href = window.URL.createObjectURL(blob);
//     link.download = `${downloadFileName}.csv`;

//     // Append the link to the document body and trigger the download
//     document.body.appendChild(link);
//     link.click();

//     // Remove the link from the document body
//     document.body.removeChild(link);
// };
