import React from "react";

import "./customIndexTable.css";
import Loader from "../../UI/Loader/Loader";
import NoDataFound from "../NoDataFound";

// CustomCheckTable component
const CustomCheckTable = ({
  children,
  headings,
  allItems,
  selectedItems,
  setSelectedItems,
  showCheckbox,
  datePickerOpened,
  loading, // Add loading prop
}) => {
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedItems(allItems?.map((item) => item.id));
    } else {
      setSelectedItems([]);
    }
  };

  const styles = {
    stickyHeader: {
      padding: "6px 10px",
    },
  };

  return (
    <table className="custom_index_table custom_index_table_body">
      <thead
        className={`custom_index_table_head ${
          datePickerOpened ? "communication-custom-position" : ""
        }`}
        style={
          !datePickerOpened
            ? {
                top: "90px",
                backgroundColor: "white",
                zIndex: 2,
                borderBottom: "1px solid #ccc",
              }
            : {}
        }
      >
        <tr className="custom_index_table_head_rows">
          {showCheckbox && (
            <th style={styles.stickyHeader}>
              <input
                type="checkbox"
                checked={
                  allItems?.length > 0 &&
                  allItems?.length === selectedItems?.length
                }
                onChange={handleCheckboxChange}
                className={
                  selectedItems?.length === 0
                    ? "custom-checkbox"
                    : selectedItems?.length > 0 &&
                      selectedItems?.length < allItems?.length
                    ? "custom-checkbox-two"
                    : "custom-checkbox"
                }
              />
            </th>
          )}
          {headings?.map((item, index) => (
            <th
              style={{
                padding: "5px 10px",
                fontWeight: "500",
                color: "#666",
                fontSize: "13px",
              }}
              key={index}
            >
              {item}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="custom_index_table_body">
    {loading ? (
        <tr>
            <td colSpan={headings.length} style={{ textAlign: "center" }}>
                <Loader />
            </td>
        </tr>
    ) : (
        children.length > 0 ? (
            children
        ) : (
            <tr>
                <td colSpan={headings.length} style={{ textAlign: "center" }}>
                    <div className="no-data-found">
                        <NoDataFound />
                    </div>
                </td>
            </tr>
        )
    )}
</tbody>
    </table>
  );
};

export default CustomCheckTable;
