import { useNavigate, useParams } from "react-router-dom";
import BookingStatusUpdate from "../CustomComponents/BookingStatusUpdate";
import "./Folio.css";
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";
import { useEffect, useRef, useState } from "react";
import DashboardFilter from "../Dashboard/DashboardFiltersComponent/DashboardFilter";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  WithoutBgButtonBlue,
} from "../UI/Buttons";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import {
  IndexTable,
  LegacyCard,
  useIndexResourceState,
  Icon,
  Tooltip,
} from "@shopify/polaris";
import { CancelMajor } from "@shopify/polaris-icons";
import { useSelector } from "react-redux";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import HandleNaN from "../CustomComponents/HandleNaN/HandleNaN";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import FolioTopBarandNavigation from "./FolioTopBarandNavigation";
import BottomChargeAmounts from "./BottomChargeAmounts";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import {
  checkFolioSummery,
  formatIndianCurrency,
  getCurrentPropertyDateHandler,
  useCurrentTime,
} from "../../utils/utils";
import RoundOffNumber from "../CustomComponents/RoundOffNumber/RoundOffNumber";
import DeletePopupWithReason from "../CustomComponents/DeletePopup/DeletePopupWithReason";
import SidebarChargeAmounts from "./SidebarChargeAmounts";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import { getDecryptedData } from "../../utils/encryptStorage";

const FolioCharges = () => {
  const navigate = useNavigate();
  const clickOutSideRef = useRef(null);
  const [editBillTo, setEditBillTo] = useState(false);
  const [showFolioFeature, setShowFolioFeature] = useState(false);
  const [createNewFolio, setCreateNewFolio] = useState(false);
  const [addGuestPopup, setAddGuestPopup] = useState(false);
  const [addChargesPopup, setAddChargesPopup] = useState(false);

  const [chargesList, setChargesList] = useState([]);
  const [settlements, setSettlements] = useState([]);
  const [folioSummery, setFolioSummery] = useState([]);
  const [folioData, setFolioData] = useState([]);
  const [folioBookings, setFolioBookings] = useState([]);
  const [allFolioArray, setAllFolioArray] = useState([]);

  const [guest, setGuest] = useState([]);
  const { folioId, folioUniqueId } = useParams();

  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(true);
  const userToken = getDecryptedData('encryptToken');

  const [roundedValue, setRoundedValue] = useState(0);
  const [roundOffValue, setRoundOffValue] = useState(null);

  const folioFilterOptions = [
    {
      value: "today",
      label: "Today",
    },
    {
      value: "mtd",
      label: "MTD",
    },
    {
      value: "qtd",
      label: "QTD",
    },
    {
      value: "ytd",
      label: "YTD",
    },
    {
      value: "future 1 month",
      label: "Future 1 Month",
    },
    {
      value: "future 3 months",
      label: "Future 3 Months",
    },
  ];

  const [roomDetails, setRoomDetails] = useState([]);

  const proofOptions = [
    { label: "Select option" },
    { label: "Aadhar Card", value: "aadhar" },
    { label: "PAN Card", value: "pan" },
    { label: "Passport", value: "passport" },
    { label: "Driving License", value: "driving_license" },
    // { label: "Other", value: "other" },
  ];

  const gstTypeOptions = [
    { label: "select Option", value: "" },
    { label: "Inclusive", value: "inclusive" },
    { label: "Exclusive", value: "exclusive" },
  ];

  const gstOptions = [
    { label: "Select Option", value: "" },
    { label: "5%", value: "5" },
    { label: "12%", value: "12" },
    { label: "18%", value: "18" },
  ];

  const [addChargesData, setAddChargesData] = useState({
    charge_type: "",
    description: "",
    rate: "",
    quantity: "",
    net_total: "",
    subtotal: "",
    discount_amount: "",
    gst_type: "inclusive",
    discount_rate: "",
    tax_rate: "",
    tax_amount: "",
  });

  const [propertyCurrentDate, setPropertyCurrentDate] = useState();

  useEffect(() => {
    if (property_id) {
      getCurrentPropertyDateHandler(property_id?.id, userToken)
        .then((date) => {
          setPropertyCurrentDate(date);
        })
        .catch((error) => console.error(error));
    }
  }, [property_id]);

  const [selectedDateFilterValue, setSelectedDateFilterValue] = useState("MTD");
  const [dayTypeFilter, setDayTypeFilter] = useState("");

  const handleSelect = (label, value) => {
    setSelectedDateFilterValue(label);
    setDayTypeFilter(value);
  };

  useEffect(() => {
    const rate = parseFloat(addChargesData.rate);
    const quantity = parseFloat(addChargesData.quantity);
    const discount_rate = parseFloat(addChargesData.discount_rate);
    const tax_rate = parseFloat(addChargesData.tax_rate);

    let subtotal = rate * quantity;

    const discountAmount = (subtotal * discount_rate) / 100;
    const priceAfterDiscount = subtotal - discountAmount;

    let gstAmount = 0;

    if (addChargesData.gst_type === "exclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / 100;

      let totalAmount = priceAfterDiscount + gstAmount;

      setAddChargesData({
        ...addChargesData,
        subtotal: subtotal.toFixed(2),
        net_total: totalAmount.toFixed(2),
        discount_amount: discountAmount.toFixed(2),
        tax_amount: gstAmount.toFixed(2),
      });
    } else if (addChargesData.gst_type === "inclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / (100 + tax_rate);

      // GST Inclusive Price * 100/(100 + GST Rate Percentage)
      subtotal = (priceAfterDiscount * 100) / (100 + tax_rate);

      let totalAmount = priceAfterDiscount;

      setAddChargesData({
        ...addChargesData,
        subtotal: subtotal.toFixed(2),
        net_total: totalAmount.toFixed(2),
        discount_amount: discountAmount.toFixed(2),
        tax_amount: gstAmount.toFixed(2),
      });
    }
  }, [
    addChargesData.rate,
    addChargesData.quantity,
    addChargesData.discount_rate,
    addChargesData.gst_type,
    addChargesData.tax_rate,
    addChargesData.tax_amount,
  ]);

  // const setAddChargesHandleChange = (event) => {
  //   setAddChargesData({
  //     ...addChargesData,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  const setAddChargesHandleChange = (event) => {
    const { name, value } = event.target;
    // Update the addChargesData and also the rounded value here if necessary
    setAddChargesData((prevData) => ({
      ...prevData,
      [name]: value,
      // Update net_total based on the roundedValue if available
      net_total: roundedValue ? roundedValue : prevData.net_total,
    }));
  };

  const fetchFolioInfo = async (propertyId, selectedFolioId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/v1/folios/${folioId}/${propertyId}?folio_id=${
          selectedFolioId ? selectedFolioId : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setFolioData(data?.data);
      setChargesList(data?.data?.charges);
      setSettlements(data?.data?.payments);
      setFolioSummery(data?.data?.folio_summary);
      setGuest(data?.data?.guest);
      setRoomDetails(data?.data?.roomDetails);
      setFolioBookings(data?.data?.booking);
      setLoader(false);

      setAllFolioArray(data?.allFolios);
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (property_id && folioUniqueId) {
      fetchFolioInfo(property_id?.id, folioUniqueId);
    }
  }, [property_id, folioUniqueId]);

  const resourceName = {
    singular: "chargesList",
    plural: "chargesLists",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(chargesList);

  const chargesRowMarkup = chargesList?.map(
    ({
      id,
      date,
      charge_type,
      description,
      rate,
      quantity,
      sub_total,
      discount_amount,
      tax_rate,
      net_total,
      index,
      reason,
      is_cancelled,
    }) => {
      return (
        <IndexTable.Row
          id={id}
          key={id}
          selected={selectedResources.includes(id)}
          position={id}
        >
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={date}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <UnderscoreRemoveCapitalize text={charge_type} />
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={description}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={<HandleNaN currency={"₹"} value={Math.trunc(rate)} />}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={quantity}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={
                <HandleNaN currency={"₹"} value={Math.trunc(sub_total)} />
              }
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={
                <HandleNaN currency={"₹"} value={Math.trunc(discount_amount)} />
              }
            ></CustomCssComponentTableText>
          </IndexTable.Cell>

          <IndexTable.Cell>{tax_rate}%</IndexTable.Cell>

          <IndexTable.Cell>
            {formatIndianCurrency(net_total)}
            {/* <CustomCssComponentTableText
              innerText={
                <HandleNaN currency={"₹"} value={Math.trunc(net_total)} />
              }
            ></CustomCssComponentTableText> */}
          </IndexTable.Cell>
          <IndexTable.Cell>
            {is_cancelled === 1 ? (
              <Tooltip content={reason} dismissOnMouseOut>
                <BookingStatusUpdate status={"cancelled"} />
              </Tooltip>
            ) : (
              <div
                onClick={() => [
                  setDeleteWithReasonPopup(true),
                  setDeleteKotId(id),
                ]}
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  display: "inline-flex",
                }}
              >
                &times;
              </div>
            )}
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );

  const settlementsRowMarkup = chargesList?.map(
    ({
      id,
      date,
      charge_type,
      description,
      rate,
      quantity,
      sub_total,
      discount_amount,
      tax_rate,
      net_total,
      index,
    }) => {
      return (
        <IndexTable.Row
          id={id}
          key={id}
          selected={selectedResources.includes(id)}
          position={id}
        >
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={date}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={charge_type}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={description}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={<HandleNaN currency={"₹"} value={Math.trunc(rate)} />}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={quantity}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={
                <HandleNaN currency={"₹"} value={Math.trunc(sub_total)} />
              }
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={
                <HandleNaN currency={"₹"} value={Math.trunc(discount_amount)} />
              }
            ></CustomCssComponentTableText>
          </IndexTable.Cell>

          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={<HandleNaN value={Math.trunc(tax_rate)} />}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>

          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={
                <HandleNaN currency={"₹"} value={Math.trunc(net_total)} />
              }
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );

  const serviceOptions = [
    { label: "Select Option", value: "" },
    { label: "Room-Charge", value: "Room-Charge" },
    { label: "Food-Charge", value: "Food-Charge" },
    { label: "Housekeeping-Charge ", value: "Housekeeping-Charge" },
    { label: "Miscellaneous-Charge", value: "Miscellaneous-Charge" },
    { label: "Laundry-Charge", value: "Laundry-Charge" },
    // { label: "MISC", value: "misc" },
  ];

  const currentTimeToBackend = useCurrentTime("24");

  const handleSubmitCreateFolio = (event) => {
    event.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/add/folios/charges/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addChargesData,
          folio_id: folioData.id,
          net_total: roundedValue ? roundedValue : addChargesData.net_total,
          round_off: roundOffValue,
          property_current_date:
            propertyCurrentDate + " " + currentTimeToBackend,
          date: propertyCurrentDate,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setAddChargesPopup(false);
          fetchFolioInfo(property_id?.id);
          setAddChargesData({
            charge_type: "",
            description: "",
            rate: "",
            quantity: "",
            net_total: "",
            subtotal: "",
            discount_amount: "",
            gst_type: "inclusive",
            discount_rate: "",
            tax_rate: "",
            tax_amount: "",
          });

          // checkFolioSummery(
          //   userToken,
          //   folioData?.id,
          //   property_id?.id,
          //   chargeAddedTransactions,
          //   true
          // );
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleClickOutside = (event) => {
    if (
      clickOutSideRef.current &&
      !clickOutSideRef.current.contains(event.target)
    ) {
      setShowFolioFeature(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [chargeAddedTransactions, setChargeAddedTransactions] = useState({
    discountAmountSum: "",
    subTotalSum: "",
    taxAmountSum: "",
    grandTotalSum: "",
    paidAmountSum: "",
    dueAmountSum: "",
  });

  useEffect(() => {
    const discountAmountSum = chargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.discount_amount || 0),
      0
    );
    const subTotalSum = chargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.sub_total),
      0
    );
    const taxAmountSum = chargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.tax_amount),
      0
    );
    const grandTotalSum = chargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.net_total),
      0
    );
    const paidAmountSum = folioData?.payments?.reduce(
      (sum, transaction) => sum + parseFloat(transaction.amount || 0),
      0
    );
    const dueAmountSum = chargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.due_amount || 0),
      0
    );

    const transactions = {
      discountAmountSum,
      subTotalSum,
      taxAmountSum,
      grandTotalSum,
      paidAmountSum,
      dueAmountSum,
    };

    setChargeAddedTransactions(transactions);
  }, [chargesList]);

  const handleRoundOffChange = (rounded, roundOffValue) => {
    setRoundedValue(rounded);
    setRoundOffValue(roundOffValue);
  };

  const roomNumberArray = [
    {
      label: "Select option",
      value: null,
    },
    ...roomDetails?.map((room) => ({
      label: room?.roomNo?.toString(),
      value: room?.roomNo?.toString(),
    })),
  ];
  const guestsArray = [
    {
      label: "Select option",
      value: null,
    },
    ...roomDetails?.flatMap((room) =>
      room?.guest_details?.map((guest) => ({
        label: guest?.guest_name,
        value: guest?.id?.toString(),
      }))
    ),
  ];

  const dataFromChild = (id) => {
    fetchFolioInfo(property_id?.id, id);
    navigate(`/bookings/charges/${folioId}/${id}`);
    // const currentUrl = window.location.href;
    // console.log(currentUrl);
    // const parts = currentUrl.split('/');
    // parts[parts.length - 1] = folioId; // Replace the last part with '38'
    // const newUrl = parts.join('/');
    // console.log(newUrl);
    // // window.location.replace(newUrl);
  };

  const isDecimal = addChargesData?.net_total % 1 !== 0;

  const [deleteWithReasonPopup, setDeleteWithReasonPopup] = useState(false);
  const [reasonFromChild, setReasonFromChild] = useState("");
  const [deleteKotId, setDeleteKotId] = useState("");
  const handleDeleteWithReason = (reason) => {
    setReasonFromChild(reason);
  };

  const handleCancelStatusButton = async (id) => {
    try {
      const response = await fetch(
        // https://dev.revcatalyst.in/api/v1/folios/charge/delete/{charge_id}/{property_id}
        `${process.env.REACT_APP_BASE_URL}/api/v1/folios/charge/delete/${deleteKotId}/${property_id.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            reason: reasonFromChild,
            folio_id: folioData?.id,
          }),
        }
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        fetchFolioInfo(property_id?.id, folioData?.id);
        setReasonFromChild("");
        setDeleteWithReasonPopup(false);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  return (
    <>
      {/* <FolioTopBarandNavigation showFolioSelect={true} /> */}
      <FolioTopBarandNavigation
        guestsArray={guestsArray}
        roomNumberArray={roomNumberArray}
        // handleSelect={handleSelect}
        // fetchFolioInfoParent = {fetchFolioInfo(property_id?.id)}
        dataFromChild={dataFromChild}
      />

      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify_content_end" style={{ margin: "20px" }}>
            {folioData?.booking?.status !== "checked_out" && (
              <WithoutBgButtonBlue
                style={{ fontSize: "14px", padding: "0" }}
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_5311_4678)">
                      <path
                        d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                        stroke="#3968ED"
                      />
                      <path
                        d="M10.5868 7H3.41357"
                        stroke="#3968ED"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M7 3.4132L7 10.5864"
                        stroke="#3968ED"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5311_4678">
                        <rect width="14" height="14" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                }
                children={"Add"}
                onClick={() => setAddChargesPopup(!addChargesPopup)}
              />
            )}
          </div>

          <div
            className=" mt-20"
            style={{
              marginRight: "20px",
              marginLeft: "20px",
            }}
          >
            {chargesList && chargesList.length > 0 ? (
              <>
                <div style={{ display: "flex", gap: "10px" }}>
                  <div
                    style={{
                      // height: "300px",
                      overflow: "auto",
                      width: "80%",
                    }}
                  >
                    <LegacyCard>
                      <IndexTable
                        selectable={false}
                        resourceName={resourceName}
                        itemCount={chargesList.length}
                        selectedItemsCount={
                          allResourcesSelected
                            ? "All"
                            : selectedResources.length
                        }
                        onSelectionChange={handleSelectionChange}
                        headings={[
                          { title: "Date" },
                          { title: "Service" },
                          { title: "Description" },
                          { title: "Rate" },
                          { title: "Qty" },
                          { title: "Amount" },
                          { title: "Disc. Amt." },
                          { title: "GST%" },
                          { title: "Total Amt." },
                          { title: "" },
                        ]}
                      >
                        {chargesRowMarkup}
                      </IndexTable>
                    </LegacyCard>
                  </div>

                  <div
                    style={{ width: "20%", position: "sticky", top: "100px" }}
                  >
                    <SidebarChargeAmounts
                      chargesList={
                        chargesList.length > 0
                          ? chargesList.filter(
                              (charge) => charge?.is_cancelled === 0
                            )
                          : []
                      }
                      folioData={folioData}
                      style={{ position: "sticky", top: "90px" }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <NoDataFoundSmall />
            )}
          </div>

          {/* <BottomChargeAmounts
            chargesList={chargesList}
            folioData={folioData}
          /> */}

          {addChargesPopup && (
            <div className="bg-backdrop">
              <form
                onSubmit={handleSubmitCreateFolio}
                style={{ width: "1000px" }}
                className="stock-add-model-container"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <div className="add-ota-model-container-heading">
                    Add Charges
                  </div>
                  <div
                    onClick={() => [
                      setAddChargesPopup(!addChargesPopup),
                      setAddChargesData({}),
                      setRoundOffValue(0),
                      setRoundedValue(0),
                    ]}
                    style={{ cursor: "pointer" }}
                  >
                    <Icon source={CancelMajor} color="base" />
                  </div>
                </div>
                <div className="form-container-div-full flex flex_gap_10">
                  <div className="form-container-div" style={{ width: "20%" }}>
                    <VerticalInputSelectCustomCopy
                      options={serviceOptions}
                      titleName="Service"
                      name="charge_type"
                      value={addChargesData.charge_type}
                      onChange={setAddChargesHandleChange}
                    />
                  </div>
                  <div className="form-container-div" style={{ width: "20%" }}>
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="Description"
                      name="description"
                      type="text"
                      value={addChargesData.description}
                      onChange={setAddChargesHandleChange}
                    />
                  </div>
                  <div className="form-container-div" style={{ width: "20%" }}>
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="Rate"
                      name="rate"
                      type="number"
                      value={addChargesData.rate}
                      onChange={setAddChargesHandleChange}
                    />
                  </div>
                  <div className="form-container-div" style={{ width: "20%" }}>
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="Qty"
                      name="quantity"
                      type="number"
                      value={addChargesData.quantity}
                      onChange={setAddChargesHandleChange}
                    />
                  </div>
                </div>

                <div className="form-container-div-full flex flex_gap_10">
                  <div className="form-container-div" style={{ width: "20%" }}>
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="Discount%"
                      name="discount_rate"
                      type="number"
                      value={addChargesData.discount_rate}
                      onChange={setAddChargesHandleChange}
                    />
                  </div>

                  <div className="form-container-div" style={{ width: "20%" }}>
                    <VerticalInputSelectCustomCopy
                      options={gstTypeOptions}
                      required={true}
                      titleName="GST Type"
                      name="gst_type"
                      value={addChargesData.gst_type}
                      onChange={setAddChargesHandleChange}
                    />
                  </div>
                  <div className="form-container-div" style={{ width: "20%" }}>
                    <VerticalInputSelectCustomCopy
                      required={true}
                      options={gstOptions}
                      titleName="GST%"
                      name="tax_rate"
                      value={addChargesData.tax_rate}
                      onChange={setAddChargesHandleChange}
                    />
                  </div>
                  <div className="form-container-div" style={{ width: "20%" }}>
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="Subtotal"
                      name="sub_total"
                      disabled={true}
                      type="number"
                      value={addChargesData.subtotal}
                      onChange={setAddChargesHandleChange}
                    />
                  </div>
                </div>

                <div>
                  Subtotal :{" "}
                  {addChargesData.gst_type === "inclusive" ? (
                    // <HandleNaN
                    //   value={addChargesData.subtotal - addChargesData.tax_amount}
                    // />
                    <HandleNaN value={addChargesData.subtotal} />
                  ) : (
                    <HandleNaN value={addChargesData.subtotal} />
                  )}
                </div>

                <div>
                  Tax Amount : <HandleNaN value={addChargesData.tax_amount} />
                </div>
                <div className="form-container-div-full flex flex_gap_10">
                  <div className="form-container-div" style={{ width: "20%" }}>
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="Total Amount"
                      name="net_total"
                      type="number"
                      disabled={true}
                      // value={
                      //   addChargesData.gst_type === "inclusive"
                      //     ? // ? addChargesData.net_total - addChargesData.tax_amount
                      //     addChargesData.net_total
                      //     : addChargesData.net_total
                      // }

                      value={
                        roundedValue ? roundedValue : addChargesData.net_total
                      }
                      onChange={setAddChargesHandleChange}
                    />
                  </div>

                  {!isNaN(addChargesData.net_total) && isDecimal && (
                    <div style={{ width: "100%" }}>
                      <div className="flex flex_gap_10">
                        <div>Round Off</div>
                      </div>
                      <div style={{ height: "36px" }}>
                        <RoundOffNumber
                          totalAmount={addChargesData?.net_total}
                          // taxRate={taxrate}
                          onRoundOffChange={handleRoundOffChange}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex flex_gap_10">
                  <BgThemeButton children={"Add"} type={"submit"} />
                  <BgOutlineThemeButton
                    children={"Cancel"}
                    type={"button"}
                    onClick={() => setAddChargesPopup(!addChargesPopup)}
                  />
                </div>
              </form>
            </div>
          )}

          {deleteWithReasonPopup && (
            <DeletePopupWithReason
              title={"Charge"}
              textAreaName={"reason_of_cancelation"}
              onDeleteWithReason={handleDeleteWithReason}
              wordLimit={50}
              deleteWithReasonPopup={deleteWithReasonPopup}
              setDeleteWithReasonPopup={setDeleteWithReasonPopup}
              deleteFunction={() => handleCancelStatusButton(deleteKotId)}
              id={deleteKotId}
            />
          )}
        </>
      )}
    </>
  );
};
export default FolioCharges;
