import React from "react";
import AddPageComp from "../../Components/Websites/AddPageComp";
// import WebsitesComp from "../../Components/Websites/WebsitesComp";

const AddPage = () => {
  return (
    <>
      <div className="main_container">
        <AddPageComp />
      </div>
    </>
  );
};

export default AddPage;
