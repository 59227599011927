import React from 'react'
import HoldRoomCreateAgent from '../Components/CustomComponents/HoldRoomCreateAgent'

const HoldRoomsCreateAgentPage = () => {
  return (
    <div className="main_container">
    
    <HoldRoomCreateAgent/>


  </div>
  )
}

export default HoldRoomsCreateAgentPage