import React from "react";

const UnderscoreRemoveCapitalize = ({ text, textColor, fontWeight, fontSize , width}) => {
  return (
    <>
      {
        <div style={{ textTransform: "capitalize", color: textColor, fontWeight: fontWeight, fontSize: fontSize, width: width }}>
          {text && text?.replace(/_/g, " ")}
        </div>
      }
    </>
  );
};

export default UnderscoreRemoveCapitalize;
