
import React from 'react';
import '../CreateBookingSelectBox/CreateBookingSelectBox.css';
const CreateBookingSelectBoxOptionDisabled = ({ label, value, onChange, options ,disabled ,error,bookingDataArray}) => {
    return (
        <div className='create_booking_selectBox_container'>
            <label className="Create_booking_label">{label}</label>
            <select value={value} onChange={onChange}  disabled={disabled}  
             style={{
                border: error ? "1px solid #E03838" : "1px solid #ddd",
                backgroundColor: error ? "#FFEAEA" : "",
              }}
            className={error ? "create_booking_error-border Create_booking_selectBox" : "Create_booking_selectBox"}>
                <option value="" id=" " >Select</option>
                {options?.length > 0 && options.map((option, index) => (
                    // Check if the option's room_name matches any room_category in bookingDataArray
                    <option key={index} value={option.room_name} 
                    disabled={bookingDataArray?.some(booking => booking?.room_category === option?.room_name)}
                    >
                        {option.room_name}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default CreateBookingSelectBoxOptionDisabled;