import {
  Button,
  Icon,
  IndexTable,
  LegacyCard,
  Page,
  Select,
  TextField,
  useIndexResourceState,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { DeleteMajor } from "@shopify/polaris-icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import Loader from "./Loader/Loader";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { BgOutlineThemeButton, BgThemeButton } from "./Buttons";
import NoDataFound from "../CustomComponents/NoDataFound";
import NodataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import { getDecryptedData } from "../../utils/encryptStorage";

const AddBanquetMenuCategories = ({ setShowMenuCategory }) => {
  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  const [categoriesData, setCategoriesData] = useState({
    category_name: "",
  });
  const resourceName = {
    singular: "category",
    plural: "categories",
  };
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  const [
    showAddMenuCategoriesLowerMenuActive,
    setShowAddMenuCategoriesLowerMenuActive,
  ] = useState(false);

  const handleAddCategoryPoopup = () => {
    clearSelection();
    setShowAddMenuCategoriesLowerMenuActive(
      !showAddMenuCategoriesLowerMenuActive
    );
  };
  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(categories);

  const addCategoriesRowMarkup = categories?.map(
    ({ id, category_name, index }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>{category_name}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
      setShowAddMenuCategoriesLowerMenuActive(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const setAddItemDataHandleChange = (event) => {
    setCategoriesData({
      ...categoriesData,
      [event.target.name]: event.target.value,
    });
  };

  const fetchInfo = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/banquet/menus/categories/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setCategories(data.menue_categories);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/edit/banquet/menus/categories/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        category_name: data.menu.category_name,
      };
      setCategoriesData(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

   useEffect(() => {
    if (property_id  && userToken)  {
      fetchInfo(property_id?.id);
    }
  }, [property_id, userToken]);

  const updateFunction = async (id) => {
    const findDepartment = categories.find((category) => category.id === id);
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/update/banquet/menus/categories/${id}/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify(categoriesData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        fetchInfo(property_id?.id);
        setShowLowerMenuSingleSelect(false);
        clearSelection();
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const handleSubmitAddCategory = async (event) => {
    if (categoriesData.category_name === "") {
      showErrorToast("Please enter category name...!!!");
      return;
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/create/banquet/menus/categories/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({
            ...categoriesData,
            property_id: property_id?.id,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        setShowAddMenuCategoriesLowerMenuActive(false);
        clearSelection();
        showSuccessToast(data.message);
        setCategoriesData({
          category_name: "",
        });
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    clearSelection();
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/delete/banquet/menus/categories/${property_id?.id}`,
        {
          method: "DELETE",
          body: JSON.stringify(deletedIds),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        showSuccessToast(data.message);
        clearSelection();
        setShowLowerMenuMultipleSelect(false);
        setShowModelMenuActive(false);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  return (
    <>
      <div
        // onClick={() => [
        //     setShowMenuCategory(false),
        // ]}
        className="bg-backdrop"
      >
        <section className="agent-add-categories-model-container">
          <LegacyCard>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingRight: "10px",
              }}
            >
              <div>
                <Page
                  backAction={{
                    content: "Banquet Menu Categories",
                    url: `/banquetmenus`,
                  }}
                  title="Banquet Menu Categories"
                  compactTitle
                ></Page>
              </div>
              <div>
                <BgThemeButton
                  onClick={() => handleAddCategoryPoopup()}
                  children={"Add Categories"}
                />
              </div>
            </div>
            <div
              style={{
                padding: "10px",
                // maxHeight: "300px",
                // overflowX: 'hidden',
                // overflowY: 'scroll'
              }}
            >
              {categories && categories.length > 0 ? (
                <IndexTable
                  resourceName={resourceName}
                  itemCount={categories.length}
                  selectedItemsCount={
                    allResourcesSelected ? "All" : selectedResources.length
                  }
                  onSelectionChange={handleSelectionChange}
                  headings={[{ title: "Categories" }]}
                >
                  {addCategoriesRowMarkup}
                </IndexTable>
              ) : (
                <NodataFoundSmall />
              )}
            </div>

            {/* Single select */}
            {showLowerMenuSingleSelect && (
              <div className=" room-type-bottom-container">
                <div className="room-type-bottom-container-div">
                  <div>
                    <Button plain destructive onClick={showModelMenu}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Icon source={DeleteMajor} color="base" />
                        </span>
                        <span>Remove</span>
                      </div>
                    </Button>
                  </div>
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="room-type-bottom-div">
                      <div>Category Name</div>
                      <div style={{ width: "200px" }}>
                        <VerticalInputFieldCustom
                          // required={true}
                          name="category_name"
                          className="textfield"
                          onChange={(e) => setAddItemDataHandleChange(e)}
                          focused={false}
                          value={categoriesData.category_name}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <BgThemeButton
                      onClick={() => updateFunction(selectedResources[0])}
                      type="submit"
                      children={"Update"}
                    />
                  </form>
                </div>
              </div>
            )}

            {/* Multiple select */}
            {showLowerMenuMultipleSelect && (
              <div className="room-type-bottom-container">
                <div className="room-type-bottom-container-div">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Button plain destructive onClick={showModelMenu}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Icon source={DeleteMajor} color="base" />
                        </span>
                        <span>Remove</span>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {/* Add Button Click */}
            {showAddMenuCategoriesLowerMenuActive && (
              <div className=" room-type-bottom-container">
                <div className="room-type-bottom-container-div">
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div className="room-type-bottom-div">
                      <div style={{ marginLeft: "10px" }}>Category Name</div>
                      <div style={{ width: "150px" }}>
                        <VerticalInputFieldCustom
                          name="category_name"
                          className="textfield"
                          onChange={(e) => setAddItemDataHandleChange(e)}
                          focused={false}
                          value={categoriesData.category_name}
                          autoComplete="off"
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        color: "#3968ED",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        children={"Add"}
                        type="submit"
                        onClick={(e) => handleSubmitAddCategory(e)}
                      />
                      <BgOutlineThemeButton
                        onClick={() =>
                          setShowAddMenuCategoriesLowerMenuActive(false)
                        }
                        children={"Cancel"}
                      />
                    </div>
                  </form>
                </div>
              </div>
            )}
          </LegacyCard>
        </section>
      </div>
      {showModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="room-type-model-container ">
              <h4>Are You Sure?</h4>
              <p>You Want To Remove?</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button onClick={showModelMenu}>Discard</Button>
                <Button onClick={() => deleteData()} destructive>
                  Yes, Remove
                </Button>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
};

export default AddBanquetMenuCategories;
