import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the CSS
import styles from "./reactQuillTextEditor.module.scss";

const ReactQuillTextEditor = ({
  editorHtml,
  name,
  handleTextChange,
  label,
  required,
  labelSize,
}) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ align: [] }],
      ["code-block"],
      ["link", "image"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "indent",
    "align",
    "code-block",
    "link",
    "image",
  ];

  const onChange = (value) => {
    if (name) {
      handleTextChange(name, value);
    } else {
      handleTextChange(value);
    }
  };

  return (
    <div>
      <div
        className={`${styles.label} ${
          labelSize === "small"
            ? styles.small
            : labelSize === "medium"
            ? styles.medium
            : styles.large
        } `}
      >
        {label}
        {required === true ? <span style={{ color: "red" }}> *</span> : ""}
      </div>
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        value={editorHtml}
        onChange={onChange}
      />
    </div>
  );
};

export default ReactQuillTextEditor;
