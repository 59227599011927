import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "./NoDataFound";
import CustomCssComponentTableText from "./CustomCssComponentTableText";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import DownloadPdfTables from "./DownloadPdfTables/DownloadPdfTables";
import DownloadCsvTables from "./DownloadCsvTables/DownloadCsvTables";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "./CustomReportComponent/CustomReportComponent";
import ButtonToDownloadTablePDF from "./DownloadPdfTables/ButtonToDownloadTablePDF";
import DownloadCSVButton from "./DownloadCsvTables/DownloadCSVButton";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { useState } from "react";

const RegionReportComp = ({ regionReportsData }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [informationPopup, setInformationPopup] = useState(false);

  const names = [
    {
      guest_state: "Guest Name",
      booking_count: "Booking Count",
      total_sale: "Total Sale",
    },
  ];
  const rowMarkup =
    regionReportsData &&
    regionReportsData?.map(
      ({ booking_count, guest_state, total_sale, state }, index) => {
        return (
          <CustomReportTableRow id={index} key={index} position={guest_state}>
            <CustomReportTableCell>{guest_state}</CustomReportTableCell>
            <CustomReportTableCell>{booking_count}</CustomReportTableCell>

            <CustomReportTableCell>
              {total_sale ? formatIndianCurrency(total_sale) : "0"}
            </CustomReportTableCell>
          </CustomReportTableRow>
        );
      }
    );

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>{"Totals"}</CustomReportTableCell>

      <CustomReportTableCell>
        {calculateTotalWithReduce(regionReportsData, "booking_count")}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(regionReportsData, "total_sale")
        )}
      </CustomReportTableCell>
    </CustomReportTableRow>
  );

  return (
    <>
      <div
        id="receipt"
        className="room-types-full-container reports-new-background"
      >
        <div className="room-types-full-container-div">
          <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
            <div className="room-type-heading">Reservation By Region</div>

            <div
              className="hoverCursor"
              onClick={() => setInformationPopup(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#3968ED"
              >
                <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
              </svg>
            </div>
          </div>
          {regionReportsData?.length > 0 && (
            <div className="flex flex_gap_15" id="pdf">
              <ButtonToDownloadTablePDF
                itemToDownloadID={"receipt"}
                downloadFileName={"Reservation By Region"}
                ignoreFromPrint={"pdf"}
                // pdfStyles={

                // }
              />
              <DownloadCSVButton
                itemToDownloadID={"receipt"}
                downloadFileName={"Reservation By Region"}
              />
            </div>
          )}
        </div>

        {regionReportsData && regionReportsData.length > 0 ? (
          <>
            {/* <LegacyCard>
              <IndexTable
                resourceName={resourceName}
                itemCount={regionReportsData.length}
                selectable={false}
                headings={[
                  { title: "Guest State" },
                  { title: "Booking Count" },
                  { title: "Total Sale" },
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </LegacyCard> */}

            <CustomReportTable
              headings={["Guest Name", "Booking Count", "Total Sale"]}
              // commonHeadings={[
              //   "",
              //   "",
              //   "",
              //   "Purchased",
              //   "Purchased",
              //   "Issued",
              //   "Issued",
              //   "Stock",
              //   "Stock",
              // ]}
              headerStyles={{
                backgroundColor: "#dddddd",
                fontSize: "12px",
                textAlign: "center",
              }}
              tableStyles={{ textAlign: "left" }}
            >
              {rowMarkup}
              {calculateTotals}
            </CustomReportTable>
          </>
        ) : (
          <NoDataFound />
        )}
      </div>

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of Reservation by Region Report"
        >
          <div className="mb-20">
            <AccordionComponent
              questions={[
                {
                  question: "What is Reservation by Region Report?",
                  answer: (
                    <p>
                      The <b>Reservation by Region</b> report provides a
                      detailed breakdown of guest bookings based on their
                      geographical region (state). It highlights the booking
                      count and total sales for each region, offering insights
                      into which areas contribute the most to the hotel's
                      reservations and revenue.
                    </p>
                  ),
                },
                {
                  question:
                    "What is the Importance of the Reservation by Region Report?",
                  answer: (
                    <div>
                      <ul>
                        <li>
                          <b>Market Insights: </b>The report offers crucial
                          insights into the geographical distribution of guests.
                          By analyzing which regions contribute the most
                          bookings and revenue, businesses can identify key
                          markets to focus on and tailor marketing efforts
                          accordingly.
                        </li>
                        <li>
                          <b>Targeted Marketing</b>: Knowing which regions have
                          the most bookings allows the hotel to target
                          promotions and advertising campaigns to areas where
                          they already have a strong presence, or where there is
                          potential for growth.
                        </li>
                        <li>
                          <b>Resource Allocation</b>: If certain regions are
                          providing the majority of bookings, hotels can
                          allocate more resources such as staff, services, or
                          partnerships to cater specifically to these guests,
                          ensuring a better customer experience.
                        </li>
                        <li>
                          <b>Revenue Forecasting:</b> By tracking regional
                          booking trends over time, the hotel can predict future
                          demand from specific areas, helping with revenue
                          planning and room rate adjustments.
                        </li>
                        <li>
                          <b>Seasonal Trends:</b> This report can highlight
                          seasonal demand from various regions, allowing the
                          hotel to prepare for peak periods and optimize room
                          occupancy.
                        </li>
                        <li>
                          <b>Business Expansion</b>: For hotel chains,
                          identifying areas with significant booking activity
                          can inform decisions about opening new properties in
                          those regions or enhancing services to cater to those
                          markets.
                        </li>
                        {/* <li>
                          <b>Strategic Decision-Making: </b>The report informs
                          decisions on marketing strategies, commission
                          structures, and partnerships to enhance business
                          growth.
                        </li>
                        <li>
                          <b>Operational Efficiency: </b> Understanding booking
                          trends through agents helps optimize inventory
                          management and resource allocation.
                        </li> */}
                      </ul>
                      <p>
                        Overall, the Reservation by Region report is a powerful
                        tool for understanding guest demographics, optimizing
                        marketing strategies, and enhancing operational
                        efficiency.
                      </p>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default RegionReportComp;
