import React from "react";
import styles from "./paymentsInclusionTable.module.scss"
const PaymentsTableCommon = ({ data }) => {
  return (
    <table className={styles.paymentsInclusionTable}>
      <tbody>
        {data?.length > 0 ? (
          <>
            <tr>
              <td className={styles.tableHeader}>Type</td>
              <td className={styles.tableHeader}>Date</td>
              <td className={styles.tableHeader}>Notes</td>
              <td className={styles.tableHeader}>Total Amt.</td>
            </tr>

            {data.map((row, index) => (
              <tr key={index}>
                <td className={styles.tableData}>{row.payment_type}</td>
                <td className={styles.tableData}>{row.payment_date}</td>
                <td className={styles.tableData}>{row.notes}</td>
                <td className={styles.tableData}>{row.amount}</td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan="4" className={styles.noDataFound}>
              No Payments Found
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default PaymentsTableCommon;
