import { createSlice } from "@reduxjs/toolkit";


const propertiesSlice = createSlice({
    name: 'properties',
    initialState: {
      properties: [],
    },
    reducers: {
      setProperties(state, action) {
        state.properties = action.payload;
      },
      clearProperties(state) {
        state.properties= [];
      },
    },
  });
  
  export const { setProperties, clearProperties } = propertiesSlice.actions;
  
  export default propertiesSlice.reducer;