import React from "react";
import Folio from "../Components/Folio/Folio";
import FolioPayments from "../Components/Folio/FolioPayments";

const FolioPaymentsPage = () => {
    return (
        <>
            {/* <div className="main_container"> */}
            <div style={{margin: "0", padding: '70px 0'}}>
            <FolioPayments />
            </div>
            {/* </div> */}
        </>
    );
};

export default FolioPaymentsPage;
