import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import styles from "../bookingSummary/bookingSummary.module.scss";

// Register the elements with Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const ChannelManagerRoomRevenue = () => {
  const [filter, setFilter] = useState("Today");

  const data = {
    labels: ["MakeMyTrip", "Goibibo", "EaseMyTrip"],
    datasets: [
      {
        data: [50, 30, 20], // Replace with dynamic data as needed
        backgroundColor: ["#FF6384", "#FF9F40", "#36A2EB"],
        hoverBackgroundColor: ["#FF6384", "#FF9F40", "#36A2EB"],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false, // Allow the chart to resize based on the container
    rotation: -90, // Rotate the chart to start from the top
    circumference: 180, // Display only half of the circle
    plugins: {
      legend: {
        display: false, // Hide the default legend
      },
    },
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    // Update data based on filter here if necessary
  };

  return (
    <div
      style={{
        padding: "20px",
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        background: "#fff",
        height: "auto", // Automatically adjust height
      }}
    >
      <div
        className="flex justify_content_between align_items_center"
        style={{ marginBottom: "20px" }}
      >
        <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>
          Room Nights & Revenue
        </h2>
        <div className={styles.filter}>
          <select
            value={filter}
            onChange={handleFilterChange}
            style={{ float: "right" }}
          >
            <option value="Today">Today</option>
            <option value="YTD">YTD</option>
            <option value="MTD">MTD</option>
          </select>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "20px",
        }}
      >
        <div style={{ position: "relative", width: "45%", height: "140px" }}>
          <Doughnut data={data} options={options} />
          <h4
            style={{
              textAlign: "center",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              top: "82%",
              padding: "5px",
              borderRadius: "4px",
            }}
          >
            Room Nights
          </h4>
        </div>
        <div style={{ position: "relative", width: "45%", height: "140px" }}>
          <Doughnut data={data} options={options} />
          <h4
            style={{
              textAlign: "center",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              top: "82%",
              padding: "5px",
              borderRadius: "4px",
            }}
          >
            Revenue
          </h4>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        {data.labels.map((label, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "15px",
            }}
          >
            <div
              style={{
                width: "15px",
                height: "15px",
                backgroundColor: data.datasets[0].backgroundColor[index],
                marginRight: "5px",
              }}
            ></div>
            <span>{label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChannelManagerRoomRevenue;
