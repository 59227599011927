import React, { useEffect, useState, useRef } from "react";
import "./CustomRoomChoiceSelect.css";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  WithoutBgButtonBlue,
} from "../../UI/Buttons";
const CustomRoomChoiceSelect = ({
  roomChoice,
  getDataFromCustomRoomChoiceSelect,
  heading,
  showButtons,
  selectedRoomsIds,
  error,
}) => {
  useEffect(() => {
    setSelectedRooms(selectedRoomsIds);
  }, [selectedRoomsIds]);

  // console.log(roomChoice, "selectedRoomsIds")
  const [selectedRooms, setSelectedRooms] = useState(selectedRoomsIds);
  const [showOptions, setShowOptions] = useState(false);
  const componentRef = useRef(null);

  // console.log(selectedRooms, "selectedRooms")

  const handleCheckboxChange = (roomId, roomType) => {
    const isSelected = selectedRooms?.includes(roomId);
    const updatedSelectedRooms = isSelected
      ? selectedRooms?.filter((roomIdInArray) => roomIdInArray !== roomId)
      : [...selectedRooms, roomId];
    setSelectedRooms(updatedSelectedRooms);
  };

  useEffect(() => {
    getDataFromCustomRoomChoiceSelect(selectedRooms);
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowOptions(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [selectedRooms]);

  return (
    <>
      <div style={{ width: "100%" }} ref={componentRef}>
        <p className="customRoomChoiceSelectHeading">{heading}</p>
        <div className="customRoomChoiceSelectContainer">
          <div
            onClick={error ? null : () => setShowOptions(!showOptions)}
            className="customRoomChoiceSelectTogglerContainer"
            style={{
              border: error ? "1px solid red" : "",
              backgroundColor: error ? "#FFEAEA" : "",
              cursor:error ? "not-allowed" : "pointer"
            }}
          >
            <span>
              {selectedRooms.length} of {roomChoice.length} SELECTED
            </span>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                className={
                  showOptions
                    ? "customRoomChoiceSelectIconRotate"
                    : "customRoomChoiceSelectIcon"
                }
              >
                <path d="M1 1L5 5L9 1" stroke="#333333"></path>
              </svg>
            </span>
          </div>
          <div>
            {showOptions && (
              <div className="customRoomChoiceSelectOption">
                {showButtons && (
                  <div className="customRoomChoiceSelectButtonContainer">
                    <WithoutBgButtonBlue
                      type="button"
                      style={{ padding: "0", fontSize: "14px" }}
                      children={"Clear All"}
                      onClick={() => setSelectedRooms([])}
                    />
                    <WithoutBgButtonBlue
                      type="button"
                      style={{ padding: "0", fontSize: "14px" }}
                      children={"Select All"}
                      onClick={() =>
                        setSelectedRooms(roomChoice.map((room) => room.value))
                      }
                    />
                  </div>
                )}
                {roomChoice.map((item) => (
                  <div
                    key={item.value}
                    style={{ display: "flex", marginBottom: "10px" }}
                  >
                    <span>
                      <input
                        type="checkbox"
                        className="customRoomChoiceSelectCheckbox"
                        checked={selectedRooms.some(
                          (room) => room === item.value
                        )}
                        onChange={() =>
                          handleCheckboxChange(item.value, item.label)
                        }
                      />
                    </span>
                    <label
                      className="customRoomChoiceSelectLabel"
                      onClick={() =>
                        handleCheckboxChange(item.value, item.label)
                      }
                    >
                      {item.label}
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomRoomChoiceSelect;
