import {
  Button,
  Icon,
  IndexTable,
  LegacyCard,
  Page,
  useIndexResourceState,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { BgOutlineThemeButton, BgThemeButton } from "./Buttons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import Loader from "./Loader/Loader";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import LoaderSmall from "./Loader/LoaderSmall";
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";
import { getDecryptedData } from "../../utils/encryptStorage";

const ShowDepartureCheckOut = ({
  array,
  setShowDepartureCheckOut,
  setCheckOutBookingDetail,
  setShowCheckOutBookingDetails,
  setShowDashBoardCheckOut,
  dayTypeFilter,
}) => {
  const [units, setUnits] = useState([]);

  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [
    showCheckOutIdClickBookingDetails,
    setShowCheckOutIdClickBookingDetails,
  ] = useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  const [unitsData, setUnitsData] = useState({
    units_name: "",
  });

  const [
    showAddCategoriesLowerMenuActive,
    setShowAddCategoriesLowerMenuActive,
  ] = useState(false);

  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);
  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const resourceName = {
    singular: "unit",
    plural: "units",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(units);

  const addCategoriesRowMarkup = units?.map(
    ({ id, booked_from, unique_booking_id, index }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>{booked_from}</IndexTable.Cell>
        <IndexTable.Cell>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => submitCheckOutBookingData(unique_booking_id)}
          >
            {unique_booking_id}
          </div>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );
  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowAddCategoriesLowerMenuActive(false);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const submitCheckOutBookingData = (bookingId) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${bookingId}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setCheckOutBookingDetail(data.booking);
          // showSuccessToast(data.message);
          setShowCheckOutBookingDetails(true);
          setShowDepartureCheckOut(false);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const setAddItemDataHandleChange = (event) => {
    setUnitsData({
      ...unitsData,
      [event.target.name]: event.target.value,
    });
  };

  const fetchInfo = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/dashboard/${property_id?.id}&dayTypeFilter=${dayTypeFilter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setUnits(data.checkout);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

   useEffect(() => {
    if (property_id  && userToken)  {
      fetchInfo(property_id?.id);
    }
  }, [property_id, userToken]);

  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/unit/edit/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        units_name: data.Unit.units_name,
      };
      setUnitsData(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  const updateFunction = async (id) => {
    const findDepartment = units.find((unit) => unit.id === id);
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/unit/update/${id}/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify(unitsData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        fetchInfo(property_id?.id);
        setShowLowerMenuSingleSelect(false);
        clearSelection();
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const handleSubmitAddDepartment = async (event) => {
    event.preventDefault();
    if (unitsData.units_name === "") {
      showErrorToast("Please fill the unit name...!!!");
      return;
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/create/unit/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({ ...unitsData, property_id: property_id?.id }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        setShowLowerMenuSingleSelect(false);
        clearSelection();
        showSuccessToast(data.message);
        setUnitsData({
          units_name: "",
        });
      } else if (data.error) {
        const firstErrorKey = Object.keys(data.error)[0];
        const firstErrorMessage = data.error[firstErrorKey][0];
        showErrorToast(firstErrorMessage);
        fetchInfo(property_id?.id);
        // return;
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    clearSelection();
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/unit/delete/${property_id?.id}`,
        {
          method: "DELETE",
          body: JSON.stringify(deletedIds),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        showSuccessToast(data.message);
        clearSelection();
        setShowLowerMenuMultipleSelect(false);
        setShowModelMenuActive(false);
        setShowAddCategoriesLowerMenuActive(false);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const handleAddUnitClick = () => {
    clearSelection();
    setShowAddCategoriesLowerMenuActive(!showAddCategoriesLowerMenuActive);
    setShowLowerMenuMultipleSelect(false);
    setShowLowerMenuSingleSelect(false);
    setUnitsData({
      ...unitsData,
      units_name: "",
    });
  };

  return (
    <>
      <div className="bg-backdrop">
        <section className="agent-add-categories-model-container">
          <LegacyCard>
            <div
              style={{
                padding: '16px 26px 0 26px',
              }}
            >
              <div>
                <GoBackButtonCustom
                  buttonNextText={`Checked Out Users`}
                  onClick={() => setShowDepartureCheckOut(false)}
                />
              </div>
              {/* <div
                onClick={() => [setShowDepartureCheckOut(false)]}
                style={{ cursor: "pointer", margin: "0 24px" }}
              >
                <Icon source={CancelMajor} color="base" />
              </div> */}
            </div>
            <div className="overflow_y_scroll_x_hidden"
              style={{
                padding: "10px",
                maxHeight: "300px"
              }}
            >
              {loader ? (
                <LoaderSmall />
              ) : (
                <>
                  {units && units.length > 0 ? (
                    <IndexTable
                      selectable={false}
                      resourceName={resourceName}
                      itemCount={units.length}
                      selectedItemsCount={
                        allResourcesSelected ? "All" : selectedResources.length
                      }
                      onSelectionChange={handleSelectionChange}
                      headings={[{ title: "Name" }, { title: "Booking ID" }]}
                    >
                      {addCategoriesRowMarkup}
                    </IndexTable>
                  ) : (
                    <NoDataFoundSmall />
                  )}
                </>
              )}
            </div>

            {/* Single select */}
            {showLowerMenuSingleSelect && (
              <div className="room-type-bottom-container">
                <div className="room-type-bottom-container-div">
                  <div>
                    <Button plain destructive onClick={showModelMenu}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Icon source={DeleteMajor} color="base" />
                        </span>
                        <span>Remove</span>
                      </div>
                    </Button>
                  </div>
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="room-type-bottom-div">
                      <div>Unit Name</div>
                      <div style={{ width: "200px" }}>
                        <VerticalInputFieldCustom
                          // required={true}
                          name="units_name"
                          className="textfield"
                          onChange={(e) => setAddItemDataHandleChange(e)}
                          focused={false}
                          value={unitsData.units_name}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <BgThemeButton
                      onClick={() => updateFunction(selectedResources[0])}
                      type="submit"
                      children={"Update"}
                    />
                  </form>
                </div>
              </div>
            )}

            {/* Multiple select */}
            {showLowerMenuMultipleSelect && (
              <div className="room-type-bottom-container">
                <div className="room-type-bottom-container-div">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Button plain destructive onClick={showModelMenu}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Icon source={DeleteMajor} color="base" />
                        </span>
                        <span>Remove</span>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {/* Add Button Click */}
            {showAddCategoriesLowerMenuActive && (
              <div className=" room-type-bottom-container">
                <div className="room-type-bottom-container-div">
                  {/* <div>
                  <Button plain destructive>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span></span>
                      <span>Add</span>
                    </div>
                  </Button>
                </div> */}
                  <form
                    onSubmit={(e) => handleSubmitAddDepartment(e)}
                    // onSubmit={(e) => e.preventDefault()}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div className="room-type-bottom-div">
                      <div style={{ marginLeft: "10px" }}>Unit Name</div>
                      <div style={{ width: "150px" }}>
                        <VerticalInputFieldCustom
                          // required={true}
                          name="units_name"
                          className="textfield"
                          onChange={(e) => setAddItemDataHandleChange(e)}
                          focused={false}
                          value={unitsData.units_name}
                          autoComplete="off"
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        color: "#3968ED",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton children={"Add"} type="submit" />
                      <BgOutlineThemeButton
                        onClick={() =>
                          setShowAddCategoriesLowerMenuActive(false)
                        }
                        children={"Cancel"}
                      />
                    </div>
                  </form>
                </div>
              </div>
            )}
          </LegacyCard>
        </section>

        {showModelMenuActive && (
          <>
            <div className="bg-backdrop">
              <section className="room-type-model-container ">
                <h4>Are You Sure?</h4>
                <p>You Want To Remove?</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button onClick={showModelMenu}>Discard</Button>
                  <Button onClick={() => deleteData()} destructive>
                    Yes, Remove
                  </Button>
                </div>
              </section>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ShowDepartureCheckOut;
