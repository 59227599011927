import React from "react";
import { useState, useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "./BillUi.css";
const IFrameBillUi = ({ downloadPdf, viewFolioBillsData, iframeContent }) => {
  const [editableItems, setEditableItems] = useState([
    {
      refNo: 1234,
      description: "Burger",
      qty: 2,
      rate: 250,
      amount: 500,
      discount: 10,
      discountAmount: 450,
      taxRate: 5,
      debit: 525,
      credit: "",
      balance: 472.5,
    },
    {
      refNo: 1234,
      description: "Burger",
      qty: 2,
      rate: 250,
      amount: 500,
      discount: 10,
      discountAmount: 450,
      taxRate: 5,
      debit: 525,
      credit: "",
      balance: 472.5,
    },
  ]);

  const data = {
    tableData: [
      {
        date: "12/08/24",
        description: [
          {
            label: "Room Charges #104 - MAP",
          },
          {
            label: "Room Charges #105 - MAP",
          },
          {
            label: "CGST 6.00%",
          },
          {
            label: "SGST 6.00%",
          },
        ],
        hsn_code: "12345",
        rates: [
          { rate: "20" },
          { rate: "200" },
          { rate: "2000" },
          { rate: "300" },
        ],
        credit: "",

        balance: "12345",
      },
      {
        date: "12/08/24",
        description: [
          {
            label: "Burger x2",
          },
          {
            label: "CGST 5.00% ",
          },
        ],
        hsn_code: "12345",
        rates: [{ rate: "2000" }, { rate: "2000" }],
        credit: "",

        balance: "12345",
      },
      {
        date: "12/08/24",
        description: [
          {
            label: "Room Service #104",
          },
          {
            label: "GST 5.00% ",
          },
          {
            label: "CGST 5.00% ",
          },
        ],
        hsn_code: "12345",
        rates: [{ rate: "2000" }, { rate: "2000" }, { rate: "2000" }],
        credit: "",

        balance: "12345",
      },
    ],

    transactions: [
      {
        label: "credit card",
        amount: "12345",
        balance: "12345",
      },
      {
        label: "UPI",
        amount: "12345",
      },
    ],
    totalAmount: [
      {
        hsn_code: "12345",
        description: "Room Rent",
        tax_rate: "12%",
        tax_amt: "12345",
        gst_amount: "600",
        cgst_amount: "600",
        vat: "00",
        total_amt: "12345",
      },
      {
        hsn_code: "12345",
        description: "Room Rent",
        tax_rate: "12%",
        tax_amt: "12345",
        gst_amount: "600",
        cgst_amount: "600",
        vat: "00",
        total_amt: "12345",
      },
      {
        hsn_code: "12345",
        description: "Room Rent",
        tax_rate: "12%",
        tax_amt: "12345",
        gst_amount: "600",
        cgst_amount: "600",
        vat: "00",
        total_amt: "12345",
      },
    ],
    grandTotal: {
      total_tax_amt: "123455",
      total_gst_amt: "123455",
      total_cgst_amt: "123455",
      total_vat_amt: "123455",
      total_amount: "123455",
    },
  };

  const discount = "5%";
  const settlementAmount = 2000;
  const discountAmount =
    editableItems.reduce(
      (total, item) => parseFloat(total) + parseFloat(item.balance),
      0
    ) *
    (parseFloat(discount) / 100);

  const [editMode, setEditMode] = useState(false);

  const [settlementEditMode, setSettlementEditMode] = useState(false);

  const handleEdit = (index, field, value) => {
    const newItems = [...editableItems];
    newItems[index][field] = value;
    setEditableItems(newItems);
  };

  const grandTotal =
    editableItems.reduce(
      (total, item) => parseFloat(total) + parseFloat(item.balance),
      0
    ) - parseFloat(discountAmount);
  const handleQuantityChange = (index, newQty) => {
    if (grandTotal > settlementAmount) {
      alert("Settlement Amount Exceeded");
    } else {
      const newItems = [...editableItems];
      const item = { ...newItems[index] };
      item.qty = newQty;
      item.amount = parseFloat(newQty) * parseFloat(item.rate);
      item.discountAmount =
        item.amount - (item.amount * parseFloat(item.discount)) / 100;
      item.balance =
        item.discountAmount +
        (item.discountAmount * parseFloat(item.taxRate)) / 100;
      newItems[index] = item;
      setEditableItems(newItems);
    }
  };

  const handleRateChange = (index, newRate) => {
    const newItems = [...editableItems];
    const item = { ...newItems[index] };
    item.rate = newRate;
    item.amount = parseFloat(newRate) * parseFloat(item.qty);
    item.discountAmount =
      item.amount - (item.amount * parseFloat(item.discount)) / 100;
    item.balance =
      item.discountAmount * (parseFloat(item.taxRate) / 100) +
      item.discountAmount;
    newItems[index] = item;
    setEditableItems(newItems);
  };
  const handleDiscountChange = (index, newDis) => {
    const newItems = [...editableItems];
    const item = { ...newItems[index] };
    item.discount = newDis;
    item.discountAmount =
      item.amount - (item.amount * parseFloat(item.discount)) / 100;

    item.balance =
      item.discountAmount * (parseFloat(item.taxRate) / 100) +
      item.discountAmount;

    newItems[index] = item;
    setEditableItems(newItems);
  };
  const handleTaxRateChange = (index, newTaxRate) => {
    const newItems = [...editableItems];
    const item = { ...newItems[index] };
    item.taxRate = newTaxRate;
    item.balance = item.amount * (parseFloat(newTaxRate) / 100) + item.amount;
    newItems[index] = item;
    setEditableItems(newItems);
  };
  const handleUpdate = () => {
    setEditMode(false);
  };
  const handleAddRow = () => {
    const newItem = {
      refNo: "",
      description: "",
      qty: 0,
      rate: 0,
      amount: 0,
      taxRate: "0%",
      debit: 0,
      credit: 0,
      balance: 0,
    };

    const lastItem = editableItems[editableItems.length - 1];
    if (
      lastItem &&
      lastItem.refNo === "" &&
      lastItem.description === "" &&
      lastItem.qty === 0 &&
      lastItem.rate === 0 &&
      lastItem.amount === 0 &&
      lastItem.taxRate === "0%" &&
      lastItem.debit === 0 &&
      lastItem.credit === 0 &&
      lastItem.balance === 0
    ) {
      alert("Please fill all the fields");
    } else {
      setEditableItems([...editableItems, newItem]);
    }
  };
  const handleRemoveRow = (index) => {
    if (editableItems.length <= 1) {
      return;
    }
    const newItems = [...editableItems];
    newItems.splice(index, 1);
    setEditableItems(newItems);
  };

  const handlePrint = () => {
    if (!editMode) {
      window.print();
    } else {
      alert("Disable Edit Mode First");
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (editMode && event.ctrlKey && event.key === "p") {
        event.preventDefault();
        alert("Print shortcut is disabled in edit mode");
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [editMode]);

  const containerRef = useRef(null);

  const generatePdf = () => {
    const foodBillContent = containerRef.current;

    const originalOverflow = foodBillContent.style.overflow;
    foodBillContent.style.overflow = "visible"; // Allow content to overflow for accurate PDF rendering

    const elements = foodBillContent.querySelectorAll("*");
    elements.forEach((element) => {
      element.style.color = window.getComputedStyle(element).color;
    });
    // foodBillContent.style.width = "81%";
    foodBillContent.style.padding = "20px";
    // foodBillContent.style.margin = "auto";

    html2canvas(foodBillContent, {
      scale: 2,
      logging: true,
    }).then((canvas) => {
      const margin = 20;
      const imgData = canvas.toDataURL("image/jpeg", 1);
      const pdf = new jsPDF({
        unit: "px",
        format: [canvas.width + 2 * margin, canvas.height + 2 * margin],
      });
      pdf.addImage(
        imgData,
        "JPEG",
        margin,
        margin,
        canvas.width,
        canvas.height
      );
      pdf.save("food_bill.pdf");

      foodBillContent.style.overflow = originalOverflow;
    });
  };

  const [settlementData, setSettlementData] = useState([
    { paymentOption: "upi", amount: 100 },
    { paymentOption: "credit card", amount: 200 },
  ]);

  const handleSettlementChange = (index, e) => {
    const { name, value } = e.target;

    // Ensure settlementData is an array
    if (!Array.isArray(settlementData)) {
      alert("Settlement data is not in the correct format.");
      return;
    }

    const updatedSettlementData = [...settlementData];
    updatedSettlementData[index][name] = value;

    const newTotal = updatedSettlementData.reduce((total, item) => {
      const amount = parseFloat(item.amount);
      return isNaN(amount) ? total : total + amount;
    }, 0);

    if (!isNaN(newTotal) && newTotal <= grandTotal) {
      setSettlementData(updatedSettlementData);
    } else {
      alert("Settlement amount exceeds the grand total!");
      // Reset the amount to 0 for the specific index
      updatedSettlementData[index].amount = 0;
      setSettlementData(updatedSettlementData);
    }
  };

  const handleUpdateInSettlement = () => {
    const lastItem = settlementData[settlementData.length - 1];

    if (lastItem.paymentOption !== "" && lastItem.amount !== 0) {
      const newTotal = settlementData.reduce((total, item) => {
        const amount = parseFloat(item.amount);
        return isNaN(amount) ? total : total + amount;
      }, 0);
      if (!isNaN(newTotal) && newTotal <= grandTotal) {
        setSettlementEditMode(false);
      } else {
        setSettlementEditMode(true);
      }
    } else {
      alert("Please fill all the fields");
    }
  };

  const handleAddRowInSettlement = () => {
    const lastItem = settlementData[settlementData.length - 1];
    const newItem = {
      paymentOption: "",
      amount: 0,
    };

    if (lastItem.paymentOption !== "" && lastItem.amount !== 0) {
      setSettlementData([...settlementData, newItem]);
    } else {
      alert("Please fill all the fields");
    }
  };

  const handleRemoveRowInSettlement = (index) => {
    if (settlementData.length <= 1) {
      return;
    }
    const newItems = [...settlementData];
    newItems.splice(index, 1);
    setSettlementData(newItems);
  };
  useEffect(() => {
    if (downloadPdf) {
      generatePdf();
    }
  }, [downloadPdf, generatePdf]);

  return (
    <div className="foodBillContent" ref={containerRef}>
      <iframe
        title="Bill Preview"
        srcDoc={iframeContent}
        width={"100%"}
        height={"100%"}
        style={{ width: "100%", height: "100%", border: "none" }}
      ></iframe>
    </div>
  );
};

export default IFrameBillUi;
