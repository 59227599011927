import { Button } from "@shopify/polaris";
import { BgThemeButton } from "./UI/Buttons";
import { Link } from "react-router-dom";

const UpgradePlane = () => {
  return (
    <div className="upgrade-bg-full-container">
      <div className="upgrade-full-container">
        <div className="upgrade-full-container-div" style={{}}>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="251"
              height="130"
              viewBox="0 0 251 130"
              fill="none"
            >
              <g clipPath="url(#clip0_58_680)">
                <path
                  d="M29.7264 107.115C29.4147 106.991 29.0178 107.693 28.7502 107.568C28.4835 107.443 28.7847 106.703 28.5407 105.738C28.4967 105.564 28.2194 104.467 27.7633 104.417C26.8988 104.321 26.1523 108.07 24.8604 108.038C24.5768 108.031 24.2369 107.841 23.3553 107.789C23.0046 107.769 22.8142 107.784 22.7151 107.911C22.5745 108.09 22.7407 108.335 22.9079 108.942C23.1587 109.852 22.961 109.939 23.2213 110.619C23.4146 111.123 23.6251 111.34 23.5181 111.497C23.3668 111.72 22.8476 111.429 22.6218 111.667C22.3913 111.91 22.6219 112.539 22.8628 112.957C23.3149 113.741 23.9344 114.008 24.4568 114.39C25.0244 114.805 25.7428 115.549 26.2677 116.984L30.468 129.897C29.6617 127.204 27.5731 119.728 26.9079 116.863C26.7159 116.036 26.546 115.178 26.9862 114.445C27.41 113.739 28.3482 113.234 29.2098 112.154C29.3104 112.028 29.586 111.673 29.4734 111.436C29.3803 111.24 29.1066 111.304 29.0169 111.122C28.8967 110.879 29.2915 110.568 29.5924 109.945C29.8046 109.506 29.8622 109.135 29.9117 108.816C29.9267 108.72 30.1413 107.281 29.7264 107.115Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M19.765 123.38C19.6143 123.498 19.8418 123.897 19.7037 123.993C19.5661 124.089 19.2924 123.727 18.7515 123.569C18.654 123.54 18.0393 123.361 17.8832 123.572C17.5873 123.972 19.199 125.422 18.8106 126.052C18.7254 126.19 18.5347 126.303 18.2551 126.724C18.1439 126.891 18.0965 126.99 18.1295 127.075C18.1763 127.196 18.3439 127.185 18.688 127.277C19.2039 127.416 19.1893 127.539 19.5954 127.606C19.8969 127.656 20.0634 127.615 20.1092 127.713C20.174 127.852 19.8826 128.025 19.9334 128.205C19.9851 128.389 20.3582 128.457 20.6314 128.458C21.144 128.461 21.4532 128.232 21.79 128.084C22.1561 127.923 22.7258 127.783 23.5769 127.937L31.0822 129.587C29.5371 129.209 25.2906 128.084 23.7025 127.586C23.244 127.442 22.7768 127.279 22.5465 126.849C22.3247 126.436 22.3493 125.827 22.0715 125.089C22.0391 125.003 21.9457 124.765 21.7978 124.752C21.6755 124.742 21.6274 124.895 21.5131 124.887C21.3597 124.876 21.322 124.591 21.1053 124.263C20.9525 124.031 20.7884 123.896 20.6473 123.779C20.6048 123.744 19.9655 123.223 19.765 123.38Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M23.3018 121.717L22.5832 120.97L22.5772 120.875C22.5497 120.439 22.4329 120.082 22.2302 119.815C22.1979 119.773 22.1636 119.732 22.1286 119.691C21.9898 119.526 21.8173 119.322 21.7374 118.989C21.6933 118.803 21.6215 118.349 21.9119 118.186C21.9895 118.142 22.0674 118.133 22.1367 118.14C22.1353 118.125 22.1341 118.111 22.1327 118.096C22.1183 117.908 22.1415 117.805 22.1639 117.706C22.1811 117.629 22.1989 117.551 22.2019 117.394C22.2032 117.326 22.201 117.268 22.1993 117.22C22.1941 117.073 22.1956 116.892 22.3522 116.773C22.5238 116.653 22.7163 116.723 22.8438 116.77C23.0712 116.853 23.2077 116.934 23.2982 116.988C23.3312 117.007 23.3685 117.029 23.3826 117.034C23.4965 117.072 23.8737 116.661 24.0165 116.507C24.3363 116.159 24.613 115.859 24.9227 115.99C25.1443 116.084 25.2438 116.359 25.2255 116.829C25.2197 116.976 25.1928 117.104 25.1636 117.212C25.2481 117.201 25.3408 117.215 25.4261 117.282C25.6276 117.442 25.6108 117.772 25.3767 118.265C25.3188 118.387 25.2404 118.552 25.0848 118.723C25.0115 118.803 24.9381 118.866 24.8745 118.918C24.9022 118.957 24.9249 119.005 24.9343 119.066C24.9625 119.242 24.8679 119.411 24.6458 119.56C24.4042 119.729 24.1786 119.833 23.9799 119.925C23.8065 120.006 23.6574 120.076 23.5629 120.16C23.3896 120.319 23.3614 120.578 23.3425 120.902L23.3018 121.717Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M19.9875 116.516L19.4517 117.406L19.3625 117.436C18.9502 117.573 18.6374 117.778 18.4325 118.043C18.4 118.085 18.3695 118.129 18.3385 118.173C18.2156 118.35 18.0629 118.57 17.7634 118.732C17.5962 118.823 17.1784 119.008 16.9484 118.766C16.8858 118.702 16.8577 118.628 16.8465 118.559C16.8333 118.564 16.82 118.569 16.8056 118.574C16.6292 118.635 16.5241 118.639 16.4229 118.642C16.3451 118.645 16.2649 118.648 16.1141 118.684C16.0479 118.7 15.9932 118.717 15.9471 118.731C15.8074 118.773 15.6332 118.818 15.4796 118.696C15.3199 118.559 15.3388 118.354 15.3512 118.218C15.3732 117.975 15.4166 117.821 15.4454 117.72C15.456 117.682 15.4677 117.641 15.4688 117.626C15.476 117.505 14.9853 117.242 14.8012 117.142C14.3866 116.918 14.0279 116.725 14.0756 116.39C14.1094 116.15 14.348 115.984 14.8044 115.882C14.9466 115.851 15.077 115.844 15.1875 115.846C15.1562 115.766 15.1453 115.672 15.1884 115.572C15.2909 115.335 15.6126 115.268 16.1449 115.371C16.2774 115.396 16.4552 115.431 16.6588 115.539C16.7546 115.59 16.8335 115.646 16.9002 115.694C16.9306 115.658 16.9709 115.623 17.0263 115.599C17.189 115.527 17.3746 115.576 17.5749 115.754C17.798 115.947 17.9552 116.14 18.0941 116.31C18.2155 116.459 18.3204 116.586 18.4253 116.657C18.6217 116.786 18.8784 116.747 19.1937 116.684L19.9875 116.516Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M17.7164 114.434L16.9978 113.687L16.9918 113.592C16.9642 113.156 16.8475 112.799 16.6447 112.532C16.6125 112.49 16.5781 112.449 16.5431 112.408C16.4044 112.243 16.2318 112.038 16.152 111.706C16.1078 111.52 16.036 111.066 16.3265 110.903C16.4041 110.858 16.4819 110.85 16.5512 110.856C16.5498 110.842 16.5486 110.828 16.5472 110.813C16.5328 110.625 16.5561 110.522 16.5785 110.423C16.5956 110.346 16.6135 110.267 16.6164 110.111C16.6178 110.043 16.6156 109.985 16.6138 109.937C16.6087 109.79 16.6102 109.609 16.7667 109.49C16.9384 109.369 17.1308 109.44 17.2583 109.486C17.4858 109.569 17.6223 109.651 17.7128 109.704C17.7458 109.724 17.7831 109.746 17.7971 109.751C17.9111 109.789 18.2882 109.377 18.431 109.223C18.7508 108.876 19.0276 108.576 19.3372 108.707C19.5589 108.801 19.6583 109.076 19.64 109.546C19.6342 109.693 19.6074 109.821 19.5781 109.929C19.6627 109.918 19.7554 109.931 19.8406 109.999C20.0422 110.159 20.0253 110.489 19.7912 110.982C19.7334 111.104 19.6549 111.269 19.4994 111.439C19.426 111.52 19.3526 111.583 19.289 111.635C19.3167 111.674 19.3394 111.722 19.3489 111.782C19.377 111.959 19.2824 112.127 19.0604 112.277C18.8187 112.446 18.5932 112.55 18.3945 112.642C18.2211 112.723 18.072 112.792 17.9775 112.877C17.8042 113.035 17.776 113.295 17.757 113.618L17.7164 114.434Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M30.7299 129.672L30.4772 129.697L30.2962 129.874C30.2788 129.858 30.2353 129.824 30.1678 129.77C29.7983 129.473 28.6734 128.57 27.1025 126.992C26.006 125.891 24.9516 124.702 23.968 123.458C22.9828 122.211 22.3103 121.211 21.7695 120.407C21.3618 119.801 20.9829 119.216 20.6142 118.646C19.6286 117.124 18.6974 115.686 17.4678 114.249C17.1935 113.928 16.6222 113.261 15.6495 112.834C15.0854 112.586 14.4697 112.456 13.8198 112.446L13.8271 111.935C14.5448 111.945 15.2264 112.09 15.8528 112.365C16.9312 112.839 17.5537 113.566 17.8529 113.916C19.1041 115.378 20.0441 116.83 21.0392 118.367C21.4068 118.934 21.7838 119.517 22.19 120.121C22.7256 120.917 23.3924 121.909 24.3649 123.139C25.3366 124.368 26.3783 125.543 27.4608 126.631C29.012 128.188 30.1202 129.078 30.4843 129.37C30.6786 129.526 30.7193 129.559 30.7299 129.672Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M13.5759 113.662C13.5594 113.675 13.5424 113.688 13.5246 113.699C13.1682 113.947 12.6932 113.987 12.1127 113.819C11.8401 113.74 11.6125 113.584 11.1586 113.274C11.0885 113.227 10.7374 112.981 10.3327 112.582C10.0681 112.322 9.97961 112.196 9.95437 112.043C9.92601 111.874 9.96496 111.71 10.0378 111.563C10.003 111.528 9.97649 111.485 9.96196 111.431C9.90103 111.209 10.1001 111.09 10.2067 111.024C10.2614 110.991 10.33 110.951 10.3943 110.896C10.496 110.808 10.5284 110.733 10.578 110.618C10.6253 110.509 10.6842 110.373 10.8119 110.224C11.0628 109.928 11.3787 109.819 11.4829 109.783C12.0292 109.591 12.5044 109.764 13.0545 109.965C13.1646 110.006 13.5549 110.162 13.9693 110.469C14.2683 110.69 14.3539 110.826 14.4017 110.939C14.5005 111.17 14.4686 111.372 14.3594 111.847C14.2459 112.343 14.1887 112.592 14.115 112.774C13.9429 113.196 13.8349 113.461 13.5759 113.662Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M30.2297 84.0183L36.1048 93.811L70.3425 91.7709L61.226 79.938L30.2297 84.0183Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M40.46 129.82C40.2605 129.82 40.0868 129.671 40.059 129.467L29.8283 54.2214C29.7979 53.998 29.953 53.7922 30.1747 53.7618C30.3963 53.7295 30.6006 53.8873 30.6311 54.1106L40.8619 129.356C40.8923 129.58 40.7371 129.785 40.5155 129.816C40.4968 129.818 40.4783 129.82 40.46 129.82Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M30.2296 129.916C30.0059 129.916 29.8245 129.733 29.8245 129.507V54.1807C29.8245 53.9554 30.0059 53.7727 30.2296 53.7727C30.4534 53.7727 30.6348 53.9554 30.6348 54.1807V129.507C30.6348 129.733 30.4534 129.916 30.2296 129.916Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M81.5861 129.616C81.4139 129.616 81.2541 129.504 81.1997 129.33L56.9902 51.8404C56.9231 51.6255 57.0417 51.3963 57.2551 51.3286C57.4685 51.2613 57.6961 51.3806 57.7632 51.5954L81.9727 129.085C82.0398 129.3 81.9212 129.529 81.7078 129.597C81.6673 129.61 81.6264 129.616 81.5861 129.616Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M74.1914 129.711C74.0105 129.711 73.8457 129.589 73.799 129.404L56.984 61.0138C56.9289 60.7954 57.06 60.5733 57.2768 60.5177C57.4943 60.4625 57.7142 60.5942 57.7694 60.8126L74.5844 129.203C74.6395 129.421 74.5084 129.643 74.2916 129.699C74.2581 129.707 74.2244 129.711 74.1914 129.711Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M30.2297 55.3806L58.2884 54.946L63.4545 84.6303H30.6349L30.2297 55.3806Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M250.809 129.501H0.190674V130H250.809V129.501Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M51.5761 22.9463C52.4263 23.4274 53.7983 21.5245 54.1093 22.4553C54.4202 23.386 53.6671 27.1417 53.2786 28.0424C53.0855 28.4901 52.8125 28.8996 52.623 29.3489C52.4335 29.7982 52.3319 30.3148 52.4994 30.7728C52.7908 31.57 53.7528 31.8943 54.2298 32.5949C54.7853 33.4109 54.561 34.51 54.3013 35.4642C54.0774 36.2867 53.8389 37.1166 53.408 37.8507C52.9771 38.5848 52.3275 39.224 51.5157 39.4669C50.4822 39.7762 49.3776 39.4087 48.3813 38.9937C47.4233 38.5948 46.4757 38.1426 45.661 37.4972C44.8462 36.8518 44.1664 35.9951 43.8816 34.9914C43.5244 33.7324 43.8228 32.3545 44.4414 31.2028C45.0599 30.051 45.9749 29.0915 46.9138 28.1855C48.495 26.6598 50.1777 25.2407 51.947 23.9411"
                  fill="#2F2E41"
                />
                <path
                  d="M56.8527 68.1445L79.5391 76.7983C79.5391 76.7983 81.4297 78.7022 81.7734 81.9906C82.1171 85.2791 88.4762 103.971 88.4762 103.971L82.9765 106.048C82.9765 106.048 73.6957 91.6829 74.2113 85.7983C74.2113 85.7983 73.1801 83.0236 72.6645 82.9398C72.1489 82.856 64.4149 78.8753 59.4307 79.3945C54.4466 79.9137 41.3847 81.4714 41.041 75.4137C40.6972 69.3561 43.7117 69.3561 43.7117 69.3561L56.8527 68.1445Z"
                  fill="#2F2E41"
                />
                <path
                  d="M87.2732 102.413C87.2732 102.413 88.82 101.894 89.8512 102.933C90.8824 103.971 90.8824 104.837 90.8824 104.837C90.8824 104.837 98.9602 104.663 99.3039 105.702C99.6476 106.74 98.4446 110.894 95.5228 111.067C92.6011 111.24 82.6328 113.317 82.6328 111.413C82.6328 109.51 82.8047 105.183 83.4922 105.183C84.1796 105.183 87.2732 102.413 87.2732 102.413Z"
                  fill="#2F2E41"
                />
                <path
                  d="M58.8125 73.649L75.3472 91.5256C75.3472 91.5256 76.205 94.0757 75.0671 97.1773C73.9291 100.279 71.4104 119.874 71.4104 119.874L65.566 119.284C65.566 119.284 63.5623 102.265 66.6125 97.2187C66.6125 97.2187 66.9081 94.2711 66.4827 93.966C66.0572 93.6609 60.8736 86.6426 56.1765 84.8853C51.4795 83.1281 39.0833 78.6994 41.4391 73.1148C43.7948 67.5303 46.4975 68.8747 46.4975 68.8747L58.8125 73.649Z"
                  fill="#2F2E41"
                />
                <path
                  d="M71.0166 117.941C71.0166 117.941 72.6318 118.165 73.0997 119.556C73.5676 120.947 73.187 121.723 73.187 121.723C73.187 121.723 80.5056 125.171 80.3571 126.255C80.2086 127.339 77.3032 130.527 74.6075 129.379C71.9118 128.231 62.061 125.648 62.8982 123.941C63.7355 122.234 65.7924 118.431 66.4088 118.738C67.0251 119.044 71.0166 117.941 71.0166 117.941Z"
                  fill="#2F2E41"
                />
                <path
                  d="M43.705 36.9041L49.2048 40.5387L55.392 50.0579C55.392 50.0579 56.7669 60.0963 56.2513 60.6155C55.7357 61.1347 55.9076 61.654 55.9076 62.6924C55.9076 63.7309 57.1702 63.8413 56.367 64.738C55.5639 65.6347 54.8899 65.8214 56.0862 66.1608C57.2825 66.5001 58.6723 66.1689 57.9774 67.1999C57.2825 68.2309 57.7981 68.577 57.4544 68.9232C57.1106 69.2693 43.1967 84.0678 40.6187 78.3562C38.0407 72.6447 37.8616 50.7502 37.8616 50.7502L39.7188 43.6177L43.705 36.9041Z"
                  fill="#CCCCCC"
                />
                <path
                  d="M26.7596 77.6799C26.1744 79.6728 26.7211 81.5922 27.9806 81.967C29.24 82.3419 30.735 81.0303 31.3202 79.0366C31.5648 78.2435 31.6172 77.4029 31.473 76.5852L33.8467 68.1099L29.8735 67.073L27.9554 75.5382C27.3927 76.1457 26.9826 76.8801 26.7596 77.6799Z"
                  fill="#FFB6B6"
                />
                <path
                  d="M49.3879 48.9402C49.3879 48.9402 42.9822 44.0156 40.5437 47.1213C38.1053 50.227 29.1727 64.0142 29.1727 64.0142L28.5806 69.2684L33.6466 70.3943L41.8348 58.2438L49.3879 48.9402Z"
                  fill="#CCCCCC"
                />
                <path
                  d="M81.5918 29.8023C83.0607 28.3428 83.5036 26.3964 82.5811 25.4551C81.6586 24.5138 79.7202 24.9341 78.2509 26.3944C77.6561 26.9696 77.2066 27.6799 76.9402 28.4659L70.7937 34.7293L73.7739 37.5713L79.5169 31.095C80.3011 30.8372 81.0126 30.3939 81.5918 29.8023Z"
                  fill="#FFB6B6"
                />
                <path
                  d="M47.9866 43.9437C47.9866 43.9437 51.2298 51.3732 54.8553 49.8417C58.4808 48.3103 72.919 40.5902 72.919 40.5902L75.9597 36.2791L72.0655 32.8271L59.0648 39.4768L47.9866 43.9437Z"
                  fill="#CCCCCC"
                />
                <path
                  d="M49.4525 35.4274C52.8593 35.4274 55.6211 32.6462 55.6211 29.2154C55.6211 25.7846 52.8593 23.0034 49.4525 23.0034C46.0457 23.0034 43.2839 25.7846 43.2839 29.2154C43.2839 32.6462 46.0457 35.4274 49.4525 35.4274Z"
                  fill="#FFB6B6"
                />
                <path
                  d="M42.4988 38.9442C43.9385 41.0124 44.6748 43.5679 44.5579 46.0909C46.1903 45.2946 47.2921 43.6122 47.6671 41.825C48.042 40.0379 47.756 38.1614 47.1534 36.4385C47.1474 36.4213 47.1413 36.404 47.1352 36.3868C46.2203 33.8008 47.0704 30.9106 49.2802 29.303C50.7708 28.2187 52.0635 26.8584 53.0557 25.299C53.6159 24.4187 54.092 23.3144 53.6735 22.3576C53.2879 21.4759 52.2918 21.0638 51.3752 20.788C49.282 20.1584 47.058 19.8001 44.9096 20.1985C42.7612 20.597 40.693 21.8411 39.6776 23.789C38.6623 25.7368 38.9283 28.3951 40.5763 29.8391C40.0565 30.0083 39.6888 30.5632 39.7329 31.1115C39.7771 31.6598 40.2288 32.1478 40.7689 32.2305C40.4165 32.2179 40.2085 32.6852 40.3342 33.017C40.4598 33.3487 40.7839 33.5548 41.093 33.7256C41.5334 33.9688 42.0215 34.2232 42.0234 34.8601C42.0241 35.1155 41.9337 35.3634 41.7859 35.571C41.4278 36.074 40.8497 36.3006 40.2932 36.5132C41.4703 36.8624 42.4089 37.9218 42.6196 39.1392"
                  fill="#2F2E41"
                />
                <path
                  d="M93.7037 37.2541C103.918 37.2541 112.199 28.9152 112.199 18.6285C112.199 8.34189 103.918 0.00292969 93.7037 0.00292969C83.4889 0.00292969 75.2083 8.34189 75.2083 18.6285C75.2083 28.9152 83.4889 37.2541 93.7037 37.2541Z"
                  fill="#3968ED"
                />
                <path
                  d="M92.745 18.7166L90.979 29.2637L92.8146 29.5754L94.5806 19.0283L92.745 18.7166Z"
                  fill="white"
                />
                <path
                  d="M99.5911 25.2184L93.2804 21.1559L85.9958 22.9099L95.5105 7.83691L99.5911 25.2184Z"
                  fill="white"
                />
                <path
                  d="M210.32 109.52C210.593 109.411 210.941 110.025 211.175 109.916C211.408 109.807 211.145 109.159 211.358 108.314C211.397 108.161 211.64 107.201 212.039 107.157C212.796 107.074 213.449 110.355 214.58 110.327C214.828 110.321 215.126 110.155 215.898 110.11C216.205 110.091 216.371 110.105 216.458 110.216C216.581 110.373 216.436 110.588 216.289 111.119C216.07 111.916 216.243 111.992 216.015 112.586C215.846 113.028 215.661 113.218 215.755 113.355C215.888 113.55 216.342 113.296 216.54 113.504C216.741 113.717 216.54 114.267 216.329 114.633C215.933 115.319 215.391 115.554 214.933 115.888C214.436 116.251 213.808 116.902 213.348 118.159L209.671 129.463C210.377 127.105 212.205 120.56 212.788 118.053C212.956 117.329 213.104 116.578 212.719 115.936C212.348 115.318 211.527 114.876 210.773 113.93C210.684 113.82 210.443 113.509 210.542 113.302C210.623 113.131 210.863 113.186 210.941 113.027C211.047 112.814 210.701 112.542 210.438 111.997C210.252 111.612 210.201 111.288 210.158 111.008C210.145 110.924 209.957 109.665 210.32 109.52Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M219.041 123.758C219.173 123.861 218.973 124.21 219.094 124.295C219.215 124.379 219.454 124.061 219.928 123.923C220.013 123.898 220.551 123.741 220.688 123.926C220.947 124.276 219.536 125.546 219.876 126.096C219.951 126.217 220.118 126.316 220.362 126.685C220.46 126.831 220.501 126.918 220.472 126.992C220.431 127.099 220.285 127.089 219.983 127.17C219.532 127.291 219.545 127.399 219.189 127.457C218.925 127.501 218.779 127.465 218.739 127.551C218.683 127.673 218.938 127.824 218.893 127.982C218.848 128.143 218.521 128.202 218.282 128.203C217.833 128.205 217.563 128.005 217.268 127.875C216.948 127.735 216.449 127.612 215.704 127.747L209.134 129.191C210.486 128.86 214.204 127.875 215.594 127.44C215.995 127.314 216.404 127.17 216.606 126.795C216.8 126.433 216.778 125.9 217.022 125.254C217.05 125.179 217.132 124.97 217.261 124.959C217.368 124.95 217.41 125.084 217.51 125.077C217.645 125.068 217.678 124.818 217.867 124.531C218.001 124.328 218.145 124.209 218.268 124.107C218.306 124.077 218.865 123.62 219.041 123.758Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M215.944 122.302L216.574 121.648L216.579 121.565C216.603 121.183 216.705 120.871 216.883 120.637C216.911 120.6 216.941 120.565 216.972 120.528C217.093 120.384 217.244 120.205 217.314 119.914C217.353 119.751 217.415 119.354 217.161 119.211C217.093 119.172 217.025 119.165 216.964 119.17C216.966 119.158 216.967 119.146 216.968 119.132C216.981 118.968 216.96 118.878 216.941 118.791C216.926 118.724 216.91 118.655 216.907 118.518C216.906 118.458 216.908 118.408 216.91 118.365C216.914 118.237 216.913 118.078 216.776 117.974C216.626 117.869 216.457 117.93 216.345 117.971C216.146 118.044 216.027 118.115 215.948 118.162C215.919 118.179 215.886 118.199 215.874 118.203C215.774 118.236 215.444 117.876 215.319 117.741C215.039 117.437 214.797 117.174 214.526 117.289C214.332 117.371 214.244 117.612 214.26 118.023C214.266 118.152 214.289 118.264 214.315 118.358C214.241 118.349 214.159 118.361 214.085 118.42C213.908 118.56 213.923 118.849 214.128 119.28C214.179 119.387 214.247 119.531 214.384 119.681C214.448 119.751 214.512 119.806 214.568 119.852C214.543 119.886 214.524 119.928 214.515 119.981C214.491 120.136 214.574 120.283 214.768 120.414C214.979 120.562 215.177 120.653 215.351 120.733C215.503 120.804 215.633 120.865 215.716 120.939C215.868 121.078 215.892 121.305 215.909 121.588L215.944 122.302Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M218.846 117.749L219.315 118.528L219.393 118.554C219.754 118.674 220.028 118.853 220.207 119.085C220.235 119.122 220.262 119.161 220.289 119.2C220.397 119.355 220.531 119.547 220.793 119.689C220.939 119.768 221.305 119.93 221.506 119.718C221.561 119.662 221.586 119.598 221.595 119.537C221.607 119.542 221.619 119.546 221.631 119.55C221.786 119.604 221.878 119.607 221.966 119.61C222.034 119.613 222.104 119.615 222.236 119.647C222.294 119.661 222.342 119.676 222.383 119.688C222.505 119.725 222.657 119.764 222.792 119.657C222.932 119.537 222.915 119.357 222.904 119.238C222.885 119.026 222.847 118.892 222.822 118.802C222.813 118.77 222.802 118.733 222.801 118.72C222.795 118.615 223.225 118.384 223.386 118.297C223.749 118.101 224.063 117.932 224.021 117.639C223.991 117.429 223.782 117.283 223.383 117.194C223.259 117.167 223.144 117.161 223.048 117.162C223.075 117.092 223.085 117.01 223.047 116.923C222.957 116.715 222.675 116.656 222.209 116.747C222.094 116.769 221.938 116.799 221.76 116.894C221.676 116.938 221.607 116.987 221.548 117.03C221.522 116.997 221.486 116.967 221.438 116.946C221.296 116.883 221.133 116.926 220.958 117.082C220.762 117.251 220.625 117.42 220.503 117.569C220.397 117.699 220.305 117.81 220.213 117.872C220.041 117.985 219.817 117.951 219.541 117.896L218.846 117.749Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M220.834 115.926L221.463 115.272L221.468 115.189C221.492 114.807 221.595 114.495 221.772 114.261C221.8 114.224 221.831 114.189 221.861 114.152C221.983 114.008 222.134 113.829 222.204 113.538C222.242 113.375 222.305 112.978 222.051 112.835C221.983 112.796 221.915 112.789 221.854 112.794C221.855 112.782 221.856 112.77 221.858 112.756C221.87 112.592 221.85 112.502 221.83 112.415C221.815 112.348 221.8 112.279 221.797 112.142C221.796 112.082 221.798 112.032 221.799 111.989C221.804 111.861 221.802 111.702 221.665 111.598C221.515 111.493 221.347 111.554 221.235 111.595C221.036 111.668 220.916 111.739 220.837 111.786C220.808 111.803 220.776 111.823 220.763 111.827C220.664 111.86 220.334 111.5 220.208 111.365C219.929 111.061 219.686 110.798 219.415 110.913C219.221 110.995 219.134 111.236 219.15 111.647C219.155 111.776 219.179 111.888 219.204 111.982C219.13 111.973 219.049 111.985 218.975 112.044C218.798 112.184 218.813 112.473 219.018 112.904C219.068 113.011 219.137 113.155 219.273 113.305C219.337 113.375 219.402 113.43 219.457 113.476C219.433 113.51 219.413 113.552 219.405 113.605C219.38 113.76 219.463 113.907 219.658 114.038C219.869 114.186 220.067 114.277 220.24 114.357C220.392 114.428 220.523 114.489 220.606 114.563C220.757 114.702 220.782 114.929 220.798 115.212L220.834 115.926Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M209.442 129.266L209.663 129.288L209.822 129.443C209.837 129.429 209.875 129.399 209.934 129.351C210.257 129.092 211.242 128.301 212.617 126.92C213.577 125.956 214.5 124.915 215.361 123.826C216.224 122.735 216.812 121.859 217.286 121.155C217.643 120.624 217.974 120.113 218.297 119.614C219.16 118.281 219.975 117.023 221.052 115.765C221.292 115.484 221.792 114.899 222.643 114.525C223.137 114.309 223.676 114.194 224.245 114.186L224.239 113.738C223.61 113.748 223.014 113.875 222.465 114.115C221.521 114.53 220.976 115.167 220.714 115.473C219.619 116.753 218.796 118.024 217.925 119.369C217.603 119.866 217.273 120.376 216.918 120.904C216.449 121.602 215.865 122.47 215.014 123.547C214.163 124.623 213.251 125.651 212.304 126.603C210.946 127.967 209.976 128.745 209.657 129.001C209.487 129.138 209.451 129.166 209.442 129.266Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M224.459 115.251C224.473 115.262 224.488 115.273 224.504 115.283C224.816 115.5 225.231 115.535 225.74 115.388C225.978 115.319 226.177 115.183 226.575 114.911C226.636 114.869 226.943 114.654 227.298 114.305C227.529 114.077 227.607 113.967 227.629 113.834C227.654 113.685 227.62 113.541 227.556 113.413C227.586 113.382 227.61 113.345 227.622 113.298C227.676 113.103 227.501 112.999 227.408 112.941C227.36 112.913 227.3 112.878 227.244 112.829C227.155 112.752 227.126 112.686 227.083 112.586C227.042 112.49 226.99 112.371 226.878 112.241C226.659 111.982 226.382 111.886 226.291 111.855C225.813 111.686 225.397 111.838 224.915 112.014C224.819 112.05 224.477 112.186 224.114 112.455C223.852 112.649 223.778 112.768 223.736 112.867C223.649 113.069 223.677 113.246 223.773 113.661C223.872 114.096 223.922 114.314 223.987 114.473C224.137 114.842 224.232 115.074 224.459 115.251Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M152.294 126.994L148.381 126.082L150.013 110.446L155.789 111.793L152.294 126.994Z"
                  fill="#A0616A"
                />
                <path
                  d="M138.906 123.779C138.737 123.958 138.519 124.61 138.464 124.85C138.294 125.589 138.751 126.326 139.485 126.497L151.605 129.322C152.105 129.439 152.605 129.125 152.721 128.621L152.837 128.113C152.837 128.113 153.788 126.725 152.986 124.555C152.986 124.555 151.113 125.671 149.352 122.829L148.959 121.438L143.237 123.901L140.447 123.605C139.837 123.54 139.331 123.333 138.906 123.779Z"
                  fill="#2F2E41"
                />
                <path
                  d="M193.361 118.567L190.452 121.356L178.383 111.384L182.677 107.267L193.361 118.567Z"
                  fill="#A0616A"
                />
                <path
                  d="M183.332 128.058C183.386 128.299 183.807 128.84 183.976 129.019C184.496 129.568 185.359 129.589 185.904 129.066L194.913 120.427C195.285 120.07 195.299 119.477 194.945 119.103L194.588 118.725C194.588 118.725 193.96 117.162 191.719 116.643C191.719 116.643 191.615 118.832 188.288 118.75L186.919 118.316L185.815 124.483L184.035 126.666C183.646 127.144 183.195 127.456 183.332 128.058Z"
                  fill="#2F2E41"
                />
                <path
                  d="M209.88 89.3004L204.737 97.873L174.765 96.087L182.746 85.7285L209.88 89.3004Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M200.924 129.395C201.099 129.395 201.251 129.265 201.275 129.086L210.231 63.2161C210.258 63.0206 210.122 62.8404 209.928 62.8137C209.734 62.7855 209.555 62.9236 209.528 63.1191L200.572 128.989C200.546 129.185 200.682 129.365 200.876 129.392C200.892 129.394 200.908 129.395 200.924 129.395Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M209.88 129.479C210.076 129.479 210.235 129.319 210.235 129.122V63.1804C210.235 62.9832 210.076 62.8232 209.88 62.8232C209.684 62.8232 209.525 62.9832 209.525 63.1804V129.122C209.525 129.319 209.684 129.479 209.88 129.479Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M164.922 129.217C165.073 129.217 165.213 129.119 165.261 128.966L186.454 61.1317C186.513 60.9436 186.409 60.743 186.222 60.6837C186.035 60.6247 185.836 60.7292 185.777 60.9172L164.584 128.752C164.525 128.94 164.629 129.141 164.816 129.2C164.851 129.211 164.887 129.217 164.922 129.217Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M171.396 129.3C171.554 129.3 171.698 129.193 171.739 129.031L186.459 69.1621C186.507 68.9709 186.393 68.7765 186.203 68.7278C186.012 68.6795 185.82 68.7948 185.772 68.9859L171.052 128.855C171.004 129.046 171.118 129.24 171.308 129.289C171.337 129.297 171.367 129.3 171.396 129.3Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M209.88 64.2307L185.317 63.8503L180.795 89.836H209.525L209.88 64.2307Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M200.442 81.6111C200.442 81.6111 204.713 92.2815 196.814 94.7152C188.914 97.1489 177.529 100.587 177.529 100.587L190.618 113.345L184.165 117.498C184.165 117.498 160.361 101.078 160.361 97.1489C160.361 93.2198 179.869 83.0398 179.869 83.0398L190.618 79.1726L200.442 81.6111Z"
                  fill="#2F2E41"
                />
                <path
                  d="M175.575 79.1726C175.575 79.1726 153.646 80.7942 151.994 85.0871C150.341 89.38 147.042 111.573 147.042 111.573L158.569 113.632L162.008 94.7152L193.564 83.4066L175.575 79.1726Z"
                  fill="#2F2E41"
                />
                <path
                  d="M163.875 54.7866L169.665 47.6577L176.061 51.882C176.061 51.882 200.164 67.6514 201.16 82.4485C201.16 82.4485 191.463 91.8421 179.019 80.0439L170.002 77.6356L163.875 54.7866Z"
                  fill="#CCCCCC"
                />
                <path
                  d="M138.157 84.4373C136.233 85.1664 134.301 84.7582 133.84 83.5256C133.379 82.293 134.565 80.7027 136.488 79.9736C137.33 79.6544 138.174 79.5532 138.895 79.6437L147.084 76.6572L148.394 80.5569L140.182 83.087C139.699 83.634 138.999 84.1181 138.157 84.4373Z"
                  fill="#A0616A"
                />
                <path
                  d="M165.807 55.4059L163.874 54.7866L158.346 71.7952L139.444 78.7781L140.651 83.4667L162.146 78.3009L170.443 70.1534L165.807 55.4059Z"
                  fill="#CCCCCC"
                />
                <path
                  d="M162.275 49.7133C166.063 49.7133 169.133 46.6216 169.133 42.8078C169.133 38.994 166.063 35.9023 162.275 35.9023C158.488 35.9023 155.418 38.994 155.418 42.8078C155.418 46.6216 158.488 49.7133 162.275 49.7133Z"
                  fill="#A0616A"
                />
                <path
                  d="M169.067 37.6037C169.067 37.6037 167.283 31.8033 161.367 32.5781C161.367 32.5781 160.171 33.7525 159.161 33.5698C158.151 33.387 156.257 36.4219 155.751 36.4155C155.245 36.4091 154.395 36.9081 155.238 36.9188C156.082 36.9295 157.001 36.5163 155.826 37.1811C154.65 37.846 153.869 38.4309 154.709 38.7814C155.548 39.1319 155.709 38.1993 154.619 39.2051C153.53 40.2109 154.192 39.6245 154.692 40.1407C155.192 40.6568 154.406 43.4695 154.406 43.4695C154.406 43.4695 154.339 41.3258 157.208 41.3621C160.076 41.3984 161.923 42.1015 162.778 41.2627C163.632 40.4238 164.886 41.7189 165.675 42.5337C166.464 43.3486 167.141 43.1872 167.141 43.1872L166.417 47.0866C166.417 47.0866 169.373 46.8751 169.561 45.3421C169.749 43.809 169.01 42.1913 169.522 41.6879C170.035 41.1846 169.067 37.6037 169.067 37.6037Z"
                  fill="#2F2E41"
                />
                <path
                  d="M134.65 83.0597C143.592 83.0597 150.841 75.7598 150.841 66.7548C150.841 57.7499 143.592 50.45 134.65 50.45C125.708 50.45 118.459 57.7499 118.459 66.7548C118.459 75.7598 125.708 83.0597 134.65 83.0597Z"
                  fill="#3968ED"
                />
                <path
                  d="M135.489 66.8321L133.883 67.105L135.428 76.3379L137.035 76.0651L135.489 66.8321Z"
                  fill="white"
                />
                <path
                  d="M129.496 72.5236L135.021 68.9674L141.398 70.5028L133.069 57.3079L129.496 72.5236Z"
                  fill="white"
                />
                <path
                  d="M139.412 75.1633C137.356 75.1644 135.69 74.0976 135.689 72.7806C135.688 71.4635 137.354 70.3949 139.409 70.3938C140.309 70.3933 141.134 70.5975 141.777 70.9377L150.486 71.0429L150.35 75.1573L141.779 74.6168C141.136 74.9577 140.311 75.1628 139.412 75.1633Z"
                  fill="#A0616A"
                />
                <path
                  d="M172.513 54.8625C176.72 59.493 177.237 66.4199 173.764 71.6312L166.791 78.055L144.431 75.9185L144.903 70.6477L160.705 69.7916L172.513 54.8625Z"
                  fill="#CCCCCC"
                />
              </g>
              <defs>
                <clipPath id="clip0_58_680">
                  <rect
                    width="250.619"
                    height="130"
                    fill="white"
                    transform="translate(0.190674)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div style={{ fontSize: "26px", fontWeight: "600" }}>
            Upgrade Your Plan
          </div>

          <div>
            With our higher plans, you'll enjoy advanced features, improved
            guest satisfaction, and increased efficiency. Elevate your property
            management game with our top-tier offerings designed exclusively for
            hotel professionals.
          </div>

          <div style={{ color: "#3968ED" }}>Give us a call</div>

          <div style={{ marginTop: "-10px" }}>
            <a href="tel://+918091805354">
              {" "}
              <BgThemeButton children={"(+91) 80918 05354"}></BgThemeButton>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradePlane;
