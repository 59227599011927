import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto"; // Required to register chart.js components
import { getDateAndMonth } from "../../../utils/utils";

const RevenueInsights = ({ data }) => {
  const MAX_DATA_POINTS = 30; // Maximum points to display to prevent overlap
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Revenue",
        data: [],
        fill: true,
        backgroundColor: "rgba(66, 133, 244, 0.2)",
        borderColor: "#4285F4",
        tension: 0.4,
      },
    ],
  });

  useEffect(() => {
    if (data && data.graphData) {
      const labels = data.graphData.map((entry) => getDateAndMonth(entry.date));
      const dataValues = data.graphData.map((entry) => entry.revenue);

      // Check if data points exceed the maximum limit
      if (data.graphData.length > MAX_DATA_POINTS) {
        // Reduce data by showing every nth element
        const factor = Math.ceil(data.graphData.length / MAX_DATA_POINTS);
        const filteredLabels = labels.filter(
          (_, index) => index % factor === 0
        );
        const filteredDataValues = dataValues.filter(
          (_, index) => index % factor === 0
        );

        setChartData({
          labels: filteredLabels,
          datasets: [
            {
              label: "Revenue",
              data: filteredDataValues,
              fill: true,
              backgroundColor: "rgba(66, 133, 244, 0.2)",
              borderColor: "#4285F4",
              tension: 0.4,
            },
          ],
        });
      } else {
        // Use all data points if within the limit
        setChartData({
          labels: labels,
          datasets: [
            {
              label: "Revenue",
              data: dataValues,
              fill: true,
              backgroundColor: "rgba(66, 133, 244, 0.2)",
              borderColor: "#4285F4",
              tension: 0.4,
            },
          ],
        });
      }
    }
  }, [data]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      <Line
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false, // Hide the legend
            },
            tooltip: {
              callbacks: {
                label: (tooltipItem) => `₹${tooltipItem.raw.toLocaleString()}`,
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              grid: {
                display: false,
              },
              ticks: {
                callback: (value) => `₹${value.toLocaleString()}`,
              },
            },
          },
        }}
        style={{ height: "100%" }}
      />
    </div>
  );
};

export default RevenueInsights;
