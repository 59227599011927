import React from "react";
import PreviewVoucher from "../../Components/PreviewVoucher/PreviewVoucher";

const PreviewVoucherPage = () => {
  return (
    <>
      <div className="main_container">
        <PreviewVoucher />
      </div>
    </>
  );
};

export default PreviewVoucherPage;
