import {
  Button,
  ChoiceList,
  Collapsible,
  Icon,
  IndexTable,
  LegacyCard,
  LegacyStack,
  Page,
  useIndexResourceState,
  Tooltip,
} from "@shopify/polaris";
import React, { useRef, useState, useEffect } from "react";
import { websites_inner_lists } from "../../assets/constant";
import { useNavigate, Link, useParams, redirect } from "react-router-dom";
import SidebarInnerLists from "../CustomComponents/SidebarInnerLists";
import { useSelector } from "react-redux";
import { DeleteMajor } from "@shopify/polaris-icons";
import {
  BgOutlineBlackButton,
  BgThemeButton,
  CheckWithoutBgUnderlineBtn,
} from "../UI/Buttons";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import { getDecryptedData } from "../../utils/encryptStorage";

function formatDate(created_at) {
  const date = new Date(created_at);
  const options = {
    year: "2-digit", // 2-digit year (e.g., 23 for 2023)
    month: "short", // Short month name (e.g., Jul)
    day: "numeric", // Numeric day (e.g., 12)
    hour: "numeric", // Numeric hour (e.g., 4)
    minute: "2-digit", // 2-digit minute (e.g., 30)
    hour12: true, // Use 12-hour clock format
  };

  return date.toLocaleString("en-US", options);
}

const WebsiteMenuLocationComp = () => {
  const navigate = useNavigate();
  const property_id = useSelector((state) => state.property.value);
  const userToken = getDecryptedData('encryptToken');
  const [loader, setLoader] = useState(false);
  // fetch Data to show the  menu list on page load
  const [location, setLocation] = useState([]);

  const fetchInfo = async (propertyId) => {
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/menu/locations/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setLocation(data?.data);
      setLoader(false);
    } catch (error) {
      // setLoader(false);
      console.error(error);
    } finally{
      setLoader(false);
    }
  };

   useEffect(() => {
    if (property_id  && userToken)  {
      fetchInfo(property_id?.id);
    }
  }, [property_id, userToken]);

  //add menu items input

  const [addMenuInput, setAddMenuInput] = useState({
    location: "",
    property_id: "",
  });
  const handleAddLocationInputChange = (e) => {
    const { name, value } = e.target;
    setAddMenuInput({
      [name]: value,
      property_id: property_id?.id,
    });
  };

  const handleAddLocationClick = async () => {
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/store/menu/location/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(addMenuInput),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add location");
      }

      const data = await response.json();
      showSuccessToast("Location added successfully");
      fetchInfo(property_id?.id);
      setAddMenuInput({
        location: "",
        property_id: "",
      });
      setLoader(false);
    } catch (error) {
      console.error("Error adding location:", error.message);
      setLoader(false);
      
    }
  };

  const handleDeleteLocation = async (id) => {
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/delete/menu/location/${id}/${property_id.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(location),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add location");
      }

      const data = await response.json();
      showSuccessToast("Location deleted successfully");
      fetchInfo(property_id?.id);
    } catch (error) {
      console.error("Error adding location:", error.message);
      showErrorToast("An error occurred while processing your request.");
    } finally{
      setLoader(false);
    }
  };

  // edit popup
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editContent, setEditContent] = useState({});

  const handleLocationEdit = async (id) => {
    setShowEditPopup(true);
    setLoader(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/edit/menu/location/${id}/${property_id.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add location");
      }

      const data = await response.json();
      setEditContent(data?.data);
      setLoader(false);
    } catch (error) {
      console.error("Error adding location:", error.message);
    }finally{
      setLoader(false);
    }
  };


  const handleEditInputChange = (e) => {
    setEditContent({
      ...editContent,
      [e.target.name]: e.target.value,
    });
  };


  const handleUpdateButtonClick = async () => {
    setLoader(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/update/menu/location/${editContent.id}/${property_id.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(editContent),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add location");
      }

      const data = await response.json();
      showSuccessToast("Location updated successfully");
      setShowEditPopup(false);
      fetchInfo(property_id?.id);
      setEditContent({});
      setLoader(false);
    } catch (error) {
      console.error("Error adding location:", error.message);
    } finally{
      setLoader(false);
    }
  };

  const styles = {
    parentCard: {
      width: "500px",
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "10px",
      margin: "0 auto",
    },
    adMarginTop: {
      marginTop: "20px",
    },
  };
  return (
    <>
      <div className="room-types-full-container">
        <div className="room-types-full-container-div">
          <div className="room-type-heading">Locations</div>
        </div>

        <div style={styles.parentCard}>
          <div className="flex flex_gap_10 " style={{ alignItems: "end" }}>
            <div style={{ width: "70%" }}>
              <VerticalInputFieldCustom
                name="location"
                placeholder={"Enter Location Name"}
                onChange={(e) => handleAddLocationInputChange(e)}
                value={addMenuInput.location}
              />
            </div>

            <BgThemeButton
              children="Add Location"
              style={{ height: "35px" }}
              loader={loader}
              onClick={handleAddLocationClick}
            />
          </div>
        </div>

        <div style={{ ...styles.parentCard, ...styles.adMarginTop }}>
          {location && location.length > 0 ? (
            <>
              {location?.map((item, index) => (
                <div
                  key={index}
                  className="flex flex_gap_10 align_center "
                  style={{
                    borderTop: index > 0 ? ".5px solid #ccc" : "none",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p
                    className="sidebar-item-list"
                    style={{
                      fontSize: "16px",
                      textTransform: "capitalize",
                      fontWeight:"500",
                      width: "40%",
                      padding: "10px 0",
                    }}
                  >
                    {item.location}
                  </p>

                  <div className="flex flex_gap_10">
                    <div
                      className="flex align_center "
                      style={{ cursor: "pointer" }}
                      onClick={() => handleLocationEdit(item.id)}
                    >
                      <svg
                        width="18px"
                        height="18px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        stroke="#2f59cb"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          stroke-linejoin="round"
                        />

                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            fill-rule="evenodd"
                            clipRule="evenodd"
                            d="M20.8477 1.87868C19.6761 0.707109 17.7766 0.707105 16.605 1.87868L2.44744 16.0363C2.02864 16.4551 1.74317 16.9885 1.62702 17.5692L1.03995 20.5046C0.760062 21.904 1.9939 23.1379 3.39334 22.858L6.32868 22.2709C6.90945 22.1548 7.44285 21.8693 7.86165 21.4505L22.0192 7.29289C23.1908 6.12132 23.1908 4.22183 22.0192 3.05025L20.8477 1.87868ZM18.0192 3.29289C18.4098 2.90237 19.0429 2.90237 19.4335 3.29289L20.605 4.46447C20.9956 4.85499 20.9956 5.48815 20.605 5.87868L17.9334 8.55027L15.3477 5.96448L18.0192 3.29289ZM13.9334 7.3787L3.86165 17.4505C3.72205 17.5901 3.6269 17.7679 3.58818 17.9615L3.00111 20.8968L5.93645 20.3097C6.13004 20.271 6.30784 20.1759 6.44744 20.0363L16.5192 9.96448L13.9334 7.3787Z"
                            fill="#2f59cb"
                          />{" "}
                        </g>
                      </svg>
                    </div>

                    <div
                      className="flex align_center "
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDeleteLocation(item.id)}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clipRule="evenodd"
                          d="M11.2001 3.2H13.6001C14.0419 3.2 14.4001 3.55817 14.4001 4L14.4001 4.8H1.6001L1.60012 4C1.60012 3.55817 1.95829 3.2 2.40012 3.2H4.80012V1.2C4.80012 0.537258 5.33738 0 6.00012 0H10.0001C10.6629 0 11.2001 0.537259 11.2001 1.2V3.2ZM6.40012 1.6V3.2H9.60012V1.6H6.40012Z"
                          fill="#E03838"
                        />
                        <path
                          fill-rule="evenodd"
                          clipRule="evenodd"
                          d="M2.40012 6.4H13.6001V14.8C13.6001 15.4627 13.0629 16 12.4001 16H3.60012C2.93738 16 2.40012 15.4627 2.40012 14.8V6.4ZM5.6001 8.8H4.0001V13.6H5.6001V8.8ZM8.8001 8.8H7.2001V13.6H8.8001V8.8ZM10.4001 8.8H12.0001V13.6H10.4001V8.8Z"
                          fill="#E03838"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              <NoDataFoundSmall />
            </>
          )}
        </div>

        {showEditPopup && (
          <>
            <div className="global_popup_container_background">
              <div className="global_popup_container flex flex_gap_10">
                <div style={{ width: "70%" }}>
                  <VerticalInputFieldCustom
                    value={editContent?.location}
                    name={"location"}
                    onChange={(e) => handleEditInputChange(e, editContent?.id)}
                  />
                </div>

                <BgThemeButton
                  children={"Update"}
                  loader={loader}
                  onClick={handleUpdateButtonClick}
                />
                <BgOutlineBlackButton
                  children={"Cancel"}
                  onClick={() => setShowEditPopup(false)}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default WebsiteMenuLocationComp;
