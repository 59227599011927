import React, { useState } from "react";
import styles from "./multipleImageUploadComponent.module.scss";
import { FaPlus, FaCancelRounder } from "./icons/uploadIcons";

const MultipleImageUploadComponent = ({
  onFileChange = (data, name) => {
    console.log(data, name);
  },
}) => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const handleFileChange = (e) => {
    const uploadedFiles = Array.from(e.target.files); // Get all selected files

    setIsLoading(true); // Start loading

    const newFilesPromises = uploadedFiles.map((file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const fileData = { src: reader.result, name: file.name };
          resolve(fileData); // Resolve promise with file data
        };
        reader.readAsDataURL(file); // Read each file as data URL
      });
    });

    Promise.all(newFilesPromises).then((newFiles) => {
      setFiles((prevFiles) => [...prevFiles, ...newFiles]); // Append new files
      newFiles.forEach((fileData) => onFileChange(fileData.src, fileData.name));
      setIsLoading(false); // Stop loading
    });
  };

  const handleDelete = (index) => {
    setFiles((prevFiles) => {
      const newFiles = prevFiles.filter((_, i) => i !== index);
      newFiles.forEach((fileData) => onFileChange(fileData.src, fileData.name));
      return newFiles;
    });
  };

  return (
    <div className={styles.container}>
      {isLoading ? ( 
         <div className={styles.loadingContainer}>
         <div className={styles.dots}>
           <div className={styles.dot}></div>
           <div className={styles.dot}></div>
           <div className={styles.dot}></div>
         </div>
       
       </div>
      ) : (
        <>
          {files.length === 0 ? (
            <div className="flex flex_direction_col align_items_center ">
              <div className={styles.uploadContainer}>

                <label htmlFor="upload" className={styles.uploadLabel}>
                  <FaPlus className={styles.icon} />
                  <p>Upload</p>
                  <input
                    id="upload"
                    type="file"
                    accept={"image/jpeg, image/png"}
                    multiple
                    onChange={handleFileChange}
                    className={styles.uploadInput}
                  />
                </label>
              </div>
              <p className={styles.uploadingText}>
                {`or drop JPG, PNG file here`}
              </p>
            </div>
          ) : (
            <div className={styles.previewContainer}>
              {files.map((file, index) => (
                <div key={index} className={styles.previewItem}>
                  <div>
                    <img src={file.src} alt={file.name} className={styles.previewImage} />
                    <button
                      onClick={() => handleDelete(index)}
                      className={styles.deleteButton}
                    >
                      <FaCancelRounder />
                    </button>
                  </div>
                  <p className={styles.fileName}>{file.name}</p>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MultipleImageUploadComponent;