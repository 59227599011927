import React, { useState, useRef, useEffect, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomInput = forwardRef((props, ref) => (
  <div ref={ref} className="dateRangePicker">
    {/* Your custom input content */}
  </div>
));

const BookingDateRangeComponent = ({
  dataFromChild,
  defaultDate,
  disabled,
  minDate,
  maxDate,
}) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const datePickerRef = useRef(null);

  const handleDateChange = (update) => {
    setDateRange(update);
  };

  const handleContainerClick = () => {
    datePickerRef.current.setOpen(true);
  };
  const handleContainerClickDisabled = () => {
    datePickerRef.current.setOpen(false);
  };

  const [from_date, setFrom_date] = useState("");
  const [to_date, setTo_date] = useState("");  

  useEffect(() => {
    if (startDate && endDate) {
      const from_date = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')}`;
      const to_date = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(endDate.getDate()).padStart(2, '0')}`;

      setFrom_date(from_date);
      setTo_date(to_date);
      dataFromChild(from_date, to_date);
      datePickerRef.current.setOpen(false);
    }
  }, [endDate]);

  return (
    <>
      <label className="Create_booking_label">Select Date Range</label>
      <div
        style={{height:"27px",borderColor:"#ddd" ,position:"relative",cursor:"pointer"}}  
        className={!disabled ? "selectdateRange_container" : "selectdateRange_container disabled"}
        onClick={!disabled ? handleContainerClick : handleContainerClickDisabled}
      >
        <div className="selectdateRange_text">
          {startDate || endDate ? (
            `${from_date ? from_date : " "} : ${to_date ? to_date : ""}`
          ) : (
            <>{defaultDate ? defaultDate : <>Select Date Range</>}</>
          )}
        </div>
        <div style={{position:"absolute",bottom:"-100px",left:"-20px" ,background:"red"}}>
          <DatePicker
            className="SelectDateRangeInput"
            ref={datePickerRef}
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            customInput={<CustomInput />}
            popperPlacement="auto"
            maxDate={maxDate}
            minDate={minDate}
            style={{zIndex:"1000000000000000000000000000"}}
          />
        </div>
      </div>
    </>
  );
};

export default  BookingDateRangeComponent;