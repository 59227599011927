import React, { useEffect, useRef, useState } from "react";
import "./AgentHoldRoomsUpliftMenuButton.css";

const AgentHoldRoomsUpliftMenuButton = ({
  menuOptions,
  handleUpliftMenuClick,
  style,
  id
}) => {
  const containerRef = useRef(null);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsMenuVisible(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (e, option) => {
    e.stopPropagation();
    if (handleOptionClick) {
      if(id){
        handleUpliftMenuClick(option,id);
      }else{
        handleUpliftMenuClick(option);
      }
    }
    toggleMenu(e);
  };

  const toggleMenu = (e) => {
    setIsMenuVisible(!isMenuVisible);
    e.stopPropagation();
  };

  return (
    <div
      ref={containerRef}
      className="AgentHoldRoomsUpliftMenuButtonContainer"
      style={style}
    >
      <button
        onClick={(e) => toggleMenu(e)}
        style={{ cursor: "pointer", background: "transparent", border: "none" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
        >
          <path
            d="M6.99961 4.1999C6.22751 4.1999 5.59961 3.572 5.59961 2.7999C5.59961 2.0278 6.22751 1.3999 6.99961 1.3999C7.77171 1.3999 8.39961 2.0278 8.39961 2.7999C8.39961 3.572 7.77171 4.1999 6.99961 4.1999ZM6.99961 5.5999C7.77171 5.5999 8.39961 6.2278 8.39961 6.9999C8.39961 7.772 7.77171 8.3999 6.99961 8.3999C6.22751 8.3999 5.59961 7.772 5.59961 6.9999C5.59961 6.2278 6.22751 5.5999 6.99961 5.5999ZM6.99961 9.7999C7.77171 9.7999 8.39961 10.4278 8.39961 11.1999C8.39961 11.972 7.77171 12.5999 6.99961 12.5999C6.22751 12.5999 5.59961 11.972 5.59961 11.1999C5.59961 10.4278 6.22751 9.7999 6.99961 9.7999Z"
            fill="#666666"
          />
        </svg>
      </button>
      <ul
        className={`AgentHoldRoomsUpliftMenuButton-popup-menu-list ${
          isMenuVisible ? "visible" : ""
        }`}
      >
        {menuOptions?.map((option, index) => (
          <li
            key={index}
            className="AgentHoldRooms-popup-menu-item"
            onClick={(e) => handleOptionClick(e, option)}
            style={{
              color:
                option?.toLowerCase() === "delete" ||
                option?.toLowerCase() === "cancel"
                  ? "red"
                  : "",
            }}
          >
            {option}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AgentHoldRoomsUpliftMenuButton;
