// Polaris Imports
import {
  Button,
  HorizontalStack,
  Icon,
  IndexTable,
  Pagination,
  Tooltip,
  useIndexResourceState,
} from "@shopify/polaris";
import BarCharts from "./BarCharts";
import { PolarisVizProvider } from "@shopify/polaris-viz";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  CheckWithoutBgUnderlineBtn,
  WithoutBgButtonBlue,
} from "./UI/Buttons";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import CustomProgressBar from "./CustomComponents/CustomProgressBar";
import CustomToggleButton from "./UI/CustomToggleButton";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { Page, LegacyCard, DataTable } from "@shopify/polaris";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import { useSelector } from "react-redux";
import Loader from "./UI/Loader/Loader";
import { useNavigate } from "react-router-dom";
import CompetitorsAfter from "./CompetitorsAfter";
import SelectDateRangeWithoutInput from "./CustomComponents/SelectDateRangeWithoutInput/SelectDateRangeWithoutInput";
import ShowConfirmedArrivals from "./UI/ShowConfirmedArrivals";
import ShowHouseCheckIn from "./UI/ShowHouseCheckIn";
import ShowDepartureCheckOut from "./UI/ShowDepartureCheckOut";
import NoDataFound from "./CustomComponents/NoDataFound";
import NoDataFoundSmall from "./CustomComponents/NoDataFoundSmall";
import { current } from "@reduxjs/toolkit";
import LineChartDashboard from "./LineChartsDashboard";
import { getDecryptedData } from "../utils/encryptStorage";

Chart.register(ArcElement);

const Reports = () => {
  const currentDate = new Date().toISOString().split("T")[0];
  const [fetchedData, setFetchedData] = useState(null);
  const [openDayStatus, setOpenDayStatus] = useState();
  const [showDashBoardCheckIn, setShowDashBoardCheckIn] = useState(false);
  const [showDashBoardCheckOut, setShowDashBoardCheckOut] = useState(false);
  const [showDashboardAllCheckIn, setShowDashboardAllCheckIn] = useState(false);
  const [showManualSearchInput, setShowManualSearchInput] = useState(false);
  const [showWebInput, setShowWebInput] = useState(false);
  const [showBookingDetails, setShowBookingDetails] = useState(false);

  const [showCheckOutBookingDetails, setShowCheckOutBookingDetails] =
    useState(false);
  const [
    showCheckOutIdClickBookingDetails,
    setShowCheckOutIdClickBookingDetails,
  ] = useState(false);

  const [settlementDoneCheckOut, setSettlementDoneCheckOut] = useState(false);

  const [showCheckOutSettlementDetails, setShowCheckOutSettlementDetails] =
    useState(false);
  const [openBookingDetails, setOpenBookingDetails] = useState(true);
  const userToken = getDecryptedData('encryptToken');

  const [bookingDetails, setBookingDetails] = useState([]);
  const [allSettlementsData, setAllSettlementsData] = useState([]);

  // show click arrow buttons
  const [showArrivalConfirmed, setShowArrivalConfirmed] = useState(false);
  const [showAllSettlement, setShowAllSettlement] = useState(false);
  const [showInHouseCheckIn, setShowInHouseCheckIn] = useState(false);
  const [showDepartureCheckOut, setShowDepartureCheckOut] = useState(false);

  const [checkOutBookingDetail, setCheckOutBookingDetail] = useState([]);

  const property_id = useSelector((state) => state.property.value);

  const [dayTypeFilter, setDayTypeFilter] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [valueArray, setValueArray] = useState([]);
  const accordRef = useRef(null);

  const toggleAccord = () => {
    setIsOpen(!isOpen);
    setIsOpen1(false);
  };
  const toggleAccord1 = () => {
    setIsOpen(false);
    setIsOpen1(!isOpen1);
  };

  const permissions = JSON.parse(localStorage.getItem("permissions"));

  // Reservations
  const dateOptions = [
    { id: 0, value: "-1", label: "Today" },
    { id: 0, value: "-2", label: "Yesterday" },
    { id: 1, value: "-7", label: "Past 7 Days" },
    { id: 2, value: "-30", label: "Past 30 Days" },
    { id: 3, value: "-90", label: "Past 90 Days" },
    { id: 4, value: "-1", label: "Last Year" },
    { id: 5, value: "-3", label: "Last 3 year" },
  ];
  // Reservations
  const dateOptions1 = [
    { id: 0, value: "revenue", label: "Revenue" },
    { id: 1, value: "profit", label: "Profit" },
    { id: 2, value: "reservation", label: "Reservation" },
    { id: 3, value: "expense", label: "Expense" },
    { id: 4, value: "event", label: "Event" },
    { id: 5, value: "f&b", label: "F&B" },
  ];

  const handleAccordClick = (id, label, value) => {
    setValueArray([{ id: id, label: label, value: value }]);
    setDayTypeFilter(value);
  };

  const handleShowDashBoardCheckIn = (e) => {
    e.stopPropagation();
    setShowDashBoardCheckIn(!showDashBoardCheckIn);
  };

  const handleShowDashBoardCheckOut = (e) => {
    e.stopPropagation();
    setShowDashBoardCheckOut(!showDashBoardCheckOut);
  };

  const handleShowManualSearchInput = () => {
    {
      showWebInput === true && setShowWebInput(false);
    }
    setShowManualSearchInput(!showManualSearchInput);
  };

  const handleShowWebInput = () => {
    {
      showManualSearchInput === true && setShowManualSearchInput(false);
    }
    setShowWebInput(!showWebInput);
  };

  const [bookingOpenIndex, setOpenBookingIndex] = useState(1);
  const [bookingAccordianActive, setBookingAccordianActive] = useState("");

  const handleOpenBookigDetails = (idx) => {
    setOpenBookingIndex(idx);
    if (bookingOpenIndex === idx) {
      setBookingAccordianActive("active");
    } else {
      setBookingAccordianActive("");
    }
  };

  const handleShowDashBoardAllCheckIn = () => {
    setShowDashboardAllCheckIn(!showDashboardAllCheckIn);
  };

  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const fetchDashboardInfo = async (propertyId, dayTypeFilter) => {
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/dashboard/${property_id?.id}?dayTypeFilter=${dayTypeFilter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (!response.ok) {
        setLoader(false);
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      if (data.message === "Unauthenticated.") {
        navigate("/login");
      }
      setLoader(false);
      setFetchedData(data);
    } catch (error) {
      if (error === "Unauthenticated.") {
        navigate("/login");
      }
      setLoader(false);
      console.error(error);
    }
  };

  const [selectedDatesRange, setSelectedDatesRange] = useState({
    from_date: "",
    to_date: "",
  });

  const dataFromChild = (from_date, to_date) => {
    setSelectedDatesRange({
      from_date: from_date,
      to_date: to_date,
    });
  };

  useEffect(() => {
    if (property_id) {
      fetchDashboardInfo(property_id.id, dayTypeFilter);
    }
  }, [property_id, dayTypeFilter]);

  const openDayStatusHandler = useCallback((data) => {
    setOpenDayStatus(data);
  }, []);

  const chart_data = {
    datasets: [
      {
        data: [fetchedData?.total_revenue, fetchedData?.foodSale, 0],
        backgroundColor: ["#3968ED", "#15AA12", "#CCCCCC"],
        display: true,
        // borderColor: "#D1D6DC"
      },
    ],
  };

  // const BottomTableRows = [
  //   ["Golden Fern Resort", 4500, 4500, 4500, 4500, 4500, 4500],
  //   ["Hotel Snow Castle", 4500, 4500, 4500, 4500, 4500, 4500],
  //   ["Hotel Woodrina", 4500, 4500, 4500, 4500, 4500, 4500],
  //   ["Hill Top Resort", 4500, 4500, 4500, 4500, 4500, 4500],
  //   ["Hotel Bobs N Barley", 4500, 4500, 4500, 4500, 4500, 4500],
  // ];

  const [bookingId, setBookingId] = useState("");

  const [openAccordion, setOpenAccordion] = useState("first");

  // UseState for forms
  const [settlementAddData, setSettlementAddData] = useState({
    settlement_name: "",
    payment_mode: "",
    notes: "",
    date: "",
    amount: "",
    property_id: property_id?.id,
    other_payment_mode: "",
  });

  const paymentModeOptions = [
    { label: "Select Option" },
    { label: "Cash", value: "cash" },
    { label: "Bank Payment", value: "bank_payment" },
    { label: "UPI", value: "upi" },
    { label: "Credit Card", value: "credit_card" },
    { label: "Debit Card", value: "debit_card" },
    { label: "Other", value: "other" },
  ];

  useEffect(() => {
    document.title = "RevCatalyst - Boosting Your Hotel Performance";

    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property.";
  }, []);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(allSettlementsData);

  const addAllSettlementsRowMarkup = allSettlementsData?.map(
    ({ id, notes, amount, payment_mode, date, index }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>{notes}</IndexTable.Cell>
        <IndexTable.Cell>{date}</IndexTable.Cell>
        <IndexTable.Cell>{payment_mode}</IndexTable.Cell>
        <IndexTable.Cell>{amount}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const otherMenusRef = useRef(null);
  const handleClickOutside = (event) => {
    if (
      otherMenusRef.current &&
      !otherMenusRef.current.contains(event.target)
    ) {
      setIsOpen(false);
      setIsOpen1(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <section className="dashboard_page">
            <div style={{ width: "100%" }}>
              {/* <div
                          >
                            <SelectDateRangeWithoutInput
                              dataFromChild={dataFromChild}
                            />
                          </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "15px 0",
                }}
              >
                <div
                  style={{ fontSize: "20px" }}
                  className="report-chart-heading"
                >
                  Reports
                </div>
                <div ref={otherMenusRef} className="flex flex_gap_10">
                  <div
                    ref={accordRef}
                    onClick={toggleAccord}
                    style={{
                      display: "flex",
                      padding: "10px",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "fit-content",
                      position: "relative",
                      cursor: "pointer",
                      border: "0.5px solid #CCC",
                      gap: "10px",
                      borderRadius: "5px",
                      fontSize: "14px",
                      height: "30px",
                    }}
                  >
                    <div style={{ borderRadius: "5px" }} className="">
                      {valueArray.length > 0 ? valueArray[0].label : "Today"}
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      className={`${isOpen ? "accordImageRotate" : null} `}
                    >
                      <path d="M1 1L5 5L9 1" stroke="#333333" />
                    </svg>
                    {isOpen && (
                      <div
                        style={{
                          position: "absolute",
                          border: "1px #3333332d solid",
                          left: "-5px",
                          top: "45px",
                          width: "190%",
                          height: "fit-content",
                          zIndex: 999,
                        }}
                        className="accord_container"
                      >
                        {dateOptions.map((qwer) => (
                          <>
                            <div
                              key={qwer.id}
                              className="accord_container_values"
                              onClick={() =>
                                handleAccordClick(
                                  qwer.id,
                                  qwer.label,
                                  qwer.value
                                )
                              }
                            >
                              {qwer.label}
                            </div>
                          </>
                        ))}
                        {/* <BgThemeButton
                                  onClick={() => customDateClickHandler()}
                                >
                                  Custom
                                </BgThemeButton> */}
                      </div>
                    )}
                  </div>
                  <div
                    ref={accordRef}
                    onClick={toggleAccord1}
                    style={{
                      display: "flex",
                      padding: "10px",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "fit-content",
                      position: "relative",
                      cursor: "pointer",
                      border: "0.5px solid #CCC",
                      gap: "10px",
                      borderRadius: "5px",
                      fontSize: "14px",
                      height: "30px",
                    }}
                  >
                    <div style={{ borderRadius: "5px" }} className="">
                      {valueArray.length > 0
                        ? valueArray[0].label
                        : "Reservations"}
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      className={`${isOpen ? "accordImageRotate" : null} `}
                    >
                      <path d="M1 1L5 5L9 1" stroke="#333333" />
                    </svg>
                    {isOpen1 && (
                      <div
                        style={{
                          position: "absolute",
                          border: "1px #3333332d solid",
                          left: "-5px",
                          top: "45px",
                          width: "190%",
                          height: "fit-content",
                          zIndex: 999,
                        }}
                        className="accord_container"
                      >
                        {dateOptions1.map((qwer) => (
                          <>
                            <div
                              key={qwer.id}
                              className="accord_container_values"
                              onClick={() =>
                                handleAccordClick(
                                  qwer.id,
                                  qwer.label,
                                  qwer.value
                                )
                              }
                            >
                              {qwer.label}
                            </div>
                          </>
                        ))}
                        {/* <BgThemeButton
                                  onClick={() => customDateClickHandler()}
                                >
                                  Custom
                                </BgThemeButton> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* <div className="flex flex_gap_10"></div> */}

              {/* <div className="checkin_rooms_status"> */}
              <BarCharts loader={loader} />
              {/* <LineChartDashboard loader={loader} /> */}
              {/* </div> */}
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Reports;
