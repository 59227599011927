import React from "react";
import Sidebar from "../Components/Sidebar";
import Agent from "../Components/Agent";
import Agent2 from "../Components/Agent2";
import NewAgents from "../Components/Agents/NewAgents";
import AgentsPayments from "../Components/AgentsPayments";


const AgentPaymentsPage = () => {
  return (
    <>
      <div className="main_container">
        <AgentsPayments />
      </div>
    </>
  );
};

export default AgentPaymentsPage;
