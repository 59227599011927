import React from "react";
import EditEvent from "../Components/EditEvent";

const EditEventPage = () => {
  return (
    <>
      <div className="main_container">
        <EditEvent />
      </div>
    </>
  );
};

export default EditEventPage;
