import React from "react";
import KotBill from "../Components/KotBill";
import EventBill from "../Components/EventBill";

const EventsBillPage = () => {
  return (
    <>
      <div className="main_container">
        <EventBill />
      </div>
    </>
  );
};

export default EventsBillPage;
