import { useState, useEffect } from "react";
import "./bookingstatusfilter.css";
import { CheckWithoutBgUnderlineBtn } from "../../UI/Buttons";

const BookingStatusFilterRadio = ({
  title,
  bookingFIlterData,
  getSelectedBookingFiltersFromChild,
  defaultSelect,
  clearAllFilters,
}) => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([defaultSelect]);

  useEffect(() => {
    getSelectedBookingFiltersFromChild(selectedCheckboxes);
  }, [selectedCheckboxes, getSelectedBookingFiltersFromChild]);

  const handleCheckboxChange = (value) => {
    if (selectedCheckboxes.includes(value)) {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((item) => item !== value)
      );
    } else {
      setSelectedCheckboxes([value]);
    }
  };

  const clearAllHandler = () => {
    setSelectedCheckboxes([]);
  };

  return (
    <div
      className="booking_status_parent"
      style={{
        width: "100%",
        padding: "15px",
        borderBottom: "0.5px solid #E5E5E5",
      }}
    >
      <div className="booking_status_content">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
            width: "100%",
          }}
        >
          <div className="booking_filter_title">{title}</div>
          <CheckWithoutBgUnderlineBtn
          type="button"
            style={{ fontSize: "11px" }}
            onClick={() => setSelectedCheckboxes([])}
          >
            Clear All
          </CheckWithoutBgUnderlineBtn>
        </div>
        {bookingFIlterData.map((item, index) => (
          <div key={index}>
            <label className="checkbox_label">
              <input
                type="radio"
                value={item.value}
                checked={selectedCheckboxes[0] === item.value}
                onChange={() => handleCheckboxChange(item.value)}
              />
              {item.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
export default BookingStatusFilterRadio;
