import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
} from "./UI/Buttons";
import Loader from "./UI/Loader/Loader";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import { Page } from "@shopify/polaris";
import SelectDateRange from "./CustomComponents/SelectDateRange/SelectDateRange";
import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import NoDataFound from "./CustomComponents/NoDataFound";
import TextDateFormatter from "./Communication/TextDateFormatter/TextDateFormatter";
import GoBackButtonCustom from "./GoBackButtonCustom/GoBackButtonCustom";
import { calculateToDatePlus, getActiveApp, getBaseUrl } from "../utils/utils";
import { SelectBasic } from "./NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import { getDecryptedData } from "../utils/encryptStorage";

const LowRateInventory = () => {
  const baseURL = getBaseUrl();
  const activeApp = getActiveApp();
  const [purchaseHeadingActiveIndex, setPurchaseHeadingActiveIndex] =
    useState();
  const [purchaseHeadingActive, setPurchaseHeadingActive] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);

  const setPurchaseHeading = (idx, param) => {
    setPurchaseHeadingActiveIndex(idx);
    setFillingState(param);

    // fetchInfo();
    if (purchaseHeadingActive === idx) {
      setPurchaseHeadingActive("active");
    } else {
      setPurchaseHeadingActive("");
    }
  };
  const [data, setData] = useState([]);
  const [roomsData, setRoomsData] = useState([]);
  const [totalRooms, seTotalRooms] = useState([]);
  const [loader, setLoader] = useState(true);

  const [fillingState, setFillingState] = useState("fast_filling");
  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);

  const [roomId, setRoomId] = useState("");

  const [roomTypeData, setRoomTypeData] = useState([]);
  const [firstRoomId, setFirstRoomId] = useState([]);
  const [propertyDiscountData, setPropertyDiscountData] = useState(null);
  const [roomTypeRateRoomData, setRoomTypeRateRoomData] = useState([]);

  useEffect(() => {
    setRoomId(firstRoomId);
  }, [firstRoomId]);

  const fetchRoomType = async (id) => {
    try {
      const response = await fetch(`${baseURL}/api/v1/rooms/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json();
      setRoomTypeData(data.rooms);
      setFirstRoomId(data.rooms[0].id);
      setPurchaseHeadingActiveIndex(data.rooms[0].id);
      if (property_id) {
        fetchInfo(data.rooms[0].id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchInfoDefault = async (id) => {
    setButtonLoader(true);
    try {
      // setLoader(true);
      const response = await fetch(
        // /api/v1/inventories/store/1
        `${baseURL}/api/v1/inventories/${property_id?.id}?inventoryType=low_inventory_rates&start_date=${startDate}&end_date=${endDate}&room_id=${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json().then((data) => {
        if (data.success) {
          setLoader(false);
          setButtonLoader(false);
          setData(data.soldInventory);
          setRoomsData(data.rooms);
          seTotalRooms(data.total_rooms);
          // navigate("/inventory");
        } else {
          showErrorToast(data.message);
          setLoader(false);
          setButtonLoader(false);
        }
      });
    } catch (error) {
      console.error("An error occurred:", error);
      // Ensure the loader is set to false in case of an error
      setLoader(false);
      setButtonLoader(false);
    }
  };

  const fetchInfo = async (id) => {
    try {
      setLoader(true);

      const response = await fetch(
        // /api/v1/inventories/store/1
        `${baseURL}/api/v1/inventories/${property_id?.id}?inventoryType=low_inventory_rates&start_date=${startDate}&end_date=${endDate}&room_id=${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json().then((data) => {
        if (data.success) {
          setLoader(false);
          setData(data.soldInventory);
          setRoomsData(data.rooms);
          seTotalRooms(data.total_rooms);
        } else {
          showErrorToast(data.message);
          setLoader(false);
        }
      });
    } catch (error) {
      console.error("An error occurred:", error);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchRoomType(property_id.id);
    }
  }, [property_id]);

  // useEffect(() => {
  //   if (property_id) {
  //     fetchInfo();
  //   }
  // }, [property_id]);

  const currentDate = new Date().toISOString().split("T")[0];

  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(calculateToDatePlus(currentDate, 30));
  const calculateToDate = (fromDate) => {
    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() - 30);
    return toDate.toISOString().split("T")[0];
  };

  const [selectedDatesRange, setSelectedDatesRange] = useState({
    from_date: calculateToDate(currentDate),
    to_date: currentDate,
  });
  const [comparisonStartDateRange, setComparisonStartDateRange] = useState({
    from_date: calculateToDate(currentDate),
    to_date: currentDate,
  });
  const [comparisonEndDateRange, setComparisonEndDateRange] = useState({
    from_date: calculateToDate(currentDate),
    to_date: currentDate,
  });

  const dataFromChild = (from_date, to_date) => {
    setSelectedDatesRange({
      from_date: from_date,
      to_date: to_date,
    });
  };

  const [inventoryRoomOpen, setInventoryRoomOpen] = useState(false);
  const [inventoryRoomOpenIdx, setInventoryRoomOpenIdx] = useState(1);
  const accordionOpenInventory = (idx) => {
    setInventoryRoomOpenIdx(idx);
    if (inventoryRoomOpenIdx === idx) {
      setInventoryRoomOpen(false);
    } else {
      setInventoryRoomOpen(false);
    }
  };

  const [groupedData, setGroupedData] = useState([]);

  useEffect(() => {
    if (activeApp !== "channel_manager") {
      const groupedData =
        data &&
        data?.length > 0 &&
        data?.reduce((acc, item) => {
          const month = new Date(item.date).toLocaleString("default", {
            month: "long",
          });
          const existingMonth = acc.find((group) => group.month === month);

          if (existingMonth) {
            existingMonth.data.push(item);
          } else {
            acc.push({ month, data: [item] });
          }

          return acc;
        }, []);
      setGroupedData(groupedData);
    }
  }, [data]);

  const navigate = useNavigate();

 
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {/* {renderedData && renderedData.length > 0 ? ( */}
          <div className="room-types-full-container ">
            <GoBackButtonCustom
              buttonNextText={"Low Rate Inventory"}
              onClick={() => navigate(-1)}
            />
            <div className="room-types-full-container-div">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ marginRight: "10px" }}></div>
                <div></div>
              </div>
            </div>

            <div className="inventory-full-container p_top_0">
              <div
                style={{
                  borderBottom: "1px solid #ccc",
                  padding: "10px 0 10px 0",
                  display: "flex",
                  gap: "14px",
                  // justifyContent: "space-between",
                  alignItems: "center",
                  position: "sticky",
                  top: "60px",
                  background: "#fff",
                  // zIndex: "99",
                }}
              >
                <div className="inventory-first-row-head">
                  <div>
                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          required={true}
                          type="date"
                          name="from_date"
                          className="textfield"
                          titleName="Start Date"
                          // min={currentDate}
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </div>
                      <div style={{}} className="form-container-div">
                        <VerticalInputFieldCustom
                          required={true}
                          type="date"
                          name="to_date"
                          className="textfield"
                          titleName="End Date"
                          focused={false}
                          min={startDate}
                          autoComplete="off"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    gap: "10px",
                  }}
                >
                  <BgThemeButton
                    onClick={() => fetchInfoDefault(roomId)}
                    type="submit"
                    children={"Apply"}
                    loader={buttonLoader}
                  />

                  {/* <Button onClick={() => navigate("/inventory")}>Cancel</Button> */}
                </div>
              </div>
              {data && data.length > 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      style={{
                        width: "30%",
                        position: "sticky",
                        top: "154px",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                          marginBottom: "10px",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        Room Categories:
                      </div>

                      <div
                        style={{
                          width: "80%",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        {roomTypeData && roomTypeData.length > 0 ? (
                          <>
                            {roomTypeData.map((room, index) => {
                              return (
                                <div
                                  onClick={(e) => [
                                    setRoomId(room.id),
                                    fetchInfoDefault(room.id),
                                    setPurchaseHeadingActiveIndex(room.id),
                                  ]}
                                  key={index}
                                  className={`room-div-box ${
                                    purchaseHeadingActiveIndex === room.id
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <span>{room.room_name} </span>
                                  <span>
                                    <svg
                                      class="MuiSvgIcon-root"
                                      focusable="false"
                                      viewBox="0 0 24 24"
                                      aria-hidden="true"
                                    >
                                      <path d="M10 16.5l6-4.5-6-4.5v9zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
                                    </svg>
                                  </span>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <>o</>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "70%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "16px",
                          marginBottom: "20px",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "16px",
                            fontSize: "14px",
                            fontWeight: 600,
                          }}
                        >
                          <div>
                            <span style={{ marginRight: "5px" }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="8"
                                height="8"
                                viewBox="0 0 8 8"
                                fill="none"
                              >
                                <circle cx="4" cy="4" r="4" fill="#15AA12" />
                              </svg>
                            </span>
                            Available
                          </div>
                          <div>
                            <span style={{ marginRight: "5px" }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="8"
                                height="8"
                                viewBox="0 0 8 8"
                                fill="none"
                              >
                                <circle cx="4" cy="4" r="4" fill="#FFEA00" />
                              </svg>
                            </span>
                            Fast Filling
                          </div>
                          <div>
                            <span style={{ marginRight: "5px" }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="8"
                                height="8"
                                viewBox="0 0 8 8"
                                fill="none"
                              >
                                <circle cx="4" cy="4" r="4" fill="#FFA500" />
                              </svg>
                            </span>
                            Almost Sold Out
                          </div>
                          <div>
                            <span style={{ marginRight: "5px" }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="8"
                                height="8"
                                viewBox="0 0 8 8"
                                fill="none"
                              >
                                <circle cx="4" cy="4" r="4" fill="#E03838" />
                              </svg>
                            </span>
                            Sold Out
                          </div>
                        </div>
                        <div>
                          Total Rooms : <strong>{totalRooms}</strong>
                        </div>
                      </div>
                      {groupedData &&
                        groupedData.length > 0 &&
                        groupedData?.map((item) => {
                          return (
                            <div
                              style={{
                                borderBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  padding: "16px",
                                  display: "flex",
                                  fontWeight: 700,
                                  borderBottom: "10px",
                                }}
                              >
                                {item.month}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  border: "1px solid #ececec",
                                }}
                              >
                                {item.data.map((innerData) => {
                                  return (
                                    <div className="low_inventory_boxes">
                                      <div
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <TextDateFormatter
                                          as={`div`}
                                          date={innerData.date}
                                          showYear={true}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            color:
                                              innerData.status === "available"
                                                ? "#15AA12"
                                                : innerData.status === "sold"
                                                ? "#E03838"
                                                : innerData.status ===
                                                  "almost_sold"
                                                ? "#FFA500"
                                                : innerData.status ===
                                                  "fast_filling"
                                                ? "#FFEA00"
                                                : "",
                                            width: "40px",
                                            padding: "8px 10px",
                                            height: "30px",
                                            border: "1px solid #909090",
                                            borderRadius: "5px",
                                            marginBottom: "10px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            fontSize: "21px",
                                            fontWeight: 500,
                                          }}
                                        >
                                          {innerData.available_room}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          textAlign: "center",
                                          color: "#909090",
                                        }}
                                      >
                                        {innerData.sold_room} sold
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </>
              ) : // for  channel manager
              typeof data === "object" ? (
                <>
                  <>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        marginTop: "20px",
                      }}
                    >
                      <div
                        style={{
                          width: "30%",
                          position: "sticky",
                          top: "154px",
                          height: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "20px",
                            marginBottom: "10px",
                            fontSize: "14px",
                            fontWeight: 600,
                          }}
                        >
                          Room Categories:
                        </div>
                        <div
                          style={{
                            width: "80%",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          {roomTypeData && roomTypeData.length > 0 ? (
                            <>
                              {roomTypeData.map((room, index) => {
                                return (
                                  <div
                                    onClick={(e) => [
                                      setRoomId(room.id),
                                      fetchInfoDefault(room.id),
                                      setPurchaseHeadingActiveIndex(room.id),
                                    ]}
                                    key={index}
                                    className={`room-div-box ${
                                      purchaseHeadingActiveIndex === room.id
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <span>{room.room_name} </span>
                                    <span>
                                      <svg
                                        class="MuiSvgIcon-root"
                                        focusable="false"
                                        viewBox="0 0 24 24"
                                        aria-hidden="true"
                                      >
                                        <path d="M10 16.5l6-4.5-6-4.5v9zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
                                      </svg>
                                    </span>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <>o</>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "70%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "16px",
                            marginBottom: "20px",
                            fontSize: "14px",
                            fontWeight: 600,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "16px",
                              fontSize: "14px",
                              fontWeight: 600,
                            }}
                          >
                            <div>
                              <span style={{ marginRight: "5px" }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="8"
                                  height="8"
                                  viewBox="0 0 8 8"
                                  fill="none"
                                >
                                  <circle cx="4" cy="4" r="4" fill="#15AA12" />
                                </svg>
                              </span>
                              Available
                            </div>
                            <div>
                              <span style={{ marginRight: "5px" }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="8"
                                  height="8"
                                  viewBox="0 0 8 8"
                                  fill="none"
                                >
                                  <circle cx="4" cy="4" r="4" fill="#FFEA00" />
                                </svg>
                              </span>
                              Fast Filling
                            </div>
                            <div>
                              <span style={{ marginRight: "5px" }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="8"
                                  height="8"
                                  viewBox="0 0 8 8"
                                  fill="none"
                                >
                                  <circle cx="4" cy="4" r="4" fill="#FFA500" />
                                </svg>
                              </span>
                              Almost Sold Out
                            </div>
                            <div>
                              <span style={{ marginRight: "5px" }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="8"
                                  height="8"
                                  viewBox="0 0 8 8"
                                  fill="none"
                                >
                                  <circle cx="4" cy="4" r="4" fill="#E03838" />
                                </svg>
                              </span>
                              Sold Out
                            </div>
                          </div>
                          <div>
                            Total Rooms : <strong>{totalRooms}</strong>
                          </div>
                        </div>

                        {
                          // Assuming `groupedData` is your JSON data
                          data &&
                            Object.keys(data).length > 0 &&
                            Object.keys(data).map((property) => {
                              const propertyData = data[property];

                              return (
                                <div
                                  key={property}
                                  style={{
                                    borderBottom: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      backgroundColor: "#f4f4f4",
                                      padding: "16px",
                                      display: "flex",
                                      fontWeight: 700,
                                      borderBottom: "10px",
                                    }}
                                  >
                                    {property}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      border: "1px solid #ececec",
                                    }}
                                  >
                                    {propertyData.map((innerData) => {
                                      return (
                                        <div
                                          className="low_inventory_boxes"
                                          key={innerData.date}
                                        >
                                          <div
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "500",
                                              marginBottom: "10px",
                                              textAlign: "center"
                                            }}
                                          >
                                            <TextDateFormatter
                                              as={`div`}
                                              date={innerData.date}
                                              showYear={true}
                                            />
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                color:
                                                  innerData.status ===
                                                  "available"
                                                    ? "#15AA12"
                                                    : innerData.status ===
                                                      "sold"
                                                    ? "#E03838"
                                                    : innerData.status ===
                                                      "almost_sold"
                                                    ? "#FFA500"
                                                    : innerData.status ===
                                                      "fast_filling"
                                                    ? "#FFEA00"
                                                    : "",
                                                width: "40px",
                                                padding: "8px 10px",
                                                height: "30px",
                                                border: "1px solid #909090",
                                                borderRadius: "5px",
                                                marginBottom: "10px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontSize: "21px",
                                                fontWeight: 500,
                                              }}
                                            >
                                              {innerData.available_room}
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              textAlign: "center",
                                              color: "#909090",
                                            }}
                                          >
                                            {innerData.sold_room} sold
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              );
                            })
                        }
                      </div>
                    </div>
                  </>
                </>
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LowRateInventory;
