import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import { formatIndianCurrency } from "../../utils/utils";
import LoaderSmall from "../UI/Loader/LoaderSmall";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import { current } from "@reduxjs/toolkit";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { useState } from "react";
import { SvgGetInfo } from "../../assets/svgIcons";
const ReportPaymentDailyRevenue = ({ departureReport, loader }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [informationPopup, setInformationPopup] = useState(false);

  const food_revenue = [
    {
      unique_booking_id: "booking_id",
      guest_name: "Name",
      check_in: "Arrival Date",
      check_out: "Departure Date",
      booking_source: "Booking Source",
      grand_total: "Grand Total",
      paid_amount: "Paid Amount",
      grand_total: "Balance",
      status: "Status",
    },
  ];
  const rowMarkup =
    departureReport &&
    departureReport.length > 0 &&
    departureReport?.map((order) => (
      <IndexTable.Row id={order.id} key={order.id} position={order.index}>
        <IndexTable.Cell>{order.date}</IndexTable.Cell>
        <IndexTable.Cell>
          <UnderscoreRemoveCapitalize text={order.payment_mode} />{" "}
        </IndexTable.Cell>
        <IndexTable.Cell>{order.settlement_amount}</IndexTable.Cell>
      </IndexTable.Row>
    ));

  const extractedData = [
    {
      name: "Revenue",
      current_date_amount: departureReport.revenue
        ? departureReport.revenue.current_date_amount
        : null,
      mtd_amount: departureReport.revenue
        ? departureReport.revenue.mtd_amount
        : null,
      ytd_amount: departureReport.revenue
        ? departureReport.revenue.ytd_amount
        : null,
    },
    {
      name: "Revenue Tax",
      current_date_amount: departureReport.revenue_tax
        ? departureReport.revenue_tax.current_date_amount
        : null,
      mtd_amount: departureReport.revenue_tax
        ? departureReport.revenue_tax.mtd_amount
        : null,
      ytd_amount: departureReport.revenue_tax
        ? departureReport.revenue_tax.ytd_amount
        : null,
    },
    {
      name: "Food Revenue",
      current_date_amount: departureReport.food_revenue
        ? departureReport.food_revenue.current_date_amount
        : null,
      mtd_amount: departureReport.food_revenue
        ? departureReport.food_revenue.mtd_amount
        : null,
      ytd_amount: departureReport.food_revenue
        ? departureReport.food_revenue.ytd_amount
        : null,
    },
    {
      name: "Food Revenue Tax",
      current_date_amount: departureReport.food_revenue_tax
        ? departureReport.food_revenue_tax.current_date_amount
        : null,
      mtd_amount: departureReport.food_revenue_tax
        ? departureReport.food_revenue_tax.mtd_amount
        : null,
      ytd_amount: departureReport.food_revenue_tax
        ? departureReport.food_revenue_tax.ytd_amount
        : null,
    },
  ];

  const names = [
    {
      name: "Name",
      current_date_amount: "Current Date Amount",
      mtd_amount: "Mtd Amount",
      ytd_amount: "Ytd Amount",
    },
  ];
  return (
    <div
      id="receipt"
      className="room-types-full-container reports-new-background"
    >
      <div className="room-types-full-container-div">
        <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
          <div className="room-type-heading">Daily Revenue Report</div>
          <div
            className="hoverCursor"
            onClick={() => setInformationPopup(true)}
            id="ignoreFromPrint"
          >
            <SvgGetInfo />
          </div>
        </div>
        {departureReport && (
          <div className="flex flex_gap_15" id="ignoreFromPrint">
            <ButtonToDownloadTablePDF
              itemToDownloadID={"receipt"}
              downloadFileName={"No Show Report"}
              ignoreFromPrint={"ignoreFromPrint"}
              pageFormat={"A3"}
            />
            <DownloadCsvTables tableData={extractedData} names={names} />
          </div>
        )}
      </div>
      {loader ? (
        <LoaderSmall />
      ) : (
        <>
          {departureReport &&
          departureReport.revenue &&
          departureReport?.revenue_tax &&
          departureReport?.food_revenue_tax ? (
            <CustomReportTable
              headings={["Title", "Current Date Amount", "MTD", "YTD"]}
              headerStyles={{
                backgroundColor: "#dddddd",
                fontSize: "12px",
                // textAlign: "center",
              }}
              tableStyles={{ textAlign: "left" }}
            >
              <CustomReportTableRow CustomReportTableRow>
                <CustomReportTableCell>Revenue</CustomReportTableCell>
                <CustomReportTableCell>
                  {formatIndianCurrency(
                    departureReport?.revenue?.current_date_amount
                  )}
                </CustomReportTableCell>
                <CustomReportTableCell>
                  {formatIndianCurrency(departureReport?.revenue?.mtd_amount)}
                </CustomReportTableCell>
                <CustomReportTableCell>
                  {formatIndianCurrency(departureReport?.revenue?.ytd_amount)}
                </CustomReportTableCell>
              </CustomReportTableRow>

              <CustomReportTableRow CustomReportTableRow>
                <CustomReportTableCell>Revenue Tax</CustomReportTableCell>
                <CustomReportTableCell>
                  {formatIndianCurrency(
                    departureReport?.revenue_tax?.current_date_amount
                  )}
                </CustomReportTableCell>
                <CustomReportTableCell>
                  {formatIndianCurrency(
                    departureReport?.revenue_tax?.mtd_amount
                  )}
                </CustomReportTableCell>
                <CustomReportTableCell>
                  {formatIndianCurrency(
                    departureReport?.revenue_tax?.ytd_amount
                  )}
                </CustomReportTableCell>
              </CustomReportTableRow>

              <CustomReportTableRow CustomReportTableRow>
                <CustomReportTableCell>Food Revenue</CustomReportTableCell>
                <CustomReportTableCell>
                  {formatIndianCurrency(
                    departureReport?.food_revenue?.current_date_amount
                  )}
                </CustomReportTableCell>
                <CustomReportTableCell>
                  {formatIndianCurrency(
                    departureReport?.food_revenue?.mtd_amount
                  )}
                </CustomReportTableCell>
                <CustomReportTableCell>
                  {formatIndianCurrency(
                    departureReport?.food_revenue?.ytd_amount
                  )}
                </CustomReportTableCell>
              </CustomReportTableRow>

              <CustomReportTableRow CustomReportTableRow>
                <CustomReportTableCell>Food Revenue Tax</CustomReportTableCell>
                <CustomReportTableCell>
                  {formatIndianCurrency(
                    departureReport?.food_revenue_tax?.current_date_amount
                  )}
                </CustomReportTableCell>
                <CustomReportTableCell>
                  {formatIndianCurrency(
                    departureReport?.food_revenue_tax?.mtd_amount
                  )}
                </CustomReportTableCell>
                <CustomReportTableCell>
                  {formatIndianCurrency(
                    departureReport?.food_revenue_tax?.ytd_amount
                  )}
                </CustomReportTableCell>
              </CustomReportTableRow>
            </CustomReportTable>
          ) : (
            <NoDataFound />
          )}

          {informationPopup && (
            <InformationCustomPopup
              setInformationPopup={setInformationPopup}
              title="Payment Reconciliation Report"
            >
              <div className="mb-20">
                <AccordionComponent
                  questions={[
                    {
                      question: "What is the Payment Reconciliation Report?",
                      answer: (
                        <p>
                          The{" "}
                          <b>
                            Detailed Revenue Report, or Sales Summary Report
                          </b>
                          , offers a comprehensive overview of hotel revenue for
                          a specific date range. It includes essential financial
                          metrics such as invoice dates, booking sources, guest
                          details, room categories, meal plans, sales amounts,
                          taxes, and payment information. This report provides a
                          clear snapshot of revenue generated from room sales,
                          food and beverage sales, and additional services,
                          helping management track financial performance.
                        </p>
                      ),
                    },
                    {
                      question:
                        "What is the Importance of the Sales Summary Report?",
                      answer: (
                        <div>
                          <p>The Sales Summary Report is crucial for:</p>

                          <ul>
                            <li>
                              <b>Performance Analysis: </b>Evaluating revenue
                              streams to identify trends and areas for
                              improvement.
                            </li>
                            <li>
                              <b>Revenue Forecasting</b>: Informing future
                              revenue predictions for better budget planning.
                            </li>
                            <li>
                              <b>Financial Transparency</b>: Ensuring clear
                              documentation of financial transactions for
                              audits.
                            </li>
                            <li>
                              <b>Guest Insights</b>: Understanding guest
                              preferences to enhance services and marketing
                              strategies.
                            </li>
                            <li>
                              <b>Operational Efficiency</b>: Streamlining
                              operations based on booking data and revenue
                              performance.
                            </li>
                            <li>
                              <b>Cash Flow Management</b>: Monitoring payments
                              and balances to maintain healthy cash flow.
                            </li>
                          </ul>
                          <p>
                            Overall, it serves as a vital tool for informed
                            decision-making and strategic growth in the
                            hospitality industry.
                          </p>
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            </InformationCustomPopup>
          )}
        </>
      )}
    </div>
  );
};
export default ReportPaymentDailyRevenue;

{
  /* <div>
  <div
    style={{
      width: "100%",
      padding: "20px",
      borderRadius: "5px",
      background: "#fff",
      marginBottom: "20px",
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ fontWeight: "500", width: "15%" }}>Revenue</div>
      <div>
        <div>Current Date Amount</div>
        <div>
          {formatIndianCurrency(
            departureReport?.revenue?.current_date_amount
          )}
        </div>
      </div>
      <div>
        <div>MTD</div>
        <div>
          {formatIndianCurrency(
            departureReport?.revenue?.mtd_amount
          )}
        </div>
      </div>
      <div>
        <div>YTD</div>
        <div>
          {formatIndianCurrency(
            departureReport?.revenue?.ytd_amount
          )}
        </div>
      </div>
    </div>
  </div>
  <div
    style={{
      width: "100%",
      padding: "20px",
      borderRadius: "5px",
      background: "#fff",
      marginBottom: "20px",
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ fontWeight: "500", width: "15%" }}>
        Revenue Tax
      </div>
      <div>
        <div>Current Date Amount</div>
        <div>
          {formatIndianCurrency(
            departureReport?.revenue_tax?.current_date_amount
          )}
        </div>
      </div>
      <div>
        <div>MTD</div>
        <div>
          {formatIndianCurrency(
            departureReport?.revenue_tax?.mtd_amount
          )}
        </div>
      </div>
      <div>
        <div>YTD</div>
        <div>
          {formatIndianCurrency(
            departureReport?.revenue_tax?.ytd_amount
          )}
        </div>
      </div>
    </div>
  </div>
  <div
    style={{
      width: "100%",
      padding: "20px",
      borderRadius: "5px",
      background: "#fff",
      gap: "10px",
      marginBottom: "20px",
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ fontWeight: "500", width: "15%" }}>
        Food Revenue
      </div>
      <div>
        <div>Current Date Amount</div>
        <div>
          {formatIndianCurrency(
            departureReport?.food_revenue?.current_date_amount
          )}
        </div>
      </div>
      <div>
        <div>MTD</div>
        <div>
          {formatIndianCurrency(
            departureReport?.food_revenue?.mtd_amount
          )}
        </div>
      </div>
      <div>
        <div>YTD</div>
        <div>
          {formatIndianCurrency(
            departureReport?.food_revenue?.ytd_amount
          )}
        </div>
      </div>
    </div>
  </div>
  <div
    style={{
      width: "100%",
      padding: "20px",
      borderRadius: "5px",
      background: "#fff",
      gap: "10px",
      marginBottom: "10px",
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ fontWeight: "500", width: "15%" }}>
        Food Revenue Tax
      </div>
      <div>
        <div>Current Date Amount</div>
        <div>
          {formatIndianCurrency(
            departureReport?.food_revenue_tax?.current_date_amount
          )}
        </div>
      </div>
      <div>
        <div>MTD</div>
        <div>
          {formatIndianCurrency(
            departureReport?.food_revenue_tax?.mtd_amount
          )}
        </div>
      </div>
      <div>
        <div>YTD</div>
        <div>
          {formatIndianCurrency(
            departureReport?.food_revenue_tax?.ytd_amount
          )}
        </div>
      </div>
    </div>
  </div>
</div> */
}
