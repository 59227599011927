import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LegacyStack, Tag } from '@shopify/polaris';
import VerticalInputFieldCustom from '../VerticalInputFieldCustom';
import { showErrorToast } from '../../../assets/toastUtils';

const CommunicationTagInput = ({ label, data, getDataFromChild, initialSelectedIds, clearTags, setClearTags }) => {
  console.log(initialSelectedIds, "initialSelectedIdsinitialSelectedIds")
  console.log(data, "darass")
  const [open, setOpen] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [showNoData, setShowNoData] = useState(false);
  const inputRef = useRef(null);

  const handleToggle = useCallback(() => {
    setOpen((open) => !open);
    if (!open) {
      setInputValue('');
    }
  }, [open]);

  const handleInputChange = useCallback((e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value.trim() !== '') {
      const filtered = data.filter((customer) =>
        customer.name.toLowerCase().startsWith(value.toLowerCase())
      );
      setFilteredCustomers(filtered);
      setShowNoData(filtered.length === 0);
    } else {
      setFilteredCustomers([]);
      setShowNoData(false);
    }
  }, [data]);

  const handleCustomerSelect = useCallback((customer) => {
    setSelectedTags((prevArray) => {
      if (prevArray.some(tag => tag.id === customer.id)) {
        showErrorToast('Item already added!');
        return prevArray;
      } else {
        const newTags = [...prevArray, customer];
        getDataFromChild(newTags); // Pass the entire object array to parent
        return newTags;
      }
    });
    setInputValue('');
    setFilteredCustomers([]);
    setShowNoData(false);
  }, [getDataFromChild]);

  const handleRemoveTag = useCallback((tag) => {
    setSelectedTags((previousTags) => {
      const newTags = previousTags.filter((t) => t.id !== tag.id);
      getDataFromChild(newTags);
      return newTags;
    });
  }, [getDataFromChild]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target) && !e.target.classList.contains('dropdown-item')) {
        setOpen(false);
        setInputValue('');
        setShowNoData(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (clearTags) {
      setSelectedTags([]);
      getDataFromChild([]);
      setClearTags(false);
    }
  }, [clearTags, getDataFromChild, setClearTags]);

  useEffect(() => {
    // Initially set selected tags based on initialSelectedIds
    if (initialSelectedIds && initialSelectedIds.length > 0) {
      const initialTags = initialSelectedIds.map(id => {
        const customer = data.find(customer => customer.id === id);
        return customer ? { id: customer.id, name: customer.name } : null;
      }).filter(customer => customer !== null);
      setSelectedTags(initialTags);
    }
  }, [data, initialSelectedIds]);
  

  const tagMarkup = selectedTags.map((option) => (
    <Tag key={option.id} onRemove={() => handleRemoveTag(option)}>
      {option.name}
    </Tag>
  ));

  const dropdownMarkup = (
    <div className="communication_dropdown" style={{
      boxShadow: "0px 0px 11px -6px rgba(0, 0, 0, 0.75)",
      maxHeight: '170px',
      overflowY: 'scroll',
      border: '1px solid #ccc',
      backgroundColor: 'white',
      position: 'absolute',
      zIndex: 1000,
      width: '100%'
    }}>
      <style>
        {`
          .communication_dropdown::-webkit-scrollbar {
            display: none;
          }
          .dropdown-item:hover {
            background-color: #3968ed;
            color: white;
            cursor: pointer;
          }
        `}
      </style>
      {showNoData ? (
        <div className="dropdown-item" style={{ textAlign: "center", padding: "5px", boxShadow: "0px 0px 11px -6px rgba(0, 0, 0, 0.75)"}}>No Data Found</div>
      ) : (
        filteredCustomers.map((customer) => (
          <div
            key={customer.id}
            className="dropdown-item"
            style={{ padding: '8px' }}
            onClick={() => handleCustomerSelect(customer)}
          >
            {customer.name}
          </div>
        ))
      )}
    </div>
  );

  return (
    <div style={{ position: 'relative', marginBottom: '10px' }}>
      <div
        onClick={handleToggle}
        aria-expanded={open}
        aria-controls="basic-collapsible"
        style={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontWeight: '500',
          fontSize: '12px',
          color: '#888888'
        }}
      >
        {label}
      </div>

      <div className="edit-rooms-form-container">
        <div className="form-container-div-full" ref={inputRef}>
          <VerticalInputFieldCustom
            type="text"
            name="customer"
            value={inputValue}
            focused={false}
            onChange={handleInputChange}
            autoComplete="off"
            onKeyDown={(e) => {
              if (e.keyCode === 8 || e.keyCode === 46 ) { 
                setInputValue("");
                setSelectedTags([]); 
                setFilteredCustomers([]); 
                setShowNoData(false); 
              }
            }}
          />
          {inputValue !== '' && dropdownMarkup}
        </div>
        <LegacyStack spacing="tight">{tagMarkup}</LegacyStack>
      </div>
    </div>
  );
};

export default CommunicationTagInput;
