import React from "react";
import FoodSaleBill from "../Components/FoodSaleBill";

const FoodSaleBillPage = () => {
    return (
        <>
            <div className="main_container">
                <FoodSaleBill />
            </div>
        </>
    );
};

export default FoodSaleBillPage;
