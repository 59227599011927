import {
  ChoiceList,
  Collapsible,
  Icon,
  LegacyCard,
  LegacyStack,
  Link,
  Page,
} from "@shopify/polaris";
import React, { useRef, useState, useEffect } from "react";
import { ChevronDownMinor, NoteMinor } from "@shopify/polaris-icons";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { BgOutlineThemeButton, BgThemeButton } from "../UI/Buttons";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import VerticalTextAreaCustom from "../CustomComponents/VerticalTextAreaCustom";
import { websites_inner_lists } from "../../assets/constant";
import SidebarInnerLists from "../CustomComponents/SidebarInnerLists";
import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { useNavigate } from "react-router";
import ReactQuill from "react-quill";
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";
import { getDecryptedData } from "../../utils/encryptStorage";
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const AddBlogComp = () => {
  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);
  const [jsonData, setJsonData] = useState("");
  const [selectedVisibility, setSelectedVisibility] = useState("");
  const [loader, setLoader] = useState(true);
  const [uploadedFile, setUploadedFile] = useState();
  const [logoUrl, setLogoUrl] = useState(null);
  const [editorHtml, setEditorHtml] = useState("");

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["align", "code-block"],
      ["link", "image"],
      ["undo", "redo"], // Add undo and redo options
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "indent",
    "align",
    "code-block",
    "link",
    "image",
  ];

  const handleTextChange = (value) => {
    setEditorHtml(value);
  };

  const handleFileUploadChange = (e) => {
    e.stopPropagation();
    setUploadedFile(e.target.files[0]);
  };

  useEffect(() => {
    if (uploadedFile) {
      const url = URL.createObjectURL(uploadedFile);
      setLogoUrl(url);
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [uploadedFile]);

  const handleRemoveLogobtn = (e) => {
    e.stopPropagation();
    setUploadedFile(null);
  };

  const navigate = useNavigate();

  const handleChangeVisibility = (value) => {
    setSelectedVisibility(value[0]);
  };

  const [addBlogPostData, setAddBlogPostsData] = useState({
    title: "",
    type: "post",
    content: "",
    content: "",
    status: "",
    blog_category: "",
    meta_title: "",
    meta_description: "",
    url_handle: "",
  });

  useEffect(() => {
    setAddBlogPostsData((prevData) => ({
      ...prevData,
      content: editorHtml,
      status: selectedVisibility,
    }));
  }, [selectedVisibility, editorHtml]);

  const handleValueChange = (event) => {
    setAddBlogPostsData({
      ...addBlogPostData,
      [event.target.name]: event.target.value,
    });
  };

  const options = [
    { label: "Travel", value: "Travel" },
    { label: "Adventure", value: "Adventure" },
    { label: "Gk", value: "Gk" },
  ];

  const handleSubmitAddDiscount = (e) => {
    e.preventDefault();
    {
      addBlogPostData.status === "" &&
        showErrorToast("Please Select Visibility!");
    }
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/store/post/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...addBlogPostData }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast("Blog Post Created Successfully!");
          setAddBlogPostsData({
            title: "",
            type: "post",
            content: editorHtml,
            content: "",
            status: selectedVisibility,
            blog_category: "",
            meta_title: "",
            meta_description: "",
            url_handle: "",
          });
          navigate("/websites/blogs");
        } else if (data.error) {
          // const firstErrorKey = Object.keys(data.error)[0];
          // const firstErrorMessage = data.error[firstErrorKey][0];
          showErrorToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      });
  };

  return (
    <div className="main_container">
      <div className="flex justify_content_center">
        <form
          // className="edit-rooms-full-container "
          style={{ width: "80%" }}
          onSubmit={(e) => handleSubmitAddDiscount(e)}
        >
          <div>
            <GoBackButtonCustom
              buttonNextText={"Add Blog Post"}
              onClick={() => navigate(-1)}
            />
          </div>
          <div className="edit-rooms-full-container-div">
            <div className="edit-rooms-full-container-left">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  width: "650px",
                }}
              >
                <div>
                  <LegacyCard sectioned>
                    <LegacyStack vertical>
                      <div
                        aria-controls="basic-collapsible"
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontWeight: "700",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                      >
                        <div>Blog details</div>
                      </div>
                      <div className="edit-rooms-form-wrapper-container">
                        <div className="edit-rooms-form-container">
                          <div className="form-container-div-full">
                            <VerticalInputFieldCustom
                              required={true}
                              focused={false}
                              name="title"
                              titleName="Title"
                              autoComplete="off"
                              onChange={handleValueChange}
                              value={addBlogPostData.title}
                            />
                          </div>
                        </div>
                        <div className="editor_container">
                          <ReactQuill
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            value={editorHtml}
                            onChange={handleTextChange}
                            style={{ border: "1px solid #ccc" }}
                          />
                         
                        </div>
                      </div>
                    </LegacyStack>
                  </LegacyCard>
                </div>
                <div className="flex flex_gap_10 mb-20">
                  <div>
                    <BgThemeButton type="submit" children={"Add"} />
                  </div>
                  <div style={{ color: "rgba(0, 122, 92, 1)" }}>
                    {/* <Link to="/websites/blogs" style={{ textDecoration: "none" }}> */}
                    <BgOutlineThemeButton
                      onClick={() => navigate("/websites/blogs")}
                      type="button"
                      children={"Discard"}
                    />
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="edit-rooms-full-container-right">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <div>
                  <LegacyCard sectioned>
                    <LegacyStack vertical>
                      <div
                        aria_controls="basic-collapsible"
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontWeight: "700",
                          fontSize: "14px",
                        }}
                      >
                        <div>Visibility</div>
                      </div>
                      <ChoiceList
                        choices={[
                          { label: "Visible", value: "visible" },
                          { label: "Hidden", value: "hidden" },
                        ]}
                        selected={selectedVisibility}
                        onChange={handleChangeVisibility}
                      />
                    </LegacyStack>
                  </LegacyCard>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddBlogComp;
