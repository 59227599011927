import React from "react";
import PreviewCommunicationVoucher from "../../Components/Communication/PreviewCommunicationVoucher";
const PreviewCommunicationVoucherPage = () => {
  return (
    <>
      <div className="main_container">
        <PreviewCommunicationVoucher />
      </div>
    </>
  );
};

export default PreviewCommunicationVoucherPage;
