export const SvgLock = () => {
  return (
    <svg
      width="13"
      height="15"
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
    >
      <path
        d="M1.49967 12.8334C1.17884 12.8334 0.904188 12.7191 0.675716 12.4907C0.447244 12.2622 0.333008 11.9875 0.333008 11.6667V5.83337C0.333008 5.51254 0.447244 5.23789 0.675716 5.00942C0.904188 4.78094 1.17884 4.66671 1.49967 4.66671H2.08301V3.50004C2.08301 2.6931 2.36738 2.00525 2.93613 1.4365C3.50488 0.867749 4.19273 0.583374 4.99967 0.583374C5.80662 0.583374 6.49447 0.867749 7.06322 1.4365C7.63197 2.00525 7.91634 2.6931 7.91634 3.50004V4.66671H8.49967C8.82051 4.66671 9.09516 4.78094 9.32363 5.00942C9.5521 5.23789 9.66634 5.51254 9.66634 5.83337V11.6667C9.66634 11.9875 9.5521 12.2622 9.32363 12.4907C9.09516 12.7191 8.82051 12.8334 8.49967 12.8334H1.49967ZM4.99967 9.91671C5.32051 9.91671 5.59516 9.80247 5.82363 9.574C6.0521 9.34553 6.16634 9.07087 6.16634 8.75004C6.16634 8.42921 6.0521 8.15455 5.82363 7.92608C5.59516 7.69761 5.32051 7.58337 4.99967 7.58337C4.67884 7.58337 4.40419 7.69761 4.17572 7.92608C3.94724 8.15455 3.83301 8.42921 3.83301 8.75004C3.83301 9.07087 3.94724 9.34553 4.17572 9.574C4.40419 9.80247 4.67884 9.91671 4.99967 9.91671ZM3.24967 4.66671H6.74967V3.50004C6.74967 3.01393 6.57954 2.60074 6.23926 2.26046C5.89898 1.92018 5.48579 1.75004 4.99967 1.75004C4.51356 1.75004 4.10037 1.92018 3.76009 2.26046C3.41981 2.60074 3.24967 3.01393 3.24967 3.50004V4.66671Z"
        fill="#333333"
      />
    </svg>
  );
};
export const SvgUnLock = () => {
  return (
    <svg
      width="13"
      height="15"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
    >
      <path
        d="M5.49967 9.91671C5.82051 9.91671 6.09516 9.80247 6.32363 9.574C6.5521 9.34553 6.66634 9.07087 6.66634 8.75004C6.66634 8.42921 6.5521 8.15455 6.32363 7.92608C6.09516 7.69761 5.82051 7.58337 5.49967 7.58337C5.17884 7.58337 4.90419 7.69761 4.67572 7.92608C4.44724 8.15455 4.33301 8.42921 4.33301 8.75004C4.33301 9.07087 4.44724 9.34553 4.67572 9.574C4.90419 9.80247 5.17884 9.91671 5.49967 9.91671ZM1.99967 12.8334C1.67884 12.8334 1.40419 12.7191 1.17572 12.4907C0.947244 12.2622 0.833008 11.9875 0.833008 11.6667V5.83337C0.833008 5.51254 0.947244 5.23789 1.17572 5.00942C1.40419 4.78094 1.67884 4.66671 1.99967 4.66671H6.08301V3.50004C6.08301 2.6931 6.36738 2.00525 6.93613 1.4365C7.50488 0.867749 8.19273 0.583374 8.99967 0.583374C9.72884 0.583374 10.3632 0.819138 10.9028 1.29067C11.4424 1.76219 11.7656 2.3431 11.8726 3.03337C11.892 3.15976 11.8483 3.26914 11.7413 3.3615C11.6344 3.45386 11.4983 3.50004 11.333 3.50004C11.1677 3.50004 11.0316 3.46601 10.9247 3.39796C10.8177 3.3299 10.74 3.2181 10.6913 3.06254C10.5844 2.6931 10.3778 2.38199 10.0715 2.12921C9.7653 1.87643 9.40801 1.75004 8.99967 1.75004C8.51356 1.75004 8.10037 1.92018 7.76009 2.26046C7.41981 2.60074 7.24967 3.01393 7.24967 3.50004V4.66671H8.99967C9.32051 4.66671 9.59516 4.78094 9.82363 5.00942C10.0521 5.23789 10.1663 5.51254 10.1663 5.83337V11.6667C10.1663 11.9875 10.0521 12.2622 9.82363 12.4907C9.59516 12.7191 9.32051 12.8334 8.99967 12.8334H1.99967Z"
        fill="#333333"
      />
    </svg>
  );
};

export const SvgBaseAdult = () => {
  return (
    <span className="flex flex_direction_col align_items_center flex_gap_5">
      <svg
        width="25"
        height="15"
        viewBox="0 0 25 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.4998 6.79998C8.6596 6.79998 9.5998 5.85977 9.5998 4.69998C9.5998 3.54018 8.6596 2.59998 7.4998 2.59998C6.34001 2.59998 5.3998 3.54018 5.3998 4.69998C5.3998 5.85977 6.34001 6.79998 7.4998 6.79998Z"
          fill="#888888"
        />
        <path
          d="M5.0498 12.4001C4.60194 12.4001 4.15407 12.2293 3.81237 11.8875C3.12895 11.2041 3.12895 10.0961 3.81237 9.41267C4.22485 9.00844 5.5398 8.19998 7.4998 8.19998C9.4598 8.19998 10.7748 9.00844 11.1872 9.41267C11.8707 10.0961 11.8707 11.2041 11.1872 11.8875C10.8455 12.2293 10.3977 12.4001 9.9498 12.4001H5.0498Z"
          fill="#888888"
        />
        <path
          d="M17.4998 6.79998C18.6596 6.79998 19.5998 5.85977 19.5998 4.69998C19.5998 3.54018 18.6596 2.59998 17.4998 2.59998C16.34 2.59998 15.3998 3.54018 15.3998 4.69998C15.3998 5.85977 16.34 6.79998 17.4998 6.79998Z"
          fill="#888888"
        />
        <path
          d="M15.0498 12.4001C14.6019 12.4001 14.1541 12.2293 13.8124 11.8875C13.129 11.2041 13.129 10.0961 13.8124 9.41267C14.2248 9.00844 15.5398 8.19998 17.4998 8.19998C19.4598 8.19998 20.7748 9.00844 21.1872 9.41267C21.8707 10.0961 21.8707 11.2041 21.1872 11.8875C20.8455 12.2293 20.3977 12.4001 19.9498 12.4001H15.0498Z"
          fill="#888888"
        />
      </svg>
      <span
        style={{
          color: "rgb(136, 136, 136)",
          fontSize: "12px",
          lineHeight: "12px",
          fontWeight: "400",
        }}
      >
        Base Rate
      </span>
    </span>
  );
};

export const SvgBaseAdultWithoutHeading = () => {
  return (
    <span className="flex flex_direction_col align_items_center flex_gap_5">
      <svg
        width="25"
        height="15"
        viewBox="0 0 25 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.4998 6.79998C8.6596 6.79998 9.5998 5.85977 9.5998 4.69998C9.5998 3.54018 8.6596 2.59998 7.4998 2.59998C6.34001 2.59998 5.3998 3.54018 5.3998 4.69998C5.3998 5.85977 6.34001 6.79998 7.4998 6.79998Z"
          fill="#888888"
        />
        <path
          d="M5.0498 12.4001C4.60194 12.4001 4.15407 12.2293 3.81237 11.8875C3.12895 11.2041 3.12895 10.0961 3.81237 9.41267C4.22485 9.00844 5.5398 8.19998 7.4998 8.19998C9.4598 8.19998 10.7748 9.00844 11.1872 9.41267C11.8707 10.0961 11.8707 11.2041 11.1872 11.8875C10.8455 12.2293 10.3977 12.4001 9.9498 12.4001H5.0498Z"
          fill="#888888"
        />
        <path
          d="M17.4998 6.79998C18.6596 6.79998 19.5998 5.85977 19.5998 4.69998C19.5998 3.54018 18.6596 2.59998 17.4998 2.59998C16.34 2.59998 15.3998 3.54018 15.3998 4.69998C15.3998 5.85977 16.34 6.79998 17.4998 6.79998Z"
          fill="#888888"
        />
        <path
          d="M15.0498 12.4001C14.6019 12.4001 14.1541 12.2293 13.8124 11.8875C13.129 11.2041 13.129 10.0961 13.8124 9.41267C14.2248 9.00844 15.5398 8.19998 17.4998 8.19998C19.4598 8.19998 20.7748 9.00844 21.1872 9.41267C21.8707 10.0961 21.8707 11.2041 21.1872 11.8875C20.8455 12.2293 20.3977 12.4001 19.9498 12.4001H15.0498Z"
          fill="#888888"
        />
      </svg>
    </span>
  );
};

export const SvgExtraAdult = () => {
  return (
    <span className="flex flex_direction_col align_items_center flex_gap_5">
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.4998 6.29998C8.6596 6.29998 9.5998 5.35977 9.5998 4.19998C9.5998 3.04018 8.6596 2.09998 7.4998 2.09998C6.34001 2.09998 5.3998 3.04018 5.3998 4.19998C5.3998 5.35977 6.34001 6.29998 7.4998 6.29998Z"
          fill="#888888"
        />
        <path
          d="M5.0498 11.9001C4.60194 11.9001 4.15407 11.7293 3.81237 11.3875C3.12895 10.7041 3.12895 9.59609 3.81237 8.91267C4.22485 8.50844 5.5398 7.69998 7.4998 7.69998C9.4598 7.69998 10.7748 8.50844 11.1872 8.91267C11.8707 9.59609 11.8707 10.7041 11.1872 11.3875C10.8455 11.7293 10.3977 11.9001 9.9498 11.9001H5.0498Z"
          fill="#888888"
        />
      </svg>

      <span
        style={{
          color: "rgb(136, 136, 136)",
          fontSize: "12px",
          lineHeight: "12px",
          fontWeight: "400",
        }}
      >
        Extra Adult
      </span>
    </span>
  );
};
export const SvgExtraWithoutHeading = () => {
  return (
    <span className="flex flex_direction_col align_items_center flex_gap_5">
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.4998 6.29998C8.6596 6.29998 9.5998 5.35977 9.5998 4.19998C9.5998 3.04018 8.6596 2.09998 7.4998 2.09998C6.34001 2.09998 5.3998 3.04018 5.3998 4.19998C5.3998 5.35977 6.34001 6.29998 7.4998 6.29998Z"
          fill="#888888"
        />
        <path
          d="M5.0498 11.9001C4.60194 11.9001 4.15407 11.7293 3.81237 11.3875C3.12895 10.7041 3.12895 9.59609 3.81237 8.91267C4.22485 8.50844 5.5398 7.69998 7.4998 7.69998C9.4598 7.69998 10.7748 8.50844 11.1872 8.91267C11.8707 9.59609 11.8707 10.7041 11.1872 11.3875C10.8455 11.7293 10.3977 11.9001 9.9498 11.9001H5.0498Z"
          fill="#888888"
        />
      </svg>
    </span>
  );
};

export const SvgChild = () => {
  return (
    <span className="flex flex_direction_col align_items_center flex_gap_5">
      <svg
        width="9"
        height="15"
        viewBox="0 0 9 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_6148_1569)">
          <path
            d="M2.75005 2.25C2.75005 1.78587 2.93442 1.34075 3.26261 1.01256C3.5908 0.684374 4.03592 0.5 4.50005 0.5C4.96417 0.5 5.40929 0.684374 5.73748 1.01256C6.06567 1.34075 6.25005 1.78587 6.25005 2.25C6.25005 2.71413 6.06567 3.15925 5.73748 3.48744C5.40929 3.81563 4.96417 4 4.50005 4C4.03592 4 3.5908 3.81563 3.26261 3.48744C2.93442 3.15925 2.75005 2.71413 2.75005 2.25ZM4.06255 11V13.625C4.06255 14.109 3.67153 14.5 3.18755 14.5C2.70356 14.5 2.31255 14.109 2.31255 13.625V8.36953L1.74106 9.27734C1.48403 9.6875 0.942624 9.80781 0.535202 9.55078C0.12778 9.29375 0.00199857 8.75508 0.25903 8.34766L1.35005 6.6168C2.0309 5.53125 3.22036 4.875 4.50005 4.875C5.77973 4.875 6.96919 5.53125 7.65005 6.61406L8.74106 8.34766C8.99809 8.75781 8.87505 9.29648 8.46762 9.55352C8.0602 9.81055 7.5188 9.6875 7.26176 9.28008L6.68755 8.36953V13.625C6.68755 14.109 6.29653 14.5 5.81255 14.5C5.32856 14.5 4.93755 14.109 4.93755 13.625V11H4.06255Z"
            fill="#888888"
          />
        </g>
        <defs>
          <clipPath id="clip0_6148_1569">
            <rect
              width="8.75"
              height="14"
              fill="white"
              transform="translate(0.125 0.5)"
            />
          </clipPath>
        </defs>
      </svg>

      <span
        style={{
          color: "rgb(136, 136, 136)",
          fontSize: "12px",
          lineHeight: "12px",
          fontWeight: "400",
        }}
      >
        Extra Child
      </span>
    </span>
  );
};

export const SvgChildWithoutHeading = () => {
  return (
    <span className="flex flex_direction_col align_items_center flex_gap_5">
      <svg
        width="9"
        height="15"
        viewBox="0 0 9 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_6148_1569)">
          <path
            d="M2.75005 2.25C2.75005 1.78587 2.93442 1.34075 3.26261 1.01256C3.5908 0.684374 4.03592 0.5 4.50005 0.5C4.96417 0.5 5.40929 0.684374 5.73748 1.01256C6.06567 1.34075 6.25005 1.78587 6.25005 2.25C6.25005 2.71413 6.06567 3.15925 5.73748 3.48744C5.40929 3.81563 4.96417 4 4.50005 4C4.03592 4 3.5908 3.81563 3.26261 3.48744C2.93442 3.15925 2.75005 2.71413 2.75005 2.25ZM4.06255 11V13.625C4.06255 14.109 3.67153 14.5 3.18755 14.5C2.70356 14.5 2.31255 14.109 2.31255 13.625V8.36953L1.74106 9.27734C1.48403 9.6875 0.942624 9.80781 0.535202 9.55078C0.12778 9.29375 0.00199857 8.75508 0.25903 8.34766L1.35005 6.6168C2.0309 5.53125 3.22036 4.875 4.50005 4.875C5.77973 4.875 6.96919 5.53125 7.65005 6.61406L8.74106 8.34766C8.99809 8.75781 8.87505 9.29648 8.46762 9.55352C8.0602 9.81055 7.5188 9.6875 7.26176 9.28008L6.68755 8.36953V13.625C6.68755 14.109 6.29653 14.5 5.81255 14.5C5.32856 14.5 4.93755 14.109 4.93755 13.625V11H4.06255Z"
            fill="#888888"
          />
        </g>
        <defs>
          <clipPath id="clip0_6148_1569">
            <rect
              width="8.75"
              height="14"
              fill="white"
              transform="translate(0.125 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};
export const SvgChildWithBed = () => {
  return (
    <span className="flex flex_direction_col align_items_center flex_gap_5">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 15 14"
        fill="none"
      >
        <path
          d="M2.25 4.08325V10.4999M2.25 8.16659H12.75M12.75 10.4999V5.83325C12.75 5.52383 12.6271 5.22709 12.4083 5.00829C12.1895 4.7895 11.8928 4.66659 11.5833 4.66659H6.91667V8.16659M4 5.83325C4 5.98796 4.06146 6.13633 4.17085 6.24573C4.28025 6.35513 4.42862 6.41659 4.58333 6.41659C4.73804 6.41659 4.88642 6.35513 4.99581 6.24573C5.10521 6.13633 5.16667 5.98796 5.16667 5.83325C5.16667 5.67854 5.10521 5.53017 4.99581 5.42077C4.88642 5.31138 4.73804 5.24992 4.58333 5.24992C4.42862 5.24992 4.28025 5.31138 4.17085 5.42077C4.06146 5.53017 4 5.67854 4 5.83325Z"
          stroke="#888888"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>

      <span
        style={{
          color: "rgb(136, 136, 136)",
          fontSize: "12px",
          lineHeight: "12px",
          fontWeight: "400",
        }}
      >
        Extra Child
      </span>
    </span>
  );
};

export const SvgChildWithBedWithoutHeading = () => {
  return (
    <span className="flex flex_direction_col align_items_center flex_gap_5">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 15 14"
        fill="none"
      >
        <path
          d="M2.25 4.08325V10.4999M2.25 8.16659H12.75M12.75 10.4999V5.83325C12.75 5.52383 12.6271 5.22709 12.4083 5.00829C12.1895 4.7895 11.8928 4.66659 11.5833 4.66659H6.91667V8.16659M4 5.83325C4 5.98796 4.06146 6.13633 4.17085 6.24573C4.28025 6.35513 4.42862 6.41659 4.58333 6.41659C4.73804 6.41659 4.88642 6.35513 4.99581 6.24573C5.10521 6.13633 5.16667 5.98796 5.16667 5.83325C5.16667 5.67854 5.10521 5.53017 4.99581 5.42077C4.88642 5.31138 4.73804 5.24992 4.58333 5.24992C4.42862 5.24992 4.28025 5.31138 4.17085 5.42077C4.06146 5.53017 4 5.67854 4 5.83325Z"
          stroke="#888888"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>

      <span
        style={{
          color: "rgb(136, 136, 136)",
          fontSize: "12px",
          lineHeight: "12px",
          fontWeight: "400",
        }}
      >
        Extra Child
      </span>
    </span>
  );
};

export const SvgPlus = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6065_11193)">
        <path
          d="M2.6811 1H11.3189C12.5235 1 13.5 1.97651 13.5 3.1811V11.8189C13.5 13.0235 12.5235 14 11.3189 14H2.6811C1.47651 14 0.5 13.0235 0.5 11.8189V3.1811C0.5 1.97651 1.47651 1 2.6811 1Z"
          stroke="#3968ED"
        />
        <path d="M10.5868 7.5H3.41357" stroke="#3968ED" strokeMiterlimit="10" />
        <path d="M7 3.91344L7 11.0867" stroke="#3968ED" strokeMiterlimit="10" />
      </g>
      <defs>
        <clipPath id="clip0_6065_11193">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const SvgMinus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g clipPath="url(#clip0_1270_876)">
        <path
          d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
          stroke="#3968ED"
        ></path>
        <path
          d="M10.5866 7H3.41333"
          stroke="#3968ED"
          strokeMiterlimit="10"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1270_876">
          <rect width="14" height="14" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>
  );
};
export const SvgCancel = ({ color, height, width }) => {
  return (
    <svg
      width={height ? height : "14"}
      height={width ? width : "14"}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_11302_4819"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="14"
        height="14"
      >
        <rect width="14" height="14" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_11302_4819)">
        <path
          d="M3.73366 11.0834L2.91699 10.2667L6.18366 7.00008L2.91699 3.73341L3.73366 2.91675L7.00033 6.18341L10.267 2.91675L11.0837 3.73341L7.81699 7.00008L11.0837 10.2667L10.267 11.0834L7.00033 7.81675L3.73366 11.0834Z"
          fill={color ? color : "white"}
        />
      </g>
    </svg>
  );
};

export const SvgReload = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
    >
      <mask
        id="mask0_7124_1862"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="28"
        height="29"
      >
        <rect y="0.5" width="28" height="28" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7124_1862)">
        <path
          d="M5.95002 19.225C5.52224 18.4861 5.20141 17.7278 4.98752 16.95C4.77363 16.1722 4.66669 15.375 4.66669 14.5584C4.66669 11.9528 5.57085 9.73613 7.37919 7.90835C9.18752 6.08058 11.3945 5.16669 14 5.16669H14.2042L12.3375 3.30002L13.9709 1.66669L18.6375 6.33335L13.9709 11L12.3375 9.36669L14.2042 7.50002H14C12.0556 7.50002 10.4028 8.18544 9.04169 9.55627C7.68058 10.9271 7.00002 12.5945 7.00002 14.5584C7.00002 15.0639 7.05835 15.5597 7.17502 16.0459C7.29169 16.532 7.46669 17.0084 7.70002 17.475L5.95002 19.225ZM14.0292 27.3334L9.36252 22.6667L14.0292 18L15.6625 19.6334L13.7959 21.5H14C15.9445 21.5 17.5972 20.8146 18.9584 19.4438C20.3195 18.0729 21 16.4056 21 14.4417C21 13.9361 20.9417 13.4403 20.825 12.9542C20.7084 12.4681 20.5334 11.9917 20.3 11.525L22.05 9.77502C22.4778 10.5139 22.7986 11.2722 23.0125 12.05C23.2264 12.8278 23.3334 13.625 23.3334 14.4417C23.3334 17.0472 22.4292 19.2639 20.6209 21.0917C18.8125 22.9195 16.6056 23.8334 14 23.8334H13.7959L15.6625 25.7L14.0292 27.3334Z"
          fill="#3968ED"
        />
      </g>
    </svg>
  );
};

export const SvgEdit = () => {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#2f59cb"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.8477 1.87868C19.6761 0.707109 17.7766 0.707105 16.605 1.87868L2.44744 16.0363C2.02864 16.4551 1.74317 16.9885 1.62702 17.5692L1.03995 20.5046C0.760062 21.904 1.9939 23.1379 3.39334 22.858L6.32868 22.2709C6.90945 22.1548 7.44285 21.8693 7.86165 21.4505L22.0192 7.29289C23.1908 6.12132 23.1908 4.22183 22.0192 3.05025L20.8477 1.87868ZM18.0192 3.29289C18.4098 2.90237 19.0429 2.90237 19.4335 3.29289L20.605 4.46447C20.9956 4.85499 20.9956 5.48815 20.605 5.87868L17.9334 8.55027L15.3477 5.96448L18.0192 3.29289ZM13.9334 7.3787L3.86165 17.4505C3.72205 17.5901 3.6269 17.7679 3.58818 17.9615L3.00111 20.8968L5.93645 20.3097C6.13004 20.271 6.30784 20.1759 6.44744 20.0363L16.5192 9.96448L13.9334 7.3787Z"
          fill="#2f59cb"
        ></path>
      </g>
    </svg>
  );
};

export const SvgDelete = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2001 3.2H13.6001C14.0419 3.2 14.4001 3.55817 14.4001 4L14.4001 4.8H1.6001L1.60012 4C1.60012 3.55817 1.95829 3.2 2.40012 3.2H4.80012V1.2C4.80012 0.537258 5.33738 0 6.00012 0H10.0001C10.6629 0 11.2001 0.537259 11.2001 1.2V3.2ZM6.40012 1.6V3.2H9.60012V1.6H6.40012Z"
        fill="#E03838"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.40012 6.4H13.6001V14.8C13.6001 15.4627 13.0629 16 12.4001 16H3.60012C2.93738 16 2.40012 15.4627 2.40012 14.8V6.4ZM5.6001 8.8H4.0001V13.6H5.6001V8.8ZM8.8001 8.8H7.2001V13.6H8.8001V8.8ZM10.4001 8.8H12.0001V13.6H10.4001V8.8Z"
        fill="#E03838"
      ></path>
    </svg>
  );
};

export const SvgGoBack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <rect
        x="29.75"
        y="29.75"
        width="29.5"
        height="29.5"
        rx="5.75"
        transform="rotate(180 29.75 29.75)"
        stroke="#666666"
        strokeWidth="0.5"
      ></rect>
      <line x1="21" y1="15.043" x2="9" y2="15.043" stroke="#666666"></line>
      <path
        d="M13.6287 19.2578L9.00009 14.6292L13.6287 10.0007"
        stroke="#666666"
        strokeWidth="1.2"
      ></path>
    </svg>
  );
};
export const SvgSettings = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="20"
      viewBox="0 0 17 18"
    >
      <g clipPath="url(#clip0_1329_1622)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.02247 0.818359C7.61537 0.818359 7.2702 1.11776 7.21268 1.52084L6.90885 3.64704C6.33417 3.86623 5.80383 4.17532 5.33501 4.55712L3.34029 3.75645C2.96236 3.60479 2.5305 3.75404 2.32696 4.10667L1.40031 5.71186C1.19656 6.06448 1.28325 6.51313 1.60366 6.76453L3.29375 8.09121C3.24661 8.38724 3.22204 8.69085 3.22204 9.00018C3.22204 9.30951 3.24661 9.6131 3.29375 9.90913L1.60366 11.2358C1.28325 11.4872 1.19656 11.9359 1.40031 12.2885L2.32696 13.8937C2.5305 14.2463 2.96236 14.3956 3.34029 14.2439L5.33501 13.4432C5.80383 13.825 6.33417 14.1341 6.90885 14.3533L7.21268 16.4795C7.2702 16.8826 7.61537 17.182 8.02247 17.182H9.87616C10.2833 17.182 10.6284 16.8826 10.686 16.4795L10.9898 14.3533C11.5645 14.1341 12.0948 13.8251 12.5636 13.4433L14.5581 14.2439C14.9361 14.3956 15.3679 14.2463 15.5715 13.8937L16.4983 12.2885C16.7019 11.9359 16.6152 11.4872 16.295 11.2358L14.6049 9.9092C14.652 9.61314 14.6766 9.30952 14.6766 9.00018C14.6766 8.89915 14.674 8.79874 14.6688 8.699C14.6582 8.49333 14.6366 8.29054 14.6049 8.09116L16.295 6.76453C16.6152 6.51313 16.7019 6.06448 16.4983 5.71186L15.5715 4.10667C15.3679 3.75404 14.9361 3.60479 14.5581 3.75645L12.5636 4.55707C12.0948 4.17529 11.5645 3.86623 10.9898 3.64704L10.686 1.52084C10.6284 1.11776 10.2833 0.818359 9.87616 0.818359H8.02247ZM8.9494 11.4547C10.305 11.4547 11.4039 10.3558 11.4039 9.00018C11.4039 7.64457 10.305 6.54563 8.9494 6.54563C7.59379 6.54563 6.49485 7.64457 6.49485 9.00018C6.49485 10.3558 7.59379 11.4547 8.9494 11.4547Z"
          fill="#5C5F62"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1329_1622">
          <rect
            width="16.3636"
            height="16.3636"
            fill="white"
            transform="translate(0.63623 0.818359)"
          ></rect>
        </clipPath>
      </defs>
    </svg>
  );
};

export const SvgLow = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_11772_5052"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="19"
      >
        <rect
          x="18"
          y="18.5"
          width="18"
          height="18"
          transform="rotate(-180 18 18.5)"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_11772_5052)">
        <path
          d="M12.9767 7.25002C13.1337 7.25002 13.2602 7.30747 13.3561 7.42236C13.452 7.53726 13.5 7.6713 13.5 7.82449C13.5 7.86279 13.4477 7.99683 13.343 8.22662L9.54942 12.3915C9.46221 12.4873 9.375 12.5543 9.28779 12.5926C9.20058 12.6309 9.10465 12.65 9 12.65C8.89535 12.65 8.79942 12.6309 8.71221 12.5926C8.625 12.5543 8.53779 12.4873 8.45058 12.3915L4.65698 8.22662C4.60465 8.16917 4.56541 8.10694 4.53925 8.03992C4.51308 7.9729 4.5 7.90109 4.5 7.82449C4.5 7.6713 4.54797 7.53726 4.6439 7.42236C4.73983 7.30747 4.86628 7.25002 5.02326 7.25002L12.9767 7.25002Z"
          fill="#E03838"
        />
      </g>
    </svg>
  );
};
export const SvgHigh = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_11762_3315"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="19"
      >
        <rect y="0.5" width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_11762_3315)">
        <path
          d="M5.02326 11.75C4.86628 11.75 4.73983 11.6925 4.6439 11.5776C4.54797 11.4627 4.5 11.3287 4.5 11.1755C4.5 11.1372 4.55233 11.0032 4.65698 10.7734L8.45058 6.60849C8.53779 6.51274 8.625 6.44572 8.71221 6.40742C8.79942 6.36912 8.89535 6.34998 9 6.34998C9.10465 6.34998 9.20058 6.36912 9.28779 6.40742C9.375 6.44572 9.46221 6.51274 9.54942 6.60849L13.343 10.7734C13.3953 10.8308 13.4346 10.8931 13.4608 10.9601C13.4869 11.0271 13.5 11.0989 13.5 11.1755C13.5 11.3287 13.452 11.4627 13.3561 11.5776C13.2602 11.6925 13.1337 11.75 12.9767 11.75H5.02326Z"
          fill="#15AA12"
        />
      </g>
    </svg>
  );
};
export const SvgUpFilled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="6"
      viewBox="0 0 9 6"
      fill="none"
    >
      <path
        d="M0.536891 2.12005e-09H7.46403C7.56547 -8.95738e-06 7.66489 0.02838 7.75102 0.0819603C7.83716 0.135541 7.90657 0.212164 7.9514 0.30316C7.99623 0.394156 8.01468 0.495889 8.00467 0.596834C7.99467 0.697778 7.95659 0.793909 7.89476 0.874331L4.50995 5.24919C4.4499 5.32722 4.37274 5.39042 4.2844 5.4339C4.19607 5.47739 4.09892 5.5 4.00046 5.5C3.902 5.5 3.80485 5.47739 3.71652 5.4339C3.62818 5.39042 3.55102 5.32722 3.49097 5.24919L0.112581 0.874331C0.0510789 0.794342 0.0130717 0.6988 0.00281478 0.598423C-0.00744212 0.498046 0.0104546 0.396799 0.0545071 0.306024C0.0985597 0.215249 0.167027 0.138533 0.252227 0.0844814C0.337427 0.0304294 0.435998 0.00117986 0.536891 2.12005e-09Z"
        fill="#666666"
      />
    </svg>
  );
};

export const SvgAlert = () => {
  return (
    <svg
      width="30"
      height="30"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M12 2a10 10 0 110 20 10 10 0 010-20zm0 18a8 8 0 100-16 8 8 0 000 16zm-.5-13h1v7h-1v-7zm0 9h1v1h-1v-1z"></path>
    </svg>
  );
};

export const SvgGetInfo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill="#3968ED"
    >
      <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
    </svg>
  )
}
