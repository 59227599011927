import React, { useEffect, useState } from "react";
import styles from "./PropertyTargets.module.scss";
import { PrimaryButton } from "../NewCustomComp/Buttons/Buttons";
import { getBaseUrl, getDateByMonth } from "../../utils/utils";
import { SvgHigh, SvgLow } from "../../assets/svgIcons";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Loader from "../UI/Loader/Loader";
import NoDataFound from "../CustomComponents/NoDataFound";
import AddCompetitors from "../AddCompetitorsPopup/AddCompetitors";
import { useSelector } from "react-redux";
import { getDecryptedData } from "../../utils/encryptStorage";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const CompetitorsTargets = () => {
  const baseUrl = getBaseUrl();
  const [loader, setLoader] = useState("");
  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value)?.id;

  const mainData = {
    date_range: [
      "2024-10-01",
      "2024-10-02",
      "2024-10-03",
      "2024-10-04",
      "2024-10-05",
      "2024-10-06",
      "2024-10-07",
      "2024-10-08",
      "2024-10-09",
      "2024-10-10",
      "2024-10-11",
      "2024-10-12",
      "2024-10-13",
      "2024-10-14",
      "2024-10-15",
      "2024-10-16",
      "2024-10-17",
      "2024-10-18",
      "2024-10-19",
      "2024-10-20",
      "2024-10-21",
      "2024-10-22",
      "2024-10-23",
      "2024-10-24",
      "2024-10-25",
      "2024-10-26",
      "2024-10-27",
      "2024-10-28",
      "2024-10-29",
      "2024-10-30",
    ],
    table_data: [
      {
        title: "Your Property",
        data: {
          "2024-10-01": [
            {
              amount: "7500",
              highOrLow: "low",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-02": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-03": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-04": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],

          "2024-10-05": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-06": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-07": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-08": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-09": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-10": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-11": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-12": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-13": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-14": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],

          "2024-10-15": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-16": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-17": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-18": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-19": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-20": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-21": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-22": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-23": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-24": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],

          "2024-10-25": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-26": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-27": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-28": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-29": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-30": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
        },
      },
      {
        title: "Competitor Avg. Rates",
        data: {
          "2024-10-01": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-02": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-03": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-04": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],

          "2024-10-05": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-06": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-07": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-08": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-09": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-10": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-11": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-12": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-13": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-14": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],

          "2024-10-15": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-16": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-17": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-18": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-19": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-20": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-21": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-22": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-23": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-24": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],

          "2024-10-25": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-26": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-27": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-28": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-29": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-30": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
        },
      },
      {
        title: "Single Properties",
        data: {
          "2024-10-01": [
            {
              amount: "7000",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-02": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-03": [
            {
              amount: "7000",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-04": [
            {
              amount: "7000",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],

          "2024-10-05": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-06": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-07": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-08": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-09": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-10": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-11": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-12": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-13": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-14": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],

          "2024-10-15": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-16": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-17": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-18": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-19": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-20": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-21": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-22": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-23": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-24": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],

          "2024-10-25": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-26": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-27": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-28": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-29": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
          "2024-10-30": [
            {
              amount: "7500",
              highOrLow: "high",
              numHighLow: "500",
              percentHighLow: "50%",
            },
          ],
        },
      },
    ],
  };

  // Competitors Data Api
  const [analysis, setAnalysis] = useState("");
  const fetchCompetitorTargets = async () => {
    setLoader(true);
    const requestOptions = {
      method: "POST",
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/admin/competitors-analysis/${property_id}`,
        requestOptions
      );
      const result = await response.json();
      if (result.success) {
        setAnalysis(result);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    if (property_id) {
      fetchCompetitorTargets();
    }
  }, [property_id]);

  const [showAddCompetitors, setShowAddCompetitors] = useState(false);


  const [switchValues, setSwitchValues] = useState("number");
  const handelSwitchValues = () => {
    if (switchValues === "number") {
      setSwitchValues("percentage");
    } else if (switchValues === "percentage") {
      setSwitchValues("number");
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {analysis && analysis?.table_data?.length > 0 ? (
            <div className={styles.targetsMain}>
              <div className="flex align_items_center justify_content_between">
                <h4 className="room-type-heading align_items_center">Competitors Rates</h4>
                <PrimaryButton
                  size={"medium"}
                  title={"Add Competitors"}
                  onClick={() => setShowAddCompetitors(true)}
                />
              </div>

              <div className={`${styles.graphsWrapper} mt-10`}>
                {analysis.graphData && analysis.graphData && analysis.graphData.map((items, index) => {
                  const data = {
                    labels: items.GraphProperty,
                    datasets: [
                      {
                        label: "Current",
                        data: items.GraphProperty,
                        borderColor: "#15AA12",
                        backgroundColor: "#15AA12",
                        tension: 0.4,
                        pointRadius: 0,
                      },
                      {
                        label: "Competitors",
                        data: items.GraphCompetitors,
                        borderColor: "#FF385C",
                        backgroundColor: "#FF385C",
                        tension: 0.4,
                        pointRadius: 0,
                      },
                    ],
                  };
                  const options = {
                    plugins: {
                      legend: {
                        display: false, // Disable legend (label box)
                      },
                    },
                    scales: {
                      x: {
                        display: false,
                        grid: {
                          display: false, // Hide grid lines on x-axis
                        },
                      },
                      y: {
                        display: false,
                        grid: {
                          display: false, // Hide grid lines on y-axis
                        },
                      },
                      y1: {
                        display: false,
                      },
                    },
                  };
                  return (
                    <div
                      key={index}
                      className={`${styles.targetsGraphs} ${styles.whiteWrapper}`}
                    >
                      <div className={styles.graphHeading}>
                        <h5>{items.title}</h5>
                        <h2>{items.percentage}</h2>
                      </div>
                      <div className={styles.graphImg}>
                        <Line options={options} data={data} />
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                className="mt-20"
                style={{
                  padding: "15px 20px",
                  borderRadius: "8px",
                  backgroundColor: "white",
                }}
              >
                <div className={`${styles.analysisWrapper}`}>
                  <table className={styles.analysisTable}>
                    <thead>
                      <tr>
                        <th
                          style={{ borderTopLeftRadius: "8px" }}
                          className={styles.analysisFixed}
                        >
                          Dates
                        </th>
                        {analysis &&
                          analysis.date_range.length > 0 &&
                          analysis.date_range.map((items, index) => (
                            <th key={index}>{getDateByMonth(items)}</th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {analysis &&
                        analysis.table_data.length > 0 &&
                        analysis.table_data.map((items, index) => (
                          <tr key={index}>
                            <td
                              className={`${styles.analysisFixed} ${items.title === "Your Property" ||
                                items.title === "Competitor Avg. Rates"
                                ? styles.highlight
                                : ""
                                }`}
                            >
                              <div style={{ width: "150px" }}>
                                {items.title}
                              </div>
                            </td>



                            {Object.keys(items.data).length > 0 ?

                              Object.keys(items.data).map((date, innerIndex) => {
                                // console.log(items.data[date][0].amount,"inner");
                                return (
                                  <td
                                    className={`${items.title === "Your Property" ||
                                      items.title === "Competitor Avg. Rates"
                                      ? styles.highlight
                                      : ""
                                      }  `}
                                    key={innerIndex}
                                  >
                                    <div className={`${styles.analysisRatesTd}`}>
                                      <span
                                        className={`${styles.analysisAmount} ${items.title === "Your Property" ||
                                          items.title === "Competitor Avg. Rates"
                                          ? styles.bold
                                          : ""
                                          }`}
                                      >
                                        {items.data[date][0]?.amount}
                                      </span>

                                      {items.data[date][0]?.highOrLow ===
                                        "low" && (
                                          <span onClick={handelSwitchValues} className={`${styles.analysisLow} pointer`}>
                                            <SvgLow />
                                            {switchValues === "number" ? items.data[date][0]?.numHighLow : items.data[date][0]?.percentHighLow}
                                          </span>
                                        )}
                                      {items.data[date][0]?.highOrLow ===
                                        "high" && (
                                          <span onClick={handelSwitchValues}
                                            className={`${styles.analysisHigh}pointer`}
                                          >
                                            <SvgHigh />

                                            {switchValues === "number" ? items.data[date][0]?.numHighLow : items.data[date][0]?.percentHighLow}
                                          </span>
                                        )}
                                    </div>
                                  </td>
                                );
                              })
                              :
                              Array.from({ length: analysis.date_range.length }).map((_, emptyIndex) => {
                                return (
                                  <td key={emptyIndex} className={styles.emptyRow}></td>
                                );
                              })
                            }
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <NoDataFound />
          )}
        </>
      )}

      {showAddCompetitors && (
        <AddCompetitors
          setShow={setShowAddCompetitors}
          userToken={userToken}
          property_id={property_id}
          fetchInfo={fetchCompetitorTargets}
        />
      )}
    </>
  );
};

export default CompetitorsTargets;
