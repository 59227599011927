import React from "react";
import BanquetHall from "../Components/BanquetHall";

const BanquetHallPage = () => {
  return (
    <>
      <div className="main_container">
        <BanquetHall />
      </div>
    </>
  );
};

export default BanquetHallPage;
