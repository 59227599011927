import React from "react";
import styles from "./auditStatus.module.scss";

const AuditStatus = ({ status, showIcon, width, height, fontSize }) => {
  return (
    <div className={styles.auditStatusContainer}>
      {showIcon ? (
        <>
          {status === "confirm" && (
            <svg
              width={width ? width : "16"}
              height={height ? height : "17"}
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 8.5C16 12.9183 12.4183 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5Z"
                fill="#2DB232"
              />
              <path
                d="M7.04058 12L3.5 8.45942L4.43152 7.5279L7.04058 10.1328L11.6734 5.5L12.6049 6.43152L7.04058 12Z"
                fill="white"
              />
            </svg>
          )}
          {status === "pending" && (
            <svg
              width={width ? width : "16"}
              height={height ? height : "16"}
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                fill="#DEAF21"
              />
              <path d="M8.79391 3H7.30005V9.78304H8.79391V3Z" fill="white" />
              <path
                d="M8.79391 10.8848H7.30005V12.5H8.79391V10.8848Z"
                fill="white"
              />
            </svg>
          )}
          {status === "submit" && (
            <svg
              width={width ? width : "16"}
              height={height ? height : "17"}
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 8.5C16 12.9183 12.4183 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5Z"
                fill="#3968ED"
              />
              <path
                d="M11.9929 10.5H6.5V4H7.90731V9.09269H11.9929V10.5Z"
                fill="white"
              />
            </svg>
          )}
          {status === "draft" && (
            <svg
              width={width ? width : "16"}
              height={height ? height : "17"}
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 8.5C16 12.9183 12.4183 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5Z"
                fill="#858585"
              />
              <path d="M12.6 8H3.5V9.5383H12.6V8Z" fill="white" />
            </svg>
          )}
          {status === "cancel" && (
            <svg
              width={width ? width : "16"}
              height={height ? height : "17"}
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 8.5C16 12.9183 12.4183 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5Z"
                fill="#ED3939"
              />
              <path
                d="M11.5 5.92895L10.5711 5L7.99794 7.569L5.42895 5L4.5 5.92895L7.069 8.49794L4.5 11.0711L5.42895 12L7.99794 9.431L10.5711 12L11.5 11.0711L8.931 8.49794L11.5 5.92895Z"
                fill="white"
              />
            </svg>
          )}
        </>
      ) : (
        <span
          className={`${styles.statusDot} ${styles[status?.toLowerCase()]}`}
          style={{ fontSize: fontSize ? fontSize : "" }}
        ></span>
      )}

      <span
        className={styles.statusText}
        style={{ fontSize: fontSize ? fontSize : "" }}
      >
        {status}
      </span>
    </div>
  );
};
export default AuditStatus;
