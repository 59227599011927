import React from "react";
import AddBanquetHall from "../Components/AddBanquetHall";
import AddEvent from "../Components/AddEvent";

const AddEventPage = () => {
  return (
    <>
      <div className="main_container">
        <AddEvent />
      </div>
    </>
  );
};

export default AddEventPage;
