import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import CustomIndexTable from "../Dashboard/CustomIndexTable/CustomIndexTable";
import CustomIndexTableRow from "../Dashboard/CustomIndexTable/CustomIndexTableRow";
import CustomIndexTableCell from "../Dashboard/CustomIndexTable/CustomIndexTableCell";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { useState } from "react";
import { SvgGetInfo } from "../../assets/svgIcons";

const ReportCategoryViseSupplier = ({ departureReport, loader }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const [informationPopup, setInformationPopup] = useState(false);

  const rowMarkupAllFoliosCustom = departureReport?.map(
    ({ id, count, order_no, order_type, net_total, date, index }) => {
      return (
        <CustomIndexTableRow
          id={id}
          key={id}
          // selectedItems={selectedArray} // send for checking the check box is checked
          // setSelectedItems={setSelectedArray}
          showCheckbox={false} // show the check box
        >
          <CustomIndexTableCell>{id}</CustomIndexTableCell>

          <CustomIndexTableCell>{count}</CustomIndexTableCell>
          <CustomIndexTableCell>
            {formatIndianCurrency(id)}
          </CustomIndexTableCell>
        </CustomIndexTableRow>
      );
    }
  );

  const calculateTotal = (items, key) => {
    return 0;
  };
  const handleDownload = () => {
    const html2pdf = require("html2pdf.js");
    const originalTable = document.getElementById("receipt");
    const clonedTable = originalTable.cloneNode(true);

    clonedTable.style.fontSize = "12px";
    clonedTable.style.fontFamily = "Arial, sans-serif";
    clonedTable.style.lineHeight = "1.5";
    clonedTable.style.margin = "auto";

    const tempContainer = document.createElement("div");
    tempContainer.appendChild(clonedTable);

    html2pdf()
      .from(tempContainer)
      .set({
        margin: [0.5, 0.5, 0.5, 0.5], // Adjust margin as needed
        filename: "receipt.pdf",
        image: { type: "jpeg", quality: 5 }, // Use higher quality for images
        html2canvas: { scale: 1 }, // Increase scale for higher resolution
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" }, // Adjust PDF format and orientation as needed
      })
      .save();
  };

  const downloadCSV = () => {
    // Prepare CSV content
    const csvHeaders = "Category,Item,Count,Sale";
    const csvContent = `${csvHeaders}\n${departureReport
      .map((category) =>
        category.items
          .map((item) =>
            [category.category, item.item, item.count, item.sale].join(",")
          )
          .join("\n")
      )
      .join("\n")}`;

    // Create a blob with CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link element to trigger download
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "sales_data.csv");
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // console.log(departureReport);

  const rowMarkup = departureReport?.map(
    ({
      id,
      item_category,
      MTD_average_purchase_cost,
      QTD_average_purchase_cost,
      YTD_average_purchase_cost,
      supplier_gst_no,
      supplier_name,
      invoice_no,
      purchase_date,
      grand_total,
      paid_amount,
      due_amount,
      payment_status,
    }) => (
      <CustomReportTableRow
        CustomReportTableRow
        id={id}
        key={id}
        // bodyStyles={{
        //   backgroundColor: "red",
        //   fontSize: "10px",
        // }}
      >
        <CustomReportTableCell>{item_category}</CustomReportTableCell>
        <CustomReportTableCell>
          {supplier_name ? supplier_name : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {supplier_gst_no ? supplier_gst_no : "N/A"}
        </CustomReportTableCell>

        <CustomReportTableCell>
          {formatIndianCurrency(MTD_average_purchase_cost)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(QTD_average_purchase_cost)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(YTD_average_purchase_cost)}
        </CustomReportTableCell>
      </CustomReportTableRow>
    )
  );

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>{"Totals"}</CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>

      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "MTD_average_purchase_cost")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "QTD_average_purchase_cost")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "YTD_average_purchase_cost")
        )}
      </CustomReportTableCell>
    </CustomReportTableRow>
  );
  const extractedData = departureReport?.map((item) => ({
    item_category: item && item?.item_category ? item?.item_category : "N/A",
    supplier_name: item && item?.supplier_name ? item?.supplier_name : "N/A",
    supplier_gst_no:
      item && item?.supplier_gst_no ? item?.supplier_gst_no : "N/A",
    MTD_average_purchase_cost:
      item && item?.MTD_average_purchase_cost
        ? item?.MTD_average_purchase_cost
        : "N/A",
    QTD_average_purchase_cost:
      item && item?.QTD_average_purchase_cost
        ? item?.QTD_average_purchase_cost
        : "N/A",
    YTD_average_purchase_cost:
      item && item?.YTD_average_purchase_cost
        ? item?.YTD_average_purchase_cost
        : "N/A",
  }));

  const names = [
    {
      item_category: "Category",
      supplier_name: "Supplier Name",
      supplier_gst_no: "GST No",
      MTD_average_purchase_cost: "MTD Average Purchase Cost per unit (₹)",
      QTD_average_purchase_cost: "QTD Average Purchase Cost per unit (₹)",
      YTD_average_purchase_cost: "YTD Average Purchase Cost per unit (₹)",
    },
  ];
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div
          id="receipt"
          className="room-types-full-container reports-new-background"
        >
          <div className="room-types-full-container-div">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
                <div className="room-type-heading">
                  Category Wise Supplier Analysis Report
                </div>
                <div
                  className="hoverCursor"
                  onClick={() => setInformationPopup(true)}
                  id="ignoreFromPrint"
                >
                 <SvgGetInfo />
                </div>
              </div>
              {departureReport.length > 0 && (
                <div className="flex flex_gap_15 align_items_center" id="ignoreFromPrint">
                  <ButtonToDownloadTablePDF
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Category_Wise_Supplier_Analysis_Report"}
                    ignoreFromPrint={"ignoreFromPrint"}
                    // pdfStyles={

                    // }
                  />
                  <DownloadCSVButton
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Category_Wise_Supplier_Analysis_Report"}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            {departureReport && departureReport.length > 0 ? (
              <>
                {/* {departureReport &&
                  departureReport.length > 0 &&
                  departureReport.map((order) => {
                    return (
                      <div className="mt-20">
                        <div
                          className="category_name"
                          style={{ marginBottom: "10px" }}
                        >
                          {order.category}
                        </div> */}

                <CustomReportTable
                  headings={[
                    "Category",
                    "Supplier Name",
                    "GST No",
                    "MTD",
                    "QTD",
                    "YTD",
                  ]}
                  commonHeadings={[
                    "",
                    "",
                    "",
                    "Average Purchase Cost per unit (₹)",
                    "Average Purchase Cost per unit (₹)",
                    "Average Purchase Cost per unit (₹)",
                  ]}
                  headerStyles={{
                    backgroundColor: "#dddddd",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                  tableStyles={{ textAlign: "left" }}
                >
                  {rowMarkup}
                  {calculateTotals}
                </CustomReportTable>
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      )}

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of Category-Wise Supplier Analysis"
        >
          <div className="mb-20">
            {/* <h2 className="mb-10"> Accordion Component </h2> */}
            <AccordionComponent
              questions={[
                {
                  question: "What is a Category-Wise Supplier Analysis?",
                  answer: (
                    <p>
                      A <b>Category-Wise Supplier Analysis report</b> provides a
                      detailed breakdown of procurement activities based on
                      product categories (such as Groceries, Beverages, etc.).
                      It includes supplier names, GST numbers, and the average
                      purchase cost per unit for each category. The report
                      allows businesses to evaluate supplier performance and
                      pricing trends over different timeframes, including MTD
                      (Month-to-Date), QTD (Quarter-to-Date), and YTD
                      (Year-to-Date).
                    </p>
                  ),
                },
                {
                  question: "Importance of a Category-Wise Supplier Analysis",
                  answer: (
                    <div>
                      <p>
                        The Category-Wise Supplier Analysis is essential for
                        businesses as it helps with:
                      </p>

                      <ul>
                        <li>
                          <b>Category Cost Management:</b> Provides insights
                          into the cost of purchasing goods in different
                          categories, allowing businesses to identify the most
                          cost-effective suppliers.
                        </li>
                        <li>
                          <b>Supplier Performance Evaluation:</b> Businesses can
                          assess which suppliers offer the best pricing and
                          reliability within specific categories, aiding in
                          decision-making.
                        </li>
                        <li>
                          <b>Procurement Strategy Optimization:</b> By
                          understanding cost variations and trends in different
                          categories over time, businesses can optimize their
                          procurement strategies and budget allocations.
                        </li>
                        <li>
                          <b>Improved Vendor Negotiations:</b> The report
                          supports negotiations by providing clear data on
                          average purchase costs, helping businesses secure
                          better deals with suppliers.
                        </li>
                        <li>
                          <b>Inventory Planning:</b> Offers a clear picture of
                          supply chain efficiency and helps plan inventory
                          requirements based on purchasing patterns in different
                          product categories.
                        </li>
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportCategoryViseSupplier;
