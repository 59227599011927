import React, { useState, useRef, useEffect } from "react";
import "./customSelectOptionsComponent.css";

const CustomSelectOptionsComponent = ({
  options,
  optionKeyName,
  getSelectedOptionObject,
  required,
  title,
  defaultValue,
  error,
  disabled,
  hint = "Select",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const selectRef = useRef(null);
  useEffect(() => {
    setSelectedOption(defaultValue);
  }, [defaultValue]);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (option) => {
    setSelectedOption(option[optionKeyName]);
    getSelectedOptionObject(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ marginBottom: "3px" }}>
        <label className="Polaris-Label__Text">
          {title}
          {required === true ? <span style={{ color: "red" }}> *</span> : ""}
        </label>
      </div>
      <div
        ref={selectRef}
        className={`custom_select_component_container ${
          isOpen ? "custom-search-input-container-focused" : ""
        } `}
        // style={{ width: "100%" }}
      >
        <div
          style={{
            cursor: disabled || !options?.length ? "not-allowed" : "pointer",
            borderColor: error ? "#E03838" : "#ddd",
            backgroundColor: error ? "#FFEAEA" : disabled ? "#eee" : "",
          }}
          className={`custom_select_component_select_box`}
          onClick={disabled || !options ? null : handleToggleDropdown}
        >
          <span>{selectedOption || hint}</span>
          <span
            className={`custom_select_component_arrow ${
              isOpen ? "custom_select_component_open" : ""
            }`}
          >
            <svg
              width="12"
              height="7"
              viewBox="0 0 12 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 1L6 6L1 1"
                stroke="#333333"
                strokeWidth="1.2"
              ></path>
            </svg>
          </span>
        </div>
        {isOpen && (
          <div className="custom_select_component_options-list overflowContainer">
            {options && options.length > 0 ? (
              <>
                {options?.map((option, index) => (
                  <div
                    key={index}
                    className="custom_select_component_option"
                    onClick={() => handleSelectOption(option)}
                  >
                    {option[optionKeyName]}
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="custom_select_component_option">No data</div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomSelectOptionsComponent;
