
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import "./DashboardCompetitorAnalysis.css";
import TextDateFormatter from "../../Communication/TextDateFormatter/TextDateFormatter";
import { Link } from "react-router-dom";
import SkeletonLoadingTable from "./SkeletonTable";
import { getDecryptedData } from "../../../utils/encryptStorage";

const DashboardCompetitorAnalysisTwo = ({ orderLimit, minRowCount }) => {
  const currentDate = new Date().toISOString().split("T")[0];
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);

  useEffect(() => {
    const fetchData = async (propertyId) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/competitors/rates/${propertyId}?date=${currentDate}`
        );
        const data = await response.json();
        setData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (property_id) {
      fetchData(property_id?.id);
    }
  }, [property_id]);
  

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const uniqueDates = [
    ...new Set(data?.property_rates.map((item) => item.date)),
  ].slice(0, 7);

  const arrayFromOrderLimit = Array.from(
    { length: orderLimit },
    (_, index) => index + 1
  );

  //for empty rows
  const competitorData = data?.competitors;
  const minimumEmptyRows = data && minRowCount;
  const emptyCompetitors =
    competitorData &&
    Array.from(
      { length: minimumEmptyRows - competitorData.length },
      (_, index) => ({ id: `empty_${index}`, competitor_name: "", rates: [] }) //check spellings if any errors occurs in api
    );

  return (
    <div className="DashboardCompetitorAnalysisTwoContainer">
      <div className="DashboardCompetitorAnalysisTwo-heading">
        Competitor Analysis
      </div>
      <div className="DashboardCompetitorAnalysisTwo-hr"></div>
      {loading ? (
        <SkeletonLoadingTable />
      ) : (
        <table className="DashboardCompetitorAnalysisTwo-table">
          <thead>
            <tr className="DashboardCompetitorAnalysisTwo-table-header">
              <th>Name</th>
              {uniqueDates.map((date) => (
                <th key={date}>
                  <TextDateFormatter as={"div"} date={date} />
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            <tr className="DashboardCompetitorAnalysisTwo-table-row dashboardCompetitorAnalysisTwo_backgroundColor">
              <td>{data?.property_rates[0]?.property_name}</td>
              {uniqueDates &&
                uniqueDates.length > 0 &&
                uniqueDates.map((date) => {
                  const rate =
                    data?.property_rates?.find((item) => item.date === date)
                      ?.rate_bar_price || "";
                  return <td key={date}>{rate}</td>;
                })}
            </tr>

            <tr className="DashboardCompetitorAnalysisTwo-table-row DashboardCompetitorAnalysisTwo-AverageRates dashboardCompetitorAnalysisTwo_backgroundColor ">
              <td>Competitor Avg. Rate</td>
              {uniqueDates &&
                uniqueDates.length > 0 &&
                uniqueDates.map((date) => {
                  const rate =
                    data?.competitor_avg_rates?.find(
                      (item) => item.rate_date === date
                    )?.rate || "";
                  return <td key={date}>{rate}</td>;
                })}
            </tr>

            <tr className="DashboardCompetitorAnalysisTwo-table-row DashboardCompetitorAnalysisTwo-AverageRates dashboardCompetitorAnalysisTwo_backgroundColor">
              <td>MMT Rates</td>
              {uniqueDates.map((date) => {
                const rate =
                  data?.mmt_rates.find((item) => item.date === date)
                    ?.rate_date || "0";

                const property_rate =
                  data?.property_rates.find((item) => item.date === date)
                    ?.rate_bar_price || "0";

                return <td key={date}>{rate}</td>;
              })}
            </tr>

            {/* show competitors here */}

            {competitorData &&
              [...competitorData, ...emptyCompetitors].map((item) => (
                <tr
                  className="DashboardCompetitorAnalysisTwo-table-row"
                  key={item.id}
                >
                  <td>{item.competitor_name}</td>

                  {uniqueDates &&
                    uniqueDates.length > 0 &&
                    uniqueDates.map((date) => {
                      const rate =
                        item.rates?.find((item) => item.rate_date === date)
                          ?.rate || "";
                      return <td key={date}>{rate}</td>;
                    })}
                </tr>
              ))}

            {/* upgrade to pro */}

            {orderLimit > 0 && data && (
              <>
                <tr>
                  <td colSpan={8}>
                    <table className="DashboardCompetitorAnalysisTwo-table">
                      <tbody style={{ position: "relative" }}>
                        <tr>
                          <td>
                            <div className="DashboardCompetitorAnalysisTwo-upgradeContainer">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M6 22C5.45 22 4.97933 21.8043 4.588 21.413C4.19667 21.0217 4.00067 20.5507 4 20V10C4 9.45 4.196 8.97933 4.588 8.588C4.98 8.19667 5.45067 8.00067 6 8H7V6C7 4.61667 7.48767 3.43767 8.463 2.463C9.43833 1.48833 10.6173 1.00067 12 1C13.3833 1 14.5627 1.48767 15.538 2.463C16.5133 3.43833 17.0007 4.61733 17 6V8H18C18.55 8 19.021 8.196 19.413 8.588C19.805 8.98 20.0007 9.45067 20 10V20C20 20.55 19.8043 21.021 19.413 21.413C19.0217 21.805 18.5507 22.0007 18 22H6ZM12 17C12.55 17 13.021 16.8043 13.413 16.413C13.805 16.0217 14.0007 15.5507 14 15C14 14.45 13.8043 13.9793 13.413 13.588C13.0217 13.1967 12.5507 13.0007 12 13C11.45 13 10.9793 13.196 10.588 13.588C10.1967 13.98 10.0007 14.4507 10 15C10 15.55 10.196 16.021 10.588 16.413C10.98 16.805 11.4507 17.0007 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
                                  fill="#3968ED"
                                />
                              </svg>
                              <Link
                                to={"https://revcatalyst.in"}
                                target="_blank"
                                className="DashboardCompetitorAnalysisTwo-upgradeBtn"
                              >
                                Upgrade Your Plan
                              </Link>
                              <p className="DashboardCompetitorAnalysisTwo-upgradeText">
                                Upgrade your plan to add more competitors and
                                avail access to more exclusive features.
                              </p>
                            </div>
                          </td>
                        </tr>
                        {arrayFromOrderLimit?.map((_, index) => (
                          <React.Fragment key={index}>
                            <tr
                              key={`upgradeRow-${index}`}
                              className="DashboardCompetitorAnalysisTwo-table-row"
                            >
                              <td>
                                <div className="DashboardCompetitorAnalysisUpgradeHotelRowHotelName"></div>
                              </td>
                              <td>
                                <div className="DashboardCompetitorAnalysisTwo-table-data-upgradePlan"></div>
                              </td>
                              <td>
                                <div className="DashboardCompetitorAnalysisTwo-table-data-upgradePlan"></div>
                              </td>
                              <td>
                                <div className="DashboardCompetitorAnalysisTwo-table-data-upgradePlan"></div>
                              </td>
                              <td>
                                <div className="DashboardCompetitorAnalysisTwo-table-data-upgradePlan"></div>
                              </td>
                              <td>
                                <div className="DashboardCompetitorAnalysisTwo-table-data-upgradePlan"></div>
                              </td>
                              <td>
                                <div className="DashboardCompetitorAnalysisTwo-table-data-upgradePlan"></div>
                              </td>
                              <td>
                                <div className="DashboardCompetitorAnalysisTwo-table-data-upgradePlan"></div>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default DashboardCompetitorAnalysisTwo;
