import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./breadCrumb.module.scss";

const BreadCrumb = ({ withBorder, showHome, customLinks }) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((path) => path);

  const svgArrow = () => {
    return (
      <svg
        width="6"
        height="11"
        viewBox="0 0 6 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.77084 5.56998C5.77084 5.73604 5.70542 5.90713 5.57962 6.03293L1.20675 10.4108C0.95011 10.6675 0.532448 10.6675 0.275813 10.4108C0.019177 10.1542 0.019177 9.73654 0.275813 9.4799L4.18573 5.56998L0.275813 1.66006C0.0191771 1.40342 0.0191771 0.990791 0.275813 0.734156C0.532448 0.47752 0.95011 0.47752 1.20171 0.734156L5.57962 5.11206C5.70542 5.23786 5.77084 5.40895 5.77084 5.57501L5.77084 5.56998Z"
          fill="#858585"
        />
      </svg>
    );
  };
  const formatPath = (path) => {
    return path
      .split("-") // Split the path on dashes
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" "); // Join the words back with spaces
  };

  console.log(customLinks, "path names");
  return (
    <nav
      aria-label="breadcrumb"
      className={`${styles.breadCrumbContainer} ${
        withBorder ? styles.withBorder : ""
      }`}
    >
      <ol>
        {showHome ? (
          <li>
            <Link to="/"> Home </Link>
          </li>
        ) : null}
        {customLinks && customLinks?.length > 0
          ? customLinks?.map((path, index) => (
              <li key={index}>
                {!showHome && index === 0 ? null : svgArrow()}

                {index < customLinks.length - 1 ? (
                  <Link to={path?.path}>{path?.label}</Link>
                ) : (
                  <span className={styles.lastLink}>{path?.label}</span>
                )}
              </li>
            ))
          : pathnames.map((path, index) => {
              const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
              const isLast = index === pathnames.length - 1;

              return (
                <li key={index} style={{ display: "flex" }}>
                  {!showHome && index === 0 ? null : svgArrow()}
                  {isLast ? (
                    <span className={styles.lastLink}>{formatPath(path)}</span>
                  ) : (
                    <Link to={routeTo}>{formatPath(path)}</Link>
                  )}
                </li>
              );
            })}
      </ol>
    </nav>
  );
};

export default BreadCrumb;
