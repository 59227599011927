import React from 'react'

const ModifyReservationSelectComponent = ({titleName,
    optionKeyName,
    selectedOptions,
    name,
    onChange,
    value,
    type,
    options,
    optionOnclick,
    required,
    disabled,
    style,
    error}) => {
        const isOptionDisabled = (option) => {
            const optionRoomNo = String(option[optionKeyName]);
            return selectedOptions?.includes(optionRoomNo);
          };
  return (
    <div>
    <div style={{ marginBottom: "0.25rem" }}>
      <label htmlFor="standard-select">
        {titleName}{" "}
        {required === true ? <span style={{ color: "red" }}> *</span> : ""}
      </label>
    </div>
    <div className="select">
      <select
        disabled={disabled}
        name={name}
        value={value}
        onChange={onChange}
        type={type}
        className="select-custom"
        // style={style}
        style={{
          width: "100%",
          minHeight: "2.25rem",
          border: error ? "1px solid red" : "0.0625rem solid #888",
          borderRadius: "0.25rem",
          paddingLeft: "5px",
          backgroundColor: error ? "#FFEAEA" : "",

          ...style,
        }}
        required={required}
      >
        <option value={""}> select </option>
        {options?.map((option, index) => {
          return (
            <option
              onClick={optionOnclick}
              key={index}
              value={option[optionKeyName]}
              disabled={isOptionDisabled(option)}
            >
              {option[optionKeyName]}
            </option>
          );
        })}
      </select>
    </div>
  </div>
  )
}

export default ModifyReservationSelectComponent