import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";

const ReportPaymentTaxReport = ({ departureReport, loader }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const rowMarkup =
    departureReport &&
    departureReport.length > 0 &&
    departureReport?.map((order) => (
      <IndexTable.Row id={order.id} key={order.id} position={order.index}>
        <IndexTable.Cell>{order.date}</IndexTable.Cell>
        <IndexTable.Cell>
          <UnderscoreRemoveCapitalize text={order.payment_mode} />{" "}
        </IndexTable.Cell>
        <IndexTable.Cell>{order.settlement_amount}</IndexTable.Cell>
      </IndexTable.Row>
    ));

  const extractedData = [
    {
      name: 'Revenue Tax',
      current_date_amount:departureReport?.revenue_tax ? departureReport?.revenue_tax?.current_date_amount : null,
      mtd_amount: departureReport?.revenue_tax ? departureReport?.revenue_tax?.mtd_amount : null,
      ytd_amount:departureReport?.revenue_tax ? departureReport?.revenue_tax?.ytd_amount : null,
    },
    {
      name: 'Food Revenue Tax',
      current_date_amount:departureReport.food_revenue_tax ? departureReport?.food_revenue_tax?.current_date_amount : null,
      mtd_amount: departureReport.food_revenue_tax ? departureReport?.food_revenue_tax?.mtd_amount : null,
      ytd_amount:departureReport.food_revenue_tax ? departureReport?.food_revenue_tax?.ytd_amount : null,
    },
  ];
  
  
  const names = [
    {
      name:'Name',
      current_date_amount:'Current Date Amount',
      mtd_amount:'Mtd Amount',
      ytd_amount:'Ytd Amount',
    },
  ];

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="room-types-full-container" id="tax_report">
          <div className="room-types-full-container-div">
            <div className="room-type-heading">Tax Report</div>
            {departureReport && (
              <div className="flex flex_gap_15">
                <DownloadPdfTables
                  tableData={extractedData}
                  tableNames={names}
                  downloadFileName={"Tax_Report"}
                />
                {/* <DownloadCsvTables tableData={extractedData} /> */}
                 <DownloadCSVButton itemToDownloadID={"tax_report"} downloadFileName={"Tax_Report"} />
              </div>
            )}
          </div>

          {/* {departureReport && departureReport.length > 0 ? (
        <>
          <LegacyCard>
            <IndexTable
              resourceName={resourceName}
              itemCount={departureReport.length}
              selectable={false}
              headings={[
                { title: "Date" },
                { title: "Payment Method" },
                { title: "Settlement Amount (₹)" },
              ]}
            >
              {rowMarkup}
            </IndexTable>
          </LegacyCard>
        </>
      ) : (
        <NoDataFound />
      )} */}

          <div>

            {/* <div
              style={{
                width: "100%",
                padding: "20px",
                borderRadius: "5px",
                background: "#fff",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ fontWeight: "500", width: "15%" }}>Revenue Tax</div>
                <div>
                  <div>Current Date Amount</div>
                  <div>{departureReport?.revenue_tax?.current_date_amount}</div>
                </div>
                <div>
                  <div>MTD</div>
                  <div>{departureReport?.revenue_tax?.mtd_amount}</div>
                </div>
                <div>
                  <div>YTD</div>
                  <div>{departureReport?.revenue_tax?.ytd_amount}</div>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                padding: "20px",
                borderRadius: "5px",
                background: "#fff",
                gap: "10px",
                marginBottom: "10px"
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ fontWeight: "500", width: "15%" }}>Food Revenue Tax</div>
                <div>
                  <div>Current Date Amount</div>
                  <div>{departureReport?.food_revenue_tax?.current_date_amount}</div>
                </div>
                <div>
                  <div>MTD</div>
                  <div>{departureReport?.food_revenue_tax?.mtd_amount}</div>
                </div>
                <div>
                  <div>YTD</div>
                  <div>{departureReport?.food_revenue_tax?.ytd_amount}</div>
                </div>
              </div>
            </div> */}
          </div>
          

          <table style={{ width: "100%",background:"#fff",borderRadius:"8px",borderSpacing:"0px" }}>
        <thead>
          <tr>
            <th style={{ padding: "10px", background: "#ddd", textAlign: "left",WebkitBorderTopLeftRadius:"8px" }}>Category</th>
            <th style={{ padding: "10px", background: "#ddd", textAlign: "left" }}>Current Date Amount</th>
            <th style={{ padding: "10px", background: "#ddd", textAlign: "left" }}>MTD</th>
            <th style={{ padding: "10px", background: "#ddd", textAlign: "left",WebkitBorderTopRightRadius:"8px"  }}>YTD</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ padding: "10px" }}>Revenue Tax</td>
            <td style={{ padding: "10px" }}>{departureReport?.revenue_tax?.current_date_amount ? departureReport?.revenue_tax?.current_date_amount : "NA"}</td>
            <td style={{ padding: "10px" }}>{departureReport?.revenue_tax?.mtd_amount ? departureReport?.revenue_tax?.mtd_amount : "NA"}</td>
            <td style={{ padding: "10px" }}>{departureReport?.revenue_tax?.ytd_amount? departureReport?.revenue_tax?.ytd_amount : "NA"}</td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>Food Revenue Tax</td>
            <td style={{ padding: "10px" }}>{departureReport?.food_revenue_tax?.current_date_amount ? departureReport?.food_revenue_tax?.current_date_amount : "NA"}</td>
            <td style={{ padding: "10px" }}>{departureReport?.food_revenue_tax?.mtd_amount ? departureReport?.food_revenue_tax?.mtd_amount : "NA"}</td>
            <td style={{ padding: "10px" }}>{departureReport?.food_revenue_tax?.ytd_amount ? departureReport?.food_revenue_tax?.ytd_amount : "NA"}</td>
          </tr>
        </tbody>
      </table>



         
        </div>
      )}
    </>
  );
};
export default ReportPaymentTaxReport;
