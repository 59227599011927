import React, { useState, useEffect, useRef } from "react";
import "./SearchInputTypeComponent.css";

const SearchInputTypeComponent = ({
  userData,
  onUserSelect,
  required,
  titleName,
  name,
  onChange,
  value,
  type,
  defaultValue,
  label,
  placeholder,
  min,
  max,
  onKeyDown,
  disabled,
  inputId,
  maxLength,
  onBlur,
  style,
  error,
  format,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setFilteredUsers(false);
    }
  };
  useEffect(() => {
    if (filteredUsers) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [filteredUsers]);

  const handleBlur = () => {
    setIsTyping(false);
  };

  const handleChange = (e) => {
    setIsTyping(true);
    const value = e.target.value;
    setInputValue(value);
    const filteredData = userData?.filter((user) =>
      user?.label?.toLowerCase()?.includes(value.toLowerCase())
    );
    setFilteredUsers(filteredData);
  };

  const handleUserClick = (user) => {
    // setInputValue(user.label);
    // setFilteredUsers([user]);
    onUserSelect(user);
    setFilteredUsers("");
  };


  return (
    <div className="user_data_parent">
      <div>
        <div className="Polaris-Labelled__LabelWrapper">
          <div className="Polaris-Label">
            <label className="Polaris-Label__Text">
              {titleName}
              {required === true ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>

        <div className="Polaris-connected">
          <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
            <div className="Polaris-TextField">
              <input
                type="text"
                placeholder="Type here to filter users"
                value={inputValue}
                onFocus={() => setInputValue("")}
                onChange={handleChange}
                format={format}
                style={style}
                onBlur={handleBlur}
                maxLength={maxLength}
                id={inputId}
                disabled={disabled}
                onKeyDown={onKeyDown}
                required={required}
                defaultValue={defaultValue}
                autoComplete="off"
                className={
                  disabled === true
                    ? "Polaris-TextField__Input Polaris-TextField__Input_disabled"
                    : "Polaris-TextField__Input"
                }
                name={name}
                min={min}
                max={max}
              ></input>
              <div
                className={
                  error
                    ? "Polaris-TextField__Backdrop__errors"
                    : "Polaris-TextField__Backdrop"
                }
              ></div>
            </div>
          </div>
        </div>

        {/* <input
                    type="text"
                    placeholder="Type here to filter users"
                    value={inputValue}
                    onChange={handleChange}
                /> */}

        <ul ref={popupRef}>
          {filteredUsers && filteredUsers.length > 0 ? (
            <>
              {filteredUsers.map((user) => (
                <div className="user_list_parent">
                  <li key={user.value} onClick={() => handleUserClick(user.value)}>
                    {user.label}
                  </li>
                </div>
              ))}
            </>
          ) : (
            <>{isTyping && <li>No Data Found</li>}</>
          )}
        </ul>
      </div>
    </div>
  );
};
export default SearchInputTypeComponent;
