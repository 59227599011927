import React from "react";
import Sidebar from "../Components/Sidebar";
import CompetitorsManage from "../Components/CompetitorsManage";


const CompetitorsManagePage = () => {
  return (
    <>
      <div className="main_container">
        <CompetitorsManage />
      </div>
    </>
  );
};

export default CompetitorsManagePage;
