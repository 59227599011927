import React from "react";
import { NavLink, Outlet } from "react-router-dom";

const AgentAdvancePaymentPage = () => {
  const links = [
    { path: "used-advance", label: "Used Advance" },
    { path: "advance-payments", label: "Advance Payments" },
    { path: "advance-payments-report", label: "Advance Payments Report" },
    // { path: "added-credits", label: "Added Credits" },
  ];

  return (
    <div className="main_container">
      <div className="footer_inner_lists">
        <ul className="flex flex_gap_10">
          {links.map((link, index) => (
            <li key={index} style={{ listStyle: "none" }}>
              <NavLink
                to={link.path}
                className={({ isActive }) =>
                  `inner_list_item${isActive ? " active" : ""}`
                }
              >
                {link.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      <div style={{marginTop:"20px"}}>
        <Outlet />
      </div>
    </div>
  );
};

export default AgentAdvancePaymentPage