import {
  TextField,
  IndexTable,
  LegacyCard,
  useSetIndexFiltersMode,
  useIndexResourceState,
  ChoiceList,
  RangeSlider,
  Button,
  Icon,
  Select,
  Page,
} from "@shopify/polaris";

import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  WithoutBgButtonBlue,
} from "./UI/Buttons";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import Loader from "./UI/Loader/Loader";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import NoDataFound from "./CustomComponents/NoDataFound";
import { banquet_inner_lists, pms_inner_lists } from "../assets/constant";
import SidebarInnerLists from "./CustomComponents/SidebarInnerLists";
import { getDecryptedData } from "../utils/encryptStorage";

const BanquetHall = () => {
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddStockModelMenuActive, setShowAddStockModelMenuActive] =
    useState(false);

  const [showAddItemModelMenuActive, setShowAddItemModelMenuActive] =
    useState(false);

  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [itemStrings, setItemStrings] = useState([
    "Past 7 Days",
    "Past 30 Days",
    "Past 90 Days",
    "Select Date Range",
  ]);
  const deleteView = (index) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    setSelected(0);
  };

  const duplicateView = async (name) => {
    setItemStrings([...itemStrings, name]);
    setSelected(itemStrings.length);
    await sleep(1);
    return true;
  };

  const tabs = itemStrings.map((item, index) => ({
    content: item,
    index,
    onAction: () => {},
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions:
      index === 0
        ? []
        : [
            {
              type: "rename",
              onAction: () => {},
              onPrimaryAction: async (value) => {
                const newItemsStrings = tabs.map((item, idx) => {
                  if (idx === index) {
                    return value;
                  }
                  return item.content;
                });
                await sleep(1);
                setItemStrings(newItemsStrings);
                return true;
              },
            },
            {
              type: "duplicate",
              onPrimaryAction: async (value) => {
                await sleep(1);
                duplicateView(value);
                return true;
              },
            },
            {
              type: "edit",
            },
            {
              type: "delete",
              onPrimaryAction: async () => {
                await sleep(1);
                deleteView(index);
                return true;
              },
            },
          ],
  }));
  const [selected, setSelected] = useState(0);
  const onCreateNewView = async (value) => {
    await sleep(500);
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    return true;
  };
  const sortOptions = [
    { label: "Order", value: "order asc", directionLabel: "Ascending" },
    { label: "Order", value: "order desc", directionLabel: "Descending" },
    { label: "Customer", value: "customer asc", directionLabel: "A-Z" },
    { label: "Customer", value: "customer desc", directionLabel: "Z-A" },
    { label: "Date", value: "date asc", directionLabel: "A-Z" },
    { label: "Date", value: "date desc", directionLabel: "Z-A" },
    { label: "Total", value: "total asc", directionLabel: "Ascending" },
    { label: "Total", value: "total desc", directionLabel: "Descending" },
  ];

  const [sortSelected, setSortSelected] = useState(["order asc"]);
  const { mode, setMode } = useSetIndexFiltersMode();
  const onHandleCancel = () => {};

  const onHandleSave = async () => {
    await sleep(1);
    return true;
  };

  const roomAreaTypeOptions = [
    { label: "Select option" },
    { label: "Square Meters", value: "square_meters" },
    { label: "Square Feet", value: "square_feet" },
  ];

  const primaryAction =
    selected === 0
      ? {
          type: "save-as",
          onAction: onCreateNewView,
          disabled: false,
          loading: false,
        }
      : {
          type: "save",
          onAction: onHandleSave,
          disabled: false,
          loading: false,
        };
  const [accountStatus, setAccountStatus] = useState(undefined);
  const [moneySpent, setMoneySpent] = useState(undefined);
  const [taggedWith, setTaggedWith] = useState("");
  const [queryValue, setQueryValue] = useState("");

  const handleAccountStatusChange = useCallback(
    (value) => setAccountStatus(value),
    []
  );
  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );
  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleAccountStatusRemove = useCallback(
    () => setAccountStatus(undefined),
    []
  );
  const handleMoneySpentRemove = useCallback(
    () => setMoneySpent(undefined),
    []
  );
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAccountStatusRemove();
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [
    handleAccountStatusRemove,
    handleMoneySpentRemove,
    handleQueryValueRemove,
    handleTaggedWithRemove,
  ]);

  const navigate = useNavigate();
  const userToken = getDecryptedData('encryptToken');

  const property_id = useSelector((state) => state.property.value);

  const [loader, setLoader] = useState(true);

  const filters = [
    {
      key: "accountStatus",
      label: "Account status",
      filter: (
        <ChoiceList
          title="Account status"
          titleHidden
          choices={[
            { label: "Enabled", value: "enabled" },
            { label: "Not invited", value: "not invited" },
            { label: "Invited", value: "invited" },
            { label: "Declined", value: "declined" },
          ]}
          selected={accountStatus || []}
          onChange={handleAccountStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "taggedWith",
      label: "Tagged with",
      filter: (
        <TextField
          label="Tagged with"
          value={taggedWith}
          onChange={handleTaggedWithChange}
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: true,
    },
    {
      key: "moneySpent",
      label: "Money spent",
      filter: (
        <RangeSlider
          label="Money spent is between"
          labelHidden
          value={moneySpent || [0, 500]}
          prefix="$"
          output
          min={0}
          max={2000}
          step={1}
          onChange={handleMoneySpentChange}
        />
      ),
    },
  ];

  const appliedFilters = [];
  if (accountStatus && !isEmpty(accountStatus)) {
    const key = "accountStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, accountStatus),
      onRemove: handleAccountStatusRemove,
    });
  }
  if (moneySpent) {
    const key = "moneySpent";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, moneySpent),
      onRemove: handleMoneySpentRemove,
    });
  }
  if (!isEmpty(taggedWith)) {
    const key = "taggedWith";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }
  const active = (
    <div
      style={{
        color: "#15AA12",
        padding: "6px",
        backgroundColor: "#DFF8DF",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        fontFamily: "Inter",
        fontSize: "12px",
      }}
    >
      Active
    </div>
  );

  const expired = (
    <div
      style={{
        color: "#888",
        padding: "6px",
        backgroundColor: "#EEE",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        fontFamily: "Inter",
        fontSize: "12px",
      }}
    >
      Expired
    </div>
  );

  const scheduled = (
    <div
      style={{
        color: "#FFC107",
        padding: "6px",
        backgroundColor: "#FFF5DC",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
        fontFamily: "Inter",
        fontSize: "12px",
      }}
    >
      Scheduled
    </div>
  );

  const order = [
    {
      id: "1",
      venue: "Taj",
      area: "700",
      unit: "sq ft",
      capacity: "1665",
    },
    {
      id: "2",
      venue: "Taj",
      area: "700",
      unit: "sq ft",
      capacity: "1665",
    },
  ];

  const [orders, setOrders] = useState([]);

  const fetchInfo = async (propertyId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        // /api/v1/expenses/1
        `${process.env.REACT_APP_BASE_URL}/api/v1/banquet/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data.banquet);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

   useEffect(() => {
    if (property_id  && userToken)  {
      fetchInfo(property_id?.id);
    }
  }, [property_id, userToken]);

  setTimeout(() => {
    setLoader(false);
  }, 1000);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(orders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const addModalButtonClickHandler = () => {
    setShowAddStockModelMenuActive(!showAddStockModelMenuActive);
  };

  const editModalButtonClickHandler = () => {
    setShowEditModelMenuActive(!showEditModelMenuActive);
  };

  const removeAddModalContainer = () => {
    setShowAddStockModelMenuActive(!showAddStockModelMenuActive);
  };

  // UseState for forms
  const [addItemData, setAddItemData] = useState({
    // venue: "",
    // banquet_id: "",
    // banquet_address: "",
    // banquet_description: "",
    // banquet_parking: "",
    // banquet_parking_capacity: "",
    // banquet_city: "",
    // banquet_state: "",
    name: "",
    venue: "",
    capacity: "",
    area: "",
    unit: "",
  });

  const setAddItemDataHandleChange = (event) => {
    setAddItemData({
      ...addItemData,
      [event.target.name]: event.target.value,
    });
  };

  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/banquet/edit/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        venue: data.banquet.venue,
        name: data.banquet.name,
        area: data.banquet.area,
        unit: data.banquet.unit,
        capacity: data.banquet.capacity,
        // expense_date: data.banquet.expense_date,
        // expense_amount: data.banquet.expense_amount,
      };

      setAddItemData(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  const rowMarkup = orders?.map(
    ({ id, name, venue, area, unit, capacity, index }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          {" "}
          <CustomCssComponentTableText
            innerText={name}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {" "}
          <CustomCssComponentTableText
            innerText={venue}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {" "}
          <CustomCssComponentTableText innerText={area}>
            {" "}
            {unit}
          </CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={capacity}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const removeItemConfirm = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  // Plan Type Options
  const planTypeOptions = [
    { label: "Ep", value: "Ep" },
    { label: "CP", value: "CP" },
    { label: "MAP", value: "MAP" },
    { label: "AP", value: "AP" },
  ];

  const [planTypeSelected, setPlanTypeSelected] = useState("EP");
  const planTypeHandleSelectChange = useCallback(
    (value) => setPlanTypeSelected(value),
    []
  );

  // Plan Name Options
  const planNameOptions = [
    { label: "Room Only", value: "newestUpdate" },
    { label: "Room + Complimentary Breakfast", value: "oldestUpdate" },
    {
      label: "Room with Complimentary Breakfast and dinner",
      value: "mostSpent",
    },
    { label: "Breakfast + Lunch + Dinner", value: "mostOrders" },
  ];

  const [planNameSelected, setNameTypeSelected] = useState("EP");
  const planNameHandleSelectChange = useCallback(
    (value) => setNameTypeSelected(value),
    []
  );

  // Plan Name Options
  const planStatusOptions = [
    { label: "Booked", value: "Booked" },
    {
      label: "Available",
      value: "Available",
    },
  ];

  const [planStatusSelected, setPlanStatusSelected] = useState("Booked");
  const planStatusHandleSelectChange = useCallback(
    (value) => setPlanStatusSelected(value),
    []
  );

  // Plan Type Options
  const roomTypeOptions = [
    { label: "Deluxe", value: "Deluxe" },
    { label: "Super Deluxe", value: "Super Deluxe" },
    { label: "Cottage", value: "Cottage" },
    { label: "Luxury", value: "Luxury" },
    { label: "Executive", value: "Executive" },
    { label: "Family", value: "Family" },
  ];

  const [roomTypeSelected, setRoomTypeSelected] = useState("EP");
  const roomTypeHandleSelectChange = useCallback(
    (value) => setRoomTypeSelected(value),
    []
  );

  const [asignHotelChecked, setAsignHotelChecked] = useState(false);

  const asignHotelCheckboxHandleChange = useCallback(
    (newChecked) => setAsignHotelChecked(newChecked),
    []
  );

  const [purchaseHeadingActiveIndex, setPurchaseHeadingActiveIndex] =
    useState(null);
  const [purchaseHeadingActive, setPurchaseHeadingActive] = useState("");

  const setPurchaseHeading = (idx) => {
    setPurchaseHeadingActiveIndex(idx);
    if (purchaseHeadingActive === idx) {
      setPurchaseHeadingActive("active");
    } else {
      setPurchaseHeadingActive("");
    }
  };

  const updateFunction = (id, event) => {
    event.preventDefault();

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/banquet/update/${id}/${property_id?.id}`,
      {
        method: "POST",

        body: JSON.stringify({ ...addItemData }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          fetchInfo(property_id?.id);
          setShowEditModelMenuActive(false);
          clearSelection();
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleSubmitAddExpenseHandler = (event) => {
    event.preventDefault();
    // http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/assign/rooms/store
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/create/banquet/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...addItemData }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          navigate("/banquethall");
          showSuccessToast(data.message);
          setShowAddStockModelMenuActive(false);
          fetchInfo(property_id?.id);
          setAddItemData({});
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const deletedIds = {
    ids: selectedResources,
  };
  const deleteData = async () => {
    clearSelection();

    // "http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/assign/rooms/delete"
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/banquet/delete/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          clearSelection();
          fetchInfo(property_id?.id);
          clearSelection();
          setShowLowerMenuMultipleSelect(false);
          setShowModelMenuActive(false);
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  return (
    <>
      <SidebarInnerLists innerLists={banquet_inner_lists} />
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="room-types-full-container">
            <div className="room-types-full-container-div">
              <div className="room-type-heading">Banquet Hall</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <div>
                  <div style={{ textDecoration: "none" }}>
                    <BgThemeButton
                      onClick={() => {
                        navigate("/banquetmenus");
                        clearSelection();
                        setShowAddStockModelMenuActive(true);
                        setAddItemData({});
                      }}
                      children={"Banquet Menus"}
                    />
                  </div>
                </div>
                <div>
                  <div style={{ textDecoration: "none" }}>
                    <BgThemeButton
                      onClick={() => {
                        // navigate("/banquethall/add");
                        clearSelection();
                        setShowAddStockModelMenuActive(true);
                        setAddItemData({});
                      }}
                      children={"Add Banquet Hall"}
                    />
                  </div>
                </div>
              </div>
            </div>

            {orders && orders.length > 0 ? (
              <LegacyCard>
                <IndexTable
                  resourceName={resourceName}
                  itemCount={orders?.length}
                  selectedItemsCount={
                    allResourcesSelected ? "All" : selectedResources.length
                  }
                  onSelectionChange={handleSelectionChange}
                  headings={[
                    { title: "Name" },
                    { title: "Venue" },
                    { title: "Area" },
                    { title: "Capacity" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>

                {/* Single select */}
                {showLowerMenuSingleSelect && (
                  <div className=" room-type-bottom-container">
                    <div className="room-type-bottom-container-div">
                      <div>
                        <Button plain destructive onClick={showModelMenu}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <Icon source={DeleteMajor} color="base" />
                            </span>
                            <span>Remove</span>
                          </div>
                        </Button>
                      </div>
                      <form
                        onSubmit={(e) =>
                          updateFunction(selectedResources[0], e)
                        }
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="room-type-bottom-div">
                          <div>Venue</div>
                          <div style={{ width: "250px" }}>
                            <VerticalInputFieldCustom
                              type={"text"}
                              name="venue"
                              value={addItemData.venue}
                              onChange={setAddItemDataHandleChange}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            color: "#3968ED",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          {/* <Link
                            style={{ textDecoration: "none" }}
                            to={`/banquethall/edit/${selectedResources[0]}`}
                            // state={updatedData}
                          > */}
                          <BgOutlineThemeButton
                            onClick={() => setShowEditModelMenuActive(true)}
                            type={"button"}
                            children={"Edit More"}
                          />
                          {/* </Link> */}

                          <BgThemeButton type={"submit"} children={"Update"} />
                        </div>
                      </form>
                    </div>
                  </div>
                )}

                {/* Multiple select */}
                {showLowerMenuMultipleSelect && (
                  <div className="room-type-bottom-container">
                    <div className="room-type-bottom-container-div">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Button plain destructive onClick={showModelMenu}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <Icon source={DeleteMajor} color="base" />
                            </span>
                            <span>Remove</span>
                          </div>
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </LegacyCard>
            ) : (
              <NoDataFound />
            )}
          </div>

          {/* Add Expenses Button Click */}
          {showAddStockModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => handleSubmitAddExpenseHandler(e)}
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <div className="add-ota-model-container-heading">
                      Add Banquet Hall
                    </div>
                    <div
                      onClick={removeAddModalContainer}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type={"text"}
                        titleName="Name"
                        name="name"
                        value={addItemData.name}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type={"text"}
                        titleName="Venue"
                        name="venue"
                        value={addItemData.venue}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type={"number"}
                        titleName="Area"
                        name="area"
                        value={addItemData.area}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        options={roomAreaTypeOptions}
                        required={true}
                        type={"text"}
                        titleName="Area units"
                        name="unit"
                        value={addItemData.unit}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type={"number"}
                        titleName="Capacity"
                        name="capacity"
                        value={addItemData.capacity}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton type="submit" children={"Add"} />
                      <Button
                        onClick={() => setShowAddStockModelMenuActive(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}

          {/* Add Item Button Click */}
          {showAddItemModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="stock-add-model-container">
                  <div style={{ marginLeft: "-200px" }}>
                    <Page
                      backAction={{
                        content: "Products",
                        url: `/hotelier/purchase`,
                      }}
                      title="Add Issue Items"
                      compactTitle
                    ></Page>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <TextField
                        type="text"
                        className="textfield"
                        focused={false}
                        label="User Type"
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <TextField
                        type="text"
                        className="textfield"
                        focused={false}
                        label="Issued To"
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <TextField
                        type="date"
                        className="textfield"
                        focused={false}
                        label="Issued Date"
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <Select
                        className="textfield"
                        focused={false}
                        label="Item Name"
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <Select
                        className="textfield"
                        focused={false}
                        label="Select Category"
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <TextField
                        className="textfield"
                        focused={false}
                        label="Qty"
                        autoComplete="off"
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "20px",
                        width: "350px",
                      }}
                      className="form-container-div"
                    >
                      <WithoutBgButtonBlue children={"Add More"} />
                    </div>
                  </div>
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <Select
                        className="textfield"
                        focused={false}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <Select
                        className="textfield"
                        focused={false}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <TextField
                        className="textfield"
                        focused={false}
                        autoComplete="off"
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "350px",
                      }}
                      className="form-container-div"
                    >
                      <WithoutBgButtonBlue children={"Add More"} />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <BgThemeButton children={"Add"} />
                    <Button
                      onClick={() => setShowAddItemModelMenuActive(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </section>
              </div>
            </>
          )}

          {/* Edit Button Click */}
          {showEditModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => updateFunction(selectedResources[0], e)}
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <div className="add-ota-model-container-heading">
                      Edit Banquet Hall
                    </div>
                    <div
                      onClick={() => setShowEditModelMenuActive(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type={"text"}
                        titleName="Name"
                        name="name"
                        value={addItemData.name}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type={"text"}
                        titleName="Venue"
                        name="venue"
                        value={addItemData.venue}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type={"number"}
                        titleName="Area"
                        name="area"
                        value={addItemData.area}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        options={roomAreaTypeOptions}
                        required={true}
                        type={"text"}
                        titleName="Area units"
                        name="unit"
                        value={addItemData.unit}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type={"number"}
                        titleName="Capacity"
                        name="capacity"
                        value={addItemData.capacity}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton type="submit" children={"Update"} />
                      <Button onClick={() => setShowEditModelMenuActive(false)}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}

          {showModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-type-model-container ">
                  <h4>Are You Sure?</h4>
                  <p>You Want To Remove?</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        setShowModelMenuActive(!showModelMenuActive)
                      }
                    >
                      Discard
                    </Button>
                    <Button onClick={deleteData} destructive>
                      Yes, Remove
                    </Button>
                  </div>
                </section>
              </div>
            </>
          )}
        </>
      )}
    </>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "moneySpent":
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case "taggedWith":
        return `Tagged with ${value}`;
      case "accountStatus":
        return value.map((val) => `Customer ${val}`).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};
export default BanquetHall;
