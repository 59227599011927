import { useNavigate } from 'react-router-dom';
import './AddPropertyNew.css';
import { useEffect, useState } from 'react';
import VerticalInputFieldCustom from '../CustomComponents/VerticalInputFieldCustom';
import VerticalInputSelectCustomCopy from '../CustomComponents/VerticalInputSelectCustomCopy';
import { BgOutlineThemeButton, BgThemeButton } from '../UI/Buttons';
import VerticalTextAreaCustom from '../CustomComponents/VerticalTextAreaCustom';

const AddPropertyNew = () => {
    const navigate = useNavigate();
    const [selectedStep, setSelectedStep] = useState(1);
    const [loading, setLoading] = useState(true);
    const [congratulationPopup, setCongratulationPopup] = useState(false);

    const stepFormData = [
        {
            id: 1,
            content: 'Add General Details'
        },
        {
            id: 2,
            content: 'Add Contact Details'
        },
        {
            id: 3,
            content: 'Add Address'
        },
    ];

    const [addPropertyData, setAddPropertyData] = useState({
        property_name: "",
        property_phone: "",
        property_landline: "",
        property_email: "",
        property_hotel_type: "1 star",
        food_prefix: "",
        booking_prefix: "",
        property_address: "",
        property_country: "",
        property_city: "",
        property_state: "",
        property_tax_rate: 0,
        property_zip: null,
        property_description: "",
        property_checkin: "",
        property_checkout: "",

        property_facebook: "",
        property_instagram: "",
        property_twitter: "",
        property_linkedin: "",
        property_threads: "",
        property_whatsapp: "",
        property_description: "",
        property_status: "",
        property_hotel_id: "",

        property_rating: "",
        property_area: ""
    });

    const setAddPropertyDataHandleChange = (event) => {
        setAddPropertyData({
            ...addPropertyData,
            [event.target.name]: event.target.value,
        });
    };

    useEffect(() => {
        const storedStep = localStorage.getItem('selectedStep');
        if (storedStep) {
            setSelectedStep(parseInt(storedStep, 10));
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (!loading) {
            localStorage.setItem('selectedStep', selectedStep.toString());
        }
    }, [selectedStep, loading]);

    const propertyTypeOptions = [
        { label: "Select an Option", value: "" },
        { label: "Standarad", value: "Standarad" },
        { label: "Deluxe", value: "Deluxe" },
        { label: "Luxury", value: "Luxury" },
    ];

    const propertyRatingOptions = [
        { label: "Select an Option", value: "" },
        { label: "1 Star", value: "1 Star" },
        { label: "2 Star", value: "2 Star" },
        { label: "3 Star", value: "3 Star" },
        { label: "4 Star", value: "4 Star" },
        { label: "5 Star", value: "5 Star" },
    ];
    const propertyAreaOptions = [
        { label: "Select an Option", value: "" },
        { label: "Hilly Area", value: "HIlly Area" },
        { label: "Plain Area", value: "Plain Area" },
    ];

    const handleNext = () => {
        const nextStep = selectedStep < stepFormData.length ? selectedStep + 1 : selectedStep;
        setSelectedStep(nextStep);
        // setNextDayPopup(true)
    };

    const handlePrevious = () => {
        const previousStep = selectedStep > 1 ? selectedStep - 1 : selectedStep;
        setSelectedStep(previousStep);
    };

    return (
        <>
            <div className='flex justify_content_center align_items_center mt-20'>
                <div className='add_property_main'>
                    <div >
                        <div className='add_property_main_heading'>
                            <h2>Setup Your Property</h2>
                        </div>
                    </div>
                    <div className='night_audit_top_step_form add_property_step flex align_items_center justify_content_center'>
                        {stepFormData.map((item) => (
                            <div
                                className={`night_audit_top_step_form_child add_property_step_child flex align_items_center flex_gap_10 ${item.id <= selectedStep ? 'selected' : ''}`}
                                key={item.id}
                                style={{ flexBasis: `calc(100% / ${stepFormData.length})` }}
                            >
                                <div className='step_form_index flex align_items_center justify_content_center'>
                                    {item.id}
                                </div>
                                <div className='step_form_content'>
                                    <p>{item.content}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <form>
                        {selectedStep === 1 && (
                            <div>
                                <div className="form-container-div-half">
                                    <div className="form-container-div">
                                        <VerticalInputFieldCustom
                                            required={true}
                                            titleName="Property Name"
                                            name="property_name"
                                            value={addPropertyData.property_name}
                                            onChange={setAddPropertyDataHandleChange}
                                        />
                                    </div>
                                    <div className="form-container-div">
                                        <VerticalInputSelectCustomCopy
                                            required={true}
                                            name="property_hotel_type"
                                            options={propertyTypeOptions}
                                            className="textfield"
                                            titleName="Property Type"
                                            focused={false}
                                            onChange={setAddPropertyDataHandleChange}
                                            autoComplete="off"
                                            value={addPropertyData.property_hotel_type}
                                        />
                                    </div>
                                </div>
                                <div className="form-container-div-half">
                                    <div className="form-container-div">
                                        <VerticalInputSelectCustomCopy
                                            required={true}
                                            options={propertyRatingOptions}
                                            name="property_rating"
                                            className="textfield"
                                            titleName="Property Rating"
                                            focused={false}
                                            onChange={setAddPropertyDataHandleChange}
                                            autoComplete="off"
                                            value={addPropertyData.property_rating}
                                        />
                                    </div>
                                    <div className="form-container-div">
                                        <VerticalInputSelectCustomCopy
                                            required={true}
                                            options={propertyAreaOptions}
                                            name="property_area"
                                            className="textfield"
                                            titleName="Property Area"
                                            focused={false}
                                            onChange={setAddPropertyDataHandleChange}
                                            autoComplete="off"
                                            value={addPropertyData.property_area}
                                        />
                                    </div>
                                </div>
                                <div className="form-container-div-half">
                                    <div className="form-container-div">
                                        <VerticalInputFieldCustom
                                            required={true}
                                            type="time"
                                            name="property_checkin"
                                            className="textfield"
                                            focused={false}
                                            titleName="Check-In Time"
                                            onChange={setAddPropertyDataHandleChange}
                                            autoComplete="off"
                                            value={addPropertyData.property_checkin}
                                        />
                                    </div>
                                    <div className="form-container-div">
                                        <VerticalInputFieldCustom
                                            required={true}
                                            type="time"
                                            name="property_checkout"
                                            className="textfield"
                                            titleName="Check-Out Time"
                                            focused={false}
                                            onChange={setAddPropertyDataHandleChange}
                                            autoComplete="off"
                                            value={addPropertyData.property_checkout}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {selectedStep === 2 && (
                            <div>
                                <div className="form-container-div-half">
                                    <div className="form-container-div">
                                        <VerticalInputFieldCustom
                                            // required={true}
                                            maxLength={10}
                                            type={"number"}
                                            name="property_phone"
                                            className="textfield"
                                            focused={false}
                                            titleName="Phone Number"
                                            onChange={setAddPropertyDataHandleChange}
                                            autoComplete="off"
                                            value={addPropertyData.property_phone}
                                        // min={1}
                                        />
                                    </div>
                                    <div className="form-container-div">
                                        <VerticalInputFieldCustom
                                            type={"email"}
                                            required={true}
                                            name="property_email"
                                            className="textfield"
                                            titleName="Email"
                                            focused={false}
                                            onChange={setAddPropertyDataHandleChange}
                                            autoComplete="off"
                                            value={addPropertyData.property_email}
                                        />

                                    </div>
                                </div>
                                <div className="form-container-div-half">
                                    <div className="form-container-div">
                                        <VerticalInputFieldCustom
                                            required={true}
                                            type={"number"}
                                            name="property_landline"
                                            className="textfield"
                                            focused={false}
                                            titleName="Landline Number"
                                            onChange={setAddPropertyDataHandleChange}
                                            autoComplete="off"
                                            value={addPropertyData.property_landline}
                                        />
                                    </div>
                                    {/* <div className="form-container-div">
                                        <VerticalInputSelectCustomCopy
                                            required={true}
                                            options={propertyTypeOptions}
                                            name="property_hotel_type"
                                            className="textfield"
                                            titleName="Property Type"
                                            focused={false}
                                            onChange={setAddPropertyDataHandleChange}
                                            autoComplete="off"
                                            value={addPropertyData.property_hotel_type}
                                        />
                                    </div> */}
                                </div>
                            </div>
                        )}

                        {selectedStep === 3 && (
                            <div>
                                <div className="form-container-div-half">
                                    <div className="form-container-div">
                                        <VerticalInputFieldCustom
                                            required={true}
                                            name="property_city"
                                            className="textfield"
                                            titleName="City"
                                            focused={false}
                                            onChange={setAddPropertyDataHandleChange}
                                            autoComplete="off"
                                            value={addPropertyData.property_city}
                                        />
                                    </div>
                                    <div className="form-container-div">
                                        <VerticalInputFieldCustom
                                            required={true}
                                            name="property_state"
                                            className="textfield"
                                            titleName="State"
                                            focused={false}
                                            onChange={setAddPropertyDataHandleChange}
                                            autoComplete="off"
                                            value={addPropertyData.property_state}
                                        />
                                    </div>
                                </div>
                                <div className="form-container-div-half">
                                    <div className="form-container-div">
                                        <VerticalInputFieldCustom
                                            required={true}
                                            name="property_country"
                                            className="textfield"
                                            titleName="Country"
                                            focused={false}
                                            onChange={setAddPropertyDataHandleChange}
                                            autoComplete="off"
                                            value={addPropertyData.property_country}
                                        />
                                    </div>
                                    <div className="form-container-div">
                                        <VerticalInputFieldCustom
                                            required={true}
                                            name="property_zip"
                                            className="textfield"
                                            titleName="Pincode"
                                            focused={false}
                                            onChange={setAddPropertyDataHandleChange}
                                            autoComplete="off"
                                            value={addPropertyData.property_zip}
                                        />
                                    </div>
                                </div>

                                <div className="form-container-div-half">
                                    <div className="form-container-div">
                                        <VerticalTextAreaCustom
                                            required={true}
                                            name="property_address"
                                            className="textfield"
                                            titleName="Address"
                                            focused={false}
                                            onChange={setAddPropertyDataHandleChange}
                                            autoComplete="off"
                                            value={addPropertyData.property_address}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className='flex justify_content_between mt-20'>
                            <BgOutlineThemeButton onClick={handlePrevious} type={'button'} children={'Previous'} />
                            <BgThemeButton onClick={handleNext} type={'button'} children={'Next'} />
                        </div>
                    </form>
                </div>
            </div>

            {congratulationPopup && (
                <div className="bg-backdrop">
                    <div className="dashboard_checkin_modal_container">
                        <div
                            className="dashboard_checkin_modal_close_btn"
                        >
                        </div>
                        <div className="dashboard_checkin_modal_box">
                            <div className="dashboard_checkin_modal_content">
                                <svg xmlns="http://www.w3.org/2000/svg" width="182" height="130" viewBox="0 0 182 130" fill="none">
                                    <g clipPath="url(#clip0_4774_4619)">
                                        <path d="M23.287 20.6953C29.0019 20.6953 33.6347 16.0625 33.6347 10.3476C33.6347 4.63279 29.0019 0 23.287 0C17.5722 0 12.9394 4.63279 12.9394 10.3476C12.9394 16.0625 17.5722 20.6953 23.287 20.6953Z" fill="#FF6584" />
                                        <path d="M144.826 105.895C141.218 105.894 137.728 104.615 134.975 102.283C132.223 99.952 130.386 96.72 129.792 93.1619L129.779 93.0867L129.828 93.0284C130.637 92.0584 131.317 90.9876 131.85 89.8427C131.261 90.486 130.592 91.0529 129.862 91.5299L129.618 91.6921L129.604 91.3997C129.591 91.1473 129.585 90.8964 129.585 90.6543C129.584 89.2312 129.78 87.8149 130.169 86.4458C131.938 80.2268 132.241 73.5485 131.068 66.5966C130.868 65.4046 130.767 64.1979 130.767 62.9892C130.769 57.881 132.589 52.9403 135.901 49.0517C139.214 45.1632 143.802 42.5807 148.845 41.7667C153.888 40.9527 159.056 41.9604 163.424 44.6094C167.791 47.2583 171.074 51.3755 172.682 56.2237L172.731 56.3715L172.587 56.4299C171.504 56.8885 170.355 57.1736 169.182 57.2746C170.421 57.5596 171.692 57.6817 172.962 57.6377L173.097 57.6331L173.129 57.7634C173.555 59.4727 173.77 61.2276 173.77 62.9892L173.769 63.1799C173.765 64.5289 174.046 65.8636 174.592 67.0969C175.139 68.3303 175.939 69.4347 176.941 70.3381C178.569 71.8194 179.857 73.6358 180.716 75.6623C181.575 77.6889 181.984 79.8775 181.917 82.0775C181.849 84.2776 181.306 86.4369 180.324 88.4069C179.342 90.3769 177.945 92.1109 176.229 93.4895L176.15 93.5537L176.057 93.5113C174.094 92.6208 172.665 91.6507 171.619 90.4928C172.316 91.9787 173.255 93.3389 174.396 94.518L174.544 94.6702L174.361 94.777C172.03 96.1357 169.381 96.8511 166.684 96.8501L166.607 96.8499C166.58 96.8497 166.552 96.8497 166.525 96.8497C162.399 96.8497 158.488 98.4453 155.783 101.235C154.359 102.702 152.656 103.87 150.775 104.67C148.893 105.47 146.87 105.887 144.826 105.895Z" fill="#F2F2F2" />
                                        <path d="M139.432 129.908C139.366 129.908 139.302 129.884 139.252 129.84C139.202 129.797 139.17 129.737 139.16 129.671C139.149 129.589 138.004 121.334 139.032 110.362C139.981 100.23 143.03 85.6354 152.163 73.6492C152.185 73.6205 152.213 73.5964 152.244 73.5782C152.275 73.56 152.31 73.5482 152.345 73.5434C152.381 73.5385 152.417 73.5408 152.452 73.55C152.487 73.5593 152.52 73.5753 152.549 73.5972C152.578 73.6191 152.602 73.6464 152.62 73.6776C152.638 73.7089 152.65 73.7434 152.655 73.7791C152.66 73.8149 152.657 73.8514 152.648 73.8863C152.639 73.9212 152.623 73.954 152.601 73.9827C143.547 85.8654 140.522 100.353 139.58 110.414C138.558 121.322 139.693 129.512 139.705 129.594C139.71 129.633 139.708 129.673 139.696 129.711C139.685 129.748 139.666 129.783 139.64 129.813C139.614 129.843 139.582 129.867 139.547 129.883C139.511 129.899 139.472 129.908 139.432 129.908Z" fill="#3F3D56" />
                                        <path d="M143.998 89.4983C143.94 89.4983 143.883 89.48 143.836 89.4459C143.789 89.4117 143.754 89.3636 143.736 89.3083C143.718 89.2531 143.718 89.1936 143.736 89.1383C143.754 89.083 143.789 89.0349 143.836 89.0007C146.438 87.2392 149.25 85.8081 152.205 84.7403C156.823 83.0498 163.844 81.5173 171.073 83.8179C171.107 83.8288 171.139 83.8464 171.167 83.8697C171.195 83.893 171.217 83.9215 171.234 83.9536C171.25 83.9857 171.261 84.0207 171.264 84.0567C171.267 84.0927 171.263 84.1289 171.252 84.1634C171.241 84.1978 171.223 84.2297 171.2 84.2573C171.177 84.2849 171.148 84.3077 171.116 84.3243C171.084 84.3409 171.049 84.351 171.013 84.354C170.977 84.3571 170.941 84.353 170.906 84.342C163.826 82.0887 156.931 83.5959 152.394 85.2569C149.486 86.3064 146.72 87.7135 144.159 89.4457C144.112 89.4799 144.056 89.4983 143.998 89.4983Z" fill="#3F3D56" />
                                        <path d="M32.2467 109.48C29.6807 109.476 25.6408 109.683 23.4403 110.844C21.7493 111.738 20.2093 112.891 18.8768 114.263C18.3632 114.794 17.8767 115.35 17.4193 115.93C16.1538 111.601 16.0065 107.021 16.9912 102.619C17.0604 102.323 17.0376 102.013 16.9259 101.73C16.8142 101.447 16.6189 101.204 16.3659 101.035C16.113 100.866 15.8145 100.778 15.5102 100.783C15.206 100.788 14.9105 100.885 14.6631 101.062C11.0882 103.669 8.80267 108.087 8.24684 112.557C7.53864 118.252 9.20623 123.99 11.5239 129.242L11.5232 129.244C11.5189 129.268 11.5124 129.294 11.506 129.32C11.4975 129.358 11.4868 129.397 11.4782 129.435C11.4631 129.502 11.446 129.57 11.431 129.637L11.8062 129.62C11.8277 129.63 11.8492 129.639 11.8705 129.65H21.27C21.3945 129.514 21.5106 129.371 21.6174 129.221C22.6575 127.795 23.0885 125.863 23.614 124.119C23.9945 122.857 24.4706 121.625 25.038 120.435C26.4667 117.445 28.438 114.747 30.8517 112.476C30.9289 112.404 31.0083 112.331 31.0877 112.26C31.4919 111.888 32.1186 111.391 32.731 110.924C32.8648 110.822 32.9633 110.681 33.0128 110.521C33.0622 110.36 33.0601 110.188 33.0066 110.028C32.9532 109.869 32.8511 109.731 32.7148 109.632C32.5785 109.534 32.4148 109.481 32.2467 109.48Z" fill="#F2F2F2" />
                                        <path d="M95.71 20.8545C95.9311 21.4727 95.9004 22.153 95.6245 22.7488C95.3486 23.3446 94.8496 23.8081 94.2351 24.0394C94.0992 24.0901 93.959 24.1288 93.8162 24.1548L92.3957 32.8525L89.0636 29.7759L90.9233 21.9881C90.8545 21.3318 91.0467 20.6748 91.4584 20.1592C91.8701 19.6435 92.4682 19.3106 93.1233 19.2323C93.6746 19.1692 94.2309 19.2953 94.7009 19.5901C95.171 19.8849 95.5268 20.3307 95.71 20.8545Z" fill="#9E616A" />
                                        <path d="M71.1187 40.5625C71.1187 40.5625 73.7049 37.1454 83.0895 37.239L88.4163 35.3037L89.6336 24.1445L93.8944 25.1589L93.2857 38.9558L84.1555 44.2311L72.1847 46.6658L71.1187 40.5625Z" fill="#6C63FF" />
                                        <path d="M56.6982 124.688L59.1567 125.736L64.3677 116.752L60.7393 115.206L56.6982 124.688Z" fill="#9E616A" />
                                        <path d="M77.0137 127.014L79.6858 127.014L80.9572 116.707L77.0131 116.707L77.0137 127.014Z" fill="#9E616A" />
                                        <path d="M76.9196 129.802L85.1371 129.802V129.698C85.1371 128.849 84.8001 128.036 84.2002 127.436C83.6004 126.836 82.7869 126.499 81.9386 126.499L80.4374 125.361L77.6368 126.499L76.9194 126.499L76.9196 129.802Z" fill="#2F2E41" />
                                        <path d="M55.2035 126.472L62.7632 129.694L62.804 129.598C63.1365 128.818 63.1454 127.938 62.8288 127.151C62.5122 126.364 61.8959 125.735 61.1156 125.402L60.181 123.766L57.1581 123.716L56.4982 123.434L55.2035 126.472Z" fill="#2F2E41" />
                                        <path d="M79.635 66.0113L83.6963 90.7409L82.591 94.4261L84.0413 97.036L81.9115 119.957L82.776 121.861L80.3605 123.35H75.4451L75.1843 102.6L73.9701 99.553V97.8739L71.4984 85.2207L71.0433 99.6944L70.3243 102.746L70.9282 104.918L70.1391 106.441L63.3764 120.962H57.8647L58.291 117.678C57.3035 117.178 59.7736 115.717 60.011 114.636L59.1994 112.81L60.011 109.36L60.7551 102.62L59.9704 88.3938L60.3396 69.2358L66.7873 64.3053L79.635 66.0113Z" fill="#2F2E41" />
                                        <path d="M69.7446 33.4439C72.8209 33.4439 75.3146 30.9501 75.3146 27.8739C75.3146 24.7977 72.8209 22.3039 69.7446 22.3039C66.6684 22.3039 64.1747 24.7977 64.1747 27.8739C64.1747 30.9501 66.6684 33.4439 69.7446 33.4439Z" fill="#9E616A" />
                                        <path d="M60.0663 76.2692C60.2224 75.9894 60.3152 75.6787 60.3383 75.3591C60.3613 75.0395 60.314 74.7187 60.1997 74.4193C60.0854 74.12 59.9068 73.8493 59.6766 73.6265C59.4463 73.4036 59.17 73.2339 58.8671 73.1294L58.8539 65.3402L55.4786 67.5742L56.0086 74.6285C55.8667 75.149 55.9207 75.7035 56.1603 76.1868C56.3999 76.6702 56.8085 77.0489 57.3087 77.2511C57.8088 77.4534 58.3658 77.4651 58.874 77.2841C59.3823 77.1032 59.8065 76.742 60.0663 76.2692Z" fill="#9E616A" />
                                        <path d="M59.7543 41.8044L58.2463 40.351L57.1386 41.1505C57.1386 41.1505 56.0487 42.4583 55.1768 46.3818C54.305 50.3053 55.1768 71.8847 55.1768 71.8847L59.9723 71.2307L59.3183 59.8961L61.2801 50.3053L59.7543 41.8044Z" fill="#6C63FF" />
                                        <path d="M57.5276 40.7055L62.608 38.2787L66.0572 35.0795L72.3469 35.0324L74.3759 37.67L79.7323 39.4896L78.8396 57.9595L79.4482 59.3798C79.4482 59.3798 79.0242 59.7629 78.8304 59.9771C78.6367 60.1914 79.0982 61.9851 79.0982 61.9851L79.3584 63.9365L78.6367 64.4522L79.5749 65.5604L79.8078 67.3072C79.8078 67.3072 63.4311 63.2368 60.3367 69.9798C60.1733 70.3361 60.5759 66.8543 60.5759 66.8543L60.9784 64.3053L60.579 62.0174L59.9704 61.4087L60.9912 60.3879L60.3367 55.544L57.5276 40.7055Z" fill="#6C63FF" />
                                        <path d="M110.555 124.578H107.541L106.106 112.95H110.555V124.578Z" fill="#FFB6B6" />
                                        <path d="M112.147 129.802H109.985L109.599 127.761L108.61 129.802H102.876C102.601 129.802 102.334 129.714 102.112 129.551C101.891 129.388 101.727 129.159 101.646 128.897C101.564 128.634 101.568 128.352 101.658 128.093C101.747 127.833 101.918 127.608 102.144 127.452L106.723 124.29V122.226L111.539 122.514L112.147 129.802Z" fill="#2F2E41" />
                                        <path d="M139.876 118.597L137.282 120.135L130.117 110.865L133.944 108.595L139.876 118.597Z" fill="#FFB6B6" />
                                        <path d="M143.909 122.278L142.049 123.381L140.676 121.822L140.867 124.082L135.935 127.007C135.698 127.147 135.423 127.208 135.15 127.181C134.876 127.154 134.619 127.04 134.414 126.856C134.21 126.672 134.07 126.427 134.015 126.158C133.959 125.889 133.991 125.609 134.106 125.359L136.432 120.303L135.379 118.529L139.669 116.319L143.909 122.278Z" fill="#2F2E41" />
                                        <path d="M109.21 50.2266L107.745 59.5663L121.663 59.7493L119.832 50.7761L109.21 50.2266Z" fill="#FFB6B6" />
                                        <path d="M107.691 59.4482L103.716 69.089L103.426 94.8711L106.819 119.488H110.573L112.111 95.2724L115.894 78.7034L119.625 96.0328L131.227 112.995H136.928L126.447 94.3033L125.234 73.942C125.234 73.942 125.99 65.3042 122.121 60.2072L121.179 57.4862L107.691 59.4482Z" fill="#2F2E41" />
                                        <path d="M127.163 66.487C126.776 66.3814 126.416 66.1921 126.109 65.9325C125.803 65.6728 125.557 65.3491 125.389 64.9842C125.221 64.6193 125.136 64.2221 125.138 63.8204C125.14 63.4188 125.231 63.0225 125.403 62.6596L119.482 43.356L123.837 42.4927L129.252 61.4142C129.843 61.7436 130.292 62.2782 130.515 62.9167C130.737 63.5552 130.718 64.2533 130.461 64.8785C130.203 65.5038 129.725 66.0129 129.117 66.3094C128.51 66.6058 127.814 66.669 127.163 66.487Z" fill="#FFB6B6" />
                                        <path d="M120.139 57.0077L109.733 52.1559C109.459 52.15 109.194 52.0545 108.98 51.8838C108.765 51.7132 108.612 51.4768 108.545 51.211C108.03 49.1747 106.899 46.9112 107.499 41.6254C107.617 40.6015 107.94 39.6117 108.448 38.7146C108.955 37.8175 109.638 37.0314 110.455 36.4027C111.272 35.774 112.206 35.3155 113.203 35.0544C114.201 34.7933 115.24 34.7348 116.26 34.8825C117.336 35.0365 118.368 35.4164 119.287 35.9971C120.206 36.5778 120.992 37.3463 121.593 38.2521C122.194 39.158 122.597 40.1806 122.775 41.2531C122.953 42.3255 122.902 43.4235 122.626 44.475C121.418 49.0611 121.548 53.9653 121.41 55.8502C121.39 56.1227 121.281 56.3811 121.1 56.5858C120.919 56.7906 120.676 56.9304 120.408 56.9841C120.328 56.9999 120.247 57.0079 120.166 57.008C120.157 57.008 120.148 57.0079 120.139 57.0077Z" fill="#6C63FF" />
                                        <path d="M97.7347 24.5875C97.6769 24.7167 97.609 24.8411 97.5317 24.9596L102.507 36.6263L105.566 37.0014L105.603 41.691L100.124 41.553C99.6814 41.5418 99.2543 41.3874 98.907 41.1129C98.5596 40.8384 98.3106 40.4586 98.1975 40.0305L94.5096 26.0801C93.9872 25.9243 93.5246 25.6131 93.1833 25.1879C92.8421 24.7628 92.6384 24.2438 92.5993 23.7C92.5602 23.1563 92.6875 22.6135 92.9644 22.1439C93.2414 21.6743 93.6547 21.3001 94.1494 21.0711C94.6442 20.8422 95.1969 20.7693 95.7341 20.8621C96.2713 20.9549 96.7675 21.2091 97.1568 21.5908C97.546 21.9726 97.8097 22.4638 97.913 22.9991C98.0162 23.5344 97.9541 24.0884 97.7347 24.5875Z" fill="#FFB6B6" />
                                        <path d="M103.009 36.2105C103.132 36.1008 103.278 36.0191 103.437 35.971C103.595 35.9229 103.762 35.9095 103.925 35.9317L109.649 36.7066C110.495 36.6956 111.311 37.0206 111.918 37.6103C112.525 38.2 112.873 39.0063 112.887 39.8524C112.9 40.6985 112.577 41.5153 111.989 42.1237C111.401 42.7322 110.596 43.0826 109.75 43.0981L104.029 44.0204C103.865 44.0467 103.698 44.0375 103.539 43.9935C103.379 43.9494 103.231 43.8715 103.105 43.7651C102.978 43.6587 102.876 43.5262 102.805 43.3767C102.735 43.2272 102.697 43.0643 102.695 42.8989L102.621 37.0869C102.618 36.9215 102.652 36.7575 102.719 36.6063C102.786 36.455 102.885 36.32 103.009 36.2105Z" fill="#6C63FF" />
                                        <path d="M119.217 48.2456C119.07 48.1687 118.942 48.061 118.841 47.93C118.74 47.7991 118.669 47.6478 118.631 47.4867L117.331 41.8587C117.022 41.0712 117.037 40.1932 117.374 39.4171C117.711 38.641 118.342 38.0301 119.129 37.7184C119.915 37.4067 120.793 37.4197 121.571 37.7544C122.348 38.0891 122.96 38.7182 123.274 39.5039L126.161 44.5288C126.243 44.6722 126.294 44.8317 126.309 44.9963C126.324 45.161 126.303 45.3271 126.249 45.4831C126.194 45.6392 126.106 45.7816 125.991 45.9006C125.877 46.0197 125.737 46.1126 125.584 46.1731L120.174 48.2985C120.02 48.359 119.854 48.3857 119.689 48.3766C119.524 48.3674 119.363 48.3228 119.217 48.2456Z" fill="#6C63FF" />
                                        <path d="M118.986 32.8978C121.838 31.3388 122.886 27.7633 121.327 24.9115C119.768 22.0597 116.192 21.0116 113.34 22.5706C110.489 24.1295 109.441 27.7051 111 30.5568C112.558 33.4086 116.134 34.4567 118.986 32.8978Z" fill="#FFB6B6" />
                                        <path d="M112.115 25.3685C110.852 25.3614 109.59 25.3543 108.327 25.3472C108.237 24.4867 108.62 23.6306 109.178 22.9695C109.736 22.3083 110.459 21.8105 111.172 21.321C111.897 20.824 112.647 20.3157 113.508 20.142C114.229 19.9964 114.975 20.0974 115.704 20.1988C116.671 20.3332 117.638 20.4677 118.605 20.6021C119.666 20.7497 120.777 20.9166 121.639 21.5531C122.804 22.4138 122.506 24.4006 123.625 25.3218C127.003 28.104 125.635 30.5336 125.698 33.0696C121.616 33.4977 117.534 33.9259 113.452 34.3541C113.009 34.4304 112.555 34.4172 112.117 34.3154C111.688 34.1843 111.295 33.8294 111.263 33.3822C111.228 32.8828 111.624 32.474 111.946 32.0903C112.714 31.1393 113.174 29.9776 113.267 28.759C113.36 27.5404 113.081 26.3222 112.467 25.2658L112.115 25.3685Z" fill="#2F2E41" />
                                        <path d="M68.3197 33.1711C68.2959 33.1187 68.2722 33.0661 68.2485 33.0134C68.2577 33.0137 68.2667 33.015 68.2758 33.0152L68.3197 33.1711Z" fill="#2F2E41" />
                                        <path d="M64.0197 22.5572C64.2842 22.3077 64.6279 22.159 64.9909 22.1373C65.3511 22.1489 65.7163 22.4742 65.6417 22.8268C66.3033 21.6554 67.3563 20.7545 68.6159 20.2821C69.8756 19.8098 71.2614 19.7962 72.53 20.2438C73.4252 20.5597 74.3031 21.1915 74.5087 22.1182C74.5287 22.3615 74.6013 22.5976 74.7214 22.8102C74.8254 22.9238 74.9605 23.0045 75.1099 23.0423C75.2592 23.0802 75.4164 23.0735 75.562 23.0231L75.5709 23.0205C75.6207 23.0035 75.6745 23.0018 75.7253 23.0157C75.7761 23.0296 75.8216 23.0584 75.8558 23.0984C75.8901 23.1385 75.9115 23.1878 75.9174 23.2402C75.9233 23.2925 75.9134 23.3454 75.8889 23.392L75.6356 23.8644C75.9549 23.9356 76.2866 23.9285 76.6026 23.8439C76.6551 23.8301 76.7106 23.8329 76.7614 23.8519C76.8122 23.8709 76.8559 23.9052 76.8865 23.95C76.9171 23.9948 76.9331 24.048 76.9322 24.1023C76.9314 24.1565 76.9138 24.2092 76.8818 24.253C76.4609 24.829 75.9113 25.2987 75.2769 25.6248C74.6425 25.9509 73.9406 26.1244 73.2273 26.1315C72.2153 26.1253 71.1928 25.7765 70.2079 26.0093C69.8269 26.0993 69.4708 26.2735 69.1659 26.5191C68.861 26.7647 68.6149 27.0754 68.4457 27.4285C68.2765 27.7816 68.1886 28.1681 68.1882 28.5596C68.1879 28.9511 68.2752 29.3378 68.4438 29.6912C68.1412 29.3602 67.5563 29.4385 67.2468 29.7631C67.1043 29.9368 66.9989 30.1379 66.9373 30.354C66.8756 30.57 66.859 30.7965 66.8884 31.0192C66.9709 31.7015 67.1735 32.3639 67.4867 32.9756C66.29 32.9388 65.1333 32.5356 64.1731 31.8204C63.2128 31.1052 62.4951 30.1125 62.1171 28.9764C61.739 27.8403 61.7187 26.6156 62.059 25.4676C62.3993 24.3196 63.0837 23.3037 64.0197 22.5572Z" fill="#2F2E41" />
                                        <path d="M174.687 130H0.340479C0.276528 130 0.215262 129.974 0.170107 129.929C0.124952 129.884 0.0995941 129.822 0.0995941 129.758C0.0995941 129.694 0.124952 129.633 0.170107 129.588C0.215262 129.543 0.276528 129.517 0.340479 129.517H174.687C174.719 129.517 174.75 129.523 174.779 129.535C174.809 129.547 174.836 129.565 174.858 129.587C174.881 129.61 174.898 129.636 174.911 129.666C174.923 129.695 174.929 129.727 174.929 129.758C174.929 129.79 174.923 129.822 174.911 129.851C174.898 129.88 174.881 129.907 174.858 129.93C174.836 129.952 174.809 129.97 174.779 129.982C174.75 129.994 174.719 130 174.687 130Z" fill="#CCCCCC" />
                                        <path d="M41.9605 2.40843C36.9456 1.73057 31.2203 4.44116 29.9205 9.33191C29.5929 8.46234 28.9974 7.71941 28.22 7.21045C27.4425 6.7015 26.5234 6.45287 25.5954 6.50049C24.6674 6.54811 23.7785 6.88951 23.0573 7.47537C22.336 8.06124 21.8197 8.86122 21.5828 9.75976L22.157 10.171C32.9038 12.495 44.0284 12.4447 54.7538 10.0236C51.2655 6.35748 46.9754 3.08628 41.9605 2.40843Z" fill="#F0F0F0" />
                                        <path d="M20.4537 16.2053C15.4388 15.5274 9.71343 18.238 8.41365 23.1288C8.08609 22.2592 7.49056 21.5163 6.71312 21.0073C5.93567 20.4984 5.01655 20.2497 4.08855 20.2974C3.16054 20.345 2.2717 20.6864 1.55043 21.2722C0.829167 21.8581 0.312821 22.6581 0.0759888 23.5566L0.650123 23.9678C11.3969 26.2918 22.5216 26.2416 33.247 23.8205C29.7586 20.1544 25.4686 16.8832 20.4537 16.2053Z" fill="#F0F0F0" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4774_4619">
                                            <rect width="181.848" height="130" fill="white" transform="translate(0.0759888)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h4>Congratulation!</h4>
                                <p>Your property has been added successfully</p>
                                <div className="dashboard_checkin_modal_btn">
                                    <BgThemeButton
                                        onClick={() => setCongratulationPopup(false)}
                                        children={"Done"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
export default AddPropertyNew;
