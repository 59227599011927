import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { BgOutlineThemeButton, BgThemeButton } from "../../../UI/Buttons";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../assets/toastUtils";
import VerticalInputFieldCustom from "../../VerticalInputFieldCustom.jsx";
import {
  grandTotalForInclusive,
  grandTotalForExclusive,
  taxAmountForInclusive,
  taxAmountForExclusive,
  calculateSubtotalForInclusive,
  calculateSubtotalForExclusive,
} from "../calculationFunctions/CalculationFunctions.jsx";
import { calculateTotal } from "../calculationFunctions/CalculateKeyTotalFromArrayOfObjects";
import { checkEmptyValuesInObjects } from "../validationFunctions/ValidationFunctions.jsx";
import { getDecryptedData } from "../../../../utils/encryptStorage.js";

const ModifyReservationConfirmChangeMealPla = ({
  bookingData,
  addOnCost,
  reasons,
  setReasons,
  setShowModifyPopup,
  handleReasonChange,
  getBookingData,
  fetchBookingDetails,
}) => {
  const [loader,setLoader] = useState(false);
  const property_id = useSelector((state) => state.property.value);
  const userToken = getDecryptedData('encryptToken');
  const navigate = useNavigate();
  const [editMealPlans, setEditMealPlans] = useState([]);
  useEffect(() => {
    if (bookingData && bookingData.items) {
      const newEditedRoomCategory = bookingData.items.map((item) => ({
        room_id: item.room_id,
        meal_plan: "",
        room_rate_per_night: item.room_rate,
        paxes: item.paxes,
        tax_type: item.tax_type,
        no_of_rooms: item.no_of_rooms,
        no_of_nights: item.no_nights,
        booking_id: item.id,
        discount_rate: item.discount_rate,
        id: item.rate_plan_id,
        new_room_rate: "",
        old_tax_rate: item.tax_rate,
        old_tax_amount: item.tax_amount,
        old_discount_amount: item.discount_amount,
        old_grand_total: item.room_grand_total,
        old_sub_total: item.room_total_amount,
        meal_plan_options: [],
        rate_plan_id: item.rate_plan_id,
        old_meal_plan: item.room_plan,
      }));

      setEditMealPlans(newEditedRoomCategory);
      localStorage.setItem(
        "editMealPlans",
        JSON.stringify(newEditedRoomCategory)
      );
    }
  }, [bookingData]);

  const [enabledMealPlanChange, setEnabledMealPlanChange] = useState([]);

  const handleMealPlanChangeDisableEnable = async (e, index, room_id) => {
    const isChecked = e.target.checked;

    try {
      if (isChecked) {
        setLoader(true);
        const response = await fetchMealPlans(room_id);
        const data = await response.json();
        if (data.message === "Unauthorised.") {
          navigate("/");
          return;
        }

        setEditMealPlans((prevEditMealPlans) => {
          const updatedEditMealPlans = [...prevEditMealPlans];
          updatedEditMealPlans[index] = {
            ...updatedEditMealPlans[index],
            meal_plan_options: data.roomRates,
          };
          return updatedEditMealPlans;
        });
        setEnabledMealPlanChange((prevEnabledMealPlanChange) => [
          ...prevEnabledMealPlanChange,
          index,
        ]);
        setLoader(false);
      } else {
        const storedEditMealPlans = JSON.parse(
          localStorage.getItem("editMealPlans")
        );
        if (storedEditMealPlans) {
          // Replace only the specific index
          setEditMealPlans((prevEditMealPlans) => {
            const updatedEditMealPlans = [...prevEditMealPlans];
            updatedEditMealPlans[index] = {
              ...storedEditMealPlans[index],
              meal_plan_options: [],
            };
            return updatedEditMealPlans;
          });
        }
        setEnabledMealPlanChange((prevEnabledMealPlanChange) =>
          prevEnabledMealPlanChange.filter((i) => i !== index)
        );
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
    setCalculatedMealPlanCost(0);
    setShowTotalMealPlanCost(false);
  };

  const handleMealPlanRateChange = (e, index) => {
    setShowTotalMealPlanCost(false);
    setCalculatedMealPlanCost(0);
    const { name, value } = e.target;
    const updatedEditMealPlans = [...editMealPlans];
    updatedEditMealPlans[index] = {
      ...updatedEditMealPlans[index],
      [name]: value,
    };
    setEditMealPlans(updatedEditMealPlans);
  };
  const handleMealPlanOptionChange = (e, index) => {
    setShowTotalMealPlanCost(false);
    setCalculatedMealPlanCost(0);
    const { name, value } = e.target;
    const updatedEditMealPlans = [...editMealPlans];
    updatedEditMealPlans[index] = {
      ...updatedEditMealPlans[index],
      rate_plan_id: value,
      meal_plan: updatedEditMealPlans[index]?.meal_plan_options?.find(
        (option) => option.id === parseInt(value)
      )?.rate_plan_type,
    };
    setEditMealPlans(updatedEditMealPlans);
  };

  const fetchMealPlans = async (room_id) => {
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/rooms/rates/${room_id}/${property_id.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
  };
  const handleMealPlanDiscountChange = (e, index, roomRate, noOfRooms) => {
    const { name, value } = e.target;

    if (+value > +roomRate * +noOfRooms) {
      showErrorToast("please enter valid discount amount");
      return;
    } else {
      const updatedEditMealPlans = [...editMealPlans];
      updatedEditMealPlans[index] = {
        ...updatedEditMealPlans[index],
        [name]: value,
      };
      setEditMealPlans(updatedEditMealPlans);
      setShowTotalMealPlanCost(false);
      setCalculatedMealPlanCost(0);
    }
  };

  const [showTotalMealPlanCost, setShowTotalMealPlanCost] = useState(false);
  const [calculatedMealPlanCost, setCalculatedMealPlanCost] = useState(0);

  const handleChangeMealPlanCalculate = async (e) => {
    e.preventDefault();
    const keysToCheck = ["meal_plan"];
    // if (
    //   checkEmptyValuesInObjects(
    //     editMealPlans,
    //     keysToCheck,
    //     enabledMealPlanChange
    //   )
    // ) {
    //   return;
    // }

    const extractPrices = (paxes) => {
      let childPrice = 0;
      let extraAdultPrice = 0;

      if (Array.isArray(paxes)) {
        paxes.forEach((pax) => {
          if (pax.pax_type === "child") {
            childPrice += parseFloat(pax.price); // Accumulate child prices
          } else if (pax.pax_type === "adult") {
            extraAdultPrice += parseFloat(pax.price); // Accumulate adult prices
          }
        });
      } else {
        console.error("Invalid paxes data:", paxes);
      }

      return { childPrice, extraAdultPrice };
    };
    const rooms = editMealPlans.map((item) => {
      const rate_per_night = item.room_rate_per_night;

      const taxRate = +rate_per_night > 7500 ? 18 : 12;
      const discountAmount = item.old_discount_amount;

      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);

      const modifiedItem = {
        BookingItemId: item.booking_id,
        RoomID: item.room_id,
        RatePlanName: "",
        RatePlanId: "",
        RoomDiscount: discountAmount,
        Rate: rate_per_night,

        TaxRate: taxRate,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
                +rate_per_night,
                +item.no_of_nights,

                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +discountAmount
              )
            : grandTotalForExclusive(
                +rate_per_night,
                +item.no_of_nights,

                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
                +rate_per_night,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              )
            : taxAmountForExclusive(
                +rate_per_night,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
                +rate_per_night,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              )
            : calculateSubtotalForExclusive(
                +rate_per_night,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +discountAmount
              ),
      };
      return modifiedItem;
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "change_mealplan",
      bookingNetTotal: +calculateTotal(rooms, "NetTotal") + +addOnCost.NetTotal,
      bookingTaxableAmount:
        +calculateTotal(rooms, "TaxbleTotal") + +addOnCost.taxableAmount,
      bookingTaxAmount:
        +calculateTotal(rooms, "TaxAmount") + +addOnCost.taxAmount,
      bookingDiscountAmount:
        +calculateTotal(rooms, "RoomDiscount") + +addOnCost.discountAmount,
      requestReason: reasons.changeMealPlanReason,
      dueAmount:
        +(+calculateTotal(rooms, "NetTotal") + +addOnCost.NetTotal) -
        +bookingData.paid_amount,
      Room: rooms,
    };

    setCalculatedMealPlanCost(requestBody.bookingNetTotal);
    setShowTotalMealPlanCost(true);
  };

  const handleChangeMealPlanSubmit = async (e) => {
    e.preventDefault();
    if (reasons.changeMealPlanReason === "") {
      showErrorToast("Please enter a reason for changing room category");
      return;
    }

    const extractPrices = (paxes) => {
      let childPrice = 0;
      let extraAdultPrice = 0;

      if (Array.isArray(paxes)) {
        paxes.forEach((pax) => {
          if (pax.pax_type === "child") {
            childPrice += parseFloat(pax.price); // Accumulate child prices
          } else if (pax.pax_type === "adult") {
            extraAdultPrice += parseFloat(pax.price); // Accumulate adult prices
          }
        });
      } else {
        console.error("Invalid paxes data:", paxes);
      }

      return { childPrice, extraAdultPrice };
    };
    const rooms = editMealPlans.map((item) => {
      const rate_per_night = item.room_rate_per_night;

      const taxRate = +rate_per_night > 7500 ? 18 : 12;
      const discountAmount = item.old_discount_amount;

      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);

      const modifiedItem = {
        BookingItemId: item.booking_id,
        RoomID: item.room_id,
        RatePlanName: item.meal_plan ? item.meal_plan : item.old_meal_plan,
        RatePlanID: item.rate_plan_id,
        RoomDiscount: discountAmount,
        Rate: rate_per_night,
        TaxRate: taxRate,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
                +rate_per_night,
                +item.no_of_nights,

                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +discountAmount
              )
            : grandTotalForExclusive(
                +rate_per_night,
                +item.no_of_nights,

                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
                +rate_per_night,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              )
            : taxAmountForExclusive(
                +rate_per_night,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
                +rate_per_night,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              )
            : calculateSubtotalForExclusive(
                +rate_per_night,
                +item.no_of_nights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +discountAmount
              ),
      };
      return modifiedItem;
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "change_mealplan",
      bookingNetTotal: +calculateTotal(rooms, "NetTotal") + +addOnCost.NetTotal,
      bookingTaxableAmount:
        +calculateTotal(rooms, "TaxbleTotal") + +addOnCost.taxableAmount,
      bookingTaxAmount:
        +calculateTotal(rooms, "TaxAmount") + +addOnCost.taxAmount,
      bookingDiscountAmount:
        +calculateTotal(rooms, "RoomDiscount") + +addOnCost.discountAmount,
      requestReason: reasons.changeMealPlanReason,
      bookingDueAmount:
        +(+calculateTotal(rooms, "NetTotal") + +addOnCost.NetTotal) -
        +bookingData.paid_amount,
      Room: rooms,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(requestBody),
    };

    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modif/stay/${property_id.id}`,
        requestOptions
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      showSuccessToast("Meal Plan changed successfully");
      setShowTotalMealPlanCost(false);
      setCalculatedMealPlanCost(0);
      setShowModifyPopup(false);
      setEnabledMealPlanChange([]);
      setReasons({});
      fetchBookingDetails();
      getBookingData(bookingData.unique_booking_id);
     setLoader(false)
      return data;
    } catch (error) {
      console.error(error);
      setLoader(false)
      showErrorToast(error.message);
      return null;
    }
  };

  return (
    <div>
      <div>
        <table className="modifyReservationPopupTable">
          <thead>
            <tr>
              <th></th>
              <th>Meal Plan</th>
              <th>Change to</th>
              <th>Rate/Night</th>

              <th>Discount Amount</th>
            </tr>
          </thead>
          <tbody>
            {editMealPlans.map((items, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      handleMealPlanChangeDisableEnable(
                        e,
                        index,
                        items.room_id,
                        items.room_rate_per_night,
                        items.old_discount_amount,
                        items.old_meal_plan
                      )
                    }
                    checked={enabledMealPlanChange.includes(index)}
                    style={{ cursor: "pointer" }}
                  />
                </td>
                <td>
                  <VerticalInputFieldCustom
                    value={items.old_meal_plan}
                    disabled={true}
                  />
                </td>
                <td>
                  <div>
                    <div className="select">
                      <select
                        className="select-custom"
                        style={{
                          width: "100%",
                          minHeight: "2.25rem",
                          border: "0.0625rem solid #888",
                          borderRadius: "0.25rem",
                          paddingLeft: "5px",
                        }}
                        onChange={(e) => handleMealPlanOptionChange(e, index)}
                        disabled={!enabledMealPlanChange.includes(index)}
                      >
                        <option>select</option>
                        {items?.meal_plan_options?.map(
                          (itemOptions, indexOptions) => (
                            <option
                              key={indexOptions}
                              value={itemOptions.id}
                              disabled={
                                items.old_meal_plan ===
                                itemOptions.rate_plan_type
                              }
                            >
                              {itemOptions.rate_plan_type}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </td>
                <td>
                  <VerticalInputFieldCustom
                    value={items?.room_rate_per_night}
                    name={"room_rate_per_night"}
                    disabled={!enabledMealPlanChange.includes(index)}
                    onChange={(e) => handleMealPlanRateChange(e, index)}
                    type={"number"}
                  />
                </td>

                <td>
                  <VerticalInputFieldCustom
                    value={items?.old_discount_amount}
                    name={"old_discount_amount"}
                    disabled={!enabledMealPlanChange.includes(index)}
                    onChange={(e) =>
                      handleMealPlanDiscountChange(
                        e,
                        index,
                        items?.room_rate_per_night
                      )
                    }
                    type={"number"}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="modify_reservation_reason_container">
          <VerticalInputFieldCustom
            titleName={"Reason to Modify*"}
            name={"changeMealPlanReason"}
            onChange={handleReasonChange}
          />

          <div className="modify_reservation_reason_container flex flex_gap_10 align_items_center">
            <label>New Total:</label>

            <VerticalInputFieldCustom
              value={calculatedMealPlanCost}
              disabled={true}
            />

            <div>Previous Total : {bookingData?.grand_total}</div>
          </div>
        </div>
        <div className="flex flex_gap_10">
          <BgThemeButton
            children={!showTotalMealPlanCost ? "Calculate" : "Modify"}
            loader={loader}
            onClick={
              !showTotalMealPlanCost
                ? handleChangeMealPlanCalculate
                : handleChangeMealPlanSubmit
            }
          />
          <BgOutlineThemeButton
            children={"Cancel"}
            onClick={() => setShowModifyPopup(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default ModifyReservationConfirmChangeMealPla;
