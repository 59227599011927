import React from "react";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";

const DownloadCsvTables = ({ tableData , names}) => {
  const headers = Array.isArray(names) && names.length > 0 && typeof names[0] === 'object' && names[0] !== null
  ? Object.keys(names[0]).map((key) => ({
      label: names[0][key],
      key: key,
    }))
  : [];

  const data = [
    {
      agent_id: 2,
      company_name: "Sam Tours and Travels",
      current_date_amount: 0,
      current_date_nights: 0,
      mtd_amount: 0,
      mtd_nights: 0,
      ytd_amount: 0,
      ytd_nights: 0,
    },
    {
      agent_id: 3,
      company_name: "B2B",
      current_date_amount: 0,
      current_date_nights: 0,
      mtd_amount: 0,
      mtd_nights: 0,
      ytd_amount: 0,
      ytd_nights: 0,
    },
    {
      ytd_amount: "Total 200",
      ytd_nights: 0,
    },
  ];

  const getTableNames = data.map((item) => {
    return Object.keys(item);
  });


  // const names = [
  //   {
  //     agent_id: "Agent ID",
  //     company_name: "Name",
  //     current_date_amount: "Amount",
  //     current_date_nights: "Nights",
  //     mtd_amount: "mtd_amount",
  //     mtd_nights: "mtd_nights",
  //     ytd_amount: "ytd_amount",
  //     ytd_nights: "ytd_nights",
  //   },
  // ];

  // const downloadPdf = () => {
  //   const doc = new jsPDF();

  //   doc?.autoTable({
  //     head: names.slice(0, 1),
  //     body: tableData,
  //   });

  //   doc.save("table.pdf");
  // };

  return (
    <div>
      {tableData ? (
        <CSVLink data={tableData || []}  headers={headers}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M6.99957 10.1107L3.11068 6.22179L4.19957 5.09401L6.22179 7.11623V0.777344H7.77734V7.11623L9.79957 5.09401L10.8885 6.22179L6.99957 10.1107ZM2.3329 13.2218C1.90512 13.2218 1.53879 13.0693 1.2339 12.7645C0.929012 12.4596 0.776827 12.0935 0.777345 11.6662V9.3329H2.3329V11.6662H11.6662V9.3329H13.2218V11.6662C13.2218 12.094 13.0693 12.4603 12.7645 12.7652C12.4596 13.0701 12.0935 13.2223 11.6662 13.2218H2.3329Z"
              fill="#3968ED"
            />
          </svg>
          <span style={{ color: "#3968ED" }}>{" "}CSV</span>
        </CSVLink>
      ) : null}
    </div>
  );
};

export default DownloadCsvTables;
