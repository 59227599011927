import { Button, Select, TextField } from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { BgThemeButton } from "./UI/Buttons";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";

const Form = () => {
  const [value, setValue] = useState("Jaded Pixel");
  const [selected, setSelected] = useState("today");

  const handleChange = useCallback((newValue) => setValue(newValue), []);

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  const options = [
    { label: "Australia", value: "Australia" },
    { label: "India", value: "India" },
    { label: "Pakistan", value: "Pakistan" },
  ];
  return (
    <form className="Parent-form-container">
      <div className="form-container " style={{ marginBottom: '10px' }}>
        <div className="form-container-div" >
          <VerticalInputFieldCustom titleName={"First name"} placeholder="Enter First Name" onChange={handleChange} autoComplete="off" />
        </div>
        <div className="form-container-div" style={{ marginBottom: '10px' }}>
          <VerticalInputFieldCustom titleName={"Last name"} onChange={handleChange} placeholder="Enter Last Name" autoComplete="off" />
        </div>
      </div>
      <div className="form-container" style={{ marginBottom: '10px' }}>
        <div className="form-container-div" >
          <VerticalInputFieldCustom titleName={"Phone"} onChange={handleChange} placeholder="Enter Phone No." autoComplete="off" />
        </div>
        <div className="form-container-div">
          <VerticalInputSelectCustomCopy titleName={"Country"} options={options}
            onChange={handleSelectChange}
            value={selected} />
        </div>
      </div>

      <div className="form-container" style={{ marginBottom: '10px' }}>
        <div className="form-container-div">
          <VerticalInputFieldCustom titleName={"City"} onChange={handleChange} placeholder="Enter City" autoComplete="off" />
        </div>
        <div className="form-container-div">
          <VerticalInputFieldCustom titleName={"Address"} onChange={handleChange} placeholder="Enter Address" autoComplete="off" />
        </div>
      </div>

      <div className="form-container" style={{ marginBottom: '10px' }}>
        <div className="form-full-container">
          <VerticalInputFieldCustom titleName={"State"} onChange={handleChange} placeholder="Enter State" autoComplete="off" />
        </div>
      </div>
      <div className="form-container" style={{ marginBottom: '10px' }}>
        <div className="form-full-container">
          <VerticalInputFieldCustom titleName={"Pincode"} onChange={handleChange} placeholder="Enter Pincode" type={"number"} autoComplete="off" />
        </div>
      </div>
      <div className="form-button-container">
        <BgThemeButton children={"Update"} />
      </div>
    </form>
  );
};

export default Form;
