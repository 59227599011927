import React from "react";
import FbFoodSale from "../Components/FbFoodSale";

const FbFoodSalePage = () => {
  return (
    <>
      <div className="main_container">
        <FbFoodSale />
      </div>
    </>
  );
};

export default FbFoodSalePage;
