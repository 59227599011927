import React from "react";
import Sidebar from "../Components/Sidebar";

import AddAgentProfile from "../Components/AddAgentProfile";


const AgentAddProfilePage = () => {
  return (
    <>
      <div className="main_container">
        <AddAgentProfile />
      </div>
    </>
  );
};

export default AgentAddProfilePage;
