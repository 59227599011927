import React, { useState, useRef, useEffect } from "react";
import "./RoomTypeAmenities.css";
import VerticalInputFieldCustom from "../VerticalInputFieldCustom";

const RoomTypeAmenities = ({ searchBox, amenities, getAmenitiesFromChild }) => {
  const suggestions = [
    "Free Wi-Fi",
    "Parking",
    "24-Hour Front Desk",
    "Restaurant/Room Service",
    "Fitness Center",
    "Swimming Pool",
    "Business Center",
    "Concierge Service",
    "Shuttle Service",
    "Laundry Facilities",
    "Housekeeping",
    "In-Room Amenities",
    "Television",
    "Iron and Ironing Board",
    "Hairdryer",
    "Toiletries",
    "Air Conditioning/Heating",
    "Wake-Up Service",
    "Luggage Storage",
    "Safe Deposit Box",
    "Pet-Friendly Accommodations",
    "Child-Friendly Amenities",
    "Conference/Meeting Facilities",
    "Spa and Wellness Facilities",
    "Bar/Lounge",
    "Gift Shop",
    "Valet Parking",
    "Airport Shuttle",
    "Free Breakfast",
    "Bicycle Rental",
    "Wheelchair Accessibility",
    "Turndown Service",
    "Dry Cleaning Service",
    "Valet Service",
    "On-Site Entertainment",
  ];

  const amenitiesContainerRef = useRef(null);
  const [query, setQuery] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [valueArray, setValueArray] = useState([]);
  const [amenitiesErrorMessage, setAmenitiesErrorMessage] = useState(false);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
    setShowSuggestions(e.target.value.length > 0 || e.target.value.length > 1);
    setAmenitiesErrorMessage(false);
  };

  const handleInputChangeWithoutSearchBox = (e) => {
    setAmenitiesErrorMessage(false);
    const inputValue = e.target.value;
    setQuery(inputValue);

    if (e.key === "Enter" && inputValue.length > 0) {
      e.preventDefault(); // Prevent default form submission behavior

      if (valueArray.includes(inputValue)) {
        console.log("No");
        setAmenitiesErrorMessage(true);
      } else {
        console.log("Yess");
        setValueArray((prevArray) => {
          const newArray = [...prevArray];
          newArray.push(inputValue);
          return newArray;
        });
        setQuery("");
      }
    }
  };

  const filteredSuggestions = suggestions.filter((suggestion) =>
    suggestion.toLowerCase().includes(query.toLowerCase())
  );

  const handleSuggestionClick = (suggestion) => {
    if (!valueArray.includes(suggestion)) {
      setQuery(suggestion);
      setValueArray([...valueArray, suggestion]);
      setQuery("");
      setAmenitiesErrorMessage(false);
    } else {
      setAmenitiesErrorMessage(true);
    }
    setShowSuggestions(false);
  };

  const handleDelete = (index) => {
    const newArray = [...valueArray];
    newArray.splice(index, 1);
    setValueArray(newArray);
  };

  const handleClickOutside = (event) => {
    // if (
    //     amenitiesContainerRef.current &&
    //     !amenitiesContainerRef.current.contains(event.target)
    // ) {
    //     setShowSuggestions(false);
    // }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (amenities && amenities.length > 0) {
      setValueArray([...amenities]);
    }
  }, [amenities]);
  
  useEffect(() => {
    if (valueArray && valueArray.length > 0) {
      getAmenitiesFromChild(valueArray);
    }
  }, [valueArray]);

  return (
    <>
      <div className="amenities_container" ref={amenitiesContainerRef}>
        {/* <div className="amenities_headings">
                    <h2>Amenities </h2>
                    <p>Manage</p>
                </div> */}
        {/* <input
                    type="text"
                    value={query}
                    onChange={
                        searchBox ? handleInputChange : handleInputChangeWithoutSearchBox
                    }
                    className="amenities_input_field"
                    onKeyDown={!searchBox ? handleInputChangeWithoutSearchBox : undefined}
                /> */}
        <VerticalInputFieldCustom
          type="text"
          name="amenities"
          value={query}
          onChange={
            searchBox ? handleInputChange : handleInputChangeWithoutSearchBox
          }
          onKeyDown={!searchBox ? handleInputChangeWithoutSearchBox : undefined}
          autoComplete="off"
        />{" "}
        {amenitiesErrorMessage ? (
          <p className="amenitiesError">Amenities Already Added</p>
        ) : null}
        {showSuggestions ? (
          <div className="amenities_suggestions">
            {filteredSuggestions.length === 0 ? (
              <p className="nodatafound">No data found</p>
            ) : (
              filteredSuggestions.map((suggestion, index) => (
                <p
                  className="amenities_single_suggestion"
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion}
                </p>
              ))
            )}
          </div>
        ) : null}
        <ul className="amenities_lists_container">
          {valueArray &&
            valueArray.map((value, index) => (
              <li key={index} className="amenities_lists">
                {value}
                <button
                  type="button"
                  onClick={() => handleDelete(index)}
                  style={{
                    border: "none",
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7"
                    height="7"
                    viewBox="0 0 7 7"
                    fill="none"
                    className="button_img"
                  >
                    <path d="M1 6L6 1" stroke="#888888" />
                    <path d="M1 1L6 6" stroke="#888888" />
                  </svg>
                </button>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default RoomTypeAmenities;
