import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./InventoryDatePicker.module.scss";
const InventoryDatePicker = ({
  selectedDatePickerDate,
  handleDateChange,
  handleDecrementDate,
  handleIncrementDate,
}) => {
  const CustomInput = ({ onClick }) => (
    <div onClick={onClick} className="dateRangePicker flex align_items_center ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        // style={{paddingBottom:"2px"}}
      >
        <g clipPath="url(#clip0_1270_814)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.625 2H11.75V1.25C11.75 0.835786 11.4142 0.5 11 0.5C10.5858 0.5 10.25 0.835786 10.25 1.25V2H5V1.25C5 0.835786 4.66421 0.5 4.25 0.5C3.83579 0.5 3.5 0.835786 3.5 1.25V2H2.375C1.775 2 1.25 2.525 1.25 3.125V14.375C1.25 14.975 1.775 15.5 2.375 15.5H13.625C14.225 15.5 14.75 14.975 14.75 14.375V3.125C14.75 2.525 14.225 2 13.625 2ZM2.75 14H13.25V6.5H2.75V14Z"
            fill="#888888"
          />
        </g>
        <defs>
          <clipPath id="clip0_1270_814">
            <rect
              width="15"
              height="15"
              fill="white"
              transform="translate(0.5 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
  return (
    <>
      <div className= {`${styles.dateRangePickerContainer}`} >
        {/* Decrement button */}
        <button
          onClick={handleDecrementDate}
          disabled={!selectedDatePickerDate}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="10"
            viewBox="0 0 7 10"
            fill="none"
          >
            <path d="M6 1L2 5L6 9" stroke="#888888" strokeWidth="1.5" />
          </svg>
        </button>

        {/* DatePicker component */}
        <DatePicker
          selected={
            selectedDatePickerDate ? new Date(selectedDatePickerDate) : null
          }
          onChange={handleDateChange}
          dateFormat="yyyy-MM-dd" // Use the desired date format
          customInput={<CustomInput />}
        />

        {/* Increment button */}
        <button
          onClick={handleIncrementDate}
          disabled={!selectedDatePickerDate}
          
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="10"
            viewBox="0 0 7 10"
            fill="none"
          >
            <path d="M1 9L5 5L0.999999 1" stroke="#888888" strokeWidth="1.5" />
          </svg>
        </button>
      </div>
    </>
  );
};

export default InventoryDatePicker;
