import React from "react";

const CustomCssComponentTableText = ({
  innerText,
  subScript,
  children,
  capitalize,
}) => {
  // const textToDisplay = capitalize
  //   ? innerText.charAt(0).toUpperCase() + innerText.slice(1).toLowerCase()
  //   : innerText;

  return (
    <div
      style={{
        margin: "7px 0",
        fontSize: "14px",
        fontWeight: "500",
        textTransform: "capitalize",
      }}
    >
      {subScript}
      {/* {textToDisplay} */}
      {innerText}
      {children}
    </div>
  );
};

export default CustomCssComponentTableText;
