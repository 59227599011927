import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import { formatIndianCurrency } from "../../utils/utils";

const ReportCompetitorsAnalysisTable = ({
  departureReport,
  loader,
  reportType,
  filterType,
}) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const rowMarkup =
    departureReport &&
    departureReport.length > 0 &&
    departureReport?.map((order) => (
      <IndexTable.Row id={order.id} key={order.id} position={order.index}>
        <IndexTable.Cell>
          {reportType === "competitor_reports" ? (
            <div>{order.competitor_name}</div>
          ) : (
            <div>
              {" "}
              {filterType === "date" ? (
                <TextDateFormatter
                  date={order.target_date_month}
                  as={"div"}
                  showYear={true}
                />
              ) : (
                order.target_date_month
              )}
            </div>
          )}
        </IndexTable.Cell>

        <IndexTable.Cell>
          {reportType === "competitor_reports" ? (
            <div>{formatIndianCurrency(order.average_rate)}</div>
          ) : (
            <div>
              {reportType === "achieved_revenue" ||
              reportType === "achieved_revpar" ||
              reportType === "achieved_ar" ? (
                <div>{formatIndianCurrency(order.achieved)}</div>
              ) : reportType === "achieved_occupancy" ? (
                <div>{order.achieved}%</div>
              ) : (
                "N/A"
              )}
            </div>
          )}
        </IndexTable.Cell>

        <IndexTable.Cell>
          {reportType === "competitor_reports" ? (
            <div>{formatIndianCurrency(order.property_average_rate)}</div>
          ) : (
            <div>
              {reportType === "achieved_revenue" ||
              reportType === "achieved_revpar" ||
              reportType === "achieved_ar" ? (
                <div>{formatIndianCurrency(order.targeted)}</div>
              ) : reportType === "achieved_occupancy" ? (
                <div>{order.targeted}%</div>
              ) : (
                "N/A"
              )}
            </div>
          )}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {reportType === "competitor_reports" ? (
            <div>{formatIndianCurrency(order.mmt_average_rate)}</div>
          ) : (
            <UnderscoreRemoveCapitalize text={order.target_status} />
          )}
        </IndexTable.Cell>
      </IndexTable.Row>
    ));

  const nameOptions = [
    { title: "Date / Month" },
    { title: "Achieved" },
    { title: "Targeted" },
    { title: "Target Status" },
  ];

  const competitors = [
    { title: "Competitor Name" },
    { title: "Average Rate" },
    { title: "Property Average Rate" },
    { title: "MMT Average Rate" },
  ];

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="room-types-full-container">
          <div className="room-types-full-container-div">
            <div className="room-type-heading">
              <UnderscoreRemoveCapitalize text={reportType} />
            </div>
          </div>

          {departureReport && departureReport.length > 0 ? (
            <>
              <LegacyCard>
                <IndexTable
                  resourceName={resourceName}
                  itemCount={departureReport.length}
                  selectable={false}
                  headings={
                    reportType === "competitor_reports"
                      ? competitors
                      : nameOptions
                  }
                >
                  {rowMarkup}
                </IndexTable>
              </LegacyCard>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
      )}
    </>
  );
};
export default ReportCompetitorsAnalysisTable;
