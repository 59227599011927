import { useState } from "react";
import { BgOutlineThemeButton, BgThemeButton } from "../Components/UI/Buttons";
import PreviewBill from "../Components/PreviewBill";
import PreviewBillCopy from "../Components/PreviewBillCopy";

const PreviewBillPage = () => {
  const [showShareOnEmail, setShowShareOnEmail] = useState(false);
  const [showShareOnWhatsapp, setShowShareWhatsapp] = useState(false);

  const handleShowShareOnEmail = () => {
    {
      showShareOnWhatsapp === true && setShowShareWhatsapp(false);
    }
    setShowShareOnEmail(!showShareOnEmail);
  };

  const handleShowShareOnWhatsapp = () => {
    {
      showShareOnEmail === true && setShowShareOnEmail(false);
    }
    setShowShareWhatsapp(!showShareOnWhatsapp);
  };

  return (
    <>
      <div className="main_container">
        {/* <PreviewBill /> */}
        <PreviewBillCopy />
      </div>
    </>
  );
};
export default PreviewBillPage;
