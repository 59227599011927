import React, { useState } from "react";
import styles from "./speedDial.module.scss";
import { FaPlay, FaPrint, FaDownload, FaCopy, FaTimes } from "./icons"; // Icons

const SpeedDial = ({linksAlign="horizontal"  }) => {
  const [open, setOpen] = useState(false);

  // Function to toggle the speed dial open/close
  const toggleSpeedDial = () => {
    setOpen(!open);
  };

  return (
    <div className={styles.speedDialContainer}>
      <div className={styles.fab} onClick={toggleSpeedDial}>
       <span className={`${styles.icons} ${open ? styles.rotate : ""}`}>
       <FaPlay   />
       </span>
      </div>
      {open && (
        <div className={styles.actionsContainer}>
          <div className={styles.actionButton}>
            <FaPrint className={styles.icon} />
          </div>
          <div className={styles.actionButton}>
            <FaDownload className={styles.icon} />
          </div>
          <div className={styles.actionButton}>
            <FaCopy className={styles.icon} />
          </div>
        </div>
      )}
    </div>
  );
};



export default SpeedDial;