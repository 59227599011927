import React from "react";
import CreateBookingNewEdit from "../Components/CustomComponents/CreateBookingNewPageEdit/CreateBookingNewEdit";

const CreateBookingPageNewEdit = () => {
  return (
    <div className="main_container">
      <CreateBookingNewEdit />
    </div>
  );
};

export default CreateBookingPageNewEdit;
