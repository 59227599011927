import React from 'react'

const ModifyReservationComponent = () => {
  return (
    <div className="room-types-full-container">
      <div className="room-types-full-container-div">


      


     
      
      
      
      
      
      
      
      
      
      </div>
    
     








    </div>
  )
}

export default ModifyReservationComponent