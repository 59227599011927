import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import { formatIndianCurrency } from "../../utils/utils";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import { useState } from "react";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { SvgGetInfo } from "../../assets/svgIcons";

const ReportSaleSummeryReport = ({
  departureReport,
  loader,
  fullData,
  donShowTitle,
}) => {
  const tableHeadings = [
    "INV Date",
    "INV No.",
    "Folio No.",
    "Booking ID",
    "Booking Source",
    "Guest Name",
    "Arrival Date",
    "Departure Date",
    "No. Of Nights",
    "Meal Plan",
    "Room Category",
    "Adults",
    "Child",
    "No. Of Rooms",
    "Room Rate/Night",
    "Taxable Amt.",
    "Tax %",
    "Tax Amt.",
    "Tax Type",
    "Net Room Sale",
    "F&B Sale Amt.",
    "Taxable Amt.",
    "Tax %",
    "Tax Amt.",
    "Tax Type",
    "Net F&B Sale",
    "Add Ons Sale",
    "Taxable Amt.",
    "Tax %",
    "Tax Amt.",
    "Tax Type",
    "Net Add-On Sale",
    "Total Bill Amt.",
    "Advance",
    "Cash",
    "Upi/Debit",
    "Guest Credit",
    "Company Credit",
    "Balance",
  ];
  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const styles = {
    tableData: {
      verticalAlign: "top",
    },
  };
  const [informationPopup, setInformationPopup] = useState(false);

  const rowMarkup =
    fullData &&
    fullData?.data?.length > 0 &&
    fullData?.data?.map((order) => (
      <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.invoice_date ? (
            <TextDateFormatter date={order.invoice_date} as={"span"} />
          ) : (
            "NA"
          )}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}

          {order.invoice_no ? order.invoice_no : "N/A"}

          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.folio_no ? order.folio_no : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>

        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.booking_id ? order.booking_id : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.booking_source ? order.booking_source : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.guest_name ? order.guest_name : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.arrival_date ? (
            <TextDateFormatter date={order.arrival_date} as={"span"} />
          ) : (
            "NA"
          )}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.departure_date ? (
            <TextDateFormatter date={order.departure_date} as={"span"} />
          ) : (
            "NA"
          )}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.no_nights ? <>{order.no_nights}</> : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.mealPlan ? <>{order.mealPlan}</> : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.room_type ? <>{order.room_type}</> : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.no_adult ? <>{order.no_adult}</> : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.no_child ? <>{order.no_child}</> : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order.no_of_rooms ? <>{order.no_of_rooms}</> : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.room_charges?.length > 0
            ? order.room_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.room_per_rate)} <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>

        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.room_charges?.length > 0
            ? order.room_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.taxable_amount)}
                  <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div  style={styles.tableData}> */}
          {order?.room_charges?.length > 0
            ? order.room_charges.map((item) => (
                <>
                  {item.tax_rate}% <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.room_charges?.length > 0
            ? order.room_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.tax_amount)} <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.room_charges?.length > 0
            ? order.room_charges.map((item) => (
                <>
                  {item.tax_type === "inclusive" ? "incl" : "Excl"} <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.room_charges?.length > 0
            ? order.room_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.net_total)} <br />{" "}
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.food_charges?.length > 0
            ? order.food_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.room_per_rate)} <br />{" "}
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>

        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.food_charges?.length > 0
            ? order.food_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.taxable_amount)}
                  <br />{" "}
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.food_charges?.length > 0
            ? order.food_charges.map((item) => (
                <>
                  {item.tax_rate}% <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.food_charges?.length > 0
            ? order.food_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.tax_amount)}
                  <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.food_charges?.length > 0
            ? order.food_charges.map((item) => (
                <>
                  {item.tax_type === "inclusive" ? "incl" : "Excl"}
                  <br />{" "}
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.food_charges?.length > 0
            ? order.food_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.net_total)} <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.add_on_charges?.length > 0
            ? order.add_on_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.room_per_rate)}
                  <br />{" "}
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>

        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.add_on_charges?.length > 0
            ? order.add_on_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.taxable_amount)}
                  <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.add_on_charges?.length > 0
            ? order.add_on_charges.map((item) => (
                <>
                  {item.tax_rate}% <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.add_on_charges?.length > 0
            ? order.add_on_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.tax_amount)}
                  <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.add_on_charges?.length > 0
            ? order.add_on_charges.map((item) => (
                <>
                  {item.tax_type === "inclusive" ? "incl" : "Excl"} <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.add_on_charges?.length > 0
            ? order.add_on_charges.map((item) => (
                <>
                  {formatIndianCurrency(item.net_total)} <br />
                </>
              ))
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>

        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.total_bill_amount &&
            formatIndianCurrency(order?.total_bill_amount)}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.payment_summary?.advance_payment &&
            formatIndianCurrency(order?.payment_summary?.advance_payment)}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.payment_summary?.cash &&
            formatIndianCurrency(order?.payment_summary?.cash)}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.payment_summary?.upi
            ? formatIndianCurrency(order?.payment_summary?.upi)
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.payment_summary?.upi
            ? formatIndianCurrency(order?.payment_summary?.upi)
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.payment_summary?.city_ledger
            ? formatIndianCurrency(order?.payment_summary?.city_ledger)
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
        <CustomReportTableCell styles={styles.tableData}>
          {/* <div style={styles.tableData}> */}
          {order?.balance_amount
            ? formatIndianCurrency(order?.balance_amount)
            : "N/A"}
          {/* </div> */}
        </CustomReportTableCell>
      </CustomReportTableRow>
    ));

  const downloadData = departureReport.map((report) => {
    return {
      date: report.date,
      guest_name: report.guest_name ? report.guest_name : "N/A",
      reference: report.guest_name
        ? `#${report.unique_booking_id} Folio-${report.folio_no} (${report.room_no})`
        : "Paid Credit",
      credits: report.credit_amount,
      debits: report.debit_amount,
      available_credit_limit: report.available_credit_limit,
    };
  });

  const names = [
    {
      Invoice_No: "INV No.",
      Inv_Date: "Date",
      Booking_Ref: "Booking Ref",
      Primary_Guest: "Guest",
      GSTIN: "GST IN",
      Account_Details: "Account",
      Tax_Rate: "Tax Rate",
      Taxable_Amount: "Taxable Amt.",
      SGST_Amount: "SGST",
      CGST_Amount: "CGST",
      Invoice_Value: "INV Value",
    },
  ];

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="room-types-full-container" id="sales_table">
          <div className="room-types-full-container-div">
            <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
              <div className="room-type-heading">Detailed Business Report</div>
              <div
                className="hoverCursor"
                onClick={() => setInformationPopup(true)}
                id="ignoreFromPrint"
              >
                <SvgGetInfo />
              </div>
            </div>
            {fullData?.data?.length > 0 && (
              <div className="flex flex_gap_15" id="ignoreFromPrint">
                <ButtonToDownloadTablePDF
                  itemToDownloadID={"sales_table"}
                  downloadFileName={"sales-summary-report"}
                  ignoreFromPrint={"ignoreFromPrint"}
                  pdfOrientation={"landscape"}
                  pdfMargins={[0.1, 0.1, 0.1, 0.1]}
                  pdfStyles={{
                    fontSize: "11px",
                    lineHeight: "1px",
                  }}
                  pageFormat={"A2"}
                />
                <DownloadCSVButton
                  itemToDownloadID={"sales_table"}
                  downloadFileName={"sales-summary-report"}
                />
              </div>
            )}
          </div>
          {fullData && fullData?.data?.length > 0 ? (
            <>
              <CustomReportTable
                headings={tableHeadings}
                commonHeadings={[
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "Room Sale",
                  "Room Sale",
                  "Room Sale",
                  "Room Sale",
                  "Room Sale",
                  "Room Sale",
                  "F&B Sale",
                  "F&B Sale",
                  "F&B Sale",
                  "F&B Sale",
                  "F&B Sale",
                  "F&B Sale",
                  "Add Ons Sale",
                  "Add Ons Sale",
                  "Add Ons Sale",
                  "Add Ons Sale",
                  "Add Ons Sale",
                  "Add Ons Sale",
                  "",
                  "Payment Details",
                  "Payment Details",
                  "Payment Details",
                  "Payment Details",
                  "Payment Details",
                  "Payment Details",
                ]}
                headerStyles={{
                  backgroundColor: "#dddddd",
                  fontSize: "12px",
                  textAlign: "center",
                  width: "max-content",
                }}
                tableStyles={{ textAlign: "left", width: "max-content" }}
              >
                {rowMarkup}
                {/* {calculateTotals} */}
                <CustomReportTableRow>
                  <CustomReportTableCell
                    groupBackgroundColors={"#ddd"}
                    styles={{ textAlign: "left" }}
                    colspan={tableHeadings.length}
                  >
                    <b>Summery</b>
                  </CustomReportTableCell>
                </CustomReportTableRow>

                <CustomReportTableRow>
                  <CustomReportTableCell>
                    Total Number of Bookings
                  </CustomReportTableCell>
                  <CustomReportTableCell  styles={{ textAlign: "left" }} colspan={tableHeadings.length - 1}>
                    {fullData?.overall_totals?.overall_bookings || "NA"}
                  </CustomReportTableCell>
                </CustomReportTableRow>

                <CustomReportTableRow>
                  <CustomReportTableCell>Total Room Sale</CustomReportTableCell>
                  <CustomReportTableCell  styles={{ textAlign: "left" }} colspan={tableHeadings.length - 1}>
                    {fullData?.overall_totals?.overall_room_sale || "NA"}
                  </CustomReportTableCell>
                </CustomReportTableRow>

                <CustomReportTableRow>
                  <CustomReportTableCell>Total F&B Sale</CustomReportTableCell>
                  <CustomReportTableCell  styles={{ textAlign: "left" }} colspan={tableHeadings.length - 1}>
                    {fullData?.overall_totals?.net_revenue || "NA"}
                  </CustomReportTableCell>
                </CustomReportTableRow>

                <CustomReportTableRow>
                  <CustomReportTableCell>
                    Total Add Ons Sale
                  </CustomReportTableCell>
                  <CustomReportTableCell  styles={{ textAlign: "left" }} colspan={tableHeadings.length - 1}>
                    {fullData?.overall_totals?.overall_addon_sale || "NA"}
                  </CustomReportTableCell>
                </CustomReportTableRow>
                <CustomReportTableRow>
                  <CustomReportTableCell>
                    Total Combined Sale
                  </CustomReportTableCell>
                  <CustomReportTableCell  styles={{ textAlign: "left" }} colspan={tableHeadings.length - 1}>
                    {fullData?.overall_totals?.overall_combined_sale || "NA"}
                  </CustomReportTableCell>
                </CustomReportTableRow>
                <CustomReportTableRow>
                  <CustomReportTableCell>
                    Total Balance Amount
                  </CustomReportTableCell>
                  <CustomReportTableCell  styles={{ textAlign: "left" }} colspan={tableHeadings.length - 1}>
                    {fullData?.overall_totals?.overall_balance_amount || "NA"}
                  </CustomReportTableCell>
                </CustomReportTableRow>
                <CustomReportTableRow>
                  <CustomReportTableCell>Net Revenue</CustomReportTableCell>
                  <CustomReportTableCell  styles={{ textAlign: "left" }} colspan={tableHeadings.length - 1}>
                    {fullData?.overall_totals?.net_revenue || "NA"}
                  </CustomReportTableCell>
                </CustomReportTableRow>
              </CustomReportTable>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
      )}

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Detailed Business Report"
        >
          <div className="mb-20">
            <AccordionComponent
              questions={[
                {
                  question: "What is the Detailed Business Report?",
                  answer: (
                    <p>
                      The <b>Detailed Business Report</b> is a detailed
                      financial document that tracks all payments received over
                      a specific date range, categorized by payment methods such
                      as cash, bank transfers, UPI, credit card, debit card, and
                      city ledger. This report provides a consolidated view of
                      all payment transactions, making it easier to reconcile
                      finances.
                    </p>
                  ),
                },
                {
                  question:
                    "What is the Importance of the Detailed Business Report?",
                  answer: (
                    <div>
                      <p>The Detailed Business Report is essential for:</p>

                      <ul>
                        <li>
                          <b>Financial Accuracy: </b>Ensures all payments are
                          accurately recorded and matched with corresponding
                          transactions, preventing discrepancies.
                        </li>
                        <li>
                          <b>Cash Flow Management: </b>Helps in tracking cash
                          inflows from different payment channels, providing a
                          clear picture of daily revenue.
                        </li>
                        <li>
                          <b>Audit Preparedness: </b> Provides a transparent
                          record of payment methods used, simplifying audits and
                          financial reporting.
                        </li>
                        <li>
                          <b>Operational Efficiency: </b>Helps in identifying
                          any pending or unmatched payments, ensuring smoother
                          financial operations.
                        </li>
                        <li>
                          <b>Fraud Prevention: </b>By reconciling payments
                          against bookings and invoices, this report helps
                          identify potential fraud or errors in financial
                          transactions.
                        </li>
                      </ul>
                      <p>
                        This report is a key tool for maintaining accurate and
                        organized financial records.
                      </p>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportSaleSummeryReport;
