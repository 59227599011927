import React from "react";

const InformationCustomPopup = ({ setInformationPopup, title, children }) => {
  return (
    <>
      <div
        className="global_popup_container_background"
        onClick={() => setInformationPopup(false)}
      >
        <div
          className="global_popup_container"
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="flex justify_content_between align_center"
            style={{ marginBottom: "10px" }}
          >
            <h4 className="room-type-heading"> {title}</h4>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              onClick={() => setInformationPopup(false)}
              style={{ margin: "0px 0 0 auto", cursor: "pointer" }}
            >
              <path
                d="M1 13.1501L13 0.850098"
                stroke="#AAAAAA"
                strokeWidth="1.2"
              />
              <path
                d="M13 13.1501L0.999999 0.850147"
                stroke="#AAAAAA"
                strokeWidth="1.2"
              />
            </svg>
          </div>

          {children}
        </div>
      </div>
    </>
  );
};

export default InformationCustomPopup;
