import React from "react";
import FbMenu from "../Components/FbMenu";
import FbMenuNew from "../Components/FbMenuNew";

const FbMenuPage = () => {
  return (
    <>
      <div className="main_container">
        {/* <FbMenu /> */}
        <FbMenuNew />
      </div>
    </>
  );
};

export default FbMenuPage;
