import React, { useState, useEffect, useRef } from "react";
import "./WhatsappBot.css";

const WhatsappBot = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showTalkToUs, setShowTalkToUs] = useState(true);
  const popupRef = useRef(null);
  const popupRefTwo = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };

    if (isMobile) {
      document.body.addEventListener("click", handleClickOutside);
    }

    return () => {
      if (isMobile) {
        document.body.removeEventListener("click", handleClickOutside);
      }
    };
  }, [isMobile]);

  return (
    <>
      {/* {showTalkToUs ? (
                <div className="talkToUsContainer">
                    <button className="talkToUsButton" onClick={() => setShowTalkToUs(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                            <line x1="0.474877" y1="8.64645" x2="8.47487" y2="0.646455" stroke="black" />
                            <line x1="0.353553" y1="0.646447" x2="8.35355" y2="8.64644" stroke="black" />
                        </svg>
                    </button>
                    <span><b style={{ lineHeight: "18px" }}>Hey! I am Muskan.</b> <br style={{ marginTop: "5px" }} />  How can I help you? </span>
                </div>
            ) : (
                ""
            )} */}

      <div
        className="whatsappPopupContainer"
        onMouseLeave={!isMobile ? () => setShowPopup(false) : undefined}
        onClick={isMobile ? () => setShowPopup(!showPopup) : undefined}
        ref={popupRef}
      >
        <div
          className="whatsappPopupIconContainer"
          onMouseEnter={!isMobile ? () => setShowPopup(true) : undefined}
        >
          <div className="flex align_items_center flex_gap_10">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29"
              height="28"
              viewBox="0 0 29 28"
              fill="none"
            >
              <circle cx="14.0312" cy="14" r="13.5" stroke="#3968ED" />
              <mask
                id="mask0_6745_2008"
                maskUnits="userSpaceOnUse"
                x="6"
                y="5"
                width="16"
                height="16"
              >
                <rect
                  x="6.66309"
                  y="5.89471"
                  width="14.7368"
                  height="14.7368"
                  fill="#D9D9D9"
                />
              </mask>
              <g mask="url(#mask0_6745_2008)">
                <path
                  d="M14.0312 20.0175V18.7895H18.3294V18.1754H15.8733V13.2632H18.3294V12.6491C18.3294 11.462 17.9099 10.4488 17.0707 9.60965C16.2315 8.77046 15.2183 8.35087 14.0312 8.35087C12.8441 8.35087 11.8309 8.77046 10.9917 9.60965C10.1525 10.4488 9.73295 11.462 9.73295 12.6491V13.2632H12.1891V18.1754H9.73295C9.39523 18.1754 9.10613 18.0552 8.86563 17.8147C8.62513 17.5742 8.50488 17.2851 8.50488 16.9474V12.6491C8.50488 11.8918 8.65072 11.178 8.94238 10.5077C9.23405 9.83735 9.63061 9.25146 10.1321 8.75C10.6335 8.24853 11.2194 7.85197 11.8898 7.5603C12.5601 7.26864 13.2739 7.1228 14.0312 7.1228C14.7885 7.1228 15.5023 7.26864 16.1726 7.5603C16.843 7.85197 17.4289 8.24853 17.9303 8.75C18.4318 9.25146 18.8283 9.83735 19.12 10.5077C19.4117 11.178 19.5575 11.8918 19.5575 12.6491V18.7895C19.5575 19.1272 19.4373 19.4163 19.1968 19.6568C18.9563 19.8973 18.6672 20.0175 18.3294 20.0175H14.0312ZM9.73295 16.9474H10.961V14.4912H9.73295V16.9474ZM17.1014 16.9474H18.3294V14.4912H17.1014V16.9474Z"
                  fill="#3968ED"
                />
              </g>
            </svg>
            <p className="whatsappPopupIconContainer_text">Support</p>
          </div>
          {showPopup && (
            <div className="whatsappPopupLinksContainer">
              <div
                className={`whatsappPopupLinksContainerChild ${
                  showPopup ? "show" : ""
                }`}
                onMouseLeave={!isMobile ? () => setShowPopup(false) : undefined}
                onMouseEnter={!isMobile ? () => setShowPopup(true) : undefined}
                ref={popupRefTwo}
              >
                <a href="tel:8091805354" className="flex mb-10 flex_gap_10">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                    >
                      <path
                        d="M4.23519 2.42244L3.90145 0.753732C3.81395 0.315618 3.42896 0 2.9821 0H0.961545C0.424062 0 -0.0234232 0.454364 0.000950983 0.991226C0.289067 7.3417 3.43771 9.75664 9.01003 9.99914C9.54689 10.0223 10 9.57665 10 9.03978V7.01796C10 6.57109 9.68439 6.1861 9.24627 6.0986L7.57758 5.76486C7.16322 5.68174 6.74448 5.88674 6.55511 6.26485L6.25012 6.87421C5.00016 6.87421 3.12522 4.99926 3.12522 3.74929L3.73458 3.44429C4.11332 3.25555 4.31831 2.83681 4.23519 2.42244Z"
                        fill="#3968ED"
                      />
                    </svg>
                  </div>
                  <span className="font_12">+91 8091805354 </span>
                </a>
                <a
                  href="mailto:support@revcatalyst.in"
                  className="flex flex_gap_10"
                >
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="9"
                      viewBox="0 0 12 9"
                      fill="none"
                    >
                      <path
                        d="M0 1.49428V8.03571C0 8.56827 0.402944 9 0.9 9H11.1C11.5971 9 12 8.56827 12 8.03571V1.49421L6.30227 5.05529C6.11546 5.17205 5.88445 5.17205 5.69763 5.05529L0 1.49428Z"
                        fill="#3968ED"
                      />
                      <path
                        d="M11.666 0.2145C11.5114 0.0803569 11.3144 0 11.1 0H0.9C0.685537 0 0.488595 0.0803712 0.333979 0.214536L5.99995 3.75577L11.666 0.2145Z"
                        fill="#3968ED"
                      />
                    </svg>
                  </div>
                  <span className="font_12">support@revcatalyst.in</span>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WhatsappBot;
