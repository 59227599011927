import React, { useState, useEffect } from "react";

function DropZone({ updateDataFromChild }) {
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const urls = selectedImages.map((image) => URL.createObjectURL(image));
    setImageUrls(urls);

    // Clean up object URLs when component unmounts
    return () => {
      urls.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [selectedImages]);

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    const updatedImages = [...selectedImages, ...newFiles];
    setSelectedImages(updatedImages);
    updateDataFromChild(updatedImages);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.dataTransfer.files);
    setSelectedImages([...selectedImages, ...newFiles]);
    updateDataFromChild(selectedImages);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div
      className="file_content_block"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <input
        // accept="image/*"
        type="file"
        id="select-image"
        onChange={handleFileChange}
        multiple
        className="dropzone_file_input"
      />

      {selectedImages.length > 0 ? (
        <div>
          {selectedImages.map((image, index) => (
            <div key={index} className="dropzone_filesList">
              <img
                src={imageUrls[index]}
                alt="error"
                height="50px"
                width="50px"
              />
              <p className="dropzone_display_img_name">{image.name}</p>
            </div>
          ))}
        </div>
      ) : (
        <div style={{ margin: "20px auto" }}>
          <p className="dropzone_add_files_button">Add Files</p>
          <p className="dropzone_drop_message">or drop JPG, PNG images here</p>
        </div>
      )}
    </div>
  );
}

export default DropZone;
