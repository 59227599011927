import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { CheckWithoutBgUnderlineBtn } from "../../UI/Buttons";
import { getDecryptedData } from "../../../utils/encryptStorage";

const EventCommunicationBill = ({ eventid }) => {
  const [showShareOnEmail, setShowShareOnEmail] = useState(false);
  const [showShareOnWhatsapp, setShowShareWhatsapp] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [urlToSend, setUrlToSend] = useState("");
  const shareLink = urlToSend;

  const [voucherEmail, setVoucherEmail] = useState("");
  const [billEmail, setBillEmail] = useState("");

  const property_id = useSelector((state) => state.property.value);

  const userToken = getDecryptedData('encryptToken');
  const navigate = useNavigate();

  function getDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${year}-${month}-${date}`;
  }

  const [currentDate, setCurrentDate] = useState(getDate());

  const [editInputBillShow, setEditInputBillShow] = useState(false);

  const [activeTabButtonShare, setActiveTabButtonShare] = useState("");

  async function fetchData(propertyId) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/event/view/bill/${eventid}/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setEventData(data.event);
    } catch (error) {
      console.error("API request error:", error);
    }
  }

  useEffect(() => {
    if (property_id) {
      fetchData(property_id?.id);
    }
  }, [property_id]);

  const [pdfFile, setPdfFile] = useState(null);

  const handlePrintClick = () => {
    window.print();
  };

  const handleShowShareOnEmail = (click) => {
    setActiveTabButtonShare(click);
    if (showShareOnWhatsapp === true) setShowShareWhatsapp(false);
    setShowShareOnEmail(!showShareOnEmail);
  };

  const handleShowShareOnWhatsapp = (click) => {
    setActiveTabButtonShare(click);
    if (showShareOnEmail === true) setShowShareOnEmail(false);
    setShowShareWhatsapp(!showShareOnWhatsapp);
  };

  const handleDownload = () => {
    const htmlContent = document.querySelector(
      ".booking-choice-full-parent-container-left"
    );

    const dpi = 500;
    const scale = 10;
    const imageQuality = 1;

    html2canvas(htmlContent, { dpi: dpi, scale: scale }).then((canvas) => {
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = 210;
      const pdfHeight = 297;

      const aspectRatio = canvas.width / canvas.height;
      let imgWidth = pdfWidth;
      let imgHeight = pdfWidth / aspectRatio;

      if (imgHeight > pdfHeight) {
        imgHeight = pdfHeight;
        imgWidth = pdfHeight * aspectRatio;
      }

      const xOffset = (pdfWidth - imgWidth) / 2;
      const yOffset = 0;

      pdf.addImage(
        canvas.toDataURL("image/jpeg", imageQuality),
        "PNG",
        xOffset,
        yOffset,
        imgWidth,
        imgHeight
      );
      const generatedPdfFile = pdf.output("blob");
      const pdfUrl = URL.createObjectURL(generatedPdfFile);

      // Log the PDF URL
      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = pdfUrl;
      downloadLink.download = "downloaded-page.pdf";
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      setPdfFile(downloadLink);
      setUrlToSend(downloadLink);
      // Simulate a click on the download link to trigger the download
      downloadLink.click();

      // Clean up by removing the download link
      document.body.removeChild(downloadLink);
    });
  };

  const IndianPriceComponent = ({ price }) => {
    const formattedPrice = price?.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    });

    return <div>{formattedPrice}</div>;
  };

  const formatIndianPrice = (price) => {
    const priceString = price.toString();

    const [rupees, paise] = priceString.split(".");

    const formattedRupees = rupees.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const formattedPrice = paise
      ? `${formattedRupees}.${paise}`
      : formattedRupees;

    return formattedPrice;
  };

  const handleWhatsAppbuttonClick = () => {
    const htmlContent = document.querySelector(
      ".booking-choice-full-parent-container-left"
    );

    const dpi = 500;
    const scale = 10;
    const imageQuality = 1;

    html2canvas(htmlContent, { dpi: dpi, scale: scale }).then((canvas) => {
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = 210;
      const pdfHeight = 297;

      const aspectRatio = canvas.width / canvas.height;
      let imgWidth = pdfWidth;
      let imgHeight = pdfWidth / aspectRatio;

      if (imgHeight > pdfHeight) {
        imgHeight = pdfHeight;
        imgWidth = pdfHeight * aspectRatio;
      }

      const xOffset = (pdfWidth - imgWidth) / 2;
      const yOffset = 0;

      pdf.addImage(
        canvas.toDataURL("image/jpeg", imageQuality),
        "PNG",
        xOffset,
        yOffset,
        imgWidth,
        imgHeight
      );
      const generatedPdfFile = pdf.output("blob");
      const pdfUrl = URL.createObjectURL(generatedPdfFile);

      const downloadLink = document.createElement("a");
      downloadLink.href = pdfUrl;
      downloadLink.download = "downloaded.pdf";
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      setPdfFile(downloadLink);
      setUrlToSend(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
    });
    // if (/^\d{10}$/.test(whatsappNumber)) {
    //   const linkText = `Click this link to view the shared content:`;
    //   const whatsappMessage = `${linkText}: ${urlToSend}`;
    //   const whatsappUrl = `https://wa.me/91${whatsappNumber}?text=${encodeURIComponent(
    //     whatsappMessage
    //   )}`;

    //   window.open(whatsappUrl, "_blank");
    // } else {
    //   showErrorToast(
    //     "Please enter a valid 10-digit WhatsApp number without country code."
    //   );
    // }
    const shareUrl = `blob:http://localhost:3000/3de55a2a-ed5a-4bed-aa05-d62005c918fd`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      shareUrl
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleEmailButtonClick = async () => {
    try {
      // setLoader(true);
      const response = await fetch(
        // http://127.0.0.1:8000/api/v1/send/event/bill/23/abhimanyuun001@gmail.com/1
        `${process.env.REACT_APP_BASE_URL}/api/v1/send/event/bill/${eventid}/${voucherEmail}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        setVoucherEmail("");
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {/* <div className="goBackBtn">
          <Link to="/communication" style={{ display: "inline-block" }}>
            <CheckWithoutBgUnderlineBtn children={"Go Back"} />
          </Link>
        </div> */}

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="booking-choice-full-parent-container-left">
          <div
            style={{
              width: "800px",
              margin: "auto",
              backgroundColor: "#fff",
              padding: "10px",
            }}
          >
            <div style={{ width: "800px" }} className="invoice_container">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <table>
                    <tbody>
                      {eventData && eventData?.propertyDetail && (
                        <>
                          <tr>
                            <td colSpan="2" className="property_headings">
                              {eventData?.propertyDetail?.property_name}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" className="property_address">
                              {eventData?.propertyDetail?.property_address}
                            </td>
                          </tr>
                          <tr>
                            <td className="property_address">
                              Ph. {eventData?.propertyDetail?.property_phone}
                            </td>
                          </tr>
                          <tr>
                            <td className="property_address">
                              Email. {eventData?.propertyDetail?.property_email}
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="invoice_gstin">
                  <table>
                    <tbody style={{ textAlign: "end" }}>
                      {eventData && eventData?.bill && (
                        <>
                          <tr>
                            <td className="property_address Invoice_bills">
                              Bill No :
                            </td>
                            <td className="property_address Invoice_bills">
                              {eventData?.bill?.bill_no}
                            </td>
                          </tr>
                          <tr>
                            <td className="property_address Invoice_bills">
                              Original Bill Date :
                            </td>
                            <td className="property_address Invoice_bills">
                              {eventData?.bill?.date}
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div style={{ width: "800px" }} className="invoice_container">
              <div className="invoice_billDetails">
                <div className="invoice_details">
                  <table className="invoice-details-table">
                    {eventData && eventData?.event && (
                      <tbody>
                        <tr>
                          <th className="property_paragraph property_large ">
                            Event ID :
                          </th>
                          <td className="property_paragraph property_small">
                            :
                          </td>
                          <td className="property_paragraph property_medium">
                            {eventData?.event?.event_id}
                          </td>
                        </tr>
                        <tr>
                          <th className="property_paragraph property_large ">
                            Event Name :
                          </th>
                          <td className="property_paragraph property_small">
                            :
                          </td>
                          <td className="property_paragraph property_medium">
                            {eventData?.event?.event_name}
                          </td>
                        </tr>
                        <tr>
                          <th className="property_paragraph property_large ">
                            Client Name :
                          </th>
                          <td className="property_paragraph property_small">
                            :
                          </td>
                          <td className="property_paragraph property_medium">
                            {eventData?.event?.client_name}
                          </td>
                        </tr>
                        <tr>
                          <th className="property_paragraph property_large">
                            Client Phone
                          </th>
                          <td className=" property_paragraph property_small">
                            :
                          </td>
                          <td className="property_paragraph property_medium">
                            {eventData?.event?.client_phone}
                          </td>
                        </tr>
                        <tr>
                          <th className="property_paragraph property_large">
                            Client Email
                          </th>
                          <td className=" property_paragraph property_small">
                            :
                          </td>
                          <td className="property_paragraph property_medium">
                            {eventData?.event?.client_email}
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
                <div className="invoice_details">
                  <table className="invoice-Detail-table">
                    <tbody>
                      {eventData && eventData.event && (
                        <>
                          <tr>
                            <th className="property_paragraph property_large">
                              No Of Rooms Booked
                            </th>
                            <td className="property_paragraph property_small">
                              :
                            </td>
                            <td className=" property_paragraph property_medium">
                              {eventData?.event?.rooms?.length}
                            </td>
                          </tr>
                          <tr>
                            <th className="property_paragraph property_large">
                              No Of Pax
                            </th>
                            <td className="property_paragraph property_small">
                              :
                            </td>
                            <td className=" property_paragraph property_medium">
                              {eventData?.event?.no_of_pax}
                            </td>
                          </tr>
                          <tr>
                            <th className="property_paragraph property_large">
                              Per Plate Cost
                            </th>
                            <td className="property_paragraph property_small">
                              :
                            </td>
                            <td className="property_paragraph property_medium">
                              {eventData?.event?.per_plate_cost}
                            </td>
                          </tr>
                          <tr>
                            <th className="property_paragraph property_large">
                              Generated By
                            </th>
                            <td className="property_paragraph property_small">
                              :
                            </td>
                            <td className="property_paragraph property_medium">
                              {eventData?.bill?.generated_by}
                            </td>
                          </tr>
                          <tr>
                            <th className="property_paragraph property_large">
                              Payment Mode
                            </th>
                            <td className="property_paragraph property_small">
                              :
                            </td>
                            <td className="property_paragraph property_medium">
                              {eventData?.bill?.payment_mode
                                .replace("_", " ")
                                .toUpperCase()}
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div style={{ width: "800px" }} className="invoice_container">
              <div className="transaction-table-container" style={{}}>
                <table className="transaction-table">
                  <thead>
                    <tr className="table-head-row">
                      <th className="table-header">Item Name</th>
                      <th className="table-header">Item Price</th>
                      <th className="table-header">Quantity</th>
                      <th className="table-header">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventData.event &&
                      eventData.event.items.map((item) => {
                        return (
                          <tr>
                            <td className="table-data">{item?.item_name}</td>
                            <td className="table-data">{item?.price}</td>
                            <td className="table-data">{1}</td>
                            <td className="table-data">{item?.price}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>

            <div style={{ width: "800px" }} className="invoice_container">
              <div className="amount_to_pay">
                {eventData && eventData.event && (
                  <>
                    <div className="invoice_charges">
                      <p className="invoice_total">Tax Applied</p>
                      <p className="invoice_money">
                        <b>{eventData?.event?.tax_applied || ""}%</b>
                      </p>
                    </div>
                    <div className="invoice_charges">
                      <p className="invoice_total">GST Type</p>
                      <p className="invoice_money">
                        <p>{eventData?.event?.gst_type?.toUpperCase() || ""}</p>
                      </p>
                    </div>
                    <div className="invoice_charges">
                      <p className="invoice_total">Sub Total</p>
                      <p className="invoice_money">
                        <b>
                          ₹
                          {formatIndianPrice(eventData?.event?.sub_total) || ""}
                          .00
                        </b>
                      </p>
                    </div>
                    <div className="invoice_charges">
                      <p className="invoice_total">Tax Amount</p>
                      <p className="invoice_money">
                        <b>
                          ₹
                          {formatIndianPrice(eventData?.event?.tax_amount) ||
                            ""}
                          .00
                        </b>
                      </p>
                    </div>
                    <div className="invoice_charges">
                      <p className="invoice_total">Grand Total</p>
                      <p className="invoice_money">
                        <b>
                          ₹
                          {formatIndianPrice(eventData?.event?.grand_total) ||
                            ""}
                          .00
                        </b>
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EventCommunicationBill;
