import React from "react";
import EventVoucher from "../Components/EventVoucher/EventVoucher";

const EventVoucherPage = () => {
  return (
    <>
      <div className="main_container">
        <EventVoucher />
      </div>
    </>
  );
};

export default EventVoucherPage;
