import React from "react";
import NavTabs from "../../Components/NewCustomComp/NavTabs/NavTabs";
import { Outlet } from "react-router-dom";

const WebsitePageNavigation = () => {
  const links = [
    {
      label: "Blogs",
      to: "blogs",
    },
    {
      label: "Pages",
      to: "pages",
    },
    {
      label: "Menus Location",
      to: "menuLocation",
    },
    {
      label: "Menus",
      to: "menus",
    },
    {
      label: "Discounts",
      to: "discounts",
    },
    {
      label: "Offers",
      to: "offers",
    },
  ];
  return (
    <>
      <div className="main_container">
        <NavTabs mainData={links} />
        <div>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default WebsitePageNavigation;
