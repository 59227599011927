import React from "react";
import Customers from "../Components/Customers";

const CustomersPage = () => {
  return (
    <>
      <div className="main_container">
        <Customers />
      </div>
    </>
  );
};

export default CustomersPage;
