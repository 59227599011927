import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import BookingStatusUpdate from "../CustomComponents/BookingStatusUpdate";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import { useState } from "react";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";

const ReportPaymentInvoice = ({
  departureReport,
  loader,
  settlementOrders,
  getSingleBookingDataHandler,
}) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const names = [
    {
      bill_created: "Bill Created",
      booking_id: "Booking ID",
      bill_no: "Bill No",
      booked_on: "Booked On",
      guest_name: "Guest Name",
      check_in: "Check In",
      check_out: "Check Out",
      booking_source: "Booking Source",
      status: "Status",
    },
  ];

  const [informationPopup, setInformationPopup] = useState(false);

  //   bill_created
  // :
  // "01 Jan, 1970"
  // bill_no
  // :
  // null
  // booked_on
  // :
  // "02 Feb, 2024"
  // booking_id
  // :
  // 304723
  // booking_source
  // :
  // "walk_in_bookings"
  // check_in
  // :
  // "2024-02-05"
  // check_out
  // :
  // "2024-02-07"
  // guest_name
  // :
  // null
  // status
  // :
  // null

  const rowMarkup =
    departureReport &&
    departureReport.length > 0 &&
    departureReport?.map((order) => (
      <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
        <CustomReportTableCell>{order.bill_created}</CustomReportTableCell>
        <CustomReportTableCell>
          <UnderscoreRemoveCapitalize
            text={order.bill_no ? order.bill_no : "N/A"}
          />{" "}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {" "}
          <div
            className="pointer"
            style={{
              color: "rgba(57, 104, 237, 1)",
            }}
            onClick={() => getSingleBookingDataHandler(order.booking_id)}
          >
            #{order.booking_id || ""}
          </div>
        </CustomReportTableCell>

        <CustomReportTableCell>
          {" "}
          {order.booked_on ? order.booked_on : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.guest_name ? order.guest_name : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.check_in ? order.check_in : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.check_out ? order.check_out : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          <UnderscoreRemoveCapitalize
            text={order.booking_source ? order.booking_source : "N/A"}
          />
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.status ? <BookingStatusUpdate status={order.status} /> : "N/A"}
        </CustomReportTableCell>
      </CustomReportTableRow>
    ));

  // const rowMarkup = departureReport?.map(
  //   ({
  //     id,
  //     bill_created,
  //     booking_id,
  //     bill_no,
  //     booked_on,
  //     check_in,
  //     check_out,
  //     booking_source,
  //     status,
  //   }) => (
  //     <CustomReportTableRow
  //       CustomReportTableRow
  //       id={id}
  //       key={id}
  //       // bodyStyles={{
  //       //   backgroundColor: "red",
  //       //   fontSize: "10px",
  //       // }}
  //     >
  //       <CustomReportTableCell>{item_name}</CustomReportTableCell>
  //       <CustomReportTableCell>
  //         {item_category ? item_category : "N/A"}
  //       </CustomReportTableCell>
  //       <CustomReportTableCell>
  //         {unit_name ? unit_name : "N/A"}
  //       </CustomReportTableCell>
  //       <CustomReportTableCell>{purchased_quantity}</CustomReportTableCell>
  //       <CustomReportTableCell>
  //         {formatIndianCurrency(total_amount)}
  //       </CustomReportTableCell>
  //       <CustomReportTableCell>
  //         {issued_quantity ? issued_quantity : 0}
  //       </CustomReportTableCell>
  //       <CustomReportTableCell>
  //         {formatIndianCurrency(issued_amount)}
  //       </CustomReportTableCell>
  //       <CustomReportTableCell>
  //         {remaining_quantity ? remaining_quantity : 0}
  //       </CustomReportTableCell>
  //       <CustomReportTableCell>
  //         {formatIndianCurrency(remaining_amount)}
  //       </CustomReportTableCell>
  //     </CustomReportTableRow>
  //   )
  // );

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        // <div style={{ display: "flex" }}>

        <div
          className="room-types-full-container reports-new-background"
          id="receipt"
        >
          <div className="room-types-full-container-div">
            <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
              <div className="room-type-heading">Invoice Report</div>
              <div
                className="hoverCursor"
                onClick={() => setInformationPopup(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#3968ED"
                >
                  <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                </svg>
              </div>
            </div>
            {departureReport?.length > 0 && (
              <div className="flex flex_gap_15">
                <DownloadPdfTables
                  tableData={departureReport}
                  tableNames={names}
                  downloadFileName="invoice_report"
                />
                <DownloadCSVButton
                  downloadFileName="invoice_report"
                  itemToDownloadID={"receipt"}
                />
              </div>
            )}
          </div>

          {departureReport && departureReport.length > 0 ? (
            <>
              {/* <LegacyCard>
                <IndexTable
                  resourceName={resourceName}
                  itemCount={departureReport.length}
                  selectable={false}
                  headings={[
                    { title: "Bill Created" },
                    { title: "Booking ID" },
                    { title: "Bill No" },
                    { title: "Booked On" },
                    { title: "Guest Name" },
                    { title: "Check In" },
                    { title: "Check In" },
                    { title: "Booking Source" },
                    { title: "Status" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>
              </LegacyCard> */}
              <CustomReportTable
                headings={[
                  "Bill Created",
                  "Invoice No",
                  "Booking ID",
                  "Booked On",
                  "Guest Name",
                  "Check In",
                  "Check Out",
                  "Booking Source",
                  "Status",
                ]}
                // commonHeadings={[
                //   "",
                //   "",
                //   "",
                //   "Purchased",
                //   "Purchased",
                //   "Issued",
                //   "Issued",
                //   "Stock",
                //   "Stock",
                // ]}
                headerStyles={{
                  backgroundColor: "#dddddd",
                  fontSize: "12px",
                  textAlign: "center",
                }}
                tableStyles={{ textAlign: "left" }}
              >
                {rowMarkup}
                {/* {calculateTotals} */}
              </CustomReportTable>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>

        // </div>
      )}

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of Invoice Report"
        >
          <div className="mb-20">
            <AccordionComponent
              questions={[
                {
                  question: "What is the Invoice Report?",
                  answer: (
                    <p>
                      The <b>Invoice Report</b> provides a comprehensive
                      breakdown of invoices generated for guest bookings,
                      filtered by booking date or check-in date. This report
                      captures essential information such as the booking source,
                      invoice status, payment status, and balance details. It is
                      particularly useful for tracking and managing guest
                      payments and outstanding balances across various booking
                      channels.
                    </p>
                  ),
                },
                {
                  question: "What is the Importance of the Invoice Report?",
                  answer: (
                    <div>
                      <p>The Invoice Report is crucial for:</p>

                      <ul>
                        <li>
                          <b>Payment Tracking: </b>Helps monitor payments made,
                          pending amounts, and payment statuses (e.g., paid,
                          partially paid, unpaid).
                        </li>
                        <li>
                          <b>Financial Oversight: </b>Ensures that all invoices
                          are generated, and discrepancies such as unpaid or
                          partially paid bookings are addressed.
                        </li>
                        <li>
                          <b>Operational Transparency: </b>Provides a clear view
                          of the financial standing of each booking, including
                          no-shows and cancellations.
                        </li>
                        <li>
                          <b>Channel Performance: </b>Allows for analysis of the
                          performance of different booking sources (e.g., OTA,
                          website, agents) and the related invoice settlements.
                        </li>
                      </ul>
                      <p>
                        This report is key for ensuring smooth financial
                        operations, improving payment collection, and managing
                        outstanding balances.
                      </p>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportPaymentInvoice;
