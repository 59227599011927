import React, { useState } from "react";
import styles from "./agentDashboard.module.scss";

const Tooltip = ({ text }) => (
  <div className={styles.tooltip}>
    {text}
  </div>
);

const MonthlySales = ({ monthlySalesData ,hideTax }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const showTooltip = (index) => {
    setActiveIndex(index);
  };

  const hideTooltip = () => {
    setActiveIndex(null);
  };

  return (
    <div className="flex align_items_center justify_content_center flex_gap_5 w-100">
      {monthlySalesData.map((item, index) => (
        <div
          key={index}
          className={`flex align_items_center justify_content_center flex_direction_col pointer ${styles.sales_info}`}
          onMouseEnter={() => showTooltip(index)} // Show tooltip on mouse enter
          onMouseLeave={hideTooltip} // Hide tooltip on mouse leave
          style={{ position: "relative" }}
        >
          <span className={styles.amount}>₹ {item.amount  || 0   }</span>
          <span className={styles.label}>{item.label}</span>
          {(activeIndex === index &&  item?.tax )  && (
            <Tooltip text={`Tax: ₹ ${item.tax}`} /> // Show tooltip for the active item
          )}
        </div>
      ))}
    </div>
  );
};

export default MonthlySales;
