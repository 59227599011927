import React from 'react'
import styles from "../Components/PropertyTargets/PropertyTargets.module.scss"

import { Outlet } from 'react-router-dom'
import NavTabs from '../Components/NewCustomComp/NavTabs/NavTabs'

const PropertyTargetsPage = () => {

    const switchTabs = [
        { label: "Property Analysis", to: "property-targets" },
        { label: "Competitor Analysis", to: "competitors-targets" }
    ]

  return (
    <section className={styles.targetsLayout}>
      <NavTabs mainData={switchTabs} />

      <Outlet>

      </Outlet>
    </section>
  )
}

export default PropertyTargetsPage
