import { createSlice } from '@reduxjs/toolkit';

const activeAppSlice = createSlice({
  name: 'activeApp',
  initialState: {
    activeApp: null,
  },
  reducers: {
    setActiveApp(state, action) {
      state.activeApp = action.payload;
    },
    clearActiveApp(state) {
      state.activeApp = null;
    },
  },
});

export const { setActiveApp, clearActiveApp } = activeAppSlice.actions;

export default activeAppSlice.reducer;