import {
  IndexTable,
  LegacyCard,
  useIndexResourceState,
  Button,
  Icon,
  Tooltip,
} from "@shopify/polaris";

import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { useState, useEffect } from "react";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
} from "./UI/Buttons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import NoDataFound from "./CustomComponents/NoDataFound";
import Loader from "./UI/Loader/Loader";
import SidebarInnerLists from "./CustomComponents/SidebarInnerLists";
import { pms_inner_lists } from "../assets/constant";
import CustomPagination from "./CustomComponents/CustomPagination/CustomPagination";
import GoBackButtonCustom from "./GoBackButtonCustom/GoBackButtonCustom";
import CustomPaginationArrows from "./CustomComponents/CustomPagination/CustomPaginationArrows";
import ItemIssueSearch from "./CustomComponents/ItemIssueSearch";
import { getDecryptedData } from "../utils/encryptStorage";

const Suppliers = () => {
  const role = JSON.parse(localStorage.getItem("role"));

  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const permission = JSON.parse(localStorage.getItem("permissions"));

  const [permissions, setPermissions] = useState(permission);

  useEffect(() => {
    setPermissions(permissions);
  }, [permissions]);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);

  const navigate = useNavigate();
  const userToken = getDecryptedData('encryptToken');

  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(true);

  const [orders, setOrders] = useState([]);

  // Pagignation
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  const [search, setSearch] = useState("");
  const handleChildData = (data) => {
    setSearch(data);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // 👇 Get input value
      if (property_id) {
        fetchInfo(property_id?.id);
      }
    }
  };

  const handlePageChange = (page, newUrl) => {
    setCurrentPage(page);
    if (newUrl) {
      fetchInfo(property_id?.id, newUrl);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentOrders = orders.slice(startIndex, endIndex);

  const fetchInfo = async (propertyId, newUrl) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        // /api/v1/expenses/1
        `${process.env.REACT_APP_BASE_URL}/api/v1/suppliers/${propertyId}${
          "/" + newUrl && newUrl
        }`,
        {
          body: JSON.stringify({ search: search }),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data.supplier.data);

      setTotalPages(data?.supplier?.last_page);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };
  const fetchInfoClearFunction = async (propertyId, newUrl) => {
    try {
      setLoader(true);
      const response = await fetch(
        // /api/v1/expenses/1
        `${process.env.REACT_APP_BASE_URL}/api/v1/suppliers/${property_id?.id}`,
        {
          body: JSON.stringify({ search: "" }),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data.supplier.data);

      setTotalPages(data?.supplier?.last_page);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

   useEffect(() => {
    if (property_id  && userToken)  {
      fetchInfo(property_id?.id);
    }
  }, [property_id, userToken]);

  setTimeout(() => {
    setLoader(false);
  }, 1000);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(currentOrders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
    // setShowLowerMenuSingleSelect(false);
    // setShowLowerMenuMultipleSelect(false);(!showLowerMenuAddMenu);
    setAddItemData({
      supplier_name: "",
      supplier_phone: "",
      supplier_city: "",
      supplier_country: "",
      supplier_gst: "",
      supplier_email: "",
      supplier_state: "",
      supplier_zipcode: "",
    });
    clearSelection();
  };

  const removeAddModalContainer = () => {
    setShowAddModelMenuActive(false);
    setAddItemData({
      supplier_name: "",
      supplier_phone: "",
      supplier_city: "",
      supplier_country: "",
      supplier_gst: "",
      supplier_email: "",
      supplier_state: "",
      supplier_zipcode: "",
    });
  };

  // UseState for forms
  const [addItemData, setAddItemData] = useState({
    supplier_name: "",
    supplier_phone: "",
    supplier_city: "",
    supplier_country: "",
    supplier_gst: "",
    supplier_email: "",
    supplier_state: "",
    supplier_zipcode: "",
  });

  const setAddItemDataHandleChange = (event) => {
    setAddItemData({
      ...addItemData,
      [event.target.name]: event.target.value,
    });
  };

  const getOneData = async (id) => {
    try {
      const response = await fetch(
        // supplier
        `${process.env.REACT_APP_BASE_URL}/api/v1/supplier/edit/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        supplier_name: data.supplier.supplier_name,
        supplier_phone: data.supplier.supplier_phone,
        supplier_city: data.supplier.supplier_city,
        supplier_country: data.supplier.supplier_country,
        supplier_gst: data.supplier.supplier_gst,
        supplier_email: data.supplier.supplier_email,
        supplier_state: data.supplier.supplier_state,
        supplier_zipcode: data.supplier.supplier_zipcode,
      };

      setAddItemData(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  const rowMarkup = currentOrders.map(
    ({
      id,
      supplier_name,
      supplier_phone,
      supplier_email,
      supplier_gst,
      index,
    }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={supplier_name}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={supplier_phone}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={supplier_email}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={supplier_gst}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const removeItemConfirm = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const updateFunction = (id, event) => {
    event.preventDefault();

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/supplier/update/${id}/${property_id?.id}`,
      {
        method: "POST",

        body: JSON.stringify({ ...addItemData }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          fetchInfo(property_id?.id);
          setShowEditModelMenuActive(false);
          clearSelection();
          showSuccessToast(data.message);
        } else {
          const firstErrorKey = Object.keys(data.data)[0];
          const firstErrorMessage = data.data[firstErrorKey][0];
          showErrorToast("firstErrorMessage");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const validate = (values) => {
    const errors = {};
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneNumberRegex = /^([+]\d{2})?\d{10}$/;
    const pincodeRegex = /^[1-9][0-9]{5}$/;

    if (!values.supplier_email) {
      // errors.property_email = "Email is required!";
    } else {
      if (emailRegex.test(values.supplier_email)) {
        errors.supplier_email = "";
      } else {
        errors.supplier_email = "This is not a valid email format!";
      }
    }

    if (!values.supplier_phone) {
      errors.supplier_phone = "Phone number is required!";
    } else {
      if (phoneNumberRegex.test(values.supplier_phone)) {
        errors.supplier_phone = "";
      } else {
        errors.supplier_phone = "This is not a valid Phone Number!";
      }
    }

    if (!values.supplier_zipcode) {
      errors.supplier_zipcode = "Pincode is required!";
    } else {
      if (pincodeRegex.test(values.supplier_zipcode)) {
        errors.supplier_zipcode = "";
      } else {
        errors.supplier_zipcode = "This is not a valid Pin Code";
      }
    }

    return errors;
  };

  const handleSubmitAddExpenseHandler = (event) => {
    event.preventDefault();

    const errors = validate(addItemData);
    setFormErrors(errors);
    const hasErrors = Object.values(errors).some((error) => !!error);
    if (hasErrors === true) {
      showErrorToast("Please check the above errors.");
      return;
    }

    // http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/assign/rooms/store
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/supplier/create/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...addItemData }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          navigate("/hotelier/suppliers");
          setShowAddModelMenuActive(false);
          fetchInfo(property_id?.id);
          // setAddItemData({
          //   room_id: "",
          //   room_name: "",
          //   room_no: "",
          //   floor_no: "",
          //   status: "booked",
          // });
        } else {
          const firstErrorKey = Object.keys(data.data)[0];
          const firstErrorMessage = data.data[firstErrorKey][0];
          showErrorToast(firstErrorMessage);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const deletedIds = {
    ids: selectedResources,
  };
  const deleteData = async () => {
    clearSelection();
    // "http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/assign/rooms/delete"
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/supplier/delete/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          clearSelection();
          fetchInfo(property_id?.id);
          clearSelection();
          setShowLowerMenuMultipleSelect(false);
          setShowModelMenuActive(false);
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  return (
    <>
      <SidebarInnerLists
        innerLists={pms_inner_lists}
        show={role === "store" ? false : true}
      />
      <div className="room-types-full-container">
        <div className="room-types-full-container-div">
          <GoBackButtonCustom buttonNextText={"Suppliers"} showSvg={false} />
          <div className="flex gap_7">
            {permissions && permissions.includes("create_suppliers") ? (
              <BgThemeButton
                children={"Add Supplier"}
                onClick={addModalButtonClickHandler}
              />
            ) : (
              <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
                <BgThemeButtonDisabled
                  children={"Add Supplier"}
                  // onClick={addModalButtonClickHandler}
                />
              </Tooltip>
            )}
          </div>
        </div>

        {loader ? (
          <Loader />
        ) : (
          <>
            <div className="booking-choice-full-parent-container-right-heading-new">
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#EFF0F2",
                }}
              >
                <div
                  className="reservation_bar_container justify_content_between"
                  style={{ width: "100%", position: "static" }}
                >
                  <div>
                    {/* <div className="reservation_total">
                  <CustomDateRangeSelector
                      onSelectedRangeChange={handleSelectedRangeChange}
                      onApplyButtonClick={handleApplyButtonClick}
                      onDateRangeSelected={handleDateRangeSelected}
                    />
                  </div> */}
                    <div className="reservation_total">
                      <h5 className="reservation_total_heading">
                        {orders && orders?.length}
                      </h5>
                      <p className="reservation_total_paragraph">
                        Total Suppliers
                      </p>
                    </div>
                  </div>
                  <div className="flex  flex_gap_15">
                    <ItemIssueSearch
                      onKeyDown={handleKeyDown}
                      sendDataToParent={handleChildData}
                      setKotID={setSearch}
                      clearFunction={(data) => fetchInfoClearFunction(data)}
                      placeholder="Search by name/email"
                    />
                    <CustomPaginationArrows
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                      apiUrl={""}
                    />
                  </div>
                </div>
              </div>
            </div>

            {loader ? (
              <Loader />
            ) : (
              <>
                {orders && orders.length > 0 ? (
                  <>
                    <LegacyCard>
                      <IndexTable
                        resourceName={resourceName}
                        itemCount={currentOrders.length}
                        selectedItemsCount={
                          allResourcesSelected
                            ? "All"
                            : selectedResources.length
                        }
                        onSelectionChange={handleSelectionChange}
                        headings={[
                          { title: "Supplier Name" },
                          { title: "Mobile Number" },
                          { title: "Email" },
                          { title: "GST NO." },
                        ]}
                      >
                        {rowMarkup}
                      </IndexTable>
                    </LegacyCard>
                    {/* <CustomPaginationArrows
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                      apiUrl={""}
                    /> */}
                  </>
                ) : (
                  <NoDataFound />
                )}

                {/* Single select */}
                {showLowerMenuSingleSelect && (
                  <div className=" room-type-bottom-container">
                    <div className="room-type-bottom-container-div">
                      <div>
                        {permissions &&
                        permissions.includes("delete_suppliers") ? (
                          <Button
                            plain
                            destructive
                            // onClick={showModelMenu}
                            onClick={() => showModelMenu()}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span>
                                <Icon source={DeleteMajor} color="base" />
                              </span>
                              <span>Remove</span>
                            </div>
                          </Button>
                        ) : (
                          <Tooltip
                            content="Please Contact Your Admin"
                            dismissOnMouseOut
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                opacity: "0.4",
                                cursor: "no-drop",
                              }}
                            >
                              <span>
                                <Icon source={DeleteMajor} color="base" />
                              </span>
                              <span>Remove</span>
                            </div>
                          </Tooltip>
                        )}
                      </div>
                      <form
                        onSubmit={(e) =>
                          updateFunction(selectedResources[0], e)
                        }
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="room-type-bottom-div">
                          <div>Phone Number</div>
                          <div style={{ width: "200px" }}>
                            <VerticalInputFieldCustom
                              error={formErrors.supplier_phone ? true : false}
                              type={"number"}
                              name="supplier_phone"
                              value={addItemData.supplier_phone}
                              onChange={setAddItemDataHandleChange}
                            />
                            {formErrors.supplier_phone && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                  gap: "5px",
                                  marginTop: "3px",
                                }}
                              >
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                  >
                                    <g clipPath="url(#clip0_1_301)">
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM6.3 4.2C6.3 3.8134 6.6134 3.5 7 3.5C7.3866 3.5 7.7 3.8134 7.7 4.2V7C7.7 7.3866 7.3866 7.7 7 7.7C6.6134 7.7 6.3 7.3866 6.3 7V4.2ZM7 10.5C7.3866 10.5 7.7 10.1866 7.7 9.8C7.7 9.4134 7.3866 9.1 7 9.1C6.6134 9.1 6.3 9.4134 6.3 9.8C6.3 10.1866 6.6134 10.5 7 10.5Z"
                                        fill="#E03838"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_1_301">
                                        <rect
                                          width="14"
                                          height="14"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </div>
                                <p style={{ color: "red" }}>
                                  {formErrors.supplier_phone}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="room-type-bottom-div">
                          <div>Email</div>
                          <div style={{ width: "200px" }}>
                            <VerticalInputFieldCustom
                              error={formErrors.supplier_email ? true : false}
                              type={"email"}
                              name="supplier_email"
                              value={addItemData.supplier_email}
                              onChange={setAddItemDataHandleChange}
                            />
                            {formErrors.supplier_email && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                  gap: "5px",
                                  marginTop: "3px",
                                }}
                              >
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                  >
                                    <g clipPath="url(#clip0_1_301)">
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM6.3 4.2C6.3 3.8134 6.6134 3.5 7 3.5C7.3866 3.5 7.7 3.8134 7.7 4.2V7C7.7 7.3866 7.3866 7.7 7 7.7C6.6134 7.7 6.3 7.3866 6.3 7V4.2ZM7 10.5C7.3866 10.5 7.7 10.1866 7.7 9.8C7.7 9.4134 7.3866 9.1 7 9.1C6.6134 9.1 6.3 9.4134 6.3 9.8C6.3 10.1866 6.6134 10.5 7 10.5Z"
                                        fill="#E03838"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_1_301">
                                        <rect
                                          width="14"
                                          height="14"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </div>
                                <p style={{ color: "red" }}>
                                  {formErrors.supplier_email}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>

                        {permissions &&
                        permissions.includes("update_suppliers") ? (
                          <BgThemeButton type={"submit"} children={"Update"} />
                        ) : (
                          <Tooltip
                            content="Please Contact Your Admin"
                            dismissOnMouseOut
                          >
                            <BgThemeButtonDisabled
                              type={"submit"}
                              children={"Update"}
                            />
                          </Tooltip>
                        )}
                        {permissions &&
                        permissions.includes("update_suppliers") ? (
                          <BgOutlineThemeButton
                            type={"button"}
                            onClick={() => setShowEditModelMenuActive(true)}
                            children={"Edit More"}
                          />
                        ) : (
                          <Tooltip
                            content="Please Contact Your Admin"
                            dismissOnMouseOut
                          >
                            <BgOutlineThemeButton
                              style={{ opacity: "0.6", cursor: "no-drop" }}
                              type={"button"}
                              // onClick={() => setShowEditModelMenuActive(true)}
                              children={"Edit More"}
                            />
                          </Tooltip>
                        )}
                      </form>
                    </div>
                  </div>
                )}

                {/* Multiple select */}
                {showLowerMenuMultipleSelect && (
                  <div className=" room-type-bottom-container">
                    <div className="room-type-bottom-container-div">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {permissions &&
                        permissions.includes("delete_suppliers") ? (
                          <Button
                            plain
                            destructive
                            // onClick={showModelMenu}
                            onClick={() => showModelMenu()}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span>
                                <Icon source={DeleteMajor} color="base" />
                              </span>
                              <span>Remove</span>
                            </div>
                          </Button>
                        ) : (
                          <Tooltip
                            content="Please Contact Your Admin"
                            dismissOnMouseOut
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                opacity: "0.4",
                                cursor: "no-drop",
                              }}
                            >
                              <span>
                                <Icon source={DeleteMajor} color="base" />
                              </span>
                              <span>Remove</span>
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>

      {/* Add Button Click */}
      {showAddModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <form
              onSubmit={(e) => handleSubmitAddExpenseHandler(e)}
              className="supplier-add-model-container"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <div className="add-ota-model-container-heading">
                  Add Supplier
                </div>
                <div
                  onClick={removeAddModalContainer}
                  style={{ cursor: "pointer" }}
                >
                  <Icon source={CancelMajor} color="base" />
                </div>
              </div>

              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    type={"text"}
                    titleName="Name"
                    name="supplier_name"
                    value={addItemData.supplier_name}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    type={"text"}
                    titleName="GST NO."
                    name="supplier_gst"
                    value={addItemData.supplier_gst}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
              </div>
              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    error={formErrors.supplier_phone ? true : false}
                    type={"number"}
                    titleName="Phone Number"
                    name="supplier_phone"
                    value={addItemData.supplier_phone}
                    onChange={setAddItemDataHandleChange}
                  />
                  {formErrors.supplier_phone && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        gap: "5px",
                        marginTop: "3px",
                      }}
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1_301)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM6.3 4.2C6.3 3.8134 6.6134 3.5 7 3.5C7.3866 3.5 7.7 3.8134 7.7 4.2V7C7.7 7.3866 7.3866 7.7 7 7.7C6.6134 7.7 6.3 7.3866 6.3 7V4.2ZM7 10.5C7.3866 10.5 7.7 10.1866 7.7 9.8C7.7 9.4134 7.3866 9.1 7 9.1C6.6134 9.1 6.3 9.4134 6.3 9.8C6.3 10.1866 6.6134 10.5 7 10.5Z"
                              fill="#E03838"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1_301">
                              <rect width="14" height="14" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <p style={{ color: "red" }}>
                        {formErrors.supplier_phone}
                      </p>
                    </div>
                  )}
                </div>
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    error={formErrors.supplier_email ? true : false}
                    required={true}
                    type={"email"}
                    titleName="Email"
                    name="supplier_email"
                    value={addItemData.supplier_email}
                    onChange={setAddItemDataHandleChange}
                  />
                  {formErrors.supplier_email && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        gap: "5px",
                        marginTop: "3px",
                      }}
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1_301)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM6.3 4.2C6.3 3.8134 6.6134 3.5 7 3.5C7.3866 3.5 7.7 3.8134 7.7 4.2V7C7.7 7.3866 7.3866 7.7 7 7.7C6.6134 7.7 6.3 7.3866 6.3 7V4.2ZM7 10.5C7.3866 10.5 7.7 10.1866 7.7 9.8C7.7 9.4134 7.3866 9.1 7 9.1C6.6134 9.1 6.3 9.4134 6.3 9.8C6.3 10.1866 6.6134 10.5 7 10.5Z"
                              fill="#E03838"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1_301">
                              <rect width="14" height="14" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <p style={{ color: "red" }}>
                        {formErrors.supplier_email}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    type={"text"}
                    titleName="City"
                    name="supplier_city"
                    value={addItemData.supplier_city}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    type={"text"}
                    titleName="State"
                    name="supplier_state"
                    value={addItemData.supplier_state}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
              </div>
              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    type={"text"}
                    titleName="Country"
                    name="supplier_country"
                    value={addItemData.supplier_country}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    error={formErrors.supplier_zipcode ? true : false}
                    required={true}
                    type={"number"}
                    titleName="Zipcode"
                    name="supplier_zipcode"
                    value={addItemData.supplier_zipcode}
                    onChange={setAddItemDataHandleChange}
                  />
                  {formErrors.supplier_zipcode && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        gap: "5px",
                        marginTop: "3px",
                      }}
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1_301)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM6.3 4.2C6.3 3.8134 6.6134 3.5 7 3.5C7.3866 3.5 7.7 3.8134 7.7 4.2V7C7.7 7.3866 7.3866 7.7 7 7.7C6.6134 7.7 6.3 7.3866 6.3 7V4.2ZM7 10.5C7.3866 10.5 7.7 10.1866 7.7 9.8C7.7 9.4134 7.3866 9.1 7 9.1C6.6134 9.1 6.3 9.4134 6.3 9.8C6.3 10.1866 6.6134 10.5 7 10.5Z"
                              fill="#E03838"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1_301">
                              <rect width="14" height="14" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <p style={{ color: "red" }}>
                        {formErrors.supplier_zipcode}
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <BgThemeButton type={"submit"} children={"Add"} loader={loader} />
                <BgOutlineThemeButton
                  onClick={removeAddModalContainer}
                  children={"Discard"}
                />
              </div>
            </form>
          </div>
        </>
      )}

      {/* Edit Button Click */}
      {showEditModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <form
              onSubmit={(e) => updateFunction(selectedResources[0], e)}
              className="supplier-add-model-container"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <div className="add-ota-model-container-heading">
                  Edit Supplier
                </div>
                <div
                  onClick={() => setShowEditModelMenuActive(false)}
                  style={{ cursor: "pointer" }}
                >
                  <Icon source={CancelMajor} color="base" />
                </div>
              </div>

              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    type={"text"}
                    titleName="Name"
                    name="supplier_name"
                    value={addItemData.supplier_name}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    type={"text"}
                    titleName="GST NO."
                    name="supplier_gst"
                    value={addItemData.supplier_gst}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
              </div>
              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    error={formErrors.supplier_phone ? true : false}
                    type={"number"}
                    titleName="Phone Number"
                    name="supplier_phone"
                    value={addItemData.supplier_phone}
                    onChange={setAddItemDataHandleChange}
                  />
                  {formErrors.supplier_phone && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        gap: "5px",
                        marginTop: "3px",
                      }}
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1_301)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM6.3 4.2C6.3 3.8134 6.6134 3.5 7 3.5C7.3866 3.5 7.7 3.8134 7.7 4.2V7C7.7 7.3866 7.3866 7.7 7 7.7C6.6134 7.7 6.3 7.3866 6.3 7V4.2ZM7 10.5C7.3866 10.5 7.7 10.1866 7.7 9.8C7.7 9.4134 7.3866 9.1 7 9.1C6.6134 9.1 6.3 9.4134 6.3 9.8C6.3 10.1866 6.6134 10.5 7 10.5Z"
                              fill="#E03838"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1_301">
                              <rect width="14" height="14" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <p style={{ color: "red" }}>
                        {formErrors.supplier_phone}
                      </p>
                    </div>
                  )}
                </div>
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    error={formErrors.supplier_email ? true : false}
                    required={true}
                    type={"email"}
                    titleName="Email"
                    name="supplier_email"
                    value={addItemData.supplier_email}
                    onChange={setAddItemDataHandleChange}
                  />
                  {formErrors.supplier_email && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        gap: "5px",
                        marginTop: "3px",
                      }}
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1_301)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM6.3 4.2C6.3 3.8134 6.6134 3.5 7 3.5C7.3866 3.5 7.7 3.8134 7.7 4.2V7C7.7 7.3866 7.3866 7.7 7 7.7C6.6134 7.7 6.3 7.3866 6.3 7V4.2ZM7 10.5C7.3866 10.5 7.7 10.1866 7.7 9.8C7.7 9.4134 7.3866 9.1 7 9.1C6.6134 9.1 6.3 9.4134 6.3 9.8C6.3 10.1866 6.6134 10.5 7 10.5Z"
                              fill="#E03838"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1_301">
                              <rect width="14" height="14" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <p style={{ color: "red" }}>
                        {formErrors.supplier_email}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    type={"text"}
                    titleName="City"
                    name="supplier_city"
                    value={addItemData.supplier_city}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    type={"text"}
                    titleName="State"
                    name="supplier_state"
                    value={addItemData.supplier_state}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
              </div>
              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    type={"text"}
                    titleName="Country"
                    name="supplier_country"
                    value={addItemData.supplier_country}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    error={formErrors.supplier_zipcode ? true : false}
                    required={true}
                    type={"number"}
                    titleName="Zipcode"
                    name="supplier_zipcode"
                    value={addItemData.supplier_zipcode}
                    onChange={setAddItemDataHandleChange}
                  />
                  {formErrors.supplier_zipcode && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        gap: "5px",
                        marginTop: "3px",
                      }}
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1_301)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM6.3 4.2C6.3 3.8134 6.6134 3.5 7 3.5C7.3866 3.5 7.7 3.8134 7.7 4.2V7C7.7 7.3866 7.3866 7.7 7 7.7C6.6134 7.7 6.3 7.3866 6.3 7V4.2ZM7 10.5C7.3866 10.5 7.7 10.1866 7.7 9.8C7.7 9.4134 7.3866 9.1 7 9.1C6.6134 9.1 6.3 9.4134 6.3 9.8C6.3 10.1866 6.6134 10.5 7 10.5Z"
                              fill="#E03838"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1_301">
                              <rect width="14" height="14" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <p style={{ color: "red" }}>
                        {formErrors.supplier_zipcode}
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <BgThemeButton type={"submit"} children={"Update"} />
                <BgOutlineThemeButton
                  onClick={() => setShowEditModelMenuActive(false)}
                  type={"button"}
                  children={"Discard"}
                />
              </div>
            </form>
          </div>
        </>
      )}

      {showModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="room-type-model-container ">
              <h4>Are You Sure?</h4>
              <p>You Want To Remove?</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={() => setShowModelMenuActive(!showModelMenuActive)}
                >
                  Discard
                </Button>
                <Button onClick={deleteData} destructive>
                  Yes, Remove
                </Button>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
};
export default Suppliers;
