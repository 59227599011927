import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateRangePicker = ({ selectedDatesRange, onDateRangeChange }) => {
  //   const [from_date, setFrom_date] = useState(null);
  //   const [to_date, setTo_date] = useState(null);
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const [from_date, setFrom_date] = useState(
    selectedDatesRange.from_date ? new Date(selectedDatesRange.from_date) : null
  );
  const [to_date, setTo_date] = useState(
    selectedDatesRange.to_date ? new Date(selectedDatesRange.to_date) : null
  );

  function formatDate(inputDate) {
    if (!inputDate) {
      return ""; // Return an empty string if the date is null
    }

    if (inputDate instanceof Date) {
      const year = inputDate.getFullYear();
      const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
      const day = inputDate.getDate().toString().padStart(2, "0");

      return `${year}-${month}-${day}`;
    }

    return inputDate; // Return inputDate as is if it's not a Date object
  }

  const onDateRangeChangeLocal = (dates) => {
    const [start, end] = dates;
    const formattedFromDate = formatDate(start);
    const formattedToDate = formatDate(end);

    onDateRangeChange(dates, formattedFromDate, formattedToDate); // Pass the formatted dates to the parent
    setFrom_date(start);
    setTo_date(end);
  };

  const togglePicker = () => {
    setIsPickerOpen(!isPickerOpen);
  };

  return (
    <div className="events_date_range_picker_comp">
      <DatePicker
        name="date_range"
        placeholderText="Select Date Range"
        selected={
          selectedDatesRange.from_date
            ? new Date(selectedDatesRange.from_date)
            : null
        }
        startDate={from_date}
        endDate={to_date}
        onChange={onDateRangeChangeLocal}
        minDate={new Date()}
        selectsRange
        showPopperArrow={false}
        customInput={
          <CustomDatePickerInput
            formatDate={formatDate}
            onFocus={togglePicker}
          />
        }
        onInputClick={togglePicker}
        popperIsOpen={isPickerOpen}
      />
    </div>
  );
};

const CustomDatePickerInput = ({ value, onClick, onFocus, formatDate }) => (
  <>
    <input
      type="text"
      value={value ? formatDate(value) : ""}
      placeholder="Select Date Range"
      onClick={onClick}
      style={{ zIndex: 1 }}
      readOnly
    />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      onClick={onFocus}
      style={{ position: "absolute", right: "0", zIndex: 0, top: "7px" }}
    >
      <g clipPath="url(#clip0_1270_814)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.625 2H11.75V1.25C11.75 0.835786 11.4142 0.5 11 0.5C10.5858 0.5 10.25 0.835786 10.25 1.25V2H5V1.25C5 0.835786 4.66421 0.5 4.25 0.5C3.83579 0.5 3.5 0.835786 3.5 1.25V2H2.375C1.775 2 1.25 2.525 1.25 3.125V14.375C1.25 14.975 1.775 15.5 2.375 15.5H13.625C14.225 15.5 14.75 14.975 14.75 14.375V3.125C14.75 2.525 14.225 2 13.625 2ZM2.75 14H13.25V6.5H2.75V14Z"
          fill="#888888"
        />
      </g>
      <defs>
        <clipPath id="clip0_1270_814">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default DateRangePicker;
