import React from "react";

const TextDateFormatter = ({ date, as, style, showYear }) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const parsedDate = new Date(date);

  const day = String(parsedDate.getDate()).padStart(2, "0");
  const month = months[parsedDate.getMonth()];
  const year = parsedDate.getFullYear();

  // const Element = React.createElement(
  //   as,
  //   { style: style },
  //   `${day} ${month}, ${year}`
  // );

  let formattedDate = `${day} ${month}`;

  if (showYear) {
    formattedDate += `, ${year}`;
  }

  const Element = React.createElement(as, { style: style }, formattedDate);

  return Element;
};

export default TextDateFormatter;

// import React from "react";

// const TextDateFormatter = ({ date, as, style, showYear }) => {
//   const months = [
//     "Jan",
//     "Feb",
//     "Mar",
//     "Apr",
//     "May",
//     "Jun",
//     "Jul",
//     "Aug",
//     "Sep",
//     "Oct",
//     "Nov",
//     "Dec",
//   ];

//   const parsedDate = new Date(date);

//   const day = String(parsedDate.getDate()).padStart(2, "0");
//   const month = months[parsedDate.getMonth()];
//   const year = parsedDate.getFullYear();

//   let formattedDate = `${day} ${month}`;

//   if (showYear) {
//     formattedDate += `, ${year}`;
//   }

//   const Element = React.createElement(as, { style: style }, formattedDate);

//   return Element;
// };

// export default TextDateFormatter;
