import {
  TextField,
  IndexTable,
  LegacyCard,
  useIndexResourceState,
  Button,
  Icon,
  Select,
  Checkbox,
  Tooltip,
} from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
} from "./UI/Buttons";
import { useSelector } from "react-redux";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import Loader from "./UI/Loader/Loader";
import NoDataFound from "./CustomComponents/NoDataFound";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import TextDateFormatter from "./Communication/TextDateFormatter/TextDateFormatter";
import { getDecryptedData } from "../utils/encryptStorage";

const BookingVoid = () => {
  const [orders, setOrders] = useState([]);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const property_id = useSelector((state) => state.property.value);
  const userToken = getDecryptedData('encryptToken');
  const permission = JSON.parse(localStorage.getItem("permissions"));

  const [permissions, setPermissions] = useState(permission);

  useEffect(() => {
    setPermissions(permissions);
  }, [permissions]);

  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);

  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(orders);

  // const getOneData = async (id) => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_BASE_URL}/api/v1/discount/edit/${id}/${property_id?.id}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${userToken}`,
  //         },
  //       }
  //     );
  //     const data = await response.json();
  //     console.log(data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    if (selectedResources.length === 1) {
      // getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  // const addModalButtonClickHandler = () => {
  //   setShowAddModelMenuActive(!showAddModelMenuActive);
  // };

  // const editModalButtonClickHandler = () => {
  //   setShowEditModelMenuActive(!showEditModelMenuActive);
  // };

  // const removeAddModalContainer = () => {
  //   setShowAddModelMenuActive(!showAddModelMenuActive);
  // };

  const getItemChecked = (id) => {};

  getItemChecked(selectedResources[0]);

  const rowMarkup = orders?.map(
    ({
      id,
      property_id,
      // status,
      discount_name,
      discount_type,
      start_date,
      end_date,
      discount_code,
      discount_amount,
      discount_method,
      index,
    }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={discount_name}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={discount_method.replace(/_/g, " ")}
          >
            {/* <span>(coupon)</span> */}
          </CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={discount_type}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>

        <IndexTable.Cell>
          {discount_type === "fixed" ? (
            <CustomCssComponentTableText
              innerText={"₹" + discount_amount}
            ></CustomCssComponentTableText>
          ) : (
            <CustomCssComponentTableText
              innerText={discount_amount + "%"}
            ></CustomCssComponentTableText>
          )}
        </IndexTable.Cell>

        <IndexTable.Cell>
          <CustomCssComponentTableText>
            <TextDateFormatter as={"span"} date={start_date} showYear={true} />{" "}
            - <TextDateFormatter as={"span"} date={end_date} showYear={true} />
          </CustomCssComponentTableText>
        </IndexTable.Cell>
        {/* <IndexTable.Cell>{status}</IndexTable.Cell> */}
      </IndexTable.Row>
    )
  );

  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    clearSelection();
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/discount/delete/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          clearSelection();
          fetchInfo(property_id?.id);
          clearSelection();
          setShowLowerMenuMultipleSelect(false);
          setShowModelMenuActive(false);
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const removeItemConfirm = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  // Plan Type Options
  const planTypeOptions = [
    { label: "Ep", value: "Ep" },
    { label: "CP", value: "CP" },
    { label: "MAP", value: "MAP" },
    { label: "AP", value: "AP" },
  ];

  const [planTypeSelected, setPlanTypeSelected] = useState("EP");
  const planTypeHandleSelectChange = useCallback(
    (value) => setPlanTypeSelected(value),
    []
  );

  const [asignHotelChecked, setAsignHotelChecked] = useState(false);

  const asignHotelCheckboxHandleChange = useCallback(
    (newChecked) => setAsignHotelChecked(newChecked),
    []
  );

  const fetchInfo = async (id) => {
    if (!id) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/void/bookings/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

   useEffect(() => {
    if (property_id  && userToken)  {
      fetchInfo(property_id?.id);
    }
  }, [property_id, userToken]);

  const role = JSON.parse(localStorage.getItem("role"));

  return (
    <>
      <div className="room-types-full-container">
        <div className="room-types-full-container-div">
          <div className="room-type-heading">Void Bookings</div>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "15px",
            }}
          >
            {permissions && permissions.includes("create_discounts") ? (
              <Link
                style={{ textDecoration: "none" }}
                to={`/discounts/adddiscounts`}
              >
                <BgThemeButton children={"Add Discount"} />
              </Link>
            ) : (
              <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
                <BgThemeButtonDisabled children={"Add Discount"} />
              </Tooltip>
            )}
          </div> */}
        </div>

        {loader ? (
          <Loader />
        ) : (
          <>
            {orders && orders.length > 0 ? (
              <>
                <LegacyCard>
                  <IndexTable
                    resourceName={resourceName}
                    itemCount={orders.length}
                    selectedItemsCount={
                      allResourcesSelected ? "All" : selectedResources.length
                    }
                    onSelectionChange={handleSelectionChange}
                    headings={[
                      { title: "Discount Name" },
                      { title: "Discount Method" },
                      { title: "Discount Type" },
                      { title: "Applied Discount" },
                      { title: "Date Range" },
                      // { title: "Status" },
                    ]}
                  >
                    {rowMarkup}
                  </IndexTable>

                  {/* Single select */}
                  {showLowerMenuSingleSelect && (
                    <div className=" room-type-bottom-container">
                      <div className="room-type-bottom-container-div">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            gap: "20px",
                          }}
                        >
                          {role === "owner" && (
                            <Link
                              style={{ textDecoration: "none" }}
                              to={`/discounts/edit/${selectedResources[0]}`}
                            >
                              <BgOutlineThemeButton children={"Edit"} />
                            </Link>
                          )}
                          <Button plain destructive onClick={showModelMenu}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span>
                                <Icon source={DeleteMajor} color="base" />
                              </span>
                              <span onClick={showModelMenu}>Remove</span>
                            </div>
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Multiple select */}
                  {showLowerMenuMultipleSelect && (
                    <div className=" room-type-bottom-container">
                      <div className="room-type-bottom-container-div multipleroom-delete-btn-div">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Button plain destructive onClick={showModelMenu}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span>
                                <Icon source={DeleteMajor} color="base" />
                              </span>
                              <span onClick={deleteData}>Remove</span>
                            </div>
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </LegacyCard>
              </>
            ) : (
              <NoDataFound />
            )}
          </>
        )}
      </div>

      {/* Add Button Click */}
      {showAddModelMenuActive && (
        <>
          <div className="bg-backdrop"></div>
          <section className="agent-add-model-container">
            <h4>Add Agent</h4>

            <div className="form-container-div-half">
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Name"
                  autoComplete="off"
                />
              </div>
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Company Name"
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="form-container-div-half">
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="GST No."
                  autoComplete="off"
                />
              </div>
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Business Type"
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="form-container-div-half">
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Phone"
                  autoComplete="off"
                />
              </div>
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Email"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="form-container-div-half">
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="City"
                  autoComplete="off"
                />
              </div>
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Country"
                  autoComplete="off"
                />
              </div>
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="State"
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="form-container-div-half">
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Pincode"
                  autoComplete="off"
                />
              </div>
              <div className="form-container-div">
                <Select
                  className="textfield"
                  focused={false}
                  label="Select Category"
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="form-container-div-half">
              <div className="form-container-div">
                <Select
                  className="textfield"
                  focused={false}
                  label="Settle From"
                  autoComplete="off"
                />
              </div>
              <div className="form-container-div">
                <Select
                  className="textfield"
                  focused={false}
                  label="Credit Limit"
                  autoComplete="off"
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <div>Temporary Booking</div>
              <div>Enable</div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "20px",
                flexDirection: "column",
              }}
            >
              <div style={{ marginBottom: "10px" }}>Assign Hotel</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginBottom: "20px",
                  gap: "10px",
                }}
              >
                <Checkbox
                  label="Golden Fern Resort"
                  checked={asignHotelChecked}
                  onChange={asignHotelCheckboxHandleChange}
                />
                <Checkbox
                  label="Golden Fern Resort"
                  checked={asignHotelChecked}
                  onChange={asignHotelCheckboxHandleChange}
                />
                <Checkbox
                  label="Golden Fern Resort"
                  checked={asignHotelChecked}
                  onChange={asignHotelCheckboxHandleChange}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "start",
                gap: "10px",
              }}
            >
              <Button primary>Add</Button>
              <Button onClick={() => setShowAddModelMenuActive(false)}>
                Discard
              </Button>
            </div>
          </section>
        </>
      )}

      {/* Edit Button Click */}
      {showEditModelMenuActive && (
        <>
          <div className="bg-backdrop"></div>
          <section className="room-rate-plan-edit-model-container">
            <h4>Edit Rate Plan</h4>
            <div className="edit-rooms-form-container">
              <div className="form-container-div-full">
                <Select
                  options={planTypeOptions}
                  value={planTypeSelected}
                  onChange={planTypeHandleSelectChange}
                  className="textfield"
                  focused={false}
                  label="Plan Type"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="form-container-div-half">
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Rack Price"
                  autoComplete="off"
                  placeholder="6500.00"
                />
              </div>
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Bar Price"
                  autoComplete="off"
                  placeholder="7000.00"
                />
              </div>
            </div>
            <div className="form-container-div-half">
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Extra Adult"
                  autoComplete="off"
                  placeholder="850.00"
                />
              </div>
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Child With Bed"
                  autoComplete="off"
                  placeholder="500.00"
                />
              </div>
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Child Without Bed"
                  autoComplete="off"
                  placeholder="500.00"
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Button primary>Update</Button>
              <Button onClick={() => setShowEditModelMenuActive(false)}>
                Discard
              </Button>
            </div>
          </section>
        </>
      )}

      {showModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="room-type-model-container ">
              <h4>Are You Sure?</h4>
              <p>You Want To Remove?</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={() => setShowModelMenuActive(!showModelMenuActive)}
                >
                  Discard
                </Button>
                <Button onClick={deleteData} destructive>
                  Yes, Remove
                </Button>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "moneySpent":
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case "taggedWith":
        return `Tagged with ${value}`;
      case "accountStatus":
        return value.map((val) => `Customer ${val}`).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};
export default BookingVoid;
