import React, { useEffect, useState } from "react";
import { BgThemeButton } from "../UI/Buttons";
import { showErrorToast } from "../../assets/toastUtils";
import Loader from "../UI/Loader/Loader";
import { useSelector } from "react-redux";
import { getDecryptedData } from "../../utils/encryptStorage";

const PrintFBill = ({ folioId }) => {
  const [isLoading, setIsLoading] = useState(true);

 const userToken = getDecryptedData('encryptToken');;
  const property_id = useSelector((state) => state.property.value);

  const handleMessage = (event) => {
    if (event.data.action === "receipt-loaded") {
      setIsLoading(false);
    }
  };

  const printIframe = (id) => {
    // setIsLoading(false);
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const [iframeContent, setIframeContent] = useState("");

  useEffect(() => {
    if (folioId) {
      getBillIframeUseEffect();
    }
  }, [folioId]);

  const getBillIframe = () => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/layout/preview/${property_id?.id}/bill?previewType=foodBill&previewId=${folioId}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.text())
      .then((data) => {
        if (data) {
          setIframeContent(data);
          // setShowBillPreview(true);
          setIsLoading(false);
          printIframe("receipt2");
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };
  const getBillIframeUseEffect = () => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/layout/preview/${property_id?.id}/bill?previewType=foodBill&previewId=${folioId}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.text())
      .then((data) => {
        if (data) {
          setIframeContent(data);
          // setShowBillPreview(true);
          setIsLoading(false);
          // printIframe("receipt");
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  {
    isLoading && <Loader />;
  }
  return (
    <>
      <iframe
        srcDoc={iframeContent}
        id="receipt2"
        style={{ display: "none" }}
        title="Receipt"
      />

      {/* <div className="pointer" onClick={getBillIframe}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="12"
          viewBox="0 0 14 12"
          fill="none"
        >
          <path
            d="M11.0007 2.66667H3.00065V0H11.0007V2.66667ZM11.0007 6.33333C11.1895 6.33333 11.348 6.26933 11.476 6.14133C11.604 6.01333 11.6678 5.85511 11.6673 5.66667C11.6673 5.47778 11.6033 5.31933 11.4753 5.19133C11.3473 5.06333 11.1891 4.99956 11.0007 5C10.8118 5 10.6533 5.064 10.5253 5.192C10.3973 5.32 10.3335 5.47822 10.334 5.66667C10.334 5.85556 10.398 6.014 10.526 6.142C10.654 6.27 10.8122 6.33378 11.0007 6.33333ZM9.66732 10.6667V8H4.33398V10.6667H9.66732ZM11.0007 12H3.00065V9.33333H0.333984V5.33333C0.333984 4.76667 0.528429 4.29156 0.917318 3.908C1.30621 3.52444 1.77843 3.33289 2.33398 3.33333H11.6673C12.234 3.33333 12.7091 3.52511 13.0927 3.90867C13.4762 4.29222 13.6678 4.76711 13.6673 5.33333V9.33333H11.0007V12Z"
            fill="#3968ED"
          />
        </svg>

      </div> */}

      <BgThemeButton children={"Print Bill"} onClick={getBillIframe} />
    </>
  );
};

export default PrintFBill;
