import React from "react";
import Sidebar from "../Components/Sidebar";
import Discounts from "../Components/Discounts";

const DiscountsPage = () => {
  return (
    <>
     <div className="main_container">
          <Discounts />
      </div>
    </>
  );
};

export default DiscountsPage;
