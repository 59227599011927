import React, { useCallback, useState } from "react";

import {
  Button,
  Collapsible,
  DropZone,
  Icon,
  LegacyCard,
  LegacyStack,
  Select,
  TextField,
  Text,
  Thumbnail,
  ButtonGroup,
  Page,
  Badge,
  Tag,
  ChoiceList,
} from "@shopify/polaris";
import { ChevronDownMinor, NoteMinor } from "@shopify/polaris-icons";
import { Link, useNavigate } from "react-router-dom";
import { BgOutlineThemeButton, BgThemeButton } from "./UI/Buttons";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import { getDecryptedData } from "../utils/encryptStorage";

const AddDiscount = () => {
  const navigate = useNavigate();
  const currentDate = new Date().toISOString().split("T")[0];
  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);

 
  const [selectedDiscountMethod, setSelectedDiscountMethod] =
    useState("automatic");
  const [selectedDiscountType, setSelectedDiscountType] = useState("fixed");
  const [selectedMinPurchase, setSelectedMinPurchase] = useState("disable");

  const handleDiscountMethodChange = (value) => {
    setSelectedDiscountMethod(value);
    setAddDiscountItem({
      ...addDiscountItem,
      discount_method:
        selectedDiscountMethod === "automatic" ? "discount_code" : "automatic",
    });
  };

  const handleDiscountAmountChange = (value) => {
    setSelectedDiscountType(value);
    setAddDiscountItem({
      ...addDiscountItem,
      discount_type: selectedDiscountType === "fixed" ? "percent" : "fixed",
    });
  };

  const handleMinPurchaseChange = (value) => {
    setSelectedMinPurchase(value);
    setAddDiscountItem({
      ...addDiscountItem,
      minimum_purchase_required:
        selectedMinPurchase === "disable" ? "enable" : "disable",
    });
  };

  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(true);
  const [open4, setOpen4] = useState(true);
  const [open5, setOpen5] = useState(true);
  const [open6, setOpen6] = useState(true);
  const [open7, setOpen7] = useState(true);
  const [open8, setOpen8] = useState(true);
  const [open9, setOpen9] = useState(true);

  const handleToggle1 = useCallback(() => setOpen1((open1) => !open1), []);
  const handleToggle2 = useCallback(() => setOpen2((open2) => !open2), []);
  const handleToggle3 = useCallback(() => setOpen3((open3) => !open3), []);
  const handleToggle4 = useCallback(() => setOpen4((open4) => !open4), []);
  const handleToggle5 = useCallback(() => setOpen5((open5) => !open5), []);
  const handleToggle6 = useCallback(() => setOpen6((open6) => !open6), []);
  const handleToggle7 = useCallback(() => setOpen7((open7) => !open7), []);
  const handleToggle8 = useCallback(() => setOpen8((open8) => !open8), []);
  const handleToggle9 = useCallback(() => setOpen9((open9) => !open9), []);

  const [addDiscountItem, setAddDiscountItem] = useState({
    discount_method: selectedDiscountMethod,
    discount_name: "",
    discount_code: "",
    discount_type: selectedDiscountType,
    discount_amount: "",
    minimum_purchase_required: selectedMinPurchase,
    minimum_purchase_amount: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
  });

  const handleAddDiscountHandleChange = (event) => {
    setAddDiscountItem({
      ...addDiscountItem,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitAddDiscount = (e) => {
    setLoader(true);
    e.preventDefault();
   
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/discount/store/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...addDiscountItem }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          
          showSuccessToast("Discount Created Successfully!");
          setAddDiscountItem({
            discount_method: selectedDiscountMethod,
            discount_name: "",
            discount_code: "",
            discount_type: selectedDiscountType,
            discount_amount: "",
            minimum_purchase_required: selectedMinPurchase,
            minimum_purchase_amount: "",
            start_date: "",
            end_date: "",
            start_time: "",
            end_time: "",
          });
          navigate("/discounts");
          setLoader(false);
        } else if (data.error) {
         
          const firstErrorKey = Object.keys(data.error)[0];
          const firstErrorMessage = data.error[firstErrorKey][0];
          showErrorToast(firstErrorMessage);
          setLoader(false);

        } else {
          showErrorToast(data.message);
        
          setLoader(false);
        }
      });
  };

  return (
    <>
      <div className="edit-rooms-full-container">
        <div>
          <Page
            backAction={{ content: "Products", url: `/discounts` }}
            title="Create Discount"
            compactTitle
          >
            <form
              className="edit-rooms-full-container-div"
              onSubmit={(e) => handleSubmitAddDiscount(e)}
            >
              <div className="edit-rooms-full-container-left">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    width: "591px",
                  }}
                >
                  <div>
                    <LegacyCard sectioned>
                      <LegacyStack vertical>
                        <div
                          onClick={handleToggle1}
                          aria-expanded={open1}
                          aria-controls="basic-collapsible"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          <div>Method</div>
                          <div>
                            <Icon source={ChevronDownMinor} />
                          </div>
                        </div>

                        <Collapsible
                          open={open1}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                          <div className="edit-rooms-form-wrapper-container">
                            <div className="edit-rooms-form-container">
                              <div className="form-container-div-full">
                                <ChoiceList
                                  title="Method"
                                  choices={[
                                    {
                                      label: "Automatic Discount",
                                      value: "automatic",
                                    },
                                    {
                                      label: "Discount Code",
                                      value: "discount_code",
                                    },
                                  ]}
                                  selected={[selectedDiscountMethod]}
                                  onChange={(value) =>
                                    handleDiscountMethodChange(value[0])
                                  }
                                />
                              </div>

                              {selectedDiscountMethod === "discount_code" && (
                                <VerticalInputFieldCustom
                                  required={true}
                                  titleName="Discount Code"
                                  name="discount_code"
                                  type="text"
                                  value={addDiscountItem.discount_code}
                                  onChange={handleAddDiscountHandleChange}
                                />
                              )}
                            </div>
                          </div>
                        </Collapsible>
                      </LegacyStack>
                    </LegacyCard>
                  </div>

                  <div>
                    <LegacyCard sectioned>
                      <LegacyStack vertical>
                        <div
                          onClick={handleToggle2}
                          aria-expanded={open2}
                          aria-controls="basic-collapsible"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          <div>Value</div>
                          <div>
                            <Icon source={ChevronDownMinor} />
                          </div>
                        </div>

                        <Collapsible
                          open={open2}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                          <div className="edit-rooms-form-wrapper-container">
                            <div className="edit-rooms-form-container">
                              <VerticalInputFieldCustom
                                required={true}
                                titleName="Discount Name"
                                name="discount_name"
                                type="text"
                                value={addDiscountItem.discount_name}
                                onChange={handleAddDiscountHandleChange}
                              />
                              <div
                                className="form-container-div-full"
                                style={{ marginTop: "16px" }}
                              >
                                <ChoiceList
                                  title="Percentage"
                                  choices={[
                                    {
                                      label: "Percentage",
                                      value: "percent",
                                    },
                                    {
                                      label: "Fixed Amount",
                                      value: "fixed",
                                    },
                                  ]}
                                  selected={[selectedDiscountType]}
                                  onChange={(value) =>
                                    handleDiscountAmountChange(value[0])
                                  }
                                />
                              </div>

                              {/* {selectedDiscountMethod === "discount_code" && ( */}
                              <VerticalInputFieldCustom
                                required={true}
                                titleName="Discount Amount"
                                name="discount_amount"
                                type="text"
                                value={addDiscountItem.discount_amount}
                                onChange={handleAddDiscountHandleChange}
                              />
                              {/* )} */}
                            </div>
                          </div>
                        </Collapsible>
                      </LegacyStack>
                    </LegacyCard>
                  </div>

                  <div>
                    <LegacyCard sectioned>
                      <LegacyStack vertical>
                        <div
                          onClick={handleToggle3}
                          aria-expanded={open3}
                          aria-controls="basic-collapsible"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          <div>Minimum purchase requirements</div>
                          <div>
                            <Icon source={ChevronDownMinor} />
                          </div>
                        </div>

                        <Collapsible
                          open={open3}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                          <div className="edit-rooms-form-wrapper-container">
                            <div className="edit-rooms-form-container">
                              <div className="form-container-div-full">
                                <ChoiceList
                                  title="Percentage"
                                  choices={[
                                    {
                                      label: "No minimum requirements",
                                      value: "disable",
                                    },
                                    {
                                      label: "Minimum purchase amount (₹)",
                                      value: "enable",
                                    },
                                  ]}
                                  selected={[selectedMinPurchase]}
                                  onChange={(value) =>
                                    handleMinPurchaseChange(value[0])
                                  }
                                />
                              </div>

                              {selectedMinPurchase === "enable" && (
                                <VerticalInputFieldCustom
                                  required={true}
                                  titleName="Minimum Purchase Amount"
                                  name="minimum_purchase_amount"
                                  type="number"
                                  value={
                                    addDiscountItem.minimum_purchase_amount
                                  }
                                  onChange={handleAddDiscountHandleChange}
                                />
                              )}
                            </div>
                          </div>
                        </Collapsible>
                      </LegacyStack>
                    </LegacyCard>
                  </div>

                  <div></div>

                  <div>
                    <LegacyCard sectioned>
                      <LegacyStack vertical>
                        <div
                          onClick={handleToggle4}
                          aria-expanded={open4}
                          aria-controls="basic-collapsible"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            cursor: "pointer",
                            fontSize: "14px",
                          }}
                        >
                          <div>Active dates</div>

                          <div>
                            <Icon source={ChevronDownMinor} />
                          </div>
                        </div>
                        <Collapsible
                          open={open4}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                          <div className="form-container-div-half">
                            <div className="form-container-div">
                              <VerticalInputFieldCustom
                                required={true}
                                type="date"
                                name="start_date"
                                className="textfield"
                                titleName="Start Date"
                                focused={false}
                                onChange={handleAddDiscountHandleChange}
                                autoComplete="off"
                                min={currentDate}
                                value={addDiscountItem.start_date}
                              />
                            </div>
                            <div className="form-container-div">
                              <VerticalInputFieldCustom
                                required={true}
                                type="time"
                                name="start_time"
                                className="textfield"
                                focused={false}
                                titleName="Start Time"
                                onChange={handleAddDiscountHandleChange}
                                autoComplete="off"
                                value={addDiscountItem.start_time}
                              />
                            </div>
                          </div>
                          <div className="form-container-div-half">
                            <div className="form-container-div">
                              <VerticalInputFieldCustom
                                required={true}
                                type="date"
                                name="end_date"
                                className="textfield"
                                titleName="End Date"
                                focused={false}
                                onChange={handleAddDiscountHandleChange}
                                autoComplete="off"
                                min={addDiscountItem.start_date || currentDate}
                                value={addDiscountItem.end_date}
                              />
                            </div>
                            <div className="form-container-div">
                              <VerticalInputFieldCustom
                                required={true}
                                type="time"
                                name="end_time"
                                className="textfield"
                                focused={false}
                                titleName="End Time"
                                onChange={handleAddDiscountHandleChange}
                                autoComplete="off"
                                value={addDiscountItem.end_time}
                              />
                            </div>
                          </div>
                        </Collapsible>
                      </LegacyStack>
                    </LegacyCard>
                  </div>
                </div>
                <div className="rooms-edit-left-container-buttons">
                  <div>
                    <BgThemeButton children={"Add"} type={"submit"} loader={loader} />
                  </div>
                  <div style={{ color: "rgba(0, 122, 92, 1)" }}>
                    <Link to="/discounts">
                      <BgOutlineThemeButton
                        children={"Discard"}
                        type={"button"}
                      />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="edit-rooms-full-container-right">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <div>
                    <LegacyCard sectioned>
                      <LegacyStack vertical>
                        <div
                          onClick={handleToggle7}
                          aria-expanded={open7}
                          aria-controls="basic-collapsible"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          <div>Summary</div>
                          <div>
                            <Icon source={ChevronDownMinor} />
                          </div>
                        </div>
                        <Collapsible
                          open={open7}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                          <div>
                            <p
                              style={{
                                marginBottom: "25px",
                                fontSize: "14px",
                                color: "#888",
                                fontWeight: "500",
                              }}
                            >
                              No discount code yet
                            </p>

                            <ul
                              style={{
                                marginBottom: "25px",
                                fontSize: "14px",
                                color: "#333",
                                fontWeight: "500",
                              }}
                            >
                              <span
                                style={{
                                  marginBottom: "25px",
                                }}
                              >
                                Type and method
                              </span>
                              <div
                                style={{
                                  marginBottom: "25px",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  marginLeft: "30px",
                                }}
                              >
                                <li>Amount off Discount</li>
                                <li>Code</li>
                              </div>
                            </ul>
                            <ul
                              style={{
                                marginBottom: "25px",
                                fontSize: "14px",
                                color: "#333",
                                fontWeight: "500",
                              }}
                            >
                              Details
                              <div
                                style={{
                                  marginBottom: "25px",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  marginLeft: "30px",
                                }}
                              >
                                <li>Can’t combine with other discounts</li>
                              </div>
                            </ul>

                            <div
                              style={{
                                fontSize: "14px",
                                color: "#333",
                                fontWeight: "500",
                              }}
                            >
                              Performance
                              <p
                                style={{
                                  marginBottom: "25px",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                Discount is not active yet
                              </p>
                            </div>
                          </div>
                        </Collapsible>
                      </LegacyStack>
                    </LegacyCard>
                  </div>
                </div>
              </div>
            </form>
          </Page>
        </div>
      </div>
    </>
  );
};

export default AddDiscount;
