import React from "react";
import Sidebar from "../Components/Sidebar";
import AddDiscount from "../Components/AddDiscount";
import UpgradePage from "./UpgradePage";
import UpgradePlane from "../Components/UpgradePlane";

const AnalyticsPage = () => {
  return (
    <>
      <div className="main_container">
        <UpgradePlane />
      </div>
    </>
  );
};

export default AnalyticsPage;
