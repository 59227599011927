import React from "react";
import "./customIndexTable.css";

const CustomIndexTable = ({
  children,
  headings,
  allItems,
  selectedItems,
  setSelectedItems,
  showCheckbox,
}) => {
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedItems(allItems?.map((item) => item.id));
    } else {
      setSelectedItems([]);
    }
  };

  return (
    <table className="custom_index_table custom_index_table_body">
      <thead className="custom_index_table_head" style={{ zIndex: "9999" }}>
        <tr className="custom_index_table_head_row">
          {showCheckbox && (
            <th>
              <input
                type="checkbox"
                checked={
                  allItems?.length > 0 &&
                  allItems?.length === selectedItems?.length
                }
                onChange={handleCheckboxChange}
                className={
                  selectedItems?.length === 0
                    ? "custom-checkbox"
                    : selectedItems?.length > 0 &&
                      selectedItems?.length < allItems?.length
                    ? "custom-checkbox-two"
                    : "custom-checkbox"
                }
              />
            </th>
          )}
          {headings?.map((item, index) => (
            <th key={index}>{item}</th>
          ))}
        </tr>
      </thead>
      <tbody className="custom_index_table_body">{children}</tbody>
    </table>
  );
};

export default CustomIndexTable;
