import React from "react";
import Events from "../Components/Events";

const EventsPage = () => {
  return (
    <>
      <div className="main_container">
        <Events />
      </div>
    </>
  );
};

export default EventsPage;
