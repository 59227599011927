import React from "react";
import EditProfile from "../Components/EditProfile";

const Profile = () => {
  return (
    <>
      <div className="main_container">
        <EditProfile />
      </div>
    </>
  );
};

export default Profile;
