import React, { useRef, useState } from "react";
import "./TruncatedText.css";

const TruncatedText = ({ width, longText, fontSize, toolTipWidth }) => {
  const containerRef = useRef(null);
  // const [tooltipTop, setTooltipTop] = useState(0);

  // useEffect(() => {
  //   if (containerRef.current) {
  //     const rect = containerRef.current.getBoundingClientRect();
  //     if (rect.top < 100) {
  //       setTooltipTop(20);
  //     } else {
  //       setTooltipTop(-40);
  //     }
  //   }
  // }, [longText, width, fontSize, toolTipWidth]);

  // useEffect(() => {
  //   if (containerRef.current) {
  //     const handleScroll = () => {
  //       const newRect = containerRef.current.getBoundingClientRect();
  //       if (newRect.top < 100) {
  //         setTooltipTop(20);
  //       } else {
  //         setTooltipTop(-40);
  //       }
  //     };

  //     window.addEventListener("scroll", handleScroll);
  //   }
  // }, [longText, width, fontSize, toolTipWidth]);
  return (
    <div>
      <div
        className="truncatedTextContainer"
        style={{
          fontSize: fontSize,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        ref={containerRef}
      >
        <div className="truncatedTextContainer_text" style={{ width: width }}>
          {longText}
        </div>
        <div
          className="truncatedTextContainer_text_tooltip"
          style={{ width: toolTipWidth, bottom: "20px" }}
        >
          {longText}
        </div>
      </div>
    </div>
  );
};

export default TruncatedText;
