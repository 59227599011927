import React, { useState, useEffect } from 'react';
import styles from './daySelectionWeekDays.module.scss';

const DaySelectionWeekDays = ({ onSaveDates, defaultSelectedDays }) => {

  const [selectedDays, setSelectedDays] = useState(defaultSelectedDays || []);
  const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'];
  // Handle toggling the checkbox for a specific day
  const handleDayChange = (day) => {
    setSelectedDays((prevSelectedDays) => {
      const isSelected = prevSelectedDays.includes(day);
      if (isSelected) {
        return prevSelectedDays.filter((d) => d !== day); // Remove day if selected
      } else {
        return [...prevSelectedDays, day]; // Add day if not selected
      }
    });
  };

  // Select weekdays (Monday to Friday)
  const selectWeekdays = () => {
    setSelectedDays(daysOfWeek.slice(1, 6)); // Monday to Friday
  };

  // Select weekends (Saturday and Sunday)
  const selectWeekends = () => {
    setSelectedDays(['sun', 'sat']);
  };

  // Select all days (custom)
  const selectCustom = () => {
    setSelectedDays(daysOfWeek);
  };

  // Check if all weekdays are selected
  const isWeekdaySelected = () => {
    const weekdays = daysOfWeek.slice(1, 6);
    return weekdays.every((day) => selectedDays.includes(day)) && selectedDays.length === 5;
  };

  // Check if only weekends are selected
  const isWeekendSelected = () =>
    selectedDays.includes('sun') && selectedDays.includes('sat') && selectedDays.length === 2;

  // Check if a custom selection (mix of any) is selected
  const isCustomSelected = () => {
    const weekdays = daysOfWeek.slice(1, 6);
    const weekends = ['sun', 'sat'];
    const isMixedSelection =
      selectedDays.some((day) => weekdays.includes(day)) &&
      selectedDays.some((day) => weekends.includes(day));

    return (
      selectedDays.length > 0 &&
      (selectedDays.length === 7 || // All days are selected
        isMixedSelection || // Mix of weekdays and weekends
        selectedDays.length === 1 || // Only one day is selected
        (!isWeekdaySelected() && !isWeekendSelected())) // Neither all weekdays nor all weekends are selected
    );
  };

  // Function to create a day selection object to send to parent component
  const getDaySelectionObject = () => {
    const daySelection = {};
    daysOfWeek.forEach((day) => {
      daySelection[day] = selectedDays.includes(day) ? 1 : 0;
    });
    return daySelection;
  };

  // Save dates every time selectedDays changes
  useEffect(() => {
    const daySelection = getDaySelectionObject();
    onSaveDates(daySelection);
  }, [selectedDays, onSaveDates]);

  return (
    <>
      <div>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <button
            onClick={selectWeekdays}
            className={`${styles.selectDayButtons} ${isWeekdaySelected() ? styles.selected : ''}`}
          >
            Weekdays
          </button>
          <button
            onClick={selectWeekends}
            className={`${styles.selectDayButtons} ${isWeekendSelected() ? styles.selected : ''}`}
          >
            Weekends
          </button>
          <button
            onClick={selectCustom}
            className={`${styles.selectDayButtons} ${isCustomSelected() ? styles.selected : ''}`}
          >
            Custom
          </button>
        </div>

        <div className="flex flex_gap_10">
          {daysOfWeek.map((day, index) => (
            <label key={index} className={styles.dayName}>
              <input
                type="checkbox"
                checked={selectedDays.includes(day)}
                onChange={() => handleDayChange(day)}
              />
              {day}
            </label>
          ))}
        </div>
      </div>
    </>
  );
};

export default DaySelectionWeekDays;
