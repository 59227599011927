import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BgThemeButton, BgThemeButtonDisabled } from "./UI/Buttons";
import Loader from "./UI/Loader/Loader";
import { useSelector } from "react-redux";
import { Tooltip } from "@shopify/polaris";
import NoDataFound from "../Components/CustomComponents/NoDataFound";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import SliderComponentSkeleton from "./NewCustomComp/SliderComponentSkelon/SliderComponentSkeleton";
import TextDateFormatter from "./Communication/TextDateFormatter/TextDateFormatter";
import InventorySliderComponent from "./CustomComponents/BookingSoldSlider/InventorySliderComponent";
import { SelectBasic } from "../Components/NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import { getActiveApp, getBaseUrl } from "../utils/utils";
import styles from "./styles/inventory/inventoryCommon.module.scss";
import StayRestrictions from "./CustomComponents/InventorySliderComponents/StayRestrictions";
import InventoryUpdate from "./CustomComponents/InventorySliderComponents/InventoryUpdate";
import InventoryRatesUpdate from "./CustomComponents/InventorySliderComponents/InventoryRatesUpdate";
import InventoryUnblock from "./CustomComponents/InventorySliderComponents/InventoryUnblock";
import InventoryBulkBlock from "./CustomComponents/InventorySliderComponents/InventoryBulkBlock";
import InventoryUnblockChannelManager from "./CustomComponents/InventorySliderComponents/InventoryUnblockChannelManager";
import InventoryBulkBlockChannelManager from "./CustomComponents/InventorySliderComponents/InventoryBulkBlockChannelManager";
import ConfirmPopup from "./NewCustomComp/confirmPopup/ConfirmPopup";
import InventoryChannelMapping from "./ChannelManagerSettings/InventoryChannelMapping";
import InventoryDatePicker from "./CustomComponents/InventorySliderComponents/InventoryDatePicker";
import InventoryAddChannel from "./ChannelManagerSettings/InventoryAddChannel";
import {SvgPlus, SvgMinus,SvgBaseAdult, SvgChild,SvgChildWithBed ,SvgExtraAdult } from "../assets/svgIcons";
import { getDecryptedData } from "../utils/encryptStorage";

const InventoryRatesCommon = () => {
  const baseUrl = getBaseUrl();
  const [deleteWithReasonData, setDeleteWithReasonData] = useState("");

  const userToken = getDecryptedData('encryptToken');

  const permission = JSON.parse(localStorage.getItem("permissions"));

  const [permissions, setPermissions] = useState(permission);

  //
  useEffect(() => {
    setPermissions(permissions);
  }, [permissions]);
  const [data, setData] = useState("");
  const [loader, setLoader] = useState(false);

  const property_id = useSelector((state) => state.property.value) || localStorage.getItem("selectedApp");
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  const [selectedValues, setSelectedValues] = useState([]);

  const [selectedDatePickerDate, setSelectedDatePickerDate] =
    useState(formattedDate);

  const formatDate = (date) => {
    if (!date) return "";
    const year = date.getFullYear();
    const monthMonth = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed, so we add 1
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${monthMonth}-${day}`;
  };
  const navigate = useNavigate();

  const [showTotalBookings, setShowTotalBookings] = useState(false);

  useEffect(() => {
    if (showTotalBookings) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showTotalBookings]);

  const [totalBookingData, setShowTotalBookingsData] = useState(null);
  const [inventorySelectedRoomName, setInventorySelectedRoomName] =
    useState(null);
  const [inventorySelectedDate, setInventorySelectedDate] = useState(null);

  const fetchSoldRoomsData = async (
    roomId,
    date,
    propertyId,
    inventorySelectedRoomName,
    inventorySelectedDate
  ) => {
    setInventorySelectedDate(inventorySelectedDate);
    setInventorySelectedRoomName(inventorySelectedRoomName);

    try {
      const response = await fetch(
        // `${baseUrl}/api/v1/get/inventory/sold/hold/details/${roomId}/${date}/${propertyId}`
        `${baseUrl}/api/v1/get/inventory/sold/hold/details/${roomId}/${date}/${propertyId}`
      );

      const data = await response.json().then((data) => {
        if (data.success) {
          setShowTotalBookingsData(data);
          setShowTotalBookings(true);
          setLoader(false);
        } else {
          showErrorToast("Something went wrong...");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // ==========================new code=============================
  const activeURL = useLocation().pathname;
  // filter for
  const fetchRooms = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/v1/rooms/${property_id.id}`);

      const data = await response.json().then((data) => {
        if (data.success) {
          setShowTotalBookingsData(data);
          setCategories(data.rooms);
          setSelectedRoom(data.rooms[0]);
          setLoader(false);
        } else {
          showErrorToast("Something went wrong...");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const [categories, setCategories] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState({});

  useEffect(() => {
    if (property_id) {
      fetchRooms();
    }
  }, [property_id]);

  const handleRoomCategoryChange = (data) => {
    // fetchInfo(property_id?.id, data ,"channel_view");
    setSelectedRoom(categories.filter((item) => item.id === data)[0]);
  };

  const fetchInfo = async (property_id, id, view_type) => {
    // 'view_type' => 'required|string|in:channel_view,room_view',
    setLoader(true);
    const url =
      view_type === "channel_view"
        ? `${baseUrl}/api/v1/inventories/${property_id}?fetchType=rate&room_id=${id}&view_type=${view_type}`
        : `${baseUrl}/api/v1/inventories/${property_id}?fetchType=rate&channel_id=${id}&view_type=${view_type}`;
    try {
      setLoader(true);
      const response = await fetch(`${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json();

      if (data.success) {
        setData(data);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
    } finally{
      setLoader(false);
    }
  };
  const fetchInfoWithDate = async (id, date, view_type) => {
    const url =
      view_type === "channel_view"
        ? `${baseUrl}/api/v1/inventories/${property_id?.id}?date=${date}&fetchType=rate&room_id=${id}`
        : `${baseUrl}/api/v1/inventories/${property_id?.id}?date=${date}&fetchType=rate&channel_id=${id}`;
    try {
      setLoader(true);
      const response = await fetch(`${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json();
      setData(data);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const dateClickHandler = (date) => {
    const id =
      viewType === "channel_view"
        ? selectedRoom?.id
        : selectedChannel?.channel_id;
    fetchInfoWithDate(id, date, viewType);
  };
  const handleIncrementDate = () => {
    if (selectedDatePickerDate) {
      const newDate = new Date(selectedDatePickerDate);
      newDate.setDate(newDate.getDate() + 1);
      setSelectedDatePickerDate(formatDate(newDate));
      const id =
        viewType === "channel_view"
          ? selectedRoom?.id
          : selectedChannel?.channel_id;
      fetchInfoWithDate(id, formatDate(newDate), viewType);
    }
  };

  const handleDecrementDate = () => {
    if (selectedDatePickerDate) {
      const newDate = new Date(selectedDatePickerDate);
      newDate.setDate(newDate.getDate() - 1);
      setSelectedDatePickerDate(formatDate(newDate));
      const id =
        viewType === "channel_view"
          ? selectedRoom?.id
          : selectedChannel?.channel_id;
      fetchInfoWithDate(id, formatDate(newDate), viewType);
    }
  };

  useEffect(() => {
    if (property_id && categories.length > 0 && selectedRoom) {
      fetchInfo(property_id?.id, selectedRoom?.id, "channel_view");
    }
  }, [property_id, categories, selectedRoom?.id]);

  const handleDateChange = (date) => {
    setSelectedDatePickerDate(formatDate(date));
    const id =
      viewType === "channel_view"
        ? selectedRoom?.id
        : selectedChannel?.channel_id;
    fetchInfoWithDate(id, formatDate(date), viewType);
  };

  

  function getMonthDay(dateString) {
    const date = new Date(dateString);
    const day = date.getDate(); // Get the day (e.g., 23)
    const month = date
      .toLocaleString("default", { month: "short" })
      .toUpperCase(); // Get the month (e.g., AUG)

    return `${day}<br/> ${month}`;
  }

  // end inventory table block unblock

  const activeApp = getActiveApp();

  const controlsBulk = [
    // "Inventory",
    "Rates",
    // "Block",
    // "Unblock",
    // "Drip Feed",
    // "Stay Restrictions",
  ];

  // chanel listing aip
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState({});
  

  const fetchChannels = async () => {
    if (activeApp !== "channel_manager"){
      return
    }
    try {
      setLoader(true);

      const response = await fetch(
        `${baseUrl}/api/v1/mapped/channels/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        setChannels(data.data);
        setSelectedChannel(data.data[0]);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoader(false);
    }
  };

  const [showBookingSlider, setShowBookingSlider] = useState(false);
  const [sliderHeading, setSliderHeading] = useState("");

  useEffect(() => {
   if( property_id && property_id?.id){
    fetchChannels();
   }
  }, [property_id]);

  const handleBulkControlChange = (data) => {
    switch (data) {
      case "Stay Restrictions": {
        setShowBookingSlider(true);
        setSliderHeading(data);
        break;
      }

      case "Inventory": {
        setShowBookingSlider(true);
        setSliderHeading(data);
        break;
      }
      case "Rates": {
        setShowBookingSlider(true);
        setSliderHeading(data);
        break;
      }
      case "Block": {
        setShowBookingSlider(true);
        setSliderHeading(data);
        break;
      }
      case "Unblock": {
        setShowBookingSlider(true);
        setSliderHeading(data);
        break;
      }

      default:
        break;
    }
  };



  
  

  const [channelMappingPopup, setChannelMappingPopup] = useState(false);

  const [selectedChannelMapping, setSelectedChannelMapping] = useState(null);

  const handleChannelMappingPopupShow = (channelId, channelName) => {
    setSelectedChannelMapping({
      channelId: channelId,
      channelName: channelName,
    });
    setChannelMappingPopup(true);
  };

  const [addChannelPopup, setAddChannelPopup] = useState(false);

  const [viewType, setViewType] = useState("channel_view");

  const handleViewTypeChange = (value) => {
    switch (value) {
      case "channel_view":
        setViewType(value);
        fetchInfo(property_id?.id, selectedRoom?.id, "channel_view");
        break;
      case "room_view":
        setViewType(value);
        fetchInfo(property_id?.id, selectedChannel?.channel_id, "room_view");
        break;
      default:
        break;
    }
  };
  const handleInventoryChannelChange = (value) => {
    setSelectedChannel(
      channels.find((channel) => +channel.channel_id === +value)
    );

    fetchInfo(property_id?.id, +value, "room_view");
  };

  const [expandedState, setExpandedState] = useState({});

  // Toggle expanded state for a specific room + rateType
  const toggleExpanded = (roomIndex, rateType) => {
    const key = `${roomIndex}-${rateType}`; // Unique key for room + rateType

    setExpandedState((prevState) => ({
      ...prevState,
      [key]: !prevState[key], // Toggle the expanded state for the specific rateType
    }));
  };

  const [originalData, setOriginalData] = useState({}); // To track the original values of the inputs
  const [inputChanges, setInputChanges] = useState({});
  useEffect(() => {
    const originalValues = {};
    if (data.rooms && data.rooms.length > 0) {
      data.rooms.forEach((room, roomIndex) => {
        Object.keys(room.rates).forEach((rateType) => {
          data.date_range.forEach((date) => {
            if (room.rates[rateType][date]) {
              const rate = room.rates[rateType][date][0];
              originalValues[
                `${roomIndex}-${rateType}-${date}-rate_bar_price`
              ] = rate.rate_bar_price;
              originalValues[`${roomIndex}-${rateType}-${date}-extra_adult`] =
                rate.extra_adult;
              originalValues[
                `${roomIndex}-${rateType}-${date}-child_with_Bed`
              ] = rate.child_with_Bed;
              originalValues[
                `${roomIndex}-${rateType}-${date}-child_without_Bed`
              ] = rate.child_without_Bed;
            }
          });
        });
      });
    }
    setOriginalData(originalValues);
  }, [data]);

  const handleInputChange = (roomIndex, rateType, date, field, value) => {
    // Clone data
    const updatedData = { ...data };

    // Update the specific rate
    if (updatedData.rooms[roomIndex].rates[rateType][date]) {
      updatedData.rooms[roomIndex].rates[rateType][date][0][field] = value;
    }

    // Update the data state
    setData(updatedData);

    // Track changes in inputs
    const key = `${roomIndex}-${rateType}-${date}-${field}`;
    const originalValue = originalData[key];
    setInputChanges((prevChanges) => ({
      ...prevChanges,
      [key]: value !== originalValue, // true if the value is different from the original
    }));
  };

  const  handleInputSaveButtonClick = async (
    rateData,
    date,
    channelName,
    channelId
  ) => {
    
    const postData = {
      from_date: date,
      to_date: date,
      rates_data: rateData.map((rate) => {
        return {
          base_rate: rate.rate_bar_price,
          extra_adult:rate.extra_adult,
          extra_child_with_bed:rate.child_with_Bed,
          extra_child_without_bed:rate.child_without_Bed,
          meal_plan_id: rate.meal_plan_id,
          room_id: rate.room_id,
         
        }
      }),
      channels: [
        {
          channel_id: channelId,
          channel_name: channelName,
        },
      ],

      sun: 1,
      mon: 1,
      tue: 1,
      wed: 1,
      thr: 1,
      fri: 1,
      sat: 1,
    };

   
    try {
      setLoader(true);

      const response = await fetch(
        `${baseUrl}/api/v1/update/rates/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          method: "POST",
          body: JSON.stringify(postData),
        }
      );

      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
       {
        fetchInfo(property_id?.id, selectedRoom?.id, "channel_view")
        setInputChanges({});
       }
      } else {
        showErrorToast(data.message || "Failed to update stay restrictions.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      showErrorToast("An error occurred while updating stay restrictions.");
    } finally {
      setLoader(false);
    }
  };

  //  
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {data && data.date_range && data.date_range.length > 0 ? (
            <div className="room-types-full-container">
              <div className="room-types-full-container-div">
                <div className="room-type-heading">Inventory</div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
             
                  <div>
                    {permissions &&
                    permissions.includes("read_low_inventory_dates") ? (
                      <Link
                        style={{ textDecoration: "none" }}
                        to={`${activeURL}/low-inventory`}
                      >
                        <BgThemeButton children={"Low Inventory Update"} />
                      </Link>
                    ) : (
                      <Tooltip
                        content={`Please contact the admin.`}
                        dismissOnMouseOut
                      >
                        <Link style={{ textDecoration: "none" }}>
                          <BgThemeButtonDisabled
                            children={"Low Inventory Update"}
                          />
                        </Link>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>

              <div className="inventory-full-container">
                <div
                  style={{
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="flex  flex_gap_10 align_items_center">
                    <div style={{width: "150px", maxWidth:"200px"
                    }}>
                    {viewType === "channel_view" && (
                      <SelectBasic
                        options={categories}
                        optionKeyName={"room_name"}
                        size={"medium"}
                        showKeyName={"room_name"}
                        selectedKeyName={"id"}
                        handleSelectTagChange={handleRoomCategoryChange}
                        defaultSelected={selectedRoom}
                      />
                    )}

                    {viewType === "room_view" && (
                      <SelectBasic
                        options={channels}
                        optionKeyName={"channel_name"}
                        size={"medium"}
                        showKeyName={"channel_name"}
                        selectedKeyName={"channel_id"}
                        handleSelectTagChange={handleInventoryChannelChange}
                        defaultSelected={selectedChannel}
                      />
                    )}
                    </div>

                    {
                      activeApp === "channel_manager" ? (
                        <div className={styles.viewTypeContainer}>
                        <button
                          className={styles.viewTypeButtons}
                          style={
                            viewType === "channel_view"
                              ? { color: "#3968ed", textDecoration: "underline" }
                              : {}
                          }
                          onClick={() => handleViewTypeChange("channel_view")}
                        >
                          Channel View
                        </button>
                        <button
                          className={styles.viewTypeButtons}
                          style={
                            viewType === "room_view"
                              ? { color: "#3968ed", textDecoration: "underline" }
                              : {}
                          }
                          onClick={() => handleViewTypeChange("room_view")}
                        >
                          Room View
                        </button>
                      </div>
                      )
                     
                     : null





                    }
                  </div>
                  <div className="flex flex_gap_10">
                    <div style={{ width: "150px" }}>
                      <SelectBasic
                        options={controlsBulk}
                        size={"medium"}
                        handleSelectTagChange={handleBulkControlChange}
                        placeholder={"Bulk Updates"}
                      />
                    </div>
                    <InventoryDatePicker
                      selectedDatePickerDate={selectedDatePickerDate}
                      handleDateChange={handleDateChange}
                      handleDecrementDate={handleDecrementDate}
                      handleIncrementDate={handleIncrementDate}
                    />
                  </div>
                </div>

                <div className="inventory-second-column-2div">
                  <div
                    style={{
                      borderRadius: "6px",
                    }}
                  ></div>
                </div>

                <table
                  style={{ width: "100%" }}
                  className={styles.tableInventory}
                >
                  <thead>
                    <tr>
                      <th className={styles.longHeader}>Rates</th>
                      <th
                        style={{ border: "none" }}
                        className={styles.dateHeaders}
                      />
                      {data.date_range?.map((date, index) => (
                        <th key={index} className={styles.dateHeaders}>
                          <div className="flex justify_content_center align_items_center">
                            <span
                              style={
                                index === 0
                                  ? {
                                      backgroundColor: "#3968ed",
                                      color: "white",
                                    }
                                  : {}
                              }
                              dangerouslySetInnerHTML={{
                                __html: getMonthDay(date),
                              }}
                              onClick={() => dateClickHandler(date)}
                            />
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.rooms?.map((room, roomIndex) => (
                      <tr key={roomIndex}>
                        <td colSpan={data.date_range.length + 2}>
                          <table
                            style={{ width: "100%", border: "1px solid #ccc" }}
                            className={`${styles.innerTable} ${styles.InventoryRatesInnerTable}`}
                          >
                            <tbody>
                              <tr>
                                <td
                                  className={styles.channelNameText}
                                  colSpan={data.date_range.length + 2}
                                >

                                   {
                                    activeApp === "channel_manager" ? (<>
                                    {viewType === "channel_view"
                                    ? room.channel_name
                                    : room.room_name}
                                    
                                    
                                    </>) : room.room_name
                                   }

                                  {}
                                </td>
                              </tr>
                              {Object.keys(room.rates).map(
                                (rateType, index) => {
                                  const key = `${roomIndex}-${rateType}`;
                                  return (
                                    <React.Fragment key={index}>
                                      <tr>
                                        <td
                                          className={`${styles.longHeader} ${styles.addBorderTop}`}
                                        >
                                          <div className="flex flex_gap_10 align_items_center">
                                            <button
                                              onClick={() =>
                                                toggleExpanded(
                                                  roomIndex,
                                                  rateType
                                                )
                                              }
                                            >
                                              {expandedState[key]
                                                ? <SvgMinus/>
                                                : <SvgPlus/>}
                                            </button>
                                            <p className={styles.rateType}>
                                              {rateType}
                                            </p>
                                          </div>
                                        </td>
                                        <td
                                          className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                        >
                                          {<SvgBaseAdult/>}{" "}
                                        </td>
                                        {data.date_range.map((date) => (
                                          <td
                                            key={date}
                                            className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                          >
                                            <span
                                              className={`flex flex_direction_col align_items_center ${styles.inputContainer}`}
                                            >
                                              <input
                                               
                                               type="number"
                                                value={
                                                  room.rates[rateType][
                                                    date
                                                  ]?.[0]?.rate_bar_price || "0"
                                                }
                                                className={`inventory-1st-column-inner-input ${styles.inputRateChange}`}
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    roomIndex,
                                                    rateType,
                                                    date,
                                                    "rate_bar_price",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                              {inputChanges[
                                                `${roomIndex}-${rateType}-${date}-rate_bar_price`
                                              ] && (
                                                <button
                                                  onClick={() =>
                                                    handleInputSaveButtonClick(
                                                      
                                                      room.rates[rateType][
                                                        date
                                                      ],
                                                      date,
                                                      room.channel_name,
                                                      room.channel_id
                                                    )
                                                  }
                                                >
                                                  Save
                                                </button>
                                              )}
                                            </span>
                                          </td>
                                        ))}
                                      </tr>

                                      {/* Only show when expanded */}
                                      {expandedState[key] && (
                                        <>
                                          <tr>
                                            <td />
                                            <td
                                              className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                            >
                                              {<SvgExtraAdult/>}
                                            </td>
                                            {data.date_range.map((date) => (
                                              <td
                                                key={date}
                                                className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                              >
                                                <span
                                                  className={`flex flex_direction_col align_items_center ${styles.inputContainer}`}
                                                >
                                                  <input
                                                   type="number"
                                                    value={
                                                      room.rates[rateType][
                                                        date
                                                      ]?.[0]?.extra_adult || "0"
                                                    }
                                                    className={`inventory-1st-column-inner-input ${styles.inputRateChange}`}
                                                    onChange={(e) =>
                                                      handleInputChange(
                                                        roomIndex,
                                                        rateType,
                                                        date,
                                                        "extra_adult",
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                  {inputChanges[
                                                    `${roomIndex}-${rateType}-${date}-extra_adult`
                                                  ] && (
                                                    <button
                                                    onClick={() =>
                                                      handleInputSaveButtonClick(
                                                        
                                                        room.rates[rateType][
                                                          date
                                                        ],
                                                        date,
                                                        room.channel_name,
                                                        room.channel_id
                                                      )
                                                    }
                                                    >
                                                      Save
                                                    </button>
                                                  )}
                                                </span>
                                              </td>
                                            ))}
                                          </tr>

                                          <tr>
                                            <td />
                                            <td
                                              className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                            >
                                              {<SvgChild/>}{" "}
                                            </td>
                                            {data.date_range.map((date) => (
                                              <td
                                                key={date}
                                                className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                              >
                                                <span
                                                  className={`flex flex_direction_col align_items_center ${styles.inputContainer}`}
                                                >
                                                  <input
                                                   type="number"
                                                    value={
                                                      room.rates[rateType][
                                                        date
                                                      ]?.[0]?.child_with_Bed ||
                                                      "0"
                                                    }
                                                    className={`inventory-1st-column-inner-input ${styles.inputRateChange}`}
                                                    onChange={(e) =>
                                                      handleInputChange(
                                                        roomIndex,
                                                        rateType,
                                                        date,
                                                        "child_with_Bed",
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                  {inputChanges[
                                                    `${roomIndex}-${rateType}-${date}-child_with_Bed`
                                                  ] && (
                                                    <button
                                                    onClick={() =>
                                                      handleInputSaveButtonClick(
                                                        
                                                        room.rates[rateType][
                                                          date
                                                        ],
                                                        date,
                                                        room.channel_name,
                                                        room.channel_id
                                                      )
                                                    }
                                                    >
                                                      Save
                                                    </button>
                                                  )}
                                                </span>
                                              </td>
                                            ))}
                                          </tr>

                                          <tr>
                                            <td />
                                            <td
                                              className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                            >
                                              {<SvgChildWithBed/>}{" "}
                                            </td>
                                            {data.date_range.map((date) => (
                                              <td
                                                key={date}
                                                className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                              >
                                                <span
                                                  className={`flex flex_direction_col align_items_center ${styles.inputContainer}`}
                                                >
                                                  <input
                                                   type="number"
                                                    value={
                                                      room.rates[rateType][
                                                        date
                                                      ]?.[0]
                                                        ?.child_without_Bed ||
                                                      "0"
                                                    }
                                                    className={`inventory-1st-column-inner-input ${styles.inputRateChange}`}
                                                    onChange={(e) =>
                                                      handleInputChange(
                                                        roomIndex,
                                                        rateType,
                                                        date,
                                                        "child_without_Bed",
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                  {inputChanges[
                                                    `${roomIndex}-${rateType}-${date}-child_without_Bed`
                                                  ] && (
                                                    <button
                                                    onClick={() =>
                                                      handleInputSaveButtonClick(
                                                        
                                                        room.rates[rateType][
                                                          date
                                                        ],
                                                        date,
                                                        room.channel_name,
                                                        room.channel_id
                                                      )
                                                    }
                                                    >
                                                      Save
                                                    </button>
                                                  )}
                                                </span>
                                              </td>
                                            ))}
                                          </tr>
                                        </>
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <NoDataFound />
          )}
           
          {/* ================================WORKING HERE================================================== */}

          {showTotalBookings && totalBookingData && (
            <>
              <SliderComponentSkeleton
                show={showTotalBookings}
                setShow={setShowTotalBookings}
                title={
                  <TextDateFormatter
                    as={"div"}
                    date={inventorySelectedDate}
                    showYear={true}
                  />
                }
                smallTitle={`Total Bookings:   ${
                  totalBookingData?.agentContractData?.length +
                  totalBookingData?.holdRooms?.length +
                  totalBookingData?.soldRooms?.length
                }`}
              >
                <InventorySliderComponent
                  totalBookingData={totalBookingData}
                  setShowTotalBookings={setShowTotalBookings}
                  selectedDate={"selectedDate"}
                  inventorySelectedRoomName={inventorySelectedRoomName}
                  inventorySelectedDate={inventorySelectedDate}
                  fetchSoldRoomsData={fetchSoldRoomsData}
                  fetchInfo={fetchInfo}
                />
              </SliderComponentSkeleton>
            </>
          )}
          {/* =============================INVENTORY SLIDER BULK UPDATE====================================================================== */}
          {showBookingSlider && (
            <SliderComponentSkeleton
              show={showBookingSlider}
              setShow={setShowBookingSlider}
              title={`Bulk Update: (${sliderHeading})`}
            >
              {sliderHeading === "Stay Restrictions" && (
                <StayRestrictions
                  categories={categories}
                  currentDate={currentDate}
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                  baseUrl={baseUrl}
                  property_id={property_id}
                  userToken={userToken}
                  setLoader={setLoader}
                  channels={channels}
                  setShow={setShowBookingSlider}
                />
              )}

              {sliderHeading === "Inventory" && (
                <InventoryUpdate
                  categories={categories}
                  currentDate={currentDate}
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                  baseUrl={baseUrl}
                  property_id={property_id}
                  userToken={userToken}
                  setLoader={setLoader}
                  channels={channels}
                  setShow={setShowBookingSlider}
                />
              )}

              {sliderHeading === "Rates" && (
                <InventoryRatesUpdate
                  categories={categories}
                  currentDate={currentDate}
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                  baseUrl={baseUrl}
                  property_id={property_id}
                  userToken={userToken}
                  setLoader={setLoader}
                  channels={channels}
                  setShow={setShowBookingSlider}
                />
              )}

              {sliderHeading === "Block" && (
                <>
                  {activeApp === "booking_engine" ? (
                    <InventoryBulkBlock
                      categories={categories}
                      currentDate={currentDate}
                      selectedValues={selectedValues}
                      setSelectedValues={setSelectedValues}
                      baseUrl={baseUrl}
                      property_id={property_id}
                      userToken={userToken}
                      setLoader={setLoader}
                      channels={channels}
                      setShow={setShowBookingSlider}
                      permissions={permissions}
                    />
                  ) : (
                    <InventoryBulkBlockChannelManager
                      categories={categories}
                      currentDate={currentDate}
                      selectedValues={selectedValues}
                      setSelectedValues={setSelectedValues}
                      baseUrl={baseUrl}
                      property_id={property_id}
                      userToken={userToken}
                      setLoader={setLoader}
                      channels={channels}
                      setShow={setShowBookingSlider}
                      permissions={permissions}
                    />
                  )}
                </>
              )}

              {sliderHeading === "Unblock" && (
                <>
                  {activeApp === "booking_engine" ? (
                    <InventoryUnblock
                      categories={categories}
                      currentDate={currentDate}
                      selectedValues={selectedValues}
                      setSelectedValues={setSelectedValues}
                      baseUrl={baseUrl}
                      property_id={property_id}
                      userToken={userToken}
                      setLoader={setLoader}
                      channels={channels}
                      setShow={setShowBookingSlider}
                      permissions={permissions}
                      fetchInfo={fetchInfo}
                    />
                  ) : (
                    <InventoryUnblockChannelManager
                      categories={categories}
                      currentDate={currentDate}
                      selectedValues={selectedValues}
                      setSelectedValues={setSelectedValues}
                      baseUrl={baseUrl}
                      property_id={property_id}
                      userToken={userToken}
                      setLoader={setLoader}
                      channels={channels}
                      setShow={setShowBookingSlider}
                      permissions={permissions}
                      fetchInfo={fetchInfo}
                    />
                  )}
                </>
              )}
            </SliderComponentSkeleton>
          )}

          


        

          {/* ======= channel mapping popup======== */}

          {channelMappingPopup && (
            <InventoryChannelMapping
              show={channelMappingPopup}
              setShow={setChannelMappingPopup}
              propertyData={property_id}
              selectedChannelMapping={selectedChannelMapping}
              roomCategories={categories}
            />
          )}

          {addChannelPopup && (
            <InventoryAddChannel
              show={addChannelPopup}
              setShow={setAddChannelPopup}
              fetchInfo={fetchInfo}
              roomId={selectedRoom.id}
              propertyId={property_id.id}
            />
          )}
        </>
      )}
    </>
  );
};

export default InventoryRatesCommon;
