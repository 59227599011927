import { useState } from "react";
import PaymentReceiptCommunication from "../../Components/Communication/PaymentReceiptCommunication";

const PaymentReceiptCommunicationPage = () => {
    //   const [showShareOnEmail, setShowShareOnEmail] = useState(false);
    //   const [showShareOnWhatsapp, setShowShareWhatsapp] = useState(false);

    //   const handleShowShareOnEmail = () => {
    //     {
    //       showShareOnWhatsapp === true && setShowShareWhatsapp(false);
    //     }
    //     setShowShareOnEmail(!showShareOnEmail);
    //   };

    //   const handleShowShareOnWhatsapp = () => {
    //     {
    //       showShareOnEmail === true && setShowShareOnEmail(false);
    //     }
    //     setShowShareWhatsapp(!showShareOnWhatsapp);
    //   };
  
  return (
    <>
      <div className="main_container">
        {/* <PreviewBill /> */}
        <PaymentReceiptCommunication />
      </div>
    </>
  );
};
export default PaymentReceiptCommunicationPage;
