import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  CheckWithoutBgUnderlineBtn,
} from "./UI/Buttons";
import Loader from "./UI/Loader/Loader";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import { useSelector } from "react-redux";
import { Button, Icon, Tooltip } from "@shopify/polaris";

import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { rooms_inner_lists } from "../assets/constant";
import SidebarInnerLists from "./CustomComponents/SidebarInnerLists";

import NoDataFound from "./CustomComponents/NoDataFound";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";

import { getBaseUrl } from "../utils/utils";
import GoBackButtonCustom from "./GoBackButtonCustom/GoBackButtonCustom";
import { getDecryptedData } from "../utils/encryptStorage";

const RateInventoryBulkUpdate = () => {
  const baseURL = getBaseUrl();

  const [purchaseHeadingActiveIndex, setPurchaseHeadingActiveIndex] =
    useState(1);
  const [purchaseHeadingActive, setPurchaseHeadingActive] = useState("");
  const currentDate = new Date().toISOString().split("T")[0];
  const setPurchaseHeading = (idx) => {
    setPurchaseHeadingActiveIndex(idx);
    if (purchaseHeadingActive === idx) {
      setPurchaseHeadingActive("active");
    } else {
      setPurchaseHeadingActive("");
    }
  };
  const userToken = getDecryptedData('encryptToken');

  const permission = JSON.parse(localStorage.getItem("permissions"));

  const [permissions, setPermissions] = useState(permission);

  //
  useEffect(() => {
    setPermissions(permissions);
  }, [permissions]);

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);

  const [propertyUniqueId, setPropertyUniqueId] = useState("");
  const selectedPropertyUniqueId = useSelector((state) => state.property.value);

  const property_id = useSelector((state) => state.property.value);

  const [selectedDate, setSelectedDate] = useState();

  // const dateClickHandler = (date) => {
  //   {
  //     setBulkInventoryData({
  //       ...bulkInventoryData,
  //       from_date: date,
  //     });
  //   }
  //   setSelectedDate(date);
  //   fetchInfoWithDate(property_id.id, date);
  // };

  const fetchInfo = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(`${baseURL}/api/v1/inventories/${id}?fetchType=rate`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json();
      setData(data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (data && data.rooms) {
      const mappedData = data.rooms.map((roomData, index) => {
        return {
          // id: roomData.id,
          room_name: roomData.room_name,
          rates: Object.keys(roomData.rates).map((roomType) => {
            const firstDate = Object.keys(roomData.rates[roomType]);
            const firstDateData = roomData.rates[roomType][firstDate[0]];
            return {
              room_name: roomData.room_name,
              id: roomData.id,
              room_type_name: roomType,
              base_rate: "",
              extra_adult: "",
              extra_child_with_bed: "",
              extra_child_without_bed: "",
              rate_plan_id: firstDateData[0].rate_plan_id,
            };
          }),
        };
      });

      setRenderedData(mappedData);
    }
  }, [data]);

 

  const [renderedData, setRenderedData] = useState([]);



  const fetchInfoWithDate = async (id, date) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${baseURL}/api/v1/inventories/${id}?date=${date}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setData(data);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

   useEffect(() => {
    if (property_id  && userToken)  {
      fetchInfo(property_id?.id);
    }
  }, [property_id, userToken]);

  setTimeout(() => {
    setLoader(false);
  }, 1000);

  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const dummyRoomPlansName = [
    {
      name: "Base Rate",
      svg: (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 15 14"
            fill="none"
          >
            <path
              d="M7.50005 6.3001C8.65985 6.3001 9.60005 5.3599 9.60005 4.2001C9.60005 3.0403 8.65985 2.1001 7.50005 2.1001C6.34025 2.1001 5.40005 3.0403 5.40005 4.2001C5.40005 5.3599 6.34025 6.3001 7.50005 6.3001Z"
              fill="#888888"
            />
            <path
              d="M5.05004 11.9002C4.60218 11.9002 4.15432 11.7294 3.81261 11.3877C3.12919 10.7042 3.12919 9.59621 3.81261 8.91279C4.22509 8.50856 5.54005 7.7001 7.50005 7.7001C9.46005 7.7001 10.775 8.50856 11.1875 8.91279C11.8709 9.59621 11.8709 10.7042 11.1875 11.3877C10.8458 11.7294 10.3979 11.9002 9.95005 11.9002H5.05004Z"
              fill="#888888"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 15 14"
            fill="none"
          >
            <path
              d="M7.50005 6.3001C8.65985 6.3001 9.60005 5.3599 9.60005 4.2001C9.60005 3.0403 8.65985 2.1001 7.50005 2.1001C6.34025 2.1001 5.40005 3.0403 5.40005 4.2001C5.40005 5.3599 6.34025 6.3001 7.50005 6.3001Z"
              fill="#888888"
            />
            <path
              d="M5.05004 11.9002C4.60218 11.9002 4.15432 11.7294 3.81261 11.3877C3.12919 10.7042 3.12919 9.59621 3.81261 8.91279C4.22509 8.50856 5.54005 7.7001 7.50005 7.7001C9.46005 7.7001 10.775 8.50856 11.1875 8.91279C11.8709 9.59621 11.8709 10.7042 11.1875 11.3877C10.8458 11.7294 10.3979 11.9002 9.95005 11.9002H5.05004Z"
              fill="#888888"
            />
          </svg>
        </>
      ),
    },
    {
      name: "Extra Adult",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 15 14"
          fill="none"
        >
          <path
            d="M7.50005 6.3001C8.65985 6.3001 9.60005 5.3599 9.60005 4.2001C9.60005 3.0403 8.65985 2.1001 7.50005 2.1001C6.34025 2.1001 5.40005 3.0403 5.40005 4.2001C5.40005 5.3599 6.34025 6.3001 7.50005 6.3001Z"
            fill="#888888"
          />
          <path
            d="M5.05004 11.9002C4.60218 11.9002 4.15432 11.7294 3.81261 11.3877C3.12919 10.7042 3.12919 9.59621 3.81261 8.91279C4.22509 8.50856 5.54005 7.7001 7.50005 7.7001C9.46005 7.7001 10.775 8.50856 11.1875 8.91279C11.8709 9.59621 11.8709 10.7042 11.1875 11.3877C10.8458 11.7294 10.3979 11.9002 9.95005 11.9002H5.05004Z"
            fill="#888888"
          />
        </svg>
      ),
    },
    {
      name: "Extra Child",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 15 14"
          fill="none"
        >
          <path
            d="M2.25 4.08325V10.4999M2.25 8.16659H12.75M12.75 10.4999V5.83325C12.75 5.52383 12.6271 5.22709 12.4083 5.00829C12.1895 4.7895 11.8928 4.66659 11.5833 4.66659H6.91667V8.16659M4 5.83325C4 5.98796 4.06146 6.13633 4.17085 6.24573C4.28025 6.35513 4.42862 6.41659 4.58333 6.41659C4.73804 6.41659 4.88642 6.35513 4.99581 6.24573C5.10521 6.13633 5.16667 5.98796 5.16667 5.83325C5.16667 5.67854 5.10521 5.53017 4.99581 5.42077C4.88642 5.31138 4.73804 5.24992 4.58333 5.24992C4.42862 5.24992 4.28025 5.31138 4.17085 5.42077C4.06146 5.53017 4 5.67854 4 5.83325Z"
            stroke="#888888"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      name: "Extra Child",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 9 14"
          fill="none"
        >
          <path
            d="M2.75005 1.75C2.75005 1.28587 2.93442 0.840752 3.26261 0.512563C3.5908 0.184374 4.03592 0 4.50005 0C4.96417 0 5.40929 0.184374 5.73748 0.512563C6.06567 0.840752 6.25005 1.28587 6.25005 1.75C6.25005 2.21413 6.06567 2.65925 5.73748 2.98744C5.40929 3.31563 4.96417 3.5 4.50005 3.5C4.03592 3.5 3.5908 3.31563 3.26261 2.98744C2.93442 2.65925 2.75005 2.21413 2.75005 1.75ZM4.06255 10.5V13.125C4.06255 13.609 3.67153 14 3.18755 14C2.70356 14 2.31255 13.609 2.31255 13.125V7.86953L1.74106 8.77734C1.48403 9.1875 0.942624 9.30781 0.535202 9.05078C0.12778 8.79375 0.00199857 8.25508 0.25903 7.84766L1.35005 6.1168C2.0309 5.03125 3.22036 4.375 4.50005 4.375C5.77973 4.375 6.96919 5.03125 7.65005 6.11406L8.74106 7.84766C8.99809 8.25781 8.87505 8.79648 8.46762 9.05352C8.0602 9.31055 7.5188 9.1875 7.26176 8.78008L6.68755 7.86953V13.125C6.68755 13.609 6.29653 14 5.81255 14C5.32856 14 4.93755 13.609 4.93755 13.125V10.5H4.06255Z"
            fill="#888888"
          />
        </svg>
      ),
    },
  ];

  const [activeIndexes, setActiveIndexes] = useState([0]);

  

  const [expandedItems, setExpandedItems] = useState([]);


  const [innerInnerClose, setInnerInnerClose] = useState(false);

  const togglePlanExpand = (rateIndex) => {
    if (expandedItems.includes(rateIndex)) {
      setExpandedItems(expandedItems.filter((i) => i !== rateIndex));
      setInnerInnerClose(true);
    } else {
      setExpandedItems([...expandedItems, rateIndex]);
      setInnerInnerClose(false);
    }
  };

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);
  const [blockShowAddModelMenuActive, setBlockShowAddModelMenuActive] =
    useState(false);
  const [unblockShowAddModelMenuActive, setUnblockShowAddModelMenuActive] =
    useState(false);

  const [holdShowAddModelMenuActive, setHoldShowAddModelMenuActive] =
    useState(false);

  const [unholdShowAddModelMenuActive, setUnholdShowAddModelMenuActive] =
    useState(false);

  const [
    inventoriesOptionShowAddModelMenuActive,
    setInventoriesOptionShowAddModelMenuActive,
  ] = useState(false);

  const [bulkInventoryData, setBulkInventoryData] = useState({
    property_id: property_id?.id,
    from_date: "",
    to_date: "",
    // inventory: "",
    // rates: "",
    // extra_adult: "",
    // extra_child: "",
    // extra_bed: "",
  });

  const [blockInventoryData, setBlockInventoryData] = useState({
    property_id: property_id?.id,
    from_date: "",
    to_date: "",
  });

  const [unblockInventoryData, setUnblockInventoryData] = useState({
    property_id: property_id?.id,
    from_date: "",
    to_date: "",
    // available_rooms: "",
  });

  const [holdInventoryData, setHoldInventoryData] = useState({
    property_id: property_id?.id,
    from_date: "",
    to_date: "",
  });

  const [selectedValues, setSelectedValues] = useState([]);

  // HOLD
  const [selectedRooms, setSelectedRooms] = useState({});
  const [holdInputValues, setHoldInputValues] = useState([]);
  const [bulkInputValues, setBulkInputValues] = useState([]);
  const [error, setError] = useState("");

  const holdHandleCheckboxChange = async (e, room) => {
    const roomId = room.id;
    const isChecked = e.target.checked;
    if (holdInventoryData.from_date === "") {
      showErrorToast("Please fill the above dates.");
      return;
    }

    if (isChecked) {
      try {
        const response = await fetch(
          `${baseURL}/api/v1/inventories/edit/${roomId}/${holdInventoryData.from_date}/${property_id?.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          const availableRooms = data.available_rooms;

          setHoldInputValues((prevRoomData) => [
            ...prevRoomData,
            {
              property_id: property_id?.id,
              from_date: selectedDate, // Set the selected date here
              to_date: "",
              available_rooms: availableRooms,
              room_id: roomId,
              hold_room: "",
            },
          ]);
        } else {
          // Handle error if the fetch request is not successful
          console.error("Failed to fetch available rooms data");
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    } else {
      setHoldInputValues((prevRoomData) =>
        prevRoomData.filter((data) => data.room_id !== roomId)
      );
    }
  };

  const bulkHandleCheckboxChange = async (e, room) => {
    const roomId = room.id;
    const isChecked = e.target.checked;

    // if (isChecked) {
    //   // Logic when the checkbox is checked
    //   // You can add your own logic here

    //   setBulkInputValues((prevRoomData) => [
    //     ...prevRoomData,
    //     {
    //       property_id: property_id?.id,
    //       from_date: selectedDate, // Set the selected date here
    //       to_date: "",
    //       room_id: roomId,
    //       rates: "",
    //     },
    //   ]);
    // } else {
    //   // Logic when the checkbox is unchecked
    //   // You can add your own logic here

    //   // Remove the item from the bulkInputValues based on room_id
    //   setBulkInputValues((prevRoomData) =>
    //     prevRoomData.filter((data) => data.room_id !== roomId)
    //   );
    // }

    if (bulkInventoryData.from_date === "") {
      showErrorToast("Please fill the above dates.");
      return;
    }
    if (isChecked) {
      try {
        const response = await fetch(
          `${baseURL}/api/v1/inventories/edit/${roomId}/${bulkInventoryData.from_date}/${property_id?.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          const availableRooms = data.available_rooms;

          setBulkInputValues((prevRoomData) => [
            ...prevRoomData,
            {
              from_date: selectedDate,
              to_date: "",
              available_rooms: availableRooms,
              room_id: roomId,
              inventory: "",
            },
          ]);
        } else {
          showErrorToast("Failed to fetch available rooms data");
        }
      } catch (error) {
        showErrorToast("An error occurred while fetching data:");
      }
    } else {
      setBulkInputValues((prevRoomData) =>
        prevRoomData.filter((data) => data.room_id !== roomId)
      );
    }
  };

  const handleHoldInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRoomData = [...holdInputValues];

    // Find the index of the current room data
    const roomDataIndex = updatedRoomData.findIndex(
      (data) => data.room_id === holdInputValues[index].room_id
    );

    if (!isNaN(value)) {
      if (
        name === "hold_room" &&
        parseInt(value) >= holdInputValues[index].available_rooms
      ) {
        showErrorToast("Hold Rooms should be less then Available Rooms.");
        return;
      }

      updatedRoomData[roomDataIndex][name] = value;
      setHoldInputValues(updatedRoomData);
    } else {
      showErrorToast("Please enter a valid number.");
    }
  };

  // new handle change
  const handleBulkInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRoomData = [...bulkInputValues];

    const roomDataIndex = updatedRoomData.findIndex(
      (data) => data.room_id === bulkInputValues[index].room_id
    );

    if (!isNaN(value)) {
      if (
        name === "inventory" &&
        parseInt(value) >= bulkInputValues[index].available_rooms
      ) {
        showErrorToast("Inventory Rooms should be less then Available Rooms.");
        return;
      }
      updatedRoomData[roomDataIndex][name] = value;
      setBulkInputValues(updatedRoomData);
    } else {
      showErrorToast("Please enter a valid number.");
    }
  };

  // Function to handle checkbox changes
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      // Check if the value is not already in the array before adding it
      if (!selectedValues.includes(value)) {
        setSelectedValues([...selectedValues, value]);
        addCheckbox();
      }
    } else {
      // Remove the value from the array if it exists
      setSelectedValues(selectedValues.filter((item) => item !== value));
    }
  };

  const [checkboxOptions, setCheckboxOptions] = useState([]);

  const addCheckbox = () => {
    const newCheckbox = {
      id: Date.now(),
      label: `Option ${checkboxOptions.length + 1}`,
      value: `option${checkboxOptions.length + 1}`,
    };

    setCheckboxOptions([...checkboxOptions, newCheckbox]);
  };

  const setBulkInventoryHandleChange = (event) => {
    setBulkInventoryData({
      ...bulkInventoryData,
      property_id: property_id.id,
      [event.target.name]: event.target.value,
    });
  };

  const setBlockInventoryHandleChange = (event) => {
    setBlockInventoryData({
      ...blockInventoryData,
      property_id: property_id.id,
      [event.target.name]: event.target.value,
    });
  };

  const setUnblockInventoryHandleChange = (event) => {
    setUnblockInventoryData({
      ...unblockInventoryData,
      property_id: property_id.id,
      [event.target.name]: event.target.value,
    });
  };

  const setHoldInventoryHandleChange = (event) => {
    setHoldInventoryData({
      ...holdInventoryData,
      property_id: property_id.id,
      [event.target.name]: event.target.value,
    });
  };

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
  };

  const blockAddModalButtonClickHandler = () => {
    setBlockShowAddModelMenuActive(!blockShowAddModelMenuActive);
  };

  const unblockAddModalButtonClickHandler = () => {
    setUnblockShowAddModelMenuActive(!unblockShowAddModelMenuActive);
  };

  const holdAddModalButtonClickHandler = () => {
    setHoldShowAddModelMenuActive(!holdShowAddModelMenuActive);
  };

  const unholdAddModalButtonClickHandler = () => {
    setUnholdShowAddModelMenuActive(!unholdShowAddModelMenuActive);
  };

  const bulkInventoryDataUpdateHandle = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);

      const response = await fetch(
        // /api/v1/inventories/store/1
        `${baseURL}/api/v1/inventories/store/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            ...bulkInventoryData,
            room_categories: selectedValues,
          }),
        }
      );
      if (response.ok) {
        addModalButtonClickHandler();

        // Parse the JSON response
        const jsonResponse = await response.json();

        window.location.reload();
        // Reset the bulkInventoryData state
        setBulkInventoryData({
          property_id: property_id.id,
          from_date: "",
          available_rooms: "",
          update_type: "",
          room_price: "",
        });
        setLoader(false);
        // Reload the page
      } else {
        console.log("Post request failed with status:", response.status);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Ensure the loader is set to false in case of an error
      setLoader(false);
    }
  };
  const rateInventoryDataUpdateHandle = async (e) => {
    e.preventDefault();

    if (bulkInventoryData.to_date === "" || bulkInventoryData.to_date === "") {
      showErrorToast("Please fill the dates.");
      return;
    }
    try {
      setLoader(true);

      const response = await fetch(
        // /api/v1/inventories/store/1
        `${baseURL}/api/v1/inventories/store/rates/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            input_data: renderedData,
            start_date: bulkInventoryData.from_date,
            end_date: bulkInventoryData.to_date,
          }),
        }
      );
      const data = await response.json().then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setLoader(false);
          navigate(-1);
        } else {
          showErrorToast(data.message);
          setLoader(false);
        }
      });
    } catch (error) {
      console.error("An error occurred:", error);
      // Ensure the loader is set to false in case of an error
      setLoader(false);
    }
  };
  const bulkInventoryDataUpdateHandleNew = async (e) => {
    e.preventDefault();

    try {
      setLoader(true);

      const response = await fetch(
        // /api/v1/inventories/store/1
        `${baseURL}/api/v1/inventories/store/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            ...bulkInventoryData,
            room_categories: bulkInputValues,
          }),
        }
      );
      if (response.ok) {
        addModalButtonClickHandler();

        // Parse the JSON response
        const jsonResponse = await response.json();
        setInventoriesOptionShowAddModelMenuActive(false);
        window.location.reload();
        setBulkInventoryData({
          property_id: property_id.id,
          from_date: "",
          available_rooms: "",
          update_type: "",
          room_price: "",
        });
        setLoader(false);
        // Reload the page
      } else {
        console.log("Post request failed with status:", response.status);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Ensure the loader is set to false in case of an error
      setLoader(false);
    }
  };
  const blockInventoryDataUpdateHandle = async (e) => {
    e.preventDefault();
    setLoader(true);
  
    try {
      const response = await fetch(
        `${baseURL}/api/v1/inventories/block/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            ...blockInventoryData,
            room_categories: selectedValues,
          }),
        }
      );
  
      const data = await response.json();
  
      if (response.ok && data.success) {
        showSuccessToast(data.message);
        addModalButtonClickHandler();
        setBlockShowAddModelMenuActive(false);
        setShowAddModelMenuActive(false);
        fetchInfo(property_id?.id);
        setInventoriesOptionShowAddModelMenuActive(false);
      } else {
        showErrorToast(data.message || "An error occurred.");
        console.log("Post request failed with status:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    } finally {
      setLoader(false);
    }
  };

  const unblockInventoryDataUpdateHandle = async (e) => {
    e.preventDefault();

    setLoader(true);

    const response = await fetch(
      `${baseURL}/api/v1/inventories/unblock/${property_id?.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          ...unblockInventoryData,
          room_categories: selectedValues,
        }),
      }
    );
    const data = await response
      .json()
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          addModalButtonClickHandler();
          setBlockShowAddModelMenuActive(false);
          setShowAddModelMenuActive(false);
          setUnblockShowAddModelMenuActive(false);
          setLoader(false);
          fetchInfo(property_id?.id);
          setInventoriesOptionShowAddModelMenuActive(false);
        } else {
          showErrorToast(data.message);
          console.log("Post request failed with status:", response.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const holdInventoryDataUpdateHandle = async (e) => {
    e.preventDefault();

    setLoader(true);

    const response = await fetch(
      `${baseURL}/api/v1/inventories/hold/${property_id?.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          ...holdInventoryData,
          room_categories: holdInputValues,
        }),
      }
    );
    const data = await response
      .json()
      .then((data) => {
        if (data.message) {
          showSuccessToast(data.message);
          addModalButtonClickHandler();
          setBlockShowAddModelMenuActive(false);
          setShowAddModelMenuActive(false);
          setUnblockShowAddModelMenuActive(false);
          setLoader(false);
          fetchInfo(property_id?.id);
          setInventoriesOptionShowAddModelMenuActive(false);
        } else {
          showErrorToast(data.message);
          console.log("Post request failed with status:", response.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const unholdInventoryDataUpdateHandle = async (e) => {
    e.preventDefault();

    setLoader(true);

    const response = await fetch(
      `${baseURL}/api/v1/inventories/unhold/${property_id?.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          ...blockInventoryData,
          room_categories: selectedValues,
        }),
      }
    );
    const data = await response
      .json()
      .then((data) => {
        if (data.message) {
          showSuccessToast(data.message);
          addModalButtonClickHandler();
          setBlockShowAddModelMenuActive(false);
          setShowAddModelMenuActive(false);
          setUnblockShowAddModelMenuActive(false);
          setLoader(false);
          fetchInfo(property_id?.id);
          setInventoriesOptionShowAddModelMenuActive(false);
        } else {
          showErrorToast(data.message);
          console.log("Post request failed with status:", response.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const [selectedDatePickerDate, setSelectedDatePickerDate] = useState();

  const formatDate = (date) => {
    if (!date) return "";
    const year = date.getFullYear();
    const monthMonth = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed, so we add 1
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${monthMonth}-${day}`;
  };

  const CustomInput = ({ onClick }) => (
    <div onClick={onClick} className="dateRangePicker">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <g clipPath="url(#clip0_1270_814)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.625 2H11.75V1.25C11.75 0.835786 11.4142 0.5 11 0.5C10.5858 0.5 10.25 0.835786 10.25 1.25V2H5V1.25C5 0.835786 4.66421 0.5 4.25 0.5C3.83579 0.5 3.5 0.835786 3.5 1.25V2H2.375C1.775 2 1.25 2.525 1.25 3.125V14.375C1.25 14.975 1.775 15.5 2.375 15.5H13.625C14.225 15.5 14.75 14.975 14.75 14.375V3.125C14.75 2.525 14.225 2 13.625 2ZM2.75 14H13.25V6.5H2.75V14Z"
            fill="#888888"
          />
        </g>
        <defs>
          <clipPath id="clip0_1270_814">
            <rect
              width="15"
              height="15"
              fill="white"
              transform="translate(0.5 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );

  const handleDateChange = (date) => {
    setSelectedDatePickerDate(formatDate(date));
    fetchInfoWithDate(property_id.id, formatDate(date));
  };

  const handleIncrementDate = () => {
    if (selectedDatePickerDate) {
      const newDate = new Date(selectedDatePickerDate);
      newDate.setDate(newDate.getDate() + 1);
      setSelectedDatePickerDate(formatDate(newDate));
      fetchInfoWithDate(property_id.id, formatDate(newDate));
    }
  };

  const handleDecrementDate = () => {
    if (selectedDatePickerDate) {
      const newDate = new Date(selectedDatePickerDate);
      newDate.setDate(newDate.getDate() - 1);
      setSelectedDatePickerDate(formatDate(newDate));
      fetchInfoWithDate(property_id.id, formatDate(newDate));
    }
  };

  const toggleRoomAccordion = (roomIndex) => {
    if (activeIndexes.includes(roomIndex)) {
      // Room is open, so close it
      setActiveIndexes(activeIndexes.filter((i) => i !== roomIndex));
    } else {
      // Room is closed, so open it
      setActiveIndexes([...activeIndexes, roomIndex]);
    }
  };

  const [activeInputRateIndex, setActiveInputRateIndex] = useState(null);
  const [activeFeildName, setActiveFeildName] = useState(null);

  const [inputValuesRates, setInputValuesRates] = useState([]);
  const [inputValuesInventory, setInputValuesInventory] = useState([]);

  const [sendInputPostRateData, setSendInputPostRateData] = useState(null);

  const [sendInputPostInventoryData, setSendInputPostInventoryData] =
    useState(null);

  const handleInputRatesOnChange = (
    rateId,
    value,
    fullObject,
    fieldName,
    index
  ) => {
    setActiveFeildName(fieldName);

    setSendInputPostRateData({
      [fieldName]: value,
      date: fullObject.date,
      rate_plan_type: fullObject.rate_plan_type,
      room_id: fullObject.room_id,
      rate_plan_id: fullObject.mealplan_id,
    });

    const updatedInputValues = [...inputValuesRates];

    updatedInputValues[index] = {
      ...updatedInputValues[index],
      [fieldName]: value,
    };
    setInputValuesRates(updatedInputValues);
    setActiveInputRateIndex(rateId);
  };

  const [activeInputInventoryIndex, setActiveInputInventoryIndex] =
    useState(null);
  const [activeInputInventoryRoomId, setActiveInputInventoryRoomId] =
    useState(null);

  const [disableUpdate, setDisableUpdate] = useState(true);
  const handleInputInventoryOnChange = (e, roomIndex, rateIndex, field) => {
    setDisableUpdate(false);
    const updatedRoomData = [...renderedData];
    const updatedRate = { ...updatedRoomData[roomIndex].rates[rateIndex] };

    updatedRate[field] = e.target.value;

    updatedRoomData[roomIndex].rates[rateIndex] = updatedRate;
    setRenderedData(updatedRoomData);
  };

  const handleButtonRemove = () => {
    if (activeInputRateIndex !== null) {
      // const newInputData = [...inputData];
      // newInputData[activeInput] = "";
      // setInputData(newInputData);
      setActiveInputRateIndex(null);
    }
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const [{ mont, yea }, setDate] = useState({ month: 1, year: 2018 });
  const [selectedDates, setSelectedDates] = useState({
    start: new Date("Wed Feb 07 2018 00:00:00 GMT-0500 (EST)"),
    end: new Date("Sat Feb 10 2018 00:00:00 GMT-0500 (EST)"),
  });

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );

  const [activeTab, setActiveTab] = useState(5);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const navigate = useNavigate();

  const handleUpdateRatesSingleSaveHandler = () => {
    fetch(`${baseURL}/api/v1/set/rates/${property_id?.id}`, {
      method: "POST",
      body: JSON.stringify({ ...sendInputPostRateData }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          window.location.reload();
        } else if (data.error) {
          const firstErrorKey = Object.keys(data.error)[0];
          const firstErrorMessage = data.error[firstErrorKey][0];
          showErrorToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      });
  };

  const handleUpdateInventoryHandler = () => {
    fetch(`${baseURL}/api/v1/set/inventory/${property_id?.id}`, {
      method: "POST",
      body: JSON.stringify({ ...sendInputPostInventoryData }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          window.location.reload();
        } else if (data.error) {
          showErrorToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      });
  };

  return (
    <>
      <div className="main_container">
        {loader ? (
          <Loader />
        ) : (
          <>
            {renderedData && renderedData.length > 0 ? (
              <div className="room-types-full-container ">
                <div className="room-types-full-container-div">
                  <GoBackButtonCustom
                    buttonNextText={"Bulk Rate Updates"}
                    onClick={() => navigate(-1)}
                  />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginRight: "10px" }}>
                      {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "auto",
                      }}
                      className="dateRangePickerContainer"
                    >
                      <button
                        className="dateRangePickerButtonone"
                        onClick={handleDecrementDate}
                        disabled={!selectedDatePickerDate}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="7"
                          height="10"
                          viewBox="0 0 7 10"
                          fill="none"
                        >
                          <path
                            d="M6 1L2 5L6 9"
                            stroke="#888888"
                            strokeWidth="1.5"
                          />
                        </svg>
                      </button>
                      <DatePicker
                        selected={
                          selectedDatePickerDate
                            ? new Date(selectedDatePickerDate)
                            : null
                        }
                        onChange={handleDateChange}
                        dateFormat="yyyy-MM-dd"
                        customInput={<CustomInput />}
                      />
                      <button
                        onClick={handleIncrementDate}
                        disabled={!selectedDatePickerDate}
                        className="dateRangePickerButtontwo"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="7"
                          height="10"
                          viewBox="0 0 7 10"
                          fill="none"
                        >
                          <path
                            d="M1 9L5 5L0.999999 1"
                            stroke="#888888"
                            strokeWidth="1.5"
                          />
                        </svg>
                      </button>
                    </div> */}
                    </div>
                    <div>
                      {/* <Link
                      style={{ textDecoration: "none" }}
                      to={`/lowinventory`}
                    >
                      <BgThemeButton children={"Low Inventory Dates"} />
                    </Link> */}
                    </div>
                  </div>
                </div>

                <div className="inventory-full-container">
                  <div
                    style={{
                      borderBottom: "1px solid #ccc",
                      paddingBottom: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="inventory-first-row-head">
                      <div>
                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              type="date"
                              name="from_date"
                              className="textfield"
                              titleName="Start Date"
                              min={currentDate}
                              value={bulkInventoryData.from_date}
                              onChange={setBulkInventoryHandleChange}
                            />
                          </div>
                          <div style={{}} className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              type="date"
                              name="to_date"
                              className="textfield"
                              titleName="End Date"
                              focused={false}
                              onChange={setBulkInventoryHandleChange}
                              autoComplete="off"
                              min={bulkInventoryData.from_date || currentDate}
                              value={bulkInventoryData.to_date}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      {disableUpdate ? (
                        <BgThemeButtonDisabled
                          onClick={rateInventoryDataUpdateHandle}
                          type="submit"
                          children={"Update Rates"}
                        />
                      ) : (
                        <BgThemeButton
                          onClick={rateInventoryDataUpdateHandle}
                          type="submit"
                          children={"Update Rates"}
                        />
                      )}

                      <Button onClick={() => navigate("/inventory")}>
                        Cancel
                      </Button>
                    </div>
                  </div>

                  {bulkInventoryData.to_date === "" ||
                    (bulkInventoryData.from_date && (
                      <>
                        {renderedData?.map((room, roomIndex) => {
                          return (
                            <div
                              className="inventory-fourth-column-4div"
                              key={roomIndex}
                            >
                              <div
                                style={{
                                  borderRadius: "6px",
                                  border: "0.5px solid #CCC",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                    padding: "0 0 0 22px",
                                    borderBottom: "0.5px solid #CCC",
                                  }}
                                >
                                  <div
                                    style={{
                                      color: "#333",
                                      fontFamily: "Inter",
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      width: "90%",
                                    }}
                                  >
                                    <div
                                      className="inventory-third-column-3div"
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          toggleRoomAccordion(roomIndex)
                                        }
                                      >
                                        <>
                                          <div
                                            style={{
                                              display: "flex",
                                              marginRight: "7px",
                                            }}
                                          >
                                            {activeIndexes.includes(
                                              roomIndex
                                            ) ? (
                                              <>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="14"
                                                  height="14"
                                                  viewBox="0 0 14 14"
                                                  fill="none"
                                                >
                                                  <g clipPath="url(#clip0_1270_876)">
                                                    <path
                                                      d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                                      stroke="#3968ED"
                                                    />
                                                    <path
                                                      d="M10.5866 7H3.41333"
                                                      stroke="#3968ED"
                                                      strokeMiterlimit="10"
                                                    />
                                                  </g>
                                                  <defs>
                                                    <clipPath id="clip0_1270_876">
                                                      <rect
                                                        width="14"
                                                        height="14"
                                                        fill="white"
                                                      />
                                                    </clipPath>
                                                  </defs>
                                                </svg>
                                              </>
                                            ) : (
                                              <>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="14"
                                                  height="14"
                                                  viewBox="0 0 14 14"
                                                  fill="none"
                                                >
                                                  <g clipPath="url(#clip0_1270_1017)">
                                                    <path
                                                      d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                                      stroke="#3968ED"
                                                    />
                                                    <path
                                                      d="M10.5866 7H3.41333"
                                                      stroke="#3968ED"
                                                      strokeMiterlimit="10"
                                                    />
                                                    <path
                                                      d="M7 3.41344L7 10.5867"
                                                      stroke="#3968ED"
                                                      strokeMiterlimit="10"
                                                    />
                                                  </g>
                                                  <defs>
                                                    <clipPath id="clip0_1270_1017">
                                                      <rect
                                                        width="14"
                                                        height="14"
                                                        fill="white"
                                                      />
                                                    </clipPath>
                                                  </defs>
                                                </svg>
                                              </>
                                            )}
                                          </div>
                                          <div className="inventory-roomName">
                                            {room.room_name}
                                          </div>
                                        </>
                                        <></>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flexEnd",
                                          gap: "10px",
                                        }}
                                      >
                                        <div>
                                          <svg
                                            width="12"
                                            height="12"
                                            viewBox="0 0 12 12"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M11.8642 10.1393L7.15021 0.711296C7.0435 0.497583 6.87935 0.317827 6.67619 0.192189C6.47302 0.0665511 6.23887 0 6 0C5.76113 0 5.52698 0.0665511 5.32381 0.192189C5.12065 0.317827 4.9565 0.497583 4.84979 0.711296L0.135805 10.1393C0.0377532 10.3353 -0.00854859 10.5532 0.00129808 10.7721C0.0111447 10.9911 0.0768129 11.2039 0.192064 11.3904C0.307315 11.5768 0.468322 11.7307 0.659789 11.8374C0.851257 11.9441 1.06683 12.0001 1.28602 12H10.714C10.9332 12.0001 11.1487 11.9441 11.3402 11.8374C11.5317 11.7307 11.6927 11.5768 11.8079 11.3904C11.9232 11.2039 11.9889 10.9911 11.9987 10.7721C12.0085 10.5532 11.9622 10.3353 11.8642 10.1393ZM5.35718 4.28621C5.35718 4.11572 5.42491 3.95222 5.54546 3.83167C5.66601 3.71112 5.82951 3.64339 6 3.64339C6.17049 3.64339 6.33399 3.71112 6.45454 3.83167C6.57509 3.95222 6.64282 4.11572 6.64282 4.28621V6.85747C6.64282 7.02796 6.57509 7.19146 6.45454 7.31201C6.33399 7.43256 6.17049 7.50029 6 7.50029C5.82951 7.50029 5.66601 7.43256 5.54546 7.31201C5.42491 7.19146 5.35718 7.02796 5.35718 6.85747V4.28621ZM6 10.2858C5.83048 10.2858 5.66477 10.2356 5.52383 10.1414C5.38288 10.0472 5.27303 9.91334 5.20815 9.75673C5.14328 9.60012 5.12631 9.42779 5.15938 9.26153C5.19245 9.09527 5.27408 8.94255 5.39395 8.82268C5.51381 8.70282 5.66653 8.62119 5.83279 8.58812C5.99905 8.55505 6.17138 8.57202 6.32799 8.63689C6.48461 8.70176 6.61846 8.81162 6.71264 8.95256C6.80682 9.09351 6.85709 9.25922 6.85709 9.42874C6.85709 9.65605 6.76679 9.87405 6.60605 10.0348C6.44532 10.1955 6.22731 10.2858 6 10.2858Z"
                                              fill="#F2A526"
                                            ></path>
                                          </svg>
                                        </div>
                                        <div>
                                          If you have not set rates for any
                                          field, it will pick rates from
                                          previously saved rates.
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* inner div */}

                                {activeIndexes.includes(roomIndex) && (
                                  <>
                                    {room.rates.map((rate, rateIndex) => {
                                      return (
                                        <div key={rateIndex}>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              width: "100%",
                                              padding: "0 20px 0 18px",
                                              borderBottom: "0.5px solid #CCC",
                                              height: expandedItems.includes(
                                                rateIndex
                                              )
                                                ? "60px"
                                                : "100%",
                                              overflow: "hidden",
                                              transition:
                                                "height 0.5s ease-in-out",
                                            }}
                                          >
                                            <div
                                              onClick={() =>
                                                togglePlanExpand(rateIndex)
                                              }
                                              style={{
                                                color: "#333",
                                                fontFamily: "Inter",
                                                fontSize: "12px",
                                                fontWeight: "600",
                                                width: "100%",
                                              }}
                                            >
                                              <div style={{}}>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "flex-start",
                                                    alignItems: "center",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  <div className="inventory-third-column-3div">
                                                    <div>
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          marginRight: "7px",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            marginRight: "10px",
                                                          }}
                                                        >
                                                          {/* {expandedItems.includes(
                                                            rateIndex
                                                          ) ? ( */}
                                                          {/* <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              width="14"
                                                              height="14"
                                                              viewBox="0 0 14 14"
                                                              fill="none"
                                                            >
                                                              <g clipPath="url(#clip0_1270_978)">
                                                                <path
                                                                  d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                                                  stroke="#3968ED"
                                                                />
                                                                <path
                                                                  d="M10.5866 7H3.41333"
                                                                  stroke="#3968ED"
                                                                  strokeMiterlimit="10"
                                                                />
                                                                <path
                                                                  d="M7 3.41344L7 10.5867"
                                                                  stroke="#3968ED"
                                                                  strokeMiterlimit="10"
                                                                />
                                                              </g>
                                                              <defs>
                                                                <clipPath id="clip0_1270_978">
                                                                  <rect
                                                                    width="14"
                                                                    height="14"
                                                                    fill="white"
                                                                  />
                                                                </clipPath>
                                                              </defs>
                                                            </svg> */}
                                                          {/* ) : ( */}
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="14"
                                                            height="14"
                                                            viewBox="0 0 14 14"
                                                            fill="none"
                                                          >
                                                            <path
                                                              d="M10.5866 7H3.41333"
                                                              stroke="#3968ED"
                                                              strokeMiterlimit="10"
                                                            />
                                                          </svg>
                                                          {/* )} */}

                                                          {/* : <span>+</span> */}
                                                          {/* } */}
                                                        </div>
                                                        <div>
                                                          {rate.room_type_name}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div></div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {/* <div>{room.rate}</div> */}

                                            <div style={{ display: "flex" }}>
                                              <div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    flexDirection: "column",
                                                  }}
                                                ></div>
                                              </div>
                                            </div>

                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                gap: "30px",
                                              }}
                                            >
                                              <div>
                                                <div>Base Rate</div>
                                                <input
                                                  // name="base_rate"
                                                  value={rate.base_rate}
                                                  className="inventory-1st-column-inner-input"
                                                  style={{ width: "150px" }}
                                                  type="number"
                                                  onChange={(e) =>
                                                    handleInputInventoryOnChange(
                                                      e,
                                                      roomIndex,
                                                      rateIndex,
                                                      "base_rate"
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div>
                                                <div>Extra Adult</div>
                                                <input
                                                  name="extra_adult"
                                                  value={rate.extra_adult}
                                                  className="inventory-1st-column-inner-input"
                                                  style={{ width: "150" }}
                                                  type="number"
                                                  onChange={(e) =>
                                                    handleInputInventoryOnChange(
                                                      e,
                                                      roomIndex,
                                                      rateIndex,
                                                      "extra_adult"
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div>
                                                <div>Extra Child with bed</div>
                                                <input
                                                  value={
                                                    rate.extra_child_with_bed
                                                  }
                                                  className="inventory-1st-column-inner-input"
                                                  style={{ width: "150" }}
                                                  type="number"
                                                  onChange={(e) =>
                                                    handleInputInventoryOnChange(
                                                      e,
                                                      roomIndex,
                                                      rateIndex,
                                                      "extra_child_with_bed"
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div>
                                                Extra Child without bed
                                                <input
                                                  value={
                                                    rate.extra_child_without_bed
                                                  }
                                                  className="inventory-1st-column-inner-input"
                                                  style={{ width: "150" }}
                                                  type="number"
                                                  onChange={(e) =>
                                                    handleInputInventoryOnChange(
                                                      e,
                                                      roomIndex,
                                                      rateIndex,
                                                      "extra_child_without_bed"
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ))}
                </div>
              </div>
            ) : (
              <NoDataFound />
            )}
            {/* Bulk inventories */}
            {showAddModelMenuActive && (
              <>
                <div className="bg-backdrop">
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    style={{ width: "700px" }}
                    className="stock-add-model-container"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "15px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <div className="add-ota-model-container-heading">
                          Bulk Update
                        </div>
                        {/* <div style={{ marginTop: "10px" }}>
                          Bulk changes will be update for only weekdays
                        </div> */}
                      </div>
                      <div
                        onClick={() => setShowAddModelMenuActive(false)}
                        style={{ cursor: "pointer" }}
                      >
                        <Icon source={CancelMajor} color="base" />
                      </div>
                    </div>

                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <div
                          style={{
                            color: "#333",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: "600",
                            marginBottom: "10px",
                          }}
                        >
                          Select Room Categories
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "13px",
                            color: "#333",
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          {data?.rooms?.map((room) => {
                            return (
                              <>
                                <input
                                  type="checkbox"
                                  name="update_type"
                                  onChange={handleCheckboxChange}
                                  value={room.id}
                                />
                                {room.room_name}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          required={true}
                          type="date"
                          name="from_date"
                          className="textfield"
                          titleName="Start Date"
                          min={currentDate}
                          value={bulkInventoryData.from_date}
                          onChange={setBulkInventoryHandleChange}
                        />
                      </div>
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          required={true}
                          type="date"
                          name="to_date"
                          className="textfield"
                          titleName="End Date"
                          focused={false}
                          onChange={setBulkInventoryHandleChange}
                          autoComplete="off"
                          min={bulkInventoryData.from_date || currentDate}
                          value={bulkInventoryData.to_date}
                        />
                      </div>
                    </div>
                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          required={true}
                          titleName="Inventory"
                          name="inventory"
                          type="number"
                          value={bulkInventoryData.inventory}
                          onChange={setBulkInventoryHandleChange}
                        />
                      </div>
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          required={true}
                          titleName="Rates"
                          type="number"
                          name="rates"
                          value={bulkInventoryData.rates}
                          onChange={setBulkInventoryHandleChange}
                        />
                      </div>
                    </div>
                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          required={true}
                          titleName="Extra Adult"
                          name="extra_adult"
                          type="number"
                          value={bulkInventoryData.extra_adult}
                          onChange={setBulkInventoryHandleChange}
                        />
                      </div>
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          required={true}
                          titleName="Extra Child"
                          type="number"
                          name="extra_child"
                          value={bulkInventoryData.extra_child}
                          onChange={setBulkInventoryHandleChange}
                        />
                      </div>
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          required={true}
                          titleName="Extra Bed"
                          type="number"
                          name="extra_bed"
                          value={bulkInventoryData.extra_bed}
                          onChange={setBulkInventoryHandleChange}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "start",
                          gap: "10px",
                        }}
                      >
                        <BgThemeButton
                          onClick={bulkInventoryDataUpdateHandle}
                          type="submit"
                          children={"Update"}
                        />
                        <Button
                          onClick={() => setShowAddModelMenuActive(false)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
            {/* Block inventory */}
            {blockShowAddModelMenuActive && (
              <>
                <div className="bg-backdrop">
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    style={{ width: "700px" }}
                    className="stock-add-model-container"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "15px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <div className="add-ota-model-container-heading">
                          Block Update
                        </div>
                        {/* <div style={{ marginTop: "10px" }}>
                          Bulk changes will be update for only weekdays
                        </div> */}
                      </div>
                      <div
                        onClick={() => setBlockShowAddModelMenuActive(false)}
                        style={{ cursor: "pointer" }}
                      >
                        <Icon source={CancelMajor} color="base" />
                      </div>
                    </div>

                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <div
                          style={{
                            color: "#333",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: "600",
                            marginBottom: "10px",
                          }}
                        >
                          Select Room Categories
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "13px",
                            color: "#333",
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          {data?.rooms?.map((room) => {
                            return (
                              <>
                                <input
                                  type="checkbox"
                                  name="update_type"
                                  onChange={handleCheckboxChange}
                                  value={room.id}
                                />
                                {room.room_name}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          required={true}
                          type="date"
                          name="from_date"
                          className="textfield"
                          titleName="Start Date"
                          // min={currentDate}
                          value={blockInventoryData.from_date}
                          onChange={setBlockInventoryHandleChange}
                        />
                      </div>
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          required={true}
                          type="date"
                          name="to_date"
                          className="textfield"
                          titleName="End Date"
                          focused={false}
                          onChange={setBlockInventoryHandleChange}
                          autoComplete="off"
                          min={bulkInventoryData.from_date || currentDate}
                          value={blockInventoryData.to_date}
                        />
                      </div>
                    </div>
                    {/* <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Inventory"
                        name="inventory"
                        type="number"
                        value={bulkInventoryData.inventory}
                        onChange={setBulkInventoryHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Rates"
                        type="number"
                        name="rates"
                        value={bulkInventoryData.rates}
                        onChange={setBulkInventoryHandleChange}
                      />
                    </div>
                  </div> */}
                    {/* <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Extra Adult"
                        name="extra_adult"
                        type="number"
                        value={bulkInventoryData.extra_adult}
                        onChange={setBulkInventoryHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Extra Child"
                        type="number"
                        name="extra_child"
                        value={bulkInventoryData.extra_child}
                        onChange={setBulkInventoryHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Extra Bed"
                        type="number"
                        name="extra_bed"
                        value={bulkInventoryData.extra_bed}
                        onChange={setBulkInventoryHandleChange}
                      />
                    </div>
                  </div> */}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "start",
                          gap: "10px",
                        }}
                      >
                        <BgThemeButton
                          onClick={blockInventoryDataUpdateHandle}
                          type="submit"
                          children={"Update"}
                        />
                        <Button
                          onClick={() => setBlockShowAddModelMenuActive(false)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
            {/* Unblock Inventory */}
            {unblockShowAddModelMenuActive && (
              <>
                <div className="bg-backdrop">
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    style={{ width: "700px" }}
                    className="stock-add-model-container"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "15px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <div className="add-ota-model-container-heading">
                          Unblock Inventory
                        </div>
                        <div style={{ marginTop: "10px" }}></div>
                      </div>
                      <div
                        onClick={() => setUnblockShowAddModelMenuActive(false)}
                        style={{ cursor: "pointer" }}
                      >
                        <Icon source={CancelMajor} color="base" />
                      </div>
                    </div>

                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <div
                          style={{
                            color: "#333",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: "600",
                            marginBottom: "10px",
                          }}
                        >
                          Select Room Categories
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "13px",
                            color: "#333",
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          {data?.rooms?.map((room) => {
                            return (
                              <>
                                <input
                                  type="checkbox"
                                  name="update_type"
                                  onChange={handleCheckboxChange}
                                  value={room.id}
                                />
                                {room.room_name}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          required={true}
                          type="date"
                          name="from_date"
                          className="textfield"
                          titleName="Start Date"
                          // min={currentDate}
                          value={unblockInventoryData.from_date}
                          onChange={setUnblockInventoryHandleChange}
                        />
                      </div>
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          required={true}
                          type="date"
                          name="to_date"
                          className="textfield"
                          titleName="End Date"
                          focused={false}
                          onChange={setUnblockInventoryHandleChange}
                          autoComplete="off"
                          min={unblockInventoryData.from_date || currentDate}
                          value={unblockInventoryData.to_date}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "start",
                          gap: "10px",
                        }}
                      >
                        <BgThemeButton
                          onClick={unblockInventoryDataUpdateHandle}
                          type="submit"
                          children={"Update"}
                        />
                        <Button
                          onClick={() =>
                            setUnblockShowAddModelMenuActive(false)
                          }
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
            {/* Hold Inventory */}
            {holdShowAddModelMenuActive && (
              <>
                <div className="bg-backdrop">
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    style={{ width: "500px" }}
                    className="stock-add-model-container"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "15px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <div className="add-ota-model-container-heading">
                          Hold Inventory
                        </div>
                        <div style={{ marginTop: "10px" }}></div>
                      </div>
                      <div
                        onClick={() => setHoldShowAddModelMenuActive(false)}
                        style={{ cursor: "pointer" }}
                      >
                        <Icon source={CancelMajor} color="base" />
                      </div>
                    </div>

                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          required={true}
                          type="date"
                          name="from_date"
                          className="textfield"
                          titleName="Start Date"
                          // min={currentDate}
                          value={holdInventoryData.from_date}
                          onChange={setHoldInventoryHandleChange}
                        />
                      </div>
                      <div style={{}} className="form-container-div">
                        <VerticalInputFieldCustom
                          required={true}
                          type="date"
                          name="to_date"
                          className="textfield"
                          titleName="End Date"
                          focused={false}
                          onChange={setHoldInventoryHandleChange}
                          autoComplete="off"
                          min={holdInventoryData.from_date || currentDate}
                          value={holdInventoryData.to_date}
                        />
                      </div>
                    </div>

                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <div
                          style={{
                            color: "#333",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: "600",
                            marginBottom: "10px",
                          }}
                        >
                          Select Room Categories
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "13px",
                            color: "#333",
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontWeight: "400",
                            flexDirection: "column",
                          }}
                        >
                          {data?.rooms?.map((room) => {
                            const roomDataIndex = holdInputValues.findIndex(
                              (data) => data.room_id === room.id
                            );
                            return (
                              <div
                                key={room.id}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: "10px",
                                  // width:"30px"
                                  // height: "60px",
                                }}
                              >
                                <div>
                                  <input
                                    style={{ cursor: "pointer" }}
                                    id={room.id}
                                    type="checkbox"
                                    name="update_type"
                                    onChange={(e) =>
                                      holdHandleCheckboxChange(e, room)
                                    }
                                    value={room.id}
                                    checked={roomDataIndex !== -1}
                                  />
                                  <label
                                    style={{ cursor: "pointer" }}
                                    htmlFor={room.id}
                                  >
                                    {" "}
                                    {room.room_name}
                                  </label>
                                </div>

                                {roomDataIndex !== -1 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "20px",
                                      // width: "80%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "fex-start",
                                        alignItems: "center",
                                      }}
                                      className="form-container-div"
                                    >
                                      <div style={{ width: "112px" }}>
                                        Available rooms:
                                      </div>
                                      <div style={{ width: "40px" }}>
                                        <input
                                          onWheel={(e) => e.target.blur()}
                                          style={{
                                            width: "100%",
                                            padding: "2px 2px",
                                          }}
                                          type="number"
                                          name="available_rooms"
                                          className="textfield"
                                          // titleName="Available Rooms:"
                                          value={
                                            holdInputValues[roomDataIndex]
                                              .available_rooms
                                          }
                                          onChange={(e) =>
                                            handleHoldInputChange(
                                              e,
                                              roomDataIndex
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "fex-start",
                                        alignItems: "center",
                                      }}
                                      className="form-container-div"
                                    >
                                      <div style={{ marginRight: "6px" }}>
                                        Hold rooms:
                                      </div>
                                      <div style={{ width: "40px" }}>
                                        <input
                                          onWheel={(e) => e.target.blur()}
                                          style={{
                                            width: "100%",
                                            padding: "2px 2px",
                                          }}
                                          min={1}
                                          // required={true}
                                          type="number"
                                          name="hold_room"
                                          className="textfield"
                                          // titleName="Hold Rooms"
                                          value={
                                            holdInputValues[roomDataIndex]
                                              .hold_room
                                          }
                                          onChange={(e) =>
                                            handleHoldInputChange(
                                              e,
                                              roomDataIndex
                                            )
                                          }
                                        />
                                        {error && (
                                          <div style={{ color: "red" }}>
                                            {error}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "start",
                          gap: "10px",
                        }}
                      >
                        <BgThemeButton
                          onClick={() => holdInventoryDataUpdateHandle()}
                          type="submit"
                          children={"Update"}
                        />
                        <Button
                          onClick={() => setHoldShowAddModelMenuActive(false)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
            {/* Unhold inventory */}
            {unholdShowAddModelMenuActive && (
              <>
                <div className="bg-backdrop">
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    style={{ width: "700px" }}
                    className="stock-add-model-container"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "15px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <div className="add-ota-model-container-heading">
                          Unhold Inventory
                        </div>
                        <div style={{ marginTop: "10px" }}></div>
                      </div>
                      <div
                        onClick={() => setUnholdShowAddModelMenuActive(false)}
                        style={{ cursor: "pointer" }}
                      >
                        <Icon source={CancelMajor} color="base" />
                      </div>
                    </div>

                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <div
                          style={{
                            color: "#333",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: "600",
                            marginBottom: "10px",
                          }}
                        >
                          Select Room Categories
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "13px",
                            color: "#333",
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          {data?.rooms?.map((room) => {
                            return (
                              <>
                                <label
                                  style={{ cursor: "pointer" }}
                                  htmlFor={room.id}
                                >
                                  <input
                                    id={room.id}
                                    type="checkbox"
                                    name="update_type"
                                    onChange={handleCheckboxChange}
                                    value={room.id}
                                  />
                                  {room.room_name}
                                </label>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          required={true}
                          type="date"
                          name="from_date"
                          className="textfield"
                          titleName="Start Date"
                          // min={currentDate}
                          value={blockInventoryData.from_date}
                          onChange={setBlockInventoryHandleChange}
                        />
                      </div>
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          required={true}
                          type="date"
                          name="to_date"
                          className="textfield"
                          titleName="End Date"
                          focused={false}
                          onChange={setBlockInventoryHandleChange}
                          autoComplete="off"
                          min={bulkInventoryData.from_date || currentDate}
                          value={blockInventoryData.to_date}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "start",
                          gap: "10px",
                        }}
                      >
                        <BgThemeButton
                          onClick={() => unholdInventoryDataUpdateHandle()}
                          type="submit"
                          children={"Update"}
                        />
                        <Button
                          onClick={() => setUnholdShowAddModelMenuActive(false)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
            {/* New UI inventory */}
            {inventoriesOptionShowAddModelMenuActive && (
              <>
                <div className="bg-backdrop">
                  <div
                    onSubmit={(e) => e.preventDefault()}
                    style={{ width: "900px", padding: "0" }}
                    className="stock-add-model-container"
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: "1px #DDD solid",
                        padding: "20px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          justifyContent: "flex-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <BgOutlineThemeButton
                            active={activeTab === 5 ? true : false}
                            onClick={() => handleTabClick(5)}
                            children={"Bulk Inventory Updates"}
                          />
                        </div>
                        <div>
                          {permissions &&
                          permissions.includes("read_block_inventory") ? (
                            <BgOutlineThemeButton
                              active={activeTab === 4 ? true : false}
                              onClick={() => handleTabClick(4)}
                              children={"Block Inventory"}
                            />
                          ) : (
                            <Tooltip
                              content={`Please contact your admin.`}
                              dismissOnMouseOut
                            >
                              <BgThemeButtonDisabled
                                active={activeTab === 4 ? true : false}
                                onClick={() => handleTabClick(4)}
                                children={"Block Inventory"}
                              />
                            </Tooltip>
                          )}
                        </div>
                        <div>
                          {permissions &&
                          permissions.includes("read_unblock_inventory") ? (
                            <BgOutlineThemeButton
                              active={activeTab === 3 ? true : false}
                              onClick={() => handleTabClick(3)}
                              children={"Unblock Inventory"}
                            />
                          ) : (
                            <Tooltip
                              content={`Please contact your admin.`}
                              dismissOnMouseOut
                            >
                              <BgThemeButtonDisabled
                                active={activeTab === 3 ? true : false}
                                onClick={() => handleTabClick(3)}
                                children={"Unblock Inventory"}
                              />
                            </Tooltip>
                          )}
                        </div>
                        <div>
                          {permissions &&
                          permissions.includes("read_hold_inventory") ? (
                            <BgOutlineThemeButton
                              active={activeTab === 1 ? true : false}
                              onClick={() => handleTabClick(1)}
                              children={"Hold Inventory"}
                            />
                          ) : (
                            <Tooltip
                              content={`Please contact your admin.`}
                              dismissOnMouseOut
                            >
                              <BgThemeButtonDisabled
                                active={activeTab === 1 ? true : false}
                                onClick={() => handleTabClick(1)}
                                children={"Hold Inventory"}
                              />
                            </Tooltip>
                          )}
                        </div>
                        <div>
                          {permissions &&
                          permissions.includes("read_hold_inventory") ? (
                            <BgOutlineThemeButton
                              active={activeTab === 2 ? true : false}
                              onClick={() => handleTabClick(2)}
                              children={"Unhold Inventory"}
                            />
                          ) : (
                            <Tooltip
                              content={`Please contact your admin.`}
                              dismissOnMouseOut
                            >
                              <BgThemeButtonDisabled
                                active={activeTab === 2 ? true : false}
                                onClick={() => handleTabClick(2)}
                                children={"Unhold Inventory"}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                      <div>
                        <div
                          onClick={() =>
                            setInventoriesOptionShowAddModelMenuActive(false)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <Icon source={CancelMajor} color="base" />
                        </div>
                      </div>
                    </div>

                    {activeTab === 1 && (
                      <>
                        <form
                          onSubmit={(e) => holdInventoryDataUpdateHandle(e)}
                          className="inventory-options-tabs-menu"
                          style={{ width: "100%" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "15px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div className="add-ota-model-container-heading">
                                Hold Inventory
                              </div>
                              <div></div>
                            </div>
                          </div>

                          <div className="form-container-div-half">
                            <div className="form-container-div">
                              <VerticalInputFieldCustom
                                required={true}
                                type="date"
                                name="from_date"
                                className="textfield"
                                titleName="Start Date"
                                // min={currentDate}
                                value={holdInventoryData.from_date}
                                onChange={setHoldInventoryHandleChange}
                              />
                            </div>
                            <div style={{}} className="form-container-div">
                              <VerticalInputFieldCustom
                                required={true}
                                type="date"
                                name="to_date"
                                className="textfield"
                                titleName="End Date"
                                focused={false}
                                onChange={setHoldInventoryHandleChange}
                                autoComplete="off"
                                min={holdInventoryData.from_date || currentDate}
                                value={holdInventoryData.to_date}
                              />
                            </div>
                          </div>

                          <div className="form-container-div-half">
                            <div className="form-container-div">
                              <div
                                style={{
                                  color: "#333",
                                  fontFamily: "Inter",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  marginBottom: "10px",
                                }}
                              >
                                Select Room Categories
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  gap: "13px",
                                  color: "#333",
                                  fontFamily: "Inter",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  flexDirection: "column",
                                }}
                              >
                                {data?.rooms?.map((room) => {
                                  const roomDataIndex =
                                    holdInputValues.findIndex(
                                      (data) => data.room_id === room.id
                                    );
                                  return (
                                    <div
                                      key={room.id}
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        gap: "10px",
                                        // width:"30px"
                                        // height: "60px",
                                      }}
                                    >
                                      <div>
                                        <input
                                          style={{ cursor: "pointer" }}
                                          id={room.id}
                                          type="checkbox"
                                          name="update_type"
                                          onChange={(e) =>
                                            holdHandleCheckboxChange(e, room)
                                          }
                                          value={room.id}
                                          checked={roomDataIndex !== -1}
                                        />
                                        <label
                                          style={{ cursor: "pointer" }}
                                          htmlFor={room.id}
                                        >
                                          {" "}
                                          {room.room_name}
                                        </label>
                                      </div>

                                      {roomDataIndex !== -1 && (
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: "20px",
                                            // width: "80%",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "fex-start",
                                              alignItems: "center",
                                            }}
                                            className="form-container-div"
                                          >
                                            <div style={{ width: "112px" }}>
                                              Available rooms:
                                            </div>
                                            <div style={{ width: "40px" }}>
                                              <input
                                                onWheel={(e) => e.target.blur()}
                                                style={{
                                                  width: "100%",
                                                  padding: "2px 2px",
                                                }}
                                                type="number"
                                                name="available_rooms"
                                                className="textfield"
                                                // titleName="Available Rooms:"
                                                value={
                                                  holdInputValues[roomDataIndex]
                                                    .available_rooms
                                                }
                                                disabled
                                                onChange={(e) =>
                                                  handleHoldInputChange(
                                                    e,
                                                    roomDataIndex
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "fex-start",
                                              alignItems: "center",
                                            }}
                                            className="form-container-div"
                                          >
                                            <div style={{ marginRight: "6px" }}>
                                              Hold rooms:
                                            </div>
                                            <div style={{ width: "40px" }}>
                                              <input
                                                onWheel={(e) => e.target.blur()}
                                                style={{
                                                  width: "100%",
                                                  padding: "2px 2px",
                                                }}
                                                min={1}
                                                // required={true}
                                                type="number"
                                                name="hold_room"
                                                className="textfield"
                                                // titleName="Hold Rooms"
                                                value={
                                                  holdInputValues[roomDataIndex]
                                                    .hold_room
                                                }
                                                onChange={(e) =>
                                                  handleHoldInputChange(
                                                    e,
                                                    roomDataIndex
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "start",
                                gap: "10px",
                              }}
                            >
                              {permissions &&
                              permissions.includes("update_hold_inventory") ? (
                                <BgThemeButton
                                  type="submit"
                                  children={"Update"}
                                />
                              ) : (
                                <Tooltip
                                  content={`Please contact your admin.`}
                                  dismissOnMouseOut
                                >
                                  <BgThemeButtonDisabled
                                    type="submit"
                                    children={"Update"}
                                  />
                                </Tooltip>
                              )}

                              <Button
                                onClick={() =>
                                  setInventoriesOptionShowAddModelMenuActive(
                                    false
                                  )
                                }
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </form>
                      </>
                    )}
                    {activeTab === 2 && (
                      <form
                        onSubmit={(e) => unholdInventoryDataUpdateHandle(e)}
                        style={{ width: "100%" }}
                        className="inventory-options-tabs-menu"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "15px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <div className="add-ota-model-container-heading">
                              Unhold Inventory
                            </div>
                            <div style={{ marginTop: "10px" }}></div>
                          </div>
                        </div>

                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <div
                              style={{
                                color: "#333",
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontWeight: "600",
                                marginBottom: "10px",
                              }}
                            >
                              Select Room Categories
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "13px",
                                color: "#333",
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              {data?.rooms?.map((room) => {
                                return (
                                  <>
                                    <label
                                      style={{ cursor: "pointer" }}
                                      htmlFor={room.id}
                                    >
                                      {" "}
                                    </label>
                                    <input
                                      id={room.id}
                                      type="checkbox"
                                      name="update_type"
                                      onChange={handleCheckboxChange}
                                      value={room.id}
                                    />
                                    {room.room_name}
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>

                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              type="date"
                              name="from_date"
                              className="textfield"
                              titleName="Start Date"
                              // min={currentDate}
                              value={blockInventoryData.from_date}
                              onChange={setBlockInventoryHandleChange}
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              type="date"
                              name="to_date"
                              className="textfield"
                              titleName="End Date"
                              focused={false}
                              onChange={setBlockInventoryHandleChange}
                              autoComplete="off"
                              min={bulkInventoryData.from_date || currentDate}
                              value={blockInventoryData.to_date}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "start",
                              gap: "10px",
                            }}
                          >
                            {permissions &&
                            permissions.includes("update_unhold_inventory") ? (
                              <BgThemeButton
                                type="submit"
                                children={"Update"}
                              />
                            ) : (
                              <Tooltip
                                content={`Please contact your admin.`}
                                dismissOnMouseOut
                              >
                                <BgThemeButtonDisabled
                                  type="submit"
                                  children={"Update"}
                                />
                              </Tooltip>
                            )}

                            <Button
                              onClick={() =>
                                setInventoriesOptionShowAddModelMenuActive(
                                  false
                                )
                              }
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </form>
                    )}
                    {activeTab === 3 && (
                      <>
                        <form
                          onSubmit={(e) => unblockInventoryDataUpdateHandle(e)}
                          style={{ width: "100%" }}
                          className="inventory-options-tabs-menu"
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "15px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div className="add-ota-model-container-heading">
                                Unblock Inventory
                              </div>
                              <div style={{ marginTop: "10px" }}></div>
                            </div>
                          </div>

                          <div className="form-container-div-half">
                            <div className="form-container-div">
                              <div
                                style={{
                                  color: "#333",
                                  fontFamily: "Inter",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  marginBottom: "10px",
                                }}
                              >
                                Select Room Categories
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  gap: "13px",
                                  color: "#333",
                                  fontFamily: "Inter",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                {data?.rooms?.map((room) => {
                                  return (
                                    <>
                                      <input
                                        type="checkbox"
                                        name="update_type"
                                        onChange={handleCheckboxChange}
                                        value={room.id}
                                      />
                                      {room.room_name}
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>

                          <div className="form-container-div-half">
                            <div className="form-container-div">
                              <VerticalInputFieldCustom
                                required={true}
                                type="date"
                                name="from_date"
                                className="textfield"
                                titleName="Start Date"
                                // min={currentDate}
                                value={unblockInventoryData.from_date}
                                onChange={setUnblockInventoryHandleChange}
                              />
                            </div>
                            <div className="form-container-div">
                              <VerticalInputFieldCustom
                                required={true}
                                type="date"
                                name="to_date"
                                className="textfield"
                                titleName="End Date"
                                focused={false}
                                onChange={setUnblockInventoryHandleChange}
                                autoComplete="off"
                                min={
                                  unblockInventoryData.from_date || currentDate
                                }
                                value={unblockInventoryData.to_date}
                              />
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "start",
                                gap: "10px",
                              }}
                            >
                              {permissions &&
                              permissions.includes(
                                "update_unblock_inventory"
                              ) ? (
                                <BgThemeButton
                                  type="submit"
                                  children={"Update"}
                                />
                              ) : (
                                <Tooltip
                                  content={`Please contact your admin.`}
                                  dismissOnMouseOut
                                >
                                  <BgThemeButtonDisabled
                                    type="submit"
                                    children={"Update"}
                                  />
                                </Tooltip>
                              )}

                              <Button
                                onClick={() =>
                                  setInventoriesOptionShowAddModelMenuActive(
                                    false
                                  )
                                }
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </form>
                      </>
                    )}
                    {activeTab === 4 && (
                      <>
                        <form
                          onSubmit={(e) => blockInventoryDataUpdateHandle(e)}
                          style={{ width: "100%" }}
                          className="inventory-options-tabs-menu"
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "15px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div className="add-ota-model-container-heading">
                                Block Update
                              </div>
                              {/* <div style={{ marginTop: "10px" }}>
                                Bulk changes will be update for only weekdays
                              </div> */}
                            </div>
                          </div>

                          <div className="form-container-div-half">
                            <div className="form-container-div">
                              <div
                                style={{
                                  color: "#333",
                                  fontFamily: "Inter",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  marginBottom: "10px",
                                }}
                              >
                                Select Room Categories
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  gap: "13px",
                                  color: "#333",
                                  fontFamily: "Inter",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                {data?.rooms?.map((room) => {
                                  return (
                                    <>
                                      <input
                                        type="checkbox"
                                        name="update_type"
                                        onChange={handleCheckboxChange}
                                        value={room.id}
                                      />
                                      {room.room_name}
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>

                          <div className="form-container-div-half">
                            <div className="form-container-div">
                              <VerticalInputFieldCustom
                                required={true}
                                type="date"
                                name="from_date"
                                className="textfield"
                                titleName="Start Date"
                                // min={currentDate}
                                value={blockInventoryData.from_date}
                                onChange={setBlockInventoryHandleChange}
                              />
                            </div>
                            <div className="form-container-div">
                              <VerticalInputFieldCustom
                                required={true}
                                type="date"
                                name="to_date"
                                className="textfield"
                                titleName="End Date"
                                focused={false}
                                onChange={setBlockInventoryHandleChange}
                                autoComplete="off"
                                min={bulkInventoryData.from_date || currentDate}
                                value={blockInventoryData.to_date}
                              />
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "start",
                                gap: "10px",
                              }}
                            >
                              {permissions &&
                              permissions.includes("update_block_inventory") ? (
                                <BgThemeButton
                                  type="submit"
                                  children={"Update"}
                                />
                              ) : (
                                <Tooltip
                                  content={`Please contact your admin.`}
                                  dismissOnMouseOut
                                >
                                  <BgThemeButtonDisabled
                                    type="submit"
                                    children={"Update"}
                                  />
                                </Tooltip>
                              )}

                              <Button
                                onClick={() =>
                                  setInventoriesOptionShowAddModelMenuActive(
                                    false
                                  )
                                }
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </form>
                      </>
                    )}
                    {/* {activeTab === 5 && (
                    <>
                      <form
                        onSubmit={(e) => bulkInventoryDataUpdateHandle(e)}
                        style={{ width: "100%" }}
                        className="inventory-options-tabs-menu"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "15px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <div className="add-ota-model-container-heading">
                              Bulk Update
                            </div>
                            <div style={{ marginTop: "10px" }}>
                              Bulk changes will be update for only weekdays
                            </div>
                          </div>
                        </div>

                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <div
                              style={{
                                color: "#333",
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontWeight: "600",
                                marginBottom: "10px",
                              }}
                            >
                              Select Room Categories
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "13px",
                                color: "#333",
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              {data?.rooms?.map((room) => {
                                return (
                                  <>
                                    <input
                                      type="checkbox"
                                      name="update_type"
                                      onChange={handleCheckboxChange}
                                      value={room.id}
                                    />
                                    {room.room_name}
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>

                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              type="date"
                              name="from_date"
                              className="textfield"
                              titleName="Start Date"
                              // min={currentDate}
                              value={bulkInventoryData.from_date}
                              onChange={setBulkInventoryHandleChange}
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              type="date"
                              name="to_date"
                              className="textfield"
                              titleName="End Date"
                              focused={false}
                              onChange={setBulkInventoryHandleChange}
                              autoComplete="off"
                              min={bulkInventoryData.from_date || currentDate}
                              value={bulkInventoryData.to_date}
                            />
                          </div>
                        </div>
                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              titleName="Inventory"
                              name="inventory"
                              type="number"
                              value={bulkInventoryData.inventory}
                              onChange={setBulkInventoryHandleChange}
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              titleName="Rates"
                              type="number"
                              name="rates"
                              value={bulkInventoryData.rates}
                              onChange={setBulkInventoryHandleChange}
                            />
                          </div>
                        </div>
                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              titleName="Extra Adult"
                              name="extra_adult"
                              type="number"
                              value={bulkInventoryData.extra_adult}
                              onChange={setBulkInventoryHandleChange}
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              titleName="Extra Child"
                              type="number"
                              name="extra_child"
                              value={bulkInventoryData.extra_child}
                              onChange={setBulkInventoryHandleChange}
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              titleName="Extra Bed"
                              type="number"
                              name="extra_bed"
                              value={bulkInventoryData.extra_bed}
                              onChange={setBulkInventoryHandleChange}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "start",
                              gap: "10px",
                            }}
                          >
                            <BgThemeButton type="submit" children={"Update"} />
                            <Button
                              onClick={() =>
                                setInventoriesOptionShowAddModelMenuActive(
                                  false
                                )
                              }
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </form>
                    </>
                  )} */}

                    {activeTab === 5 && (
                      <>
                        <form
                          onSubmit={(e) => bulkInventoryDataUpdateHandleNew(e)}
                          className="inventory-options-tabs-menu"
                          style={{ width: "100%" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "15px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div className="add-ota-model-container-heading">
                                Bulk Inventory Updates
                              </div>
                              <div>
                                Bulk Inventory changes will be update for only
                                weekdays
                              </div>
                            </div>
                          </div>

                          <div className="form-container-div-half">
                            <div className="form-container-div">
                              <VerticalInputFieldCustom
                                required={true}
                                type="date"
                                name="from_date"
                                className="textfield"
                                titleName="Start Date"
                                // min={currentDate}
                                value={bulkInventoryData.from_date}
                                onChange={setBulkInventoryHandleChange}
                              />
                            </div>
                            <div style={{}} className="form-container-div">
                              <VerticalInputFieldCustom
                                required={true}
                                type="date"
                                name="to_date"
                                className="textfield"
                                titleName="End Date"
                                focused={false}
                                onChange={setBulkInventoryHandleChange}
                                autoComplete="off"
                                min={bulkInventoryData.from_date || currentDate}
                                value={bulkInventoryData.to_date}
                              />
                            </div>
                          </div>

                          <div className="form-container-div-half">
                            <div className="form-container-div">
                              <div
                                style={{
                                  color: "#333",
                                  fontFamily: "Inter",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  marginBottom: "10px",
                                }}
                              >
                                Select Room Categories
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  gap: "13px",
                                  color: "#333",
                                  fontFamily: "Inter",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  flexDirection: "column",
                                }}
                              >
                                {data?.rooms?.map((room) => {
                                  const roomDataIndex =
                                    bulkInputValues.findIndex(
                                      (data) => data.room_id === room.id
                                    );
                                  return (
                                    <div
                                      key={room.id}
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <div>
                                        <input
                                          style={{
                                            cursor: "pointer",
                                            marginRight: "5px",
                                          }}
                                          id={room.id}
                                          type="checkbox"
                                          name="update_type"
                                          onChange={(e) =>
                                            bulkHandleCheckboxChange(e, room)
                                          }
                                          value={room.id}
                                          checked={roomDataIndex !== -1}
                                        />
                                        <label
                                          style={{ cursor: "pointer" }}
                                          htmlFor={room.id}
                                        >
                                          {room.room_name}
                                        </label>
                                      </div>

                                      {roomDataIndex !== -1 && (
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: "20px",
                                            // width: "80%",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "fex-start",
                                              alignItems: "center",
                                            }}
                                            className="form-container-div"
                                          >
                                            <div
                                              style={{ width: "max-content" }}
                                            >
                                              {" "}
                                              Available Rooms:
                                            </div>
                                            <div style={{ width: "40px" }}>
                                              <input
                                                disabled
                                                onWheel={(e) => e.target.blur()}
                                                style={{
                                                  width: "100%",
                                                  padding: "2px 2px",
                                                }}
                                                type="number"
                                                name="available_rooms"
                                                className="textfield"
                                                value={
                                                  bulkInputValues[roomDataIndex]
                                                    .available_rooms
                                                }
                                                onChange={(e) =>
                                                  handleBulkInputChange(
                                                    e,
                                                    roomDataIndex
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "fex-start",
                                              alignItems: "center",
                                            }}
                                            className="form-container-div"
                                          >
                                            <div style={{ width: "70px" }}>
                                              Inventory:
                                            </div>
                                            <div style={{ width: "40px" }}>
                                              <input
                                                onWheel={(e) => e.target.blur()}
                                                style={{
                                                  width: "100%",
                                                  padding: "2px 2px",
                                                }}
                                                type="number"
                                                name="inventory"
                                                className="textfield"
                                                // titleName="Available Rooms:"
                                                value={
                                                  bulkInputValues[roomDataIndex]
                                                    .inventory
                                                }
                                                onChange={(e) =>
                                                  handleBulkInputChange(
                                                    e,
                                                    roomDataIndex
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "start",
                                gap: "10px",
                              }}
                            >
                              {permissions &&
                              permissions.includes("update_bulk_inventory") ? (
                                <BgThemeButton
                                  type="submit"
                                  children={"Update"}
                                />
                              ) : (
                                <Tooltip
                                  content={`Please contact your admin.`}
                                  dismissOnMouseOut
                                >
                                  <BgThemeButtonDisabled
                                    type="submit"
                                    children={"Update"}
                                  />
                                </Tooltip>
                              )}

                              <Button
                                onClick={() =>
                                  setInventoriesOptionShowAddModelMenuActive(
                                    false
                                  )
                                }
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </form>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
            ~
          </>
        )}
      </div>
    </>
  );
};

export default RateInventoryBulkUpdate;
