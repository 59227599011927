import React, { useEffect, useRef, useState } from "react";
import "./AgentHoldRoomsSliderSideBar.css";
import { BgOutlineThemeButton, BgThemeButton } from "../../UI/Buttons";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DateRangePickerNew from "../CreateBookingNewPage/DateRangePickerNew/DateRangePickerNew";
import { formatDateYYYYMMDD, getCurrentPropertyDateHandler } from "../../../utils/utils";

import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import styles from "./agentSliderMoreOptions.module.scss";
import ConfirmPopup from "../../NewCustomComp/confirmPopup/ConfirmPopup";
import DateRangePicker from "../../NewCustomComp/DateRangePicker/DateRangePicker";
import { getDecryptedData } from "../../../utils/encryptStorage";

const AgentContractRoomsSidebarContent = ({
  singleAgentData,
  isOpen,
  toggleSidebar,
  propertyCurrentDate,
  setShow,
  fetchHoldRoomsListing,
}) => {
  const navigate = useNavigate();
 console.log("singleAgentData", singleAgentData);
  const userToken = getDecryptedData('encryptToken');
  const property_id = useSelector((state) => state.property.value);
  const [roomCategories, setRoomCategories] = useState([]);
  const [dates,setDates]= useState({
    startDate:"",
    endDate:""
  })

  const fetchRoomCategories = async () => {
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${property_id?.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    ).then((response) => response.json());
  };

  useEffect(() => {
    fetchRoomCategories().then((data) => {
      setRoomCategories(data.rooms);
    });
  }, []);

  const [roomInsightData, setRoomInsightData] = useState([]);
  const [roomId, setRoomId] = useState("");
  const handleSelectChange = (event) => {
    setRoomId(event.target.value);
  };

  const fetchRoomWiseData = async (from_date, to_date, roomId, agentContractId) => {
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/agent/contract/insights/${property_id.id}?from_date=${from_date}&to_date=${to_date}&room_id=${roomId}&agentContractId=${agentContractId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    ).then((response) => response.json());
  };

  const currentDate = new Date();
  const nextDate = new Date(currentDate);
  nextDate.setDate(currentDate.getDate() + 1);

  const formattedCurrentDate = formatDateYYYYMMDD(currentDate, "yyyy-MM-dd");
  const formattedNextDate = formatDateYYYYMMDD(nextDate, "yyyy-MM-dd");

  const handleDateRangeChange = (from_date, to_date) => {
    if (!roomId) {
      showErrorToast("please select room type");
      return;
    }
    if (from_date && to_date) {
      fetchRoomWiseData(from_date, to_date, roomId, singleAgentData?.id).then((data) => {
        setRoomInsightData(data?.data);
      });
    }
  };
  useEffect(() => {
    if (dates.startDate && dates.endDate && roomId) {
      fetchRoomWiseData(dates.startDate, dates.endDate, roomId, singleAgentData?.id).then((data) => {
        setRoomInsightData(data?.data);
      });
    }
  }, [dates.setDate, dates.endDate, roomId]);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const moreOptionsButtonRef = useRef(null);
  const moreOptionsContainerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        moreOptionsContainerRef.current &&
        !moreOptionsContainerRef.current.contains(event.target)
      ) {
        setShowMoreOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [moreOptionsContainerRef]);

  const handleDeleteClick = (id) => {
    setDeleteId(id);
  };

  const handleContractsDelete = async () => {
    // setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/delete/agent/contract/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          method: "DELETE",
          body: JSON.stringify({
            id: deleteId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch current date from the API");
      }
      const data = await response.json();
      setShow(false);
      showSuccessToast(data.message);
      fetchHoldRoomsListing("dayTypeFilter=+90");
    } catch (error) {
      console.error(error);
      showErrorToast(
        error.message || "An error occurred while processing your request."
      );
      return "Error fetching data";
    } finally {
      setDeleteId("");
    }
  };
  const [deleteId, setDeleteId] = useState(null);


  return (
    <>
      <div>
        <div className="agentSidebarContentContainer flex flex_gap_10">
          <BgThemeButton
            children={"Create Booking"}
            onClick={() =>
              navigate(`/agent-contracts/create-booking/${singleAgentData.id}`)
            }
          />
          <BgOutlineThemeButton
            children={"Delete"}
            onClick={() => handleDeleteClick(singleAgentData.id)}
            style={{ border: "1px solid red", color: "red" }}
          />
        </div>
        {/* Agent Details */}
        <div>
          <div
            className="agentSidebarContentContainer agentHoldRoomsSidebarHeadingSmall flex flex_gap_10"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            Contract Details
          </div>
          <div className="agentSidebarContentContainer flex justify_content_between">
            <div>
              <h6 className="agentHoldRoomsSidebarHeadingSmall">Start Data</h6>
              <p>{singleAgentData.from_date || "NA"}</p>
            </div>
            <div>
              <h6 className="agentHoldRoomsSidebarHeadingSmall"> End Date </h6>
              <p>{singleAgentData.to_date || "NA"}</p>
            </div>
            <div>
              <h6 className="agentHoldRoomsSidebarHeadingSmall">
                Total Hold Rooms
              </h6>
              <p>{singleAgentData.total_hold_rooms || "NA"}</p>
            </div>
          </div>
        </div>

        {/* Room Categories */}

        <div>
          <div
            className="agentSidebarContentContainer agentHoldRoomsSidebarHeadingSmall flex flex_gap_10"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            Room Categories
          </div>

          <div className="agentSidebarContentContainer ">
            {singleAgentData &&
              singleAgentData?.roomDetails?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex justify_content_between"
                    style={{ padding: "5px 0", borderBottom: "1px solid #333" }}
                  >
                    <span className="agentHoldRoomsSidebarHeadingSmall">
                      {item.room_name}
                    </span>
                    <span>{item.hold_rooms}</span>
                  </div>
                );
              })}
          </div>
        </div>

        {/* release restrictions */}

        {singleAgentData?.release_label && (
          <div>
            <div
              className="agentSidebarContentContainer agentHoldRoomsSidebarHeadingSmall flex flex_gap_10"
              style={{ backgroundColor: "#f5f5f5" }}
            >
              Auto-Release Restriction
            </div>
            <div className="agentSidebarContentContainer ">
              <h5>Release Restrictions : </h5>
            </div>
            <div className="agentSidebarContentContainer ">
              {singleAgentData &&
                singleAgentData?.release_label.map((item, index) => {
                  const percent = singleAgentData?.release_percent[index];

                  return (
                    <div
                      key={index}
                      className="flex justify_content_between"
                      style={{
                        padding: "5px 0",
                        borderBottom: "1px solid #333",
                      }}
                    >
                      <span className="agentHoldRoomsSidebarHeadingSmall">
                        {item}
                      </span>
                      <span>{percent} %</span>
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        {/*Rooms insight */}

        <div>
          <div className="agentSidebarContentContainer ">
            <div className="agentHoldBookingSliderContractInsight">
              <div className="flex justify_content_between align_center">
                <h4 className="agentHoldRoomsSidebarHeadingSmall    ">
                  Room Insights
                </h4>
                <div
                  className="flex flex_gap_10 align_center"
                  style={{ width: "65%" }}
                >
                  <div style={{ width: "40%" }}>
                    <select
                      className="agentHoldBookingSliderContractInsightSelect"
                      value={roomId}
                      onChange={handleSelectChange}
                    >
                      <option value={""}>select</option>
                      {roomCategories.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.room_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div style={{ width: "60%" }}>
                    <DateRangePicker
                      onChange={handleDateRangeChange}
                      size={"small"}
                      minDate={new Date(propertyCurrentDate)}
                      disabled={!roomId}
                    />
                  </div>
                </div>
              </div>
              <div
                className="flex justify_content_between align_center"
                style={{ margin: "5px 0" }}
              >
                <span>Hold Rooms</span>
                <span>{roomInsightData[0]?.hold_rooms || "NA"}</span>
              </div>
              <div className="flex justify_content_between align_center">
                <span>Converted </span>
                <span>{roomInsightData[0]?.converted || "NA"}</span>
              </div>
              <div
                className="flex justify_content_between align_center"
                style={{ margin: "5px 0" }}
              >
                <span>Not Used</span>
                <span>{roomInsightData[0]?.no_used || "NA"}</span>
              </div>
              <div
                className="flex justify_content_between align_center"
                style={{ margin: "5px 0" }}
              >
                <span>Remaining</span>
                <span>{roomInsightData[0]?.remaining || "NA"}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {deleteId && (
        <ConfirmPopup
          heading={"Are you sure ?"}
          subHeading={"Do you want to delete ?"}
          showPopup={deleteId}
          setShowPopup={setDeleteId}
          handleConfirmClick={handleContractsDelete}
        />
      )}
    </>
  );
};

export default AgentContractRoomsSidebarContent;
