import React, { useState } from 'react';
import './CommunicationMediaSlider.css';

const CommunicationMediaSlider = ({ mediaData }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? mediaData.length - 1 : prevSlide - 1));
  };

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === mediaData.length - 1 ? 0 : prevSlide + 1));
  };

  return (
    <div className="carousel">
      <button className="carousel__button carousel__button--left" onClick={goToPreviousSlide}>
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#fff">
          <path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z"></path>
        </svg>
      </button>

      <div className="carousel__track-container">
        <ul className="carousel__track" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
          {mediaData.map((item, index) => (
            <li key={index} className={`carousel__slide ${index === currentSlide ? 'current-slide' : ''}`}>
              <img style={{display: 'block',    maxWidth: '60%',maxHeight: '80%',     margin: "60px auto"}} src={item.src} alt={`Slide ${index}`} />
            </li>
          ))}
        </ul>
      </div>

      <button className="carousel__button carousel__button--right" onClick={goToNextSlide}>
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#fff">
          <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"></path>
        </svg>
      </button>

      <div className="carousel__nav">
        {mediaData.map((_, index) => (
          <button
            key={index}
            className={`carousel__indicator ${index === currentSlide ? 'current-slide' : ''}`}
            onClick={() => setCurrentSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default CommunicationMediaSlider;
