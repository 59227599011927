import React from "react";
import styles from "./recentBookings.module.scss"
// import "./RecentBookings.css"; 


const RecentBookings = () => {
  // Sample booking data array
  const bookings = [
    {
      id: "#12345",
      name: "John Smith",
      date: "14 Aug 2024 - 15 Aug 2024",
      phone: "+91-8368937055",
      platform: "MakeMyTrip",
      amount: "₹ 4,000",
    },
    {
      id: "#12346",
      name: "Jane Doe",
      date: "16 Aug 2024 - 17 Aug 2024",
      phone: "+91-9876543210",
      platform: "MakeMyTrip",
      amount: "₹ 5,000",
    },
    {
      id: "#12347",
      name: "Alice Brown",
      date: "18 Aug 2024 - 19 Aug 2024",
      phone: "+91-1234567890",
      platform: "MakeMyTrip",
      amount: "₹ 4,500",
    },
  ];

  return (
    <div className={styles["recent-bookings"]}>
      <h2>Recent Bookings</h2>
      {bookings.map((booking) => (
        <div className={styles["booking-card"]} key={booking.id}>
          <div className={styles["booking-details"]}>
            <a href="#" className={styles["booking-id"]}>
              {booking.id}
            </a>
            <p>{booking.name}</p>
            <p>{booking.date}</p>
            <p>{booking.phone}</p>
          </div>
          <div className={styles["booking-platform"]}>
            <a href="#" className={styles["platform-link"]}>
              {booking.platform}
            </a>
            <p className={styles["booking-amount"]}>{booking.amount}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RecentBookings;