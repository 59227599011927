import { useState, useEffect } from "react";
import styles from "./CustomRadio.module.scss";
import { CheckWithoutBgUnderlineBtn } from "../../UI/Buttons";

const CustomRadio = ({
    title,
    customRadioData,
    getSelectedCustomRadioDataFromChild,
    defaultSelect,
    clearAllFilters,
    size
}) => {
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([defaultSelect]);

    useEffect(() => {
        getSelectedCustomRadioDataFromChild(selectedCheckboxes);
    }, [selectedCheckboxes, getSelectedCustomRadioDataFromChild]);

    const handleCheckboxChange = (value) => {
        if (selectedCheckboxes.includes(value)) {
            setSelectedCheckboxes(selectedCheckboxes.filter((item) => item !== value));
        } else {
            setSelectedCheckboxes([value]);
        }
    };

    return (
        <div className={styles.custom_radio_cont}>
            <div className={styles.custom_radio_cont_main}>
                <div className="flex justify_content_between mb-10">
                    <h2 className={`${styles.custom_radio_title} ${styles[size]}`}>{title}</h2>
                    <CheckWithoutBgUnderlineBtn
                        type="button"
                        style={{ fontSize: "11px" }}
                        onClick={() => setSelectedCheckboxes([])}
                    >
                        Clear All
                    </CheckWithoutBgUnderlineBtn>
                </div>
                {customRadioData.map((item, index) => (
                    <div key={index}>
                        <label className={`${styles.custom_radio_label} ${styles[size]} ${item.disabled ? styles.disabled_radio_input : ''}`}
                        >
                            <input
                                type="radio"
                                value={item.value}
                                checked={selectedCheckboxes[0] === item.value}
                                onChange={() => handleCheckboxChange(item.value)}
                                className={`${styles.custom_radio_input} ${styles[size]}`}
                                disabled={item.disabled}
                            />
                            <span className={`${styles.custom_radio_circle} ${styles[size]}`}></span>
                            {item.label}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CustomRadio;
