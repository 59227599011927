import React ,{useEffect,useCallback,useRef,useState}from "react";
import styles from "./infiniteLoading.module.scss";

const InfiniteLoading = ({ children,fetchInfo ,nextPageUrl,loaderComponent}) => {
const [isFetching, setIsFetching] = useState(false);


  const loaderRef = useRef(null);

  const fetchMoreItems = useCallback(async () => {
    if (!nextPageUrl || isFetching) return;
    setIsFetching(true);
    await fetchInfo(nextPageUrl);
    setIsFetching(false);
  }, [nextPageUrl, isFetching, fetchInfo]);

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        fetchMoreItems();
      }
    },
    [fetchMoreItems]
  );
  useEffect(() => {
    const currentLoader = loaderRef.current;

    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    });

    if (currentLoader) {
      observer.observe(currentLoader);
    }

    return () => {
      if (currentLoader) {
        observer.unobserve(currentLoader);
      }
    };
  }, [handleObserver]);

  return (
    <div>
      <div>{children}</div>

      <div ref={loaderRef}>
        {isFetching && (
          <>
           {
            loaderComponent ? loaderComponent : <div className={styles.loader_wrap}>
            <div className={styles.loader_infinite_scrolling}>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
           }
          </>
        )}
      </div>
    </div>
  );
};

export default InfiniteLoading;
