import React from "react";
import styles from "./stepIndicator.module.scss";

const faCheck = (
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.95834 11L0.208344 6.24998L1.39584 5.06247L4.95834 8.62498L12.6042 0.979141L13.7917 2.16664L4.95834 11Z"
      fill="#3968ED"
    />
  </svg>
);

const faTimes = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4818 1.69669L12.3033 0.518175L7 5.82148L1.6967 0.518175L0.518188 1.69669L5.82149 6.99999L0.518188 12.3033L1.6967 13.4818L7 8.1785L12.3033 13.4818L13.4818 12.3033L8.17851 6.99999L13.4818 1.69669Z"
      fill="#ED3939"
    />
  </svg>
);

// Step component for individual steps
const Step = ({ label, description, status, stepNumber }) => {
  // Function to return the icon based on status
  const getStatusIcon = () => {
    switch (status) {
      case "finished":
        return faCheck;
      case "error":
        return faTimes;
      case "current":
        return stepNumber;
      case "wait":
        return stepNumber;
      default:
        return null;
    }
  };

  const getColors = () => {
    switch (status) {
      case "finished":
        return {
          backgroundColor: "#DAE3FC",
          text: "",
        };
      case "error":
        return {
          backgroundColor: "#FCDADA",
          text: "",
        };
      case "current":
        return {
          backgroundColor: "#3968ED",
          text: "#fff",
        };
      case "wait":
        return {
          backgroundColor: "#D6D6D6",
          text: "#858585",
        };
      default:
        return null;
    }
  };

  return (
    <div className={styles.step}>
      <div
        className={styles["step-icon"]}
        style={{
          backgroundColor: getColors().backgroundColor,
          color: getColors().text,
        }}
      >
        {getStatusIcon()}
      </div>
      <div className={styles["step-details"]}>
        <div className={styles["step-label"]}>{label}</div>
        {description && (
          <div className={styles["step-description"]}>{description}</div>
        )}
      </div>
      <hr />
    </div>
  );
};

// StepIndicator component for the list of steps
const StepIndicator = ({ steps, vertical,stepHeight ,currentStepIndicator}) => {
  return (
    <div
      className={
        vertical ? styles["step-indicator-vertical"] : styles["step-indicator"]
      }
    >
      {vertical
        ? steps.map((step, index) => (
            <div className={styles.singleStepContainer} style={{height:stepHeight}}>
              <div key={index} className={styles["step-container"]}>
                <Step
                  stepNumber={index + 1}
                  label={step.label}
                  description={step.description}
                  status={step.status}
                  isLastStep={index === steps.length - 1}
                />
              </div>

              {index !== steps.length - 1 && (
                <div
                className={styles["step-line"]}
                style={{
                  backgroundColor:
                    steps[index + 1].status === "current" && currentStepIndicator
                      ? currentStepIndicator
                      : "#3968ED", // Default color
                }}
              ></div>
              )}
            </div>
          ))
        : steps.map((step, index) => (
            <>
              <div key={index} className={styles["step-container"]}>
                <Step
                  stepNumber={index + 1}
                  label={step.label}
                  description={step.description}
                  status={step.status}
                  isLastStep={index === steps.length - 1}
                />
              </div>

              {index !== steps.length - 1 && (
                <div
                className={styles["step-line"]}
                style={{
                  backgroundColor:
                    steps[index + 1].status === "current" && currentStepIndicator
                      ? currentStepIndicator
                      : "#3968ED", // Default color
                }}
              ></div>
              )}
            </>
          ))}
    </div>
  );
};

export default StepIndicator;
