import {
  IndexTable,
  LegacyCard,
  useIndexResourceState,
  Button,
  Icon,
  Tooltip,
} from "@shopify/polaris";
import { Link, useLocation } from "react-router-dom";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomCssComponentTableText from "../../CustomComponents/CustomCssComponentTableText";
import StatusUpdate from "../../CustomComponents/StatusUpdate";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import SidebarInnerLists from "../../CustomComponents/SidebarInnerLists";
// import {
//   other_select_nav_items_inventory,
//   rooms_inner_lists,
// } from "../../assets/constant";
import {
  other_select_nav_items_inventory,
  rooms_inner_lists,
} from "../../../assets/constant";
import Loader from "../../UI/Loader/Loader";
import { BgThemeButton, BgThemeButtonDisabled } from "../../UI/Buttons";
import VerticalInputFieldCustom from "../../CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../../CustomComponents/VerticalInputSelectCustomCopy";
import CustomPagination from "../../CustomComponents/CustomPagination/CustomPagination";
import NoDataFound from "../../CustomComponents/NoDataFound";

import UnderscoreRemoveCapitalize from "../../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import { getDecryptedData } from "../../../utils/encryptStorage";

const RoomViewChefDashBoard = () => {
  const navigate = useNavigate();
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);
  const userToken = getDecryptedData('encryptToken');
  const permission = JSON.parse(localStorage.getItem("permissions"));

  const [permissions, setPermissions] = useState(permission);

  useEffect(() => {
    setPermissions(permissions);
  }, [permissions]);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);
  const [editRooms, setEditRooms] = useState(false);

  const [showLowerMenuAddMenu, setShowLowerMenuAddMenu] = useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [orders, setOrders] = useState([]);
  // fetch data
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(true);

  // Pagignation
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(orders.length / itemsPerPage);
    setCurrentPage(newPage > totalPages ? totalPages : newPage);
  };

  const sortMethods = {
    none: { method: (a, b) => null },
    ascendingRooms: { method: undefined },
    sortedRooms: {
      method: (a, b) => {
        const nameComparison = a.room_name.localeCompare(b.room_name);

        if (nameComparison !== 0) {
          return nameComparison;
        }
        return a.room_no - b.room_no;
      },
    },
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentOrders =
    orders && orders.length > 0
      ? orders
          .sort(sortMethods["sortedRooms"].method)
          .slice(startIndex, endIndex)
      : [];

  const fetchInfo = async (propertyId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/restaurant/room_view/fetch/${propertyId}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   property_id: property_id,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data.room_list);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  // const chargesForDate = () => {Object.keys(viewFolioBillsData.charges).map((date) => {
  const [fetchedRoomType, setFetchedRoomType] = useState([]);

  const fetchRoomType = async (propertyId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setFetchedRoomType(data.rooms);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
      fetchRoomType(property_id?.id);
    }
  }, [property_id]);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(currentOrders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
      setShowLowerMenuAddMenu(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuAddMenu(false);
    } else {
      setAddItemData({
        room_name: "",
        room_no: "",
        floor_no: "",
        status: "clean",
        room_id: fetchedRoomType ? fetchedRoomType[0]?.id : "",
        property_id: property_id?.id,
      });
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuAddMenu(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const getOneData = async (id, uniqueId) => {
    setEditId(uniqueId);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/assign/rooms/edit/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        room_name: data.room.room_name,
        room_no: data.room.room_no,
        floor_no: data.room.floor_no,
        status: data.room.status,
        room_id: data.room.room_id,
        property_id: data.room.property_id,
      };
      setEditRooms(true);

      setAddItemData(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  const rowMarkup = currentOrders?.map(
    ({ id, room_no, room_name, floor_no, status, index }) => {
      return (
        <IndexTable.Row
          id={id}
          key={id}
          selected={selectedResources.includes(id)}
          position={id}
        >
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={room_no}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={room_name}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={floor_no}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <StatusUpdate status={status}></StatusUpdate>
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const roomTypeOptions = fetchedRoomType?.map((roomType) => {
    return { label: roomType.room_name, value: roomType.id };
  });

  const statusOptions = [
    { label: "Clean", value: "clean" },
    { label: "Dirty", value: "dirty" },
    { label: "Inspected", value: "inspected" },
    { label: "Out of order", value: "out_of_order" },
    // { label: "Occupied", value: "occupied" },
  ];

  // UseState for forms
  const [addItemData, setAddItemData] = useState({
    service: "",
    description: "",
    rate: "",
    quantity: "",
    total_amount: "",
    sub_total: "",
    discount: "",
    gst_type: "",
    gst: "",
  });

  const setAddItemDataHandleChange = (event) => {
    setAddItemData({
      ...addItemData,
      [event.target.name]: event.target.value,
    });
  };
  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    clearSelection();
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/assign/rooms/delete/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          clearSelection();
          fetchInfo(property_id?.id);
          clearSelection();
          setShowLowerMenuMultipleSelect(false);
          setShowModelMenuActive(false);
          showSuccessToast(data.message);

          const totalPages = Math.ceil(orders.length / itemsPerPage);
          if (currentPage > totalPages) {
            handlePageChange(totalPages);
          }
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleSubmitAddRooms = (event) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/assign/rooms/store/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...addItemData }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          navigate("/rooms");
          showSuccessToast(data.message);
          setShowAddModelMenuActive(false);
          fetchInfo(property_id?.id);
          setAddItemData({
            room_id: "",
            room_name: "",
            room_no: "",
            floor_no: "",
            status: "clean",
          });
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const [editId, setEditId] = useState(null);

  const updateFunction = (id) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/assign/rooms/update/${id}/${property_id?.id}`,
      {
        method: "POST",

        body: JSON.stringify({ ...addItemData, status: addItemData.status }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setEditRooms(false);
        if (data.message) {
          fetchInfo(property_id?.id);
          clearSelection();
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
    clearSelection();
  };
  // ..............................
  const tabs = [
    {
      title: "Table View",
      link: "table-view",
    },
    {
      title: "Room View",
      link: "room-view",
    },
  ];
  const location = useLocation();
  const activeTab = tabs.findIndex((tab) =>
    location.pathname.includes(tab.link)
  );
  return (
    <div className="main_container">
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="room-types-full-container">
            {/* Single select */}
            {showLowerMenuSingleSelect && (
              <div className=" room-type-bottom-container">
                <div className="room-type-bottom-container-div">
                  <div>
                    {permissions && permissions.includes("delete_rooms") ? (
                      <Button
                        plain
                        destructive
                        // onClick={showModelMenu}
                        onClick={() => showModelMenu()}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            <Icon source={DeleteMajor} color="base" />
                          </span>
                          <span>Remove</span>
                        </div>
                      </Button>
                    ) : (
                      <Tooltip
                        content="Please Contact Your Admin"
                        dismissOnMouseOut
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            opacity: "0.4",
                            cursor: "no-drop",
                          }}
                        >
                          <span>
                            <Icon source={DeleteMajor} color="base" />
                          </span>
                          <span>Remove</span>
                        </div>
                      </Tooltip>
                    )}
                  </div>

                  <form
                    onSubmit={(e) => e.preventDefault()}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="room-type-bottom-div">
                      <div>Room No.</div>
                      <div style={{ width: "100px" }}>
                        <VerticalInputFieldCustom
                          name="room_no"
                          type="text"
                          value={addItemData.room_no}
                          onChange={setAddItemDataHandleChange}
                        />
                      </div>
                    </div>

                    <div className="room-type-bottom-div">
                      <div>Floor No.</div>
                      <div style={{ width: "100px" }}>
                        <VerticalInputFieldCustom
                          name="floor_no"
                          type="text"
                          value={addItemData.floor_no}
                          onChange={setAddItemDataHandleChange}
                        />
                      </div>
                    </div>
                    <div className="room-type-bottom-div">
                      <div>Room Status </div>
                      {addItemData.status === "occupied" ? (
                        <div style={{ width: "201px" }}>
                          <VerticalInputSelectCustomCopy
                            disabled
                            options={statusOptions}
                            name="status"
                            type="text"
                            value={addItemData.status}
                            onChange={setAddItemDataHandleChange}
                          />
                        </div>
                      ) : (
                        <div style={{ width: "100px" }}>
                          <VerticalInputSelectCustomCopy
                            options={statusOptions}
                            name="status"
                            type="text"
                            value={addItemData.status}
                            onChange={setAddItemDataHandleChange}
                          />
                        </div>
                      )}
                    </div>

                    <div
                      style={{
                        color: "#3968ED",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {permissions && permissions.includes("update_rooms") ? (
                        <BgThemeButton
                          onClick={() => updateFunction(selectedResources[0])}
                          children={"Update"}
                        />
                      ) : (
                        <Tooltip
                          content={`Please Contact Admin.`}
                          dismissOnMouseOut
                        >
                          <BgThemeButtonDisabled
                            onClick={() => updateFunction(selectedResources[0])}
                            children={"Update"}
                          />
                        </Tooltip>
                      )}

                      {/* <BgOutlineThemeButton children={"Edit"} /> */}
                    </div>
                  </form>
                </div>
              </div>
            )}

            {/* Multiple select */}
            {showLowerMenuMultipleSelect && (
              <div className=" room-type-bottom-container">
                <div className="room-type-bottom-container-div multipleroom-delete-btn-div">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Button plain destructive>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textDecoration: "none",
                        }}
                      >
                        <span>
                          <Icon source={DeleteMajor} color="base" />
                        </span>
                        <span onClick={deleteData}>Remove</span>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <div
              className="custom_tab_container"
              style={{ marginBottom: "20px" }}
            >
              <div className="custom_tab_header">
                {tabs.map((tab, index) => (
                  <Link
                    to={`/chef-dashboard/${tab.link}`}
                    className={`tab_item ${
                      index === activeTab ? "active" : ""
                    }`}
                    key={index}
                  >
                    {tab.title}
                  </Link>
                ))}
              </div>
            </div>
            <div
              style={{
                backgroundColor: "white",
                width: "100%",
                borderRadius: "4px",
              }}
            >
              <div>
                {orders &&
                  orders?.map((room) => {
                    return (
                      <>
                        <div style={{ padding: "10px 20px" }}>
                          {room.room_name}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            padding: "10px 20px",
                            paddingTop: "0",
                            borderBottom: "0.5px solid #CCC",
                          }}
                        >
                          {room?.Rooms?.map((singleRoom) => {
                            return (
                              <div
                                key={singleRoom?.room_no}
                                // onClick={() => getOneData(singleRoom.id, singleRoom.id)}
                                style={{
                                  width: "80px",
                                  height: "35px",
                                  backgroundColor: "#BD941B",
                                  color: "white",
                                  fontSize: "10px",
                                  fontWeight: "500",
                                  borderRadius: "4px",

                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  //   cursor: "pointer",
                                  //   flexDirection: "column",
                                }}
                                // className="hoverOnRoom"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    width: "100%",
                                    height: "100%",
                                    lineHeight: "12px",
                                    textAlign: "center",
                                  }}
                                >
                                  <p>{singleRoom.room_no}</p>
                                  <p>{singleRoom.balance_amount}</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>

          {/* Add Button Click */}
          {showAddModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <div className="add-ota-model-container-heading">
                      Add Rooms
                    </div>
                    <div
                      onClick={() => setShowAddModelMenuActive(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Room No."
                        name="room_no"
                        type="number"
                        value={addItemData.room_no}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        options={roomTypeOptions}
                        titleName="Room Type"
                        name="room_id"
                        value={addItemData.room_id}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Floor No."
                        name="floor_no"
                        value={addItemData.floor_no}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>

                    <div
                      style={{ width: "100%" }}
                      className="form-container-div"
                    >
                      <VerticalInputSelectCustomCopy
                        options={statusOptions}
                        titleName="Status"
                        name="status"
                        value={addItemData.status}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        onClick={(e) => handleSubmitAddRooms(e)}
                        type="submit"
                        children={"Add"}
                        loader={loader}
                      />
                      <Button onClick={() => setShowAddModelMenuActive(false)}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
          {/* Edit Button Click */}
          {editRooms && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <div className="add-ota-model-container-heading">
                      Edit Rooms
                    </div>
                    <div
                      onClick={() => setEditRooms(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Room No."
                        name="room_no"
                        type="number"
                        value={addItemData.room_no}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        options={roomTypeOptions}
                        titleName="Room Type"
                        name="room_id"
                        value={addItemData.room_id}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Floor No."
                        name="floor_no"
                        value={addItemData.floor_no}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>

                    <div
                      style={{ width: "100%" }}
                      className="form-container-div"
                    >
                      <VerticalInputSelectCustomCopy
                        options={statusOptions}
                        titleName="Status"
                        name="status"
                        value={addItemData.status}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        onClick={(e) => updateFunction(editId)}
                        type="submit"
                        children={"Update"}
                      />
                      <Button onClick={() => setEditRooms(false)}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}

          {showModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-type-model-container ">
                  <h4>Are You Sure?</h4>
                  <p>You Want To Remove?</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        setShowModelMenuActive(!showModelMenuActive)
                      }
                    >
                      Discard
                    </Button>
                    <Button onClick={deleteData} destructive>
                      Yes, Remove
                    </Button>
                  </div>
                </section>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
export default RoomViewChefDashBoard;
