import React, { useState } from "react";

const PasswordVerticalComponent = ({
  titleName,
  name,
  onChange,
  value,
  type,
  defaultValue,
  label,
  placeholder,
  required,
  min,
  max,
  onKeyDown,
  disabled,
  inputId,
  maxLength,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const showPasswordOnMouseDown = () => {
    setShowPassword(true);
  };

  const hidePasswordOnMouseUp = () => {
    setShowPassword(false);
  };

  return (
    <div>
      <div className="Polaris-Labelled__LabelWrapper">
        <div className="Polaris-Label">
          <label className="Polaris-Label__Text">
            {titleName}
            {required === true ? <span style={{ color: "red" }}> *</span> : ""}
          </label>
        </div>
      </div>
      <div className="Polaris-connected">
        <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
          <div style={{position:"relative"}} className="Polaris-TextField">
            <input
              type={showPassword ? "text" : "password"}
              onWheel={(e) => e.target.blur()}
              maxLength={maxLength}
              id={inputId}
              disabled={disabled}
              onKeyDown={onKeyDown}
              required={required}
              defaultValue={defaultValue}
              autoComplete="off"
              className="Polaris-TextField__Input"
              name={name}
              onChange={onChange}
              value={value}
              placeholder={placeholder}
              min={min}
              max={max}
            ></input>
            <div className="Polaris-TextField__Backdrop"></div>

            <div
              style={{ position:"absolute",     position: "absolute",
              zIndex: "999999",
              left: "93%",
              top:"25%",
              cursor: "pointer" }}
              onMouseDown={showPasswordOnMouseDown}
              onMouseUp={hidePasswordOnMouseUp}
              className="toggleButtonPasswordComponent"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 14 10"
                fill="none"
              >
                <g clipPath="url(#clip0_1_298)">
                  <path
                    d="M7.00001 3.25C6.53588 3.25 6.09076 3.43437 5.76257 3.76256C5.43438 4.09075 5.25001 4.53587 5.25001 5C5.25001 5.46413 5.43438 5.90925 5.76257 6.23744C6.09076 6.56563 6.53588 6.75 7.00001 6.75C7.46414 6.75 7.90926 6.56563 8.23745 6.23744C8.56564 5.90925 8.75001 5.46413 8.75001 5C8.75001 4.53587 8.56564 4.09075 8.23745 3.76256C7.90926 3.43437 7.46414 3.25 7.00001 3.25ZM7.00001 7.91667C6.22646 7.91667 5.4846 7.60938 4.93762 7.06239C4.39063 6.51541 4.08334 5.77355 4.08334 5C4.08334 4.22645 4.39063 3.48459 4.93762 2.93761C5.4846 2.39062 6.22646 2.08333 7.00001 2.08333C7.77356 2.08333 8.51542 2.39062 9.0624 2.93761C9.60939 3.48459 9.91668 4.22645 9.91668 5C9.91668 5.77355 9.60939 6.51541 9.0624 7.06239C8.51542 7.60938 7.77356 7.91667 7.00001 7.91667ZM7.00001 0.625C4.08334 0.625 1.59251 2.43917 0.583344 5C1.59251 7.56083 4.08334 9.375 7.00001 9.375C9.91668 9.375 12.4075 7.56083 13.4167 5C12.4075 2.43917 9.91668 0.625 7.00001 0.625Z"
                    fill="#888888"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_298">
                    <rect width="14" height="10" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordVerticalComponent;
