import React, { useEffect, useState } from "react";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "../../../Components/CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  BgThemeButton,
  CheckWithoutBgUnderlineBtn,
} from "../../../Components/UI/Buttons";
import BookingDetailSliderNew from "../../../Components/CustomComponents/BookingSoldSlider/BookingDetailsSlider";
import { showSuccessToast, showErrorToast } from "../../../assets/toastUtils";
import AdvancePayments from "./AdvancePayments";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../../../Components/CustomComponents/CustomReportComponent/CustomReportComponent";
import DateRangePickerNew from "../../../Components/CustomComponents/CreateBookingNewPage/DateRangePickerNew/DateRangePickerNew";
import Loader from "../../../Components/UI/Loader/Loader";
import ButtonToDownloadTablePDF from "../../../Components/CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import DownloadCSVButton from "../../../Components/CustomComponents/DownloadCsvTables/DownloadCSVButton";
import NoDataFound from "../../../Components/CustomComponents/NoDataFound";
import { formatIndianCurrency } from "../../../utils/utils";

const AdvancePaymentsReport = () => {
  const { ID } = useParams();
  const [data, setData] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState({});
  const property_id = useSelector((state) => state.property.value);
  const userToken = localStorage.getItem("token");
  const [loader, setLoader] = useState(false);

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  // Default to one month ago from today
  const getOneMonthAgoDate = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date.toISOString().split("T")[0];
  };

  const fetchReportCredits = async (
    fromDate = getOneMonthAgoDate(),
    toDate = getCurrentDate()
  ) => {
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/advance/payment/report/${ID}/${property_id.id}?viewType=List&from_date=${fromDate}&to_date=${toDate}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const data = await response.json();
      setLoader(false);

      setData(data.advanceTransactions);
      setPaymentDetails(data.advancePayment);
    } catch (error) {
      console.error("Fetch agent data failed", error);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchReportCredits();
    }
  }, [property_id]);

  const [dateRange, setDateRange] = useState({ from_date: "", to_date: "" });
  const dataFromChild = (from_date, to_date) => {
    setDateRange({
      from_date: from_date,
      to_date: to_date,
    });
  };
  const handleApplyButtonClick = () => {
    fetchReportCredits(dateRange.from_date, dateRange.to_date);
  };
  console.log(data, "data");

  return (
    <div>
      <div
        className="flex flex_gap_15  "
        style={{ margin: "20px 0", alignItems: "flex-end" }}
      >
        <div>
          <DateRangePickerNew dataFromChild={dataFromChild} />
        </div>{" "}
        <BgThemeButton
          onClick={handleApplyButtonClick}
          style={{ height: "30px", fontSize: "12px" }}
        >
          Apply
        </BgThemeButton>
      </div>

      {loader ? (
        <Loader />
      ) : (
        <div id={"report"}>
          <div class="room-type-heading flex justify_content_between mt-10 mb-10 ">
            Advance Payment Report{" "}
            <div
              id={"button"}
              className="flex flex_gap_10 "
              style={{ float: "left", width: "fit-content" }}
            >
              <ButtonToDownloadTablePDF
                itemToDownloadID={"report"}
                ignoreFromPrint={"button"}
                downloadFileName={"advance_payment_report"}
              />
              <DownloadCSVButton
                itemToDownloadID={"report"}
                ignoreFromPrint={"button"}
              />
            </div>
           
          </div>
          <div className="mb-20 flex flex_gap_20" >
              <div>
                <div>
                  {" "}
                  <strong>Total Advance Limit</strong> : {formatIndianCurrency(paymentDetails?.advance_amount)}
                </div>
              </div>
              <div>
                <div>
                  {" "}
                  <strong>Available Advance Limit</strong> : {formatIndianCurrency(paymentDetails?.balance_amount)}
                </div>
              </div>
              <div>
                <div>
                  {" "}
                  <strong>Used Advance Limit</strong> : {formatIndianCurrency(paymentDetails?.used_amount)}
                </div>
              </div>
            </div>
          {data && data.length > 0 ? (
            <CustomReportTable
              headings={[
                "Date",
                "Guest Name",
                "Reference",
                "KOT No",
                "Total Advance",
                "Credits",
                "Debits",
                "Balance",
              ]}
            >
              <>
                {data?.map((item, index) => {
                  return (
                    <>
                      <CustomShopifyIndexTableRow key={index}>
                        <CustomReportTableCell>
                          {item.date || "N/A"}
                        </CustomReportTableCell>
                        <CustomReportTableCell>
                          {item.guest_name || "N/A"}
                        </CustomReportTableCell>
                        <CustomReportTableCell>
                          #{item.unique_booking_id || "N/A"}
                        </CustomReportTableCell>
                        <CustomReportTableCell>
                          {item.kot_no || "N/A"}
                        </CustomReportTableCell>
                        <CustomReportTableCell>
                          {item.total_advance_amount || "N/A"}
                        </CustomReportTableCell>
                        <CustomReportTableCell>
                          {item.credit_amount || "N/A"}
                        </CustomReportTableCell>
                        <CustomReportTableCell>
                          {item.debit_amount || "N/A"}
                        </CustomReportTableCell>
                        <CustomReportTableCell>
                          {item.available_advance_amount || "N/A"}
                        </CustomReportTableCell>
                      </CustomShopifyIndexTableRow>
                    </>
                  );
                })}
              </>
            </CustomReportTable>
          ) : (
            <>
              <NoDataFound />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default AdvancePaymentsReport;
