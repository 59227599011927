import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SelectBasic } from "../../NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import styles from "./syncRooms.module.scss";
import { BgOutlineThemeButton, BgThemeButton } from "../../UI/Buttons";
import { showErrorToast } from "../../../assets/toastUtils";
import { getBaseUrl } from "../../../utils/utils";

const SyncRooms = ({ activeApp, userToken, setShowSyncPopup , baseURL, fetchInventoryRoom }) => {
  const baseUrl = getBaseUrl();
  const [loader, setLoader] = useState(false);
  const [pmsRooms, setPmsRooms] = useState([]);
  const [pmsRoomsId ,setPmsRoomsId] = useState();

   const pmsPropertyId  = useSelector((state) => state.property.value)?.pms_property_id;

  const property_id =  useSelector(
    (state) => state.property.value
  )?.id;

  const fetchInfo = async (propertyId) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setPmsRooms(data.rooms);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (pmsPropertyId) {
      fetchInfo(pmsPropertyId);
    }
  }, [pmsPropertyId]);

  const handleRoomCategoryChange = (data) => {
    setPmsRoomsId(data,);
  };




  const handleSyncSubmit = async() => {
    if (!pmsRoomsId) {
        showErrorToast("Please select room category")
        return
    }
    setLoader(true);
    try {
        setLoader(true);
        const response = await fetch(
          `${baseURL}/api/v1/sync/pms/room/rates/${property_id}?pms_property_id=${pmsPropertyId}&pms_room_id=${pmsRoomsId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        const data = await response.json();
       
        setLoader(false);
        setShowSyncPopup(false);
        fetchInventoryRoom(property_id)
      } catch (error) {
        setLoader(false);
        console.error(error);
      }
  }

  return (
    <div className={styles.container}>
      <div className="room-types-heading ">
        <div> Sync Room Categories</div>
      </div>

      <div className="mb-10">
        <SelectBasic
          options={pmsRooms}
          optionKeyName={"room_name"}
          selectedKeyName={"id"}
          showKeyName={"room_name"}
          handleSelectTagChange={handleRoomCategoryChange}
        />
      </div>

      <div className="flex flex_gap_10">
        <BgThemeButton onClick={handleSyncSubmit} loader={loader}>Sync</BgThemeButton>

        <BgOutlineThemeButton onClick={() => setShowSyncPopup(false)}>Cancel</BgOutlineThemeButton>
      </div>
    </div>
  );
};

export default SyncRooms;
