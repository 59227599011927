import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  valueValue: 0,
};

export const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    saveActiveProperty: (state, action) => {
      state.value = action.payload;
    },
    fetchProperties: (state, action) => {
      const fetchInfo = async () => {
        try {
          const response = fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v1/properties`
          );
          const data = await response.json();
          return data;
        } catch (error) {
          console.error(error);
        }
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { saveActiveProperty, fetchProperties } = propertySlice.actions;

export default propertySlice.reducer;
