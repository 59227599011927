import React from "react";
import "./customShopifyIndexTable.css";

export const CustomShopifyIndexTable = ({
  children,
  headings,
  allItems,
  selectedItems,
  setSelectedItems,
  showCheckbox,
  style,
}) => {
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedItems(allItems?.map((item) => item.id));
    } else {
      setSelectedItems([]);
    }
  };

  return (
    <table className="custom_shopify_index_table" style={style}>
      <thead className="" style={{ zIndex: "9999" }}>
        <tr className="custom_shopify_index_table_head_row">
          {showCheckbox && (
            <th   style={{ borderTopLeftRadius: "8px" ,padding:"10px",textAlign:"center"}}>
              <input
                type="checkbox"
                checked={
                  allItems?.length > 0 &&
                  allItems?.length === selectedItems?.length
                }
                onChange={handleCheckboxChange}
                className={
                  selectedItems?.length === 0
                    ? "custom-checkbox"
                    : selectedItems?.length > 0 &&
                      selectedItems?.length < allItems?.length
                    ? "custom-checkbox-two"
                    : "custom-checkbox"
                }
              />
            </th>
          )}
          {headings?.map((item, index) => (
            <th
              key={index}
              style={{
                ...(index === headings?.length - 1 && {
                  borderTopRightRadius: "8px",
                }),
                ...(index === 0 &&
                  !showCheckbox && { borderTopLeftRadius: "8px" }),
                  padding:"5px 10px",
              }}
            >
              {item}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="">{children}</tbody>
    </table>
  );
};

export const CustomShopifyIndexTableRow = ({
  children,
  id,
  selectedItems,
  setSelectedItems,
  showCheckbox,
  style,
}) => {
  const handleCheckboxChange = () => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(id)) {
        return prevSelectedItems?.filter((itemId) => itemId !== id);
      } else {
        return [...prevSelectedItems, id];
      }
    });
  };

  return (
    <tr
      onClick={showCheckbox ? handleCheckboxChange : null}
      className={
        selectedItems?.includes(id)
          ? "custom_index_table_body_row_selected"
          : "custom_index_table_body_row"
      }
      style={style}
    >
      {showCheckbox && (
        <td className="custom_shopify_index_table_cell tableCellCheckBox" >
          <input
            type="checkbox"
            checked={selectedItems?.includes(id)}
            onChange={handleCheckboxChange}
            className="custom-checkbox"
            onClick={(e) => e.stopPropagation()}
          />
        </td>
      )}
      {children}
    </tr>
  );
};

export const CustomShopifyIndexTableCell = ({ children }) => {
  return <td className="custom_shopify_index_table_cell" style={{padding:"5px 10px",}}>{children}</td>;
};
