import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import "./ModifyReservationPopup.css";
import { showErrorToast} from "../../../assets/toastUtils";
import { calculateToDatePlus } from "../../../utils/utils";
import { getCurrentPropertyDateHandler } from "../../../utils/utils";
import Loader from "../../UI/Loader/Loader";
import ModifyReservationArrivalsChangeRoomCategory from "./ModifyReservationArrivalsComponents/ModifyReservationArrivalsChangeRoomCategory";
import ModifyReservationArrivalsChangeMealPlans from "./ModifyReservationArrivalsComponents/ModifyReservationArrivalsChangeMealPlans";
import ModifyReservationArrivalsAmendStay from "./ModifyReservationArrivalsComponents/ModifyReservationArrivalsAmendStay";
import ModifyReservationArrivalsPaxChange from "./ModifyReservationArrivalsComponents/ModifyReservationArrivalsPaxChange";
import ModifyReservationArrivalsChangeGuestDetails from "./ModifyReservationArrivalsComponents/ModiifyResrevationArrivalsChangeGuestDetails";
import { getDecryptedData } from "../../../utils/encryptStorage";
const ModifyReservationPopupArrived = ({
  setShowModifyPopup,
  getBookingData,
  bookingData,
}) => {
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const property_id = useSelector((state) => state.property.value);
  const userToken = getDecryptedData('encryptToken');
  const [propertyCurrentDate, setPropertyCurrentDate] = useState();

  useEffect(() => {
    if (property_id) {
      getCurrentPropertyDateHandler(property_id?.id, userToken)
        .then((date) => setPropertyCurrentDate(date))
        .catch((error) => console.error(error));
    }
  }, [property_id]);

 
  
  //----------------------------modify reservation--------------------

  //----------------------Modify reservation Pop options------------------------------

  const modifyReservationPopupButtonOptions = [
    {
      label: "Change Room Category",
      value: "change_room_category",
    },

    {
      label: "Change Meal Plan",
      value: "change_meal_plan",
    },
    {
      label: "Amend Stay",
      value: "amend_stay",
    },
    {
      label: "Guest Details",
      value: "guest_details",
    },
    {
      label: "Pax Details",
      value: "pax_details",
    },
  ];
  const [selectedOption, setSelectedOption] = useState("change_room_category");

  // ----------------------modify reservation Pop Guest Details------------------------------

  function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  function formatDateMMDDYYYY(date) {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  //--------------------reasons for modification common for all popups-------------
  const [reasons, setReasons] = useState({
    changeRoomCategoryReason: "",
    changeMealPlanReason: "",
    amendStayReason: "",
    paxDetailReason: "",
  });

  const handleReasonChange = (event) => {
    const { name, value } = event.target;
    setReasons((prevReasons) => ({
      ...prevReasons,
      [name]: value,
    }));
  };

  // ============addon costs ==========================
  function calculateTotalAddons(array, property) {
    if (!Array.isArray(array)) {
      return 0;
    }
    return array?.reduce((total, item) => {
      if (item.is_cancelled === 0) {
        const value = parseFloat(item[property]) || 0;
        return total + value;
      }
      return total;
    }, 0);
  }
  const [addOnCost, setAddOnCost] = useState({
    taxableAmount: 0,
    discountAmount: 0,
    taxAmount: 0,
    NetTotal: 0,
  });

  useEffect(() => {
    if (bookingData?.addons && bookingData?.addons.length > 0) {
      setAddOnCost({
        taxableAmount:
          +calculateTotalAddons(bookingData.addons, "price") -
          +calculateTotalAddons(bookingData.addons, "discount_amount"),
        discountAmount: calculateTotalAddons(
          bookingData.addons,
          "discount_amount"
        ),
        taxAmount: calculateTotalAddons(bookingData.addons, "tax_amount"),
        NetTotal: calculateTotalAddons(bookingData.addons, "total_amount"),
      });
    }
  }, [bookingData]);

  // ========== addon cost end =========================

  const checkKeyValue = (array, keyName) => {
    return array.some(
      (obj) =>
        obj.hasOwnProperty(keyName) &&
        Array.isArray(obj[keyName]) &&
        obj[keyName].length > 0
    );
  };

  //====================pax change==========================

  //function for checking the empty values in objects

  function checkEmptyValuesInObjects(data, keysToCheck, enabledPaxes) {
    const invalidObjects = data.filter((obj, index) => {
      // Check if the current index is included in enabledPaxes array
      if (enabledPaxes.includes(index)) {
        return keysToCheck.some((key) => obj[key] === "");
      }
      return false;
    });

    if (invalidObjects.length > 0) {
      showErrorToast("Please fill in all required fields");
      return true;
    }
    return false;
  }

  return (
    <>
      <div>
        {/* -----------------------------Modify Reservation Popup-------------------------------- */}

        <div
          className="global_popup_container_background"
          style={{ background: "transparent" }}
        >
          <div className="global_popup_container_background">
            <div
              className="global_popup_container "
              style={{ width: "1000px", backgroundColor: "white" }}
            >
              <div className="flex flex_gap_10">
                {modifyReservationPopupButtonOptions.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {/* BgOutlineThemeButton BgThemeButton */}
                      <button
                        className={
                          selectedOption === item.value
                            ? " BgThemeButton"
                            : "BgOutlineThemeButton"
                        }
                        onClick={() => setSelectedOption(item.value)}
                      >
                        {item.label}
                      </button>
                    </React.Fragment>
                  );
                })}
                <div className="flex " style={{ alignItems: "end" }}>
                  <span> Booking ID: </span> &nbsp;{" "}
                  <b>{bookingData?.unique_booking_id}</b>
                </div>
              </div>
              <hr style={{ margin: "10px 0" }} />

              {loader ? (
                <Loader />
              ) : (
                <>
                  <div className="form-container-div">
                    {/* ===========================change room category=========================== */}
                    {selectedOption === "change_room_category" && (
                      <>
                        <ModifyReservationArrivalsChangeRoomCategory
                          bookingData={bookingData}
                          addOnCost={addOnCost}
                          reasons={reasons}
                          setReasons={setReasons}
                          setShowModifyPopup={setShowModifyPopup}
                          handleReasonChange={handleReasonChange}
                          getBookingData={getBookingData}
                          checkKeyValue={checkKeyValue}
                          
                        />
                      </>
                    )}
                    {/* =========================================================================== */}

                    {/* ==========================change meal plan=========================== */}

                    {selectedOption === "change_meal_plan" && (
                      <>
                        <ModifyReservationArrivalsChangeMealPlans
                          bookingData={bookingData}
                          addOnCost={addOnCost}
                          reasons={reasons}
                          setReasons={setReasons}
                          setShowModifyPopup={setShowModifyPopup}
                          handleReasonChange={handleReasonChange}
                          getBookingData={getBookingData}
                          checkKeyValue={checkKeyValue}
                          propertyCurrentDate={propertyCurrentDate}
                          checkEmptyValuesInObjects={checkEmptyValuesInObjects}
                        />
                      </>
                    )}

                    {/*========================================================================*/}

                    {/* ==========================amend stay=========================== */}

                    {selectedOption === "amend_stay" && (
                      <>
                        <ModifyReservationArrivalsAmendStay
                          bookingData={bookingData}
                          addOnCost={addOnCost}
                          reasons={reasons}
                          setReasons={setReasons}
                          setShowModifyPopup={setShowModifyPopup}
                          handleReasonChange={handleReasonChange}
                          getBookingData={getBookingData}
                          checkKeyValue={checkKeyValue}
                          propertyCurrentDate={propertyCurrentDate}
                          checkEmptyValuesInObjects={checkEmptyValuesInObjects}
                          formatDate={formatDate}
                          formatDateMMDDYYYY={formatDateMMDDYYYY}
                          calculateToDatePlus={calculateToDatePlus}
                        />
                      </>
                    )}

                    {/*========================================================================*/}

                    {/*=============================edit guest details=============================*/}

                    {selectedOption === "guest_details" && (
                      <>
                        <ModifyReservationArrivalsChangeGuestDetails
                          bookingData={bookingData}
                          setShowModifyPopup={setShowModifyPopup}
                          getBookingData={getBookingData}
                        />
                      </>
                    )}

                    {/*=============================edit guest details end=============================*/}

                    {/*================================ edit paxes start ========================== */}

                    {selectedOption === "pax_details" && (
                      <>
                        <ModifyReservationArrivalsPaxChange
                          bookingData={bookingData}
                          checkEmptyValuesInObjects={checkEmptyValuesInObjects}
                          propertyCurrentDate={propertyCurrentDate}
                          addOnCost={addOnCost}
                          reasons={reasons}
                          setReasons={setReasons}
                          setShowModifyPopup={setShowModifyPopup}
                          handleReasonChange={handleReasonChange}
                          getBookingData={getBookingData}
                          checkKeyValue={checkKeyValue}
                          formatDate={formatDate}
                          formatDateMMDDYYYY={formatDateMMDDYYYY}
                          calculateToDatePlus={calculateToDatePlus}
                        />
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModifyReservationPopupArrived;
