import React from "react";
import SettingsBanner from "./SettingsBanner";
import SettingsContent from "./SettingsContent";

const Settings = () => {
  return (
    <>
      
      <div >
      <SettingsContent />
      </div>
    </>
  );
};

export default Settings;
