import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "./NoDataFound";
import CustomCssComponentTableText from "./CustomCssComponentTableText";
import DownloadCsvTables from "./DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "./DownloadPdfTables/DownloadPdfTables";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "./CustomReportComponent/CustomReportComponent";
import ButtonToDownloadTablePDF from "./DownloadPdfTables/ButtonToDownloadTablePDF";
import DownloadCSVButton from "./DownloadCsvTables/DownloadCSVButton";
import { useState } from "react";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";

const ReportCancelledKot = ({ reportData }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [informationPopup, setInformationPopup] = useState(false);

  const names = [
    {
      kot_id: "KOT ID",
      kot_date: "Date",
      kot_grand_total: "SubTotal",
      kot_tax_amount: "Tax",
      kot_total_amount: "Grand Total",
      kot_reason: "Reason",
    },
  ];
  const rowMarkup =
    reportData &&
    reportData?.map(
      (
        {
          kot_id,
          kot_date,
          kot_grand_total,
          kot_reason,
          kot_tax_amount,
          kot_total_amount,
          rooms_occupancy,
          room_name,
          total_rooms,
          sold_rooms,
        },
        index
      ) => {
        return (
          <CustomReportTableRow id={index} key={index}>
            <CustomReportTableCell>{kot_id}</CustomReportTableCell>
            <CustomReportTableCell>{kot_date}</CustomReportTableCell>

            <CustomReportTableCell>
              {formatIndianCurrency(kot_grand_total)}
            </CustomReportTableCell>
            <CustomReportTableCell>
              {formatIndianCurrency(kot_tax_amount)}
            </CustomReportTableCell>
            <CustomReportTableCell>
              {formatIndianCurrency(kot_total_amount)}
            </CustomReportTableCell>
            <CustomReportTableCell>{kot_reason}</CustomReportTableCell>
          </CustomReportTableRow>
        );
      }
    );

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>{"Total"}</CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>

      <CustomReportTableCell>
        {" "}
        {formatIndianCurrency(
          calculateTotalWithReduce(reportData, "kot_grand_total")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(reportData, "kot_tax_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(reportData, "kot_total_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
    </CustomReportTableRow>
  );

  return (
    <>
      <div
        id="receipt"
        className="room-types-full-container reports-new-background"
      >
        <div className="room-types-full-container-div">
          <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
            <div className="room-type-heading">Cancelled KOT's</div>
            <div
              className="hoverCursor"
              onClick={() => setInformationPopup(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#3968ED"
              >
                <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
              </svg>
            </div>
          </div>

          {reportData?.length > 0 && (
            <div className="flex flex_gap_15 align_items_center" id="pdf">
              <ButtonToDownloadTablePDF
                itemToDownloadID={"receipt"}
                downloadFileName={"Cancelled KOT's"}
                ignoreFromPrint={"pdf"}
                // pdfStyles={

                // }
              />
              <DownloadCSVButton
                itemToDownloadID={"receipt"}
                downloadFileName={"Cancelled KOT's"}
              />
            </div>
          )}
        </div>

        {reportData && reportData.length > 0 ? (
          <>
            {/* <LegacyCard>
              <IndexTable
                resourceName={resourceName}
                itemCount={reportData.length}
                selectable={false}
                headings={[
                  { title: "KOT ID" },
                  { title: "Date" },
                  { title: "SubTotal" },
                  { title: "Tax Amount" },
                  { title: "Grand Total" },
                  { title: "Reason" },
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </LegacyCard> */}

            <CustomReportTable
              headings={[
                "KOT ID",
                "Date",
                "SubTotal",
                "Tax Amount",
                "Grand Total",
                "Reason",
              ]}
              // commonHeadings={[
              //   "",
              //   "",
              //   "",
              //   "Purchased",
              //   "Purchased",
              //   "Issued",
              //   "Issued",
              //   "Stock",
              //   "Stock",
              // ]}
              headerStyles={{
                backgroundColor: "#dddddd",
                fontSize: "12px",
                textAlign: "center",
              }}
              tableStyles={{ textAlign: "left" }}
            >
              {rowMarkup}
              {calculateTotals}
            </CustomReportTable>
          </>
        ) : (
          <NoDataFound />
        )}
      </div>


      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of Canceled KOT Report"
        >
          <div className="mb-20">
            <AccordionComponent
              questions={[
                {
                  question: "What is the Canceled KOT Report?",
                  answer: (
                    <p>
                 A <b>Cancelled KOT (Kitchen Order Ticket)</b> Report provides a detailed account of food and beverage orders that were canceled after being placed. The report includes the KOT ID, date of the cancellation, subtotal, tax amount, grand total, and the reason for cancellation. This information helps the establishment track cancellations effectively.

                    </p>
                  ),
                },
                {
                  question:
                    "What is the Importance of the Canceled KOT Report?",
                  answer: (
                    <div>
                      <ul>
                        <li>
                        <b>Identifying Trends: </b>By analyzing cancellation reasons, establishments can identify common issues or patterns, allowing for improvements in menu offerings or service.
                        </li>
                        <li>
                        <b>Operational Efficiency:</b> Understanding the frequency and reasons for cancellations can help streamline operations, minimizing waste and optimizing staff performance.
                        </li>
                        <li>
                        <b>Financial Analysis: </b>The report assists in assessing the financial impact of cancellations on revenue, helping management make informed decisions.
                        </li>
                        <li>
                       <b> Customer Feedback:</b> Insights gained from cancellation reasons can enhance customer satisfaction by addressing specific concerns or preferences in the dining experience.
                        </li>
                      </ul>
                     
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportCancelledKot;
