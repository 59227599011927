import React from "react";
import EventCommunicationBill from "../../Components/Communication/EventCommunicationVoucher/EventCommunicationBill";

const EventsCommunicationBillPage = () => {
  return (
    <>
      <div className="main_container">
        <EventCommunicationBill />
      </div>
    </>
  );
};

export default EventsCommunicationBillPage;
