import React from "react";
import Sidebar from "../Components/Sidebar";
import Discounts from "../Components/Discounts";
import BookingVoid from "../Components/BookingVoid";

const BookingVoidPage = () => {
  return (
    <>
     <div className="main_container">
          <BookingVoid />
      </div>
    </>
  );
};

export default BookingVoidPage;
