import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import LoaderSmall from "../../UI/Loader/LoaderSmall";
import NoDataFoundSmall from "../../CustomComponents/NoDataFoundSmall";
import { getDecryptedData } from "../../../utils/encryptStorage";

const OTABookingsChart = () => {
  const [loader, setLoader] = useState(false);
  const property_id = useSelector((state) => state.property.value);
  const userToken = getDecryptedData('encryptToken');
  const [otaBookingChartData, setOtaBookingChartData] = useState({
    chartsData: [],
    total: 0,
  });

  const fetchOtaChartData = async (id) => {
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/ota-booking-data-by-source/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (!response.ok) {
        setLoader(false);
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      setLoader(false);
      setOtaBookingChartData({
        graphData: data?.data,
        total: data?.total_bookings,
      });
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchOtaChartData(property_id?.id);
    }
  }, [property_id]);

  const OTABookingsChartData = [
    { name: "Makemytrip", value: 50 },
    { name: "Goibibo", value: 50 },
    { name: "Expedia", value: 50 },
    { name: "Booking.com", value: 50 },
    { name: "Airbnb", value: 50 },
  ];

  const labels = otaBookingChartData?.graphData?.map((ota) => ota.name);
  const dataValues = otaBookingChartData?.graphData?.map(
    (ota) => ota.this_month
  );

  const backgroundColors = ["#FF6384", "#36A2EB", "#4BC0C0", "#FF9F40"];

  const chartData = {
    data: {
      labels: labels,
      datasets: [
        {
          label: "OTA Bookings",
          data: dataValues,
          backgroundColor: backgroundColors,
          hoverBackgroundColor: backgroundColors,
          borderWidth: 1,
        },
      ],
    },
    options: {
      cutout: "60%", // Creates the donut shape
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        tooltip: {
          enabled: true,
        },
        legend: {
          display: false,
        },
        // Custom plugin to display a message when all data values are zero
        afterDraw: (chart) => {
          // Check if all values are zero
          const allZero = dataValues.every((value) => parseFloat(value) === 0);
  
          if (allZero) {
            const ctx = chart.ctx;
            const width = chart.width;
            const height = chart.height;
            ctx.save();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.font = '16px Arial';
            ctx.fillStyle = '#FF6384';
            ctx.fillText('No Bookings Available', width / 2, height / 2);
            ctx.restore();
          }
        },
      },
    },
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        margin: "auto",
        position: "relative",
      }}
    >
      {loader ? (
        <LoaderSmall />
      ) : (
        <>
          <Doughnut data={chartData.data} options={chartData.options} />

          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
            }}
          >
            <h2 style={{ fontSize: "15px", fontWeight: "bold" }}>Total</h2>
            <h3 style={{ fontSize: "14px", fontWeight: "bold" }}>{otaBookingChartData?.total }</h3>
          </div>
        </>
      )}
    </div>
  );
};

export default OTABookingsChart;
