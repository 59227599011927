import React, { useEffect, useRef, useState } from "react";
import "./folioSummeryBill.css";
import BillUi from "./BillUi";
import FolioTopBarandNavigation from "./FolioTopBarandNavigation";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
} from "../UI/Buttons";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import Loader from "../UI/Loader/Loader";
import PopupPreview from "./PopupPreview";
import IFrameBillUi from "./IFrameBillUi";
import NewIframe from "./newIframe";
import PrintFolioRoomBill from "./PrintFolioRoomBill";
import ViewFolioRoomBill from "./ViewFolioRoomBill";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  currentDateSingle,
  getCurrentPropertyDateHandler,
} from "../../utils/utils";
import { Button, Icon, Tooltip } from "@shopify/polaris";
import RoundOffNumber from "../CustomComponents/RoundOffNumber/RoundOffNumber";
import CustomCountryStateSelector from "../CustomComponents/CustomCountryStateSelector";
import SearchComponent from "../CustomComponents/SearchComponent/SearchComponent";
import { getDecryptedData } from "../../utils/encryptStorage";

const FolioSummeryBill = () => {
  const navigate = useNavigate();
  const clickOutSideRef = useRef(null);

  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(true);
  const userToken = getDecryptedData('encryptToken');

  const [editBillTo, setEditBillTo] = useState(false);
  const [showFolioFeature, setShowFolioFeature] = useState(false);
  const [createNewFolio, setCreateNewFolio] = useState(false);
  const [addGuestPopup, setAddGuestPopup] = useState(false);
  const [addChargesPopup, setAddChargesPopup] = useState(false);

  const [chargesList, setChargesList] = useState([]);
  const [settlements, setSettlements] = useState([]);
  const [folioData, setFolioData] = useState([]);
  const [folioBookings, setFolioBookings] = useState([]);
  const [allFolioArray, setAllFolioArray] = useState([]);
  const [downloadPdf, setDownloadPdf] = useState(false);

  const [roundedValue, setRoundedValue] = useState(0);
  const [roundOffValue, setRoundOffValue] = useState(null);

  const [guest, setGuest] = useState([]);
  const { folioId, folioUniqueId } = useParams();

  const [roomDetails, setRoomDetails] = useState([]);

  const proofOptions = [
    { label: "Select option" },
    { label: "Aadhar Card", value: "aadhar" },
    { label: "PAN Card", value: "pan" },
    { label: "Passport", value: "passport" },
    { label: "Driving License", value: "driving_license" },
    // { label: "Other", value: "other" },
  ];

  const gstTypeOptions = [
    { label: "select Option", value: "" },
    { label: "Inclusive", value: "inclusive" },
    { label: "Exclusive", value: "exclusive" },
  ];

  const gstOptions = [
    { label: "Select Option", value: "" },
    { label: "8%", value: "8" },
    { label: "12%", value: "12" },
    { label: "15%", value: "15" },
  ];

  const serviceOptions = [
    { label: "Select Option", value: "" },
    { label: "Room-Charge", value: "Room-Charge" },
    { label: "Food-Charge", value: "Food-Charge" },
    { label: "Housekeeping-Charge ", value: "Housekeeping-Charge" },
    { label: "Miscellaneous-Charge", value: "Miscellaneous-Charge" },
    { label: "Laundry-Charge", value: "Laundry-Charge" },
    // { label: "MISC", value: "misc" },
  ];

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const [openCustomersListPopup, setOpenCustomersListPopup] = useState(true);
  const [openCompanyListPopup, setOpenCompanyListPopup] = useState(true);

  const [filteredCustomerSuggestions, setFilteredCustomerSuggestions] =
    useState([]);
  const [filteredCompanySuggestions, setFilteredCompanySuggestions] = useState(
    []
  );
  const popupRef = useRef(null);

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //       if (popupRef.current && !popupRef.current.contains(event.target)) {
  //         setOpenCustomersListPopup(false);
  //       }
  //   }

  //   // Bind the event listener
  //   document.addEventListener('click', handleClickOutside);

  //   // Unbind the event listener on cleanup
  //   return () => {
  //       document.removeEventListener('click', handleClickOutside);
  //   };
  // }, []);
  // ....................
  const fetchFolioInfo = async (propertyId, selectedFolioId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/v1/folios/${folioId}/${propertyId}?folio_id=${
          selectedFolioId ? selectedFolioId : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setFolioData(data?.data);
      setBillGenerated(data?.bill_generated);
      setInvoiceGenerated(data?.billNumber);
      setChargesList(data?.data?.charges);
      setSettlements(data?.data?.payments);
      setFolioSummery(data?.data?.bill_to);
      setGuest(data?.data?.guest);
      setRoomDetails(data?.data?.roomDetails);
      setFolioBookings(data?.data?.booking);
      setLoader(false);
      setAllFolioArray(data?.allFolios);
    } catch (error) {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (property_id && folioUniqueId) {
      fetchFolioInfo(property_id?.id, folioUniqueId);
    }
  }, [property_id]);

  const [folioSummery, setFolioSummery] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    country: "",
    city: "",
    state: "",
    pin_code: "",
    gst_no: "",
  });
  const [showFolioSummeryEdit, setShowFolioSummeryEdit] = useState(false);

  const [showBillPreview, setShowBillPreview] = useState(false);
  const [billGenerated, setBillGenerated] = useState(false);
  const [invoiceGenerated, setInvoiceGenerated] = useState(null);

  const [generateInvoice, setGenerateInvoice] = useState(false);

  const handlePrintButtonClick = () => {
    setShowBillPreview(true);
    setTimeout(() => {
      window.print();
      setShowBillPreview(false);
    }, 100);
  };

  const roomNumberArray = [
    {
      label: "Select option",
      value: null,
    },
    ...roomDetails?.map((room) => ({
      label: room?.roomNo.toString(),
      value: room?.roomNo.toString(),
    })),
  ];

  const guestsArray = [
    ...roomDetails.flatMap((room) =>
      room?.guest_details?.map((guest) => ({
        label: guest?.guest_name,
        value: guest?.id.toString(),
        id: guest?.id.toString(),
        guest_name: guest?.guest_name,
      }))
    ),
  ];

  const allGuestArray = [
    ...roomDetails?.flatMap((room) =>
      room?.guest_details?.map((guest) => guest)
    ),
  ];
  const dataFromChild = (id) => {
    fetchFolioInfo(property_id?.id, id);
    navigate(`/bookings/folio-bills/${folioId}/${id}`);
  };

  const [billingDetails, setBillingDetails] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = useState("Guest");

  const [billingDetailsFormData, setBillingDetailsFormData] = useState({
    email: "",
    phone: "",
    city: "",
    state: "",
    country: "",
    guest_dob: "",
    address: "",
    pincode: "",
    identity_proof_type: "",
    identity_proof_no: "",
    room_no: "",
    folio_id: "",
    is_primary: "",
    name: "",
    refrence_id: "",
    customer: "",
    company: "",
    gst_no: "",
  });

  console.log(billingDetailsFormData);
  
  const handleSelectCountryState = (data) => {
    setBillingDetailsFormData((prevState) => ({
      ...prevState,
      country: data.country,
      state: data.state,
    }));
  };

  const [billToPopup, setBillToPopup] = useState(false);

  useEffect(() => {
    if (allGuestArray) {
      const filteredObj = allGuestArray?.find(
        (guest) => +guest?.id === +billingDetailsFormData?.customer
      );
      if (filteredObj) {
        setBillingDetailsFormData({
          ...billingDetailsFormData,
          name: filteredObj.guest_name,
          email: filteredObj.guest_email ? filteredObj.guest_email : " ",
          phone: filteredObj.guest_phone ? filteredObj.guest_phone : " ",
          city: filteredObj.guest_city ? filteredObj.guest_city : " ",
          state: filteredObj.guest_state ? filteredObj.guest_state : " ",
          country: filteredObj.guest_country ? filteredObj.guest_country : " ",
          guest_dob: filteredObj.guest_dob ? filteredObj.guest_dob : " ",
          address: filteredObj.guest_address ? filteredObj.guest_address : " ",
          pincode: filteredObj.guest_pincode ? filteredObj.guest_pincode : " ",
          identity_proof_type: filteredObj.identity_proof_type
            ? filteredObj.identity_proof_type
            : " ",
          identity_proof_no: filteredObj.identity_proof_no
            ? filteredObj.identity_proof_no
            : " ",
          room_no: filteredObj.room_no ? filteredObj.room_no : " ",
          // folio_id:filteredObj.guest_email,
          is_primary: filteredObj.is_primary ? filteredObj.is_primary : " ",
          refrence_id: filteredObj.refrence_id ? filteredObj.refrence_id : " ",
        });
      }
    }
  }, [billingDetailsFormData.customer]);

  useEffect(() => {
    if (agentListOptions) {
      const filteredObj = agentLists?.find(
        (guest) =>
          +guest?.agent_information === +billingDetailsFormData?.company
      );
      if (filteredObj) {
        setBillingDetailsFormData({
          ...billingDetailsFormData,
          // guest_name: filteredObj.guest_name,
          email: filteredObj.agent_email ? filteredObj.agent_email : " ",
          phone: filteredObj.agent_phone ? filteredObj.agent_phone : " ",
          city: filteredObj.guest_city ? filteredObj.guest_city : " ",
          state: filteredObj.guest_state ? filteredObj.guest_state : " ",
          country: filteredObj.guest_country ? filteredObj.guest_country : " ",
          guest_dob: filteredObj.guest_dob ? filteredObj.guest_dob : " ",
          address: filteredObj.guest_address ? filteredObj.guest_address : " ",
          pincode: filteredObj.guest_pincode ? filteredObj.guest_pincode : " ",
          identity_proof_type: filteredObj.identity_proof_type
            ? filteredObj.identity_proof_type
            : " ",
          identity_proof_no: filteredObj.identity_proof_no
            ? filteredObj.identity_proof_no
            : " ",
          room_no: filteredObj.room_no ? filteredObj.room_no : " ",
          // folio_id:filteredObj.guest_email,
          is_primary: filteredObj.is_primary,
          gst_no: filteredObj.agent_gst ? filteredObj.agent_gst : " ",
          name: filteredObj.company_name ? filteredObj.company_name : " ",
        });
      }
    }
  }, [billingDetailsFormData.company]);

  const [agentLists, setAgentLists] = useState([]);

  const fetchAgentLists = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      if (data?.data.length > 0) {
        setAgentLists(data.data);
      } else setAgentLists([]);
    } catch (error) {
      console.error(error);
    }
  };

  const agentListOptions = [
    {
      label: "Select option",
      value: "",
    },
    ...agentLists?.map((agent) => ({
      label: agent?.company_name,
      value: agent?.agent_information,
    })),
  ];

  function handleBillToPopup() {
    setBillToPopup(!billToPopup);
  }

  const handleCheckBoxesChange = (e) => {
    const selectedValue = e.target.value;
    // Always clear all form fields when switching between "Customer" and "Company"
    const emptyFormData = {
      customer: "", // Clear the customer field
      company: "", // Clear the company field
      phone: "",
      email: "",
      country: "",
      state: "",
      city: "",
      address: "",
      pincode: "",
      gst_no: "",
    };
    setBillingDetailsFormData(emptyFormData);
    setSelectedCheckBox(selectedValue);
  };

  const handleBillingDetailsInputChange = (e) => {
    const { name, value } = e.target;
    const filteredGuestNames = roomDetails.map((room) =>
      room.guest_details.filter((guest) =>
        guest.guest_name.toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredCustomerSuggestions(filteredGuestNames.flat());

    const filteredCompany = agentLists.filter((agent) =>
      agent.company_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCompanySuggestions(filteredCompany);

    setBillingDetailsFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [openCustomersClickListPopup, setOpenCustomersClickListPopup] =
    useState(false);
  const handleCustomerInputClick = () => {
    setOpenCustomersClickListPopup(true);
  };
  const [openCompanyClickListPopup, setOpenCompanyClickListPopup] =
    useState(false);
  const handleCompanyInputClick = () => {
    setOpenCompanyClickListPopup(true);
  };
  const handleSubmitBillToHandle = (e) => {
    if (
      !billingDetailsFormData?.address ||
      billingDetailsFormData?.address.trim() === "" ||
      !billingDetailsFormData?.city ||
      billingDetailsFormData?.city.trim() === "" ||
      !billingDetailsFormData?.state ||
      billingDetailsFormData?.state.trim() === "" ||
      !billingDetailsFormData?.country ||
      billingDetailsFormData?.country.trim() === "" ||
      !billingDetailsFormData?.pincode
    ) {
      showErrorToast("Fill all the required details.");
      return;
    }

    e.preventDefault();
    fetch(
      // Route::post(‘update/bill/to/{bill_to_id}/{property_id}’, ‘updateBillTo’);
      `${process.env.REACT_APP_BASE_URL}/api/v1/update/bill/to/${folioSummery?.id}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...billingDetailsFormData,
          folio_id: folioData.id,
          bill_to_type: selectedCheckBox,
          refrence_id: billingDetailsFormData?.customer
            ? billingDetailsFormData?.customer || folioSummery?.refrence_id
            : billingDetailsFormData.company || folioSummery?.refrence_id,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setShowFolioSummeryEdit(false);
          // setAddChargesData(false);
          fetchFolioInfo(property_id.id, folioData?.id);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleSuggestionClick = (field, value, name) => {
    setBillingDetailsFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));

    setOpenCustomersClickListPopup(false);
    setOpenCompanyClickListPopup(false);
    setFilteredCustomerSuggestions([]);
    setFilteredCompanySuggestions([]);
  };

  const handleSubmitGenerateBillHandle = (regenerate) => {
    fetch(
      // Route::get(‘generate/folio/bill/{folio_id}/{property_id}’, ‘generateBill’);
      `${process.env.REACT_APP_BASE_URL}/api/v1/generate/folio/bill/${folioData?.id}/${generateInvoice}/${property_id?.id}?regenerateBill=${regenerate}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          // setShowFolioSummeryEdit(false);
          // setAddChargesData(false);
          fetchFolioInfo(property_id.id, folioData?.id);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  // const handleDownloadPdf = () => {
  //   setShowBillPreview(true);
  //   setDownloadPdf(!downloadPdf);
  // };
  // ..............View Folio Bills..............
  const [viewFolioBillsData, setViewFolioBillsData] = useState(null);
  useEffect(() => {
    const fetchViewFolioBillsData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/get/folio/bill/details/127/4`
        );
        const result = await response.json();
        setViewFolioBillsData(result.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchViewFolioBillsData();
  }, []);
  const [propertyCurrentDate, setPropertyCurrentDate] = useState("");

  useEffect(() => {
    if (property_id) {
      getCurrentPropertyDateHandler(property_id?.id, userToken)
        .then((date) => setPropertyCurrentDate(date))
        .catch((error) => console.error(error));
    }

    fetchAgentLists();
  }, [property_id]);

  const [iframeContent, setIframeContent] = useState("");

  const getBillIframe = () => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/layout/preview/${property_id?.id}/bill?previewType=roomBillPreview&previewId=${folioData?.id}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.text())
      .then((data) => {
        setIframeContent(data);
        setShowBillPreview(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };
  const getBillIframeDownload = () => {
    setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/layout/preview/${property_id?.id}/bill?previewType=roomBillPreview&previewId=${folioData?.id}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.text())
      .then((data) => {
        // setIframeContent(data);
        handleDownloadPdf(data); // Call handleDownloadPdf here
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleDownloadPdf = (data) => {
    const html2pdf = require("html2pdf.js");
    const styles = `
    <style>
      body {
        margin: 0;
        padding: 0;
        font-family: Arial, sans-serif;
      }
      .page {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .bill-container {
        border: 1px solid #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        padding:10px;
        max-width: 800px; /* Adjust as needed */
      }
      /* Add more styles as needed */
    </style>
  `;
    const htmlWithStyles = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Bill</title>
      ${styles}
    </head>
    <body>
      <div class="page"> 
        <div class="bill-container">
          ${data} 
        </div>
      </div>
    </body>
    </html>
  `;

    html2pdf()
      .from(htmlWithStyles)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        const filename = "bill.pdf";
        pdf.save(filename);
        setLoader(false);
      });
  };
  const [htmlContent, setHtmlContent] = useState("");
  const [showPreview, setShowPreview] = useState(false);

  const handleRoundOffChange = (rounded, roundOffValue) => {
    setRoundedValue(rounded);
    setRoundOffValue(roundOffValue);
  };
  const popupsRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupsRef.current && !popupsRef.current.contains(event.target)) {
        setOpenCustomersClickListPopup(false);
        setOpenCompanyClickListPopup(false);
        setOpenCompanyListPopup(false);
        setOpenCustomersListPopup(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleUpdateBillToState = () => {
    setSelectedCheckBox(
      folioSummery?.bill_to_type ? folioSummery?.bill_to_type : "Guest"
    );

    setBillingDetailsFormData({
      ...billingDetailsFormData,
      email: folioSummery?.email,
      phone: folioSummery?.phone,
      city: folioSummery?.city,
      state: folioSummery?.state,
      country: folioSummery?.country,
      address: folioSummery?.address,
      pincode: folioSummery?.pincode,
      name: folioSummery?.name,
      gst_no: folioSummery?.gst_no,
      bill_to_type: folioSummery?.bill_to_type,
      refrence_id: folioSummery?.refrence_id,
    });

    setShowFolioSummeryEdit(true);
  };

  const getSelectedItem = (item, index) => {
    setBillingDetailsFormData((prevState) => ({
      ...prevState,
      customer: item,
    }));
  };
  const getSelectedItemAgent = (item, index) => {
    setBillingDetailsFormData((prevState) => ({
      ...prevState,
      company: item,
    }));
  };

  return (
    <>
      <FolioTopBarandNavigation
        guestsArray={guestsArray}
        roomNumberArray={roomNumberArray}
        // handleSelect={handleSelect}
        // fetchFolioInfoParent = {fetchFolioInfo(property_id?.id)}
        dataFromChild={dataFromChild}
      />

      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="folioSummeryBill" style={{ marginTop: "20px" }}>
            <div className="folioSummeryBillContainer">
              <table className="folioSummeryTable">
                <thead>
                  <tr>
                    <th className="folioSummeryTableHeading">Bill To</th>
                    <th className="folioSummeryTableHeading">
                      <button
                        className="folioSummeryTableHeaderButton"
                        onClick={() => handleUpdateBillToState()}
                      >
                        <span>
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.03107 0.841887L11.1921 3.09345L11.5525 2.71794C12.1492 2.09629 12.1492 1.08853 11.5525 0.466875C10.9557 -0.154974 9.98784 -0.154974 9.391 0.466875L9.03107 0.841887Z"
                              fill="#3968ED"
                            />
                            <path
                              d="M10.1999 4.12486L8.03885 1.8733L1.18146 9.01677L0.47998 11.9992L3.34248 11.2683L10.1999 4.12486Z"
                              fill="#3968ED"
                            />
                          </svg>
                        </span>
                        Edit
                      </button>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="folioSummeryTableData">Guest Name</td>
                    <td className="folioSummeryTableData folioSummeryTableDataRight">
                      {folioSummery && folioSummery?.name}
                    </td>
                  </tr>
                  <tr>
                    <td className="folioSummeryTableData">Phone</td>
                    <td className="folioSummeryTableData folioSummeryTableDataRight">
                      {folioSummery && folioSummery?.phone}
                    </td>
                  </tr>
                  <tr>
                    <td className="folioSummeryTableData">Email</td>
                    <td className="folioSummeryTableData folioSummeryTableDataRight">
                      {folioSummery && folioSummery?.email}
                    </td>
                  </tr>
                  <tr>
                    <td className="folioSummeryTableData">Country</td>
                    <td className="folioSummeryTableData folioSummeryTableDataRight">
                      {folioSummery && folioSummery?.country}
                    </td>
                  </tr>
                  <tr>
                    <td className="folioSummeryTableData">State</td>
                    <td className="folioSummeryTableData folioSummeryTableDataRight">
                      {folioSummery && folioSummery?.state}
                    </td>
                  </tr>
                  <tr>
                    <td className="folioSummeryTableData">Address</td>
                    <td className="folioSummeryTableData folioSummeryTableDataRight">
                      {folioSummery && folioSummery?.address}
                    </td>
                  </tr>
                  <tr>
                    <td className="folioSummeryTableData">
                      PIN Code/ ZIP Code
                    </td>
                    <td className="folioSummeryTableData folioSummeryTableDataRight">
                      {folioSummery && folioSummery?.pincode}
                    </td>
                  </tr>
                  <tr>
                    <td className="folioSummeryTableData">GST No.</td>
                    <td className="folioSummeryTableData  folioSummeryTableDataRight">
                      {folioBookings && folioSummery?.gst_no}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="folioSummeryBillInvoiceSettings">
              <h4 className="folioSummeryBillInvoiceSettingsHeading">
                Invoice Settings
              </h4>

              {/* <div>
                <p>1700.80</p>
                {isDecimal && (
                <div className='w-half flex flex_gap_10' >
                  <div className="">
                    <div>Round Off</div>
                  </div>
                  <div style={{ height: "28px" }}>
                    <RoundOffNumber
                      totalAmount={1700.80}
                      onRoundOffChange={handleRoundOffChange}
                    />
                  </div>
                </div>
                 )} 
              </div> */}

              {invoiceGenerated ? (
                <div className="folioSummeryBillInvoiceSettingsCheckbox">
                  {" "}
                  Invoice No. :{invoiceGenerated}
                </div>
              ) : (
                <>
                  <input
                    type="checkbox"
                    id="invoice"
                    className="folioSummeryBillInvoiceSettingsCheckbox"
                    checked={generateInvoice}
                    onChange={() => setGenerateInvoice(!generateInvoice)}
                  />
                  <label
                    htmlFor="invoice"
                    className="folioSummeryBillInvoiceSettingsLabel"
                  >
                    Generate Invoice No.
                  </label>
                </>
              )}

              {/* {folioData?.booking?.status} */}
              {billGenerated ? (
                <>
                  <div className="flex flex_gap_15 mt-20">
                    {/* <BgThemeButton
                      style={{ height: "30px" }}
                      onClick={() => getBillIframe()}
                      children={"View"}
                    /> */}
                    <div onClick={() => getBillIframe()} className="pointer">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M7 8.75C7.9665 8.75 8.75 7.9665 8.75 7C8.75 6.0335 7.9665 5.25 7 5.25C6.0335 5.25 5.25 6.0335 5.25 7C5.25 7.9665 6.0335 8.75 7 8.75Z"
                          fill="#3968ED"
                        />
                        <path
                          d="M13.5361 6.85125C13.0215 5.52022 12.1282 4.36914 10.9665 3.54029C9.80489 2.71143 8.42584 2.24113 6.99981 2.1875C5.57379 2.24113 4.19473 2.71143 3.03309 3.54029C1.87145 4.36914 0.978134 5.52022 0.463564 6.85125C0.428812 6.94737 0.428812 7.05263 0.463564 7.14875C0.978134 8.47978 1.87145 9.63086 3.03309 10.4597C4.19473 11.2886 5.57379 11.7589 6.99981 11.8125C8.42584 11.7589 9.80489 11.2886 10.9665 10.4597C12.1282 9.63086 13.0215 8.47978 13.5361 7.14875C13.5708 7.05263 13.5708 6.94737 13.5361 6.85125ZM6.99981 9.84375C6.43737 9.84375 5.88756 9.67697 5.41991 9.36449C4.95226 9.05202 4.58777 8.60788 4.37253 8.08826C4.15729 7.56863 4.10098 6.99685 4.21071 6.44521C4.32043 5.89358 4.59127 5.38687 4.98898 4.98917C5.38668 4.59146 5.89339 4.32062 6.44503 4.21089C6.99666 4.10117 7.56844 4.15748 8.08807 4.37272C8.6077 4.58795 9.05183 4.95245 9.36431 5.4201C9.67678 5.88775 9.84356 6.43756 9.84356 7C9.84241 7.75385 9.54243 8.4765 9.00937 9.00956C8.47631 9.54261 7.75367 9.84259 6.99981 9.84375Z"
                          fill="#3968ED"
                        />
                      </svg>
                    </div>

                    {/* <BgThemeButton
                      style={{ height: "30px" }}
                      onClick={handlePrintButtonClick}
                      children={"Print"}
                    /> */}

                    <div
                      onClick={() => getBillIframeDownload()}
                      className="pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M6.99957 10.1107L3.11068 6.22179L4.19957 5.09401L6.22179 7.11623V0.777344H7.77734V7.11623L9.79957 5.09401L10.8885 6.22179L6.99957 10.1107ZM2.3329 13.2218C1.90512 13.2218 1.53879 13.0693 1.2339 12.7645C0.929012 12.4596 0.776827 12.0935 0.777345 11.6662V9.3329H2.3329V11.6662H11.6662V9.3329H13.2218V11.6662C13.2218 12.094 13.0693 12.4603 12.7645 12.7652C12.4596 13.0701 12.0935 13.2223 11.6662 13.2218H2.3329Z"
                          fill="#3968ED"
                        />
                      </svg>
                    </div>

                    <PrintFolioRoomBill
                      folioId={folioData?.id}
                      htmlContent={iframeContent}
                    />

                    {/* <BgThemeButton
                      style={{ height: "30px" }}
                      onClick={handleDownloadPdf}
                      children={"Download PDF"}
                    /> */}
                    <br />
                  </div>
                  <BgThemeButton
                    style={{ height: "30px", marginTop: "10px" }}
                    onClick={() => handleSubmitGenerateBillHandle(true)}
                    children={"Re-Generate Bill"}
                    loader={loader}
                  />
                </>
              ) : (
                <>
                  <div className="flex flex_gap_10 mt-10">
                    {/* <BgThemeButton
                        style={{ height: "30px" }}
                        onClick={() => handleSubmitGenerateBillHandle(false)}
                        children={"Generate Bill"}
                      /> */}

                    {/* {currentDateSingle === propertyCurrentDate ? ( */}
                    <BgThemeButton
                      style={{ height: "30px" }}
                      onClick={() => handleSubmitGenerateBillHandle(false)}
                      children={"Generate Bill"}
                      loader={loader}
                    />
                    {/* ) : (
                      <Tooltip content={`Please close the current day.`}>
                        <BgThemeButtonDisabled
                          style={{ height: "30px" }}
                          onClick={() => handleSubmitGenerateBillHandle(false)}
                          children={"Generate Bill"}
                        />
                      </Tooltip>
                    )} */}
                  </div>
                </>
              )}
            </div>
          </div>

          {/* show edit  */}

          {showFolioSummeryEdit && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      marginBottom: "15px",
                      display: "flex",
                    }}
                  >
                    <div className="add-ota-model-container-heading">
                      Bill To
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginBottom: "15px",
                      gap: "10px",
                    }}
                  >
                    <div>
                      <label
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        <input
                          type="radio"
                          name="billRadioBtn"
                          value="Guest"
                          checked={selectedCheckBox === "Guest"}
                          onChange={handleCheckBoxesChange}
                        ></input>
                        Guest
                      </label>
                    </div>
                    <div>
                      <label
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        <input
                          type="radio"
                          name="billRadioBtn"
                          value="Company"
                          checked={selectedCheckBox === "Company"}
                          onChange={handleCheckBoxesChange}
                        ></input>
                        Company
                      </label>
                    </div>
                  </div>
                  <div className="form-container-div-half">
                    {/* {
                      selectedCheckBox === "Guest" &&
                        guestsArray.length > 0 && (
                          // <div className="form-container-div">
                          //   <VerticalInputSelectCustomCopy
                          //     options={guestsArray}
                          //     titleName="Select Customer"
                          //     name="customer"
                          //     value={billingDetailsFormData.customer}
                          //     onChange={handleBillingDetailsInputChange}
                          //   />
                          // </div>
                          <div
                            className="form-container-div"
                            style={{ position: "relative" }}
                          >
                            <div onClick={handleCustomerInputClick}>
                              <VerticalInputFieldCustom
                                onChange={handleBillingDetailsInputChange}
                                type={"text"}
                                name="customer"
                                required={true}
                                titleName="Customer"
                                value={billingDetailsFormData.name}
                              />
                            </div>
                            {openCustomersClickListPopup && (
                              <ul
                                className="filtered_countries_list"
                                ref={popupsRef}
                              >
                                {roomDetails.flatMap((room) =>
                                  room.guest_details.map(
                                    (suggestion, index) => (
                                      <li
                                        key={index}
                                        onClick={() =>
                                          handleSuggestionClick(
                                            "customer",
                                            suggestion.id,
                                            suggestion.guest_name
                                          )
                                        }
                                      >
                                        {suggestion.guest_name}
                                      </li>
                                    )
                                  )
                                )}
                              </ul>
                            )}
                            {openCustomersListPopup && (
                              <ul
                                className="filtered_countries_list"
                                ref={popupsRef}
                              >
                                {filteredCustomerSuggestions.map(
                                  (suggestion, index) => (
                                    <li
                                      key={index}
                                      onClick={() =>
                                        handleSuggestionClick(
                                          "customer",
                                          suggestion.id,
                                          suggestion.guest_name
                                        )
                                      }
                                    >
                                      {suggestion.guest_name}
                                    </li>
                                  )
                                )}
                              </ul>
                            )}
                          </div>
                        )
                      // : (
                      //   <div>No Guest Added</div>
                      // )
                    } */}

                    {selectedCheckBox === "Guest" && (
                      <div
                        className="form-container-div"
                        style={{ position: "relative" }}
                      >
                        <SearchComponent
                          searchArray={guestsArray}
                          keyName={"guest_name"}
                          getSelectedItem={getSelectedItem}
                          returnKeyName={"id"}
                          titleName="Select Customer"
                          // index={index}
                          selectedValue={billingDetailsFormData.name}

                          // disabledOptions={itemDetails.map((x) => x.item_name)}
                        />
                      </div>
                    )}

                    {
                      selectedCheckBox === "Company" &&
                        agentListOptions.length > 0 && (
                          <div
                            className="form-container-div"
                            style={{ position: "relative" }}
                          >
                            <SearchComponent
                              searchArray={agentLists}
                              keyName={"company_name"}
                              getSelectedItem={getSelectedItemAgent}
                              returnKeyName={"agent_information"}
                              titleName="Select Company"
                              // index={index}
                              selectedValue={billingDetailsFormData.name}

                              // disabledOptions={itemDetails.map((x) => x.item_name)}
                            />
                          </div>
                        )
                      // : (
                      //   <div>No Company Added</div>
                      // )
                    }
                    {/* {
                      selectedCheckBox === "Company" &&
                        agentListOptions.length > 0 && (
                          // <div className="form-container-div">
                          //   <VerticalInputSelectCustomCopy
                          //     options={agentListOptions}
                          //     titleName="Select Company"
                          //     name="company"
                          //     value={billingDetailsFormData.company}
                          //     onChange={handleBillingDetailsInputChange}
                          //   />
                          // </div>
                          <div
                            className="form-container-div"
                            style={{ position: "relative" }}
                          >
                            <div onClick={handleCompanyInputClick}>
                              <VerticalInputFieldCustom
                                onChange={handleBillingDetailsInputChange}
                                // onKeyDown={handleStateKeyDown}
                                type={"text"}
                                name="company"
                                focused={false}
                                required={true}
                                titleName="Company"
                                value={billingDetailsFormData.name}
                              />
                            </div>
                            {openCompanyClickListPopup && (
                              <ul
                                className="filtered_countries_list"
                                ref={popupsRef}
                              >
                                {agentLists.map((agent, index) => (
                                  <li
                                    key={index}
                                    onClick={() =>
                                      handleSuggestionClick(
                                        "company",
                                        agent.agent_information,
                                        agent.company_name
                                      )
                                    }
                                  >
                                    {agent.company_name}
                                  </li>
                                ))}
                              </ul>
                            )}
                            {openCompanyListPopup && (
                              <ul
                                className="filtered_countries_list"
                                ref={popupsRef}
                              >
                                {filteredCompanySuggestions.map(
                                  (suggestion, index) => (
                                    <li
                                      key={index}
                                      onClick={() =>
                                        handleSuggestionClick(
                                          "company",
                                          suggestion.agent_information,
                                          suggestion.company_name
                                        )
                                      }
                                    >
                                      {suggestion.company_name}
                                    </li>
                                  )
                                )}
                              </ul>
                            )}
                          </div>
                        )
                      // : (
                      //   <div>No Company Added</div>
                      // )
                    } */}

                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        titleName="Phone"
                        name="phone"
                        required={true}
                        type="number"
                        value={billingDetailsFormData.phone}
                        onChange={handleBillingDetailsInputChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        titleName="Email"
                        required={true}
                        name="email"
                        type="text"
                        value={billingDetailsFormData.email}
                        onChange={handleBillingDetailsInputChange}
                      />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    {/* <div className="form-container-div">
                      <VerticalInputFieldCustom
                        titleName="Country"
                        name="guest_country"
                        value={billingDetailsFormData.guest_country}
                        onChange={handleBillingDetailsInputChange}
                      />
                    </div>

                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        titleName="State"
                        name="guest_state"
                        value={billingDetailsFormData.guest_state}
                        onChange={handleBillingDetailsInputChange}
                      />
                    </div> */}
                    <CustomCountryStateSelector
                      // onSelect={handleSelectCountryState}
                      getCountryState={handleSelectCountryState}
                      defaultCountry={billingDetailsFormData.country}
                      defaultState={billingDetailsFormData.state}
                    />
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="City"
                        name="city"
                        value={billingDetailsFormData.city}
                        onChange={handleBillingDetailsInputChange}
                      />
                    </div>
                  </div>
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Address"
                        name="address"
                        value={billingDetailsFormData.address}
                        onChange={handleBillingDetailsInputChange}
                      />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        type="number"
                        required={true}
                        titleName="Pin Code / Zip Code"
                        name="pincode"
                        value={billingDetailsFormData.pincode}
                        onChange={handleBillingDetailsInputChange}
                      />
                    </div>

                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        titleName="GST No."
                        type="text"
                        name="gst_no"
                        value={billingDetailsFormData.gst_no}
                        onChange={handleBillingDetailsInputChange}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        onClick={(e) => handleSubmitBillToHandle(e)}
                        type="submit"
                        children={"Update"}
                      />
                      <BgOutlineThemeButton
                        onClick={() => setShowFolioSummeryEdit(false)}
                      >
                        Cancel
                      </BgOutlineThemeButton>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}

          {showBillPreview && (
            <div
              // style={{ height: "70vh", overflowY: "scroll", margin: "110px 0" }}
              className="editFolioSummeryBillContainer"
              onClick={() => setShowBillPreview(false)}
            >
              {/* <BillUi
                downloadPdf={downloadPdf}
                viewFolioBillsData={viewFolioBillsData}
              /> */}
              <IFrameBillUi
                downloadPdf={downloadPdf}
                viewFolioBillsData={viewFolioBillsData}
                iframeContent={iframeContent}
              />
              {/* <NewIframe
                folioData={folioData?.id}
                htmlContent={iframeContent}
              /> */}
              {/* <ViewFolioRoomBill
                folioData={folioData?.id}
                htmlContent={iframeContent}
              /> */}
              {/* <PopupPreview htmlContent={htmlContent} onClose={handleClosePreview} /> */}
            </div>
          )}

          {/* {showPreview && <NewIframe htmlContent={htmlContent} />} */}
        </>
      )}
      <iframe
        srcDoc={iframeContent}
        id="receipt"
        style={{ display: "none" }}
        title="Receipt"
      />
    </>
  );
};

export default FolioSummeryBill;
