// React Imports
import * as React from "react";

// Polaris Imports
import { LineChart } from "@shopify/polaris-viz";
import { Card, Icon } from "@shopify/polaris";
import "@shopify/polaris-viz/build/esm/styles.css";
import { ArrowDownMinor, ArrowUpMinor } from "@shopify/polaris-icons";
import Loader from "./UI/Loader/Loader";

const LineChartDashboard = ({ data, title }) => {
  const data1 = [
    {
      name: "Values in Thousand",
      color: "#7C9CF3",
      barMargin: "None",
      data: [
        { value: 2000, key: "Sold rooms =40" },
        { value: 2000, key: "Sep" },
      ],
    },
  ];

  // const [renderedChartData, setRenderedChartData] = React.useState(data);

  return (
    <div style={{ position: "relative" }}>
      <Card>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "40px",
            }}
          >
            <div className="report-chart-heading">{title}</div>
            {/* <div>
              Total Rooms:
              <span className="report-chart-heading">{data.comparison_from[0]}</span>
            </div> */}
          </div>

          <LineChart
            // annotations={[
            //   {
            //     startKey: "6",
            //     label: "Sales increase",
            //     axis: "x",
            //   },
            //   {
            //     startKey: "8000",
            //     label: "Sales increase",
            //     axis: "y",
            //   },
            // ]}
            theme="Light"
            isAnimated={true}
            data={data}
          />
        </div>
      </Card>
    </div>
  );
};

export default LineChartDashboard;
