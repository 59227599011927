// Polaris Imports
import {
  Button,
  Card,
  ChoiceList,
  HorizontalStack,
  Icon,
  IndexTable,
  Pagination,
  Tooltip,
  useIndexResourceState,
  Text,
} from "@shopify/polaris";
import BarCharts from "../BarCharts";
import { PolarisVizProvider } from "@shopify/polaris-viz";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  CheckWithoutBgUnderlineBtn,
  WithoutBgButtonBlue,
} from "../UI/Buttons";

import DatePicker from "react-datepicker";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import CustomProgressBar from "../CustomComponents/CustomProgressBar";
import CustomToggleButton from "../UI/CustomToggleButton";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { Page, LegacyCard, DataTable } from "@shopify/polaris";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import { useSelector } from "react-redux";
import Loader from "../UI/Loader/Loader";
import { useNavigate } from "react-router-dom";
import CompetitorsAfter from "../CompetitorsAfter";
import SelectDateRangeWithoutInput from "../CustomComponents/SelectDateRangeWithoutInput/SelectDateRangeWithoutInput";
import ShowConfirmedArrivals from "../UI/ShowConfirmedArrivals";
import ShowHouseCheckIn from "../UI/ShowHouseCheckIn";
import ShowDepartureCheckOut from "../UI/ShowDepartureCheckOut";
import NoDataFound from "../CustomComponents/NoDataFound";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import { current } from "@reduxjs/toolkit";
import LineChartDashboard from "../LineChartsDashboard";
import SelectDateRange from "../CustomComponents/SelectDateRange/SelectDateRange";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import ReactDatePicker from "react-datepicker";
import SelectDatePickerCustom from "../CustomComponents/SelectDateRange/SelectDatePickerCustom";
import SidebarInnerLists from "../CustomComponents/SidebarInnerLists";
import {
  permission_inner_lists,
  rooms_inner_lists,
} from "../../assets/constant";
import { CSVLink } from "react-csv";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import CustomRoomChoiceSelect from "../CustomComponents/CustomRoomChoiceSelect/CustomRoomChoiceSelect";
import ReportCompetitorsAnalysisTable from "./ReportCompetitorsAnalysisTable";
import { getDecryptedData } from "../../utils/encryptStorage";

Chart.register(ArcElement);

const CompetitorsAnalysis = () => {
  const currentDate = new Date().toISOString().split("T")[0];
  const [fetchedData, setFetchedData] = useState(null);
  const [openDayStatus, setOpenDayStatus] = useState();
  const [showDashBoardCheckIn, setShowDashBoardCheckIn] = useState(false);
  const [showDashBoardCheckOut, setShowDashBoardCheckOut] = useState(false);
  const [showDashboardAllCheckIn, setShowDashboardAllCheckIn] = useState(false);
  const [showManualSearchInput, setShowManualSearchInput] = useState(false);
  const [showWebInput, setShowWebInput] = useState(false);
  const [showBookingDetails, setShowBookingDetails] = useState(false);

  const [showCheckOutBookingDetails, setShowCheckOutBookingDetails] =
    useState(false);
  const [
    showCheckOutIdClickBookingDetails,
    setShowCheckOutIdClickBookingDetails,
  ] = useState(false);

  const [settlementDoneCheckOut, setSettlementDoneCheckOut] = useState(false);

  const [showCheckOutSettlementDetails, setShowCheckOutSettlementDetails] =
    useState(false);
  const [openBookingDetails, setOpenBookingDetails] = useState(true);
  const userToken = getDecryptedData('encryptToken');

  const [bookingDetails, setBookingDetails] = useState([]);
  const [allSettlementsData, setAllSettlementsData] = useState([]);

  // show click arrow buttons
  const [showArrivalConfirmed, setShowArrivalConfirmed] = useState(false);
  const [showAllSettlement, setShowAllSettlement] = useState(false);
  const [showInHouseCheckIn, setShowInHouseCheckIn] = useState(false);
  const [showDepartureCheckOut, setShowDepartureCheckOut] = useState(false);

  const [checkOutBookingDetail, setCheckOutBookingDetail] = useState([]);

  const property_id = useSelector((state) => state.property.value);

  const [dayTypeFilter, setDayTypeFilter] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [valueArray, setValueArray] = useState([]);
  const accordRef = useRef(null);

  const toggleAccord = () => {
    setIsOpen(!isOpen);
    setIsOpen1(false);
    setIsOpen2(false);
  };
  const toggleAccord1 = () => {
    setIsOpen(false);
    setIsOpen1(!isOpen1);
    setIsOpen2(false);
  };
  const toggleAccord2 = () => {
    setIsOpen2(!isOpen2);
    // setIsOpen1(false);
    // setIsOpen(false);
  };
  const toggleAccord3 = () => {
    setIsOpen3(!isOpen3);
    setIsOpen1(false);
    setIsOpen(false);
  };

  const permissions = JSON.parse(localStorage.getItem("permissions"));

  // Reservations
  const dateOptions = [
    { id: 0, value: "-1", label: "Today" },
    { id: 0, value: "-2", label: "Yesterday" },
    { id: 1, value: "-7", label: "Past 7 Days" },
    { id: 2, value: "-30", label: "Past 30 Days" },
    { id: 3, value: "-90", label: "Past 90 Days" },
    { id: 4, value: "-1", label: "Last Year" },
    { id: 5, value: "-3", label: "Last 3 year" },
  ];
  // Reservations
  const dateOptions1 = [
    { id: 0, value: "revenue", label: "Revenue" },
    { id: 1, value: "profit", label: "Profit" },
    { id: 2, value: "reservation", label: "Reservation" },
    { id: 3, value: "expense", label: "Expense" },
    { id: 4, value: "event", label: "Event" },
    { id: 5, value: "f&b", label: "F&B" },
  ];

  const [allRoomTypes, setAllRoomTypes] = useState([]);
  const fetchAllRoomTypes = async (propertyId) => {
    try {
      // setLoader(true);
      const response = await fetch(
        // `${process.env.REACT_APP_BASE_URL}/api/v1/event/update/${id}/${property_id?.id}`
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAllRoomTypes(data.rooms);

      const roomIds = data.rooms.map((room) => room.id);
      setSelectedRoomType(roomIds);
      if (property_id) {
        fetchInfo(property_id?.id, roomIds, selectedRoomType);
      }
      // setLoader(false);
    } catch (error) {
      // setLoader(false);
      console.error(error);
    }
  };

  const choiceListRoomsArray = allRoomTypes.map((room) => {
    return { label: room.room_name, value: room.id };
  });

  const monthWiseChoiceListArray = [
    { label: "Jan", value: "1" },
    { label: "Feb", value: "2" },
    { label: "Mar", value: "3" },
    { label: "Apr", value: "4" },
    { label: "May", value: "5" },
    { label: "Jun", value: "6" },
    { label: "Jul", value: "7" },
    { label: "Aug", value: "8" },
    { label: "Sep", value: "9" },
    { label: "Oct", value: "10" },
    { label: "Nov", value: "11" },
    { label: "Dec", value: "12" },
  ];

  const monthArray = monthWiseChoiceListArray.map((month) => {
    return [{ label: month.label }];
  });

  // console.log(monthArray);

  const roomIdsArray = allRoomTypes.map((room) => room.id);

  const handleAccordClick = (id, label, value) => {
    setValueArray([{ id: id, label: label, value: value }]);
    setDayTypeFilter(value);
  };

  const handleShowDashBoardCheckIn = (e) => {
    e.stopPropagation();
    setShowDashBoardCheckIn(!showDashBoardCheckIn);
  };

  const handleShowDashBoardCheckOut = (e) => {
    e.stopPropagation();
    setBookingId("");
    setShowDashBoardCheckOut(!showDashBoardCheckOut);
  };

  const handleShowManualSearchInput = () => {
    {
      showWebInput === true && setShowWebInput(false);
    }
    setShowManualSearchInput(!showManualSearchInput);
  };

  const handleShowWebInput = () => {
    {
      showManualSearchInput === true && setShowManualSearchInput(false);
    }
    setShowWebInput(!showWebInput);
  };

  const [bookingOpenIndex, setOpenBookingIndex] = useState(1);
  const [bookingAccordianActive, setBookingAccordianActive] = useState("");

  const handleOpenBookigDetails = (idx) => {
    setOpenBookingIndex(idx);
    if (bookingOpenIndex === idx) {
      setBookingAccordianActive("active");
    } else {
      setBookingAccordianActive("");
    }
  };

  const handleShowDashBoardAllCheckIn = () => {
    setShowDashboardAllCheckIn(!showDashboardAllCheckIn);
  };

  const [loader, setLoader] = useState(true);

  const navigate = useNavigate();

  const [selectedDiscountMethod, setSelectedDiscountMethod] =
    useState("automatic");

  const handleDiscountMethodChange = (value) => {
    setSelectedDiscountMethod(value);
  };

  const [selectedRoomType, setSelectedRoomType] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedBalanceType, setSelectedBalanceType] = useState([]);
  const [selectedBookingStatus, setSelectedBookingStatus] = useState([
    "in-house",
  ]);

  const handleRoomTypeStatus = useCallback(
    (value) => setSelectedRoomType(value),
    []
  );
  const handleBalanceTypeStatus = useCallback(
    (value) => setSelectedBalanceType(value),
    []
  );
  const handleBookingStatus = useCallback(
    (value) => setSelectedBookingStatus(value),
    []
  );

  // const currentDate = new Date().toISOString().split("T")[0];

  const calculateToDate = (fromDate) => {
    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() - 15);
    return toDate.toISOString().split("T")[0];
  };
  const calculatePlusToDate = (fromDate) => {
    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() + 15);
    return toDate.toISOString().split("T")[0];
  };

  const [selectedDatesRange, setSelectedDatesRange] = useState({
    from_date: calculateToDate(currentDate),
    to_date: calculatePlusToDate(currentDate),
  });
  const [comparisonStartDateRange, setComparisonStartDateRange] = useState({
    from_date: calculateToDate(currentDate),
    to_date: currentDate,
  });
  const [comparisonEndDateRange, setComparisonEndDateRange] = useState({
    from_date: calculateToDate(currentDate),
    to_date: currentDate,
  });

  const dataFromChild = (from_date, to_date) => {
    setSelectedDatesRange({
      from_date: from_date,
      to_date: to_date,
    });
  };
  const dataFromChild1 = (from_date, to_date) => {
    setComparisonStartDateRange({
      from_date: from_date,
      to_date: to_date,
    });
  };
  const dataFromChild2 = (from_date, to_date) => {
    setComparisonEndDateRange({
      from_date: from_date,
      to_date: to_date,
    });
  };

  useEffect(() => {
    if (property_id) {
      // fetchDashboardInfo(property_id.id, dayTypeFilter);
      fetchAllRoomTypes(property_id.id);
    }
  }, [property_id]);

  const openDayStatusHandler = useCallback((data) => {
    setOpenDayStatus(data);
  }, []);

  const chart_data = {
    datasets: [
      {
        data: [fetchedData?.total_revenue, fetchedData?.foodSale, 0],
        backgroundColor: ["#3968ED", "#15AA12", "#CCCCCC"],
        display: true,
      },
    ],
  };

  const [bookingId, setBookingId] = useState("");

  const [openAccordion, setOpenAccordion] = useState("first");

  // UseState for forms
  const [settlementAddData, setSettlementAddData] = useState({
    settlement_name: "",
    payment_mode: "",
    notes: "",
    date: "",
    amount: "",
    property_id: property_id?.id,
    other_payment_mode: "",
  });

  const paymentModeOptions = [
    { label: "Select Option" },
    { label: "Cash", value: "cash" },
    { label: "Bank Payment", value: "bank_payment" },
    { label: "UPI", value: "upi" },
    { label: "Credit Card", value: "credit_card" },
    { label: "Debit Card", value: "debit_card" },
    { label: "Other", value: "other" },
  ];

  const reportFilterOptions = [
    { label: "Select Option" },
    { label: "Arrival", value: "arrival" },
    { label: "Departure", value: "departure" },
    { label: "In House", value: "in_house" },
    // { label: "RevPar", value: "rev_par" },
  ];

  const yearFilterOptions = [
    // { label: "Select Option" },
    { label: "Date", value: "date" },
    { label: "Month", value: "month" },
  ];

  const reportTypeOptions = [
    { label: "Select Option" },
    { label: "Achieved Revenue", value: "achieved_revenue" },
    { label: "Achieved Occupancy", value: "achieved_occupancy" },
    { label: "Achieved RevPar", value: "achieved_revpar" },
    { label: "Achieved AR", value: "achieved_ar" },
    { label: "Competitors Reports", value: "competitor_reports" },
  ];

  const renderYearContent = (year) => {
    const tooltipText = `Tooltip for year: ${year}`;
    return <span title={tooltipText}>{year}</span>;
  };

  useEffect(() => {
    document.title = "RevCatalyst - Boosting Your Hotel Performance";

    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property.";
  }, []);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [orders, setOrders] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [filterName, setFilterName] = useState("Comparison");
  const [filterType, setFilterType] = useState("date");
  const [reportType, setReportType] = useState("achieved_revenue");

  const [urlFilter, setUrlFilter] = useState("occupancy");

  const handleUrlInnerFilter = useCallback(
    (e) => {
      if (e === "payment_ledger_report") {
        setUrlFilter("payment");
      } else {
        setUrlFilter("occupancy");
      }
    },
    [urlFilter]
  );

  const generateYearOptions = (currentYear) => {
    const numYears = 3;
    const startYear = currentYear - numYears;
    const endYear = currentYear;

    const years = [];
    years.push({ label: "Select a year", value: "" });
    for (let year = startYear; year <= endYear; year++) {
      years.push({ label: year.toString(), value: year });
    }

    return years;
  };

  const data = [
    {
      name: "Booking",
      color: "#7C9CF3",
      data: chartData,
    },
  ];

  // Example usage:
  const currentYear = new Date().getFullYear();
  const yearOptions = generateYearOptions(currentYear);

  const fetchInfo = async (propertyId, roomIds) => {


    try {
      setLoader(true);
      setChartData([]);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/achieved-target-report/${propertyId}?filter_type=${filterType}&report_type=${reportType}&from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&selectedMonths=${selectedMonths}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedMonths,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
        setLoader(false);
      } else {
        showErrorToast(data.message);
        setLoader(false);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchFilterInfo = async (propertyId, roomIds) => {
    try {
      setLoader(true);
      setChartData([]);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/achieved-target-report/${propertyId}?filter_type=${filterType}&report_type=${reportType}&from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&selectedMonths=${selectedMonths}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedMonths,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
        setLoader(false);
      } else {
        showErrorToast(data.message);
        setLoader(true);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };


  useEffect(() => {
    setChartData(fullData?.reportData);
  }, [orders, fullData]);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(orders);




  const otherMenusRef = useRef(null);
  const handleClickOutside = (event) => {
    if (
      otherMenusRef.current &&
      !otherMenusRef.current.contains(event.target)
    ) {
      setIsOpen(false);
      setIsOpen1(false);
      setIsOpen2(false);
      setIsOpen3(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const getDataFromCustomRoomChoiceSelect = (data) => {
    setSelectedMonths(data);
  };


  return (
    <>
      <SidebarInnerLists innerLists={permission_inner_lists} />

      {loader ? (
        <Loader />
      ) : (
        <>
          {/* <CSVLink data={orders}>Download me</CSVLink>; */}
          <section className="dashboard_page">
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "15px 0",
                }}
              >
                <div
                  style={{ fontSize: "20px" }}
                  className="report-chart-heading"
                >
                  Competitors Analysis
                </div>
              </div>
              <div>
                <div
                  style={{
                    background: "rgba(255, 255, 255, 1)",
                    padding: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <div title="Reservation Insights">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: "10px",
                      }}
                    >
                      <div className="report-chart-heading">Filters</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                      }}
                    >
                      <div className="flex flex_gap_10">
                        <div style={{ width: "150px" }}>
                          <VerticalInputSelectCustomCopy
                            titleName={"Filter Name"}
                            value={filterType}
                            defaultValue={"date"}
                            onChange={(e) => {
                              setFilterType(e.target.value);
                              handleUrlInnerFilter(e.target.value);
                              setSelectedRoomType([]);
                              setBookingDetails([]);
                              setSelectedBookingStatus([]);
                              setOrders([]);
                            }}
                            options={yearFilterOptions}
                          />
                        </div>

                        <div style={{ width: "150px" }}>
                          <VerticalInputSelectCustomCopy
                            titleName={"Report Type"}
                            value={reportType}
                            defaultValue={"month"}
                            onChange={(e) => {
                              setReportType(e.target.value);
                              handleUrlInnerFilter(e.target.value);
                              setSelectedRoomType([]);
                              setBookingDetails([]);
                              setSelectedBookingStatus([]);
                              setOrders([]);
                            }}
                            options={reportTypeOptions}
                          />
                        </div>

                        {filterType === "date" && (
                          <div style={{ width: "200px" }}>
                            <div style={{ marginBottom: "5px" }}>
                              Date Range
                            </div>
                            <SelectDateRange
                              minDate={false}
                              dataFromChild={dataFromChild}
                              defaultDate={`${calculateToDate(
                                currentDate
                              )}:${calculatePlusToDate(currentDate)}`}
                            />
                          </div>
                        )}

                        {filterType === "month" && (
                          <div style={{ width: "210px" }}>
                            <CustomRoomChoiceSelect
                              roomChoice={monthWiseChoiceListArray}
                              getDataFromCustomRoomChoiceSelect={
                                getDataFromCustomRoomChoiceSelect
                              }
                              selectedRoomsIds={selectedMonths}
                              heading="Months"
                              showButtons={false}
                            />
                          </div>
                        )}
                      </div>

                      <div className="flex flex_gap_10">
                        <BgThemeButton
                          children={"Apply"}
                          loader={loader}
                          onClick={() => fetchFilterInfo(property_id.id)}
                        />
                        <BgOutlineThemeButton
                          onClick={() => {
                            setFilterName("");
                            setOrders([]);
                          }}
                          type={"button"}
                          children={"Clear"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ReportCompetitorsAnalysisTable
                reportType={reportType}
                departureReport={orders}
                filterType={filterType}
              />
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default CompetitorsAnalysis;
