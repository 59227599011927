import React from "react";
import EditBlogComp from "../../Components/Websites/EditBlogComp";

const EditBlog = () => {
  return (
    <>
      <div className="main_container">
        <EditBlogComp />
      </div>
    </>
  );
};

export default EditBlog;
