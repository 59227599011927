const NoInternetConnection = () => {
  return (
    <div style={{ marginTop: "100px" }} className="upgrade-bg-full-container">
      <div className="upgrade-full-container">
        <div className="upgrade-full-container-div" style={{}}>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="125"
              height="130"
              viewBox="0 0 125 130"
              fill="none"
            >
              <path
                d="M39.3011 14.0566H3.92568C1.77269 14.0566 0.0273438 15.802 0.0273438 17.955V28.4963C0.0273438 30.6493 1.77269 32.3947 3.92568 32.3947H39.3011C41.454 32.3947 43.1994 30.6493 43.1994 28.4963V17.955C43.1994 15.802 41.454 14.0566 39.3011 14.0566Z"
                fill="#375DEB"
              />
              <path
                d="M37.2065 16.5107H6.01976C4.30507 16.5107 2.91504 17.9008 2.91504 19.6155V26.8334C2.91504 28.5481 4.30507 29.9381 6.01976 29.9381H37.2065C38.9212 29.9381 40.3112 28.5481 40.3112 26.8334V19.6155C40.3112 17.9008 38.9212 16.5107 37.2065 16.5107Z"
                fill="#C5C5C5"
              />
              <path
                d="M37.2065 30.1266H6.01979C4.20446 30.1266 2.72656 28.6487 2.72656 26.8334V19.6135C2.72656 17.7982 4.20446 16.3203 6.01979 16.3203H37.2084C39.0237 16.3203 40.5016 17.7982 40.5016 19.6135V26.8334C40.5016 28.6487 39.0237 30.1266 37.2084 30.1266H37.2065ZM6.01979 16.6992C4.41182 16.6992 3.10358 18.0075 3.10358 19.6154V26.8353C3.10358 28.4432 4.41182 29.7515 6.01979 29.7515H37.2084C38.8164 29.7515 40.1246 28.4432 40.1246 26.8353V19.6154C40.1246 18.0075 38.8164 16.6992 37.2084 16.6992H6.01979Z"
                fill="white"
              />
              <path
                d="M24.5015 32.3926H18.7256V44.6286H24.5015V32.3926Z"
                fill="#375DEB"
              />
              <path
                d="M24.5015 44.6289H18.7256V53.7357H24.5015V44.6289Z"
                fill="#C5C5C5"
              />
              <path
                d="M24.5015 53.7354H18.7256V65.9714H24.5015V53.7354Z"
                fill="#375DEB"
              />
              <path
                d="M24.5015 65.9717H18.7256V75.0785H24.5015V65.9717Z"
                fill="#C5C5C5"
              />
              <path
                d="M30.2056 124.803C30.2056 124.803 73.9563 94.3365 62.8382 79.4652C62.8382 79.4652 68.7573 78.4548 76.8443 59.1063C76.8443 59.1063 79.5871 55.4964 84.2074 54.3409C84.2074 54.3409 95.631 48.614 103.925 34.5117C103.925 34.5117 99.2239 50.1541 82.0414 59.8283C82.0414 59.8283 84.2074 79.1767 73.3776 83.0751C73.3776 83.0751 74.388 102.567 30.2056 124.803Z"
                fill="#BABABA"
              />
              <path
                d="M27.8774 124.48H30.509C30.509 124.48 73.3756 103.624 73.0872 82.9277L50.4833 76.1904C50.4833 76.1904 9.23588 76.6391 27.8774 113.21H29.3365C29.3365 113.21 24.8613 84.6601 50.1289 88.7036C50.1289 88.7036 68.8665 89.6499 27.8774 124.48Z"
                fill="#2A2939"
              />
              <path
                d="M24.5015 75.0781H18.7256V87.3142H24.5015V75.0781Z"
                fill="#375DEB"
              />
              <path
                d="M24.5015 87.3145H18.7256V96.4213H24.5015V87.3145Z"
                fill="#C5C5C5"
              />
              <path
                d="M24.5015 96.4209H18.7256V108.657H24.5015V96.4209Z"
                fill="#375DEB"
              />
              <path
                d="M24.5015 117.764H18.7256V130H24.5015V117.764Z"
                fill="#375DEB"
              />
              <path
                d="M24.5015 108.657H18.7256V117.764H24.5015V108.657Z"
                fill="#C5C5C5"
              />
              <path
                d="M111.015 1.82951C111.015 1.82951 115.298 -1.29972 119.871 0.626827C119.871 0.626827 124.107 2.26307 124.972 7.17182L123.336 8.03895C123.336 8.03895 121.892 3.46575 118.861 2.69664C118.861 2.69664 114.913 1.25267 112.267 3.6109L111.015 1.82951Z"
                fill="#375DEB"
              />
              <path
                d="M113.662 7.55856C113.662 7.55856 117.513 5.24746 119.292 9.77165L121.217 9.1458C121.217 9.1458 119.628 2.5518 112.794 5.53588L113.662 7.55668V7.55856Z"
                fill="#375DEB"
              />
              <path
                d="M115.824 11.8863C116.582 11.8863 117.196 11.2719 117.196 10.5139C117.196 9.75602 116.582 9.1416 115.824 9.1416C115.066 9.1416 114.452 9.75602 114.452 10.5139C114.452 11.2719 115.066 11.8863 115.824 11.8863Z"
                fill="#375DEB"
              />
              <path
                d="M27.2685 62.9064V60.9817C27.2685 60.9817 51.045 59.5378 65.0982 54.7251C65.0982 54.7251 69.1097 57.3567 71.4509 52.5121C71.4509 52.5121 88.8427 46.8323 101.612 34.6396H103.987C103.987 34.6396 99.1748 50.6402 82.0394 59.8544C82.0394 59.8544 84.7351 80.1379 73.1833 82.8335C73.1833 82.8335 51.4295 76.6731 51.0431 76.2886C50.6566 75.904 63.2678 68.2996 57.1074 64.7368C57.1074 64.7368 31.3101 65.1214 27.2666 62.9083L27.2685 62.9064Z"
                fill="#C5C5C5"
              />
              <path
                d="M63.3017 49.976L62.3384 46.4472L66.3178 42.4678L68.0502 44.3283C68.0502 44.3283 71.7713 47.4727 69.5262 51.1957C69.5262 51.1957 70.103 52.5435 71.1945 52.5435C71.1945 52.5435 72.6064 54.0836 69.9748 55.8801C69.9748 55.8801 65.4827 56.4569 65.034 54.6605C65.034 54.6605 65.675 53.7613 65.675 52.7999L64.7117 52.0289L64.0067 52.2853L62.3384 51.4502V49.6537L63.3017 49.9742V49.976Z"
                fill="#94575F"
              />
              <path
                d="M68.1144 44.7778C68.1144 44.7778 65.5469 42.4026 62.8532 46.7025C62.8532 46.7025 62.5968 47.6017 63.5582 49.8468C63.5582 49.8468 61.183 50.2954 63.7505 52.0919C63.7505 52.0919 64.3914 52.4124 64.6496 51.5792C64.6496 51.5792 65.8957 52.2842 65.3358 52.7988C65.3358 52.7988 54.1253 50.4236 60.4139 43.0454C60.4139 43.0454 63.0454 40.4779 65.1624 41.2489C65.1624 41.2489 66.7666 41.5053 67.4735 40.0293C67.4735 40.0293 70.746 40.9926 68.1144 44.7778Z"
                fill="#2A2939"
              />
              <path
                d="M64.7139 41.1201C64.7139 41.1201 56.4365 43.3012 58.4893 48.1156C58.4893 48.1156 60.8645 52.736 65.613 52.736L64.6498 51.5163C64.6498 51.5163 63.9447 52.736 62.8533 51.5163C62.8533 51.5163 62.0823 50.489 62.7892 50.1685C62.7892 50.1685 57.8484 45.5482 64.7139 41.1201Z"
                fill="#2A2939"
              />
              <path
                d="M24.0283 126.792L28.0718 124.48H30.2528V127.112C30.2528 127.112 28.2 128.78 24.0283 130V126.792Z"
                fill="#2A2939"
              />
              <path
                d="M104.47 31.3331L99.2482 31.8628C98.2943 31.9589 97.5667 31.0239 97.8966 30.1228L103.201 15.6172C103.369 15.161 103.776 14.8349 104.259 14.7745L110.862 13.9432C111.869 13.8169 112.627 14.8424 112.208 15.768L105.522 30.5753C105.331 30.9975 104.93 31.2859 104.468 31.3331H104.47Z"
                fill="#2A2939"
              />
              <path
                d="M100.13 28.0645C100.993 26.8873 101.379 25.7034 100.993 25.4203C100.606 25.1372 99.5937 25.862 98.7308 27.0393C97.868 28.2165 97.4816 29.4004 97.8678 29.6835C98.2541 29.9666 99.2667 29.2418 100.13 28.0645Z"
                fill="#94575F"
              />
              <path
                d="M27.8135 113.209C27.8135 113.209 26.3374 123.838 28.969 119.153L31.2141 114.661C31.2141 114.661 30.8296 113.783 29.0972 112.682L27.8135 113.209Z"
                fill="#2A2939"
              />
              <path
                d="M101.805 34.5109H103.922C103.922 34.5109 107.451 28.9273 108.093 27.3231C108.093 27.3231 108.222 26.8574 107.549 26.6293C107.549 26.6293 109.413 25.6303 107.958 25.1137C107.958 25.1137 109.895 24.224 108.583 23.2852C108.583 23.2852 108.103 23.0081 107.211 23.1043C107.211 23.1043 105.526 23.8903 107.019 24.7405C107.019 24.7405 105.021 25.3173 106.706 26.618C106.706 26.618 104.155 27.0026 106.225 28.4221C106.225 28.4221 105.656 30.9801 104.564 30.9801L100.521 31.5569C100.521 31.5569 100.97 34.509 101.805 34.509V34.5109Z"
                fill="#94575F"
              />
              <path
                d="M27.1724 61.0301V63.0999L24.3335 63.9651V59.1035L27.1724 61.0301Z"
                fill="#94575F"
              />
              <path
                d="M18.8459 58.7197V59.8753C18.8459 59.8753 21.8299 60.3088 19.6169 61.7999C19.6169 61.7999 21.6867 62.6671 19.6414 63.4362C19.6414 63.4362 21.9751 64.5917 18.8478 65.2176C18.8478 65.2176 15.4791 63.0516 18.8478 58.7197H18.8459Z"
                fill="#94575F"
              />
              <path
                d="M29.0972 74.9387C29.0972 74.9387 89.7401 85.1426 110.148 42.21C110.148 42.21 96.8638 78.788 29.0972 74.9387Z"
                fill="#375DEB"
              />
              <path
                d="M71.4512 80.1373C71.4512 80.1373 90.8958 79.7527 101.484 62.8115C101.484 62.8115 90.7035 77.2512 71.4512 80.1373Z"
                fill="#375DEB"
              />
            </svg>
          </div>
          <div style={{ fontSize: "26px", fontWeight: "600" }}>
            No Internet Connection
          </div>

          <div>
            Unable to connect to the internet. Please check your network and try
            again....!!!
          </div>

          {/* <div style={{ marginTop: "-10px" }}>
            <BgThemeButton children={"(+91) 80918 05354"}></BgThemeButton>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default NoInternetConnection;
