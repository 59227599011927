import React, { useEffect, useRef } from "react";
import { NavLink, Outlet } from "react-router-dom";
import NavTabs from "../NewCustomComp/NavTabs/NavTabs";

const BookingEngineInventoryPage = () => {
  const ref = useRef(null);

  useEffect(() => {
    // Set the meta title
    document.title =
      "Room Type - RevCatalyst - Boosting Your Hotel Performance";

    // Create or update the meta description tag
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property.";


    

  
  }, []);

  const links = [
    // { label: "Rooms", path: "rooms" },
    { label: "Room Types", to: "room-types" },
    { label: "Meal Plans", to: "meal-plans" },
    { label: "Room Types Plan", to: "room-rate-plans" },
    { label: "Inventory", to: "inventory" },
    { label: "Rates", to: "inventory-rates" },

  ];


 


 




  return (
    <div className="main_container">

      <NavTabs mainData={links}/>
      <div style={{ marginTop: "20px" }}>
        <Outlet />
      </div>
    </div>
  );
};

export default BookingEngineInventoryPage;
