import React, { useState, useImperativeHandle, forwardRef } from "react";
import CustomToast from "./CustomToast";
import "./Toastify.css";

const CustomToastContainer = forwardRef((props, ref) => {
  const defaultPosition = "top-center";
  const [toasts, setToasts] = useState([]);

  useImperativeHandle(ref, () => ({
    addToast(type, message, duration = 3000, animation = "fadeIn") {
      const id = Date.now();
      setToasts((prevToasts) => [
        ...prevToasts,
        { id, type, message, duration, animation },
      ]);
    },
  }));

  const removeToast = (id) => {
    setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
  };

  const position = props.position || defaultPosition;

  return (
    <div className={`toast-container ${position} ${props.rtl ? "rtl" : ""}`}>
      {toasts.map((toast, index) => (
        <CustomToast
          key={toast.id}
          id={index}
          type={toast.type}
          position={position}
          message={toast.message}
          duration={toast.duration}
          animation={toast.animation}
          onClose={() => removeToast(toast.id)}
        />
      ))}
    </div>
  );
});

export default CustomToastContainer;
