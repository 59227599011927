import React from "react";
import Otas from "../Components/Otas";

const OtasPage = () => {
  return (
    <>
      <div className="main_container">
        <Otas />
      </div>
    </>
  );
};

export default OtasPage;
