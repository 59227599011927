import { useState } from "react";
import PreviewCommunicationBillCopy from "../../Components/Communication/PreviewCommunicationBillCopy";

const PreviewCommunicationBillPage = () => {
  const [showShareOnEmail, setShowShareOnEmail] = useState(false);
  const [showShareOnWhatsapp, setShowShareWhatsapp] = useState(false);

  const handleShowShareOnEmail = () => {
    {
      showShareOnWhatsapp === true && setShowShareWhatsapp(false);
    }
    setShowShareOnEmail(!showShareOnEmail);
  };

  const handleShowShareOnWhatsapp = () => {
    {
      showShareOnEmail === true && setShowShareOnEmail(false);
    }
    setShowShareWhatsapp(!showShareOnWhatsapp);
  };
  
  return (
    <>
      <div className="main_container">
        {/* <PreviewBill /> */}
        <PreviewCommunicationBillCopy />
      </div>
    </>
  );
};
export default PreviewCommunicationBillPage;
