import React, { useEffect, useState } from "react";
import HandleNaN from "../CustomComponents/HandleNaN/HandleNaN";
import { checkFolioSummery, formatIndianCurrency } from "../../utils/utils";
import { useSelector } from "react-redux";
import { Tooltip } from "@shopify/polaris";
import { getDecryptedData } from "../../utils/encryptStorage";

const SidebarChargeAmounts = ({ chargesList, folioData, style }) => {
  const userToken = getDecryptedData('encryptToken');
  const role = JSON.parse(localStorage.getItem("role"));
  const property_id = useSelector((state) => state.property.value);

  const [chargeAddedTransactions, setChargeAddedTransactions] = useState({
    discountAmountSum: "",
    subTotalSum: "",
    taxAmountSum: "",
    grandTotalSum: "",
    paidAmountSum: "",
    dueAmountSum: "",
  });

  useEffect(() => {
    const discountAmountSum = chargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.discount_amount || 0),
      0
    );
    const subTotalSum = chargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.sub_total),
      0
    );
    const taxAmountSum = chargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.tax_amount),
      0
    );
    const grandTotalSum = chargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.net_total),
      0
    );
    const paidAmountSum = folioData?.payments
      ?.filter((payment) => payment?.is_cancelled === 0)
      .reduce(
        (sum, transaction) => sum + parseFloat(transaction.amount || 0),
        0
      );
    const dueAmountSum = chargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.due_amount || 0),
      0
    );

    const transactions = {
      discountAmountSum,
      subTotalSum,
      taxAmountSum,
      grandTotalSum,
      paidAmountSum,
      dueAmountSum,
    };
    setChargeAddedTransactions(transactions);
  }, [chargesList]);


  return (
    <>
      <div
        className="flex gap_7 folio_total_amount_div_sidebar flex_direction_col folio_total_amount_div_sidebar"
        style={{ padding: "20px", ...style }}
      >
        <div style={{ fontWeight: "700" }}>Price BreakUp</div>
        <div className="flex justify_content_between">
          <p className="">Discount Amt.</p>
          <h4 className="">
            {formatIndianCurrency(chargeAddedTransactions.discountAmountSum)}
          </h4>
        </div>
        <div className="flex justify_content_between">
          <p className="folio_amount_title">Subtotal</p>
          <h4 className="folio_amount_value">
            {formatIndianCurrency(chargeAddedTransactions.subTotalSum)}
          </h4>
        </div>
        <div className="flex justify_content_between">
          <p className="folio_amount_title">Tax Amt.</p>
          <h4 className="folio_amount_value">
            {formatIndianCurrency(chargeAddedTransactions.taxAmountSum)}
          </h4>
        </div>
        <div className="flex justify_content_between folio_bottom_grand_total_amount">
          <p className="folio_amount_title">Grand Total</p>
          <h4 className="folio_amount_value">
            {formatIndianCurrency(chargeAddedTransactions.grandTotalSum)}
          </h4>
        </div>
        <div className="flex justify_content_between folio_bottom_paid_amount">
          <p className="folio_amount_title">Paid Amt.</p>
          <h4 className="folio_amount_value">
            {formatIndianCurrency(chargeAddedTransactions.paidAmountSum)}
          </h4>
        </div>
        {chargeAddedTransactions.grandTotalSum >
          chargeAddedTransactions.paidAmountSum && (
          <div className="flex justify_content_between">
            <p className="folio_amount_title">Due</p>
            <h4 className="folio_amount_value">
              {chargesList?.length < 1 ||
              Math.sign(
                chargeAddedTransactions.grandTotalSum -
                  chargeAddedTransactions.paidAmountSum
              ) === -1 ? (
                <>{formatIndianCurrency(0)}</>
              ) : (
                <>
                  {formatIndianCurrency(
                    chargeAddedTransactions.grandTotalSum -
                      chargeAddedTransactions.paidAmountSum
                  )}
                </>
              )}
            </h4>
          </div>
        )}
        {chargeAddedTransactions.grandTotalSum <
          chargeAddedTransactions.paidAmountSum && (
          <div className="flex justify_content_between folio_bottom_due_amount">
            <p className="folio_amount_title">Advance</p>
            <h4 className="folio_amount_value">
              {chargesList?.length < 1 ? (
                <>
                  <HandleNaN
                    value={chargeAddedTransactions.paidAmountSum || 0}
                    decimal={true}
                  />
                </>
              ) : (
                <>
                  <HandleNaN
                    decimal={true}
                    value={
                      chargeAddedTransactions.paidAmountSum -
                      chargeAddedTransactions.grandTotalSum
                    }
                  />
                </>
              )}
            </h4>
          </div>
        )}
        {/* <div
          onClick={() =>
            checkFolioSummery(
              userToken,
              folioData?.id,
              property_id?.id,
              chargeAddedTransactions,
              true
            )
          }
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        >
          <Tooltip content={"Room is already assigned."}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
              fill="#3968ED"
            >
              <path d="M160-160v-80h110l-16-14q-52-46-73-105t-21-119q0-111 66.5-197.5T400-790v84q-72 26-116 88.5T240-478q0 45 17 87.5t53 78.5l10 10v-98h80v240H160Zm400-10v-84q72-26 116-88.5T720-482q0-45-17-87.5T650-648l-10-10v98h-80v-240h240v80H690l16 14q49 49 71.5 106.5T800-482q0 111-66.5 197.5T560-170Z" />
            </svg>
          </Tooltip>
        </div> */}
      </div>
    </>
  );
};

export default SidebarChargeAmounts;
