// ... (previous imports and settings)

import Slider from "react-slick";
import "./CustomCarousel.css";

const CustomCarousel = ({ suggestions }) => {

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 8000
  };

  return (
    <>
      <Slider {...settings} className="housekeeping_suggession_slider">
        {suggestions?.map((item, index) => {
          return (
            <div
              key={index}
              style={{ position: "relative" }}
              className={
                item?.positive === 1
                  ? "positive_background"
                  : "negative_background"
              }
            >
              <div className="description_parent">
                {item?.positive === 1 ? (
                  <div style={{ marginTop: "3px" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path fillRule="evenodd" clipRule="evenodd" d="M3.75 16.875C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H15.3125C15.5611 3.125 15.7996 3.02623 15.9754 2.85041C16.1512 2.6746 16.25 2.43614 16.25 2.1875C16.25 1.93886 16.1512 1.7004 15.9754 1.52459C15.7996 1.34877 15.5611 1.25 15.3125 1.25H3.75C3.08696 1.25 2.45107 1.51339 1.98223 1.98223C1.51339 2.45107 1.25 3.08696 1.25 3.75V16.25C1.25 16.913 1.51339 17.5489 1.98223 18.0178C2.45107 18.4866 3.08696 18.75 3.75 18.75H16.25C16.913 18.75 17.5489 18.4866 18.0178 18.0178C18.4866 17.5489 18.75 16.913 18.75 16.25V12.1875C18.75 11.9389 18.6512 11.7004 18.4754 11.5246C18.2996 11.3488 18.0611 11.25 17.8125 11.25C17.5639 11.25 17.3254 11.3488 17.1496 11.5246C16.9738 11.7004 16.875 11.9389 16.875 12.1875V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75ZM19.725 5.85C19.8906 5.67228 19.9808 5.43722 19.9765 5.19435C19.9722 4.95147 19.8738 4.71974 19.702 4.54797C19.5303 4.37621 19.2985 4.27782 19.0557 4.27353C18.8128 4.26925 18.5777 4.3594 18.4 4.525L11.4525 11.4713L9.11125 9.05125C9.02583 8.96199 8.92361 8.8905 8.81047 8.84088C8.69732 8.79126 8.57549 8.7645 8.45197 8.76212C8.32845 8.75975 8.20567 8.78181 8.09071 8.82704C7.97574 8.87227 7.87085 8.93978 7.78206 9.02569C7.69327 9.11159 7.62234 9.2142 7.57334 9.32761C7.52433 9.44103 7.49823 9.563 7.49653 9.68654C7.49483 9.81007 7.51756 9.93272 7.56342 10.0474C7.60928 10.1622 7.67736 10.2667 7.76375 10.355L10.7675 13.46C10.8539 13.5496 10.9573 13.6212 11.0717 13.6704C11.1861 13.7196 11.3091 13.7456 11.4336 13.7467C11.5581 13.7479 11.6816 13.7243 11.7969 13.6772C11.9122 13.6301 12.0169 13.5605 12.105 13.4725L19.725 5.85Z" fill="white" />
                    </svg>
                  </div>
                ) : (
                  <div style={{ marginTop: "3px" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g clipPath="url(#clip0_1827_2156)">
                        <path d="M10 0C4.47656 0 0 4.47656 0 10C0 15.5234 4.47656 20 10 20C15.5234 20 20 15.5234 20 10C20 4.47656 15.5234 0 10 0ZM2.5 10C2.5 5.85547 5.85938 2.5 10 2.5C11.6445 2.5 13.1641 3.03516 14.3984 3.93359L3.93359 14.3984C3.03516 13.1641 2.5 11.6445 2.5 10ZM10 17.5C8.35547 17.5 6.83594 16.9648 5.60156 16.0664L16.0664 5.60156C16.9648 6.83984 17.5 8.35547 17.5 10C17.5 14.1445 14.1406 17.5 10 17.5Z" fill="white" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1827_2156">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                )}
                <p>{item?.suggestion}</p>
              </div>
            </div>
          );
        })}
      </Slider>
    </>
  );
};

export default CustomCarousel;
