import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import { useState } from "react";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";

const ReportConsolidatedBusiness = ({ departureReport, fullData }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",

    //   { title: "Booking ID" },d
    //   { title: "Guest Name" },d
    //   { title: "Arrival Date" },d
    //   { title: "Departure Date" },d
    //   { title: "Source" },
    //   { title: "Grand Total" },
    //   { title: "Amount Paid" },
    //   { title: "Balance" },
    //   { title: "Status" },
  };
  const extractedData = departureReport?.map((item) => ({
    // unique_booking_id: item?.unique_booking_id,
    // guest_name: item?.guest[0]?.guest_name,
    // check_in: item?.check_in,
    // check_out: item?.check_out,
    // booking_source: item?.booking_source?.replace(/_/g, " "),
    // grand_total: item?.grand_total,
    // paid_amount: item?.paid_amount,
    // balance_amount: item?.balance_amount,
    // status: item?.status?.replace(/_/g, " "),
  }));

  const [informationPopup, setInformationPopup] = useState(false);

  const names = [
    {
      unique_booking_id: "Booking ID",
      guest_name: "Guest Name",
      check_in: "Arrival Date",
      check_out: "Departure Date",
      booking_source: "Booking Source",
      grand_total: "Grand Total",
      paid_amount: "Paid Amount",
      grand_total: "Balance",
      status: "Status",
    },
  ];

  console.log("departureReport", departureReport);

  const rowMarkup = departureReport?.map((order) => (
    <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
      <CustomReportTableCell>
        <TextDateFormatter as={"span"} showYear={true} date={order.date} />
      </CustomReportTableCell>
      <CustomReportTableCell>
        {Number(order?.occupancy).toFixed(2)}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(order?.adr)}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(order?.revpar)}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(order?.todays_room_sale)}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(order?.cgst_room)}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(order?.sgst_room)}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(order?.cgst_room + order?.sgst_room)}
      </CustomReportTableCell>

      <CustomReportTableCell>
        {formatIndianCurrency(order?.todays_food_sale)}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(order.cgst_food)}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(order.sgst_food)}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(order.cgst_food + order.sgst_food)}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(order.todays_miscellaneous_sale)}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(order.sgst_misc)}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(order.cgst_misc)}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(order.total_miscellaneous_tax)}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(order.sgst_misc)}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(order.total_cgst)}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(order.total_sgst)}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(order.total_amount)}
      </CustomReportTableCell>
    </CustomReportTableRow>
  ));

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell colSpan={4}>{"Totals"}</CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>

      <CustomReportTableCell>
        {formatIndianCurrency(calculateTotalWithReduce(departureReport, "adr"))}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "revpar")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "todays_room_sale")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "cgst_room")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "sgst_room")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          Number(calculateTotalWithReduce(departureReport, "sgst_room")) +
            Number(calculateTotalWithReduce(departureReport, "cgst_room"))
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "todays_food_sale")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "cgst_food")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "sgst_food")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          Number(calculateTotalWithReduce(departureReport, "cgst_food")) +
          Number(calculateTotalWithReduce(departureReport, "sgst_food") )
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "todays_miscellaneous_sale")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "sgst_misc")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "cgst_misc")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "total_miscellaneous_tax")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "sgst_misc")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "total_cgst")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "total_cgst")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "total_amount")
        )}
      </CustomReportTableCell>
      {/* <CustomReportTableCell></CustomReportTableCell> */}
    </CustomReportTableRow>
  );

  return (
    <>
      <div
        id="receipt"
        className="room-types-full-container reports-new-background"
      >
        <div className="room-types-full-container-div">
          <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
            <div className="room-type-heading">
              Consolidated Business Report
            </div>
            <div
              className="hoverCursor"
              onClick={() => setInformationPopup(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#3968ED"
              >
                <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
              </svg>
            </div>
          </div>
          {departureReport?.length > 0 && (
            <div className="flex flex_gap_15" id="doNotPrint">
              <DownloadPdfTables
                tableData={extractedData}
                tableNames={names}
                downloadFileName="AccountBalanceReport"
              />

              {/* <ButtonToDownloadTablePDF  downloadFileName="AccountBalanceReport" itemToDownloadID={"receipt"} ignoreFromPrint={"doNotPrint"}/> */}
              <DownloadCSVButton
                downloadFileName="AccountBalanceReport"
                itemToDownloadID={"receipt"}
              />
            </div>
          )}
        </div>

        {departureReport && departureReport.length > 0 ? (
          <>
            {/* <LegacyCard>
              <IndexTable
                resourceName={resourceName}
                itemCount={departureReport.length}
                selectable={false}
                headings={[
                  { title: "Booking ID" },
                  { title: "Guest Name" },
                  { title: "Arrival Date" },
                  { title: "Departure Date" },
                  { title: "Source" },
                  { title: "Grand Total" },
                  { title: "Amount Paid" },
                  { title: "Balance" },
                  { title: "Status" },
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </LegacyCard> */}

            <CustomReportTable
              headings={[
                "Date",
                "Occupancy",
                "ADR",
                "RevPar",
                "Room Sale",
                "CGST",
                "SGST",
                "Total",
                "F&B Sale",
                "CGST",
                "SGST",
                "Total",
                "Misc. Sale",
                "CGST",
                "SGST",
                "Total",
                "Total Sale",
                "CGST",
                "SGST",
                "Total",
              ]}
              commonHeadings={[
                "",
                "",
                "",
                "",
                "",
                "Room Sale Tax",
                "Room Sale Tax",
                "Room Sale Tax",
                "",
                "F&B Sale Tax",
                "F&B Sale Tax",
                "F&B Sale Tax",
                "",
                "Misc. Tax",
                "Misc. Tax",
                "Misc. Tax",
                "",
                "Total Tax",
                "Total Tax",
                "Total Tax",
              ]}
              headerStyles={{
                backgroundColor: "#dddddd",
                fontSize: "12px",
                textAlign: "left",
              }}
              tableStyles={{ textAlign: "left" }}
            >
              {rowMarkup}
              {calculateTotals}
            </CustomReportTable>
          </>
        ) : (
          <NoDataFound />
        )}

        {informationPopup && (
          <InformationCustomPopup
            setInformationPopup={setInformationPopup}
            title="Information of Consolidated Business Report"
          >
            <div className="mb-20">
              <AccordionComponent
                questions={[
                  {
                    question: "What is the Consolidated Business Report?",
                    answer: (
                      <p>
                        The <b>Consolidated Business Report</b> is a
                        comprehensive summary that tracks the hotel’s
                        performance metrics over a specified date range. It
                        includes key indicators such as occupancy rates, average
                        daily rate (ADR), revenue per available room (RevPAR),
                        total sales from room, food and beverage (F&B), and
                        miscellaneous categories, along with associated taxes.
                        This report provides a clear overview of the hotel's
                        financial performance.
                      </p>
                    ),
                  },
                  {
                    question:
                      "What is the Importance of the Consolidated Business Report?",
                    answer: (
                      <div>
                        <p>
                          The Consolidated Business Report is crucial for
                          several reasons:
                        </p>

                        <ul>
                          <li>
                            <b>Performance Analysis:</b> It enables management
                            to assess overall hotel performance, identify
                            trends, and make data-driven decisions.
                          </li>
                          <li>
                            <b>Revenue Management:</b> By aggregating sales
                            data, it helps optimize pricing strategies and
                            improve revenue forecasting.
                          </li>
                          <li>
                            <b>Operational Efficiency:</b> Provides insights
                            that support better resource allocation and
                            operational planning.
                          </li>
                          <li>
                            <b>Revenue Assurance: </b>Assists in tracking all
                            financial aspects of bookings, ensuring timely
                            payment collection and minimizing revenue leakage.
                          </li>
                        </ul>
                        <p>
                          This report is a valuable tool for maintaining
                          financial control, ensuring accurate billing, and
                          promoting smoother guest check-ins and check-outs.
                        </p>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </InformationCustomPopup>
        )}
      </div>
    </>
  );
};
export default ReportConsolidatedBusiness;
